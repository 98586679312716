import { Skeleton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { Colors } from '../../../theme/colors';
import ContainerBg from '../../../assets/svg/feature/ranking/progress-bg.svg';
import { ReactComponent as SyncIcon } from '../../../assets/svg/feature/sync.svg';
import { useHandleProgress } from '../hooks/useHandleProgress';
import { CHALLENGE_TYPE } from '../../../models/enum/CHALLENGE_TYPE';
import { AWARD_TRIGGER } from '../../../models/enum/AWARD_TRIGGER';
import { ChartProgress } from './ChartProgress';
import { numberLimitWithSpaces, numberWithSpaces } from '../../../utils/numberWithSpace';

type ProgressCardVariant = 'lg' | 'md';

type ProgressCardProps = {
  isLoading?: boolean;
  value?: number;
  teamValue?: number;
  valueLeft?: number;
  syncDate?: string;
  selfPercent?: number;
  teamPercent?: number;
  metricTitle?: string;
  challengeType?: CHALLENGE_TYPE;
  awardTrigger?: AWARD_TRIGGER;
  variant?: ProgressCardVariant;
  onlyTeam?: boolean;
};

export const ProgressCard: React.FC<ProgressCardProps> = ({
  isLoading = false,
  value = 0,
  teamValue,
  valueLeft = 0,
  syncDate,
  metricTitle = '',
  challengeType,
  awardTrigger,
  selfPercent = 0,
  teamPercent,
  variant = 'md',
  onlyTeam = false,
}) => {
  return isLoading || !syncDate ? (
    <Skeleton
      component="div"
      variant="rectangular"
      sx={{
        flex: 1,
        height: { xs: 300, ...(variant === 'lg' ? { md: 356 } : { md: 200 }) },
        marginBottom: '16px',
        borderRadius: '10px',
        paddingBottom: { xs: '16px', md: 0 },
        background: Colors.green[150],
      }}
    />
  ) : (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        border: `5px solid ${Colors.green[50]}`,
        flex: { xs: 'unset', md: 1 },
        height: { xs: 'auto', md: variant === 'lg' ? 'auto' : 200 },
        marginBottom: variant === 'lg' ? { xs: '16px', md: '0' } : '16px',
        borderRadius: '10px',
        paddingBottom: '16px',
        background:
          'linear-gradient(258.6deg, #B9F1DA -2.25%, #E8FAF3 97.36%), linear-gradient(0deg, #E8FAF3, #E8FAF3)',

        overflow: 'hidden',
      }}
    >
      <img
        src={ContainerBg}
        style={{ position: 'absolute', zIndex: 0, top: 50, left: 23, bottom: -60, right: -30, objectFit: 'cover' }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingTop: '10px',
          paddingInline: '10px',
        }}
      >
        <Typography sx={{ fontWeight: 700, fontSize: '14px', color: Colors.gray[700] }}>
          {typeof teamValue === 'number' ? 'Team progress' : 'Progress'}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <SyncIcon height="12px" width="12px" fill="#8DA09D" style={{ marginRight: '8px' }} />
          <Typography sx={{ fontSize: '10px', color: '#8DA09D', '& b': { fontWeight: 700 } }}>
            Last sync: <b>{syncDate}</b>
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexWrap: { xs: 'wrap', md: variant === 'lg' ? 'wrap' : 'unset' },
          justifyContent: 'space-between',
          flex: 1,
          marginTop: '8px',
          alignItems: 'center',
          marginInline: '23px',
        }}
      >
        <Box
          sx={{
            display: { xs: 'flex', md: variant === 'lg' ? 'flex' : 'none' },
            flexDirection: 'column',
            alignItems: 'center',
            flex: 1,
            minWidth: 160,
            marginTop: variant === 'lg' ? { xs: '24px', md: '40px' } : '24px',
          }}
        >
          <Typography
            sx={{
              fontSize: '32px',
              color: Colors.gray[700],
              fontWeight: 900,
              textAlign: 'center',
              lineHeight: 1,
              whiteSpace: 'nowrap',
            }}
          >
            {valueLeft < 0 ? numberWithSpaces(-valueLeft) : numberWithSpaces(valueLeft)}
          </Typography>
          <Typography
            sx={{
              fontSize: '10px',
              color: '#8DA09D',
              textAlign: 'center',
              marginTop: '-4px',
              ...(variant === 'lg' && { marginBottom: { xs: '8px', md: '24px' } }),
            }}
          >
            {challengeType === CHALLENGE_TYPE.STICK_TO_IT
              ? `days more to the ${
                  awardTrigger === AWARD_TRIGGER.COMPLETION || awardTrigger === AWARD_TRIGGER.FASTEST ? 'target' : 'top'
                }`
              : challengeType === CHALLENGE_TYPE.LONGER_STREAK
              ? `days-in-a-row more to the ${
                  awardTrigger === AWARD_TRIGGER.COMPLETION || awardTrigger === AWARD_TRIGGER.FASTEST ? 'target' : 'top'
                }`
              : `${metricTitle} more to the ${
                  awardTrigger === AWARD_TRIGGER.COMPLETION || awardTrigger === AWARD_TRIGGER.FASTEST ? 'target' : 'top'
                }`}
          </Typography>
        </Box>
        <ChartProgress
          score={teamValue ?? value}
          leftScore={valueLeft}
          selfPercent={selfPercent}
          teamPercent={teamPercent}
          yourScore={value}
          teamScore={teamValue}
          onlyTeam={onlyTeam}
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'row', md: 'column' },

            height: { xs: 'auto', md: 100 },
            width: '100%',
          }}
        >
          {variant === 'md' && (
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                flexDirection: 'column',
                alignItems: 'center',
                minWidth: 140,
              }}
            >
              <Typography
                sx={{
                  fontSize: '32px',
                  color: Colors.gray[700],
                  fontWeight: 900,
                  textAlign: 'center',
                  lineHeight: 1,
                  whiteSpace: 'nowrap',
                }}
              >
                {valueLeft < 0 ? numberLimitWithSpaces(-valueLeft, 5) : numberLimitWithSpaces(valueLeft, 5)}
              </Typography>
              <Typography sx={{ fontSize: '10px', color: '#8DA09D', textAlign: 'center', marginTop: '-4px' }}>
                {challengeType === CHALLENGE_TYPE.STICK_TO_IT
                  ? `days more to the ${
                      awardTrigger === AWARD_TRIGGER.COMPLETION || awardTrigger === AWARD_TRIGGER.FASTEST
                        ? 'target'
                        : 'top'
                    }`
                  : challengeType === CHALLENGE_TYPE.LONGER_STREAK
                  ? `days-in-a-row more to the ${
                      awardTrigger === AWARD_TRIGGER.COMPLETION || awardTrigger === AWARD_TRIGGER.FASTEST
                        ? 'target'
                        : 'top'
                    }`
                  : `${metricTitle} more to the ${
                      awardTrigger === AWARD_TRIGGER.COMPLETION || awardTrigger === AWARD_TRIGGER.FASTEST
                        ? 'target'
                        : 'top'
                    }`}
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              flex: { xs: 1, md: 'unset' },
              ...(variant === 'lg'
                ? { marginTop: { xs: 'auto', md: variant === 'lg' ? '24px' : 'auto' } }
                : { marginTop: 'auto' }),
              display: { xs: 'flex', md: variant === 'lg' ? 'flex' : 'unset' },
              justifyContent: { xs: 'center', md: variant === 'lg' ? 'center' : 'unset' },
              flexWrap: 'wrap',
            }}
          >
            {!onlyTeam && (
              <Box
                sx={{
                  display: 'flex',
                  marginBottom: { xs: 'unset', md: '4px' },
                  marginRight: { xs: '24px', md: variant === 'lg' ? '24px' : 'unset' },
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    height: '8px',
                    width: '8px',
                    borderRadius: '8px',
                    border: `1px solid ${Colors.green[500]}`,
                    background: `linear-gradient(180deg, #B0F3D8 0%, ${Colors.green[500]} 100%)`,
                    marginRight: '8px',
                  }}
                />
                <Typography sx={{ fontSize: '10px', lineHeight: 1, color: '#8DA09D' }}>You</Typography>
              </Box>
            )}
            {typeof teamValue === 'number' && (
              <Box
                sx={{
                  display: 'flex',
                  marginBottom: { xs: 'unset', md: '4px' },
                  marginRight: { xs: '24px', md: variant === 'lg' ? '24px' : 'unset' },
                  alignItems: 'center',
                  // minWidth: '150px',
                }}
              >
                <Box
                  sx={{
                    height: '8px',
                    width: '8px',
                    borderRadius: '8px',
                    border: `1px solid ${Colors.magenta[200]}`,
                    background: ` linear-gradient(180deg, ${Colors.magenta[100]} 0%, #D3ABEE 60.11%, ${Colors.magenta[200]} 100%)`,
                    marginRight: '8px',
                  }}
                />
                <Typography sx={{ fontSize: '10px', lineHeight: 1, color: '#8DA09D' }}>Team</Typography>
              </Box>
            )}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                sx={{
                  height: '8px',
                  width: '8px',
                  borderRadius: '8px',
                  border: `1px solid ${Colors.white}`,
                  background: `linear-gradient(148.24deg, ${Colors.white} -19.3%, ${Colors.green[100]} 92.83%)`,
                  marginRight: '8px',
                }}
              />
              <Typography sx={{ fontSize: '10px', lineHeight: 1, color: '#8DA09D' }}>Remaining</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const ProgressCardLayout: React.FC<{ variant?: ProgressCardVariant }> = ({ variant }) => {
  const props = useHandleProgress();
  return <ProgressCard {...props} variant={variant} />;
};
