import * as React from 'react';
import { Box, Card, CardContent, CardHeader, Typography, useMediaQuery, useTheme } from '@mui/material';
import WellbeingPieChart from '../charts/WellbeingPieChart';

interface WellbeingCardProps {
  rating: number;
  series: number[];
  colors: string[];
  admin?: boolean;
  loading?: boolean;
}

const WellbeingCard: React.FC<WellbeingCardProps> = ({ rating, series, colors, admin = false, loading = false }) => {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Card
      sx={{
        borderRadius: 2,
        backgroundColor: 'common.white',
        boxShadow: `0px 4px 24px rgba(138, 138, 138, 0.25)`,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        p: xsDown ? 2 : undefined,
      }}
      elevation={0}
    >
      <CardHeader
        sx={{ pb: 1 }}
        title={!admin ? `Wellics™ Index` : `Your Organization's Wellics™ Index`}
        titleTypographyProps={{
          variant: 'h5',
          color: 'common.black',
          fontWeight: 'bold',
          textAlign: xsDown ? 'center' : 'left',
        }}
      />
      <CardContent
        sx={{
          pt: 0,
          '&:last-child': { pb: 1 },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
          flexDirection: { xs: 'column', sm: 'row' },
        }}
      >
        <WellbeingPieChart rating={rating} series={series} colors={colors} loading={loading} />
        <Box>
          <Typography sx={{ lineHeight: 1, fontWeight: 'bold' }} variant={'body1'}>
            Wellics™ Index (WIN)
          </Typography>
          <Typography sx={{ mt: 1, lineHeight: 1 }} variant={'body2'}>
            {!admin ? (
              <span>
                {`Your WIN is calculated by combining the scores in the four different aspects of your lifestyle. 
                WIN increases within the day as more data comes in.`}
              </span>
            ) : (
              `This is your organization's Wellics™ Index.`
            )}
          </Typography>
          <Typography sx={{ mt: 1, lineHeight: 1 }} variant={'body2'}>
            {!admin
              ? `Your WIN keeps you aware of your status and helps you identify trends and actions that impact on your well-being.`
              : 'Use this number to explore well-being trends across your staff and what can be learned that is unique to your workplace.'}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default WellbeingCard;
