import { Box, ClickAwayListener } from '@mui/material';
import React, { MutableRefObject } from 'react';
import { ModalContent } from './ModalContent';
import { NotificationContent } from '../../../models/NotificationContent';

type Props = {
  show: boolean;
  notifications: NotificationContent[];
  handleClose: () => void;
  containerRef?: MutableRefObject<null>;
};
export const NotificationClickAwayModal: React.FC<Props> = ({ show, notifications, containerRef, handleClose }) => {
  return show ? (
    <ClickAwayListener onClickAway={handleClose}>
      <Box>
        <ModalContent show={show} containerRef={containerRef} notifications={notifications} handleClose={handleClose} />
      </Box>
    </ClickAwayListener>
  ) : null;
};
