import React from 'react';
import { Range } from 'react-date-range';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { CustomRangePicker } from '../../../components/features/CustomRangePicker';
import CustomDropdown from '../../../components/common/CustomDropdown';
import { Colors } from '../../../theme/colors';
import { GraphMetric } from '../../../models/Metrics';
import WellicsLineChart from '../../../components/features/WellicsLineChart';
import { CustomBudge } from '../../../components/features/CustomBudge';
import { ReactComponent as AverageIcon } from '../../../assets/svg/feature/average.svg';
import { ReactComponent as FlameIcon } from '../../../assets/svg/feature/flame.svg';
import theme from '../../../theme';
import { numberWithSpaces } from '../../../utils/numberWithSpace';
import { CustomDialog } from '../../../components/features/CustomDialog';
import moment from 'moment';
import EmptyImage from '../../../assets/png/empty-statistics.png';

type StatisticModalProps = {
  visible: boolean;
  graph?: GraphMetric;
  metricTitle?: string;
  range: Range[];
  bestScore?: number | null;
  dailyAverage?: number | null;
  teamBestScore?: number | null;
  teamDailyAverage?: number | null;
  points?: PointAnnotations[];
  isTeam?: boolean;
  startDate?: string;
  endDate?: string;
  selectOptions?: string[];
  selected?: string;
  isBoolean?: boolean;
  isSelf?: boolean;
  onClose: () => void;
  setRange: (range: Range[]) => void;
  setSelected?: (val: string) => void;
};

export const StatisticModal: React.FC<StatisticModalProps> = ({
  visible,
  range,
  graph,
  metricTitle,
  bestScore,
  dailyAverage,
  teamBestScore,
  teamDailyAverage,
  points,
  isTeam,
  startDate,
  endDate,
  selectOptions,
  selected,
  isBoolean,
  isSelf,
  onClose,
  setRange,
  setSelected,
}) => {
  const breakpointDown = useMediaQuery(theme.breakpoints.down(700));
  const isNotEmpty =
    graph && (graph.metrics[0]?.metric_samples?.length > 0 || graph.metrics?.[1]?.metric_samples?.length > 0);
  return (
    <CustomDialog onClose={onClose} open={visible} label="Your Challenge Progress">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: { xs: 'center', md: 'space-between' },
          marginRight: 'auto',
          marginBottom: '24px',
          paddingInline: '40px',
          width: { xs: '100%', table: '700px' },
          flexWrap: 'wrap',
        }}
      >
        <CustomRangePicker
          mini={breakpointDown}
          range={range}
          setRange={setRange}
          minDate={startDate ? new Date(startDate) : undefined}
          maxDate={endDate ? new Date(endDate) : undefined}
        />
        {selectOptions && setSelected && (
          <CustomDropdown
            fullWidth={breakpointDown}
            width={218}
            selected={selected ?? ''}
            list={selectOptions}
            setSelected={setSelected}
          />
        )}
        {isNotEmpty && (
          <Box sx={{ display: 'flex' }}>
            {isTeam && (
              <Box sx={{ display: { xs: 'none', md: 'flex' }, marginRight: '16px' }}>
                <Box
                  sx={{
                    height: '8px',
                    width: '8px',
                    borderRadius: '8px',
                    border: `1px solid ${Colors.magenta[200]}`,
                    background: Colors.magenta[300],
                    marginRight: '8px',
                  }}
                />
                <Typography sx={{ fontSize: '14px', lineHeight: 1, color: '#8DA09D' }}>Your team</Typography>
              </Box>
            )}
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Box
                sx={{
                  height: '8px',
                  width: '8px',
                  borderRadius: '8px',
                  border: `1px solid ${Colors.green[200]}`,
                  background: Colors.green[500],
                  marginRight: '8px',
                }}
              />
              <Typography sx={{ fontSize: '14px', lineHeight: 1, color: '#8DA09D' }}>You</Typography>
            </Box>
          </Box>
        )}
      </Box>
      <Box sx={{ paddingInline: '30px', height: 'auto', width: { xs: 'auto' }, paddingBottom: '16px' }}>
        {isNotEmpty ? (
          <WellicsLineChart
            height={203}
            startDate={startDate ? moment(startDate).add(-1, 'days').valueOf() : undefined}
            endDate={endDate ? moment(endDate).add(1, 'days').valueOf() : undefined}
            colors={[Colors.green[500], Colors.magenta[200], Colors.green[500], Colors.green[500]]}
            newCategoryColors={[Colors.green[500], Colors.magenta[200], Colors.green[500], Colors.green[500]]}
            graph={graph}
            points={points}
          />
        ) : (
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            sx={{ paddingTop: '16px', paddingBottom: '16px' }}
          >
            <img
              src={EmptyImage}
              style={{ height: '100%', width: 'auto', maxHeight: '128px', objectFit: 'contain', marginBottom: '20px' }}
            />
            <Typography sx={{ textAlign: 'center', fontSize: '18px', color: Colors.gray[300] }}>
              No data logged
            </Typography>
          </Box>
        )}
      </Box>
      {isNotEmpty && (
        <Box sx={{ marginInline: { xs: '16px', md: '40px' }, marginBottom: '50px' }}>
          <Box display="flex">
            {isTeam && (
              <Box sx={{ display: { xs: 'flex', md: 'none' }, marginRight: '16px' }}>
                <Box
                  sx={{
                    height: '8px',
                    width: '8px',
                    borderRadius: '8px',
                    border: `1px solid ${Colors.magenta[200]}`,
                    background: Colors.magenta[300],
                    marginRight: '8px',
                  }}
                />
                <Typography sx={{ fontSize: '14px', lineHeight: 1, color: '#8DA09D' }}>Your team</Typography>
              </Box>
            )}
            <Box sx={{ display: { xs: 'flex', md: 'none' }, marginBottom: '24px' }}>
              <Box
                sx={{
                  height: '8px',
                  width: '8px',
                  borderRadius: '8px',
                  border: `1px solid ${Colors.green[200]}`,
                  background: Colors.green[500],
                  marginRight: '8px',
                }}
              />

              <Typography sx={{ fontSize: '12px', lineHeight: 1, color: '#8DA09D' }}>You</Typography>
            </Box>
          </Box>
          {!isBoolean && (
            <Box sx={{ display: 'flex' }}>
              {isTeam && teamBestScore && teamDailyAverage && (
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', mt: '16px' }}>
                  <Typography sx={{ fontSize: '14px', color: Colors.gray[700], fontWeight: 700, mr: '16px' }}>
                    You
                  </Typography>
                  <Typography sx={{ fontSize: '14px', color: Colors.gray[700], fontWeight: 700, mr: '16px' }}>
                    Team
                  </Typography>
                </Box>
              )}
              <Box>
                <Typography sx={{ fontSize: '12px', color: Colors.gray[400], fontWeight: 400, mr: '16px' }}>
                  Best:
                </Typography>
                <Box sx={{ borderRight: '1px dashed #F2F2F2', pr: '16px', mb: '16px' }}>
                  <CustomBudge key={'Duration'} Icon={FlameIcon} label={numberWithSpaces(bestScore) ?? '---'} />
                </Box>
                {isTeam && teamBestScore && (
                  <Box sx={{ borderRight: '1px dashed #F2F2F2', pr: '16px' }}>
                    <CustomBudge key={'Duration'} Icon={FlameIcon} label={numberWithSpaces(teamBestScore) ?? '---'} />
                  </Box>
                )}
              </Box>
              <Box>
                <Typography sx={{ fontSize: '12px', color: Colors.gray[400], fontWeight: 400, mr: '16px' }}>
                  Average:
                </Typography>
                <Box sx={{ borderRight: '1px dashed #F2F2F2', pr: '16px', mb: '16px' }}>
                  <CustomBudge key={'Duration'} Icon={AverageIcon} label={numberWithSpaces(dailyAverage) ?? '---'} />
                </Box>
                {isTeam && teamDailyAverage && (
                  <Box sx={{ borderRight: '1px dashed #F2F2F2', pr: '16px' }}>
                    <CustomBudge
                      key={'Duration'}
                      Icon={AverageIcon}
                      label={numberWithSpaces(teamDailyAverage) ?? '---'}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Box>
      )}
    </CustomDialog>
  );
};
