import { Box } from '@mui/material';
import React from 'react';
import { Colors } from '../../../theme/colors';
import { ReactComponent as CheckIcon } from '../../../assets/svg/feature/check.svg';
import { ReactComponent as MinusIcon } from '../../../assets/svg/feature/minus.svg';

type CheckboxProps = {
  isSelected?: boolean;
  isMultiple?: boolean;
};

export const Checkbox: React.FC<CheckboxProps> = ({ isSelected, isMultiple }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '16px',
      width: '16px',
      borderRadius: '4px',
      border: `1px solid ${Colors.blue[300]}`,
      ...(isSelected && { background: Colors.blue[500] }),
    }}
  >
    {isSelected && isMultiple ? <MinusIcon fill={Colors.white} /> : <CheckIcon />}
  </Box>
);
