import React, { Fragment } from 'react';
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  Link,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { IonHeader, IonToolbar, useIonRouter } from '@ionic/react';
import { Capacitor } from '@capacitor/core';
import { InfoOutlined } from '@mui/icons-material';
import { HeaderPage, useHeaderPageHandle } from '../feature/structure/HeaderPage';
import { Colors } from '../../theme/colors';
import { BreadcrumbProps } from '../../models/Shared';
import { ReactComponent as ArrowIcon } from '../../assets/svg/feature/arrow.svg';

type CustomHeaderProps = {
  breadcrumb?: BreadcrumbProps;
  pagination?: React.ReactNode;
  rightAction?: React.ReactNode;
  upBox?: React.ReactNode;
  label?: string;
  tooltip?: NonNullable<React.ReactNode>;
  titleToolTip?: string;
  inProgress?: boolean;
  breakpoint?: 'xs' | 'sm' | 'md' | 'lg' | number;
  notificationComponent?: React.ReactNode;
};

const CustomNewHeader: React.FC<CustomHeaderProps> = ({
  children,
  breadcrumb,
  pagination,
  label,
  tooltip,
  titleToolTip,
  inProgress,
  rightAction,
  upBox,
  breakpoint = 'sm',
  notificationComponent,
}) => {
  const route = useIonRouter();
  const theme = useTheme();
  const breakpointDown = useMediaQuery(theme.breakpoints.down(breakpoint));
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const tableDown = useMediaQuery(theme.breakpoints.down('table'));
  const loading = useSelector((root: RootState) => root.app.headerLoading);

  const headerProps = useHeaderPageHandle();

  const getHeader = () => {
    return (
      <Container maxWidth={'lg'}>
        {breadcrumb && breadcrumb.list.length && !mdDown ? (
          <Grid container sx={{ pb: '12px' }}>
            <Box
              sx={{
                padding: '2px 8px 2px 2px',
                backgroundColor: Colors.gray[100],
                display: 'flex',
                alignItems: 'center',
                borderRadius: 20,
              }}
            >
              <Box
                sx={{
                  height: 20,
                  width: 20,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: `1px solid #E9EDEF`,
                  background: Colors.gray[150],
                  borderRadius: 20,
                }}
              >
                <breadcrumb.Icon height={12} width={12} fill="#89959D" />
              </Box>
              {breadcrumb.list.map((item, idx) => (
                <Fragment key={item.label}>
                  {idx < breadcrumb.list.length - 1 ? (
                    <>
                      <Link
                        underline="none"
                        href="/"
                        sx={{
                          ml: '8px',
                          mr: '8px',
                          color: '#6C7B84',
                          fontSize: '12px',
                          maxWidth: '133px',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          route.push(item.url, 'back');
                        }}
                      >
                        {item.label}
                      </Link>
                      <ArrowIcon height={12} width={12} fill="#A7B0B5" style={{ transform: 'rotate(180deg)' }} />
                    </>
                  ) : (
                    <Typography
                      sx={{
                        ml: '8px',
                        mr: '8px',
                        fontSize: '12px',
                        color: '#414A4F',
                        fontWeight: '700',
                        maxWidth: '188px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {item.label}
                    </Typography>
                  )}
                </Fragment>
              ))}
            </Box>
          </Grid>
        ) : null}

        {(pagination || breadcrumb) && mdDown ? (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            {breadcrumb && breadcrumb.list.length > 0 && (
              <Grid container>
                <Box
                  sx={{
                    padding: '2px 8px 2px 2px',
                    backgroundColor: Colors.gray[100],
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: 20,
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    route.push(breadcrumb.list[breadcrumb.list.length - 2].url, 'back');
                  }}
                >
                  <Box
                    sx={{
                      height: 26,
                      width: 26,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: `1px solid #E9EDEF`,
                      background: Colors.gray[150],
                      borderRadius: 20,
                    }}
                  >
                    <ArrowIcon height={16} width={16} fill="#6C7B84" />
                  </Box>

                  <Link
                    underline="none"
                    href="/"
                    sx={{
                      ml: '8px',
                      mr: '8px',
                      color: '#6C7B84',
                      fontSize: '14px',
                      maxWidth: '155px',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {breadcrumb.list[breadcrumb.list.length - 2].label}
                  </Link>
                </Box>
              </Grid>
            )}
            {pagination}
          </Box>
        ) : null}

        {upBox}

        <Grid
          container
          wrap={tableDown || breakpointDown ? 'wrap' : 'nowrap'}
          alignItems={'center'}
          sx={{
            pt: mdDown && !pagination && !breadcrumb ? 0 : Capacitor.getPlatform() === 'ios' ? 3 : 3,
            pb: mdDown || breakpointDown ? 1 : 3,
            justifyContent: 'space-between',
          }}
        >
          <Grid
            item
            sx={{ flex: 1 }}
            container
            alignItems={'center'}
            alignSelf={'flex-start'}
            xs={tableDown || breakpointDown ? 12 : false}
          >
            <Grid item container alignItems={'center'} justifyContent={'start'}>
              <Box position={'relative'} sx={{ flex: 1 }} alignItems={'center'} display={'flex'}>
                <Tooltip title={titleToolTip ? <Typography variant={'body2'}>{titleToolTip}</Typography> : ''}>
                  <Typography
                    sx={{
                      fontSize: { xs: 24, md: 32 },
                      fontWeight: 900,
                      color: 'text.primary',
                    }}
                  >
                    {label}
                    {tooltip ? (
                      <Tooltip
                        title={<Typography variant={'body2'}>{tooltip}</Typography>}
                        placement={tableDown || breakpointDown ? 'bottom-end' : 'right-start'}
                        enterTouchDelay={0}
                        leaveTouchDelay={10000}
                        sx={{ mx: 1, fontSize: tableDown || breakpointDown ? 16 : 20, color: theme.palette.grey[600] }}
                      >
                        <InfoOutlined fontSize={'inherit'} color={'inherit'} />
                      </Tooltip>
                    ) : null}
                  </Typography>
                </Tooltip>
                {(loading || inProgress) && (
                  <CircularProgress
                    size={tableDown || breakpointDown ? 16 : 24}
                    sx={{
                      margin: '0 8px',
                      // position: 'absolute',
                      right: tableDown || breakpointDown ? -16 : -24,
                      top: 4,
                    }}
                  />
                )}
                {rightAction}
              </Box>
            </Grid>
          </Grid>
          {children && (
            <Grid
              item
              sx={{ py: tableDown || breakpointDown ? 1 : undefined }}
              xs={tableDown || breakpointDown ? 12 : undefined}
            >
              {children}
            </Grid>
          )}
        </Grid>
      </Container>
    );
  };

  return (
    <IonHeader>
      <HeaderPage {...headerProps} />
      {notificationComponent}
      <IonToolbar
        style={{
          '--background': Colors.gray[50],
          '--border-width': 0,
        }}
      >
        <Box
          sx={{
            color: 'text.primary',
            width: '100%',
            background: Colors.gray[50],
          }}
          color={'text'}
        >
          {getHeader()}
        </Box>
      </IonToolbar>
    </IonHeader>
  );
};

export default CustomNewHeader;
