import { throttle } from 'lodash';
import { useState, useEffect, useRef } from 'react';
import { QueryFilterData } from '../../components/features/HeaderFilter/useHandleFilter';
import { getTemplatesRequest } from '../../lib/api/http/requests/challengeAdmin';
import { Challenge } from '../../models/Challenge';
import { handleBackendError } from '../../utils/handleBackendError';

const SIZE_PAGE = 12;

export const useHandleFetch = (queryFilter: QueryFilterData) => {
  const [items, setItems] = useState<Challenge[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);

  const divRef = useRef<HTMLIonContentElement>(null);

  const [selectedChallenge, setSelectedChallenge] = useState<Challenge | null>(null);
  const handleNext = async () => {
    try {
      if (isFetching || !hasNextPage || loading) return;
      setIsFetching(true);
      const { data } = await getTemplatesRequest({
        searchText: queryFilter.searchText,
        challengePillars: queryFilter.challengePillars,
        competition: queryFilter.competition,
        challengeType: queryFilter.challengeType,
        page: page,
        size: SIZE_PAGE,
        metric: queryFilter.metric,
      });
      setPage(data.pageable.pageNumber + 1);
      setHasNextPage(!data.last);
      setItems([...items, ...data.content]);
      setIsFetching(false);
    } catch (err) {
      handleBackendError(err, 'Failed fetch challenge templates');
      setHasNextPage(false);
      setIsFetching(false);
    }
  };

  const handleScrollEvent = throttle(async () => {
    if (divRef.current) {
      const { scrollHeight, scrollTop, offsetHeight } = await divRef.current.getScrollElement();
      const currentPosition = scrollTop + offsetHeight;
      const triggerPosition = scrollHeight - 40;
      if (currentPosition >= triggerPosition && (!isFetching || hasNextPage)) {
        handleNext();
      }
    }
  }, 200);

  const firstFetch = async () => {
    try {
      setLoading(true);
      const { data } = await getTemplatesRequest({
        searchText: queryFilter.searchText,
        challengePillars: queryFilter.challengePillars,
        competition: queryFilter.competition,
        challengeType: queryFilter.challengeType,
        page: 0,
        size: SIZE_PAGE,
        metric: queryFilter.metric,
      });
      if (divRef.current && !data.last) {
        const { scrollHeight, scrollTop, offsetHeight } = await divRef.current.getScrollElement();
        const currentPosition = scrollTop + offsetHeight;
        const triggerPosition = scrollHeight - 40;
        if (currentPosition >= triggerPosition) {
          const { data: nextPageData } = await getTemplatesRequest({
            searchText: queryFilter.searchText,
            challengePillars: queryFilter.challengePillars,
            competition: queryFilter.competition,
            page: 1,
            size: SIZE_PAGE,
            metric: queryFilter.metric,
          });
          setHasNextPage(!nextPageData.last);
          setItems([...data.content, ...nextPageData.content]);
          setPage(nextPageData.pageable.pageNumber + 1);
          setLoading(false);
          return;
        }
      }

      setHasNextPage(!data.last);
      setItems(data.content);
      setPage(data.pageable.pageNumber + 1);
      setLoading(false);
    } catch (err) {
      handleBackendError(err, 'Failed fetch challenge templates');
      setLoading(false);
    }
  };

  useEffect(() => {
    const subscribe = setTimeout(firstFetch, 600);
    return () => clearTimeout(subscribe);
  }, [queryFilter]);
  return { items, loading, isFetching, divRef, selectedChallenge, setSelectedChallenge, handleScrollEvent };
};
