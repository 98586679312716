import { IonContent, IonPage, useIonRouter } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { Box, Container, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CustomNewHeader from '../components/features/CustomNewHeader';
import { Colors } from '../theme/colors';
import { RootState } from '../redux/store';
import { getResult, startPoll, submitInitialise } from '../lib/api/http/requests/mentalAssessment';
import { asyncGetIsUserEligible, setInitialData } from '../redux/slices/mentalAssessmentSlice';
import { IResult } from '../models/MentalAssessment';
import { TestResultItem } from '../components/features/cards/TestResultItem';
import { InAppBrowser, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser';
import { Capacitor } from '@capacitor/core';

import { Diagnostic } from '@ionic-native/diagnostic';
import { RetrySection } from '../components/features/MentalAssessment/RetrySection';
import { CalculatingSection } from '../components/features/MentalAssessment/CalculatingSection';

export const MentalAssessmentPage: React.FC = () => {
  const dispatch = useDispatch();
  const router = useIonRouter();
  const { initialiseData, isAllowInitPlugin, personalData } = useSelector(
    (state: RootState) => state.mentalAssessmentSlice,
  );

  const [showHistoryLink, setShowHistoryLink] = useState<boolean>(false);
  const [showRetry, setShowRetry] = useState<boolean>(false);
  const [eventTriggered, setEventTriggered] = useState(false);
  const [startGetResult, setStartGetResult] = useState(false);
  const [counter, setCounter] = useState(0);
  const [notification, setNotification] = useState('');
  const [testResult, setTestResult] = useState<IResult | null>(null);

  const getAccessToMicrophone = async () => {
    const status = await Diagnostic.getMicrophoneAuthorizationStatus();
    if (status !== 'GRANTED' || status !== 'GRANTED_WHEN_IN_USE') {
      await Diagnostic.requestMicrophoneAuthorization();
    }
  };

  const resetData = () => {
    setTestResult(null);
    setNotification('');
    setEventTriggered(false);
    setShowHistoryLink(false);
    setStartGetResult(false);
    setShowRetry(false);
    setCounter(0);
  };

  function getInappBrowser(url: string) {
    const options: InAppBrowserOptions = {
      zoom: 'no',
      location: 'no',
      fullscreen: 'yes',
      hidenavigationbuttons: 'yes',
      cleardata: 'no',
      clearcache: 'no',
      clearsessioncache: 'no',
      usewkwebview: 'yes',
      closebuttoncaption: 'Close',
    };
    return InAppBrowser.create(url, '_blank', options);
  }

  useEffect(() => {
    if (!initialiseData.id && !initialiseData.activityLink) {
      router.push('/page/your-journey');

      return;
    }

    resetData();

    if (Capacitor.isNativePlatform() && initialiseData.activityLink) {
      getAccessToMicrophone();

      const browser = getInappBrowser(initialiseData.activityLink);

      browser.on('loadstop').subscribe((event) => {
        console.log('Widget loaded, going to inject script');

        browser.executeScript({
          code: `console.log('Script running inside the InAppBrowser');
          let completed = false;
          const ACTIVITY_COMPLETE_OK_EVENT = "thymia:activity-complete-ok";

          window.addEventListener("message", (event) => {
          console.log('Received message apo thymia widget inside the inAppBrowser');
          if (event.data === ACTIVITY_COMPLETE_OK_EVENT) {
            const target = window['cordova_iab'] ?? window['webkit'].messageHandlers['cordova_iab'];
            completed = true;
            target.postMessage(JSON.stringify({'completed': completed}));
          }
          });`,
        });

        console.log('Script supposedly injected');
      });

      let thymiaCompletedFlag = false;

      const browserMessageListener = browser.on('message').subscribe((event) => {
        const receivedCompleted = JSON.parse(event.data.completed);

        if (receivedCompleted) {
          // Thymia activity completed successfully or was very short
          thymiaCompletedFlag = receivedCompleted;
          handleCompleteEvent(null);
        }

        browserMessageListener.unsubscribe();
        browser.close();
      });

      const exitListener = browser.on('exit').subscribe((e) => {
        if (!thymiaCompletedFlag) {
          setEventTriggered(true);
          setShowRetry(true);
        }

        // reset flag to false for next iteration
        thymiaCompletedFlag = false;
        exitListener.unsubscribe();
      });
    }
  }, [dispatch, initialiseData]);

  const handleRetryInitialise = async () => {
    if (!isAllowInitPlugin) return;
    const response = await submitInitialise(personalData);

    if (!response.data) return;
    setShowRetry(false);
    dispatch(setInitialData(response.data));
    setEventTriggered(false);
  };

  const sendStartPoll = async () => {
    if (!initialiseData.id && !initialiseData.activityLink) {
      return;
    }
    try {
      setStartGetResult(true);
      await startPoll({ activityId: initialiseData.id as string });
    } catch (e) {}
  };

  const handleGetResult = async () => {
    if (!initialiseData.id && !initialiseData.activityLink) {
      return;
    }
    try {
      const response = await getResult(initialiseData.id as string);
      setStartGetResult(false);
      if ('errorMessage' in response.data) {
        setNotification(response.data.errorMessage);
        setShowRetry(true);

        return;
      } else {
        setTestResult(response.data);
        dispatch(asyncGetIsUserEligible());
      }
    } catch (error: any) {
      const errorObject = Capacitor.isNativePlatform() ? JSON.parse(error?.error) : error?.response;
      console.log('errorObject', errorObject);
      if (!Capacitor.isNativePlatform() && error?.response.status === 404) {
        setNotification('Activity is not found');
      }
    }
  };
  const handleCompleteEvent = async (event: MessageEvent<any> | null) => {
    const ACTIVITY_COMPLETE_OK_EVENT = 'thymia:activity-complete-ok';

    if (event === null || event.data === ACTIVITY_COMPLETE_OK_EVENT) {
      await sendStartPoll();
      setNotification('');
      setEventTriggered(true);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (counter < 15) {
        await handleGetResult();

        setCounter((prevCounter) => prevCounter + 1);
        setShowHistoryLink(false);
      } else {
        setNotification('The results are still not ready and you can access them later in the ');
        setShowHistoryLink(true);
        clearInterval(intervalId);
      }
    }, 5000);

    if (!startGetResult) {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [counter, startGetResult]);

  useEffect(() => {
    window.addEventListener('message', handleCompleteEvent);
    return () => window.removeEventListener('message', handleCompleteEvent);
  }, [initialiseData.activityLink]);

  return (
    <IonPage>
      <CustomNewHeader
        label={'WellVox'}
        tooltip={'A snapshot of your current mental wellness to guide you towards a more balanced daily experience.'}
      />

      <IonContent style={{ '--padding-bottom': '36px' }} scrollEvents={false}>
        <Container maxWidth={'lg'}>
          <Grid container>
            <Grid container item xs={12} sx={{ mb: 0, zIndex: 1 }}>
              {testResult && (
                <Grid item md={12} table={12} xs={12} flex={1}>
                  <TestResultItem item={testResult} />
                </Grid>
              )}

              {!testResult && (
                <Box
                  sx={{
                    height: {
                      xs: Capacitor.getPlatform() === 'ios' ? 'calc(100vh - 200px)' : 'calc(100vh - 250px)',
                      table: 640,
                    },
                    width: '100%',
                    borderRadius: '20px',
                    overflow: 'hidden',
                    boxShadow: ' 5px 0px 15px 0px rgba(108, 123, 132, 0.05)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: Colors.white,
                  }}
                >
                  {!eventTriggered && initialiseData.activityLink ? (
                    Capacitor.isNativePlatform() ? null : (
                      <iframe
                        style={{ width: '100%', height: '100%', border: 'none' }}
                        src={initialiseData.activityLink}
                        allow="microphone"
                      ></iframe>
                    )
                  ) : showRetry ? (
                    <RetrySection notification={notification} handleRetryInitialise={handleRetryInitialise} />
                  ) : (
                    <CalculatingSection showHistoryLink={showHistoryLink} notification={notification} />
                  )}
                </Box>
              )}
            </Grid>
          </Grid>
        </Container>
      </IonContent>
    </IonPage>
  );
};

export default MentalAssessmentPage;
