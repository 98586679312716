import React, { useRef } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import CustomNewHeader from '../../components/features/CustomNewHeader';
import { Box, CardMedia, Collapse, Container, Link, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { Colors } from '../../theme/colors';
import { styles } from './styles';
import IconPlaceholder from '../../assets/defaultBlog.png';
import { ReactComponent as GoalIcon } from '../../assets/svg/goal.svg';
import { ReactComponent as ClockIcon } from '../../assets/svg/clock.svg';
import { ReactComponent as ArrowIcon } from '../../assets/svg/feature/arrow.svg';
import { ReactComponent as DeleteIcon } from '../../assets/svg/feature/delete.svg';
import { ReactComponent as EditIcon } from '../../assets/svg/feature/edit.svg';
import { ReactComponent as ChallengeType } from '../../assets/svg/feature/сhellange-type.svg';
import { ReactComponent as Sum } from '../../assets/svg/feature/sum.svg';
import { ReactComponent as Winner } from '../../assets/svg/feature/winner.svg';
import { ReactComponent as SlackIcon } from '../../assets/svg/feature/slack.svg';
import { ReactComponent as CalendarIcon } from '../../assets/svg/feature/calendar.svg';
import { ReactComponent as StarIcon } from '../../assets/svg/feature/star.svg';
import { ReactComponent as TeamIcon } from '../../assets/svg/feature/team.svg';
import { ReactComponent as EmptyParticipantsIcon } from '../../assets/svg/feature/empty-participants.svg';
import { ReactComponent as TotalIcon } from '../../assets/svg/feature/sum.svg';
import { ReactComponent as DailyIcon } from '../../assets/svg/feature/daily-calendar.svg';
import { ReactComponent as LogIcon } from '../../assets/svg/feature/log.svg';
import { ReactComponent as WearableIcon } from '../../assets/svg/wearable.svg';
import { CardLabel } from '../../components/features/CardLabel';
import { useHandle } from './hooks/useHandle';
import { RouteComponentProps } from 'react-router';
import Grid from '@mui/material/Grid';
import { CustomAvatar } from '../../components/features/CustomAvatar';
import { CustomBudge } from '../../components/features/CustomBudge';
import { DIMENSION_LIST } from '../../models/enum/DIMENTION_LIST';
import { COMPETITION_LIST } from '../../models/enum/COMPETITION_LIST';
import { AWARD_LIST } from '../../models/enum/AWARD_LIST';
import { CustomButton } from '../../components/features/CustomButton';
import theme from '../../theme';
import { CollapseBox } from '../../components/features/CollapseBox';
import { Challenge } from '../../models/Challenge';
import { CustomAvatarList } from '../../components/features/CustomAvatarList';
import { RoundIconButton } from '../../components/features/RoundIconButton';
import { CHALLENGE_TYPE } from '../../models/enum/CHALLENGE_TYPE';
import { ChallengeParticipantsModal } from '../../components/features/modals/ChallengeParticipantsModal';
import { ChallengeDeleteModal } from '../../components/features/modals/ChallengeDeleteModal';
import { useDeleteHandle } from './hooks/useDeleteHandle';
import { capitalize } from '../../utils/stringCapitalize';
import { AWARD_TYPE } from '../../models/enum/AWARD_TYPE';
import { AWARD_TRIGGER } from '../../models/enum/AWARD_TRIGGER';
import { COMPETITION } from '../../models/enum/COMPETITION';
import { CALCULATION_TYPE } from '../../models/enum/CALCULATION_TYPE';
import { CHALLENGE_TYPE_LABEL } from '../../models/enum/CHALLENGE_TYPE_LABEL';
import { useParticipantsHandle } from './hooks/useParticipantsHandle';
import { useTeamsHandle } from './hooks/useTeamsHandle';
import { TRACKING_TYPE } from '../../models/enum/TRACKING_TYPE';

type Props = RouteComponentProps<{
  id: string;
}>;
type RouteParams = {
  id: string;
};

export const ChallengeAdminDetailPage: React.FC<Props> = ({ match }) => {
  const fullDescriptionRef = useRef<null | HTMLDivElement>(null);
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const { participants, participantsLoading, divParticipantsRef, handleScrollEvent, handleUpdateParticipants } =
    useParticipantsHandle(match.params as RouteParams);

  const {
    challenge,
    challengeTitle,
    emptyTrophy,
    expanded,
    showEditAction,
    showParticipantsModal,
    showSeparateParticipantsModal,
    challengeStatusBreadcrumbItem,
    durationInDays,
    challengeLoading,
    isModalTeam,
    progressBreadcrumbItem,
    handleOpenParticipant,
    setShowParticipantsModal,
    setShowSeparateParticipantsModal,
    handleExpandClick,
    formattedStartDate,
    formattedEndDate,
    navigateToDetailPage,
    handleExportTeams,
    handleExportParticipants,
  } = useHandle(match.params as RouteParams, handleUpdateParticipants);
  const { teams, teamsLoading, divTeamsRef, handleTeamScrollEvent } = useTeamsHandle(
    match.params as RouteParams,
    challenge,
  );

  const { deleteLoading, showDelete, showDeleteButton, deleteRequest, setShowDelete } = useDeleteHandle(
    match.params as RouteParams,
    challenge,
  );
  const executeScrollToDescription = () => {
    setTimeout(() => {
      if (fullDescriptionRef && fullDescriptionRef.current) {
        fullDescriptionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }
    }, 100);
  };
  const EditButton: React.FC = () =>
    showEditAction || showDeleteButton ? (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {showEditAction && !challengeLoading ? (
          <CustomButton
            sx={{ width: mdDown ? '100%' : '115px', maxWidth: '328px', marginRight: '16px' }}
            type={'bigLightOrange'}
            text={'Edit'}
            inputProps={{
              variant: 'contained',
              startIcon: <EditIcon width={16} height={16} style={{ fill: Colors.orange[500] }} />,
            }}
            onClick={navigateToDetailPage}
          />
        ) : null}
        {showDeleteButton && !challengeLoading && (
          <CustomButton
            sx={{ width: mdDown ? '100%' : '135px', maxWidth: '328px' }}
            type={'bigLightRed'}
            text={'Delete'}
            inputProps={{
              variant: 'contained',
              startIcon: <DeleteIcon width={16} height={16} style={{ fill: Colors.red[500] }} />,
            }}
            onClick={() => setShowDelete(true)}
          />
        )}
      </Box>
    ) : null;
  const Information: React.FC<{ challenge: Challenge }> = ({ challenge }) => {
    return (
      <>
        <Grid sx={{ display: 'flex', borderBottom: mdDown ? '1px dashed #F2F2F2' : 'none', py: mdDown ? '16px' : 0 }}>
          <Grid sx={{ mr: '16px' }}>
            <Typography sx={{ fontSize: '12px', color: Colors.gray[400], fontWeight: 700, mb: '4px' }}>
              Dates:
            </Typography>
            <CustomBudge
              key={'Dates'}
              Icon={CalendarIcon}
              label={
                !formattedStartDate() || !formattedEndDate()
                  ? 'Open'
                  : `${formattedStartDate()} - ${formattedEndDate()}`
              }
            />
          </Grid>

          <Grid>
            <Typography sx={{ fontSize: '12px', color: Colors.gray[400], fontWeight: 700, mb: '4px' }}>
              Duration:
            </Typography>
            <Box sx={{ borderRight: mdDown ? 'none' : '1px dashed #F2F2F2', pr: mdDown ? 0 : '16px' }}>
              <CustomBudge key={'Duration'} Icon={ClockIcon} label={durationInDays} />
            </Box>
          </Grid>
        </Grid>

        <Grid
          sx={{
            display: 'flex',
            ml: mdDown ? 0 : '16px',
            borderBottom: mdDown ? '1px dashed #F2F2F2' : 'none',
            py: mdDown ? '16px' : 0,
          }}
        >
          <Grid sx={{ mr: '16px' }}>
            <Typography sx={{ fontSize: '12px', color: Colors.gray[400], fontWeight: 700, mb: '4px' }}>
              Dimension:
            </Typography>
            <Grid sx={{ display: 'flex', flexWrap: 'no-wrap' }}>
              {challenge.challengeCharacteristic.challengePillars.map((challengePillar) => {
                return DIMENSION_LIST.map((item, index) =>
                  item.value === challengePillar ? (
                    <Grid
                      key={item.value}
                      sx={{
                        mr:
                          challenge.challengeCharacteristic.challengePillars.length > 1 &&
                          index !== challenge.challengeCharacteristic.challengePillars.length - 1
                            ? '4px'
                            : 0,
                      }}
                      item
                    >
                      <CustomAvatar size={28} bgColor={item.mainColor} borderBg={item.borderColor} borderWidth={1}>
                        <item.Icon width={12} height={12} />
                      </CustomAvatar>
                    </Grid>
                  ) : null,
                );
              })}
            </Grid>
          </Grid>

          <Grid>
            <Typography sx={{ fontSize: '12px', color: Colors.gray[400], fontWeight: 700, mb: '4px' }}>
              Competition:
            </Typography>
            <Box>
              {COMPETITION_LIST.map((item) =>
                item.value === challenge.challengeCharacteristic.competition ? (
                  <CustomBudge
                    key={'Competition'}
                    Icon={item.Icon}
                    boxColor={item.mainColor}
                    borderColor={item.borderColor}
                    bgColor={'#D3DFE0'}
                    textColor={item.mainColor}
                    label={item.label}
                  />
                ) : null,
              )}
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };

  const SecondInformation: React.FC<{ challenge: Challenge }> = ({ challenge }) => {
    return (
      <Grid
        sx={{
          display: 'flex',
          flexDirection: mdDown ? 'column' : 'row',
          mt: mdDown ? '0' : '24px',
        }}
      >
        <Grid
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            padding: mdDown ? '16px 0 0 0' : 0,
            borderTop: mdDown ? '1px dashed #F2F2F2' : 'none',
          }}
        >
          <Grid sx={{ mr: '16px' }}>
            <Typography sx={{ fontSize: '12px', color: Colors.gray[400], fontWeight: 700, mb: '4px' }}>
              Tracking:
            </Typography>
            <Box sx={{ borderRight: mdDown ? 'none' : '1px dashed #F2F2F2', pr: mdDown ? 0 : '16px' }}>
              <Box
                sx={[
                  {
                    padding: '2px',
                    backgroundColor: Colors.gray[100],
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    borderRadius: '24px',
                  },
                ]}
              >
                <Box
                  alignItems={'center'}
                  justifyContent={'center'}
                  display={'flex'}
                  sx={{
                    width: challenge.challengeAction.actionSource === TRACKING_TYPE.HYBRID ? '100%' : 24,
                    height: 24,
                    px: challenge.challengeAction.actionSource === TRACKING_TYPE.HYBRID ? '6px' : 0,
                    backgroundColor: emptyTrophy.mainColor,
                    borderWidth: 1,
                    borderColor: emptyTrophy.borderColor,
                    borderRadius: 100,
                    borderStyle: 'solid',
                  }}
                >
                  {(challenge.challengeAction.actionSource === TRACKING_TYPE.HYBRID ||
                    challenge.challengeAction.actionSource === TRACKING_TYPE.MANUAL) && (
                    <LogIcon width={12} height={12} fill={Colors.white} />
                  )}

                  {challenge.challengeAction.actionSource === TRACKING_TYPE.HYBRID && (
                    <Typography sx={{ fontSize: 12, color: Colors.white, lineHeight: 12 }}>+</Typography>
                  )}
                  {(challenge.challengeAction.actionSource === TRACKING_TYPE.HYBRID ||
                    challenge.challengeAction.actionSource === TRACKING_TYPE.AUTO) && (
                    <WearableIcon width={12} height={12} />
                  )}
                </Box>
                <Typography
                  sx={[
                    {
                      ml: '8px',
                      mr: '12px',
                      color: Colors.gray[700],
                      fontSize: '12px',
                      fontWeight: 700,
                    },
                  ]}
                >
                  {capitalize(challenge.challengeAction.actionSource.toLowerCase())}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            padding: mdDown ? '16px 0 0 0' : 0,
            borderTop: mdDown ? '1px dashed #F2F2F2' : 'none',
            mt: mdDown ? '16px' : 0,
          }}
        >
          <Grid
            sx={{
              mr:
                challenge.challengeAward?.awards.length &&
                challenge.challengeAward?.awards[0].awardType === AWARD_TYPE.OTHER
                  ? '16px'
                  : 0,
            }}
          >
            <Typography sx={{ fontSize: '12px', color: Colors.gray[400], fontWeight: 700, mb: '4px' }}>
              Award:
            </Typography>
            {!challenge.challengeAward || !challenge.challengeAward?.awards.length ? (
              <CustomBudge
                key={'Award'}
                Icon={emptyTrophy.Icon}
                boxColor={emptyTrophy.mainColor}
                borderColor={emptyTrophy.borderColor}
                textColor={Colors.gray[700]}
                label={emptyTrophy.label}
              />
            ) : (
              AWARD_LIST.map((item) =>
                item.value === challenge.challengeAward?.awards[0].awardType ? (
                  <Grid key={item.value} item>
                    <CustomBudge
                      Icon={item.Icon}
                      boxColor={item.mainColor}
                      borderColor={item.borderColor}
                      bgColor={item.backgroundColor}
                      textColor={item.value === AWARD_TYPE.NONE ? Colors.gray[700] : Colors.yellow[600]}
                      label={
                        challenge.challengeAward?.awards[0].points
                          ? challenge.challengeAward.awards[0].points
                          : item.label
                      }
                    />
                  </Grid>
                ) : null,
              )
            )}
          </Grid>
          {challenge.challengeAward?.awards.length &&
            challenge.challengeAward?.awards[0].awardType === AWARD_TYPE.OTHER && (
              <Typography sx={{ fontSize: '14px', color: Colors.gray[500] }}>
                {challenge.challengeAward.awards[0].awardTitle}
              </Typography>
            )}
        </Grid>

        <Grid
          sx={{
            padding: mdDown ? '16px 0 8px 0' : 0,
            ml: mdDown ? 0 : '16px',
            borderTop: mdDown ? '1px dashed #F2F2F2' : 'none',
            mt: mdDown ? '16px' : 0,
          }}
        >
          <Typography
            sx={{ fontSize: '12px', color: Colors.gray[400], fontWeight: 700, mb: '4px', pl: mdDown ? 0 : '16px' }}
          >
            Challenge type:
          </Typography>
          <Box sx={{ display: 'flex', borderLeft: mdDown ? 'none' : '1px dashed #F2F2F2', pl: mdDown ? 0 : '16px' }}>
            <CustomBudge
              key={'Challenge type'}
              Icon={ChallengeType}
              label={CHALLENGE_TYPE_LABEL[challenge.challengeCharacteristic.challengeType as CHALLENGE_TYPE]}
              sx={{ marginRight: '8px' }}
            />
            <CustomBudge
              key={'Winner'}
              Icon={Winner}
              label={
                challenge.challengeAward?.awardTrigger === AWARD_TRIGGER.COMPLETION
                  ? 'All'
                  : capitalize(challenge.challengeAward?.awardTrigger.toLowerCase())
              }
              sx={{ marginRight: '8px' }}
            />
            {!(
              challenge.challengeCharacteristic.competition === COMPETITION.INDIVIDUAL &&
              challenge.challengeCharacteristic.calculationType === CALCULATION_TYPE.NONE
            ) && (
              <CustomBudge
                key={'Sum'}
                Icon={Sum}
                label={
                  challenge.challengeCharacteristic.competition === COMPETITION.TEAM &&
                  challenge.challengeCharacteristic.calculationType === CALCULATION_TYPE.NONE
                    ? 'Self Performance'
                    : capitalize(challenge.challengeCharacteristic.calculationType.toLowerCase())
                }
              />
            )}
          </Box>
        </Grid>
      </Grid>
    );
  };

  const Participants: React.FC<{ challenge: Challenge }> = ({ challenge }) => {
    return (
      <>
        <Box>
          {challenge && (
            <Box sx={{ mb: '20px' }}>
              {challenge.challengeCharacteristic.challengeType === CHALLENGE_TYPE.TARGET &&
                challenge.challengeGoal?.totalGoalValue && (
                  <Grid sx={{ display: 'flex', alignItems: 'center', mt: '8px' }}>
                    <CustomBudge
                      key={'team'}
                      Icon={TotalIcon}
                      label={challenge.challengeGoal.totalGoalValue.toString()}
                      sx={{ width: 'fit-content' }}
                      labelSx={{ minWidth: '17px' }}
                    />
                    <Typography
                      sx={{ fontSize: '12px', color: Colors.gray[700], fontWeight: 700, ml: '16px', lineHeight: 1 }}
                    >
                      Total goal
                    </Typography>
                  </Grid>
                )}
              {challenge.challengeGoal?.daysGoalValue &&
                (challenge.challengeCharacteristic.challengeType === CHALLENGE_TYPE.LONGER_STREAK ||
                  challenge.challengeCharacteristic.challengeType === CHALLENGE_TYPE.STICK_TO_IT) && (
                  <Grid sx={{ display: 'flex', alignItems: 'center', mt: '8px' }}>
                    <CustomBudge
                      key={'team'}
                      Icon={DailyIcon}
                      label={challenge.challengeGoal.daysGoalValue.toString()}
                      sx={{ width: 'fit-content' }}
                      labelSx={{ minWidth: '17px' }}
                    />
                    <Typography sx={{ fontSize: '12px', color: Colors.gray[700], fontWeight: 700, ml: '16px' }}>
                      Daily goal
                    </Typography>
                  </Grid>
                )}
              {'maxTeamSize' in challenge.challengeParticipation &&
                challenge.challengeParticipation.maxTeamSize !== undefined && (
                  <Grid sx={{ display: 'flex', alignItems: 'center', mt: '8px' }}>
                    <CustomBudge
                      key={'team'}
                      Icon={TeamIcon}
                      label={challenge.challengeParticipation.maxTeamSize.toString()}
                      sx={{ width: 'fit-content' }}
                      labelSx={{ minWidth: '17px' }}
                    />
                    <Typography sx={{ fontSize: '12px', color: Colors.gray[700], fontWeight: 700, ml: '16px' }}>
                      Max team size
                    </Typography>
                  </Grid>
                )}
              {challenge.challengeAward &&
                'awardTriggerLimit' in challenge.challengeAward &&
                challenge.challengeAward.awardTriggerLimit !== undefined &&
                challenge.challengeAward.awardTriggerLimit !== null &&
                challenge.challengeAward &&
                challenge.challengeAward.awardTriggerLimit >= 0 &&
                challenge.challengeAward.awardTrigger !== AWARD_TRIGGER.COMPLETION && (
                  <Grid sx={{ display: 'flex', alignItems: 'center', mt: '8px' }}>
                    <CustomBudge
                      key={'awardTriggerLimit'}
                      Icon={StarIcon}
                      label={challenge.challengeAward.awardTriggerLimit.toString()}
                      sx={{ width: 'fit-content' }}
                      labelSx={{ minWidth: '17px' }}
                    />
                    <Typography sx={{ fontSize: '12px', color: Colors.gray[700], fontWeight: 700, ml: '16px' }}>
                      Number of winners
                    </Typography>
                  </Grid>
                )}
            </Box>
          )}

          <Typography sx={{ fontSize: '12px', color: Colors.gray[400], fontWeight: 700 }}>Participants:</Typography>

          {participants.length ? (
            <Box sx={{ mt: '12px', mb: challenge.teamsAvatars && challenge.teamsAvatars.length ? '20px' : 0 }}>
              <CustomAvatarList
                sx={{ cursor: 'pointer' }}
                challenge={challenge}
                type={'lg'}
                onClick={() => setShowSeparateParticipantsModal(true)}
              />
            </Box>
          ) : (
            <Grid sx={{ display: 'flex', alignItems: 'center', pl: '10px', mt: '20px' }}>
              <EmptyParticipantsIcon width={86} height={71} />
              <Typography sx={{ fontSize: '14px', color: Colors.gray[400], fontWeight: 600, ml: '10px' }}>
                Be the first to join!
              </Typography>
            </Grid>
          )}

          {challenge.teamsAvatars && challenge.teamsAvatars.length ? (
            <Box>
              <Typography sx={{ fontSize: '12px', color: Colors.gray[400], fontWeight: 700 }}>Teams:</Typography>
              <Box sx={{ mt: '12px', mb: challenge.metadata.slackURL ? '20px' : 0 }}>
                <CustomAvatarList
                  sx={{ cursor: 'pointer' }}
                  isTeam={true}
                  challenge={challenge}
                  type={'lg'}
                  onClick={handleOpenParticipant(true)}
                />
              </Box>
            </Box>
          ) : null}
        </Box>
        {challenge.metadata.slackURL && (
          <Box sx={{ pt: '16px', mt: '20px', borderTop: '1px dashed #F2F2F2' }}>
            <Typography sx={{ fontSize: '12px', color: Colors.gray[400], fontWeight: 700, mb: '8px' }}>
              Discussion board:
            </Typography>

            <RoundIconButton
              sx={{
                marginBottom: {
                  xs: '4px',
                  sm: 0,
                  width: { xs: '100%', sm: 'auto' },
                },
                alignSelf: { xs: 'stretch', sm: 'unset' },
                backgroundColor: Colors.gray[50],
                width: '100%',
              }}
              Icon={SlackIcon}
              size={36}
              background={Colors.white}
              roundColor={Colors.white}
              textColor={Colors.gray[600]}
              roundBorderColor={Colors.gray[150]}
              textSx={{
                fontSize: '14px',
                maxWidth: '150px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
              label={challengeTitle}
              isDisable={!challenge.challengeParticipation.isUserParticipant}
              onClick={() =>
                challenge.metadata.slackURL && challenge.challengeParticipation.isUserParticipant
                  ? window.open(challenge.metadata.slackURL)
                  : undefined
              }
            />
          </Box>
        )}
      </>
    );
  };
  const SkeletonContent: React.FC = () => {
    return mdDown ? (
      <Grid sx={{ position: 'relative', width: '100%', marginRight: '24px' }}>
        <Skeleton variant="rectangular" height={'160px'} width={'100%'} sx={{ borderRadius: '20px' }} />

        <Box sx={{ width: '100%', mt: '26px' }}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box>
        <Skeleton variant="rectangular" height={'54px'} sx={{ borderRadius: '20px', margin: '32px auto 0 auto' }} />
        <Skeleton variant="rectangular" height={'54px'} sx={{ borderRadius: '20px', margin: '18px auto 0 auto' }} />
      </Grid>
    ) : (
      <Grid container display={'flex'} wrap={'nowrap'}>
        <Grid sx={{ position: 'relative', width: '100%', marginRight: '24px' }}>
          <Skeleton variant="rectangular" height={'324px'} width={'100%'} sx={{ borderRadius: '20px' }} />

          <Skeleton
            variant="rectangular"
            height={'92px'}
            width={'90%'}
            sx={{ borderRadius: '20px', margin: '-46px auto 0 auto' }}
          />

          <Box sx={{ width: '100%', mt: '26px' }}>
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
          </Box>
        </Grid>
        <Grid
          sx={{
            width: '276px',
          }}
        >
          <Skeleton variant="rectangular" height={'212px'} width={'276px'} sx={{ borderRadius: '20px' }} />
          <Skeleton variant="rectangular" height={'46px'} width={'100%'} sx={{ mt: '40px', borderRadius: '100px' }} />
        </Grid>
      </Grid>
    );
  };

  return (
    <IonPage>
      <CustomNewHeader
        breadcrumb={{
          Icon: GoalIcon,
          list: [
            { label: "Your Employees' Challenges", url: '/page/employees-challenges' },
            ...challengeStatusBreadcrumbItem,
            ...progressBreadcrumbItem(),
            { label: `Detailed:${challengeTitle}`, url: '/' },
          ],
        }}
        inProgress={challengeLoading}
        label={challengeTitle}
      >
        {!mdDown && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <EditButton />
          </Box>
        )}
      </CustomNewHeader>

      <IonContent style={{ '--padding-bottom': '36px' }} scrollEvents={true}>
        <Container maxWidth={'lg'}>
          {challengeLoading ? (
            <SkeletonContent />
          ) : challenge ? (
            <Grid container display={'flex'} wrap={'nowrap'}>
              <Grid sx={{ position: 'relative', width: '100%', marginRight: mdDown ? 0 : '24px' }}>
                <CardMedia
                  sx={mdDown ? styles.mdCardImage : styles.cardImage}
                  image={challenge.challengeInfo.imageUrl ?? IconPlaceholder}
                />
                <CardLabel challenge={challenge} type={'lg'} sxContainer={styles.labelStatusPosition} />

                {!mdDown && (
                  <Grid
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '21px 16px',
                      background: Colors.white,
                      borderLeft: '5px solid #B573E2',
                      boxShadow: '5px 0px 15px rgba(108, 123, 132, 0.05)',
                      borderRadius: '20px',
                      margin: '-46px 32px 0 32px',
                    }}
                  >
                    <Grid
                      sx={{
                        display: 'flex',
                        alignItems: 'flex-end',
                      }}
                    >
                      <Information challenge={challenge} />
                    </Grid>
                    <SecondInformation challenge={challenge} />
                  </Grid>
                )}

                <Grid
                  sx={{
                    padding: mdDown ? '16px' : '24px',
                    mt: mdDown ? '32px' : '26px',
                    mb: '32px',
                    backgroundColor: '#F7F9F9',
                    borderRadius: '20px',
                  }}
                >
                  <Typography sx={{ fontSize: mdDown ? '14px' : '16px', color: Colors.gray[500] }}>
                    {challenge.challengeInfo.shortDescription}
                  </Typography>

                  {!expanded ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        maxWidth: '150px',
                        cursor: 'pointer',
                        mt: mdDown ? '4px' : 0,
                      }}
                      onClick={() => {
                        handleExpandClick();
                        executeScrollToDescription();
                      }}
                    >
                      <Link sx={{ fontSize: mdDown ? '14px' : '16px', color: Colors.blue[500], mr: '4px' }}>
                        Read more...
                      </Link>
                      <ArrowIcon
                        height={16}
                        width={16}
                        fill={Colors.blue[500]}
                        style={{ transform: 'rotate(-90deg)' }}
                      />
                    </Box>
                  ) : (
                    <Box sx={{ height: 17, mt: mdDown ? '4px' : 0 }} />
                  )}
                </Grid>

                {mdDown && (
                  <Box sx={{ mb: '45px' }}>
                    <EditButton />
                  </Box>
                )}

                {mdDown && (
                  <Box>
                    <CollapseBox title={'Information'} borderColor={Colors.magenta[300]}>
                      <Information challenge={challenge} />
                      <SecondInformation challenge={challenge} />
                    </CollapseBox>
                    <CollapseBox title={'Setup information'} borderColor={Colors.green[500]} sx={{ mt: '18px' }}>
                      <Box sx={{ mt: '16px', mb: '8px' }}>
                        <Participants challenge={challenge} />
                      </Box>
                    </CollapseBox>
                  </Box>
                )}

                <Box ref={fullDescriptionRef}>
                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                    {challenge && (
                      <Typography
                        sx={{
                          mt: mdDown ? '40px' : 0,
                          fontSize: mdDown ? '14px' : '16px',
                          color: Colors.gray[500],
                          '& > * iframe': {
                            border: 'none',
                            ...(mdDown && { width: '100%', height: 'calc(80vw * 0.75)', maxHeight: '315px' }),
                          },
                        }}
                        dangerouslySetInnerHTML={{ __html: challenge.challengeInfo.description }}
                      />
                    )}

                    {expanded && (
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', mt: '16px' }}
                        onClick={() => handleExpandClick()}
                      >
                        <Link sx={{ fontSize: mdDown ? '14px' : '16px', color: Colors.blue[500], mr: '4px' }}>
                          View less
                        </Link>
                        <ArrowIcon
                          height={16}
                          width={16}
                          fill={Colors.blue[500]}
                          style={{ transform: 'rotate(90deg)' }}
                        />
                      </Box>
                    )}
                  </Collapse>
                </Box>
              </Grid>
              {!mdDown && (
                <Grid>
                  <Grid
                    sx={{
                      width: '276px',
                      padding: '24px 16px',
                      backgroundColor: Colors.white,
                      borderRadius: '20px',
                      borderTop: `5px solid ${Colors.green[500]}`,
                      boxShadow: '5px 0px 15px rgba(108, 123, 132, 0.05)',
                    }}
                  >
                    <Typography sx={{ fontSize: '16px', color: Colors.gray[700], fontWeight: 700, mb: '16px' }}>
                      Setup information
                    </Typography>
                    <Participants challenge={challenge} />
                  </Grid>
                </Grid>
              )}
            </Grid>
          ) : null}
        </Container>

        <ChallengeDeleteModal
          show={showDelete}
          loading={deleteLoading}
          onClose={() => setShowDelete(false)}
          onDelete={deleteRequest}
        />

        <ChallengeParticipantsModal
          show={showSeparateParticipantsModal}
          divRef={divParticipantsRef}
          isAdmin={true}
          isUserParticipant={challenge ? challenge.challengeParticipation.isUserParticipant : false}
          isParticipationLocked={challenge ? challenge.challengeParticipation.isParticipationLocked : false}
          participants={participants}
          loading={participantsLoading}
          isTeam={false}
          teams={teams}
          handleScrollEvent={handleScrollEvent}
          onClose={() => setShowSeparateParticipantsModal(false)}
          handleExport={handleExportParticipants}
        />

        <ChallengeParticipantsModal
          show={showParticipantsModal}
          divRef={divTeamsRef}
          isUserParticipant={challenge ? challenge.challengeParticipation.isUserParticipant : false}
          isParticipationLocked={challenge ? challenge.challengeParticipation.isParticipationLocked : false}
          participants={participants}
          loading={teamsLoading}
          isTeam={isModalTeam}
          teams={teams}
          handleScrollEvent={handleTeamScrollEvent}
          onClose={() => setShowParticipantsModal(false)}
          handleExport={isModalTeam ? handleExportTeams : handleExportParticipants}
        />
      </IonContent>
    </IonPage>
  );
};
