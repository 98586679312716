import { Capacitor } from '@capacitor/core';
import { postTrackAccess, postTrackContent, postTrackVideo } from '../lib/api/http/requests/track';

type TrackTypes = 'CONTENT' | 'VIDEOS' | 'LOGIN' | 'FOREGROUND';

interface HandlerTrackEventInterface {
  (props: { type: TrackTypes; uuid?: string; videoAction?: 'STARTED' | 'COMPLETED' }): void;
}

const clientTypes: { [key: string]: string } = {
  web: 'WEB',
  ios: 'IOS',
  android: 'ANDROID',
};

const toIsoString = (date: Date) => {
  const tzo = -date.getTimezoneOffset();
  const dif = tzo >= 0 ? '+' : '-';
  const pad = (num: number) => {
    return (num < 10 ? '0' : '') + num;
  };

  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    dif +
    pad(Math.floor(Math.abs(tzo) / 60)) +
    ':' +
    pad(Math.abs(tzo) % 60)
  );
};

export const handlerTrackEvent: HandlerTrackEventInterface = async ({ type, uuid, videoAction }) => {
  const platform = Capacitor.getPlatform();
  const date = toIsoString(new Date());
  switch (type) {
    case 'CONTENT': {
      if (!uuid) return;
      await postTrackContent({ contentUUID: uuid, date });
      return;
    }
    case 'VIDEOS': {
      if (!uuid || !videoAction) return;
      await postTrackVideo({ videoUUID: uuid, action: videoAction, date });
      return;
    }
    case 'LOGIN': {
      if (!platform) return;
      await postTrackAccess({ clientType: clientTypes[platform], accessOrigin: 'LOGIN', date });
      return;
    }
    case 'FOREGROUND': {
      if (!platform) return;
      await postTrackAccess({ clientType: clientTypes[platform], accessOrigin: 'FOREGROUND', date });
      return;
    }
    default:
      return;
  }
};
