import { Redirect, Route } from 'react-router-dom';
import { FocusPage } from '../pages/features/FocusPage';
import { ToolboxPage } from '../pages/ToolboxPage/ToolboxPage';
import { JourneyPage } from '../pages/JourneyPage';
import RewardsPage from '../pages/RewardsPage';
import ProfilePage from '../pages/ProfilePage';
import { IonPage, IonRouterOutlet, useIonRouter } from '@ionic/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Layout from '../components/structure/Layout';
import EmployeesRewardsPage from '../pages/EmployeesRewardsPage';
import { EmployeesPoints } from '../pages/EmployeesPointPage';
import EmployeesPage from '../pages/EmployeesPage';
import JourneyAdminPage from '../pages/JourneyAdminPage';
import { FocusAdminPage } from '../pages/features/FocusAdminPage/FocusAdminPage';
import SupportPage from '../pages/SupportPage';
import { ChallengeDetailPage } from '../pages/ChallengeDetailPage';
import { TeamDetailPage } from '../pages/TeamDetailPage';
import ChallengesViewAllPage from '../pages/ChallengesViewAllPage';
import { ChallengesPage } from '../pages/ChallengesPage';
import { ChallengesAdminPage } from '../pages/ChallengesAdminPage';
import ChallengesAdminViewAllPage from '../pages/ChallengesAdminViewAllPage';
import TemplatesAdminPage from '../pages/TemplatesAdminPage';
import { TemplateAdminDetailPage } from '../pages/TemplateAdminDetailPage';
import { ChallengeAdminDetailPage } from '../pages/ChallengeAdminDetailPage';
import { EditChallengePage } from '../pages/EditChallengePage';
import { NotificationsPage } from '../pages/NotificationsPage';
import { NotificationsSettingsPage } from '../pages/NotificationsSettingsPage';
import TrophyLibraryPage from '../pages/TrophyLibraryPage';
import TestResultsPage from '../pages/TestResultsPage';
import { MentalAssessmentPage } from '../pages/MentalAssessmentPage';
import { PointsPage } from '../pages/PointsPage';
import { getRole } from '../utils/getRole';
import { AdminRoutes } from '../models/AdminRoutes';
import CustomFab from '../components/structure/CustomFab';
import MetricsModal from '../components/modals/MetricsModal';
import { RootState } from '../redux/store';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { openNotification, setHandleNotificationCallback, syncNotificationToken } from '../redux/slices/appSlice';
import { asyncGetIsUserEligible, setShowMentalAssessmentModal } from '../redux/slices/mentalAssessmentSlice';
import { UseIonRouterResult } from '@ionic/react/dist/types/components/IonRouterContext';
import { useDispatch, useSelector } from 'react-redux';
import LoginPage from '../pages/LoginPage';
import { Capacitor } from '@capacitor/core';
import EmployeesStepsPage from '../pages/EmployeesStepsPage';
import ChallengeActivityPage from '../pages/ChallengeActivityPage';
import { ChallengesAdminResultsPage } from '../pages/ChallengesAdminResultsPage';
import { NOTIFICATION_ACTION_SOURCE } from '../models/enum/NOTIFICATION_ACTION_SOURCE';
import { MetricsLogDataPage } from '../pages/MetricsLogDataPage';
import moment from 'moment';
import { asyncGetUserJourneyCards } from '../redux/slices/journeyPageSlice';
import { StartRedemptionPage } from '../pages/StartRedemptionPage';
import { asyncGetUserPoints } from '../redux/slices/profileSlice';
import { handlerTrackEvent } from '../utils/handlerTrackEvent';
import { MentalAssessmentModal } from '../components/features/modals/MentalAssessmentModal';

const ProtectedRoutes: React.FC = () => {
  const router = useIonRouter();
  const dispatch = useDispatch();

  const [notifToken, setNotifToken] = useState<string>('');

  const { authToken, notLoggined, handleNotificationCallback } = useSelector((state: RootState) => state.app);
  const { showMentalAssessmentModal } = useSelector((state: RootState) => state.mentalAssessmentSlice);
  const { wifiConnected } = useSelector((state: RootState) => state.app);
  const { showCustomFab } = useSelector((state: RootState) => state.app);

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return;

    if (authToken && notifToken) {
      dispatch(syncNotificationToken({ fcmToken: notifToken }));
    }
  }, [authToken, dispatch, notifToken]);

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return;
    router.push('/', 'root', 'replace', {});
  }, [authToken]);

  const registerPushNotifications = useCallback((router: UseIonRouterResult) => {
    // Register with Apple / Google to receive push via APNS/FCM
    PushNotifications.register().catch(console.error);

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration', (token: Token) => {
      if (token?.value) setNotifToken(token.value);
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', console.error);

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
      // console.log('Push Notification received ' + JSON.stringify(notification));
      dispatch(
        openNotification({
          text: notification.body ?? '',
          category: notification?.data?.category ?? '',
          challengeUuid: notification?.data?.challenge_uuid ?? '',
          challengeActionSource: notification?.data?.challenge_action_source ?? '',
          isUser: notification?.data?.isUser === 'true',
          isParticipant: notification?.data?.isParticipant === 'true',
        }),
      );
    });

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed', (actionPerformed: ActionPerformed) => {
      // console.log('Push Notification action performed: ' + JSON.stringify(actionPerformed));
      handlePushNotification(actionPerformed?.notification, router);
    });
  }, []);

  const removePushNotifications = useCallback(() => {
    PushNotifications.removeAllListeners().catch(console.error);
  }, []);

  useEffect(() => {
    if (Capacitor.isNativePlatform() && wifiConnected) {
      PushNotifications.checkPermissions().then((res) => {
        if (res.receive !== 'granted') {
          PushNotifications.requestPermissions().then((res) => {
            if (res.receive === 'denied') {
              console.log('Push Notification permission denied');
            } else {
              console.log('Push Notification permission granted');
              registerPushNotifications(router);
            }
          });
        } else {
          registerPushNotifications(router);
        }
      });
    }

    return () => {
      if (Capacitor.isNativePlatform()) {
        removePushNotifications();
      }
    };
  }, [wifiConnected]);

  const handlePushNotification = (notification: PushNotificationSchema, router: UseIonRouterResult) => {
    const isAdmin = !(notification?.data?.isUser === 'true');
    const isParticipant = notification?.data?.isParticipant === 'true';
    const challengeUUID = notification?.data?.challenge_uuid;
    const actionSource = notification?.data?.challenge_action_source;
    switch (notification?.data?.category) {
      case 'AABOMD':
        break;
      case 'AABOM':
        if (isAdmin) dispatch(setHandleNotificationCallback(() => router.push('/page/employees-focus')));
        return;
      case 'AUBOW':
        break;
      case 'AABOW':
        break;
      case 'AUBOMD':
        dispatch(setHandleNotificationCallback(() => router.push('/page/your-focus')));
        return;
      case 'RAEOM':
        if (isAdmin) dispatch(setHandleNotificationCallback(() => router.push('/page/employees-journey')));
        return;
      case 'RAEOW':
        break;
      case 'RUEOW':
        dispatch(setHandleNotificationCallback(() => router.push('/page/your-journey')));
        return;
      case 'CJOI':
        if (challengeUUID) {
          dispatch(
            setHandleNotificationCallback(() => router.push(`/page/your-challenges/challenge/${challengeUUID}`)),
          );
        }
        break;
      case 'TMIS':
        if (challengeUUID) {
          dispatch(
            setHandleNotificationCallback(() => router.push(`/page/your-challenges/challenge/${challengeUUID}`)),
          );
        }
        break;
      case 'CUPD':
        if (challengeUUID) {
          dispatch(
            setHandleNotificationCallback(() =>
              router.push(`/page/your-challenges/challenge/${challengeUUID}?date=${new Date()}`),
            ),
          );
        }
        break;
      case 'CACT':
        // challenge datail page
        if (challengeUUID) {
          dispatch(
            setHandleNotificationCallback(() => router.push(`/page/your-challenges/challenge/${challengeUUID}`)),
          );
        }
        break;
      case 'DWEE':
        if (actionSource === NOTIFICATION_ACTION_SOURCE.MANUAL) {
          dispatch(
            setHandleNotificationCallback(() =>
              router.push(`/page/your-challenges/challenge/${challengeUUID}/activity`),
            ),
          );
        }
        if (actionSource === NOTIFICATION_ACTION_SOURCE.AUTO || actionSource === NOTIFICATION_ACTION_SOURCE.HYBRID) {
          dispatch(setHandleNotificationCallback(() => router.push('/page/your-profile')));
        }
        break;
      case 'UDAL':
        dispatch(
          setHandleNotificationCallback(() => router.push(`/page/your-challenges/challenge/${challengeUUID}/activity`)),
        );
        break;
      case 'CWEE':
        // challenge progress page
        dispatch(
          setHandleNotificationCallback(() => router.push(`/page/your-challenges/challenge/${challengeUUID}/activity`)),
        );
        break;
      case 'CFIN':
        // challenge activity page
        dispatch(
          setHandleNotificationCallback(() => router.push(`/page/your-challenges/challenge/${challengeUUID}/activity`)),
        );
        break;
      case 'CSTR':
        isAdmin
          ? dispatch(
              setHandleNotificationCallback(() =>
                router.push(`/page/employees-challenges/challenge/${challengeUUID}/results`),
              ),
            )
          : isParticipant
          ? dispatch(
              setHandleNotificationCallback(() =>
                router.push(`/page/your-challenges/challenge/${challengeUUID}/activity`),
              ),
            )
          : dispatch(
              setHandleNotificationCallback(() => router.push(`/page/your-challenges/challenge/${challengeUUID}`)),
            );
        break;
      case 'CEND':
        // Insert Admin challenge results page link]
        isAdmin
          ? dispatch(
              setHandleNotificationCallback(() =>
                router.push(`/page/employees-challenges/challenge/${challengeUUID}/results`),
              ),
            )
          : dispatch(
              setHandleNotificationCallback(() =>
                router.push(`/page/your-challenges/challenge/${challengeUUID}/activity`),
              ),
            );
        break;

      // case 'UTRG':
      // case 'CDEL':
      // case 'TDEL':
      // case 'TTRG':
      //   // nothing to do
      //   break;
    }
  };

  const getAdminRoutes = () => {
    // A check if a non-admin tries to access ADMIN pages
    if (getRole() === 'USER' && AdminRoutes.includes(router.routeInfo.pathname)) {
      router.push('/page/your-focus');
      return;
    }
  };
  getAdminRoutes();

  const getRedirect = useMemo(() => {
    if (Capacitor.isNativePlatform() && !authToken) {
      return <Redirect to={'/login'} />;
    }

    if (router.routeInfo.pathname === '/page/your-profile') {
      return <Redirect to="/page/your-profile" />;
    }
    if (getRole() === 'ADMIN') {
      return <Redirect to="/page/employees-journey" />;
    } else {
      return <Redirect to="/page/your-focus" />;
    }
  }, [authToken, router.routeInfo.search]);

  useEffect(() => {
    if (authToken && handleNotificationCallback) {
      handleNotificationCallback();
      dispatch(setHandleNotificationCallback(undefined));
    }
  }, [authToken, handleNotificationCallback]);

  useEffect(() => {
    if (authToken) {
      dispatch(asyncGetUserJourneyCards({ date: moment().format('YYYY-MM-DD') }));
      dispatch(asyncGetUserPoints());
      dispatch(asyncGetIsUserEligible());
    }
  }, [authToken, dispatch]);

  useEffect(() => {
    dispatch(setShowMentalAssessmentModal(false));
  }, [router.routeInfo.pathname]);

  //TODO: is removed if the LOGIN event will only be used in onAuthSuccess
  // useEffect(() => {
  //   if (!authToken && !notLoggined && Capacitor.isNativePlatform()) {
  //     const timeoutSetNotLoggined = setTimeout(() => dispatch(setNotLoggined(true)), 600);
  //     return () => clearTimeout(timeoutSetNotLoggined);
  //   }
  // }, [authToken, notLoggined, dispatch]);

  // useEffect(() => {
  //   const isNativePlatform = Capacitor.isNativePlatform();

  //   if (isNativePlatform && authToken && notLoggined) {
  //     handlerTrackEvent({ type: 'LOGIN' });
  //     dispatch(setNotLoggined(false));
  //   }
  // }, [notLoggined, authToken, dispatch]);

  return (
    <IonRouterOutlet animated={false}>
      <Route path="/" exact={true}>
        {getRedirect}
      </Route>
      <Route path="/login" exact={true}>
        {Capacitor.isNativePlatform() && <LoginPage />}
      </Route>
      <Route path={'/page'}>
        <IonPage>
          <Layout>
            <IonRouterOutlet id="main" animated={false}>
              <Route path="/page/your-focus" exact={true}>
                <FocusPage />
              </Route>
              <Route path="/page/your-toolbox" exact={true}>
                <ToolboxPage />
              </Route>
              <Route path="/page/your-journey" exact={true}>
                <JourneyPage />
              </Route>
              <Route path="/page/your-profile" exact={true}>
                <ProfilePage />
              </Route>
              <Route path="/page/our-support" exact={true}>
                <SupportPage />
              </Route>
              <Route path="/page/your-challenges" exact={true}>
                <ChallengesPage />
              </Route>
              <Route path="/page/trophy-library" exact={true}>
                <TrophyLibraryPage />
              </Route>
              <Route
                path="/page/your-challenges/challenge/:id"
                exact={true}
                render={(props) => {
                  return <ChallengeDetailPage {...props} />;
                }}
              />
              <Route
                path="/page/your-challenges/challenge/:id/team/:teamId"
                exact={true}
                render={(props) => {
                  return <TeamDetailPage {...props} />;
                }}
              />
              <Route
                path="/page/your-challenges/challenge/:id/activity"
                exact={true}
                render={(props) => <ChallengeActivityPage {...props} />}
              />
              <Route path="/page/your-challenges/ongoing" exact={true}>
                <ChallengesViewAllPage type="ongoing" />
              </Route>
              <Route path="/page/your-challenges/join" exact={true}>
                <ChallengesViewAllPage type="join" />
              </Route>
              <Route path="/page/your-challenges/finished" exact={true}>
                <ChallengesViewAllPage type="finished" />
              </Route>
              <Route path="/page/notifications" exact={true}>
                <NotificationsPage />
              </Route>
              <Route path="/page/notifications-settings" exact={true}>
                <NotificationsSettingsPage />
              </Route>
              <Route path="/page/your-points" exact={true}>
                <PointsPage />
              </Route>
              <Route path="/page/employees-focus" exact={true}>
                {getRole() === 'ADMIN' && <FocusAdminPage />}
              </Route>
              <Route path="/page/employees-journey" exact={true}>
                {getRole() === 'ADMIN' && <JourneyAdminPage />}
              </Route>
              <Route path="/page/employees-rewards" exact={true}>
                {getRole() === 'ADMIN' && <EmployeesPoints />}
              </Route>
              <Route path="/page/employees-rewards/redemption" exact={true}>
                {getRole() === 'ADMIN' && <StartRedemptionPage />}
              </Route>
              <Route path="/page/employees-steps" exact={true}>
                {getRole() === 'ADMIN' && <EmployeesStepsPage />}
              </Route>
              <Route path="/page/employees" exact={true}>
                {getRole() === 'ADMIN' && <EmployeesPage />}
              </Route>
              <Route path="/page/employees-challenges" exact={true}>
                {getRole() === 'ADMIN' && <ChallengesAdminPage />}
              </Route>
              <Route path="/page/employees-challenges/ongoing" exact={true}>
                <ChallengesAdminViewAllPage type="ongoing" />
              </Route>
              <Route path="/page/employees-challenges/join" exact={true}>
                <ChallengesAdminViewAllPage type="join" />
              </Route>
              <Route path="/page/employees-challenges/finished" exact={true}>
                <ChallengesAdminViewAllPage type="finished" />
              </Route>
              <Route path="/page/employees-challenges/challenge-templates" exact={true}>
                <TemplatesAdminPage />
              </Route>
              <Route path="/page/mental-assessment-results" exact={true}>
                <TestResultsPage />
              </Route>
              <Route path="/page/mental-assessment" exact={true}>
                <MentalAssessmentPage />
              </Route>
              <Route
                path="/page/employees-challenges/challenge-templates/template/:id"
                exact={true}
                render={(props) => {
                  return getRole() === 'ADMIN' && <TemplateAdminDetailPage {...props} />;
                }}
              />
              <Route
                path="/page/employees-challenges/challenge/:id"
                exact={true}
                render={(props) => {
                  return getRole() === 'ADMIN' && <ChallengeAdminDetailPage {...props} />;
                }}
              />
              <Route
                path="/page/employees-challenges/challenge/:id/results"
                exact={true}
                render={(props) => getRole() === 'ADMIN' && <ChallengesAdminResultsPage {...props} />}
              />
              <Route
                path="/page/employees-challenges/edit-challenge/:id"
                exact={true}
                render={(props) => {
                  return getRole() === 'ADMIN' && <EditChallengePage {...props} />;
                }}
              />
              <Route path="/page/metrics-log-data">
                <MetricsLogDataPage />
              </Route>
            </IonRouterOutlet>
          </Layout>
        </IonPage>
        {Capacitor.isNativePlatform() && !authToken ? null : (
          <>
            {!showCustomFab || router.routeInfo.pathname !== '/page/employees-journey' ? null : <CustomFab />}
            <MetricsModal />
          </>
        )}
        {showMentalAssessmentModal && (
          <MentalAssessmentModal
            show={showMentalAssessmentModal}
            onClose={() => dispatch(setShowMentalAssessmentModal(false))}
            onSubmit={() => dispatch(setShowMentalAssessmentModal(false))}
          />
        )}
      </Route>
    </IonRouterOutlet>
  );
};

export default ProtectedRoutes;
