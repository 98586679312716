import { BADGE } from './enum/BADGE';

export interface Points {
  badge: BADGE;
  moduleTitle: string;
  points: number;
  position: number;
  rankingType: rankingType;
}

export enum rankingType {
  EXPERIENCE = 'EXPERIENCE',
  ACHIEVEMENT = 'ACHIEVEMENT',
}
