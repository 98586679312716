import React from 'react';
import CustomNewHeader from '../../../components/features/CustomNewHeader';
import { Box, Button, Typography } from '@mui/material';
import { ChallengePagination } from '../../../components/features/ChallengePagination';
import { useHandleHeader } from '../hooks/useHandleHeader';
import { ReactComponent as GoalIcon } from '../../../assets/svg/goal.svg';
import { CHALLENGE_SUB_CATEGORY } from '../../../models/enum/CHALLENGE_SUB_CATEGORY';
import { RoundIconButton } from '../../../components/features/RoundIconButton/RoundIconButton';
import { Colors } from '../../../theme/colors';
import { ReactComponent as SlackIcon } from '../../../assets/svg/feature/slack.svg';

export const Header: React.FC = () => {
  const {
    subcategory,
    title,
    nextLink,
    prevLink,
    mdDown,
    hasSlackButton,
    shortDescription,
    openDetailPage,
    openSlack,
  } = useHandleHeader();
  return (
    <CustomNewHeader
      breadcrumb={{
        Icon: GoalIcon,
        list: subcategory
          ? [
              { label: 'Your Challenges', url: '/page/your-challenges' },
              {
                label: subcategory === CHALLENGE_SUB_CATEGORY.ONGOING ? 'Ongoing challenges' : 'Finished challenges',
                url:
                  subcategory === CHALLENGE_SUB_CATEGORY.ONGOING
                    ? '/page/your-challenges/ongoing'
                    : '/page/your-challenges/finished',
              },
              { label: title ?? '', url: '/' },
            ]
          : [],
      }}
      pagination={
        subcategory !== CHALLENGE_SUB_CATEGORY.FINISHED && (
          <ChallengePagination
            title={title ?? ''}
            nextChallengeUUID={nextLink}
            previousChallengeUUID={prevLink}
            isActivity
          />
        )
      }
      label={title ?? ''}
      titleToolTip={shortDescription}
      rightAction={
        <Box display="flex" alignItems="center" sx={{ alignSelf: { xs: 'stretch', md: 'center' }, marginLeft: 'auto' }}>
          {hasSlackButton && (
            <Button
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '36px',
                width: '36px',
                borderRadius: '36px',
                minWidth: 'unset',
                background: Colors.white,
                border: `1px solid ${Colors.gray[150]}`,
                marginRight: '16px',
              }}
              onClick={openSlack}
            >
              <SlackIcon />
            </Button>
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: { xs: 'unset', md: '10px 24px' },
              borderRadius: 100,
              height: { xs: 36, md: 'unset' },
              width: { xs: 36, md: 'fit-content' },
              border: `1px solid #A2E5EC`,
              background: '#D0F2F5',
              marginRight: { xs: 'unset', md: '16px' },
              cursor: 'pointer',
            }}
            onClick={openDetailPage}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: 16,
                justifyContent: 'space-between',
                height: 3,
                marginRight: { xs: 'auto', md: '8px' },
                marginLeft: { xs: 'auto', md: 'unset' },
              }}
            >
              <Box sx={{ height: 3, width: 3, borderRadius: 3, background: '#16BECF' }} />
              <Box sx={{ height: 3, width: 3, borderRadius: 3, background: '#16BECF' }} />
              <Box sx={{ height: 3, width: 3, borderRadius: 3, background: '#16BECF' }} />
            </Box>
            {!mdDown && (
              <Box>
                <Typography
                  sx={{ fontSize: 14, fontWidth: 400, lineHeight: 1, color: '#16BECF', whiteSpace: 'nowrap' }}
                >
                  Details
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      }
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {!mdDown && subcategory !== CHALLENGE_SUB_CATEGORY.FINISHED && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <ChallengePagination
              title={title ?? ''}
              nextChallengeUUID={nextLink}
              previousChallengeUUID={prevLink}
              isActivity
            />
          </Box>
        )}
      </Box>
    </CustomNewHeader>
  );
};
