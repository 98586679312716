import { getRequest, postRequest, putRequest } from '../../../../services/HttpService';
import { CHALLENGE_STATUS } from '../../../../models/enum/CHALLENGE_STATUS';
import { COMPETITION } from '../../../../models/enum/COMPETITION';
import { DIMENSION } from '../../../../models/enum/DIMENSION';

export const getChallengesRequest = (params: {
  page?: number;
  size?: number;
  metric?: string;
  searchText: string;
  challengeStatus?: CHALLENGE_STATUS[];
  challengePillars?: DIMENSION[];
  competition?: COMPETITION[];
  onlyCorporate?: boolean;
}) => {
  const search: {
    queryField: 'challengePillars' | 'competition' | 'metricType' | 'challengeStatus' | 'title';
    queryValues: string[] | string;
  }[] = [];

  if (params.challengeStatus && params.challengeStatus.length > 0) {
    search.push({
      queryField: 'challengeStatus',
      queryValues: params.challengeStatus,
    });
  }

  if (params.challengePillars && params.challengePillars.length > 0) {
    search.push({ queryField: 'challengePillars', queryValues: params.challengePillars });
  }

  if (
    params.onlyCorporate &&
    params.competition &&
    params.competition.find((item) => item === COMPETITION.TEAM) &&
    params.competition.find((item) => item === COMPETITION.INDIVIDUAL)
  ) {
    search.push({ queryField: 'competition', queryValues: [COMPETITION.INDIVIDUAL, COMPETITION.TEAM] });
  } else if (
    params.onlyCorporate &&
    params.competition &&
    params.competition.find((item) => item === COMPETITION.TEAM)
  ) {
    search.push({ queryField: 'competition', queryValues: [COMPETITION.TEAM] });
  } else if (
    params.onlyCorporate &&
    params.competition &&
    params.competition.find((item) => item === COMPETITION.INDIVIDUAL)
  ) {
    search.push({ queryField: 'competition', queryValues: [COMPETITION.INDIVIDUAL] });
  } else if (params.onlyCorporate) {
    search.push({ queryField: 'competition', queryValues: [COMPETITION.INDIVIDUAL, COMPETITION.TEAM] });
  }

  if (params.competition && params.competition.length > 0 && !params.onlyCorporate) {
    search.push({ queryField: 'competition', queryValues: params.competition });
  }
  if (params.searchText.trim().length > 0) {
    search.push({ queryField: 'title', queryValues: [params.searchText.trim()] });
  }
  if (params.metric && params.metric.trim().length > 0) {
    search.push({ queryField: 'metricType', queryValues: [params.metric] });
  }
  return postRequest(`/gamification-service/user/challenge/query?page=${params.page || 0}&size=${params.size || 10}`, {
    search,
  });
};

export const joinToChallenge = (params: {
  challengeUUID?: string;
  templateChallengeUUID?: string;
  action?: string;
  startDate?: string;
  endDate?: string;
}) => {
  return postRequest('/gamification-service/challenge/join', { ...params, userNotificationInfo: {} });
};

export const getChallengeMetrics = () => {
  return getRequest('/gamification-service/metrics');
};
export const abortChallenge = (params: { challengeUUID?: string; templateChallengeUUID?: string }) => {
  return putRequest('/gamification-service/challenge/abort', {}, { ...params });
};

export const getChallenge = (params: { challengeUUID?: string; templateChallengeUUID?: string }) => {
  return postRequest('/gamification-service/challenge/details', { ...params });
};
export const getChallengeParticipants = (params: {
  challengeUUID?: string;
  templateChallengeUUID?: string;
  page?: number;
  size?: number;
}) => {
  return postRequest(
    `/gamification-service/challenge/participants?page=${params.page || 0}&size=${params.size || 10}`,
    { ...params },
  );
};
export const getChallengeProgress = (params: { challengeUUID: string }) =>
  getRequest('/gamification-service/user/challenge/progress', { ...params });

export const getChallengeLeaderBoard = (params: { challengeUUID: string; page: string; size: string }) =>
  getRequest('/gamification-service/user/progress/ranking', { ...params });

export const setChallengeHabit = (params: { date: string; challengeUUID: string; habitValue: boolean }) =>
  postRequest('/gamification-service/score/habit', params);

export const getChallengeTeams = (params: { challengeUUID: string; page?: number; size?: number }) =>
  getRequest('/gamification-service/challenge/teams', params);

export const getChallengeWinWeekly = (data: { moduleOrder: number | null; periodIndex: number | null }) =>
  postRequest('/gamification-service/challenge/win_weekly', data);
