import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Slider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import StyledButton from '../common/Button';
import InputField from '../common/CustomTextField';
import { FileRejection, useDropzone } from 'react-dropzone';
import { IAccountInfo, ICompanyInfo } from '../../models/Profile';
import {
  asyncChangePassword,
  asyncChangeUsername,
  asyncTransferAdmin,
  asyncUploadAvatar,
  asyncUploadCompanyImage,
} from '../../redux/slices/profileSlice';
import { useDispatch } from 'react-redux';
import { openToast } from '../../redux/slices/appSlice';
import { validate } from 'email-validator';
import { Capacitor } from '@capacitor/core';
import Cropper, { Area, Point } from 'react-easy-crop';
import { getCroppedImg, getRotatedImage } from '../../utils/canvasUtils';
import { getOrientation, Orientation } from 'get-orientation/browser';

interface ModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: '8px',
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export const ChangePassword: FC<ModalProps> = ({ open, setOpen }) => {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const [password, setPassword] = useState({ newPass: '', reEnterPass: '' });

  const handleSubmitPassword = () => {
    if (password.newPass !== password.reEnterPass) {
      dispatch(openToast({ text: 'Passwords do not match!', type: 'error' }));
      return;
    }

    dispatch(
      asyncChangePassword({
        password: password.newPass,
      }),
    );
    setOpen(false);
  };

  const handlePasswordChange = (e: React.ChangeEvent<{ name: string; value: any }>) => {
    setPassword({
      ...password,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <Dialog open={open} onClose={() => setOpen(false)} PaperProps={{ style: { margin: '8px', borderRadius: '8px' } }}>
      <DialogTitle>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          UPDATE PASSWORD
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ minWidth: xsDown ? undefined : 420, maxWidth: 420 }}>
        <Box display="flex" flexDirection="column" gap={6} justifyContent="center" sx={{ pt: 6 }}>
          <InputField
            label="New password"
            type={'password'}
            name="newPass"
            value={password.newPass}
            setValue={handlePasswordChange}
          />
          <InputField
            label="Re-enter new password"
            name="reEnterPass"
            type={'password'}
            value={password.reEnterPass}
            setValue={handlePasswordChange}
          />
        </Box>
        <Box display="flex" justifyContent="center" sx={{ pt: 3, pb: 3 }}>
          <StyledButton
            text="Update password"
            onClick={handleSubmitPassword}
            inputProps={{
              variant: 'contained',
              disabled: password.newPass !== password.reEnterPass || password.newPass === '',
            }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export const ChangeUsername: FC<ModalProps> = ({ open, setOpen }) => {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const [username, setUsername] = useState({ newUsername: '', reEnterUsername: '' });

  const handleUsernameChange = (e: React.ChangeEvent<{ name: string; value: any }>) => {
    setUsername({
      ...username,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmitUsername = () => {
    if (username.newUsername !== username.reEnterUsername) {
      dispatch(openToast({ text: 'Usernames do not match!', type: 'error' }));
      return;
    }
    dispatch(
      asyncChangeUsername({
        username: username.newUsername,
      }),
    );
    setOpen(false);
  };
  return (
    <Dialog open={open} onClose={() => setOpen(false)} PaperProps={{ style: { margin: '8px', borderRadius: '8px' } }}>
      <DialogTitle>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          CHANGE USERNAME
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ minWidth: xsDown ? undefined : 420, maxWidth: 420 }}>
        <Box display="flex" flexDirection="column" gap={6} justifyContent="center" sx={{ pt: 6 }}>
          <InputField
            label="New username"
            name="newUsername"
            value={username.newUsername}
            setValue={handleUsernameChange}
          />
          <InputField
            label="Re-enter new username"
            name="reEnterUsername"
            value={username.reEnterUsername}
            setValue={handleUsernameChange}
          />
        </Box>
        <Box display="flex" justifyContent="center" sx={{ pt: 3, pb: 3 }}>
          <StyledButton
            text="Change username"
            onClick={handleSubmitUsername}
            inputProps={{
              variant: 'contained',
              disabled: username.newUsername !== username.reEnterUsername || username.newUsername === '',
            }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export const TransferAdminRole: FC<ModalProps> = ({ open, setOpen }) => {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [disabled, setDisabled] = useState<boolean>(true);

  const handleChangeEmail = (e: ChangeEvent<{ value: string }>) => {
    setEmail(e.target.value);
    if (validate(e.target.value)) setDisabled(false);
    else setDisabled(true);
  };

  const handleTransferRole = () => {
    setOpen(false);
    dispatch(asyncTransferAdmin({ email }));
  };
  return (
    <Dialog open={open} onClose={() => setOpen(false)} PaperProps={{ style: { margin: '8px', borderRadius: '8px' } }}>
      <DialogTitle>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          TRANSFER ADMIN ROLE
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ minWidth: xsDown ? undefined : 520, maxWidth: 560 }}>
        <Typography>
          Transfer your Admin role to another colleague of yours by adding her/his email address below. Afterwards, you
          will still have access to the Wellics™ application and the User sections, but you will not have any Admin
          rights.
        </Typography>
        <Box display="flex" flexDirection="column" gap={6} justifyContent="center" sx={{ pt: 6 }}>
          <InputField label="Enter email" name="email" value={email} setValue={handleChangeEmail} />
        </Box>
        <Box display="flex" justifyContent="center" sx={{ pt: 3, pb: 3 }}>
          <StyledButton
            text="Transfer"
            onClick={handleTransferRole}
            inputProps={{ variant: 'contained' }}
            disabled={disabled}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

interface UploadPictureProps {
  modal: { open: boolean; title: string };
  setModal: (modal: { open: boolean; title: string }) => void;
  personalInfo?: IAccountInfo;
  setPersonalInfo?: (personalInfo: IAccountInfo) => void;
  companyInfo?: ICompanyInfo;
  setCompanyInfo?: (companyInfo: ICompanyInfo) => void;
  selectedImage?: string;
}

export const UploadPictureModal: FC<UploadPictureProps> = ({ modal, setModal, selectedImage }) => {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState<string>('');

  const [imageSrc, setImageSrc] = useState<string>();
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();

  useEffect(() => {
    if (!modal.open) setErrorMsg('');
  }, [modal]);

  useEffect(() => {
    setImageSrc(selectedImage);
  }, [selectedImage]);

  const orientationToAngle = (orientation: Orientation) => {
    switch (orientation) {
      case Orientation.BOTTOM_RIGHT:
        return 180;
      case Orientation.RIGHT_TOP:
        return 90;
      case Orientation.LEFT_BOTTOM:
        return -90;
      default:
        return 0;
    }
  };

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (acceptedFiles?.length > 0) {
      setErrorMsg('');

      const file = acceptedFiles[0];
      let imageDataUrl = await readFile(file);

      const orientation: Orientation = await getOrientation(file);
      const rotation = orientationToAngle(orientation);
      if (rotation) {
        imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
      }

      setImageSrc(imageDataUrl);
    }
  }, []);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCloseModal = useCallback(() => {
    setModal({ open: false, title: '' });
    removeAll();
  }, [setModal]);

  const cropImage = useCallback(async () => {
    try {
      if (!imageSrc || !croppedAreaPixels) return;
      return await getCroppedImg(imageSrc, croppedAreaPixels);
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels]);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/*',
    maxSize: 5e6,
    onDrop,
    onDropRejected: (e: FileRejection[]) => handleReject(e),
    multiple: false,
  });

  const handleReject = (e: FileRejection[]) => {
    if (e.length > 0) {
      const firstFile = e[0];
      if (firstFile.errors[0].code === 'file-too-large') {
        setErrorMsg('The image file should be up to 5MB');
      } else if (firstFile.errors.length > 0) {
        setErrorMsg(firstFile.errors[0].message);
      }
    }
  };

  const removeAll = () => {
    setImageSrc(undefined);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setCroppedAreaPixels(undefined);
  };

  const style: any = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  const handleFileUpload = () => {
    cropImage().then((cropImage) => {
      if (cropImage) {
        if (modal.title === 'Upload Company Logo') {
          dispatch(asyncUploadCompanyImage(cropImage));
        } else if (modal.title === 'Upload Profile Picture') {
          dispatch(asyncUploadAvatar(cropImage));
        }
        handleCloseModal();
      }
    });
  };

  const getDropzoneMessage = () => {
    return Capacitor.isNativePlatform() ? (
      <p>{'Tap here to select image'}</p>
    ) : (
      <p>{`Drag & drop your photo here, or click to select files`}</p>
    );
  };

  return (
    <Dialog open={modal.open} onClose={handleCloseModal} PaperProps={{ style: { margin: '8px', borderRadius: '8px' } }}>
      <DialogTitle sx={{ minWidth: xsDown ? undefined : 520, maxWidth: 560 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          {modal.title}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ minWidth: xsDown ? undefined : 520, maxWidth: 560 }}>
        {!selectedImage ? (
          <Box padding="30px">
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              {errorMsg ? <p style={{ color: '#DA4E53' }}>{errorMsg}</p> : getDropzoneMessage()}
            </div>
          </Box>
        ) : null}
        <Collapse in={!!imageSrc}>
          <Box>
            <Box position={'relative'} width={'100%'} height={240}>
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                minZoom={0.2}
                maxZoom={3}
                aspect={1}
                cropShape={modal.title === 'Upload Profile Picture' ? 'round' : 'rect'}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                restrictPosition={false}
              />
            </Box>
            <Slider
              value={zoom}
              min={0.2}
              max={3}
              step={0.025}
              aria-labelledby="Zoom"
              onChange={(e, zoom) => setZoom(Number(zoom))}
            />
          </Box>
        </Collapse>
        <Box display="flex" justifyContent="center" sx={{ pt: 3, pb: 3 }}>
          <StyledButton
            text="Upload file"
            onClick={handleFileUpload}
            inputProps={{ variant: 'contained', disabled: !imageSrc }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

function readFile(file: File) {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader?.result as string), false);
    reader.readAsDataURL(file);
  });
}
