import { Box, Grid, IconButton, Typography, ButtonBase, Container, useMediaQuery, Fade } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReactComponent as LogoutIcon } from '../../../../assets/svg/feature/logout.svg';
import { ReactComponent as SupportIcon } from '../../../../assets/svg/feature/support.svg';
import { ReactComponent as BellIcon } from '../../../../assets/svg/feature/bell.svg';
import { ReactComponent as ActiveBellIcon } from '../../../../assets/svg/feature/active-bell.svg';
import { ReactComponent as TrophyIcon } from '../../../../assets/svg/feature/trophy.svg';
import { ReactComponent as MentalFitnessIcon } from '../../../../assets/svg/feature/dimentions/mental-fitness.svg';

import IconPlaceholder from '../../../../assets/svg/placeholder_avatar.svg';
import { Avatar } from '../../../features/Avatar';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { StatusBar, Style } from '@capacitor/status-bar';

import { styles } from './styles';
import BurgerButton from '../../../structure/BurgerButton';
import theme from '../../../../theme';
import { NotificationClickAwayModal } from '../../../features/Notifications/NotificationModal';
import { Colors } from '../../../../theme/colors';
import { ModalContent } from '../../../features/Notifications/ModalContent';
import { getNotifications, updateNotificationReadStatus } from '../../../../lib/api/http/requests/notification';
import { NotificationContent } from '../../../../models/NotificationContent';
import { MobileMenu } from './MobileMenu';
import { Capacitor } from '@capacitor/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { getMetricsCategory, categories } from '../../../../utils/metricsCategories';
import { HeaderWellbeingChart } from '../../../features/charts/HeaderWellbeingChart';
import { ReactComponent as WalletIcon } from '../../../../assets/svg/feature/wallet.svg';
import { useIonRouter } from '@ionic/react';
import moment from 'moment';
import { setShowMentalAssessmentModal } from '../../../../redux/slices/mentalAssessmentSlice';

interface HeaderPageProps {
  name: string;
  avatar?: string;
  openTrophy: () => void;
  openNotification: () => void;
  openSupport: () => void;
  openProfile: () => void;
  logOut: () => void;
}

export const HeaderPage: React.FC<HeaderPageProps> = ({
  name,
  avatar,
  openTrophy,
  openNotification,
  openSupport,
  openProfile,
  logOut,
}) => {
  const [safeAreaTop, setSafeAreaTop] = useState(30);
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [showModal, setShowModal] = useState(false);
  const { isUserEligible } = useSelector((state: RootState) => state.mentalAssessmentSlice);

  const [notifications, setNotifications] = useState<NotificationContent[]>([]);

  const [isNewMessage, setIsNewMessage] = useState(false);

  const router = useIonRouter();

  const dispatch = useDispatch();

  const containerRef = React.useRef(null);
  const handleClose = () => {
    setShowModal(false);
  };
  const handleOpen = () => setShowModal(true);

  const journey = useSelector((state: RootState) => state.journeyPage.data.profileJourneyCards);
  const points = useSelector((state: RootState) => state.profile.data.points);

  const getNotificationList = async () => {
    try {
      const response = await getNotifications({ page: 0, size: 10 });
      setNotifications(response.data.content);
      const unread = (response.data.content as NotificationContent[]).filter((item) => !item.read);
      setIsNewMessage(Boolean(unread.length));
    } catch (e) {}
  };

  useEffect(() => {
    if (Capacitor.getPlatform() === 'ios') {
      StatusBar.setStyle({ style: Style.Dark });
    }
    const getStatusBar = async () => {
      const { statusBarHeight } = await SafeArea.getStatusBarHeight();
      setSafeAreaTop(statusBarHeight - 4);
    };
    getNotificationList();
    getStatusBar();

    const interval = setInterval(getNotificationList, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (showModal || !notifications.length || !isNewMessage) return;
    const updateNotificationStatus = async () => {
      await updateNotificationReadStatus();
      await getNotificationList();
    };
    updateNotificationStatus();
    console.log(showModal);
  }, [showModal]);

  return (
    <Box
      sx={{
        ...styles.container,
        ...(Capacitor.getPlatform() === 'ios' && {
          height: `${64 + safeAreaTop}px`,
          paddingTop: `${safeAreaTop}px`,
        }),
      }}
    >
      {Capacitor.getPlatform() === 'ios' && (
        <Box
          sx={{
            height: `${safeAreaTop}px`,
            position: 'absolute',
            zIndex: 100,
            left: 0,
            top: 0,
            right: 0,
            background: '#07393E',
          }}
        />
      )}
      <Container maxWidth="lg" ref={containerRef} sx={{ position: 'relative' }}>
        <Grid item container justifyContent="space-between" alignItems="center" sx={{ position: 'relative' }}>
          <Grid sx={{ flex: { xs: 1, sm: 'unset' } }}>
            <Box display="flex">
              {xsDown && <BurgerButton style={{ marginRight: 8 }} size={24} />}{' '}
              <Box
                component="button"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mr: { xs: '14px', sm: 'unset' },
                  padding: '1px',
                  backgroundColor: Colors.blue[800],
                  borderRadius: '100px',
                }}
                onClick={() =>
                  router.push(`/page/metrics-log-data?category=WIN&focusDate=${moment().format('YYYY-MM-DD')}`)
                }
              >
                <HeaderWellbeingChart
                  rating={journey?.scores?.wellbeing_index_number ?? 0}
                  series={categories.map((category) => getMetricsCategory(category, journey)?.score ?? 0)}
                  colors={categories.map((category) => getMetricsCategory(category, journey)?.color ?? '#fff')}
                  loading={typeof journey?.scores?.wellbeing_index_number !== 'number'}
                />
                {!xsDown && (
                  <Typography sx={{ marginLeft: '10px', mr: '16px', fontSize: '14px', color: Colors.white }}>
                    Your WIN
                  </Typography>
                )}
              </Box>
              {!xsDown && (
                <ButtonBase sx={styles.button} onClick={openTrophy}>
                  <Box sx={styles.buttonRoundIcon}>
                    <TrophyIcon fill="white" />
                  </Box>
                  <Typography sx={styles.buttonLabel}>Trophy library</Typography>
                </ButtonBase>
              )}
              {/*<Box*/}
              {/*  sx={{*/}
              {/*    display: 'flex',*/}
              {/*    alignItems: 'center',*/}
              {/*    justifyContent: 'center',*/}
              {/*    mr: { xs: '52px', sm: 'unset' },*/}
              {/*    padding: '1px',*/}
              {/*    backgroundColor: Colors.blue[800],*/}
              {/*    borderRadius: '100px',*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <Box*/}
              {/*    sx={{*/}
              {/*      display: 'flex',*/}
              {/*      alignItems: 'center',*/}
              {/*      justifyContent: 'center',*/}
              {/*      height: '36px',*/}
              {/*      width: '36px',*/}
              {/*      borderRadius: '50%',*/}
              {/*      background: Colors.blue[500],*/}
              {/*      border: `1px solid ${Colors.blue[300]}`,*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <WalletIcon />*/}
              {/*  </Box>*/}
              {/*  <Typography sx={{ marginLeft: '10px', mr: '16px', fontSize: '14px', color: Colors.white }}>*/}
              {/*    {Math.floor(points)}*/}
              {/*  </Typography>*/}
              {/*</Box>*/}
              {isUserEligible && (
                <ButtonBase sx={styles.buttonBlue} onClick={() => dispatch(setShowMentalAssessmentModal(true))}>
                  <Box sx={styles.buttonBlueRoundIcon}>
                    <MentalFitnessIcon fill="white" />
                  </Box>
                  <Typography sx={styles.buttonLabel}>WellVox</Typography>
                </ButtonBase>
              )}
            </Box>
          </Grid>

          <Grid display={'flex'} alignItems="center">
            {xsDown && (
              <MobileMenu
                isNewMessage={isNewMessage}
                openProfile={openProfile}
                openTrophy={openTrophy}
                openSupport={openSupport}
                openNotifications={handleOpen}
              />
            )}

            {!xsDown && (
              <>
                <IconButton
                  sx={(styles.fillIconButton, { background: showModal ? Colors.blue[700] : Colors.blue[800] })}
                  onClick={handleOpen}
                >
                  {isNewMessage ? (
                    <ActiveBellIcon height={20} width={20} fill="#1298A6" />
                  ) : (
                    <BellIcon height={20} width={20} fill="#1298A6" />
                  )}
                </IconButton>
                <Box component="span" sx={styles.divider} />
                <Typography sx={styles.profileText}>Hello {name}!</Typography>
                <ButtonBase sx={styles.avatarButton} onClick={openProfile}>
                  <Avatar alt={name} src={avatar || IconPlaceholder} />
                </ButtonBase>
                <Box component="span" sx={styles.divider} />
                <IconButton sx={styles.iconButton} onClick={openSupport} color="primary">
                  <SupportIcon height={20} width={20} stroke="#1298A6" />
                </IconButton>
                <IconButton sx={styles.iconButton} onClick={logOut} color="secondary">
                  <LogoutIcon height={20} width={20} stroke="#1298A6" />
                </IconButton>
              </>
            )}
          </Grid>
        </Grid>

        {xsDown && <ModalContent show={showModal} notifications={notifications} handleClose={handleClose} />}

        {!xsDown && (
          <NotificationClickAwayModal
            show={showModal}
            notifications={notifications}
            handleClose={handleClose}
            containerRef={containerRef}
          />
        )}
      </Container>
    </Box>
  );
};
