import React from 'react';
import { Challenge } from '../../../models/Challenge';
import Grid from '@mui/material/Grid';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { Colors } from '../../../theme/colors';
import { CustomBudge } from '../../../components/features/CustomBudge';
import { DIMENSION_LIST } from '../../../models/enum/DIMENTION_LIST';
import { CustomAvatar } from '../../../components/features/CustomAvatar';
import { COMPETITION_LIST } from '../../../models/enum/COMPETITION_LIST';
import { AWARD_TYPE } from '../../../models/enum/AWARD_TYPE';
import { AWARD_LIST } from '../../../models/enum/AWARD_LIST';
import theme from '../../../theme';
import { ReactComponent as CalendarIcon } from '../../../assets/svg/feature/calendar.svg';
import { ReactComponent as ClockIcon } from '../../../assets/svg/clock.svg';
import { ReactComponent as LogIcon } from '../../../assets/svg/feature/log.svg';
import { ReactComponent as WearableIcon } from '../../../assets/svg/wearable.svg';
import moment from 'moment/moment';
import { daysConverter } from '../../../utils/daysConverter';
import { capitalize } from '../../../utils/stringCapitalize';
import { TRACKING_TYPE } from '../../../models/enum/TRACKING_TYPE';
type Props = {
  challenge: Challenge;
};
export const Information: React.FC<Props> = ({ challenge }) => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const emptyTrophy = AWARD_LIST[AWARD_LIST.length - 1];

  const start = moment(challenge?.challengeDuration.startDate, 'YYYY-MM-DD');
  const end = moment(challenge?.challengeDuration.endDate, 'YYYY-MM-DD');

  const days = moment.duration(end.diff(start)).asDays().toFixed(0);
  const durationInDays =
    challenge && challenge.challengeDuration.suggestedDuration
      ? challenge.challengeDuration.suggestedDuration
      : daysConverter(Number(days) + 1);

  const formattedStartDate = () => {
    if (!challenge || !challenge.challengeDuration.startDate) return null;
    const parts = challenge.challengeDuration.startDate.split('-');

    return `${parts[2]}/${parts[1]}`;
  };
  const formattedEndDate = () => {
    if (!challenge || !challenge.challengeDuration.endDate) return null;
    const parts = challenge.challengeDuration.endDate.split('-');

    return `${parts[2]}/${parts[1]}`;
  };
  return (
    <>
      <Grid
        sx={{
          display: 'flex',
          flexWrap: mdDown ? 'wrap' : 'nowrap',
          borderBottom: mdDown ? '1px dashed #F2F2F2' : 'none',
          pb: mdDown ? '16px' : 0,
          mr: mdDown ? 0 : '16px',
          mt: mdDown ? 0 : '21px',
        }}
      >
        <Grid sx={{ mr: '16px', pt: mdDown ? '16px' : 0 }}>
          <Typography sx={{ fontSize: '12px', color: Colors.gray[400], fontWeight: 700, mb: '4px' }}>Dates:</Typography>
          <CustomBudge
            key={'Dates'}
            Icon={CalendarIcon}
            label={
              !formattedStartDate() || !formattedEndDate() ? 'Open' : `${formattedStartDate()} - ${formattedEndDate()}`
            }
          />
        </Grid>

        <Grid sx={{ mr: '16px', pt: mdDown ? '16px' : 0 }}>
          <Typography sx={{ fontSize: '12px', color: Colors.gray[400], fontWeight: 700, mb: '4px' }}>
            Duration:
          </Typography>

          <CustomBudge key={'Duration'} Icon={ClockIcon} label={durationInDays} />
        </Grid>

        <Grid sx={{ pt: mdDown ? '16px' : 0 }}>
          <Typography sx={{ fontSize: '12px', color: Colors.gray[400], fontWeight: 700, mb: '4px' }}>
            Tracking:
          </Typography>
          <Box sx={{ borderRight: mdDown ? 'none' : '1px dashed #F2F2F2', pr: mdDown ? 0 : '16px' }}>
            <Box
              sx={[
                {
                  padding: '2px',
                  backgroundColor: Colors.gray[100],
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  borderRadius: '24px',
                },
              ]}
            >
              <Box
                alignItems={'center'}
                justifyContent={'center'}
                display={'flex'}
                sx={{
                  width: challenge.challengeAction.actionSource === TRACKING_TYPE.HYBRID ? '100%' : 24,
                  height: 24,
                  px: challenge.challengeAction.actionSource === TRACKING_TYPE.HYBRID ? '6px' : 0,
                  backgroundColor: emptyTrophy.mainColor,
                  borderWidth: 1,
                  borderColor: emptyTrophy.borderColor,
                  borderRadius: 100,
                  borderStyle: 'solid',
                }}
              >
                {(challenge.challengeAction.actionSource === TRACKING_TYPE.HYBRID ||
                  challenge.challengeAction.actionSource === TRACKING_TYPE.MANUAL) && (
                  <LogIcon width={12} height={12} fill={Colors.white} />
                )}

                {challenge.challengeAction.actionSource === TRACKING_TYPE.HYBRID && (
                  <Typography sx={{ fontSize: 12, color: Colors.white, lineHeight: 12 }}>+</Typography>
                )}
                {(challenge.challengeAction.actionSource === TRACKING_TYPE.HYBRID ||
                  challenge.challengeAction.actionSource === TRACKING_TYPE.AUTO) && (
                  <WearableIcon width={12} height={12} />
                )}
              </Box>
              <Typography
                sx={[
                  {
                    ml: '8px',
                    mr: '12px',
                    color: Colors.gray[700],
                    fontSize: '12px',
                    fontWeight: 700,
                  },
                ]}
              >
                {capitalize(challenge.challengeAction.actionSource.toLowerCase())}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid
        sx={{
          display: 'flex',
          borderBottom: mdDown ? '1px dashed #F2F2F2' : 'none',
          py: mdDown ? '16px' : 0,
          mr: mdDown ? 0 : '16px',
          mt: mdDown ? 0 : '21px',
        }}
      >
        <Grid sx={{ mr: '16px' }}>
          <Typography sx={{ fontSize: '12px', color: Colors.gray[400], fontWeight: 700, mb: '4px' }}>
            Dimension:
          </Typography>
          <Grid sx={{ display: 'flex', flexWrap: 'no-wrap' }}>
            {challenge.challengeCharacteristic.challengePillars.map((challengePillar) => {
              return DIMENSION_LIST.map((item, index) =>
                item.value === challengePillar ? (
                  <Grid
                    key={item.value}
                    sx={{
                      mr:
                        challenge.challengeCharacteristic.challengePillars.length > 1 &&
                        index !== challenge.challengeCharacteristic.challengePillars.length - 1
                          ? '4px'
                          : 0,
                    }}
                    item
                  >
                    <CustomAvatar size={28} bgColor={item.mainColor} borderBg={item.borderColor} borderWidth={1}>
                      <item.Icon width={12} height={12} />
                    </CustomAvatar>
                  </Grid>
                ) : null,
              );
            })}
          </Grid>
        </Grid>

        <Grid>
          <Typography sx={{ fontSize: '12px', color: Colors.gray[400], fontWeight: 700, mb: '4px' }}>
            Competition:
          </Typography>
          <Box sx={{ borderRight: mdDown ? 'none' : '1px dashed #F2F2F2', pr: mdDown ? 0 : '16px' }}>
            {COMPETITION_LIST.map((item) =>
              item.value === challenge.challengeCharacteristic.competition ? (
                <CustomBudge
                  key={'Competition'}
                  Icon={item.Icon}
                  boxColor={item.mainColor}
                  borderColor={item.borderColor}
                  bgColor={'#D3DFE0'}
                  textColor={item.mainColor}
                  label={item.label}
                />
              ) : null,
            )}
          </Box>
        </Grid>
      </Grid>

      <Grid
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          padding: mdDown ? '16px 0 8px 0' : 0,
          mt: mdDown ? 0 : '21px',
        }}
      >
        <Grid
          sx={{
            mr:
              challenge.challengeAward?.awards.length &&
              challenge.challengeAward?.awards[0].awardType === AWARD_TYPE.OTHER
                ? '16px'
                : 0,
          }}
        >
          <Typography sx={{ fontSize: '12px', color: Colors.gray[400], fontWeight: 700, mb: '4px' }}>Award:</Typography>
          {!challenge.challengeAward || !challenge.challengeAward?.awards.length ? (
            <CustomBudge
              key={'Award'}
              Icon={emptyTrophy.Icon}
              boxColor={emptyTrophy.mainColor}
              borderColor={emptyTrophy.borderColor}
              textColor={Colors.gray[700]}
              label={emptyTrophy.label}
            />
          ) : (
            AWARD_LIST.map((item) =>
              item.value === challenge.challengeAward?.awards[0].awardType ? (
                <Grid key={item.value} item>
                  <CustomBudge
                    Icon={item.Icon}
                    boxColor={item.mainColor}
                    borderColor={item.borderColor}
                    bgColor={item.backgroundColor}
                    textColor={item.value === AWARD_TYPE.NONE ? Colors.gray[700] : Colors.yellow[600]}
                    label={
                      challenge.challengeAward?.awards[0].points
                        ? challenge.challengeAward.awards[0].points
                        : item.label
                    }
                  />
                </Grid>
              ) : null,
            )
          )}
        </Grid>
        {challenge.challengeAward?.awards.length
          ? challenge.challengeAward?.awards[0]?.awardType === AWARD_TYPE.OTHER && (
              <Typography sx={{ fontSize: '14px', color: Colors.gray[500] }}>
                {challenge.challengeAward.awards[0].awardTitle}
              </Typography>
            )
          : null}
      </Grid>
    </>
  );
};
