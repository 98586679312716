import { getRequest, putRequest } from '../../../../services/HttpService';
import { GeneralNotificationSettings, NotificationChallengeSettings } from '../../../../models/NotificationSettings';

export const getNotificationsSettings = () => getRequest('/gamification-service/notification/settings');
export const updateNotificationsSettings = (params: {
  UserChallengeNotificationSettingsDTO: GeneralNotificationSettings;
  specificChallengeNotificationInfoList: NotificationChallengeSettings[];
}) => putRequest('/gamification-service/notification/settings', params);
export const updateChallengeNotificationSettings = (params: NotificationChallengeSettings) =>
  putRequest('/gamification-service/challenge/notification/settings', params);

export const getChallengeNotificationsSettings = (uuid: string) =>
  getRequest(`/gamification-service/challenge/notification/settings?challengeUUID=${uuid}`);

export const getNotifications = (params: { page?: number; size?: number }) =>
  getRequest(
    `/notification-service/notificationcenter/notifications?page=${params.page || 0}&size=${params.size || 10}`,
  );

export const updateNotificationReadStatus = () =>
  putRequest(`/notification-service/notificationcenter/notifications/all`);
