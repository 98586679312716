import { IonContent, IonInfiniteScroll, IonInfiniteScrollContent, IonPage } from '@ionic/react';
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import CustomHeader from '../components/structure/CustomHeader';
import { RootState } from '../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import CustomDropdown from '../components/common/CustomDropdown';
import useViewIsVisible from '../hooks/useViewIsVisible';
import { ANALYTICS_PAGE } from '../models/enum/ANALYTICS_PAGE';
import { Range } from 'react-date-range';
import { PERIOD } from '../models/Shared';
import moment from 'moment';
import CustomRangePicker from '../components/common/CustomRangePicker';
import EmployeesStepsTable from '../components/tables/EmployeesStepsTable';
import { fetchMoreEmployeesSteps, getEmployeesSteps } from '../redux/slices/employeesStepsSlice';

const EmployeesStepsPage: React.FC = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [page, setPage] = useState<number>(0);
  const visible = useViewIsVisible(ANALYTICS_PAGE.EMPLOYEES_STEPS);

  const [selectedPeriod, setSelectedPeriod] = useState<PERIOD>(PERIOD.MONTH);
  const [range, setRange] = useState<Range[]>([]);

  const { employeesSteps, employeesStepsCount } = useSelector((state: RootState) => state.employeesSteps.data);
  const { employeesStepsLoading } = useSelector((state: RootState) => state.employeesSteps.meta);

  useEffect(() => {
    if (!visible) return;

    setSelectedPeriod(PERIOD.MONTH);
    setRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ]);
  }, [dispatch, visible]);

  const fetchEmployeesSteps = useCallback(() => {
    if (range.length === 1) {
      const { startDate, endDate } = range[0];
      if (startDate && endDate) {
        let actualStartDate: any = startDate;
        let actualEndDate: any = endDate;
        if (moment(startDate).isSame(endDate, 'day')) {
          actualEndDate = moment();
          if (selectedPeriod === PERIOD.WEEK) {
            actualStartDate = moment().subtract(7, 'days');
          } else if (selectedPeriod === PERIOD.MONTH) {
            actualStartDate = moment().subtract(1, 'month');
          }
          if (selectedPeriod === PERIOD.YEAR) {
            actualStartDate = moment().subtract(1, 'year');
          }
        }
        dispatch(
          getEmployeesSteps({
            date_from: actualStartDate.toString(),
            date_to: actualEndDate.toString(),
          }),
        );
      }
    }
  }, [dispatch, range, selectedPeriod]);

  useEffect(() => {
    if (!visible) return;

    setPage(0);
    fetchEmployeesSteps();
  }, [dispatch, fetchEmployeesSteps, visible]);

  const handleFetchMoreEmployeesSteps = (ev: any) => {
    if (range.length === 1) {
      const { startDate, endDate } = range[0];
      if (startDate && endDate) {
        let actualStartDate: any = startDate;
        let actualEndDate: any = endDate;
        if (moment(startDate).isSame(endDate, 'day')) {
          actualEndDate = moment();
          if (selectedPeriod === PERIOD.WEEK) {
            actualStartDate = moment().subtract(7, 'days');
          } else if (selectedPeriod === PERIOD.MONTH) {
            actualStartDate = moment().subtract(1, 'month');
          }
          if (selectedPeriod === PERIOD.YEAR) {
            actualStartDate = moment().subtract(1, 'year');
          }
        }
        dispatch(
          fetchMoreEmployeesSteps({
            page: page + 1,
            date_from: actualStartDate,
            date_to: actualEndDate,
            callback: () => ev.target.complete(),
          }),
        );
        setPage(page + 1);
      }
    }
  };

  const returnEmployeesSteps = () => {
    if (employeesStepsLoading || employeesSteps.length) {
      return (
        <Box sx={{ my: 2 }}>
          <EmployeesStepsTable employeesSteps={employeesSteps} loading={employeesStepsLoading} page={page} />
          <IonInfiniteScroll
            onIonInfinite={handleFetchMoreEmployeesSteps}
            threshold="100px"
            disabled={employeesSteps.length >= employeesStepsCount}
          >
            <IonInfiniteScrollContent
              style={{ paddingTop: 16 }}
              loadingSpinner={null}
              loadingText="Loading more data..."
            />
          </IonInfiniteScroll>
        </Box>
      );
    } else {
      return (
        <Grid container spacing={4} sx={{ my: 2 }} justifyContent={'center'}>
          <Grid item>
            <Typography
              variant="caption"
              textAlign="center"
              sx={{ padding: 10, fontSize: 16 }}
            >{`No results`}</Typography>
          </Grid>
        </Grid>
      );
    }
  };

  const getResponsiveHeader = () => {
    const getDisabledPeriod = () => {
      if (range.length === 1) {
        const { startDate, endDate } = range[0];
        return !moment(startDate).isSame(endDate);
      } else return false;
    };

    return (
      <Grid container justifyContent={'flex-end'} spacing={1}>
        <Grid item>
          <CustomDropdown
            disabled={getDisabledPeriod()}
            fullWidth={xsDown}
            width={150}
            list={Object.values(PERIOD)}
            selected={selectedPeriod}
            setSelected={(s: string) => setSelectedPeriod(s as unknown as PERIOD)}
          />
        </Grid>
        <Grid item>
          <CustomRangePicker mini={xsDown} range={range} setRange={setRange} />
        </Grid>
      </Grid>
    );
  };
  return (
    <IonPage>
      <CustomHeader
        label={"YOUR EMPLOYEES' STEPS"}
        tooltip={
          "Monitor your employees' performance during a step challenge so that you can reward the top performers."
        }
      >
        {getResponsiveHeader()}
      </CustomHeader>
      <IonContent id="scrollableEmployeesSteps" style={{ '--padding-bottom': '42px' }}>
        <Container maxWidth="lg">{returnEmployeesSteps()}</Container>
      </IonContent>
    </IonPage>
  );
};

export default EmployeesStepsPage;
