import React, { useState } from 'react';
import CustomNewHeader from '../../components/features/CustomNewHeader';
import Grid from '@mui/material/Grid';
import { Colors } from '../../theme/colors';
import { IonContent, IonPage } from '@ionic/react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Container,
  Skeleton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { SectionContainer } from './components/SectionContainer';
import { SettingsForm } from './components/SettingsForm';
import { ReactComponent as ArrowTopIcon } from '../../assets/svg/feature/arrow-top.svg';
import { ReactComponent as GoalIcon } from '../../assets/svg/feature/goal.svg';
import { ReactComponent as SaveIcon } from '../../assets/svg/feature/save.svg';
import { useSettingsHandle } from './hooks/useSettingsHandle';
import { useHandleFetch } from './hooks/useHandleFetch';
import { CustomButton } from '../../components/features/CustomButton';
import theme from '../../theme';

export const NotificationsSettingsPage: React.FC = () => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const {
    generalSettings,
    challengesSettings,
    isLoading,
    isAllUpdates,
    isAllReminders,
    handleGeneralSettingsChange,
    handleChallengeSettingsChange,
  } = useSettingsHandle();
  const { isSaveLoading, updateSettings } = useHandleFetch(generalSettings, challengesSettings);
  return (
    <IonPage>
      <CustomNewHeader
        inProgress={isLoading}
        label={'Challenge notifications settings'}
        breadcrumb={{
          Icon: GoalIcon,
          list: [
            { label: 'Challenge notification center', url: '/page/notifications' },
            { label: 'Challenge notifications settings', url: '/' },
          ],
        }}
        tooltip={'Enable or disable notifications that will be sent outside the notification center.'}
      >
        {!mdDown && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <CustomButton
              text={'Save'}
              width={'115px'}
              inputProps={{
                variant: 'contained',
                startIcon: <SaveIcon width={16} height={16} fill={Colors.white} />,
              }}
              onClick={updateSettings}
            />
          </Box>
        )}
      </CustomNewHeader>

      <IonContent scrollEvents={true}>
        <Container
          maxWidth={'lg'}
          sx={{
            paddingBottom: '36px',
            position: 'relative',
          }}
        >
          {isSaveLoading && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                zIndex: 2,
                background: 'rgba(65, 74, 79, 0.3)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress color="inherit" style={{ width: 30, height: 30 }} />
            </Box>
          )}
          {isLoading ? (
            <Grid sx={{ position: 'relative', width: '100%', maxWidth: '823px', margin: '0 auto' }}>
              <Skeleton variant="rectangular" height={'296px'} width={'100%'} sx={{ borderRadius: '20px' }} />

              <Skeleton variant="rectangular" height={'296px'} sx={{ borderRadius: '20px', mt: '24px' }} />
            </Grid>
          ) : (
            <Grid
              sx={{
                maxWidth: { xs: '100%', md: '823px' },
                width: '100%',
                margin: { xs: 0, md: '0 auto 0' },
              }}
            >
              <SectionContainer title={'Global challenge notifications'}>
                <SettingsForm
                  isAllUpdates={isAllUpdates}
                  isAllReminders={isAllReminders}
                  form={generalSettings}
                  disabled={!generalSettings.receive}
                  handleFormChange={handleGeneralSettingsChange}
                />
              </SectionContainer>

              {challengesSettings && challengesSettings.length > 0 ? (
                <SectionContainer title={'Specific challenge notifications'} sx={{ mt: '24px' }}>
                  {challengesSettings.map((item, index) => (
                    <Accordion
                      key={item.challengeUUID}
                      sx={{
                        background: '#F7F9F9',
                        boxShadow: 'none',
                        borderRadius: '10px!important',
                        mt: !index ? '16px!important' : '8px!important',
                        mb: '0px!important',
                        '&:before': { height: 0 },
                        overflow: 'hidden',
                        '& .MuiAccordionSummary-root.Mui-expanded': { minHeight: 'auto' },
                        '& .MuiAccordionSummary-root .MuiTypography-root': !item.receive
                          ? {
                              color: Colors.gray[300],
                            }
                          : {},
                        '& .MuiButtonBase-root.MuiAccordionSummary-root': !item.receive
                          ? { background: '#F7F9F9' }
                          : {},
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ArrowTopIcon style={{ transform: 'rotate(180deg)' }} />}
                        sx={{
                          '& .MuiAccordionSummary-content': { margin: '16px 0!important' },
                        }}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography sx={{ fontSize: { xs: '14px', md: '16px' }, color: Colors.gray[700] }}>
                          {item.title}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ pt: 0 }}>
                        <SettingsForm
                          key={item.challengeUUID}
                          innerForm
                          form={item}
                          index={index}
                          disabled={!item.receive}
                          handleFormChange={handleChallengeSettingsChange}
                        />
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </SectionContainer>
              ) : null}

              {mdDown && (
                <Box sx={{ mt: '50px', textAlign: 'center' }}>
                  <CustomButton
                    text={'Save'}
                    width={'100%'}
                    type={'bigBlue'}
                    sx={{ maxWidth: '328px', ml: 'auto', mr: 'auto' }}
                    inputProps={{
                      variant: 'contained',
                      startIcon: <SaveIcon width={16} height={16} fill={Colors.white} />,
                    }}
                    onClick={updateSettings}
                  />
                </Box>
              )}
            </Grid>
          )}
        </Container>
      </IonContent>
    </IonPage>
  );
};
