import { IonContent, IonPage } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Skeleton, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CustomNewHeader from '../components/features/CustomNewHeader';
import CustomPaginator from '../components/features/CustomPaginator';
import { ReactComponent as GoalIcon } from '../assets/svg/goal.svg';
import { IResult } from '../models/MentalAssessment';
import { getResultsRequest } from '../lib/api/http/requests/mentalAssessment';
import { TestResultItem } from '../components/features/cards/TestResultItem';
import { setShowMentalAssessmentModal } from '../redux/slices/mentalAssessmentSlice';
import { CustomButton } from '../components/features/CustomButton';
import { RootState } from '../redux/store';
import theme from '../theme';
import useViewIsVisible from '../hooks/useViewIsVisible';
import { ANALYTICS_PAGE } from '../models/enum/ANALYTICS_PAGE';

const PAGE_SIZE = 3;

export const TestResultsPage: React.FC = () => {
  const dispatch = useDispatch();
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));
  const visible = useViewIsVisible(ANALYTICS_PAGE.MENTAL_ASSESSMENT_RESULTS);

  const { isUserEligible } = useSelector((state: RootState) => state.mentalAssessmentSlice);

  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);

  const [totalItems, setTotalItems] = useState(0);
  const [testResults, setTestResults] = useState<IResult[]>([]);

  const getResults = async () => {
    setLoading(true);

    const response = await getResultsRequest({ page: page, size: PAGE_SIZE });

    setTestResults(response.data.content);
    setTotalItems(response.data.totalElements);

    setLoading(false);
  };

  useEffect(() => {
    getResults();
  }, [dispatch, page]);
  useEffect(() => {
    if (!visible) return;
    getResults();
  }, [visible]);

  const Item = () => {
    return (
      <Grid container item xs={12} spacing={2}>
        {!testResults || loading ? (
          [1, 1, 1].map((i, index) => (
            <Grid item md={12} table={12} xs={12} key={index}>
              <Skeleton variant="rectangular" height={xsDown ? '540px' : '335px'} sx={{ borderRadius: '20px' }} />
            </Grid>
          ))
        ) : testResults?.length ? (
          (testResults ?? []).map((item, index) => (
            <Grid item md={12} table={12} xs={12} key={item.activityId} flex={1}>
              <TestResultItem key={index} item={item} />{' '}
            </Grid>
          ))
        ) : (
          <Grid item sx={{ mt: 6, color: 'text.disabled' }} container justifyContent={'center'}>
            {'No content available'}
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <IonPage>
      <CustomNewHeader
        label={'WellVox results history'}
        breadcrumb={{
          Icon: GoalIcon,
          list: [
            { label: 'Your Journey', url: '/page/your-journey' },
            { label: 'WellVox results history', url: '/' },
          ],
        }}
        tooltip={'A snapshot of your current mental wellness to guide you towards a more balanced daily experience.'}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: { xs: '8px', sm: '16px' },
            justifyContent: 'space-between',
          }}
        >
          <CustomPaginator currentPage={page} pageSize={PAGE_SIZE} setPage={setPage} totalItems={totalItems} />
          {isUserEligible && (
            <CustomButton
              inputProps={{
                variant: 'contained',
              }}
              text={'Take an assessment'}
              sx={{ minWidth: { xs: 'auto', sm: '124px' }, fontSize: { xs: '12px', sm: '14px' } }}
              onClick={() => dispatch(setShowMentalAssessmentModal(true))}
            />
          )}
        </Box>
      </CustomNewHeader>

      <IonContent style={{ '--padding-bottom': '36px' }} scrollEvents={true}>
        <Container maxWidth={'lg'}>
          <Grid container>
            <Grid container item xs={12} sx={{ mb: 6, zIndex: 1 }}>
              {Item()}
            </Grid>
          </Grid>
        </Container>
      </IonContent>
    </IonPage>
  );
};

export default TestResultsPage;
