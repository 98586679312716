import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { fetchChallenge } from '../../../redux/slices/challengeSlice';
import { AWARD_LIST } from '../../../models/enum/AWARD_LIST';
import moment from 'moment';
import { daysConverter } from '../../../utils/daysConverter';

type Props = {
  id: string;
};

export const useHandle = (params: Props) => {
  const dispatch = useDispatch();
  const { challenge } = useSelector((state: RootState) => state.challenge.data);
  const { challengeLoading } = useSelector((state: RootState) => state.challenge.meta);

  const [expanded, setExpanded] = useState(false);

  const loadData = () => {
    if (!params) return;
    const id = params.id;

    dispatch(fetchChallenge({ id: id, isTemplate: 'true' }));
  };

  useEffect(() => {
    loadData();
  }, [params]);

  const challengeTitle = challenge ? challenge.challengeInfo.title : '';

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const emptyTrophy = AWARD_LIST[AWARD_LIST.length - 1];

  const start = moment(challenge?.challengeDuration.startDate, 'YYYY-MM-DD');
  const end = moment(challenge?.challengeDuration.endDate, 'YYYY-MM-DD');

  const days = moment.duration(end.diff(start)).asDays().toFixed(0);
  const durationInDays =
    challenge && challenge.challengeDuration.suggestedDuration
      ? challenge.challengeDuration.suggestedDuration
      : daysConverter(Number(days) + 1);

  return {
    challenge,
    challengeTitle,
    challengeLoading,
    emptyTrophy,
    expanded,
    durationInDays,
    loadData,
    handleExpandClick,
  };
};
