import { useEffect, useState } from 'react';
import { abortTeam } from '../../../lib/api/http/requests/challengeTeam';
import { Team } from '../../../models/Team';
export const useTeamAbortHandle = (challengeUUID: string, callback?: () => void) => {
  const [abortLoading, setAbortLoading] = useState(false);
  const [abortTeamItem, setAbortTeamItem] = useState<Team | null>(null);
  useEffect(() => {
    if (abortLoading) return;

    setAbortTeamItem(null);
  }, [abortLoading]);
  const abortRequest = async () => {
    if (!abortTeamItem) return;

    try {
      setAbortLoading(true);
      await abortTeam({ challengeUUID: challengeUUID, teamUUID: abortTeamItem.teamUUID });
      if (callback) {
        callback();
      }
      setAbortLoading(false);
    } catch (e) {
      setAbortLoading(false);
    }
  };
  return {
    abortLoading,
    abortTeamItem,
    setAbortTeamItem,
    abortRequest,
  };
};
