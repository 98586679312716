export const Colors = {
  white: '#FFFFFF',
  black: '#000000',

  gray: {
    50: '#F0F2F3',
    100: '#E2E5E6',
    150: '#D3D7DA',
    200: '#C4CACE',
    300: '#A7B0B5',
    400: '#89959D',
    500: '#6C7B84',
    600: '#56626A',
    700: '#414A4F',
    800: '#2B3135',
    850: '#202528',
    900: '#16191A',
    950: '#0B0C0D',
  },

  magenta: {
    50: '#F3E8FA',
    100: '#E6D0F5',
    150: '#DAB9F1',
    200: '#CEA2EC',
    300: '#B573E2',
    400: '#9D45D9',
    500: '#8416CF',
    600: '#6A12A6',
    700: '#4F0D7C',
    800: '#350953',
    850: '#28073E',
    900: '#1A0429',
    950: '#0D0215',
  },

  yellow: {
    50: '#FAF7E8',
    100: '#F5F0D0',
    150: '#F1E8B9',
    200: '#ECE0A2',
    300: '#E2D173',
    400: '#D9C145',
    500: '#CFB216',
    600: '#A68E12',
    700: '#7C6B0D',
    800: '#534709',
    850: '#3E3507',
    900: '#292404',
    950: '#151202',
  },

  blue: {
    50: '#E8F9FA',
    100: '#D0F2F5',
    150: '#B9ECF1',
    200: '#A2E5EC',
    300: '#73D8E2',
    400: '#45CBD9',
    500: '#16BECF',
    600: '#1298A6',
    700: '#0D727C',
    800: '#094C53',
    850: '#07393E',
    900: '#042629',
    950: '#021315',
  },

  green: {
    50: '#E8FAF3',
    100: '#D0F5E6',
    150: '#B9F1DA',
    200: '#A2ECCE',
    300: '#73E2B5',
    400: '#45D99D',
    500: '#16CF84',
    600: '#12A66A',
    700: '#0D7C4F',
    800: '#095335',
    850: '#073E28',
    900: '#04291A',
    950: '#02150D',
  },

  red: {
    50: '#FBEDEE',
    100: '#F8DCDD',
    150: '#F4CACB',
    200: '#F0B8BA',
    300: '#E99598',
    400: '#E17175',
    500: '#DA4E53',
    600: '#AE3E42',
    700: '#832F32',
    800: '#571F21',
    850: '#411719',
    900: '#2C1011',
    950: '#160808',
  },

  purple: {
    50: '#F9EEF7',
    100: '#F3DDEF',
    150: '#ECCCE7',
    200: '#E6BBDF',
    300: '#DA98D0',
    400: '#CD76C0',
    500: '#C154B0',
    600: '#9A438D',
    700: '#74326A',
    800: '#4D2246',
    850: '#3A1935',
    900: '#271123',
    950: '#130812',
  },

  orange: {
    50: '#FFF7E9',
    100: '#FEEFD2',
    150: '#FEE7BC',
    200: '#FDDFA5',
    300: '#FCCF79',
    400: '#FBBF4C',
    500: '#FAAF1F',
    600: '#C88C19',
    700: '#966913',
    800: '#64460C',
    850: '#4B3509',
    900: '#322306',
    950: '#191203',
  },
};
