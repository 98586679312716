import { Box, Slider, SliderProps, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';

const CustomSliderComponent = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-thumb': {
    height: 35,
    width: 35,
    border: `8px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.light,
    boxShadow: 'none',
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 12,
    fontWeight: 'normal',
    top: -10,
    backgroundColor: theme.palette.primary.main,
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    color: theme.palette.common.white,
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
    backgroundColor: theme.palette.primary.main,
    height: 5,
  },
  '& .MuiSlider-rail': {
    opacity: 0.5,
    backgroundColor: theme.palette.primary.light,
    height: 5,
  },
}));

const CustomCaloriesSliderComponent = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-thumb': {
    height: 35,
    width: 35,
    border: `8px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.light,
    boxShadow: 'none',
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 12,
    fontWeight: 'normal',
    top: -10,
    backgroundColor: theme.palette.primary.main,
    width: 'auto',
    height: 'auto',
    borderRadius: '1000px',
    color: theme.palette.common.white,
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
    backgroundColor: theme.palette.primary.main,
    height: 5,
  },
  '& .MuiSlider-rail': {
    opacity: 0.5,
    backgroundColor: theme.palette.primary.light,
    height: 5,
  },
}));

interface Props {
  inputProps?: SliderProps;
  value: number;
  minValue: number;
  maxValue: number;
  setValue: (value: number) => void;
}

export const CustomMetricsSlider: React.FC<Props> = ({ inputProps, value, setValue, minValue, maxValue }) => {
  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setValue(value);
  };
  return (
    <Box sx={{ padding: 2 }}>
      <Stack spacing={3} direction="row" sx={{ mb: 1 }} alignItems="center">
        <Typography>1</Typography>
        <CustomSliderComponent
          {...inputProps}
          onChange={handleChange}
          value={value}
          valueLabelDisplay="on"
          defaultValue={3}
          step={1}
          min={minValue}
          max={maxValue}
        />
        <Typography>{maxValue}</Typography>
      </Stack>
    </Box>
  );
};
