import { useIonRouter } from '@ionic/react';
import React, { useState } from 'react';
import { useMediaQuery } from '@mui/material';
import theme from '../../../theme';
import { SwiperProps } from 'swiper/react/swiper-react';
import { CHALLENGE_STATUS } from '../../../models/enum/CHALLENGE_STATUS';
import { Challenge } from '../../../models/Challenge';
import { useDispatch } from 'react-redux';
import { updateFinished, updateOngoing } from '../../../redux/slices/challengeSlice';

export const useHandle = (status: CHALLENGE_STATUS) => {
  const router = useIonRouter();
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [isSliderBeginning, setIsSliderBeginning] = useState(true);
  const [isSliderEnd, setIsSliderEnd] = useState(false);

  const dispatch = useDispatch();

  const slideOptions: SwiperProps = {
    speed: 400,
    centeredSlidesBounds: true,
    slideToClickedSlide: false,
    threshold: 15,
    slidesPerGroup: 1,
    breakpoints: {
      // when window width is >= 340px
      340: {
        slidesPerGroup: 1,
        slidesPerView: 1.2,
      },
      400: {
        slidesPerGroup: 1,
        slidesPerView: 1.2,
      },
      600: {
        slidesPerView: 1.4,
      },
      700: {
        slidesPerView: 2.2,
      },
      900: {
        slidesPerGroup: 1,
        slidesPerView: 2.8,
      },
      1000: {
        slidesPerGroup: 1,
        slidesPerView: 2.8,
      },
      1100: {
        slidesPerGroup: 1,
        slidesPerView: 3.2,
      },
      1200: {
        slidesPerGroup: 1,
        slidesPerView: 3.6,
      },
      1400: {
        slidesPerGroup: 4,
        slidesPerView: 4,
      },
      2100: {
        slidesPerGroup: 4,
        slidesPerView: 4,
      },
      2400: {
        slidesPerGroup: 4,
        slidesPerView: 4,
      },
    },
  };
  const goPreviousDisabled = () => isSliderBeginning;
  const goNextDisabled = () => isSliderEnd;

  const handleViewAll = (path: string) => {
    router.push(path);
  };

  const handleUpdateChallenge = (challenge?: Challenge) => {
    dispatch(status === CHALLENGE_STATUS.ACTIVE ? updateOngoing(challenge) : updateFinished(challenge));
  };

  return {
    isSliderEnd,
    slideOptions,
    xsDown,
    setIsSliderBeginning,
    setIsSliderEnd,
    goPreviousDisabled,
    goNextDisabled,
    handleViewAll,
    handleUpdateChallenge,
  };
};
