import * as React from 'react';
import { Switch, SwitchProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ChangeEvent } from 'react';

const SwitchCustomized = styled(Switch)(({ theme }) => ({
  width: 29,
  height: 16,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: '2px',
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.success,
        opacity: 1,
        border: '1px solid #73D8E2',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 12,
    height: 12,
  },
  '& .MuiSwitch-track': {
    borderRadius: '99px',
    backgroundColor: '#D3D7DA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

interface Props {
  value: boolean;
  setValue: (value: ChangeEvent<HTMLInputElement>) => void;
  inputProps?: SwitchProps;
  disabled?: boolean;
}

const CustomSwitch: React.FC<Props> = ({ value, setValue, inputProps, disabled }) => {
  return <SwitchCustomized {...inputProps} checked={value} disabled={disabled} onChange={setValue} />;
};

export default CustomSwitch;
