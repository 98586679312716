import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import { Colors } from '../../theme/colors';
import { Box, Collapse, Link, Typography, useMediaQuery } from '@mui/material';
import { ReactComponent as PuzzleIcon } from '../../assets/svg/feature/puzzle.svg';
import { ReactComponent as ReminderIcon } from '../../assets/svg/feature/reminder.svg';
import { ReactComponent as CrownIcon } from '../../assets/svg/feature/crown.svg';
import { ReactComponent as StarIcon } from '../../assets/svg/feature/star.svg';
import { CustomAvatar } from './CustomAvatar';
import theme from '../../theme';
import { NotificationContent } from '../../models/NotificationContent';
import moment from 'moment';
import '../../theme/notification-content.css';

export enum NotificationCategory {
  LIFE_CIRCLE = 'LIFE_CIRCLE',
  REMINDER = 'REMINDER',
  ADMINS = 'ADMINS',
  ACHIEVEMENT = 'ACHIEVEMENT',
}
export const NotificationTypes = {
  LIFE_CIRCLE: {
    Icon: PuzzleIcon,
    borderColor: Colors.magenta[300],
    backgroundColor: Colors.purple[500],
  },
  REMINDER: {
    Icon: ReminderIcon,
    borderColor: Colors.blue[300],
    backgroundColor: Colors.blue[500],
  },
  ADMINS: {
    Icon: CrownIcon,
    borderColor: Colors.orange[300],
    backgroundColor: Colors.orange[500],
  },
  ACHIEVEMENT: {
    Icon: StarIcon,
    backgroundColor: Colors.yellow[400],
    borderColor: Colors.yellow[300],
  },
};
type Props = {
  notification: NotificationContent;
  isVariantBig?: boolean;
};
export const NotificationItem: React.FC<Props> = ({ notification, isVariantBig }) => {
  const smDown = useMediaQuery(theme.breakpoints.down('md'));
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const category =
    notification.eventType === 'UDAL' &&
    notification.recipientGroup !== 'ADMINS' &&
    notification.recipientGroup !== 'ADMINS_SELF'
      ? NotificationCategory.REMINDER
      : notification.recipientGroup === 'ADMINS' || notification.recipientGroup === 'ADMINS_SELF'
      ? NotificationCategory.ADMINS
      : NotificationCategory.LIFE_CIRCLE;
  const item = NotificationTypes[category];

  const start = moment(notification.creationDate, 'YYYY-MM-DD');
  const end = moment(new Date(), 'YYYY-MM-DD');

  const isCurrentDate = moment(notification.creationDate).isSame(new Date(), 'day');
  const isYesterday = moment.duration(end.diff(start)).asDays().toFixed(0);
  const formattedDate = moment(notification.creationDate).format('D MMM');
  const notificationDate = isCurrentDate ? 'Today' : Number(isYesterday) === 1 ? 'Yesterday' : formattedDate;
  return (
    <Grid
      sx={{
        display: 'flex',
        width: '100%',
        padding: isVariantBig && smDown ? '16px 0' : '16px 8px',
        borderRadius: '10px',
        background: isVariantBig && smDown ? 'transparent' : !notification.read ? '#F3FBFD' : Colors.white,
      }}
    >
      <Grid>
        <CustomAvatar
          size={isVariantBig && !smDown ? 32 : 24}
          bgColor={item.backgroundColor}
          borderBg={item.borderColor}
          borderWidth={1}
        >
          <item.Icon
            width={isVariantBig && !smDown ? 16 : 12}
            height={isVariantBig && !smDown ? 16 : 12}
            fill={Colors.white}
          />
        </CustomAvatar>

        {!notification.read && (
          <Box
            sx={{
              margin: '4px auto 0',
              width: '5px',
              height: '5px',
              borderRadius: '100%',
              background: Colors.blue[500],
            }}
          />
        )}
      </Grid>
      <Grid sx={{ width: '100%', padding: '0 10px' }} onClick={() => (expanded ? undefined : handleExpandClick())}>
        <Typography
          sx={{ fontSize: isVariantBig && !smDown ? '16px' : '14px', color: Colors.gray[700], fontWeight: 900 }}
        >
          {notification.title}
        </Typography>
        <Typography
          sx={{
            mt: '4px',
            fontSize: isVariantBig && !smDown ? '14px' : '12px',
            color: Colors.gray[600],
          }}
          dangerouslySetInnerHTML={{ __html: notification.shortDescription }}
        />
        {!expanded && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              maxWidth: '150px',
              cursor: 'pointer',
              mt: '4px',
            }}
          >
            <Link sx={{ fontSize: isVariantBig && !smDown ? '14px' : '12px', color: Colors.blue[500] }}>
              Read more...
            </Link>
          </Box>
        )}
        <Collapse in={expanded} timeout="auto">
          <Box sx={{ mt: '16px', padding: '16px', background: Colors.gray[50], borderRadius: '20px' }}>
            <Typography
              sx={{
                fontSize: isVariantBig && !smDown ? '14px' : '12px',
                color: Colors.gray[600],
              }}
              dangerouslySetInnerHTML={{ __html: notification.fullDescription }}
            />
          </Box>
          <Box
            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', mt: '16px' }}
            onClick={() => handleExpandClick()}
          >
            <Link sx={{ fontSize: isVariantBig && !smDown ? '14px' : '12px', color: Colors.blue[500] }}>View less</Link>
          </Box>
        </Collapse>
      </Grid>
      <Grid>
        <Typography color={Colors.gray[400]} sx={{ whiteSpace: 'noWrap', fontSize: '12px', lineHeight: 1.3 }}>
          {notificationDate}
        </Typography>
      </Grid>
    </Grid>
  );
};
