import { useEffect, useRef, useState } from 'react';
import { handleBackendError } from '../../../utils/handleBackendError';
import { getRewardsCatalog, getRewardsCompany, postRewardOrder } from '../../../lib/api/http/requests/rewards';
import { AltRewardCatalog, RewardEmployees, RewardOrder } from '../../../models/Rewards';
import { ModalCatalogPrams, RowInputsType, RowUserType } from '../types';
import { openToast } from '../../../redux/slices/appSlice';
import { useDispatch } from 'react-redux';
import useIsPageActive from '../../../hooks/useIsPageActive';
import { ANALYTICS_PAGE } from '../../../models/enum/ANALYTICS_PAGE';

const PAGE_SIZE = '15';

export const useHandler = () => {
  const [catalog, setCatalog] = useState<AltRewardCatalog>();
  const [openModal, setOpenModal] = useState(false);
  const [modalParams, setModalParams] = useState<ModalCatalogPrams>();
  const [selectedReward, setSelectedReward] = useState('');
  const [selectedBrandId, setSelectedBrandId] = useState('');

  const [page, setPage] = useState('0');
  const [sort, setSort] = useState('points,desc');
  const [search, setSearch] = useState('');
  const [hasNextPage, setHasNextPage] = useState(true);

  const [rowUsers, setRowUsers] = useState<RowUserType[]>();
  const [selectedRowUser, setSelectedRowUser] = useState<RowUserType[]>([]);
  const [openRedeemModal, setOpenRedeemModal] = useState(false);
  const [comment, setComment] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const inputs = useRef<RowInputsType[]>([]);

  const dispatch = useDispatch();

  const visible = useIsPageActive(ANALYTICS_PAGE.REDEMPTION);

  const handleFetchUser = async (params: { page: string; name?: string; sort?: string }) => {
    try {
      setIsLoading(true);
      const response = await getRewardsCompany({ size: PAGE_SIZE, ...params });
      if (response.data.content && catalog) {
        const userData: RewardEmployees[] = response.data.content;
        const prevData: RowUserType[] = rowUsers && Number(params.page) > 0 ? rowUsers : [];
        setHasNextPage(!response.data.last);
        if (!response.data.last) {
          setPage(params.page);
        }
        setRowUsers([
          ...prevData,
          ...userData.map((item) => {
            const selectedUser = selectedRowUser.find((user) => user.uuid === item.emp_uuid);
            const row: RowUserType = {
              uuid: item.emp_uuid,
              avatar: item.avatar,
              name: item.name ?? item.username,
              country: item.country,
              points: item.points,
              rewardEligible: item.rewardEligible,
              isEdit: false,
            };
            if (item.country && item.rewardEligible) {
              const rewardLink = catalog[item.country].find((reward) => reward.rewardType === 'REWARD_LINK');
              if (rewardLink) {
                row.isRewardLink = true;
                row.reward = {
                  rewardTitle: rewardLink.brandItems?.[0].rewardName ?? '',
                  rewardBrandId: rewardLink.brandId,
                  rewardUTID: rewardLink.brandItems?.[0].rewardUTID ?? '',
                  min: rewardLink.brandItems?.[0].rewardMinValue ?? 0,
                  max: rewardLink.brandItems?.[0].rewardMaxValue ?? 0,
                  currency: rewardLink.brandItems?.[0].rewardCurrencyCode ?? '',
                };
                if (row.reward.min === row.reward.max) {
                  inputs.current = [
                    ...inputs.current,
                    { uuid: row.uuid, amount: row.reward.max.toString(), points: '' },
                  ];
                }
              }
            }
            if (selectedUser) {
              return {
                ...row,
                ...(row.country === selectedUser.country && {
                  isEdit: selectedUser.isEdit,
                  inputErrors: selectedUser.inputErrors,
                  reward: selectedUser?.reward,
                }),
              };
            }
            return row;
          }),
        ]);
      }
    } catch (err) {
      handleBackendError(err, 'Failure to fetch the user list, please try again');
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeSort = (name: string) => {
    const splitSort = sort.split(',');
    if (splitSort?.[0] && splitSort?.[1]) {
      const sortType = splitSort?.[0] === name ? (splitSort?.[1] === 'desc' ? 'asc' : 'desc') : 'desc';
      const newSort = `${name},${sortType}`;
      setSort(newSort);
      handleFetchUser({ page: '0', name: search, sort: newSort });
    }
  };

  const handleClearSelected = () => {
    if (selectedRowUser.length > 0) {
      setSelectedRowUser([]);
      setRowUsers(
        rowUsers?.map((item) =>
          item.isEdit
            ? { ...item, isEdit: false, inputErrors: undefined, ...(!item.isRewardLink && { reward: undefined }) }
            : item,
        ),
      );
    }
  };

  const handleFetchNextUsers = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (
      e.currentTarget.scrollTop >= e.currentTarget.scrollHeight - e.currentTarget.offsetHeight &&
      !isLoading &&
      hasNextPage
    ) {
      console.log(page);
      handleFetchUser({ page: (Number(page) + 1).toString(), name: search, sort });
    }
  };

  const onChangeSearch = async (val: string) => {
    setSearch(val);
    await handleFetchUser({ page: '0', name: val, sort });
  };

  const onChangeInputs = (uuid: string, type: 'amount' | 'points') => (val: string) => {
    const hasInputs = inputs.current.findIndex((item) => item.uuid === uuid) >= 0;
    if (hasInputs) {
      inputs.current = inputs.current.map((item) =>
        item.uuid === uuid
          ? { ...item, ...(type === 'amount' && { amount: val }), ...(type === 'points' && { points: val }) }
          : item,
      );
      return;
    }
    inputs.current = [
      ...inputs.current,
      {
        uuid,
        ...(type === 'amount'
          ? { amount: val, points: '' }
          : type === 'points'
          ? { points: val, amount: '' }
          : { points: '', amount: '' }),
      },
    ];
  };

  const handleEditUser = (uuid: string) => (isRemove?: boolean) => {
    setRowUsers(
      rowUsers?.map((user) => (user.uuid === uuid ? { ...user, isEdit: !user.isEdit, inputErrors: undefined } : user)),
    );
    if (isRemove) {
      setSelectedRowUser(selectedRowUser.filter((user) => user.uuid !== uuid));
      return;
    }
    const editUser = rowUsers?.find((user) => user.uuid === uuid);
    if (editUser) {
      setSelectedRowUser([...selectedRowUser, { ...editUser, isEdit: true }]);
    }
  };

  const openCatalogModal = (params: ModalCatalogPrams) => {
    setModalParams(params);
    setOpenModal(true);
    if (params.brandId && params.rewardName) {
      setSelectedReward(params.rewardName);
      setSelectedBrandId(params.brandId);
    }
  };

  const closeCatalogModal = () => {
    setOpenModal(false);
    setModalParams(undefined);
    setSelectedBrandId('');
    setSelectedReward('');
  };

  const closeRedeemModal = () => {
    if (isUpdating) return;
    setComment('');
    setOpenRedeemModal(false);
  };

  const onSubmitReward = () => {
    const uuid = modalParams?.empUUID;
    if (uuid && rowUsers) {
      const user = rowUsers.find((item) => item.uuid === uuid);
      if (selectedBrandId && catalog && user) {
        const reward = catalog[user.country as string]
          .find((item) => item.brandId === selectedBrandId)
          ?.brandItems.find((item) => item.rewardName === selectedReward);
        if (reward) {
          const fixAmount = reward.rewardMinValue === reward.rewardMaxValue;
          if (fixAmount) {
            const hasInput = inputs.current.findIndex((input) => input.uuid === uuid) >= 0;
            if (hasInput) {
              inputs.current = inputs.current.map((input) =>
                input.uuid === uuid ? { ...input, amount: reward.rewardMaxValue.toString() } : input,
              );
            } else {
              inputs.current = [...inputs.current, { uuid, points: '', amount: reward.rewardMaxValue.toString() }];
            }
          }
          const updatedUser: RowUserType = {
            ...user,
            reward: {
              rewardBrandId: selectedBrandId,
              rewardTitle: reward.rewardName,
              rewardUTID: reward.rewardUTID,
              min: reward.rewardMinValue,
              max: reward.rewardMaxValue,
              currency: reward.rewardCurrencyCode,
            },
          };
          setRowUsers(rowUsers.map((item) => (item.uuid === uuid ? updatedUser : item)));
          setSelectedRowUser(selectedRowUser.map((item) => (item.uuid === uuid ? updatedUser : item)));
        }
      }
    }
    closeCatalogModal();
  };

  const onChangeReward = (brandId: string) => (val: string) => {
    setSelectedBrandId(brandId);
    setSelectedReward(val);
  };

  const handleOpenRedeem = () => {
    if (selectedRowUser.length === 0) {
      dispatch(openToast({ text: 'Please make sure at least one user is selected', type: 'error' }));
      return;
    }
    const emptyUserWithReward = selectedRowUser.find((item) => !item.reward);
    if (emptyUserWithReward) {
      dispatch(openToast({ text: `Please select a reward for the user ${emptyUserWithReward.name}`, type: 'error' }));
      return;
    }
    let isError = false;
    const updatedRowUser = selectedRowUser.map((user) => {
      const input = inputs.current.find((item) => item.uuid === user.uuid);
      if (!input) {
        isError = true;
        return { ...user, inputErrors: { amount: true, points: true } };
      }
      if (input.amount.length === 0 || input.points.length === 0) {
        isError = true;
        return { ...user, inputErrors: { amount: input.amount.length === 0, points: input.points.length === 0 } };
      }
      const amountError =
        user.reward && (Number(input.amount) < user.reward.min || Number(input.amount) > user.reward.max);
      const pointsError = Number(input.points) < 0 || Number(input.points) > user.points;
      if (amountError || pointsError) {
        isError = true;
        return { ...user, inputErrors: { ...(amountError && { amount: true }), ...(pointsError && { points: true }) } };
      }
      return user;
    });
    if (isError) {
      dispatch(openToast({ text: 'One or several users have invalid fields', type: 'error' }));
      setSelectedRowUser(updatedRowUser);
      setRowUsers(
        rowUsers?.map((user) => {
          const selectedUser = updatedRowUser.find((updateUser) => updateUser.uuid === user.uuid);
          if (selectedUser) {
            return selectedUser;
          }
          return user;
        }),
      );
      return;
    }
    setOpenRedeemModal(true);
  };

  const submitOrder = async () => {
    try {
      if (isUpdating) return;
      setIsUpdating(true);
      const orders: RewardOrder[] = selectedRowUser.map((user) => {
        const input = inputs.current.find((item) => item.uuid === user.uuid);
        return {
          pointsRedeemed: Number(input?.points),
          amountRedeemed: Number(input?.amount),
          orderProductID: user.reward?.rewardUTID ?? '',
          empUUID: user.uuid,
          orderIssueDate: new Date().toISOString(),
        };
      });
      const response = await postRewardOrder({ orders, ...(comment.length > 0 && { orderComment: comment }) });
      if (response.data.message) {
        dispatch(openToast({ text: response?.data?.message ?? '', type: 'success' }));
      }
      await handleFetchUser({ page: '0', name: search, sort });
      setOpenRedeemModal(false);
      setPage('0');
      setSelectedRowUser([]);
      setComment('');
      const updatedInputs: RowInputsType[] = [];
      const updatedRowUsers: RowUserType[] | undefined = rowUsers?.map((item) => {
        const isRewardLinkFix = item.isRewardLink && item.reward && item.reward.min === item.reward.max;
        if (isRewardLinkFix && item.reward?.max) {
          updatedInputs.push({ uuid: item.uuid, amount: item.reward.max.toString(), points: '' });
        }
        return { ...item, isEdit: false, ...(!item.isRewardLink && { reward: undefined }) };
      });
      setRowUsers(updatedRowUsers);
      inputs.current = updatedInputs;
    } catch (err) {
      console.log(err);
      handleBackendError(err, '');
    } finally {
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    if (!visible) {
      setOpenModal(false);
      setOpenRedeemModal(false);
      return;
    }
    const fetchUsers = async () => {
      try {
        const responseCatalog = await getRewardsCatalog();
        const catalogData: AltRewardCatalog = responseCatalog.data;
        setCatalog(catalogData);
        const response = await getRewardsCompany({ size: PAGE_SIZE, page: '0', sort: 'points,desc' });
        if (response.data.content) {
          const userData: RewardEmployees[] = response.data.content;
          setSelectedRowUser([]);
          inputs.current = [];
          setSearch('');
          setSort('points,desc');
          setHasNextPage(!response.data.last);
          setRowUsers(
            userData.map((item) => {
              const row: RowUserType = {
                uuid: item.emp_uuid,
                avatar: item.avatar,
                name: item.name ?? item.username,
                country: item.country,
                points: item.points,
                rewardEligible: item.rewardEligible,
                isEdit: false,
              };
              if (item.country && item.rewardEligible) {
                const rewardLink = catalogData[item.country]?.find((reward) => reward.rewardType === 'REWARD_LINK');
                if (rewardLink) {
                  row.isRewardLink = true;
                  row.reward = {
                    rewardTitle: rewardLink.brandItems?.[0].rewardName ?? '',
                    rewardBrandId: rewardLink.brandId,
                    rewardUTID: rewardLink.brandItems?.[0].rewardUTID ?? '',
                    min: rewardLink.brandItems?.[0].rewardMinValue ?? 0,
                    max: rewardLink.brandItems?.[0].rewardMaxValue ?? 0,
                    currency: rewardLink.brandItems?.[0].rewardCurrencyCode ?? '',
                  };
                  if (row.reward.min === row.reward.max) {
                    inputs.current = [
                      ...inputs.current,
                      { uuid: row.uuid, amount: row.reward.max.toString(), points: '' },
                    ];
                  }
                }
              }
              return row;
            }),
          );
        }
      } catch (err) {
        console.log(err);
        handleBackendError(err, 'Fetch list error');
      }
    };
    fetchUsers();
  }, [visible]);

  // useEffect(() => {}, []);
  return {
    catalog,
    openModal,
    modalParams,
    selectedReward,
    selectedBrandId,
    onChangeReward,
    onSubmitReward,
    openCatalogModal,
    closeCatalogModal,
    rowUsers,
    selectedRowUser,
    inputs,
    comment,
    openRedeemModal,
    search,
    sort,
    isUpdating,
    onChangeInputs,
    handleEditUser,
    handleOpenRedeem,
    closeRedeemModal,
    setComment,
    submitOrder,
    onChangeSearch,
    handleFetchNextUsers,
    onChangeSort,
    handleClearSelected,
  };
};
