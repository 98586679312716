import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Employee } from '../../models/Employee';
import {
  bambooIntegrationRequest,
  deleteEmployeeRequest,
  getEmployeesRequest,
  postCSVRequest,
  sendInvitationsRequest,
} from '../../lib/api/http/requests/employee';
import { RootState } from '../store';
import { openToast, setHeaderLoading } from './appSlice';
import { handleBackendError } from '../../utils/handleBackendError';
import { EmployeeSteps } from '../../models/EmployeeSteps';
import moment from 'moment';
import { getEmployeesStepsRequest } from '../../lib/api/http/requests/steps';

export interface EmployeesStepsState {
  data: {
    employeesSteps: EmployeeSteps[];
    employeesStepsCount: number;
  };
  meta: {
    employeesStepsLoading: boolean;
  };
}

const initialState: EmployeesStepsState = {
  data: {
    employeesSteps: [],
    employeesStepsCount: 0,
  },
  meta: {
    employeesStepsLoading: false,
  },
};

export const getEmployeesSteps = createAsyncThunk(
  'employeesSteps/getEmployeesSteps',
  async (params: { date_from: string; date_to: string }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setHeaderLoading(true));
      const { data } = await getEmployeesStepsRequest({
        page: 0,
        date_from: moment(params.date_from).format('YYYY-MM-DD'),
        date_to: moment(params.date_to).format('YYYY-MM-DD'),
      });
      const employeesStepsCount = data.totalElements;
      const employeesSteps: EmployeeSteps[] = data.content;
      return { employeesSteps, employeesStepsCount };
    } catch (error) {
      handleBackendError(error, 'Failed to fetch employees steps');
    } finally {
      thunkAPI.dispatch(setHeaderLoading(false));
    }
  },
);

export const fetchMoreEmployeesSteps = createAsyncThunk(
  'employeesSteps/fetchMoreEmployeesSteps',
  async (params: { page: number; date_from: string; date_to: string; callback?: () => void }, thunkApi) => {
    try {
      const { data } = await getEmployeesStepsRequest({
        page: params.page,
        date_from: moment(params.date_from).format('YYYY-MM-DD'),
        date_to: moment(params.date_to).format('YYYY-MM-DD'),
      });
      const employeesStepsCount = data.totalElements;
      let employeesSteps: EmployeeSteps[] = data.content;
      const state: RootState = thunkApi.getState() as RootState;
      employeesSteps = [...state.employeesSteps.data.employeesSteps, ...employeesSteps];
      return { employeesSteps, employeesStepsCount };
    } catch (error) {
      handleBackendError(error, 'Failed to fetch more employees');
    } finally {
      if (params.callback) params.callback();
    }
  },
);

export const employeesStepsSlice = createSlice({
  name: 'employeesSteps',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEmployeesSteps.fulfilled, (state, action) => {
      state.data.employeesSteps = action.payload?.employeesSteps || [];
      state.data.employeesStepsCount = action.payload?.employeesStepsCount || 0;
      state.meta.employeesStepsLoading = false;
    });
    builder.addCase(getEmployeesSteps.rejected, (state) => {
      state.meta.employeesStepsLoading = false;
    });
    builder.addCase(getEmployeesSteps.pending, (state) => {
      state.meta.employeesStepsLoading = true;
    });
    builder.addCase(fetchMoreEmployeesSteps.fulfilled, (state, action) => {
      state.data.employeesSteps = action.payload?.employeesSteps || [];
      state.data.employeesStepsCount = action.payload?.employeesStepsCount || 0;
      state.meta.employeesStepsLoading = false;
    });
    builder.addCase(fetchMoreEmployeesSteps.rejected, (state) => {
      state.meta.employeesStepsLoading = false;
    });
    builder.addCase(fetchMoreEmployeesSteps.pending, (state) => {
      state.meta.employeesStepsLoading = true;
    });
  },
});

export default employeesStepsSlice.reducer;
