import KeycloakJs, { KeycloakError } from 'keycloak-js';
import { Capacitor } from '@capacitor/core';
import { asyncGetAccountInfo, asyncGetCompanyInfo } from '../redux/slices/profileSlice';
import { getModuleSelector } from '../redux/slices/focusPageSlice';
import { dispatch } from '../index';
import { getWearableInfo, setAuthToken } from '../redux/slices/appSlice';
import { adapter } from './adapter';
import { SplashScreen } from '@capacitor/splash-screen';
import { resetIdentify, trackIdentify } from '../utils/analytics';
import { getModuleAdminSelector } from '../redux/slices/focusAdminSlice';
import { getContentTypes } from '../redux/slices/toolboxPageSlice';
import { handlerTrackEvent } from '../utils/handlerTrackEvent';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// const _kc = new Keycloak('/keycloak.json');

const config = {
  url: process.env.REACT_APP_AUTH_URL,
  realm: 'organisation',
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const _kc: KeycloakJs.KeycloakInstance = new KeycloakJs(config);
/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback: () => void) => {
  if (Capacitor.isNativePlatform()) {
    return initKeycloakMobile(onAuthenticatedCallback);
  } else
    _kc
      .init({
        onLoad: 'login-required',
        flow: 'standard',
        redirectUri: window.location.href,
        checkLoginIframe: false,
      })
      .then((authenticated: boolean) => {
        if (!authenticated) {
          console.log('user is not authenticated..!');
        }
        onAuthenticatedCallback();
      })
      .catch((e: Error) => console.error('So, em yup..', e));
};

const initKeycloakMobile = (onAuthenticatedCallback: () => void) => {
  _kc
    .init({
      adapter: adapter(_kc),
      // onLoad: 'login-required',
      responseMode: 'query',
      redirectUri: `${process.env.REACT_APP_REDIRECT_URL}`,
    })
    .then(() => {
      _kc.login();
    })
    .catch((e: Error) => {
      console.error('So, em yup..', e);
    })
    .then(() => {
      return _kc.loadUserProfile();
    })
    .finally(() => {
      SplashScreen.hide().catch(console.log);
      onAuthenticatedCallback();
    });
};

_kc.onAuthSuccess = () => {
  trackIdentify(_kc.subject);
  dispatch(setAuthToken(_kc.token));
  dispatch(asyncGetAccountInfo());
  dispatch(asyncGetCompanyInfo());
  dispatch(getModuleSelector({}));
  dispatch(getContentTypes({}));

  if (hasRole(['ADMIN'])) {
    dispatch(getModuleAdminSelector({}));
  }

  handlerTrackEvent({ type: 'LOGIN' });

  dispatch(getWearableInfo({ uuid: _kc.subject, initTerra: true }));
  console.log(`user logged in!`);
};

_kc.onAuthError = (errorData: KeycloakError) => {
  console.log('onAuthError', JSON.stringify(errorData));
};

_kc.onAuthLogout = () => {
  resetIdentify();
  dispatch({ type: 'STORE_RESET' });
  console.log(`user logged out!`);
};

_kc.onTokenExpired = () => {
  _kc
    .updateToken(10)
    .then(() => {
      // console.log('successfully get a new token', _kc.token);
    })
    .catch(() => {
      console.log('Failed to get a new token');
    });
};

// _kc.onReady = () => {
//   SplashScreen.hide().catch(console.log);
// };

const doLogin = _kc.login;

const doLogout = () => _kc.logout({ redirectUri: process.env.REACT_APP_REDIRECT_URL });

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback: any) => _kc.updateToken(10).then(successCallback).catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles: string[]) => roles.some((role) => _kc.hasRealmRole(role));

const getMineId = () => _kc.subject;

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  getMineId,
};

export default UserService;
