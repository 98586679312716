import { Box, ButtonBase, Fade, IconButton, Typography } from '@mui/material';
import { Colors } from '../../../../theme/colors';
import { styles } from './styles';
import React, { useState } from 'react';
import { ReactComponent as SupportIcon } from '../../../../assets/svg/feature/support.svg';
import { ReactComponent as BellIcon } from '../../../../assets/svg/feature/bell.svg';
import { ReactComponent as ActiveBellIcon } from '../../../../assets/svg/feature/active-bell.svg';
import { ReactComponent as TrophyIcon } from '../../../../assets/svg/feature/trophy.svg';
import { ReactComponent as AvatarIcon } from '../../../../assets/svg/feature/avatar.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/svg/feature/close.svg';

type Props = {
  isNewMessage: boolean;
  openTrophy: () => void;
  openNotifications: () => void;
  openSupport: () => void;
  openProfile: () => void;
};
export const MobileMenu: React.FC<Props> = ({
  isNewMessage,
  openTrophy,
  openNotifications,
  openSupport,
  openProfile,
}) => {
  const [openMenu, setOpenMenu] = useState(false);

  const handleOpenMenu = () => setOpenMenu(true);
  const handleCloseMenu = () => setOpenMenu(false);

  return (
    <>
      <Fade in={!openMenu} unmountOnExit>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            display: 'flex',
            alignItems: 'center',
            padding: { xs: 'unset', md: '10px 24px' },
            borderRadius: 100,
            height: { xs: 36, md: 'unset' },
            width: { xs: 36, md: 'fit-content' },
            background: Colors.blue[500],
            cursor: 'pointer',
            zIndex: 12,
          }}
          onClick={openMenu ? handleCloseMenu : handleOpenMenu}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: 16,
              justifyContent: 'space-between',
              height: 3,
              marginRight: { xs: 'auto', md: '8px' },
              marginLeft: { xs: 'auto', md: 'unset' },
            }}
          >
            <Box sx={{ height: 3, width: 3, borderRadius: 3, background: '#084349' }} />
            <Box sx={{ height: 3, width: 3, borderRadius: 3, background: '#084349' }} />
            <Box sx={{ height: 3, width: 3, borderRadius: 3, background: '#084349' }} />
          </Box>
        </Box>
      </Fade>

      <Fade in={openMenu} unmountOnExit>
        <IconButton
          sx={
            (styles.fillIconButton,
            {
              position: 'absolute',
              top: '-2px',
              right: 0,
              background: Colors.blue[800],
              width: 40,
              height: 40,
              zIndex: 12,
            })
          }
          onClick={handleCloseMenu}
        >
          <CloseIcon height={20} width={20} fill={Colors.blue[500]} />
        </IconButton>
      </Fade>

      <Fade in={openMenu}>
        <Box
          sx={{
            position: 'absolute',
            top: '-100px',
            right: '-96px',
            border: '64px solid rgba(123, 171, 175, 0.45)',
            width: '235px',
            height: '235px',
            borderRadius: '100%',
            zIndex: 11,
          }}
        >
          <Box sx={{ position: 'relative' }}>
            <ButtonBase
              sx={{
                position: 'absolute',
                top: '34px',
                left: '-52px',
                background: Colors.yellow[400],
                height: 40,
                width: 40,
                borderRadius: 20,
                alignItems: 'center',
                '&:hover': {
                  background: Colors.yellow[500],
                },
              }}
              onClick={() => {
                handleCloseMenu();
                openTrophy();
              }}
            >
              <Box sx={styles.buttonRoundIcon}>
                <TrophyIcon fill="white" />
              </Box>
              <Typography sx={styles.buttonLabel}>Trophy library</Typography>
            </ButtonBase>

            <IconButton
              sx={
                (styles.fillIconButton,
                {
                  background: Colors.blue[800],
                  width: 40,
                  height: 40,
                  position: 'absolute',
                  top: '83px',
                  left: '-36px',
                })
              }
              onClick={() => {
                handleCloseMenu();
                openNotifications();
              }}
            >
              {isNewMessage ? (
                <ActiveBellIcon height={20} width={20} fill="#1298A6" />
              ) : (
                <BellIcon height={20} width={20} fill="#1298A6" />
              )}
            </IconButton>

            <IconButton
              sx={
                (styles.fillIconButton,
                {
                  background: Colors.blue[800],
                  width: 40,
                  height: 40,
                  position: 'absolute',
                  top: '111px',
                  left: 0,
                })
              }
              onClick={() => {
                handleCloseMenu();
                openSupport();
              }}
            >
              <SupportIcon height={20} width={20} stroke="#1298A6" />
            </IconButton>

            <IconButton
              sx={
                (styles.fillIconButton,
                {
                  background: Colors.blue[800],
                  width: 40,
                  height: 40,
                  position: 'absolute',
                  top: '118px',
                  left: '46px',
                })
              }
              onClick={() => {
                handleCloseMenu();
                openProfile();
              }}
            >
              <AvatarIcon height={20} width={20} fill="#1298A6" />
            </IconButton>
          </Box>
        </Box>
      </Fade>
    </>
  );
};
