import { IonContent, IonPage } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { Box, Collapse, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import CustomSearchbar from '../../components/features/CustomSearchbar';

import SkeletonBlogPostCard from '../../components/common/SkeletonBlogPostCard';
import { BlogPostCard } from '../../components/features/BlogPostCard/BlogPostCard';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { getToolboxBlogs, getToolboxSearch } from '../../redux/slices/toolboxPageSlice';
import { DIMENSIONS } from '../../models/enum/DIMENSIONS';
import CloseIcon from '@mui/icons-material/Close';
import useViewIsVisible from '../../hooks/useViewIsVisible';
import { ANALYTICS_PAGE } from '../../models/enum/ANALYTICS_PAGE';
import { trackEvent } from '../../utils/analytics';
import { useHandlePostCard } from './hooks/useHandlePostCard';
import CustomNewHeader from '../../components/features/CustomNewHeader';
import { Colors } from '../../theme/colors';
import { ExpandMore } from '../../components/features/HeaderFilter/HeaderFilter';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactComponent as SortIcon } from '../../assets/svg/feature/sort.svg';
import { ReactComponent as FilterIcon } from '../../assets/svg/feature/filter.svg';
import { ReactComponent as SortDateIcon } from '../../assets/svg/feature/sortDate.svg';
import { ReactComponent as SortLatestIcon } from '../../assets/svg/feature/sort-latest.svg';
import { ReactComponent as SortTitleIcon } from '../../assets/svg/feature/sortTitle.svg';
import { ReactComponent as SportIcon } from '../../assets/svg/feature/dimentions/sport.svg';
import { ReactComponent as SleepIcon } from '../../assets/svg/feature/dimentions/sleep.svg';
import { ReactComponent as NutritionIcon } from '../../assets/svg/feature/dimentions/nutrition.svg';
import { ReactComponent as MentalFitnessIcon } from '../../assets/svg/feature/dimentions/mental-fitness.svg';
import { ReactComponent as WellBeingIcon } from '../../assets/svg/feature/well-being.svg';
import { ReactComponent as WorkIcon } from '../../assets/svg/feature/work.svg';
import { ReactComponent as FilterResetIcon } from '../../assets/svg/feature/filter-reset.svg';
import { ReactComponent as PhysicalHealthIcon } from '../../assets/svg/feature/physical-health.svg';
import CustomPaginator from '../../components/features/CustomPaginator';
import { FilterSelector } from '../../components/features/HeaderFilter/FilterSelector';
import { PreviewRoundItemProps } from '../../models/Shared';
import CustomFilterDropdown from './components/CustomFilterDropdown';
import { CustomButton } from '../../components/features/CustomButton';
import { MobileFilterContainer } from '../../components/features/HeaderFilter/MobileFilterContainer';
import { setShowCustomFab } from '../../redux/slices/appSlice';

const PAGE_SIZE = 9;

const sortingOptionList: PreviewRoundItemProps[] = [
  {
    label: 'Oldest',
    value: 'creationDate,asc',
    Icon: SortDateIcon,
    mainColor: Colors.blue[700],
    backgroundColor: Colors.blue[800],
    borderColor: Colors.blue[600],
  },
  {
    label: 'Latest',
    value: 'creationDate,desc',
    Icon: SortLatestIcon,
    mainColor: Colors.blue[700],
    backgroundColor: Colors.blue[800],
    borderColor: Colors.blue[600],
  },
  {
    label: 'Title',
    value: 'title,asc',
    Icon: SortTitleIcon,
    mainColor: Colors.blue[700],
    backgroundColor: Colors.blue[800],
    borderColor: Colors.blue[600],
  },
];

const dimensionOptionList: PreviewRoundItemProps[] = [
  {
    value: DIMENSIONS.PA,
    label: 'Physical activity',
    Icon: SportIcon,
    mainColor: Colors.red[400],
    borderColor: Colors.red[300],
    backgroundColor: Colors.red[500],
  },
  {
    value: DIMENSIONS.SL,
    label: 'Sleep',
    Icon: SleepIcon,
    mainColor: Colors.purple[400],
    borderColor: Colors.magenta[300],
    backgroundColor: Colors.purple[500],
  },
  {
    value: DIMENSIONS.NU,
    label: 'Nutrition',
    Icon: NutritionIcon,
    mainColor: Colors.orange[400],
    borderColor: Colors.yellow[300],
    backgroundColor: Colors.orange[500],
  },
  {
    value: DIMENSIONS.MW,
    label: 'Mental well-being',
    Icon: MentalFitnessIcon,
    mainColor: Colors.blue[400],
    borderColor: Colors.blue[300],
    backgroundColor: Colors.blue[500],
  },
  {
    value: DIMENSIONS.HL,
    label: 'Physical health',
    Icon: PhysicalHealthIcon,
    mainColor: Colors.yellow[400],
    borderColor: Colors.yellow[300],
    backgroundColor: Colors.yellow[500],
  },
  {
    value: DIMENSIONS.WB,
    label: 'Well-being',
    Icon: WellBeingIcon,
    mainColor: Colors.green[500],
    borderColor: Colors.green[300],
    backgroundColor: Colors.green[600],
  },
  {
    value: DIMENSIONS.WE,
    label: 'Working environment',
    Icon: WorkIcon,
    mainColor: Colors.magenta[400],
    borderColor: Colors.magenta[200],
    backgroundColor: Colors.magenta[500],
  },
];

export const ToolboxPage: React.FC = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const breakpointDown = useMediaQuery(theme.breakpoints.down(790));

  const { blogs, blogsCount, searches, contentTypes } = useSelector((root: RootState) => root.toolboxPage.data);
  const blogsLoading = useSelector((root: RootState) => root.toolboxPage.meta.blogsLoading);
  const [expanded, setExpanded] = useState<boolean>(false);

  const [tab, setTab] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState<string>(sortingOptionList[1].value);
  const [dimension, setDimension] = useState<DIMENSIONS[]>([]);
  const [searchInput, setSearchInput] = useState<string>('');
  const [actualSearch, setActualSearch] = useState<string>('');
  const [selectedTag, setSelectedTag] = useState<string | undefined>(undefined);
  // const actualSearch = useDebounce(searchInput, 1000);
  const [page, setPage] = useState<number>(0);
  const [contentTypeOptions, setContentTypeOptions] = useState<string[]>([]);
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const visible = useViewIsVisible(ANALYTICS_PAGE.TOOLBOX);

  const [showResetFilters, setShowResetFilters] = useState(false);

  const { getPostCardProps } = useHandlePostCard();
  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop(1000);
  };

  useEffect(() => {
    //Scroll to top when fetch new blogs
    scrollToTop();
  }, [blogs]);

  useEffect(() => {
    const content = contentTypes ? Object.keys(contentTypes) : [];
    setContentTypeOptions(content);
  }, [contentTypes]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setActualSearch(searchInput);
      setPage(0);
    }, 1000);
    return () => {
      clearTimeout(handler);
    };
  }, [searchInput]);

  useEffect(() => {
    if (actualSearch || tab.length || dimension.length) {
      trackEvent('search', {
        page: ANALYTICS_PAGE.TOOLBOX,
        content_type: tab,
        dimension: dimension,
        search_text: actualSearch,
      });
    }
  }, [actualSearch, dimension, tab]);

  useEffect(() => {
    if (actualSearch.length || tab.length || dimension.length) {
      setShowResetFilters(true);
      return;
    }
    setShowResetFilters(false);
  }, [actualSearch, dimension, tab]);

  useEffect(() => {
    if (xsDown && expanded) {
      dispatch(setShowCustomFab(false));
      return;
    }
    dispatch(setShowCustomFab(true));
  }, [xsDown, expanded]);

  useEffect(() => {
    if (!visible) return;

    const entries = contentTypes ? Object.entries(contentTypes) : [];
    const selectedContentType: string[] = [];
    entries.forEach(([key, value]) => {
      if (tab.includes(key)) {
        selectedContentType.push(value);
      }
    });

    dispatch(
      getToolboxBlogs({
        pagination: {
          page,
          size: PAGE_SIZE,
          contentType: selectedContentType.length ? selectedContentType : undefined,
          dimension: !dimension.length ? undefined : dimension,
          searchText: !actualSearch.length ? undefined : actualSearch,
          sort: sortBy,
          tag: selectedTag,
        },
      }),
    );
  }, [dispatch, selectedTag, page, tab, sortBy, actualSearch, dimension, visible]);

  useEffect(() => {
    if (!visible) return;

    dispatch(getToolboxSearch({}));
  }, [dispatch, visible]);

  const handleChangeSearch = (s: string) => {
    setSearchInput(s);
  };

  const handleChangeDimension = (value: DIMENSIONS, isSelected: boolean) => {
    const currentDimension = isSelected ? dimension.filter((item) => item !== value) : [...dimension, value];
    setDimension(currentDimension);

    setPage(0);
  };

  const handleSelectTag = (t: string) => {
    setSelectedTag(t);
    setPage(0);
  };

  const handleChangeSortBy = (s: string) => {
    setSortBy(s);
    setPage(0);
  };

  const handleChangeCategory = (value: string[]) => {
    setTab(value);

    setPage(0);
  };

  const handleClickSearch = (s: string) => {
    if (searches) {
      const findSearch = searches.find((single) => single.title === s);
      if (findSearch) window.open(findSearch.location, '_blank');
    }
  };
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleResetFilters = () => {
    setSearchInput('');
    setTab([]);
    setDimension([]);
    setPage(0);
  };
  const getBlogs = () => {
    return (
      <Grid container item xs={12} spacing={4} sx={{ pt: '32px!important' }}>
        {!blogs || blogsLoading ? (
          [1, 1, 1, 1].map((i, index) => (
            <Grid item md={4} table={6} xs={12} key={index}>
              <SkeletonBlogPostCard />
            </Grid>
          ))
        ) : blogs?.length ? (
          (blogs ?? []).map((blog, index) => (
            <Grid
              item
              md={4}
              table={6}
              xs={12}
              key={blog.title + index}
              flex={1}
              className={'blogGridItem'}
              sx={{ pt: { xs: !index ? '32!important' : '32px', sm: '32px!important' } }}
            >
              <BlogPostCard {...getPostCardProps(blog, handleSelectTag)} />
            </Grid>
          ))
        ) : (
          <Grid item sx={{ mt: 6, color: 'text.disabled' }} container justifyContent={'center'}>
            {'No content available'}
          </Grid>
        )}
      </Grid>
    );
  };

  const getDimensionDropdown = () => {
    return (
      <FilterSelector
        data={dimensionOptionList}
        selected={dimension}
        onClickItem={(value: string, isSelected: boolean) => () =>
          handleChangeDimension(value as DIMENSIONS, isSelected)}
        roundIconSize={32}
        iconSize={12}
        roundIconTextSx={{ fontSize: '14px', mr: '16px', ml: '8px' }}
        sx={{
          flexWrap: 'wrap',
          width: {
            sx: '100%',
            sm: 400,
            table: 710,
            md: 860,
          },
          [theme.breakpoints.up(1401)]: {
            width: 'fit-content',
            height: '40px',
            borderRadius: '30px',
          },
          height: { xs: '100%' },
          borderRadius: { xs: '20px' },
          p: { xs: '8px', sm: '4px' },
        }}
      />
    );
  };

  const getSortDropdown = () => {
    return (
      <FilterSelector
        data={sortingOptionList}
        selected={[sortBy]}
        onClickItem={(value: string, isSelected: boolean) => () => handleChangeSortBy(value)}
        roundIconSize={32}
        iconSize={12}
        roundIconTextSx={{ fontSize: '14px', mr: '16px', ml: '8px' }}
        sx={{
          borderRadius: { xs: '20px', lg: '30px' },
          p: { xs: '8px', sm: '4px' },
          flexDirection: { xs: 'row', sm: 'row' },
          flexWrap: { xs: 'wrap', sm: 'nowrap' },
          gap: { xs: '8px', sm: 0 },
          height: { xs: '100%', lg: '40px' },
        }}
        roundIconButtonSx={{ width: { xs: '47.6%', sm: 'auto' }, mb: '0!important' }}
      />
    );
  };

  const Filters = () => (
    <Grid>
      <Typography
        sx={{ fontSize: '14px', fontWeight: '700', color: Colors.gray[700], display: { xs: 'block', sm: 'none' } }}
      >
        Filters
      </Typography>

      <Grid item sx={{ mt: { xs: '8px', table: '16px', md: 0, lg: 0 } }}>
        {getDimensionDropdown()}
      </Grid>
      <Grid
        sx={{
          display: 'flex',
          alignItems: breakpointDown ? 'flex-start' : 'center',
          flexDirection: breakpointDown ? 'column' : 'row',
          mt: { xs: '16px', sm: '24px' },
        }}
      >
        <Grid
          item
          sx={{ my: '4px', mr: { xs: 0, sm: '16px' }, width: { xs: '100%', sm: 'auto' }, mb: { xs: '40px', sm: 0 } }}
        >
          <CustomFilterDropdown
            list={contentTypeOptions}
            selected={tab}
            setSelected={handleChangeCategory}
            placeholder={'Type of content'}
            fullWidth
          />
        </Grid>

        <Typography
          sx={{ fontSize: '14px', fontWeight: '700', color: Colors.gray[700], display: { xs: 'block', sm: 'none' } }}
        >
          Sorting
        </Typography>

        <Grid item sx={{ mt: { xs: '8px', sm: breakpointDown ? '16px' : '4px' }, width: { xs: '100%', sm: 'auto' } }}>
          {getSortDropdown()}
        </Grid>
      </Grid>
    </Grid>
  );

  const getResponsiveHeader = () => {
    return (
      <Grid sx={{ display: 'flex' }}>
        <CustomSearchbar
          width={mdDown ? '100%' : 320}
          searchInput={searchInput}
          setSearchInput={handleChangeSearch}
          handleSearch={handleClickSearch}
          autoCompleteList={searches ? searches.map((s) => s.title) : []}
          enableSearchList={visible}
        />
        {!xsDown && showResetFilters && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '91px',
              height: '36px',
              background: Colors.blue[100],
              border: '1px solid #A2E5EC',
              borderRadius: '100px',
              px: '16px',
              marginLeft: '16px',
              cursor: 'pointer',
            }}
            onClick={handleResetFilters}
          >
            <FilterResetIcon width={16} height={16} fill={Colors.blue[500]} />
            <Typography
              variant="h5"
              sx={{
                ml: '4px',
                textAlign: 'center',
                color: Colors.blue[500],
                fontSize: 14,
              }}
            >
              Reset
            </Typography>
          </Box>
        )}
        <Grid
          onClick={handleExpandClick}
          container
          flexWrap={'nowrap'}
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{
            background: Colors.gray[100],
            borderRadius: '30px',
            maxWidth: '106px',
            padding: '2px',
            marginLeft: '16px',
            cursor: 'pointer',
          }}
        >
          <FilterIcon height={16} style={{ margin: '0 0 0 14px' }} fill={Colors.gray[400]} />

          <Box
            sx={{
              display: 'block',
              height: 16,
              width: '1px',
              ml: '8px',
              mr: '8px',
              background: Colors.gray[150],
            }}
          />

          <SortIcon height={16} style={{ margin: '0 8px 0 0' }} fill={Colors.gray[400]} />
          <Grid item>
            <ExpandMore
              expand={expanded}
              aria-expanded={expanded}
              aria-label="show more"
              sx={{
                background: showResetFilters ? '#26ACBA' : Colors.gray[150],
                border: showResetFilters ? '1px solid #73D8E2' : '1px solid #E9EDEF',
                color: showResetFilters ? Colors.white : Colors.gray[500],
                '&:hover': {
                  background: showResetFilters ? '#26ACBA' : Colors.gray[150],
                },
              }}
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  return (
    <IonPage>
      <CustomNewHeader
        label={'Your Trusted Toolbox'}
        tooltip={
          'A large variety of scientifically credible health and wellness resources from all over the web curated by our experts to educate you on well-being aspects.'
        }
      >
        {getResponsiveHeader()}
      </CustomNewHeader>
      {!xsDown && (
        <Collapse in={expanded} timeout="auto">
          <Box sx={{ background: Colors.gray[50], paddingBottom: '16px' }}>
            <Container maxWidth={'lg'}>
              <Filters />
            </Container>
          </Box>
        </Collapse>
      )}
      <Container maxWidth={'lg'}>
        <Grid
          item
          xs={12}
          container
          justifyContent={selectedTag ? 'space-between' : 'flex-end'}
          sx={{
            zIndex: 3,
            mt: { xs: 0, sm: expanded ? 0 : '4px' },
            mb: '16px',
            flexDirection: { xs: 'column', mobile: 'row' },
            alignItems: 'center',
          }}
        >
          {selectedTag && (
            <Box
              sx={{
                border: '1px solid #A2E5EC',
                background: Colors.blue[100],
                padding: '4px 12px 4px 16px',
                borderRadius: '99px',
                height: '28px',
                mb: { xs: '16px', mobile: 0 },
              }}
            >
              <Grid container wrap={'nowrap'} alignItems={'center'}>
                <Grid item>
                  <Typography sx={{ fontSize: 12, color: Colors.blue[500], fontWeight: 600, whiteSpace: 'nowrap' }}>
                    #{selectedTag}
                  </Typography>
                </Grid>
                <Grid item container alignItems={'center'} sx={{ ml: '4px' }}>
                  <CloseIcon
                    style={{ fill: Colors.blue[500], width: 16, height: 16, cursor: 'pointer' }}
                    onClick={() => setSelectedTag(undefined)}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
          <Grid item sx={{ my: '4px' }}>
            <CustomPaginator currentPage={page} pageSize={PAGE_SIZE} setPage={setPage} totalItems={blogsCount} />
          </Grid>
        </Grid>
      </Container>
      <IonContent style={{ '--padding-bottom': '36px' }} ref={contentRef} scrollEvents={true}>
        <Container maxWidth={'lg'}>
          <Grid container>
            <Grid container item xs={12} spacing={4} sx={{ mb: 6, zIndex: 1 }}>
              {getBlogs()}
            </Grid>
          </Grid>
        </Container>
      </IonContent>

      {xsDown && (
        <MobileFilterContainer
          show={expanded}
          title={'Filters and sorting'}
          handleClose={handleExpandClick}
          action={
            <Box sx={{ mt: '24px' }}>
              {showResetFilters && (
                <CustomButton
                  type={'bigBlue'}
                  text={'Reset'}
                  inputProps={{
                    variant: 'contained',
                    startIcon: <FilterResetIcon width={16} height={16} fill={Colors.white} />,
                  }}
                  onClick={() => {
                    handleResetFilters();
                    handleExpandClick();
                  }}
                  width={'100%'}
                />
              )}
              <CustomButton
                type={'bigLightBlue'}
                text={'Close'}
                onClick={handleExpandClick}
                width={'100%'}
                sx={{ mt: showResetFilters ? '16px' : 0 }}
              />
            </Box>
          }
        >
          <Filters />
        </MobileFilterContainer>
      )}
    </IonPage>
  );
};

export default ToolboxPage;
