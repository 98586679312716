import { CONTENT_TYPE } from '../../../models/enum/CONTENT_TYPE';
import { ReactComponent as PodcastIcon } from '../../../assets/svg/feature/podcast.svg';
import { ReactComponent as PaperIcon } from '../../../assets/svg/feature/paper.svg';
import { ReactComponent as VideoIcon } from '../../../assets/svg/feature/video.svg';
import { ReactComponent as BookIcon } from '../../../assets/svg/feature/book.svg';
import { ReactComponent as EbookIcon } from '../../../assets/svg/feature/ebook.svg';
import { ReactComponent as ProductIcon } from '../../../assets/svg/feature/product.svg';
import { ReactComponent as ReportIcon } from '../../../assets/svg/feature/report.svg';
import { ReactComponent as ToolsIcon } from '../../../assets/svg/feature/tools.svg';
import { ReactComponent as ArticleIcon } from '../../../assets/svg/feature/article.svg';
import { ReactComponent as BlogIcon } from '../../../assets/svg/feature/blog.svg';

export const categoryByType: {
  [key: string]: {
    label: string;
    Icon: React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
      }
    >;
  };
} = {
  [CONTENT_TYPE.ARTICLE]: { label: 'Article', Icon: PaperIcon },
  [CONTENT_TYPE.CASE_STUDY]: { label: 'Case study', Icon: BookIcon },
  [CONTENT_TYPE.BOOKS]: { label: 'Books', Icon: BookIcon },
  [CONTENT_TYPE.EBOOK]: { label: 'Ebook', Icon: EbookIcon },
  [CONTENT_TYPE.PODCAST]: { label: 'Podcast', Icon: PodcastIcon },
  [CONTENT_TYPE.PRODUCTS]: { label: 'Products', Icon: ProductIcon },
  [CONTENT_TYPE.REPORT]: { label: 'Report', Icon: ReportIcon },
  [CONTENT_TYPE.TOOL]: { label: 'Tool', Icon: ToolsIcon },
  [CONTENT_TYPE.VIDEO]: { label: 'Video', Icon: VideoIcon },
  [CONTENT_TYPE.JOURNALS]: { label: 'Journals', Icon: ArticleIcon },
  [CONTENT_TYPE.BLOG]: { label: 'Blog', Icon: BlogIcon },
};
