import { IonApp, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/animations.css';
import React, { useEffect, useState } from 'react';
import ProtectedRoutes from './routing/ProtectedRoutes';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useDispatch, useSelector } from 'react-redux';
import CustomToast from './components/structure/CustomToast';
import { RootState } from './redux/store';
import { closeNotification, closeToast, completeTutorial, setWifiConnected } from './redux/slices/appSlice';
// Import Swiper styles
import 'swiper/css';
import TooltipSupportModal from './components/modals/TooltipSupportModal';

import AppListeners from './pages/AppListeners';
import { Network } from '@capacitor/network';
import PushNotificationToast from './components/structure/PushNotificationToast';
import { Portal } from '@mui/material';
import { initializeAnalytics } from './utils/analytics';
import { Capacitor } from '@capacitor/core';

console.log(Capacitor.getPlatform());

setupIonicReact();
initializeAnalytics();

const App: React.FC = () => {
  const dispatch = useDispatch();
  const [supportModal, setSupportModal] = useState<boolean>(false);

  const [networkToast, setNetworkToast] = useState<boolean>(false);
  const { toast, notification } = useSelector((state: RootState) => state.app);
  const { first_login } = useSelector((state: RootState) => state.profile.data.accountInfo);

  useEffect(() => {
    Network.getStatus().then((status) => dispatch(setWifiConnected(status.connected)));
    Network.addListener('networkStatusChange', (status: any) => {
      dispatch(setWifiConnected(status.connected));
      if (!status.connected) {
        setNetworkToast(true);
      }
    });
  }, []);

  useEffect(() => {
    if (first_login) setSupportModal(true);
  }, [first_login]);

  const handleSupportModalClose = () => {
    // Tooltip modal tutorial
    dispatch(completeTutorial());
    setSupportModal(false);
  };

  const handleCloseToast = () => {
    dispatch(closeToast());
  };

  const handleCloseNotification = () => {
    dispatch(closeNotification());
  };

  return (
    <IonApp>
      <IonReactRouter>
        <AppListeners />
        <ProtectedRoutes />
        <Portal>
          <CustomToast
            open={networkToast}
            onClose={() => setNetworkToast(false)}
            message={'No internet connection'}
            type={'error'}
            position={'top'}
          />
          <CustomToast open={toast.open} onClose={handleCloseToast} message={toast.text} type={toast.type} />
        </Portal>
        <TooltipSupportModal open={supportModal} handleClose={handleSupportModalClose} routingEnabled />
        <PushNotificationToast
          open={notification.open}
          onClose={handleCloseNotification}
          message={notification.text}
          category={notification.category}
          challengeUuid={notification.challengeUuid}
          challengeActionSource={notification.challengeActionSource}
          isUser={notification.isUser}
          isParticipant={notification.isParticipant}
        />
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
