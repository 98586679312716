import { IQuestionnaireAnswers } from '../../../../models/Questionnaire';
import { getRequest, postRequest } from '../../../../services/HttpService';

export const getQuestionnaire = () => {
  return getRequest('/questionnaire-service/surveys/answers/user/self_assessment');
};

export const submitQuestionnaire = (answer: IQuestionnaireAnswers) => {
  return postRequest('/questionnaire-service/surveys/answers', { ...answer });
};
