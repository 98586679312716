import { Avatar, Box, List, Skeleton, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { Colors } from '../../../theme/colors';
import IconPlaceholder from '../../../assets/svg/placeholder_avatar.svg';
import { ReactComponent as TrophyIcon } from '../../../assets/svg/feature/awards/trophy.svg';
import { ReactComponent as ArrowUpIcon } from '../../../assets/svg/feature/arrow-up.svg';
import theme from '../../../theme';
import { numberWithSpaces } from '../../../utils/numberWithSpace';
import { LeaderBoardItemData, LeaderBoardType } from '../../../models/Shared';

export type ColorType = 'default' | 'top' | 'award';

type LeaderBoardListProps = {
  outLeaderBoardList?: [LeaderBoardItemData, LeaderBoardItemData];
  place?: number;
  data?: LeaderBoardItemData[];
  target?: number | null;
  isLoading?: boolean;
  userUuid?: string;
  type: LeaderBoardType;
  isGracePeriodEnd: boolean;
  triggerLimit?: number | null;
  listRef: React.RefObject<HTMLUListElement>;
  handleScrollEvent: () => void;
};

export const LeaderBoardItem: React.FC<
  LeaderBoardItemData & {
    isYou?: boolean;
    valueLeft?: number;
    target?: number | null;
    color?: ColorType;
  }
> = ({ isYou = false, avatar, place, name, target, score, valueLeft = 0, color = 'default' }) => {
  const matchXs = useMediaQuery(theme.breakpoints.up('md'));
  const percent = target ? (score >= target ? 100 : Math.round((score / target) * 100)) : 0;
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        height: { xs: '56px', md: '64px' },
        marginLeft: '8px',
        borderRadius: '10px',
        paddingInline: { xs: '16px', md: '24px' },
        background:
          color === 'award'
            ? `linear-gradient(91.55deg, ${Colors.blue[100]} 2.44%, #E8F8FA 100%)`
            : color === 'top'
            ? `linear-gradient(88.52deg, ${Colors.yellow[100]} 2.71%, ${Colors.yellow[50]} 103.2%)`
            : isYou
            ? `linear-gradient(88.79deg, ${Colors.magenta[150]} 0%, ${Colors.magenta[50]} 104.83%)`
            : `linear-gradient(89.56deg, ${Colors.gray[50]} -1.42%, #F7F9F9 99.73%)`,
        marginBottom: { xs: '8px', md: '16px' },
      }}
    >
      {valueLeft && valueLeft < 0 ? (
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            top: 0,
            right: '10px',
            height: 20,
            transform: 'translateY(-50%)',
            border: `1px solid ${Colors.orange[300]}`,
            background: Colors.orange[500],
            paddingInline: '8px',
            borderRadius: '20px',
          }}
        >
          <ArrowUpIcon
            height={matchXs ? '10px' : '12px'}
            width={matchXs ? '10px' : '12px'}
            fill={Colors.white}
            style={{ marginRight: '8px' }}
          />
          <Typography
            sx={{ fontSize: { xs: '10px', md: '14px' }, fontWeight: 900, color: Colors.white, marginTop: '4px' }}
          >
            {numberWithSpaces(valueLeft)}
          </Typography>
        </Box>
      ) : null}
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          top: '50%',
          display: 'flex',
          height: { xs: '24px', md: '30px' },
          width: { xs: '24px', md: '30px' },
          borderRadius: { xs: '24px', md: '30px' },
          border: `2px solid ${Colors.white}`,
          justifyContent: 'center',
          alignItems: 'center',
          background: isYou
            ? `linear-gradient(268.52deg, ${Colors.magenta[400]} -2.46%, ${Colors.magenta[400]} 99.53%)`
            : color === 'award'
            ? `linear-gradient(268.6deg, ${Colors.blue[600]} 8.42%, ${Colors.blue[500]} 99.55%)`
            : color === 'top'
            ? `linear-gradient(257.35deg, ${Colors.yellow[500]} 16.7%, ${Colors.yellow[300]} 78.11%)`
            : `linear-gradient(236.78deg, ${Colors.gray[300]} -0.57%, ${Colors.gray[100]} 82.01%)`,
          transform: 'translateX(-50%) translateY(-50%)',
        }}
      >
        {color === 'award' ? (
          <TrophyIcon height={matchXs ? '16px' : '12px'} width={matchXs ? '16px' : '12px'} fill={Colors.white} />
        ) : (
          <Typography sx={{ fontSize: { xs: '10px', md: '12px' }, color: Colors.white, fontWeight: 800 }}>
            {place}
          </Typography>
        )}
      </Box>

      <Avatar
        alt={name}
        sx={{
          height: { xs: 40, md: 48 },
          width: { xs: 40, md: 48 },
          border: `1px solid ${
            color === 'award'
              ? '#E8F8FA'
              : color === 'top'
              ? Colors.yellow[50]
              : isYou
              ? Colors.magenta[300]
              : Colors.white
          }`,
          marginRight: { xs: '8px', md: '16px' },
        }}
        src={avatar || IconPlaceholder}
      />
      <Box sx={{ flex: 1 }}>
        <Typography sx={{ fontSize: { xs: '10px', md: '12px' }, fontWeight: 800, color: Colors.gray[700] }}>
          {name}
        </Typography>
        <Box
          sx={{
            position: 'relative',
            overflow: 'hidden',

            height: { xs: 18, md: target ? 16 : 24 },
            width: '100%',
            borderRadius: '5px',
            border: `1px solid ${
              color === 'award'
                ? Colors.blue[500]
                : color === 'top'
                ? Colors.yellow[150]
                : isYou
                ? Colors.magenta[300]
                : Colors.gray[100]
            }`,
            background:
              color == 'award'
                ? Colors.blue[500]
                : color === 'top'
                ? Colors.yellow[150]
                : isYou
                ? Colors.magenta[300]
                : Colors.gray[100],
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              top: 0,
              bottom: 0,
              left: 0,
              width: !target || score >= target ? '100%' : `${Math.round((score / target) * 100)}%`,
              borderRadius: '4px',
              background:
                color === 'award'
                  ? `linear-gradient(270deg, ${Colors.blue[600]} 0%, ${Colors.blue[500]} 88%)`
                  : color === 'top'
                  ? `linear-gradient(268.32deg, ${Colors.yellow[500]} -2.51%, ${Colors.yellow[200]} 118.93%)`
                  : isYou
                  ? `linear-gradient(268.52deg, ${Colors.magenta[400]} -2.46%, ${Colors.magenta[400]} 99.53%)`
                  : `linear-gradient(270deg, ${Colors.gray[300]} 0%, ${Colors.gray[100]} 100%)`,
            }}
          >
            <Typography sx={{ position: 'relative', fontSize: '10px', color: Colors.white, lineHeight: 1 }}>
              {!target ? numberWithSpaces(score) : percent >= 7 ? `${percent}%` : ''}
            </Typography>
          </Box>
        </Box>
        {target ? (
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <Typography
              sx={{
                fontSize: '10px',
                color:
                  color === 'award'
                    ? Colors.blue[600]
                    : color === 'top'
                    ? Colors.yellow[600]
                    : isYou
                    ? Colors.magenta[400]
                    : Colors.gray[500],
              }}
            >
              {numberWithSpaces(score)}
            </Typography>
            <Typography
              sx={{
                fontSize: '10px',
                color:
                  color === 'award'
                    ? Colors.blue[400]
                    : color === 'top'
                    ? Colors.yellow[400]
                    : isYou
                    ? Colors.magenta[200]
                    : Colors.gray[300],
              }}
            >
              {numberWithSpaces(target)}
            </Typography>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export const LeaderBoardList: React.FC<LeaderBoardListProps> = ({
  data = [],
  isLoading = false,
  place,
  userUuid,
  target,
  type,
  triggerLimit = 0,
  isGracePeriodEnd,
  outLeaderBoardList,
  listRef,
  handleScrollEvent,
}) => {
  return (
    <Box>
      {isLoading ? (
        new Array(5).fill('1').map((_, idx) => (
          <Skeleton
            key={`leaderBoard${idx}`}
            variant="rectangular"
            sx={{
              width: '100%',
              height: '64px',
              marginLeft: '8px',
              background: Colors.gray[300],
              borderRadius: '10px',
              marginTop: '16px',
              marginBottom: '16px',
            }}
          />
        ))
      ) : (
        <>
          <List
            ref={listRef}
            onScroll={handleScrollEvent}
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
              paddingInline: '16px',
              maxHeight: { xs: 416, table: 'unset' },
              height: {
                xs: typeof place !== 'number' || place <= 5 ? 320 : 192,
                table: typeof place !== 'number' || place <= 11 ? 704 : 576,
                md: typeof place !== 'number' || place <= 5 ? 400 : 240,
              },
              overflow: 'auto',
              '&::-webkit-scrollbar': {
                width: '5px !important',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#D3D7DA',
              },
            }}
          >
            {data.map((item, idx) => {
              const isYou = item.uuid === userUuid;
              const valueLeft = idx > 0 ? item.score - data[idx - 1].score : 0;
              let itemType: ColorType = 'default';
              if (type === 'Top' && item.score >= 0) {
                if (isGracePeriodEnd) {
                  if (item.isAwarded) {
                    itemType = 'award';
                  }
                } else {
                  if (triggerLimit && triggerLimit > idx) {
                    itemType = 'top';
                  }
                }
              }
              if ((type === 'Target' || type === 'Completion') && item.score > 0) {
                if (item.isAwarded) {
                  itemType = 'award';
                } else {
                  if (idx < 3) {
                    itemType = 'top';
                  }
                }
              }
              if (isYou) {
                return (
                  <LeaderBoardItem
                    key={item.uuid}
                    {...item}
                    target={target}
                    isYou={true}
                    valueLeft={valueLeft}
                    color={itemType}
                  />
                );
              }
              return <LeaderBoardItem key={item.uuid} {...item} target={target} color={itemType} />;
            })}
          </List>
          {outLeaderBoardList ? (
            <Box sx={{ paddingInline: '16px' }}>
              <LeaderBoardItem
                key={outLeaderBoardList[0].uuid}
                target={target}
                {...outLeaderBoardList[0]}
                color={outLeaderBoardList[0].isAwarded ? 'award' : 'default'}
              />
              <LeaderBoardItem
                key={outLeaderBoardList[1].uuid}
                {...outLeaderBoardList[1]}
                isYou={true}
                color={outLeaderBoardList[1].isAwarded ? 'award' : 'default'}
                target={target}
                valueLeft={outLeaderBoardList[1].score - outLeaderBoardList[0].score}
              />
            </Box>
          ) : null}
        </>
      )}
    </Box>
  );
};
