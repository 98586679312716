import React, { useRef, useState } from 'react';
import { Box, Grid, styled, Typography, Popover } from '@mui/material';
import { ReactComponent as PieIcon } from '../../assets/svg/PieChart.svg';
import { DateRangePicker, Range } from 'react-date-range';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import moment from 'moment';

const StyledBox = styled(Box)({
  height: 40,
  minWidth: 210,
  fontSize: 14,
  fontWeight: 700,
  background: 'white',
  borderRadius: '999px',
  paddingInline: '18px 12px',
  boxShadow: 'var(--shadow-button)',
  userSelect: 'none',
  whiteSpace: 'nowrap',
  position: 'relative',
});

type CustomRangePickerProps = {
  disabled?: boolean;
  setRange: (range: Range[]) => void;
  range: Range[];
  mini?: boolean;
  maxDate?: Date;
  minDate?: Date;
};

const defaultRange = {
  startDate: new Date(),
  endDate: new Date(),
  key: 'selection',
};

export const CustomRangePicker: React.FC<CustomRangePickerProps> = ({
  disabled,
  range,
  setRange,
  mini = false,
  minDate,
  maxDate,
}) => {
  const selfRef = useRef(null);
  const [datePicked, setDatePicked] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleSelect = (ranges: any) => {
    const tempRange: Range = ranges.selection;
    setRange([tempRange]);
    setDatePicked(true);
    if (!moment(tempRange.startDate).isSame(tempRange.endDate)) close();
  };

  const toggle = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    setExpanded(!expanded);
  };

  const open = () => {
    setExpanded(true);
    if (selfRef && selfRef !== null) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      selfRef.current.focus();
    }
  };

  const close = () => {
    setExpanded(false);
  };

  const clear = () => {
    setRange([defaultRange]);
    setDatePicked(false);
    setExpanded(false);
  };

  const getFormattedDate = () => {
    const startDate = moment(range[0].startDate).format('MMM D');
    const endDate = moment(range[0].endDate).format('MMM D');
    if (startDate === endDate) {
      return startDate;
    } else {
      return `${startDate} - ${endDate}`;
    }
  };

  return (
    <>
      <StyledBox tabIndex={-1} sx={mini ? { minWidth: 160 } : undefined} onClick={toggle}>
        <Grid
          container
          wrap={'nowrap'}
          sx={{ height: '100%', opacity: disabled ? 0.4 : 1, cursor: disabled ? 'default' : 'pointer' }}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Grid item container alignItems={'center'}>
            <Typography sx={{ fontSize: mini ? 12 : 16, fontWeight: 'bold' }}>
              {datePicked ? getFormattedDate() : 'Custom Date Range'}
            </Typography>
          </Grid>
          {expanded ? (
            <Grid item onClick={clear} container alignItems={'center'} justifyContent={'flex-end'}>
              <HighlightOffIcon color={'primary'} />
            </Grid>
          ) : (
            <Grid item onClick={open} container alignItems={'center'} justifyContent={'flex-end'}>
              <PieIcon />
            </Grid>
          )}
        </Grid>
      </StyledBox>
      <Popover
        open={expanded}
        onClose={close}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          sx: {
            '& .rdrDefinedRangesWrapper': {
              display: 'none !important',
            },
            borderRadius: '24px',
            boxShadow: 'var(--shadow-shape)',
            mt: 2,
          },
        }}
      >
        <DateRangePicker
          ranges={range}
          minDate={minDate}
          onChange={handleSelect}
          showDateDisplay={false}
          showMonthAndYearPickers={false}
          rangeColors={['#00B6BE']}
          maxDate={maxDate}
        />
      </Popover>
    </>
  );
};
