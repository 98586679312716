import { deleteRequest, getRequest, postRequest, putRequest } from '../../../../services/HttpService';
import { CHALLENGE_STATUS } from '../../../../models/enum/CHALLENGE_STATUS';
import { COMPETITION } from '../../../../models/enum/COMPETITION';
import { DIMENSION } from '../../../../models/enum/DIMENSION';
import { PublishFormData } from '../../../../pages/TemplatesAdminPage/usePublishHandle';
import { CHALLENGE_TYPE } from '../../../../models/enum/CHALLENGE_TYPE';

export const getChallengesRequest = (params: {
  page?: number;
  size?: number;
  metric?: string;
  searchText: string;
  publisher?: string;
  challengeStatus?: CHALLENGE_STATUS[];
  challengePillars?: DIMENSION[];
  competition?: COMPETITION[];
  onlyCorporate?: boolean;
}) => {
  const search: {
    queryField: 'challengePillars' | 'competition' | 'metricType' | 'challengeStatus' | 'title' | 'publisher';
    queryValues: string[] | string;
  }[] = [];

  if (params.challengeStatus && params.challengeStatus.length > 0) {
    search.push({
      queryField: 'challengeStatus',
      queryValues: params.challengeStatus,
    });
  }

  if (params.challengePillars && params.challengePillars.length > 0) {
    search.push({ queryField: 'challengePillars', queryValues: params.challengePillars });
  }

  if (
    params.competition &&
    params.competition.find((item) => item === COMPETITION.TEAM) &&
    params.competition.find((item) => item === COMPETITION.INDIVIDUAL)
  ) {
    search.push({ queryField: 'competition', queryValues: [COMPETITION.INDIVIDUAL, COMPETITION.TEAM] });
  } else if (params.competition && params.competition.find((item) => item === COMPETITION.TEAM)) {
    search.push({ queryField: 'competition', queryValues: [COMPETITION.TEAM] });
  } else if (params.competition && params.competition.find((item) => item === COMPETITION.INDIVIDUAL)) {
    search.push({ queryField: 'competition', queryValues: [COMPETITION.INDIVIDUAL] });
  }

  if (params.searchText.trim().length > 0) {
    search.push({ queryField: 'title', queryValues: [params.searchText.trim()] });
  }
  if (params.metric && params.metric.trim().length > 0) {
    search.push({ queryField: 'metricType', queryValues: [params.metric] });
  }
  if (params.publisher) {
    search.push({ queryField: 'publisher', queryValues: [params.publisher] });
  }
  return postRequest(`/gamification-service/admin/challenge/query?page=${params.page || 0}&size=${params.size || 10}`, {
    search,
  });
};

export const getTemplatesRequest = (params: {
  page?: number;
  size?: number;
  metric?: string;
  searchText: string;
  challengePillars?: DIMENSION[];
  competition?: COMPETITION[];
  challengeType?: CHALLENGE_TYPE[];
}) => {
  const search: {
    queryField: 'challengePillars' | 'competition' | 'metricType' | 'title' | 'challengeType';
    queryValues: string[] | string;
  }[] = [];

  if (params.challengePillars && params.challengePillars.length > 0) {
    search.push({ queryField: 'challengePillars', queryValues: params.challengePillars });
  }

  if (
    params.competition &&
    params.competition.find((item) => item === COMPETITION.TEAM) &&
    params.competition.find((item) => item === COMPETITION.INDIVIDUAL)
  ) {
    search.push({ queryField: 'competition', queryValues: [COMPETITION.INDIVIDUAL, COMPETITION.TEAM] });
  } else if (params.competition && params.competition.find((item) => item === COMPETITION.TEAM)) {
    search.push({ queryField: 'competition', queryValues: [COMPETITION.TEAM] });
  } else if (params.competition && params.competition.find((item) => item === COMPETITION.INDIVIDUAL)) {
    search.push({ queryField: 'competition', queryValues: [COMPETITION.INDIVIDUAL] });
  }

  if (params.challengeType && params.challengeType.length > 0) {
    search.push({ queryField: 'challengeType', queryValues: params.challengeType });
  }
  if (params.searchText.trim().length > 0) {
    search.push({ queryField: 'title', queryValues: [params.searchText.trim()] });
  }
  if (params.metric && params.metric.trim().length > 0) {
    search.push({ queryField: 'metricType', queryValues: [params.metric] });
  }
  return postRequest(`/gamification-service/template/query?page=${params.page || 0}&size=${params.size || 10}`, {
    search,
  });
};

export const getChallenge = (params: { challengeUUID?: string; templateChallengeUUID?: string }) => {
  return postRequest('/gamification-service/challenge/details', { ...params });
};

export const publishTemplateChallenge = (params: PublishFormData, uuid: string) => {
  const requestParams: {
    startDate?: string;
    endDate?: string;
    maxTeamSize?: number;
    awardTriggerLimit?: number;
    slackURL?: string;
  } = {};

  if (params.startDate && params.endDate) {
    requestParams.startDate = params.startDate;
    requestParams.endDate = params.endDate;
  }

  if (params.teamSize && params.teamSize.length > 0) {
    requestParams.maxTeamSize = Number(params.teamSize);
  }

  if (params.winners && params.winners.length > 0) {
    requestParams.awardTriggerLimit = Number(params.winners);
  }
  if (params.slackUrl && params.slackUrl.length > 0) {
    requestParams.slackURL = params.slackUrl;
  }

  return postRequest(`/gamification-service/challenge/based_on_template?templateChallengeUUID=${uuid}`, {
    ...requestParams,
  });
};

export const publishFullTemplateChallenge = (
  params: {
    [key: string]: any;
  },
  uuid: string,
) => {
  return postRequest(`/gamification-service/challenge/based_on_template?templateChallengeUUID=${uuid}`, {
    ...params,
  });
};

export const updateChallenge = (
  params: {
    [key: string]: any;
  },
  uuid: string,
) => {
  return putRequest(`/gamification-service/challenge?challengeUUID=${uuid}`, {
    ...params,
  });
};

export const deleteChallenge = (params: { challengeUUID: string }) => {
  return deleteRequest('/gamification-service/challenge', { ...params });
};

export const deleteTemplateChallenge = (params: { templateChallengeUUID: string }) => {
  return deleteRequest('/gamification-service/template', { ...params });
};

export const getAdminChallengeProgress = (params: { challengeUUID: string }) =>
  getRequest('/gamification-service/admin/challenge/progress', { ...params });

export const getAdminChallengeLeaderBoard = (params: { challengeUUID: string; page: number; size: number }) =>
  getRequest('/gamification-service/admin/progress/ranking', { ...params });

export const getChallengeAdminMetrics = () => getRequest('/gamification-service/admin/metrics');

export const getTemplatesMetrics = () => getRequest('/gamification-service/admin/templates/metrics');
