import React, { useRef, useState } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import CustomNewHeader from '../../components/features/CustomNewHeader';
import { Box, CardMedia, Collapse, Container, Link, Typography, useMediaQuery } from '@mui/material';
import { Colors } from '../../theme/colors';
import { styles } from './styles';
import IconPlaceholder from '../../assets/defaultBlog.png';
import { ChallengePagination } from '../../components/features/ChallengePagination';
import { ReactComponent as GoalIcon } from '../../assets/svg/goal.svg';
import { ReactComponent as ArrowIcon } from '../../assets/svg/feature/arrow.svg';
import { ReactComponent as ExclamationIcon } from '../../assets/svg/feature/exclamation.svg';
import { ReactComponent as DangerIcon } from '../../assets/svg/feature/danger.svg';
import { CardLabel } from '../../components/features/CardLabel';
import { useHandle } from './hooks/useHandle';
import { RouteComponentProps } from 'react-router';
import Grid from '@mui/material/Grid';
import { ChallengeJoinModal } from '../../components/features/modals/ChallengeJoinModal';
import { useJoinHandle } from '../ChallengesPage/hooks/useJoinHandle';
import { ChallengeAbortModal } from '../../components/features/modals/ChallengeAbortModal';
import { useAbortHandle } from '../ChallengesPage/hooks/useAbortHandle';
import { ChallengeParticipantsModal } from '../../components/features/modals/ChallengeParticipantsModal';
import theme from '../../theme';
import { CollapseBox } from '../../components/features/CollapseBox';
import { COMPETITION } from '../../models/enum/COMPETITION';
import { SkeletonContent } from './components/SkeletonContent';
import { Information } from './components/Information';
import { Participants } from './components/Participants';
import { Actions } from './components/Actions';
import { TeamSection } from './components/TeamSection';
import { PARTICIPATION_STATUS } from '../../models/enum/PARTICIPATION_STATUS';
import { Challenge } from '../../models/Challenge';
import { useNotificationsHandle } from './hooks/useNotificationsHandle';
import { NotificationSettingsSection } from './components/NotificationSettingsSection';
import { CHALLENGE_SUB_CATEGORY } from '../../models/enum/CHALLENGE_SUB_CATEGORY';
import { useTeamHandle } from './hooks/useTeamHandle';
import { useParticipantsHandle } from '../ChallengeAdminDetailPage/hooks/useParticipantsHandle';

type Props = RouteComponentProps<{
  id: string;
}>;
type RouteParams = {
  id: string;
};

export const ChallengeDetailPage: React.FC<Props> = ({ match }) => {
  const fullDescriptionRef = useRef<null | HTMLDivElement>(null);
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const { participants, participantsLoading, divParticipantsRef, handleScrollEvent, handleUpdateParticipants } =
    useParticipantsHandle(match.params as RouteParams);
  const {
    challenge,
    challengeTitle,
    expanded,
    showParticipantsModal,
    challengeStatusBreadcrumbItem,
    progressBreadcrumbItem,
    challengeLoading,
    daysLeftToJoin,
    isTeamChallenge,
    loadData,
    setShowParticipantsModal,
    handleExpandClick,
    replaceChallengeData,
  } = useHandle(match.params as RouteParams, handleUpdateParticipants);
  const { joinToChallengeLoading, joinChallenge, joinToChallengeRequest, setJoinChallenge } = useJoinHandle(
    undefined,
    replaceChallengeData,
    loadData,
  );

  const { showAbort, abortChallengeLoading, setShowAbort, abortChallengeRequest } = useAbortHandle(
    match.params as RouteParams,
    challenge,
    handleUpdateParticipants,
  );

  const { ...teamProps } = useTeamHandle(match.params.id, isTeamChallenge, loadData);
  const executeScrollToDescription = () => {
    setTimeout(() => {
      if (fullDescriptionRef && fullDescriptionRef.current) {
        fullDescriptionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100);
  };

  const { challengeSettings, handleChallengeSettingsChange } = useNotificationsHandle(challenge);

  const Notification: React.FC = () =>
    challenge &&
    challenge.challengeParticipation.isUserParticipant &&
    isTeamChallenge &&
    !challenge.challengeParticipation.isUserTeamMember ? (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: Colors.red[100],
          borderRadius: '30px',
          padding: '8px 16px',
          mt: '24px',
        }}
      >
        <ExclamationIcon width={16} height={16} style={{ fill: Colors.red[500], flexShrink: 0 }} />
        <Typography sx={{ fontSize: 12, lineHeight: 1, color: Colors.red[500], ml: '8px' }}>
          <b>Action required:</b> Join or create a team to participate in this challenge.
        </Typography>
      </Box>
    ) : null;

  const RejoinNotification: React.FC<{ challenge: Challenge }> = ({ challenge }) =>
    challenge.challengeParticipation.participationStatus &&
    challenge.challengeParticipation.participationStatus === PARTICIPATION_STATUS.EXCLUDED &&
    challenge.challengeCharacteristic.competition !== COMPETITION.SELF ? (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: Colors.yellow[100],
          borderRadius: '30px',
          padding: '8px 16px',
          mb: mdDown ? '27px' : 0,
          mt: mdDown ? 0 : '40px',
        }}
      >
        <DangerIcon width={16} height={16} style={{ fill: Colors.yellow[600], flexShrink: 0 }} />
        <Typography sx={{ fontSize: 12, lineHeight: 1.4, color: Colors.yellow[600], ml: '8px' }}>
          You have recently aborted the challenge and cannot re-join.
        </Typography>
      </Box>
    ) : null;

  return (
    <IonPage>
      <CustomNewHeader
        breadcrumb={{
          Icon: GoalIcon,
          list: [
            { label: 'Your Challenges', url: '/page/your-challenges' },
            ...challengeStatusBreadcrumbItem,
            ...progressBreadcrumbItem(),
            { label: `Detailed:${challengeTitle}`, url: '/' },
          ],
        }}
        pagination={
          challenge && (challenge.nextChallengeUUID || challenge.previousChallengeUUID) ? (
            <ChallengePagination
              title={challengeTitle}
              nextChallengeUUID={challenge ? challenge.nextChallengeUUID : null}
              previousChallengeUUID={challenge ? challenge.previousChallengeUUID : null}
            />
          ) : undefined
        }
        label={challengeTitle}
      >
        {challenge && (challenge.nextChallengeUUID || challenge.previousChallengeUUID) && !mdDown && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <ChallengePagination
              title={challengeTitle}
              nextChallengeUUID={challenge ? challenge.nextChallengeUUID : null}
              previousChallengeUUID={challenge ? challenge.previousChallengeUUID : null}
            />
          </Box>
        )}
      </CustomNewHeader>

      <IonContent style={{ '--padding-bottom': '36px' }} scrollEvents={true}>
        <Container
          maxWidth={'lg'}
          sx={{
            paddingBottom: challenge && !challenge.challengeParticipation.isUserParticipant && mdDown ? '56px' : 0,
          }}
        >
          {challengeLoading ? (
            <SkeletonContent />
          ) : challenge ? (
            <Grid container display={'flex'} wrap={'nowrap'}>
              <Grid sx={{ position: 'relative', width: '100%', marginRight: mdDown ? 0 : '24px' }}>
                <CardMedia
                  sx={mdDown ? styles.mdCardImage : styles.cardImage}
                  image={challenge.challengeInfo.imageUrl ?? IconPlaceholder}
                />
                <CardLabel challenge={challenge} type={'lg'} sxContainer={styles.labelStatusPosition} />

                {!mdDown && (
                  <Grid
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      flexWrap: 'wrap',
                      padding: '0 16px 21px',
                      background: Colors.white,
                      borderLeft: '5px solid #B573E2',
                      boxShadow: '5px 0px 15px rgba(108, 123, 132, 0.05)',
                      borderRadius: '20px',
                      margin: '-46px 32px 0 32px',
                    }}
                  >
                    <Information challenge={challenge} />
                  </Grid>
                )}

                {!mdDown && challenge.metadata.challengeUUID && isTeamChallenge && (
                  <TeamSection challenge={challenge} daysLeftToJoin={daysLeftToJoin} {...teamProps} />
                )}
                <Grid
                  sx={{
                    padding: mdDown ? '16px' : '24px',
                    mt: mdDown ? '32px' : '26px',
                    mb: '32px',
                    backgroundColor: '#F7F9F9',
                    borderRadius: '20px',
                  }}
                >
                  <Typography sx={{ fontSize: mdDown ? '14px' : '16px', color: Colors.gray[500] }}>
                    {challenge.challengeInfo.shortDescription}
                  </Typography>

                  {!expanded ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        maxWidth: '150px',
                        cursor: 'pointer',
                        mt: mdDown ? '4px' : 0,
                      }}
                      onClick={() => {
                        handleExpandClick();
                        executeScrollToDescription();
                      }}
                    >
                      <Link sx={{ fontSize: mdDown ? '14px' : '16px', color: Colors.blue[500], mr: '4px' }}>
                        Read more...
                      </Link>
                      <ArrowIcon
                        height={16}
                        width={16}
                        fill={Colors.blue[500]}
                        style={{ transform: 'rotate(-90deg)' }}
                      />
                    </Box>
                  ) : (
                    <Box sx={{ height: 17, mt: mdDown ? '4px' : 0 }} />
                  )}
                </Grid>

                {mdDown && (
                  <>
                    <RejoinNotification challenge={challenge} />
                    {daysLeftToJoin > 0 && <Notification />}
                    {challenge.metadata.challengeUUID && isTeamChallenge && (
                      <TeamSection challenge={challenge} daysLeftToJoin={daysLeftToJoin} {...teamProps} />
                    )}
                  </>
                )}
                {mdDown && (
                  <Box>
                    <CollapseBox title={'Information'} borderColor={Colors.magenta[300]}>
                      <Information challenge={challenge} />
                    </CollapseBox>
                    <CollapseBox title={'Participants'} borderColor={Colors.green[500]} sx={{ mt: '18px' }}>
                      <Box sx={{ mt: '16px', mb: '8px' }}>
                        <Participants
                          challenge={challenge}
                          participants={participants}
                          challengeTitle={challengeTitle}
                          daysLeftToJoin={daysLeftToJoin}
                          isCorporate={isTeamChallenge}
                          showParticipantsModal={() => setShowParticipantsModal(true)}
                        />
                      </Box>
                    </CollapseBox>

                    {challengeSettings &&
                      challenge.challengeParticipation.isUserParticipant &&
                      challenge.challengeSubCategory !== CHALLENGE_SUB_CATEGORY.FINISHED && (
                        <NotificationSettingsSection
                          challengeSettings={challengeSettings}
                          handleChallengeSettingsChange={handleChallengeSettingsChange}
                        />
                      )}
                  </Box>
                )}

                <Box ref={fullDescriptionRef}>
                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                    {challenge && (
                      <Typography
                        sx={{
                          mt: mdDown ? '40px' : 0,
                          fontSize: mdDown ? '14px' : '16px',
                          color: Colors.gray[500],
                          '& > * iframe': {
                            border: 'none',
                            ...(mdDown && { width: '100%', height: 'calc((100vw - 32px) * 0.75)', maxHeight: '315px' }),
                          },
                        }}
                        dangerouslySetInnerHTML={{ __html: challenge.challengeInfo.description }}
                      />
                    )}

                    {expanded && (
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', mt: '16px' }}
                        onClick={() => handleExpandClick()}
                      >
                        <Link sx={{ fontSize: mdDown ? '14px' : '16px', color: Colors.blue[500], mr: '4px' }}>
                          View less
                        </Link>
                        <ArrowIcon
                          height={16}
                          width={16}
                          fill={Colors.blue[500]}
                          style={{ transform: 'rotate(90deg)' }}
                        />
                      </Box>
                    )}
                  </Collapse>
                </Box>

                {mdDown && (
                  <Box
                    sx={{
                      position: !challenge.challengeParticipation.isUserParticipant ? 'fixed' : 'relative',
                      bottom: !challenge.challengeParticipation.isUserParticipant ? 30 : 'auto',
                      left: !challenge.challengeParticipation.isUserParticipant ? 30 : 'auto',
                      zIndex: 3,
                      maxWidth: !challenge.challengeParticipation.isUserParticipant ? '118px' : '328px',
                      mt: !challenge.challengeParticipation.isUserParticipant ? 0 : '50px',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  >
                    <Actions
                      challenge={challenge}
                      onJoin={() => setJoinChallenge(challenge)}
                      onAbort={() => setShowAbort(true)}
                    />
                  </Box>
                )}
              </Grid>
              {!mdDown &&
              !(!participants.length && challenge.challengeSubCategory === CHALLENGE_SUB_CATEGORY.FINISHED) ? (
                <Grid sx={{ width: '276px', flexShrink: 0 }}>
                  <Grid
                    sx={{
                      padding: '24px 16px',
                      backgroundColor: Colors.white,
                      borderRadius: '20px',
                      borderTop: `5px solid ${Colors.green[500]}`,
                      boxShadow: '5px 0px 15px rgba(108, 123, 132, 0.05)',
                    }}
                  >
                    <Participants
                      challenge={challenge}
                      participants={participants}
                      challengeTitle={challengeTitle}
                      daysLeftToJoin={daysLeftToJoin}
                      isCorporate={isTeamChallenge}
                      showParticipantsModal={() => setShowParticipantsModal(true)}
                    />
                  </Grid>
                  {challengeSettings &&
                    challenge.challengeParticipation.isUserParticipant &&
                    challenge.challengeSubCategory !== CHALLENGE_SUB_CATEGORY.FINISHED && (
                      <NotificationSettingsSection
                        challengeSettings={challengeSettings}
                        handleChallengeSettingsChange={handleChallengeSettingsChange}
                      />
                    )}
                  <Actions
                    challenge={challenge}
                    onJoin={() => setJoinChallenge(challenge)}
                    onAbort={() => setShowAbort(true)}
                  />
                  <RejoinNotification challenge={challenge} />
                  {daysLeftToJoin > 0 && <Notification />}
                </Grid>
              ) : null}
            </Grid>
          ) : null}
        </Container>
      </IonContent>

      <ChallengeJoinModal
        show={!!joinChallenge}
        showMore={false}
        title={joinChallenge ? joinChallenge.challengeInfo.title : ''}
        challenge={challenge}
        loading={joinToChallengeLoading}
        onClose={() => setJoinChallenge(null)}
        onJoin={joinToChallengeRequest}
      />
      <ChallengeAbortModal
        show={showAbort}
        challenge={challenge}
        isTeam={isTeamChallenge}
        loading={abortChallengeLoading}
        onClose={() => setShowAbort(false)}
        onAbort={abortChallengeRequest}
      />
      <ChallengeParticipantsModal
        show={showParticipantsModal}
        divRef={divParticipantsRef}
        isSelf={(challenge && challenge.challengeCharacteristic.competition === COMPETITION.SELF) || false}
        isUserParticipant={challenge ? challenge.challengeParticipation.isUserParticipant : false}
        isParticipationLocked={challenge ? challenge.challengeParticipation.isParticipationLocked : false}
        participants={participants}
        loading={participantsLoading}
        handleScrollEvent={handleScrollEvent}
        onClose={() => setShowParticipantsModal(false)}
        onAbort={
          challenge && challenge.challengeSubCategory !== CHALLENGE_SUB_CATEGORY.FINISHED
            ? () => {
                setShowParticipantsModal(false);
                setShowAbort(true);
              }
            : undefined
        }
        onJoin={
          challenge && challenge.challengeSubCategory !== CHALLENGE_SUB_CATEGORY.FINISHED
            ? () => {
                setShowParticipantsModal(false);
                setJoinChallenge(challenge);
              }
            : undefined
        }
      />
    </IonPage>
  );
};
