import { Colors } from '../../theme/colors';
import { ReactComponent as PointIcon } from '../../assets/svg/feature/awards/point.svg';
import { ReactComponent as TrophyIcon } from '../../assets/svg/feature/awards/trophy.svg';
import { ReactComponent as TrophyOldIcon } from '../../assets/svg/feature/awards/trophy_old.svg';
import { ReactComponent as NoTrophyIcon } from '../../assets/svg/feature/awards/notrophy.svg';
import { AWARD_TYPE } from './AWARD_TYPE';
export const AWARD_LIST = [
  {
    value: AWARD_TYPE.POINT,
    label: '5 000',
    Icon: PointIcon,
    mainColor: Colors.yellow[400],
    backgroundColor: Colors.yellow[150],
    borderColor: Colors.yellow[300],
  },
  {
    value: AWARD_TYPE.BADGE,
    label: 'Badge',
    Icon: TrophyIcon,
    mainColor: Colors.yellow[400],
    backgroundColor: Colors.yellow[150],
    borderColor: Colors.yellow[300],
  },
  {
    value: AWARD_TYPE.OTHER,
    label: 'Other',
    Icon: TrophyOldIcon,
    mainColor: Colors.yellow[400],
    backgroundColor: Colors.yellow[150],
    borderColor: Colors.yellow[300],
  },
  {
    value: AWARD_TYPE.NONE,
    label: 'None',
    Icon: NoTrophyIcon,
    mainColor: Colors.gray[200],
    backgroundColor: Colors.gray[100],
    borderColor: '#E9EDEF',
  },
];
