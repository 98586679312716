import React, { MouseEventHandler, useEffect, useState } from 'react';
import { Box, Grid, IconButton, Popover, styled, Typography } from '@mui/material';
import { ReactComponent as PieIcon } from '../../assets/svg/PieChart.svg';
import { Calendar } from 'react-date-range';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import moment from 'moment';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

const StyledBox = styled(Box)({
  height: 40,
  zIndex: 5000,
  minWidth: 130,
  fontSize: 14,
  fontWeight: 700,
  background: 'white',
  borderRadius: '999px',
  paddingInline: '18px 12px',
  boxShadow: 'var(--shadow-button)',
  userSelect: 'none',
  whiteSpace: 'nowrap',
  position: 'relative',
});

type CustomRangePickerProps = {
  date: string;
  setDate: (date: string) => void;
  disabled?: boolean;
  label?: string;
  anchorLeft?: boolean;
  disableFuture?: boolean;
};

const CustomCalendar: React.FC<CustomRangePickerProps> = ({
  disabled,
  date,
  setDate,
  label,
  disableFuture = false,
}) => {
  // const selfRef = useRef(null);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const toggle = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    if (!disabled) setExpanded(!expanded);
  };

  useEffect(() => {
    if (disabled) {
      setExpanded(false);
    }
  }, [date, disabled]);

  const open = () => {
    if (!disabled) {
      setExpanded(true);
      // if (selfRef && selfRef !== null && selfRef.current !== null) {
      //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //   // @ts-ignore
      //   selfRef.current.focus();
      // }
    }
  };
  const close = () => {
    setExpanded(false);
  };

  const handleDateChange = (newDate: Date) => {
    setDate(moment(newDate).toString());
    close();
  };

  const handleNextDate: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    const newDate = moment(date).add(1, 'days');
    if (disableFuture && newDate.isAfter()) {
      setDate(moment().toString());
      return;
    }
    setDate(moment(newDate).toString());
  };

  const handlePrevDate: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    const newDate = moment(date).subtract(1, 'days');
    setDate(moment(newDate).toString());
  };

  const clear = () => {
    setExpanded(false);
  };

  const getFormattedDate = (dateToFormat: any) => {
    return moment(dateToFormat).format('D/M/YY');
  };

  return (
    <>
      <StyledBox tabIndex={-1} onClick={toggle}>
        {label && (
          <Typography sx={{ position: 'absolute', top: -25, left: 10, fontWeight: 'bold' }}>{label}</Typography>
        )}
        <Grid
          container
          wrap={'nowrap'}
          sx={{ height: '100%', opacity: disabled ? 0.4 : 1, cursor: disabled ? 'default' : 'pointer' }}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          {date ? (
            <Grid item container alignItems={'center'}>
              <IconButton size="small" aria-label="close" color="inherit" onClick={handlePrevDate} disabled={disabled}>
                <ChevronLeft fontSize="small" />
              </IconButton>
            </Grid>
          ) : null}
          <Grid item display="flex" alignItems={'center'}>
            <Typography sx={{ fontSize: 16, fontWeight: 'bold', mr: '4px' }}>
              {date ? getFormattedDate(date) : null}
            </Typography>
            {expanded ? (
              <Box onClick={clear}>
                <HighlightOffIcon color={'primary'} />
              </Box>
            ) : (
              <Box onClick={open}>
                <PieIcon />
              </Box>
            )}
          </Grid>

          {date ? (
            <Grid item container alignItems={'center'} justifyContent={'flex-end'}>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleNextDate}
                disabled={disabled || moment(moment().format('YYYY-MM-DD')).isSame(moment(date).format('YYYY-MM-DD'))}
              >
                <ChevronRight fontSize="small" />
              </IconButton>
            </Grid>
          ) : null}
        </Grid>
      </StyledBox>
      <Popover
        open={expanded}
        onClose={close}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          sx: {
            '& .rdrDefinedRangesWrapper': {
              display: 'none !important',
            },
            borderRadius: '24px',
            boxShadow: 'var(--shadow-shape)',
            mt: 2,
          },
        }}
      >
        <Calendar
          date={date ? new Date(date) : undefined}
          maxDate={disableFuture ? new Date(Date.now()) : undefined}
          onChange={(e) => handleDateChange(e)}
          color={'#00B6BE'}
        />
      </Popover>
    </>
  );
};

export default CustomCalendar;
