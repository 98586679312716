import { DIMENSION } from './DIMENSION';
import { Colors } from '../../theme/colors';
import { ReactComponent as SportIcon } from '../../assets/svg/feature/dimentions/sport.svg';
import { ReactComponent as SleepIcon } from '../../assets/svg/feature/dimentions/sleep.svg';
import { ReactComponent as NutritionIcon } from '../../assets/svg/feature/dimentions/nutrition.svg';
import { ReactComponent as MentalFitnessIcon } from '../../assets/svg/feature/dimentions/mental-fitness.svg';
import { PreviewRoundItemProps } from '../Shared';

export const DIMENSION_LIST: PreviewRoundItemProps[] = [
  {
    value: DIMENSION.PHYSICAL_ACTIVITY,
    label: 'Physical activity',
    Icon: SportIcon,
    mainColor: Colors.red[400],
    borderColor: Colors.red[300],
    backgroundColor: Colors.red[500],
  },
  {
    value: DIMENSION.SLEEP,
    label: 'Sleep',
    Icon: SleepIcon,
    mainColor: Colors.purple[400],
    borderColor: Colors.magenta[300],
    backgroundColor: Colors.purple[500],
  },
  {
    value: DIMENSION.NUTRITION,
    label: 'Nutrition',
    Icon: NutritionIcon,
    mainColor: Colors.orange[400],
    borderColor: Colors.yellow[300],
    backgroundColor: Colors.orange[500],
  },
  {
    value: DIMENSION.MENTAL_FITNESS,
    label: 'Mental well-being',
    Icon: MentalFitnessIcon,
    mainColor: Colors.blue[400],
    borderColor: Colors.blue[300],
    backgroundColor: Colors.blue[500],
  },
];
