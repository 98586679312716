import { Box, Grid } from '@mui/material';
import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ReactComponent as Clipboard } from '../../assets/svg/clipboard.svg';
import { ReactComponent as AddIcon } from '../../assets/svg/addIconRound.svg';
import { ReactComponent as Wearable } from '../../assets/svg/wearable.svg';
import { useDispatch, useSelector } from 'react-redux';
import { IMetric } from '../../models/Metrics';
import { asyncAddNewMetric, asyncPostMetric, setMetricsModal } from '../../redux/slices/journeyPageSlice';
import StyledButton from '../common/Button';
import InputField from '../common/CustomTextField';
import CustomTextFieldSwitch from '../common/CustomTextFieldSwitch';
import { CustomMetricsSlider } from '../CustomMetricsSlider';
import moment from 'moment';
import { AppDispatch, RootState } from '../../redux/store';
import { throttle } from 'lodash';

interface Props {
  foundMetric: IMetric | undefined;
  date: any;
  newMetric: boolean;
  reset: () => void;
}

const JourneyModalContent: FC<Props> = ({ foundMetric, date, newMetric, reset }) => {
  const dispatch: AppDispatch = useDispatch();
  const [sleep, setSleep] = useState({ hours: 0, minutes: 0 });
  const [goalSleep, setGoalSleep] = useState({ hours: 0, minutes: 0 });
  const [value, setValue] = useState(0);
  const [sliderValue, setSliderValue] = useState(0);
  const [isGoal, setIsGoal] = useState(false);
  const [goal, setGoal] = useState(0);
  const [newMetricInstance, setNewMetricInstance] = useState({
    metric_category: ['INDIVIDUAL'],
    metric_value: 0,
    title: '',
    goal_value: 0,
  });

  const { isCorporate, page } = useSelector((state: RootState) => state.journeyPage.data.metricsModal);

  useEffect(() => {
    if (foundMetric) {
      setIsGoal(foundMetric?.metric_goal !== null);
      if (foundMetric?.metric_goal !== null) {
        setGoal(foundMetric?.metric_goal?.target_value);
        if (foundMetric?.metric_type === 'SLEEP_DURATION') {
          const num = foundMetric?.metric_goal?.target_value || 0;
          const hours = num / 60;
          const rhours = Math.floor(hours);
          const minutes = (hours - rhours) * 60;
          const rminutes = Math.round(minutes);
          setGoalSleep({ hours: rhours, minutes: rminutes });
        }
      }
      if (
        (foundMetric?.metric_characteristics?.max_value > 10 && foundMetric?.metric_samples?.length) ||
        (foundMetric?.metric_characteristics?.max_value === null && foundMetric?.metric_samples?.[0]?.total_value)
      ) {
        setValue(foundMetric?.metric_samples[0]?.total_value);
        if (foundMetric?.metric_type === 'SLEEP_DURATION') {
          const num = foundMetric?.metric_samples?.[0]?.total_value || 0;
          const hours = num / 60;
          const rhours = Math.floor(hours);
          const minutes = (hours - rhours) * 60;
          const rminutes = Math.round(minutes);
          setSleep({ hours: rhours, minutes: rminutes });
        }
      } else {
        setValue(0);
        if (foundMetric?.metric_type === 'SLEEP_DURATION') {
          setSleep({ hours: 0, minutes: 0 });
        }
      }
      if (foundMetric?.metric_characteristics?.max_value <= 10) {
        if (foundMetric?.metric_samples?.length) {
          setSliderValue(foundMetric?.metric_samples?.[0]?.total_value);
        } else {
          setSliderValue(Math.ceil(foundMetric?.metric_characteristics?.max_value / 2));
        }
      }
    }
  }, [foundMetric]);

  const handleReset = useCallback(() => {
    setSleep({ hours: 0, minutes: 0 });
    setGoalSleep({ hours: 0, minutes: 0 });
    setValue(0);
    setSliderValue(0);
    setIsGoal(false);
    setGoal(0);
    setNewMetricInstance({
      metric_category: ['INDIVIDUAL'],
      metric_value: 0,
      title: '',
      goal_value: 0,
    });
    reset();
  }, [reset]);

  const handleClose = useCallback(
    (isOpen = false) => {
      dispatch(
        setMetricsModal({ isOpen: isOpen, predefinedMetric: '', date: moment().format('YYYY-MM-DD'), page: undefined }),
      );
    },
    [dispatch],
  );

  const handleNewMetricChange = (e: ChangeEvent<{ name: string; value: string }>) => {
    setNewMetricInstance({ ...newMetricInstance, [e.target.name]: e.target.value });
  };

  const handleSliderAction = useCallback(
    (close: boolean) => {
      if (foundMetric !== undefined) {
        dispatch(
          asyncPostMetric({
            metric: {
              date: moment(date).format('YYYY-MM-DD'),
              metric_type: foundMetric?.metric_type,
              group: isCorporate ? 'CORPORATE' : 'INDIVIDUAL',
              goal_value: isGoal ? goal : undefined,
              title: foundMetric?.title,
              metric_value: sliderValue,
            },
            date: moment(date).format('YYYY-MM-DD'),
            page: page,
          }),
        )
          .unwrap()
          .then(() => {
            handleClose(!close);
            handleReset();
          });
      }
    },
    [date, dispatch, foundMetric, goal, handleClose, handleReset, isCorporate, isGoal, page, sliderValue],
  );

  const throttleSliderAction = useMemo(
    () => throttle(handleSliderAction, 1000, { trailing: false }),
    [handleSliderAction],
  );

  const handleAction = useCallback(
    (close: boolean) => {
      if (foundMetric !== undefined) {
        const calculatedValue =
          foundMetric.metric_type === 'SLEEP_DURATION' ? sleep.hours * 60 + +sleep.minutes : value;
        const calculatedValueGoal =
          foundMetric.metric_type === 'SLEEP_DURATION' ? goalSleep.hours * 60 + +goalSleep.minutes : goal;
        dispatch(
          asyncPostMetric({
            metric: {
              date: moment(date).format('YYYY-MM-DD'),
              goal_value: isGoal ? calculatedValueGoal : undefined,
              metric_type: foundMetric?.metric_type,
              group: isCorporate ? 'CORPORATE' : 'INDIVIDUAL',
              title: foundMetric?.title,
              metric_value: calculatedValue,
            },
            date: moment(date).format('YYYY-MM-DD'),
            page: page,
          }),
        )
          .unwrap()
          .then(() => {
            handleClose(!close);
            handleReset();
          });
      }
    },
    [
      date,
      dispatch,
      foundMetric,
      goal,
      goalSleep.hours,
      goalSleep.minutes,
      handleClose,
      handleReset,
      isCorporate,
      isGoal,
      page,
      sleep.hours,
      sleep.minutes,
      value,
    ],
  );

  const throttleAction = useMemo(() => throttle(handleAction, 1000, { trailing: false }), [handleAction]);

  const handleAddNewMetric = useCallback(
    (close: boolean) => {
      dispatch(
        asyncAddNewMetric({
          metric: {
            metric_category_group: isCorporate ? 'CORPORATE' : 'INDIVIDUAL',
            metric_value: newMetricInstance.metric_value,
            title: newMetricInstance.title,
            goal_value: isGoal ? newMetricInstance.goal_value : undefined,
          },
          date: moment(date).format('YYYY-MM-DD'),
        }),
      )
        .unwrap()
        .then(() => {
          handleClose(!close);
          handleReset();
        });
    },
    [
      date,
      dispatch,
      handleClose,
      handleReset,
      isCorporate,
      isGoal,
      newMetricInstance.goal_value,
      newMetricInstance.metric_value,
      newMetricInstance.title,
    ],
  );

  const throttleAddNewMetric = useMemo(
    () => throttle(handleAddNewMetric, 1000, { trailing: false }),
    [handleAddNewMetric],
  );

  const minValue = foundMetric?.metric_characteristics?.min_value;
  const maxValue = foundMetric?.metric_characteristics?.max_value;
  const metricInput = foundMetric?.title;

  const handleGoalChange = () => {
    if (foundMetric && foundMetric?.metric_characteristics?.max_value <= 10) setGoal(3);
    setIsGoal(!isGoal);
  };

  const extractFields = (type: string | undefined) => {
    if (type === 'SLEEP_DURATION') {
      return (
        <>
          <InputField
            label={`${metricInput!} (hours)`}
            name={'hours'}
            value={sleep.hours}
            type={'number'}
            setValue={(e) => setSleep({ ...sleep, hours: e.target.value })}
          />
          <InputField
            label={`${metricInput!} (minutes)`}
            name={'minutes'}
            value={sleep.minutes}
            type={'number'}
            setValue={(e) => setSleep({ ...sleep, minutes: e.target.value })}
          />
        </>
      );
    } else {
      return (
        <Box>
          <Grid container wrap={'nowrap'} alignItems={'center'}>
            <Grid item sx={{ flex: 1 }}>
              <InputField
                label={metricInput!}
                name={'value'}
                value={value}
                type={'number'}
                setValue={(e) => setValue(e.target.value)}
              />
            </Grid>
            {foundMetric?.metric_characteristics.metric_unit &&
              foundMetric?.metric_characteristics.metric_unit !== 'NOT_AVAILABLE' && (
                <Grid item sx={{ fontSize: 16, fontWeight: 'bold', pl: 1 }}>
                  <Box>{foundMetric?.metric_characteristics.metric_unit}</Box>
                </Grid>
              )}
          </Grid>
        </Box>
      );
    }
  };

  const extractGoalFields = (type: string | undefined) => {
    if (type === 'SLEEP_DURATION') {
      return (
        <>
          <InputField
            label={`${metricInput!} Goal (hours)`}
            name={'hours'}
            value={goalSleep.hours}
            type={'number'}
            setValue={(e) => setGoalSleep({ ...goalSleep, hours: e.target.value })}
          />
          <InputField
            label={`${metricInput!} Goal (minutes)`}
            name={'minutes'}
            type={'number'}
            value={goalSleep.minutes}
            setValue={(e) => setGoalSleep({ ...goalSleep, minutes: e.target.value })}
          />
        </>
      );
    } else {
      return (
        <Box>
          <Grid container wrap={'nowrap'} alignItems={'center'}>
            <Grid item sx={{ flex: 1 }}>
              <InputField
                label={'Enter target value'}
                name={'goal_value'}
                value={goal}
                type={'number'}
                setValue={(e) => setGoal(e.target.value)}
              />
            </Grid>
            {foundMetric?.metric_characteristics.metric_unit &&
              foundMetric?.metric_characteristics.metric_unit !== 'NOT_AVAILABLE' && (
                <Grid item sx={{ fontSize: 16, fontWeight: 'bold', pl: 1 }}>
                  <Box>{foundMetric?.metric_characteristics.metric_unit}</Box>
                </Grid>
              )}
          </Grid>
        </Box>
      );
    }
  };

  const extractMainContent = () => {
    if (
      !newMetric &&
      foundMetric &&
      foundMetric?.metric_characteristics?.min_value >= 1 &&
      foundMetric?.metric_characteristics?.max_value <= 10 &&
      foundMetric?.metric_characteristics?.value_type === 'INTEGER'
    ) {
      return (
        <>
          <Box width="100%" sx={{ mt: 2 }}>
            <CustomMetricsSlider
              value={sliderValue}
              setValue={setSliderValue}
              minValue={minValue!}
              maxValue={maxValue!}
            />
            <CustomTextFieldSwitch value={isGoal} setValue={handleGoalChange} />
            {isGoal && (
              <Box sx={{ mt: 4 }}>
                <CustomMetricsSlider value={goal} setValue={setGoal} minValue={minValue!} maxValue={maxValue!} />
              </Box>
            )}
          </Box>
          <Box display={'flex'} alignItems={'center'} justifyContent={'space-around'} width={'100%'}>
            <StyledButton
              text="Log and add new"
              inputProps={{ color: 'primary', variant: 'contained', startIcon: <AddIcon /> }}
              onClick={() => throttleSliderAction(false)}
            />
            <StyledButton
              text="Log"
              inputProps={{ color: 'primary', variant: 'contained', startIcon: <Clipboard /> }}
              onClick={() => throttleSliderAction(true)}
            />
          </Box>
        </>
      );
    } else if (!newMetric && foundMetric?.metric_characteristics?.tracking_type !== 'AUTO') {
      return (
        <>
          <Box width="100%" display="flex" flexDirection="column" gap={7} sx={{ mt: 2 }}>
            {extractFields(foundMetric?.metric_type)}
            <CustomTextFieldSwitch value={isGoal} setValue={handleGoalChange} />
            {isGoal && extractGoalFields(foundMetric?.metric_type)}
          </Box>
          <Box display={'flex'} alignItems={'center'} justifyContent={'space-around'} width={'100%'}>
            <StyledButton
              text="Log and add new"
              inputProps={{
                color: 'primary',
                variant: 'contained',
                startIcon: <AddIcon />,
                disabled: (value === 0 && sleep.hours === 0) || (isGoal && goal.toString() === ''),
              }}
              onClick={() => throttleAction(false)}
            />
            <StyledButton
              text="Log"
              inputProps={{
                color: 'primary',
                variant: 'contained',
                startIcon: <Clipboard />,
                disabled: (value === 0 && sleep.hours === 0) || (isGoal && goal.toString() === ''),
              }}
              onClick={() => throttleAction(true)}
            />
          </Box>
        </>
      );
    } else if (newMetric) {
      return (
        <>
          <Box width="100%" display="flex" flexDirection="column" gap={7} sx={{ mt: 2 }}>
            <InputField
              label="Metric Name"
              name={'title'}
              value={newMetricInstance?.title}
              setValue={handleNewMetricChange}
            />
            <InputField
              label="Current value"
              name={'metric_value'}
              value={newMetricInstance?.metric_value}
              setValue={handleNewMetricChange}
            />
            <CustomTextFieldSwitch value={isGoal} setValue={handleGoalChange} />
            {isGoal && extractGoalFields(foundMetric?.metric_type)}
          </Box>
          <Box display={'flex'} alignItems={'center'} justifyContent={'space-around'} width={'100%'}>
            <StyledButton
              text="Log and add new"
              inputProps={{
                color: 'primary',
                variant: 'contained',
                startIcon: <AddIcon />,
                disabled:
                  newMetricInstance?.metric_value === 0 || newMetricInstance?.title === ''
                    ? true
                    : isGoal && goal.toString() === '',
              }}
              onClick={() => throttleAddNewMetric(false)}
            />
            <StyledButton
              text="Log"
              inputProps={{
                color: 'primary',
                variant: 'contained',
                startIcon: <Clipboard />,
                disabled:
                  newMetricInstance?.metric_value === 0 || newMetricInstance?.title === ''
                    ? true
                    : isGoal && goal.toString() === '',
              }}
              onClick={() => throttleAddNewMetric(true)}
            />
          </Box>
        </>
      );
    } else if (foundMetric?.metric_characteristics?.tracking_type === 'AUTO') {
      return (
        <StyledButton
          text="Log from wearable"
          inputProps={{ color: 'warning', variant: 'contained', startIcon: <Wearable /> }}
          onClick={handleClose}
        />
      );
    }
  };

  return <>{extractMainContent()}</>;
};

export default JourneyModalContent;
