import React, { FC, useEffect, useState } from 'react';
import { Fab } from '@mui/material';
import Add from '@mui/icons-material/Add';
import { setMetricsModal } from '../../redux/slices/journeyPageSlice';
import { useDispatch } from 'react-redux';
import { useIonRouter } from '@ionic/react';
import { AdminRoutes } from '../../models/AdminRoutes';
import moment from 'moment';

const CustomFab: FC = ({}) => {
  const dispatch = useDispatch();
  const router = useIonRouter();
  const [isAdminPage, setIsAdminPage] = useState<boolean>(false);

  useEffect(() => {
    const regex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;
    const urlName = router.routeInfo.pathname.replace(regex, '');

    if (AdminRoutes.includes(urlName)) setIsAdminPage(true);
    else setIsAdminPage(false);
  }, [router.routeInfo]);

  const handleOpenModal = () => {
    dispatch(
      setMetricsModal({
        isOpen: true,
        isCorporate: isAdminPage,
        predefinedMetric: '',
        date: moment().format('YYYY-MM-DD'),
        page: `Plus Button${isAdminPage ? ' Admin' : ''}`,
      }),
    );
  };

  return (
    <Fab
      aria-label="add"
      sx={{
        position: 'absolute',
        right: 30,
        bottom: 30,
        color: 'white',
      }}
      color={isAdminPage ? 'warning' : 'primary'}
      onClick={handleOpenModal}
    >
      <Add />
    </Fab>
  );
};
export default CustomFab;
