import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import { Container } from '@mui/material';
import { ChallengeCardList } from '../../components/features/ChallengeCardList/ChallengeCardList';
import { CHALLENGE_STATUS } from '../../models/enum/CHALLENGE_STATUS';
import { HeaderFilter, useHandleFilter } from '../../components/features/HeaderFilter';
import { useHandle } from './hooks/useHandle';
import { FilterAdditionalActions } from './FilterAdditionalActions';
import { useMetricsHandle } from './hooks/useMetricsHandle';

const PAGE_SIZE = 8;
export const ChallengesAdminPage: React.FC = () => {
  const { queryFilter, ...headerFilterProps } = useHandleFilter();
  const { metrics } = useMetricsHandle();
  const {
    challengesOngoing,
    challengesOpenToJoin,
    challengesFinished,
    challengesOngoingCount,
    challengesOpenToJoinCount,
    challengesFinishedCount,
    isFirstOngoingLoading,
    isFirstOpenToJoinLoading,
    isFirstFinishedLoading,
    challengesOngoingLoading,
    challengesOpenToJoinLoading,
    challengesFinishedLoading,
    ongoingPage,
    openToJoinPage,
    finishedPage,
    setOngoingPage,
    setOpenToJoinPage,
    setFinishedPage,
    handleExport,
  } = useHandle(queryFilter, PAGE_SIZE);

  return (
    <IonPage>
      <HeaderFilter
        label="Your Employees' Challenges"
        tooltip={
          'Boost social connection and engagement through organization-wide  challenges. Publish those that fit better for your company, view the leaderboards and award the winners.'
        }
        loading={
          challengesOngoingLoading || challengesOpenToJoinLoading || challengesFinishedLoading || isFirstOngoingLoading
        }
        isAdmin={true}
        actions={<FilterAdditionalActions handleExport={handleExport} />}
        {...headerFilterProps}
        metrics={metrics}
      />

      <IonContent style={{ '--padding-bottom': '36px' }} scrollEvents={true}>
        <Container maxWidth={'lg'}>
          <ChallengeCardList
            status={CHALLENGE_STATUS.ACTIVE}
            title={'Ongoing'}
            list={challengesOngoing}
            isLoading={challengesOngoingLoading || isFirstOngoingLoading}
            showJoin={false}
            isAdmin={true}
            path={'/page/employees-challenges/ongoing'}
            sxContainer={{ marginBottom: '30px' }}
            currentPage={ongoingPage}
            pageSize={PAGE_SIZE}
            setPage={setOngoingPage}
            totalItems={challengesOngoingCount}
          />

          <ChallengeCardList
            status={CHALLENGE_STATUS.INACTIVE}
            title={'Starting soon'}
            list={challengesOpenToJoin}
            isLoading={challengesOpenToJoinLoading || isFirstOpenToJoinLoading}
            showJoin={false}
            isAdmin={true}
            path={'/page/employees-challenges/join'}
            sxContainer={{ marginBottom: '30px' }}
            currentPage={openToJoinPage}
            pageSize={PAGE_SIZE}
            setPage={setOpenToJoinPage}
            totalItems={challengesOpenToJoinCount}
          />

          <ChallengeCardList
            status={CHALLENGE_STATUS.COMPLETED}
            title={'Finished'}
            list={challengesFinished}
            isLoading={challengesFinishedLoading || isFirstFinishedLoading}
            showJoin={false}
            isAdmin={true}
            path={'/page/employees-challenges/finished'}
            sxContainer={{ marginBottom: '30px' }}
            currentPage={finishedPage}
            pageSize={PAGE_SIZE}
            setPage={setFinishedPage}
            totalItems={challengesFinishedCount}
          />
        </Container>
      </IonContent>
    </IonPage>
  );
};
