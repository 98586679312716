import React from 'react';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import { Colors } from '../../../../theme/colors';
import { ReactComponent as GlowIcon } from '../../../../assets/svg/feature/glow.svg';
import DefaultAwards from '../../../../assets/svg/feature/ranking/awards.svg';
import TopAwards from '../../../../assets/svg/feature/ranking/top-awards.svg';

type AwardProps = {
  isCompleted?: boolean;
  awardUrl?: string;
  sx?: SxProps<Theme>;
};

export const Award: React.FC<AwardProps> = ({ isCompleted = false, awardUrl, sx }) => {
  return (
    <Box sx={sx}>
      <Typography sx={{ fontSize: 10, color: Colors.gray[500], mb: '8px' }}>Award:</Typography>
      <Box
        sx={{
          position: 'relative',
          height: '31px',
          width: '41px',
          borderRadius: '10px',
          background: isCompleted ? Colors.yellow[150] : Colors.gray[50],
          paddingInline: '10px',
          paddingTop: '4px',
          paddingBottom: '4px',
        }}
      >
        <GlowIcon fill={Colors.yellow[50]} style={{ position: 'absolute', top: '-2px', right: '-1px' }} />
        <img
          src={awardUrl ? awardUrl : isCompleted ? TopAwards : DefaultAwards}
          style={{
            position: 'relative',
            width: '100%',
            height: 'auto',
            objectFit: 'contain',
          }}
        />
      </Box>
    </Box>
  );
};
