import { Box, Card, CardContent, SvgIcon, Typography, Grid, useTheme } from '@mui/material';
import React, { FC } from 'react';
import StyledButton from '../common/Button';
import { ReactComponent as Automatic } from '../../assets/svg/automatic.svg';
import { ReactComponent as HeartRate } from '../../assets/svg/heartRate.svg';
import Chart from 'react-apexcharts';
import { IMetric } from '../../models/Metrics';
import { useDispatch } from 'react-redux';
import { setMetricsModal } from '../../redux/slices/journeyPageSlice';
import { ReactComponent as Upwards } from '../../assets/svg/upwards.svg';
import { ReactComponent as Downwards } from '../../assets/svg/downwards.svg';
import { ReactComponent as Goal } from '../../assets/svg/goal_indicator.svg';
import { ReactComponent as WearableMetric } from '../../assets/svg/wearable-metric.svg';
import { ReactComponent as Edit } from '../../assets/svg/edit.svg';
import moment from 'moment';
import { ANALYTICS_PAGE } from '../../models/enum/ANALYTICS_PAGE';

interface Props {
  metric: IMetric;
  date: any;
  onEdit: (metric: IMetric) => void;
  page?: ANALYTICS_PAGE;
}

const options: any = {
  chart: {
    height: '500px',
    offsetX: 0,
  },
  colors: ['#FAAF1F'],
  plotOptions: {
    offsetX: 0,
    radialBar: {
      hollow: {
        margin: 15,
        size: '60%',
      },
      dataLabels: {
        name: {
          show: false,
        },
        value: {
          show: false,
        },
        total: {
          show: false,
          color: '#373d3f',
        },
      },
    },
  },
};

export const calculateTime = (n: number, fontSize?: number) => {
  const num = n || 0;
  const hours = num / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  return (
    <span>
      {rhours}
      <span style={{ fontSize: fontSize || 16 }}>{' hrs '}</span>
      {rminutes}
      <span style={{ fontSize: fontSize || 16 }}>{' min'}</span>
    </span>
  );
};

export const MetricsCard: FC<Props> = ({ metric, date, onEdit, page }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleMetricsModal = (title: string) => {
    dispatch(
      setMetricsModal({
        isOpen: true,
        isCorporate: metric.group === 'CORPORATE',
        predefinedMetric: title,
        date: moment(date).format('YYYY-MM-DD'),
        page: page,
      }),
    );
  };

  const extractSuffix = () => {
    if (metric?.metric_characteristics?.metric_unit !== 'NOT_AVAILABLE') {
      return metric?.metric_characteristics?.metric_unit?.toLowerCase();
    }
  };

  const extractTrend = (trend: string) => {
    if (trend) {
      if (trend === 'INCREASE') {
        return (
          <SvgIcon>
            <Upwards />
          </SvgIcon>
        );
      } else if (trend === 'DECREASE') {
        return (
          <SvgIcon>
            <Downwards />
          </SvgIcon>
        );
      } else {
        return '-';
      }
    }
  };

  const extractPercentData = () => {
    if (metric?.metric_samples?.[0]?.total_value) {
      return metric?.metric_type === 'SLEEP_DURATION' ? (
        <Box display="flex" flexDirection="column">
          <Box display="flex" alignItems="center">
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {calculateTime(metric?.metric_samples?.[0]?.total_value)}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box display="flex" alignItems={'center'}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', pr: 1 }}>
            {metric?.metric_samples[0]?.total_value}
          </Typography>
          <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>{extractSuffix()}</Typography>
        </Box>
      );
    } else {
      return (
        <Typography sx={{ fontSize: '16px', fontStyle: 'italic', fontWeight: 'bold' }}>No data available</Typography>
      );
    }
  };

  const extractMetricData = () => {
    if (metric?.metric_samples?.length || metric?.metric_goal) {
      if (metric?.metric_characteristics?.tracking_type !== 'AUTO') {
        if (metric?.metric_goal !== null) {
          return (
            <Box display="flex" gap={1} alignItems="center" justifyContent="space-between">
              <Box display="flex" flexDirection="column" gap={0.5}>
                <Box display="flex" flexDirection="column">
                  <Typography sx={{ fontSize: '14px' }}>Goal:</Typography>
                  <Box display="flex" alignItems="center">
                    {metric?.metric_type === 'SLEEP_DURATION' ? (
                      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {calculateTime(metric.metric_goal.target_value)}
                      </Typography>
                    ) : (
                      <>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', pr: 1 }}>
                          {metric?.metric_goal?.target_value}
                        </Typography>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>{extractSuffix()}</Typography>
                      </>
                    )}
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Typography sx={{ fontSize: '14px' }}>Today:</Typography>
                  <Box display="flex" alignItems="center">
                    {extractPercentData()}
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box sx={{ position: 'relative', display: 'inline-block', mt: 3 }}>
                  <Chart
                    options={options}
                    series={[metric?.metric_goal?.goal_percent || 0]}
                    type={'radialBar'}
                    width={135}
                    height={135}
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography fontWeight={'bold'} fontSize="22px" color="common.black">
                      {metric?.metric_goal?.goal_percent <= 100 ? metric?.metric_goal?.goal_percent || 0 : 100}%
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        } else {
          return (
            <Grid container alignItems={'center'}>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: '14px' }}>Today:</Typography>
              </Grid>
              <Box display="flex" alignItems="center" sx={{ pr: 3 }}>
                {metric?.metric_type === 'SLEEP_DURATION' ? (
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    {calculateTime(metric?.metric_samples?.[0]?.total_value)}
                  </Typography>
                ) : (
                  <>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', pr: 1 }}>
                      {metric?.metric_samples[0]?.total_value || 0}
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>{extractSuffix()}</Typography>
                  </>
                )}
              </Box>
              {metric?.metric_samples[0]?.metric_trend ? extractTrend(metric?.metric_samples[0]?.metric_trend) : '-'}
            </Grid>
          );
        }
      } else {
        return (
          <Box display="flex" alignItems="center" gap={3}>
            <Box display="flex" flexDirection="column">
              <Typography sx={{ fontSize: '14px' }}>Today:</Typography>
              <Box display="flex" alignItems="center">
                {extractPercentData()}
              </Box>
            </Box>
            {metric?.metric_samples[0]?.metric_trend ? extractTrend(metric?.metric_samples[0]?.metric_trend) : '-'}
            {(metric?.metric_characteristics?.metric_unit === 'BPM' ||
              metric?.metric_characteristics?.metric_unit === 'MS') && (
              <SvgIcon>
                <HeartRate />
              </SvgIcon>
            )}
          </Box>
        );
      }
    } else {
      return <Typography>No data available</Typography>;
    }
  };

  return (
    <Card
      sx={{
        borderRadius: 2,
        backgroundColor: 'common.white',
        boxShadow: `0px 4px 24px rgba(138, 138, 138, 0.25)`,
        maxWidth: '100%',
        width: '100%',
        position: 'relative',
        height: 250,
        overflow: 'visible',
        display: 'flex',
        borderWidth: metric?.primary ? 4 : 0,
        borderColor: theme.palette.warning.main,
        borderStyle: 'solid',
      }}
    >
      {metric.metric_goal && (
        <Goal
          overflow="visible"
          style={{
            position: 'absolute',
            top: -15,
            left: -10,
            display: 'block',
            overflow: 'visible',
            transform: 'rotate(-30deg)',
          }}
        />
      )}
      <CardContent sx={{ width: '100%' }}>
        <Box
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            gap: 0.1,

            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box display={'flex'}>
            {(metric?.metric_characteristics?.tracking_type === 'AUTO' && metric?.metric_type !== 'BMI') ||
            metric?.metric_characteristics?.tracking_type === 'HYBRID' ? (
              <Box
                sx={{
                  bgcolor: 'warning.main',
                  padding: '3px',
                  height: '24px',
                  width: '24px',
                  borderRadius: '4px',
                  mr: 1,
                }}
              >
                <WearableMetric height={18} width={18} />
              </Box>
            ) : null}
            <Typography sx={{ fontWeight: 'bold', fontSize: '16px', flex: 1 }}>{metric.title}</Typography>
            {metric?.metric_type === 'CUSTOM' ? (
              <Edit style={{ cursor: 'pointer' }} onClick={() => onEdit(metric)} />
            ) : null}
          </Box>

          {/*<StyledTypography>{metric.metric_description}</StyledTypography>*/}
          {extractMetricData()}
          <Box display="flex" justifyContent="center" marginTop={1}>
            {metric?.metric_characteristics?.tracking_type !== 'AUTO' ? (
              <StyledButton
                text="Log"
                inputProps={{ variant: 'contained', style: { fontWeight: 'bold', minWidth: 100 } }}
                onClick={() => handleMetricsModal(metric.title)}
              />
            ) : (
              <Box>
                <Grid container wrap={'nowrap'}>
                  <Grid item sx={{ '& *': { stroke: '#8B8B8B50' }, mr: 1 }}>
                    <SvgIcon color="action">
                      <Automatic />
                    </SvgIcon>
                  </Grid>
                  <Grid item>
                    <Typography sx={{ color: '#8B8B8B50', fontSize: 14 }}>{'Automatic value'}</Typography>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        </Box>
      </CardContent>
      {metric?.primary ? (
        <Typography
          sx={{
            position: 'absolute',
            bottom: -1,
            right: -1,
            backgroundColor: theme.palette.warning.main,
            color: 'white',
            borderTopLeftRadius: 8,
            px: '12px',
            pt: '4px',
            fontSize: 12,
            fontWeight: 'bold',
          }}
        >
          FOCUS
        </Typography>
      ) : null}
    </Card>
  );
};
