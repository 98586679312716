import { CustomButton } from '../../components/features/CustomButton';
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material';
import { Colors } from '../../theme/colors';
import React from 'react';

import { ReactComponent as PlusIcon } from '../../assets/svg/plus.svg';
import { ReactComponent as ExportIcon } from '../../assets/svg/feature/export.svg';
import theme from '../../theme';
import { useIonRouter } from '@ionic/react';
import { Capacitor } from '@capacitor/core';

type FilterAdditionalActionsProps = {
  handleExport: () => void;
};

export const FilterAdditionalActions: React.FC<FilterAdditionalActionsProps> = ({ handleExport }) => {
  const route = useIonRouter();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const isWeb = !Capacitor.isNativePlatform();
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: mdDown ? '16px' : 0 }}>
      <IconButton
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: Colors.orange[500],
          height: 36,
          width: 179,
          ml: { xs: '8px', md: '16px' },
          borderRadius: '100px',
          color: Colors.white,
          '&:hover': {
            background: Colors.orange[400],
            color: '#FAEED7',
            '& svg': {
              fill: '#FAEED7',
            },
          },
        }}
        onClick={() => route.push('/page/employees-challenges/challenge-templates')}
      >
        <PlusIcon width={16} height={16} />
        <Typography sx={{ fontSize: '16px', fontWeight: 700, ml: '4px' }}>Add challenge</Typography>
      </IconButton>

      {isWeb && (
        <IconButton
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#FAEED7',
            height: 36,
            width: 113,
            mr: mdDown ? 0 : '16px',
            ml: { xs: '8px', md: '16px' },
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: Colors.orange[300],
            borderRadius: '100px',
            color: Colors.orange[500],
            '&:hover': {
              background: Colors.orange[500],
              color: '#FAEED7',
              '& svg': {
                fill: '#FAEED7',
              },
            },
          }}
          onClick={handleExport}
        >
          <ExportIcon height={16} width={16} fill={Colors.orange[500]} />
          <Typography sx={{ fontSize: '14px', ml: '5px' }}>Export</Typography>
        </IconButton>
      )}
    </Box>
  );
};
