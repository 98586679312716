import { IonContent, IonPage } from '@ionic/react';
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import JourneyComponent from '../components/JourneyComponent';
import WellnessComponent from '../components/WellnessComponent';
import CustomHeader from '../components/structure/CustomHeader';
import CustomDivider from '../components/common/CustomDivider';
import { useDispatch, useSelector } from 'react-redux';
import {
  asyncDeleteMetric,
  asyncGetAdminJourneyCards,
  asyncGetAdminMetrics,
  asyncGetMetricsByPillar,
  asyncUpdateMetric,
  getAdminWellnessMetrics,
  getWellnessChartAdmin,
} from '../redux/slices/journeyPageSlice';
import { RootState } from '../redux/store';
import MetricsContainer from '../components/Journey/MetricsContainer';
import CustomCalendar from '../components/common/CustomCalendar';
import moment from 'moment';
import { IDeleteMetric, IMetric, IUpdateMetric } from '../models/Metrics';
import { Range } from 'react-date-range';
import useViewIsVisible from '../hooks/useViewIsVisible';
import CustomSubHeader from '../components/structure/CustomSubHeader';
import MetricsEditModal from '../components/modals/MetricsEditModal';
import { ANALYTICS_PAGE } from '../models/enum/ANALYTICS_PAGE';
import { PERIOD } from '../models/Shared';

const JourneyAdminPage: React.FC = () => {
  const dispatch = useDispatch();

  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [date, setDate] = useState(new Date().toString());

  const [graphMetricsSelected, setGraphMetricsSelected] = useState<IMetric[]>([]);
  const [selectedGoal, setSelectedGoal] = useState<string>('Not Selected');

  const [selectedPeriod, setSelectedPeriod] = useState<PERIOD>(PERIOD.MONTH);
  const [range, setRange] = useState<Range[]>([]);

  const [editMetric, setEditMetric] = useState<IMetric>();

  const visible = useViewIsVisible(ANALYTICS_PAGE.JOURNEY_ADMIN);

  const { adminMetrics, adminGraphMetrics, journeyAdminCards, adminGraph, metricsModal } = useSelector(
    (state: RootState) => state.journeyPage.data,
  );
  const { journeyAdminCardsLoading, metricsLoading } = useSelector((state: RootState) => state.journeyPage.meta);

  useEffect(() => {
    if (!visible) return;

    setDate(new Date().toString());
    setSelectedPeriod(PERIOD.MONTH);
    setRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ]);
  }, [dispatch, visible]);

  useEffect(() => {
    if (!graphMetricsSelected.length) {
      const preselectedGraphs = ['WELLBEING', 'SLEEP_DURATION', 'STEPS', 'WATER_CONSUMPTION'];
      setGraphMetricsSelected(adminGraphMetrics.metrics.filter((m) => preselectedGraphs.includes(m.metric_type)));
    }
  }, [adminGraphMetrics, graphMetricsSelected.length]);

  const fetchWellnessChart = useCallback(() => {
    if (graphMetricsSelected.length > 0 && range.length === 1) {
      const { startDate, endDate } = range[0];
      if (startDate && endDate) {
        let actualStartDate: any = startDate;
        let actualEndDate: any = endDate;
        if (moment(startDate).isSame(endDate, 'day')) {
          actualEndDate = moment();
          if (selectedPeriod === PERIOD.WEEK) {
            actualStartDate = moment().subtract(7, 'days');
          } else if (selectedPeriod === PERIOD.MONTH) {
            actualStartDate = moment().subtract(1, 'month');
          }
          if (selectedPeriod === PERIOD.YEAR) {
            actualStartDate = moment().subtract(1, 'year');
          }
        }
        dispatch(
          getWellnessChartAdmin({
            metrics: graphMetricsSelected,
            date_from: actualStartDate.toString(),
            date_to: actualEndDate.toString(),
          }),
        );
      }
    }
  }, [dispatch, graphMetricsSelected, range, selectedPeriod]);

  useEffect(() => {
    if (!visible) return;

    if (metricsModal.isOpen === false) {
      fetchWellnessChart();
    }
  }, [visible, fetchWellnessChart, metricsModal.isOpen]);

  useEffect(() => {
    if (metricsModal.isOpen === false) {
      dispatch(asyncGetMetricsByPillar({ date: moment(date).format('YYYY-MM-DD'), pillar: 'ALL' }));
      dispatch(asyncGetAdminMetrics({ date: moment(date).format('YYYY-MM-DD') }));
      dispatch(asyncGetAdminJourneyCards({ date: moment(date).format('YYYY-MM-DD') }));
    }
  }, [dispatch, date, metricsModal.isOpen]);

  useEffect(() => {
    if (!visible) return;

    if (metricsModal.isOpen === false) {
      dispatch(getAdminWellnessMetrics());
    }
  }, [dispatch, metricsModal.isOpen, visible]);

  const getGoalList = () => {
    return ['Not Selected', ...adminGraph.goals.map((g) => g.goal_category_title)];
  };

  const closeEditMetric = () => {
    setEditMetric(undefined);
  };

  const updateMetric = (metric: IMetric) => {
    if (editMetric) {
      const updateMetric: IUpdateMetric = {
        metric_category_group: editMetric.group,
        metric_value: editMetric.metric_samples?.[0]?.total_value ?? 0,
        title: metric.title,
        previous_title: editMetric.title,
        goal_value: editMetric.metric_goal?.target_value,
      };
      dispatch(
        asyncUpdateMetric({
          metric: updateMetric,
          date: moment(date).format('YYYY-MM-DD'),
        }),
      );
      setEditMetric(undefined);
    }
  };

  const deleteMetric = (metric: IMetric) => {
    if (editMetric) {
      const deleteMetric: IDeleteMetric = {
        metric_category_group: editMetric.group,
        title: metric.title,
      };
      dispatch(
        asyncDeleteMetric({
          metric: deleteMetric,
          date: moment(date).format('YYYY-MM-DD'),
        }),
      );
      setEditMetric(undefined);
    }
  };

  return (
    <IonPage>
      <CustomHeader
        label={"Your Employees' Journey"}
        tooltip={
          'View your employees’ trends over time related to various well-being metrics and get insights on how each aspect affects your organization’s well-being. The Wellics™ Index (WIN) reflects the average well-being of your team.'
        }
      >
        <Grid container justifyContent={xsDown ? 'center' : 'flex-end'}>
          <Grid item sx={{ mr: xsDown ? 0 : 1 }}>
            <CustomCalendar date={date} setDate={setDate} />
          </Grid>
        </Grid>
      </CustomHeader>
      <IonContent style={{ '--padding-bottom': '42px' }}>
        <Container maxWidth={'lg'}>
          <Grid item xs={12} sx={{ mt: 4 }}>
            <Container maxWidth={'lg'}>
              <Grid container justifyContent={'space-between'} wrap={'nowrap'}>
                <Grid item sx={{ flex: 1, whiteSpace: 'nowrap' }}>
                  <CustomSubHeader
                    label={`Your Organization's Progress`}
                    tooltip={
                      'See the progress your people are doing and the trends of your team on various well-being metrics. You can select up to any four metrics and change the period as you like. If one of your selections is your goal metric, then you can select to display the goal target value.'
                    }
                  />
                </Grid>
              </Grid>
            </Container>
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <JourneyComponent admin journeyData={journeyAdminCards} loading={journeyAdminCardsLoading} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <CustomDivider marginY={8} />
          </Grid>
          <Grid container sx={{ mt: 2, mb: 8 }}>
            <WellnessComponent
              metricsList={adminGraphMetrics.metrics}
              selectedMetrics={graphMetricsSelected}
              setSelectedMetrics={setGraphMetricsSelected}
              period={selectedPeriod}
              range={range}
              setRange={setRange}
              graph={adminGraph}
              setPeriod={setSelectedPeriod}
              selectedGoal={selectedGoal}
              setSelectedGoal={setSelectedGoal}
              goalsList={getGoalList()}
            />
            <Grid item xs={12}>
              <CustomDivider marginY={8} />
            </Grid>
            <Container maxWidth={'lg'}>
              <Grid container justifyContent={'space-between'} wrap={'nowrap'} sx={{ mb: -22 }}>
                <Grid item sx={{ flex: 1, whiteSpace: 'nowrap' }}>
                  <CustomSubHeader
                    label={`Metrics & Goals`}
                    tooltip={
                      'Create custom metrics that reflect your company-wide KPIs through the ⊕ button on the bottom right of the page. Log their values regularly and set them as goals.'
                    }
                  />
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Container>
        <Box sx={{ mb: 4 }}>
          {adminMetrics?.metrics?.length || metricsLoading ? (
            <MetricsContainer
              metrics={adminMetrics}
              date={date}
              onEdit={setEditMetric}
              page={ANALYTICS_PAGE.JOURNEY_ADMIN}
              loading={metricsLoading}
            />
          ) : (
            <Box display="flex" width="100%" justifyContent="center" sx={{ p: 3 }}>
              <Typography fontWeight="bold" variant="body1">
                No metrics available
              </Typography>
            </Box>
          )}
        </Box>
        <MetricsEditModal
          metric={editMetric}
          onClose={closeEditMetric}
          onUpdate={updateMetric}
          onDelete={deleteMetric}
        />
      </IonContent>
    </IonPage>
  );
};

export default JourneyAdminPage;
