import React, { useEffect, useState } from 'react';
import { TableRow } from './TableRow';
import { TableCell } from './TableCell';
import { InfoOutlined } from '@mui/icons-material';
import { Colors } from '../../../theme/colors';
import { Box, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { numberWithSpaces } from '../../../utils/numberWithSpace';
import { Checkbox } from './Checkbox';
import CustomTextField from '../../../components/common/CustomTextField';
import { ModalCatalogPrams, RowUserType } from '../types';
import { Avatar } from '../../../components/features/Avatar';
import { ReactComponent as TrophyIcon } from '../../../assets/svg/feature/trophy.svg';
import { ReactComponent as PointIcon } from '../../../assets/svg/feature/awards/point.svg';
import theme from '../../../theme';

const re = /^\d+\.?\d*$/;

type TableUserRowProps = RowUserType & {
  inputAmount: string;
  inputPoints: string;
  onChangeAmount: (val: string) => void;
  onChangePoints: (val: string) => void;
  handleEdit?: (isRemove?: boolean) => void;
  openCatalog?: (params: ModalCatalogPrams) => void;
};

export const TableUserRow: React.FC<TableUserRowProps> = ({
  uuid,
  avatar,
  name,
  points,
  country,
  rewardEligible,
  reward,
  isRewardLink,
  isEdit,
  inputAmount,
  inputPoints,
  inputErrors,
  onChangePoints,
  onChangeAmount,
  handleEdit = () => null,
  openCatalog = () => null,
}) => {
  const [amountVal, setAmountVal] = useState('');
  const [pointsVal, setPointsVal] = useState('');
  const [errors, setErrors] = useState<{ amount?: boolean; points?: boolean }>();

  const tableDown = useMediaQuery(theme.breakpoints.down('table'));

  const onChangeAmountVal = (e: any) => {
    const val = e.target.value;
    if (val === '' || re.test(val)) {
      setErrors({ ...errors, amount: false });
      onChangeAmount(val);
      setAmountVal(val);
    }
  };

  const onChangePointsVal = (e: any) => {
    const val = e.target.value;
    if (val === '' || /^\d*$/.test(val)) {
      setErrors({ ...errors, points: false });
      if (Number(val) <= points) {
        onChangePoints(val);
        setPointsVal(val);
        return;
      }
      onChangePoints(Math.floor(points).toString());
      setPointsVal(Math.floor(points).toString());
    }
  };

  useEffect(() => {
    setAmountVal(inputAmount);
  }, [inputAmount]);
  useEffect(() => {
    setPointsVal(inputPoints);
  }, [inputPoints]);
  useEffect(() => {
    setErrors(inputErrors);
  }, [inputErrors]);
  return tableDown ? (
    <Box sx={{ borderBottom: `1px solid ${Colors.gray[100]}` }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '16px 15px',
          borderBottom: `1px solid ${Colors.gray[100]}`,
        }}
      >
        <Box {...(rewardEligible && { onClick: () => handleEdit(isEdit) })} sx={{ mr: '16px' }}>
          {rewardEligible ? (
            <Checkbox isSelected={isEdit} />
          ) : (
            <Tooltip
              enterTouchDelay={0}
              title={
                country
                  ? "The user's country is not included in the list of eligible countries. You cannot proceed to a redemption for this user."
                  : 'Ask the user to add their country of residence to their profile to be eligible for redemption'
              }
            >
              <InfoOutlined sx={{ color: Colors.gray[400], fontSize: '16px' }} />
            </Tooltip>
          )}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mr: '8px' }}>
          <Avatar
            alt={name}
            src={avatar ?? undefined}
            sx={{ mr: '16px', height: '32px', width: '32px', ...(!rewardEligible && { opacity: 0.7 }) }}
          />
          <Typography
            sx={{
              flex: 1,
              fontSize: '14 px',
              fontWeight: 800,
              color: rewardEligible ? Colors.gray[700] : Colors.gray[400],
            }}
          >
            {name}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            ml: 'auto',
            alignItems: 'center',
            padding: '1px 8px 1px 1px',
            background: rewardEligible ? '#E8F8FA' : '#F8F8F9',
            borderRadius: '30px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '20px',
              width: '20px',
              borderRadius: '50%',
              border: `1px solid ${rewardEligible ? Colors.blue[200] : Colors.gray[100]}`,
              background: rewardEligible ? Colors.blue[500] : Colors.gray[200],
              mr: '4px',
            }}
          >
            <PointIcon height={12} width={12} />
          </Box>
          <Typography sx={{ fontSize: '12px', color: rewardEligible ? Colors.blue[600] : Colors.gray[300] }}>
            {numberWithSpaces(Math.floor(points))}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '16px 12px' }}>
        <Typography
          sx={{ fontSize: '14px', fontWeight: 800, color: rewardEligible ? Colors.gray[500] : Colors.gray[300] }}
        >
          Country
        </Typography>
        <Typography sx={{ fontSize: '16px', color: rewardEligible ? Colors.gray[700] : Colors.gray[400] }}>
          {country ?? '-'}
        </Typography>
      </Box>
      <Box sx={{ padding: '16px 12px' }}>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 800,
            color: rewardEligible ? Colors.gray[500] : Colors.gray[300],
            mb: '24px',
          }}
        >
          Amount
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: '24px' }}>
          {!isRewardLink && rewardEligible && country && (
            <Tooltip enterTouchDelay={0} title={reward?.rewardTitle ?? ''}>
              <Box
                component="button"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '28px',
                  width: '28px',
                  borderRadius: '50%',
                  background: reward?.rewardTitle ? '#FAEED7' : Colors.orange[500],
                  border: `1px solid ${reward?.rewardTitle ? Colors.orange[300] : Colors.orange[200]}`,
                  mr: '8px',
                  ...(!isEdit && { cursor: 'default' }),
                }}
                onClick={
                  isEdit
                    ? () =>
                        openCatalog({
                          country,
                          empUUID: uuid,
                          rewardName: reward?.rewardTitle,
                          brandId: reward?.rewardBrandId,
                        })
                    : undefined
                }
              >
                <TrophyIcon height={12} width={12} fill={reward?.rewardTitle ? Colors.orange[600] : Colors.white} />
              </Box>
            </Tooltip>
          )}
          <Box sx={{ flex: 1 }}>
            <CustomTextField
              label=""
              name="amount"
              value={amountVal}
              error={errors?.amount}
              setValue={onChangeAmountVal}
              inputProps={{
                type: 'number',
                sx: {
                  '& .MuiInputBase-root': {
                    '&.Mui-error': {
                      backgroundColor: Colors.white,
                      border: '1px solid red',
                    },
                    '&.Mui-disabled': {
                      background: '#F7F9F9',
                      '& input': {
                        WebkitTextFillColor: Colors.gray[150],
                      },
                      '& fieldset': {
                        borderColor: Colors.gray[100],
                      },
                    },
                  },
                },
                disabled: !rewardEligible || !reward || (reward && reward.min === reward.max) || !isEdit,
                placeholder: 'Enter number',
              }}
            />
          </Box>
          <Tooltip
            enterTouchDelay={0}
            title={
              typeof reward?.min === 'number' && typeof reward?.max === 'number'
                ? reward.min === reward.max
                  ? `Amount is equal to the award monetary value`
                  : `${reward.min}-${reward.max}${' ' + reward.currency} max in ${country ?? ''}`
                : ''
            }
          >
            <InfoOutlined
              sx={{
                color:
                  typeof reward?.min === 'number' && typeof reward?.max === 'number'
                    ? Colors.gray[400]
                    : Colors.gray[200],
                fontSize: '16px',
                ml: '8px',
              }}
            />
          </Tooltip>
        </Box>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 800,
            color: rewardEligible ? Colors.gray[500] : Colors.gray[300],
            mb: '24px',
          }}
        >
          Points for redemption
        </Typography>
        <CustomTextField
          label=""
          name="points"
          value={pointsVal}
          error={errors?.points}
          setValue={onChangePointsVal}
          inputProps={{
            type: 'number',
            disabled: !rewardEligible || !isEdit,
            placeholder: 'Enter number',
            sx: {
              '& .MuiInputBase-root': {
                '&.Mui-error': {
                  backgroundColor: Colors.white,
                  border: '1px solid red',
                },
                '&.Mui-disabled': {
                  background: '#F7F9F9',
                  '& input': {
                    WebkitTextFillColor: Colors.gray[150],
                  },
                  '& fieldset': {
                    borderColor: Colors.gray[100],
                  },
                },
              },
            },
          }}
        />
      </Box>
    </Box>
  ) : (
    <TableRow
      sx={{
        ...(!rewardEligible && { background: '#FBFBFB' }),
        flexDirection: { xs: 'column', table: 'unset' },
        height: { xs: 'auto', table: '60px' },
      }}
    >
      <TableCell
        sx={{ flex: '0 0 64px', borderBottom: `1px solid ${Colors.gray[100]}` }}
        {...(rewardEligible && { onClick: () => handleEdit(isEdit) })}
      >
        {rewardEligible ? (
          <Checkbox isSelected={isEdit} />
        ) : (
          <Tooltip
            enterTouchDelay={0}
            title={
              country ? (
                <Typography variant="body2">
                  {`The user's country is not included in the list of eligible countries. You cannot proceed to a
                  redemption for this user.`}
                </Typography>
              ) : (
                <Typography variant="body2">
                  Ask the user to add their country of residence to their profile to be eligible for redemption
                </Typography>
              )
            }
          >
            <InfoOutlined sx={{ color: Colors.gray[400], fontSize: '16px' }} />
          </Tooltip>
        )}
      </TableCell>
      <TableCell>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt={name} src={avatar ?? undefined} sx={{ mr: '16px', ...(!rewardEligible && { opacity: 0.7 }) }} />
          <Typography
            sx={{
              flex: 1,
              fontSize: '16px',
              fontWeight: 800,
              color: rewardEligible ? Colors.gray[700] : Colors.gray[400],
            }}
          >
            {name}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box>
          <Typography sx={{ fontSize: '16px', color: rewardEligible ? Colors.gray[700] : Colors.gray[400] }}>
            {country ?? '-'}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
          {!isRewardLink && rewardEligible && country && (
            <Tooltip
              enterTouchDelay={0}
              title={reward?.rewardTitle ? <Typography variant="body2">{reward.rewardTitle}</Typography> : ''}
            >
              <Box
                component="button"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'absolute',
                  height: '28px',
                  width: '28px',
                  borderRadius: '50%',
                  background: reward?.rewardTitle ? '#FAEED7' : Colors.orange[500],
                  border: `1px solid ${reward?.rewardTitle ? Colors.orange[300] : Colors.orange[200]}`,
                  top: '50%',
                  left: 0,
                  transform: 'translate(-100%, -50%)',
                  ...(!isEdit && { cursor: 'default' }),
                }}
                onClick={
                  isEdit
                    ? () =>
                        openCatalog({
                          country,
                          empUUID: uuid,
                          rewardName: reward?.rewardTitle,
                          brandId: reward?.rewardBrandId,
                        })
                    : undefined
                }
              >
                <TrophyIcon height={12} width={12} fill={reward?.rewardTitle ? Colors.orange[600] : Colors.white} />
              </Box>
            </Tooltip>
          )}
          <CustomTextField
            label=""
            name="amount"
            value={amountVal}
            error={errors?.amount}
            setValue={onChangeAmountVal}
            inputProps={{
              type: 'number',
              sx: {
                ml: '12px',
                '& .MuiInputBase-root': {
                  '&.Mui-error': {
                    backgroundColor: Colors.white,
                    border: '1px solid red',
                  },
                  '&.Mui-disabled': {
                    background: '#F7F9F9',
                    '& input': {
                      WebkitTextFillColor: Colors.gray[150],
                    },
                    '& fieldset': {
                      borderColor: Colors.gray[100],
                    },
                  },
                },
              },
              disabled: !rewardEligible || !reward || (reward && reward.min === reward.max) || !isEdit,
              placeholder: 'Enter number',
            }}
          />
          <Tooltip
            enterTouchDelay={0}
            title={
              typeof reward?.min === 'number' && typeof reward?.max === 'number' ? (
                reward.min === reward.max ? (
                  <Typography variant="body2">{`Amount is equal to the award monetary value`}</Typography>
                ) : (
                  <Typography variant="body2">{`${reward.min}-${reward.max}${' ' + reward.currency} max in ${
                    country ?? ''
                  }`}</Typography>
                )
              ) : (
                ''
              )
            }
          >
            <InfoOutlined
              sx={{
                color:
                  typeof reward?.min === 'number' && typeof reward?.max === 'number'
                    ? Colors.gray[400]
                    : Colors.gray[200],
                fontSize: '16px',
                ml: '8px',
              }}
            />
          </Tooltip>
        </Box>
      </TableCell>
      <TableCell sx={{ flex: '0 0 168px' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '1px 16px 1px 1px',
            background: rewardEligible ? '#E8F8FA' : '#F8F8F9',
            borderRadius: '30px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '32px',
              width: '32px',
              borderRadius: '50%',
              border: `1px solid ${rewardEligible ? Colors.blue[200] : Colors.gray[100]}`,
              background: rewardEligible ? Colors.blue[500] : Colors.gray[200],
              mr: '8px',
            }}
          >
            <PointIcon height={16} width={16} />
          </Box>
          <Typography sx={{ fontSize: '16px', color: rewardEligible ? Colors.blue[600] : Colors.gray[300] }}>
            {numberWithSpaces(Math.floor(points))}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box>
          <CustomTextField
            label=""
            name="points"
            value={pointsVal}
            error={errors?.points}
            setValue={onChangePointsVal}
            inputProps={{
              type: 'number',
              disabled: !rewardEligible || !isEdit,
              placeholder: 'Enter number',
              sx: {
                '& .MuiInputBase-root': {
                  '&.Mui-error': {
                    backgroundColor: Colors.white,
                    border: '1px solid red',
                  },
                  '&.Mui-disabled': {
                    background: '#F7F9F9',
                    '& input': {
                      WebkitTextFillColor: Colors.gray[150],
                    },
                    '& fieldset': {
                      borderColor: Colors.gray[100],
                    },
                  },
                },
              },
            }}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
};
