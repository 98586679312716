import { Button, ButtonProps, styled } from '@mui/material';
import React from 'react';
import { Colors } from '../../theme/colors';
import { SxProps } from '@mui/system';

type ButtonType = {
  type?:
    | 'bigBlue'
    | 'bigLightBlue'
    | 'lightBlue'
    | 'bigOrange'
    | 'bigLightOrange'
    | 'bigLightRed'
    | 'bigMagenta'
    | 'bigLightMagenta'
    | 'smallMagenta'
    | 'white';
};
type Props = ButtonType & {
  width?: string;
  minWidth?: string;
  text: React.ReactNode;
  startIcon?: any;
  endIcon?: any;
  onClick: (e: any) => void;
  inputProps?: ButtonProps;
  sx?: SxProps;
  disabled?: boolean;
};
export const CustomButton: React.FC<Props> = ({
  width = '150px',
  minWidth = '94px',
  type,
  text,
  sx,
  inputProps,
  startIcon,
  endIcon,
  onClick,
  disabled,
}) => {
  const StyledButton = styled(Button)(({ theme }) => {
    switch (type) {
      case 'bigBlue':
        return {
          height: '48px',
          background: Colors.blue[500],
          color: Colors.white,
          padding: '12px 16px',
          minWidth: minWidth,
          borderRadius: '100px',
          boxShadow: 'none',
          width: '100%',
          maxWidth: width,
          fontSize: '16px',
          '&:hover': {
            background: Colors.blue[600],
          },
          whiteSpace: 'nowrap',
        };
        break;
      case 'bigOrange':
        return {
          height: '48px',
          background: Colors.orange[500],
          color: Colors.white,
          padding: '12px 16px',
          minWidth: minWidth,
          borderRadius: '100px',
          boxShadow: 'none',
          width: '100%',
          maxWidth: width,
          fontSize: '16px',
          '&:hover': {
            background: Colors.orange[600],
          },
          whiteSpace: 'nowrap',
        };
        break;
      case 'bigMagenta':
        return {
          height: '48px',
          background: Colors.magenta[300],
          color: Colors.white,
          padding: '12px 16px',
          minWidth: minWidth,
          borderRadius: '100px',
          boxShadow: 'none',
          width: '100%',
          maxWidth: width,
          fontSize: '16px',
          '&:hover': {
            background: Colors.magenta[400],
          },
          whiteSpace: 'nowrap',
        };
        break;
      case 'smallMagenta':
        return {
          height: '28px',
          background: Colors.magenta[300],
          color: Colors.white,
          padding: '12px 16px',
          minWidth: minWidth,
          borderRadius: '100px',
          boxShadow: 'none',
          width: '100%',
          maxWidth: width,
          fontSize: '12px',
          '&:hover': {
            background: Colors.magenta[400],
          },
          whiteSpace: 'nowrap',
        };
        break;
      case 'bigLightMagenta':
        return {
          height: '48px',
          background: Colors.magenta[100],
          color: Colors.magenta[400],
          padding: '12px 16px',
          minWidth: minWidth,
          borderRadius: '100px',
          boxShadow: 'none',
          width: '100%',
          maxWidth: width,
          fontSize: '16px',
          border: '1px solid #DAB9F1',
          '&:hover': {
            color: Colors.magenta[500],
            background: Colors.magenta[200],
          },
          whiteSpace: 'nowrap',
        };
        break;
      case 'bigLightBlue':
        return {
          height: '48px',
          background: Colors.blue[100],
          color: Colors.blue[500],
          padding: '12px 16px',
          minWidth: minWidth,
          borderRadius: '100px',
          boxShadow: 'none',
          width: '100%',
          maxWidth: width,
          fontSize: '16px',
          border: '1px solid #A2E5EC',
          '&:hover': {
            color: Colors.blue[600],
            background: '#B9EBF1',
          },
          whiteSpace: 'nowrap',
        };
        break;
      case 'lightBlue':
        return {
          height: '36px',
          background: Colors.blue[100],
          color: Colors.blue[500],
          padding: '12px 16px',
          minWidth: minWidth,
          borderRadius: '100px',
          boxShadow: 'none',
          whiteSpace: 'nowrap',
        };
        break;
      case 'bigLightOrange':
        return {
          height: '48px',
          background: '#FAEED7',
          color: Colors.orange[600],
          padding: '12px 16px',
          minWidth: minWidth,
          borderRadius: '100px',
          boxShadow: 'none',
          width: '100%',
          maxWidth: width,
          fontSize: '16px',
          border: '1px solid #FCCF79',
          '&:hover': {
            color: Colors.orange[700],
            background: Colors.orange[150],
          },
          whiteSpace: 'nowrap',
        };
        break;

      case 'bigLightRed':
        return {
          height: '48px',
          background: Colors.red[150],
          color: Colors.red[500],
          padding: '12px 16px',
          minWidth: minWidth,
          borderRadius: '100px',
          boxShadow: 'none',
          width: '100%',
          maxWidth: width,
          fontSize: '16px',
          border: '1px solid #F4CACB',
          '&:hover': {
            color: Colors.red[600],
            background: Colors.red[200],
          },
          whiteSpace: 'nowrap',
        };
        break;

      case 'white':
        return {
          height: '36px',
          background: Colors.white,
          color: Colors.gray[700],
          padding: '12px 16px',
          minWidth: minWidth,
          borderRadius: '100px',
          whiteSpace: 'nowrap',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.10)',
          fontSize: '14px',
        };
        break;

      default:
        return {
          height: '36px',
          background: Colors.blue[500],
          color: Colors.white,
          padding: '12px 16px',
          minWidth: minWidth,
          borderRadius: '100px',
          boxShadow: 'none',
          whiteSpace: 'nowrap',
        };
    }
  });

  return (
    <StyledButton
      sx={{
        '&.Mui-disabled': {
          background: Colors.gray[100],
          color: Colors.gray[200],
          border: 'unset',
        },
        ...sx,
      }}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      {...inputProps}
      onClick={onClick}
    >
      {text}
    </StyledButton>
  );
};
