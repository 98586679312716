import { useEffect, useState } from 'react';
import { COMPETITION } from '../../../models/enum/COMPETITION';
import { DIMENSION } from '../../../models/enum/DIMENSION';

import { FilterFormParams } from '../../../models/Shared';
import { CHALLENGE_TYPE } from '../../../models/enum/CHALLENGE_TYPE';

export type QueryFilterData = {
  metric?: string;
  searchText: string;
  challengePillars?: DIMENSION[];
  competition?: COMPETITION[];
  challengeType?: CHALLENGE_TYPE[];
  onlyCorporate?: boolean;
  publisher?: boolean;
};

let timeout: NodeJS.Timeout | undefined;

export const useHandleFilter = () => {
  const [filterParams, setFilterParams] = useState<FilterFormParams>({
    dimention: [],
    competition: [],
    challengeType: [],
    metric: '',
    onlyCorporate: false,
    publisher: false,
  });
  const [queryFilter, setQueryFilter] = useState<QueryFilterData>({
    searchText: '',
    challengePillars: [],
    competition: [],
    challengeType: [],
    onlyCorporate: false,
    publisher: false,
  });
  const [searchValue, setSearchValue] = useState<string>('');
  const [expanded, setExpanded] = useState(false);
  const [showResetFilters, setShowResetFilters] = useState(false);

  const onChangeSearch = (value: string) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    setSearchValue(value);
    timeout = setTimeout(() => setQueryFilter((prev) => ({ ...prev, searchText: value })), 400);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleChangeSortBy = (metric: string) => {
    setFilterParams({ ...filterParams, metric });
    setQueryFilter({ ...queryFilter, metric });
  };

  const handleItemDimention = (value: string, isSelected: boolean) => () => {
    const dimention = isSelected
      ? filterParams.dimention.filter((item) => item !== value)
      : [...filterParams.dimention, value];
    setFilterParams({
      ...filterParams,
      dimention,
    });
    setQueryFilter((prev) => ({ ...prev, challengePillars: dimention as DIMENSION[] }));
  };

  const handleItemCompetition = (value: string, isSelected: boolean) => () => {
    const competition = isSelected
      ? filterParams.competition.filter((item) => item !== value)
      : [...filterParams.competition, value];
    if (queryFilter.onlyCorporate && value === COMPETITION.SELF) {
      setFilterParams({
        ...filterParams,
        competition,
        onlyCorporate: false,
      });
      setQueryFilter((prev) => ({ ...prev, onlyCorporate: false, competition: competition as COMPETITION[] }));
      return;
    }
    setFilterParams({
      ...filterParams,
      competition,
    });
    setQueryFilter((prev) => ({ ...prev, competition: competition as COMPETITION[] }));
  };

  const handleItemChallengeType = (value: string, isSelected: boolean) => () => {
    if (!filterParams.challengeType) return;
    const challengeType = isSelected
      ? filterParams.challengeType.filter((item) => item !== value)
      : [...filterParams.challengeType, value];
    setFilterParams({
      ...filterParams,
      challengeType,
    });
    setQueryFilter((prev) => ({ ...prev, challengeType: challengeType as CHALLENGE_TYPE[] }));
  };

  const handleSwitchCorporate = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setFilterParams({
        ...filterParams,
        competition: filterParams.competition.filter((item) => item !== COMPETITION.SELF),
        onlyCorporate: event.target.checked,
      });
      setQueryFilter({
        ...queryFilter,
        competition: filterParams.competition.filter((item) => item !== COMPETITION.SELF) as COMPETITION[],
        onlyCorporate: event.target.checked,
      });
      return;
    }
    setQueryFilter({
      ...queryFilter,
      onlyCorporate: event.target.checked,
    });
    setFilterParams({ ...filterParams, onlyCorporate: event.target.checked });
  };
  const handleSwitchPublisher = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryFilter({
      ...queryFilter,
      publisher: event.target.checked,
    });
    setFilterParams({ ...filterParams, publisher: event.target.checked });
  };

  const handleResetFilters = () => {
    setFilterParams({
      dimention: [],
      competition: [],
      challengeType: [],
      metric: '',
      onlyCorporate: false,
      publisher: false,
    });
    setQueryFilter({
      searchText: '',
      challengePillars: [],
      competition: [],
      challengeType: [],
      onlyCorporate: false,
      publisher: false,
    });
    setSearchValue('');
  };

  useEffect(() => {
    if (
      queryFilter.searchText.length > 0 ||
      queryFilter.challengePillars?.length ||
      queryFilter.competition?.length ||
      queryFilter.challengeType?.length ||
      queryFilter.onlyCorporate ||
      queryFilter.publisher ||
      queryFilter.metric?.length
    ) {
      setShowResetFilters(true);

      return;
    }
    setShowResetFilters(false);
  }, [queryFilter]);

  return {
    queryFilter,
    searchValue,
    filterParams,
    expanded,
    showResetFilters,
    onChangeSearch,
    handleExpandClick,
    handleChangeSortBy,
    handleItemDimention,
    handleItemCompetition,
    handleItemChallengeType,
    handleSwitchCorporate,
    handleSwitchPublisher,
    handleResetFilters,
  };
};
