import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { asyncGetTemplatesMetrics } from '../../redux/slices/challengeAdminSlice';
import { METRICS } from '../../models/enum/METRICS';
import { RootState } from '../../redux/store';

export const useMetricsHandle = () => {
  const dispatch = useDispatch();

  const templatesMetrics = useSelector((state: RootState) => state.challengeAdmin.data.templatesMetrics);

  const metrics = useMemo(
    () =>
      METRICS.filter((metric) => templatesMetrics.findIndex((item) => item === metric.value) >= 0).map((metric) => ({
        label: metric.label,
        value: metric.value,
      })),
    [templatesMetrics],
  );

  useEffect(() => {
    dispatch(asyncGetTemplatesMetrics());
  }, []);

  return { metrics };
};
