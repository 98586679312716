import { throttle } from 'lodash';
import { useState, useEffect, useRef } from 'react';
import { getPointsRequest } from '../../../lib/api/http/requests/awards';
import useIsPageActive from '../../../hooks/useIsPageActive';
import { ANALYTICS_PAGE } from '../../../models/enum/ANALYTICS_PAGE';
import { Points } from '../../../models/Points';

const SIZE_PAGE = 12;

export const useHandleFetch = () => {
  const [items, setItems] = useState<Points[]>();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);

  const visible = useIsPageActive(ANALYTICS_PAGE.POINTS);
  const divRef = useRef<HTMLIonContentElement>(null);

  const handleNext = async () => {
    try {
      if (isFetching || !hasNextPage || loading) return;
      setIsFetching(true);
      const { data } = await getPointsRequest({
        page: page,
        size: SIZE_PAGE,
      });
      setPage(data.pageable.pageNumber + 1);
      setHasNextPage(!data.last);
      setItems([...(items ?? []), ...data.content]);
      setIsFetching(false);
    } catch (err) {
      console.log('error', err);
      setHasNextPage(false);
      setIsFetching(false);
    }
  };

  const handleScrollEvent = throttle(async () => {
    if (divRef.current) {
      const { scrollHeight, scrollTop, offsetHeight } = await divRef.current.getScrollElement();
      const currentPosition = scrollTop + offsetHeight;
      const triggerPosition = scrollHeight - 40;
      if (currentPosition >= triggerPosition && (!isFetching || hasNextPage)) {
        handleNext();
      }
    }
  }, 200);

  useEffect(() => {
    const firstFetch = async () => {
      try {
        setLoading(true);
        const { data } = await getPointsRequest({
          page: 0,
          size: SIZE_PAGE,
        });
        setHasNextPage(!data.last);
        setItems(data.content);
        setPage(data.pageable.pageNumber + 1);
        if (divRef.current && !data.last) {
          const { scrollHeight, scrollTop, offsetHeight } = await divRef.current.getScrollElement();
          const currentPosition = scrollTop + offsetHeight;
          const triggerPosition = scrollHeight - 40;
          if (currentPosition >= triggerPosition) {
            handleNext();
          }
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    if (!visible) return;
    const subscribe = setTimeout(firstFetch, 500);
    return () => clearTimeout(subscribe);
  }, [visible]);

  return { items, loading, isFetching, divRef, handleScrollEvent };
};
