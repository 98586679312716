import * as FileSaver from 'file-saver';
import moment from 'moment';
import XLSX from 'sheetjs-style';

export const useExportExcel = (): {
  exportToExcel: (value: string, fileName: string) => Promise<void>;
  exportFileName: (title: string, uuid?: string, challengeTitle?: string) => string;
} => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToExcel = async (value: string, fileName: string) => {
    const rows = value.split('\n').filter((item) => item);
    const rowHeader = rows[0].split(',').filter((item) => item);
    const rowData = rows.splice(1).map((item) => item.split(','));
    const json = rowData.map((row) => {
      const arrItem: { [key: string]: string } = {};
      row.forEach((item, idx) => {
        const key = rowHeader[idx];
        if (key) {
          arrItem[key] = item;
        }
      });
      return arrItem;
    });
    const ws = XLSX.utils.json_to_sheet(json);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const exportFileName = (title: string, uuid?: string, challengeTitle?: string) => {
    const exportDate = moment().format('YYYY-MM-DD');
    if (challengeTitle) {
      const shortTitle = challengeTitle.split(' ')?.[0];
      return `${title}-${shortTitle}-${exportDate}`;
    }
    if (uuid) {
      return `${title}-${uuid}-${exportDate}`;
    }
    return `${title}-${exportDate}`;
  };

  return { exportToExcel, exportFileName };
};
