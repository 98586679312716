import React from 'react';
import { LogDataModal } from '../../../pages/ChallengeActivityPage/components/LogDataModal';
import { CalendarTimelineItem, EditCalendarTimelineItem } from '../../../pages/ChallengeActivityPage/types';
import { BooleanLogDataModal } from '../../../pages/ChallengeActivityPage/components/BooleanLogDataModal';

type CardsLogDataProps = {
  visible: boolean;
  isBoolean: boolean;
  date?: Date;
  metricTitle: string;
  input?: string;
  secondInput?: string;
  timelineScore: CalendarTimelineItem[];
  teamTimelineScore: CalendarTimelineItem[];
  startDate?: string;
  endDate?: string;
  isTeam?: boolean;
  isSumNone: boolean;
  maxCount?: number;
  disableLogData: boolean;
  metricType?: string;
  onChangeInput: (val: string) => void;
  onChangeSecondInput: (val: string) => void;
  onChangeDate: (date: Date) => void;
  handleAddValue: () => void;
  onClose: () => void;
  handlePlusButton: () => void;
  handleUpdateHabit: (date: string, isSuccess: boolean) => () => void;
  showDate?: Date;
};

export const CardsLogData: React.FC<CardsLogDataProps> = ({
  visible,
  isBoolean,
  date,
  input,
  secondInput,
  onChangeDate,
  onChangeInput,
  onChangeSecondInput,
  onClose,
  timelineScore,
  teamTimelineScore,
  isTeam,
  metricTitle,
  handleAddValue,
  startDate,
  endDate,
  handlePlusButton,
  isSumNone,
  maxCount,
  handleUpdateHabit,
  showDate,
  disableLogData,
  metricType,
}) => {
  return (
    <>
      <LogDataModal
        date={date}
        onChangeDate={onChangeDate}
        visible={visible && !isBoolean}
        input={input}
        secondInput={secondInput}
        onChangeInput={onChangeInput}
        onChangeSecondInput={onChangeSecondInput}
        onClose={onClose}
        timelineScore={timelineScore}
        teamTimelineScore={teamTimelineScore}
        isTeam={isTeam}
        metricTitle={metricTitle ?? ''}
        handleAddValue={handleAddValue}
        startDate={startDate}
        endDate={endDate}
        handlePlusButton={handlePlusButton}
        isSumNone={isSumNone}
        maxCount={maxCount}
        disableLogData={disableLogData}
        metricType={metricType}
      />
      <BooleanLogDataModal
        visible={visible && isBoolean}
        startDate={startDate}
        endDate={endDate}
        timelineScore={timelineScore}
        teamTimelineScore={teamTimelineScore}
        onClose={onClose}
        handleUpdateHabit={handleUpdateHabit}
        isTeam={isTeam}
        showDate={showDate}
        maxCount={maxCount}
      />
    </>
  );
};
