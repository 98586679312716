import * as React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { Box, Skeleton, Typography } from '@mui/material';

interface WellbeingPieChartProps {
  rating: number;
  series: number[];
  colors: string[];
  width?: string | number;
  height?: string | number;
  loading?: boolean;
}

const WellbeingPieChart: React.FC<WellbeingPieChartProps> = ({
  rating,
  series,
  colors,
  width,
  height,
  loading = false,
}: WellbeingPieChartProps) => {
  const chartDataOptions: ApexOptions = {
    colors: rating === 0 ? ['#8b8b8b'] : colors,
    stroke: {
      width: 0,
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    dataLabels: {
      enabled: false,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: '75%',
        },
      },
    },
    chart: {
      toolbar: {
        show: false,
      },
    },
  };

  return (
    <Box sx={{ position: 'relative', display: 'inline-block' }}>
      <ReactApexChart
        options={chartDataOptions}
        series={rating === 0 ? [100] : series}
        type={'donut'}
        width={width ?? 240}
        height={height ?? 240}
      />
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {loading ? (
          <Skeleton variant={'text'} width={72} height={80} />
        ) : (
          <Typography variant={'h2'} fontWeight={'bold'} color="common.black">
            {rating}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default WellbeingPieChart;
