import { Colors } from '../../theme/colors';
import { ReactComponent as IndividualIcon } from '../../assets/svg/feature/competitions/individual.svg';
import { ReactComponent as TeamIcon } from '../../assets/svg/feature/competitions/team.svg';
import { ReactComponent as SelfIcon } from '../../assets/svg/feature/competitions/self.svg';
import { COMPETITION } from './COMPETITION';
import { PreviewRoundItemProps } from '../Shared';
export const COMPETITION_LIST: PreviewRoundItemProps[] = [
  {
    value: COMPETITION.INDIVIDUAL,
    label: 'Individual',
    Icon: IndividualIcon,
    mainColor: Colors.blue[800],
    backgroundColor: Colors.blue[850],
    borderColor: Colors.blue[600],
  },
  {
    value: COMPETITION.TEAM,
    label: 'Team',
    Icon: TeamIcon,
    mainColor: Colors.blue[800],
    backgroundColor: Colors.blue[850],
    borderColor: Colors.blue[600],
  },
  {
    value: COMPETITION.SELF,
    label: 'Self',
    Icon: SelfIcon,
    mainColor: Colors.blue[800],
    backgroundColor: Colors.blue[850],
    borderColor: Colors.blue[600],
  },
];

export const ADMIN_COMPETITION_LIST: PreviewRoundItemProps[] = [
  {
    value: COMPETITION.INDIVIDUAL,
    label: 'Individual',
    Icon: IndividualIcon,
    mainColor: Colors.blue[800],
    backgroundColor: Colors.blue[850],
    borderColor: Colors.blue[600],
  },
  {
    value: COMPETITION.TEAM,
    label: 'Team',
    Icon: TeamIcon,
    mainColor: Colors.blue[800],
    backgroundColor: Colors.blue[850],
    borderColor: Colors.blue[600],
  },
];
