import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getContentTypesRequest, getToolboxRequest, getToolboxSearchRequest } from '../../lib/api/http/requests/blogs';
import { Blog } from '../../models/Blog';
import { DIMENSIONS } from '../../models/enum/DIMENSIONS';
import { setHeaderLoading } from './appSlice';
import { handleBackendError } from '../../utils/handleBackendError';

export const getToolboxBlogs = createAsyncThunk(
  'toolboxPage/getToolboxBlogs',
  async (
    params: {
      pagination: {
        page: number;
        size: number;
        contentType?: string[];
        dimension?: DIMENSIONS[];
        searchText?: string;
        sort?: string;
        tag?: string;
      };
      callback?: () => void;
    },
    thunkAPI,
  ) => {
    try {
      thunkAPI.dispatch(setHeaderLoading(true));
      const res = await getToolboxRequest(params.pagination);
      const blogs: Blog[] = res.data.content;
      const blogsCount = res.data.totalElements;
      return { blogs, blogsCount };
    } catch (error) {
      handleBackendError(error, 'Failed to retrieve blogs');
    } finally {
      thunkAPI.dispatch(setHeaderLoading(false));
      if (params.callback) params.callback();
    }
  },
);

export const getToolboxSearch = createAsyncThunk(
  'toolboxPage/getToolboxSearch',
  async (params: { callback?: () => void }, thunkAPI) => {
    try {
      const res = await getToolboxSearchRequest();
      if (Array.isArray(res.data)) thunkAPI.dispatch(setSearches(res.data));
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      if (params.callback) params.callback();
    }
  },
);

export const getContentTypes = createAsyncThunk(
  'toolboxPage/getContentTypes',
  async (params: { callback?: () => void }, thunkAPI) => {
    try {
      const res = await getContentTypesRequest();
      thunkAPI.dispatch(setContentTypes(res.data));
    } catch (error) {
      handleBackendError(error, 'Failed to fetch the content types');
    } finally {
      if (params.callback) params.callback();
    }
  },
);

export interface toolboxPageState {
  data: {
    blogs: Blog[];
    blogsCount: number;
    searches: Array<{
      location: string;
      title: string;
    }>;
    contentTypes: { [key: string]: string } | null;
  };
  meta: {
    blogsLoading: boolean;
  };
}

const initialState: toolboxPageState = {
  data: {
    blogs: [],
    searches: [],
    blogsCount: 0,
    contentTypes: null,
  },
  meta: {
    blogsLoading: false,
  },
};

export const toolboxPageSlice = createSlice({
  name: 'toolboxPage',
  initialState,
  reducers: {
    setSearches(state, action) {
      state.data.searches = action.payload;
    },
    setContentTypes(state, action) {
      state.data.contentTypes = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getToolboxBlogs.pending, (state) => {
      state.meta.blogsLoading = true;
    });
    builder.addCase(getToolboxBlogs.fulfilled, (state, action) => {
      state.meta.blogsLoading = false;
      state.data.blogs = action.payload?.blogs || [];
      state.data.blogsCount = action.payload?.blogsCount || 0;
    });
    builder.addCase(getToolboxBlogs.rejected, (state) => {
      state.meta.blogsLoading = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setSearches, setContentTypes } = toolboxPageSlice.actions;

export default toolboxPageSlice.reducer;
