import { Box, BoxProps } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';

type ExtraProps = {
  column: number;
};

const CustomSnapBox = styled(Box)<ExtraProps>(({ theme, column }) => ({
  overflow: 'auto',
  display: 'grid',
  padding: '20px',
  gap: 30,
  gridTemplateColumns: `repeat(${column}, minmax(290px, 290px))`,
  scrollSnapType: 'x mandatory',
  '&::-webkit-scrollbar': {
    height: 12,
  },
  '&::-webkit-scrollbar-thumb': {
    bgcolor: 'background.paper',
    border: `1px solid ${theme.palette.secondary.dark}`,
    borderRadius: 8,
  },
  '&>*': {
    scrollSnapAlign: 'center',
  },
}));

export default CustomSnapBox;
