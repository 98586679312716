import { Box, Typography, alpha } from '@mui/material';
import React, { useMemo } from 'react';
import { Colors } from '../../../theme/colors';
import { IonRange } from '@ionic/react';
import './slider.css';

type variant = 'default' | 'small';

type CustomSliderProps = {
  variant?: variant;
  label?: string;
  value: number;
  min: number;
  max: number;
  onChange: (val: number) => void;
};

export const CustomSlider: React.FC<CustomSliderProps> = ({
  variant = 'default',
  label,
  value,
  min,
  max,
  onChange,
}) => {
  const range = useMemo(() => max - min + 1, [min, max]);
  const knobWidth = variant === 'small' ? 32 : 44;

  return (
    <Box sx={{ width: '100%' }}>
      {label && (
        <Typography
          sx={{
            color: Colors.gray[700],
            fontSize: '14px',
            fontWeight: 'bold',
            position: 'relative',
            top: 'auto',
            left: 'auto',
            transform: 'none',
            marginLeft: '15px',
            marginTop: '-22px',
          }}
        >
          {label}
        </Typography>
      )}
      <Box
        component="div"
        className="custom-slider"
        sx={{
          position: 'relative',
          width: '100%',
          height: variant === 'small' ? '32px' : '44px',
          borderRadius: '30px',
          background: alpha('#E2E5E6', 0.3),
          border: `2px solid #E2E5E6`,
          display: 'flex',
          justifyContent: 'space-between',
          paddingInline: knobWidth / 2 + 'px',
          alignItems: 'center',
        }}
      >
        {new Array(range).fill(0).map((item, idx) =>
          idx === 0 ? (
            <Box key={`mark-${idx}`} sx={{ position: 'relative' }} />
          ) : idx === range - 1 ? (
            <Box key={`mark-${idx}`} sx={{ position: 'relative', height: '8px', width: '1px' }}>
              <Typography
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-70%, -40%)',
                  fontSize: '10px',
                  color: Colors.gray[700],
                  fontWeight: 800,
                }}
              >
                {max ?? 0}
              </Typography>
            </Box>
          ) : (
            <Box key={`mark-${idx}`} sx={{ height: '8px', width: '1px', background: Colors.gray[100] }} />
          ),
        )}
        {value > min && (
          <Box
            sx={{
              position: 'absolute',
              left: '-2px',
              top: '-2px',
              bottom: '-2px',
              background: `linear-gradient(181deg, #A2E5EC 0%, #45CBD9 100%)`,
              // width: `calc(${(value / range) * 100}% - ${knobWidth / 4}px)`,
              width: `calc(((100% - ${knobWidth}px) / ${max - min}) * ${value - min} + ${knobWidth / 2}px)`,
              border: `2px solid #D0F2F5`,
              borderTopLeftRadius: '30px',
              borderBottomLeftRadius: '30px',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                paddingLeft: knobWidth / 2 + 'px',
                alignItems: 'center',
              }}
            >
              {new Array(value - min + 1).fill(0).map((_, idx) =>
                idx === 0 ? (
                  <Box key={`mark-${idx}`} sx={{ position: 'relative', height: '8px', width: '1px' }}>
                    <Typography
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        fontWeight: 800,
                        transform: 'translate(-30%, -40%)',
                        fontSize: '10px',
                        color: Colors.white,
                      }}
                    >
                      {min ?? 0}
                    </Typography>
                  </Box>
                ) : (
                  <Box key={`mark-${idx}`} sx={{ height: '8px', width: '1px', background: alpha(Colors.white, 0.4) }} />
                ),
              )}
            </Box>
          </Box>
        )}
        <Box
          {...(variant === 'small' && { className: 'small-slide' })}
          sx={{ position: 'absolute', left: 0, right: 0, top: '-4px' }}
        >
          <IonRange
            style={{ '& .range-slider': { opacity: 0 } }}
            aria-label="Range"
            ticks
            snaps
            min={min}
            max={max}
            defaultValue={value}
            value={value}
            pin
            onIonChange={(e) => onChange(e.detail.value as number)}
          />
        </Box>
      </Box>
    </Box>
  );
};
