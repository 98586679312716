import React, { FC, useEffect, useState } from 'react';
import { Box, FormControl, Typography, useMediaQuery } from '@mui/material';
import { Colors } from '../../../theme/colors';
import { CustomButton } from '../CustomButton';
import { useIonRouter } from '@ionic/react';
import Grid from '@mui/material/Grid';
import theme from '../../../theme';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { IMentalAssessmentForm } from '../../../models/MentalAssessment';
import UserService from '../../../services/UserService';
import CustomLabelValueDropdown from '../../features/CustomLabelValueDropdown';
import { ReactComponent as ArrowIcon } from '../../../assets/svg/feature/arrow.svg';
import { ReactComponent as LoadingAnimationIcon } from '../../../assets/svg/feature/loadingAnimation.svg';
import { CustomDialog } from '../CustomDialog';
import { submitInitialise } from '../../../lib/api/http/requests/mentalAssessment';
import {
  setShowMentalAssessmentModal,
  setInitialData,
  setPersonalData,
  setIsAllowInitPlugin,
} from '../../../redux/slices/mentalAssessmentSlice';

interface ModalProps {
  show: boolean;
  onClose: () => void;
  onSubmit: () => void;
}
const InitialSettingsForm: IMentalAssessmentForm = {
  user: {
    userLabel: '',
    dateOfBirth: '',
    birthSex: '',
  },
  language: '',
  activityType: '',
};

const transformAccountGenders = [
  { newValue: 'MALE', value: 'MALE' },
  { newValue: 'FEMALE', value: 'FEMALE' },
  { newValue: 'UNKNOWN', value: 'OTHER' },
];

const birthSex = [
  { label: 'Female', value: 'FEMALE' },
  { label: 'Male', value: 'MALE' },
  { label: 'Intersex', value: 'INTERSEX' },
  { label: 'Unknown', value: 'UNKNOWN' },
];

const assessmentType = [
  { label: 'Read aloud', value: 'read-aloud' },
  { label: 'Image description', value: 'image-description' },
  { label: 'Question', value: 'question' },
];

const languages = [
  { label: 'English (American)', value: 'en-US' },
  { label: 'English (Australian)', value: 'en-AU' },
  { label: 'English (British)', value: 'en-GB' },
  { label: 'English (India)', value: 'en-IN' },
  { label: 'English (Ireland)', value: 'en-IE' },
  { label: 'English (South Africa)', value: 'en-ZA' },
  { label: 'English (Other)', value: 'en' },
  { label: 'Spanish (European)', value: 'es-ES' },
  { label: 'Spanish (United States)', value: 'es-US' },
  { label: 'Other', value: 'en' },
];

export const MentalAssessmentModal: FC<ModalProps> = ({ show = false, onClose, onSubmit }) => {
  const router = useIonRouter();
  const dispatch = useDispatch();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const [personalInfo, setPersonalInfo] = useState<IMentalAssessmentForm>(InitialSettingsForm);
  const [activityPluginData, setActivityPluginData] = useState<{
    id: string;
    activityLink: string;
  } | null>(null);
  const [loading, setLoading] = useState(false);
  const [allowInitPlugin, setAllowInitPlugin] = useState(false);

  const { accountInfo } = useSelector((state: RootState) => state.profile.data);

  const years = Array.from({ length: new Date().getFullYear() - 1900 - 17 }, (_, index) => {
    const year = 1900 + index;
    return { label: `${year}`, value: year.toString() };
  }).reverse();

  useEffect(() => {
    if (accountInfo) {
      const data = {
        user: {
          userLabel: UserService.getMineId(),
          dateOfBirth: accountInfo.personal_details.date_of_birth
            ? String(new Date(accountInfo.personal_details.date_of_birth).getFullYear())
            : '',
          birthSex:
            transformAccountGenders.find((el) => el.value === accountInfo.personal_details.gender)?.newValue || '',
        },
        language: '',
        activityType: '',
      };
      setPersonalInfo({ ...data });
    }
  }, [accountInfo]);

  useEffect(() => {
    if (
      personalInfo.activityType.length &&
      personalInfo.language.length &&
      personalInfo.user.dateOfBirth.length &&
      personalInfo.user.birthSex.length
    ) {
      setAllowInitPlugin(true);
    } else {
      setAllowInitPlugin(false);
    }
  }, [personalInfo]);

  const handleInitialise = async () => {
    setLoading(true);
    const params = {
      ...personalInfo,
      user: { ...personalInfo.user, dateOfBirth: `${personalInfo.user.dateOfBirth}-01-01` },
    };
    dispatch(setPersonalData(params));
    const response = await submitInitialise(params);
    setActivityPluginData(response.data);
    setLoading(false);

    if (!response.data) return;
    dispatch(setIsAllowInitPlugin(true));
    dispatch(setInitialData(response.data));
    dispatch(setShowMentalAssessmentModal(false));

    router.push(`/page/mental-assessment`);
    console.log(response.data);
  };

  return (
    <CustomDialog onClose={onClose} open={show}>
      <FormControl
        sx={{
          maxWidth: '384px',
          margin: '0 auto',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        {loading && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
              zIndex: 2,
              // background: 'rgba(65, 74, 79, 0.3)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <LoadingAnimationIcon style={{ width: 80, height: 80 }} />
          </Box>
        )}

        <Box
          sx={{
            maxWidth: '100%',
            backgroundColor: Colors.white,
            margin: { xs: '0 16px', md: '0 40px' },
            height: '100%',
            minHeight: '200px',
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            sx={{ mt: '6px', maxWidth: '296px', mx: 'auto', mb: '16px', justifyContent: 'center' }}
          >
            <Typography
              variant="h5"
              sx={{ textAlign: 'center', color: Colors.gray[700], fontWeight: 'bold', fontSize: 18 }}
            >
              WellVox
            </Typography>
          </Box>

          {activityPluginData ? null : (
            <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" sx={{ mt: '10px' }}>
              <Typography
                variant="h5"
                sx={{ maxWidth: '335px', textAlign: 'center', color: Colors.gray[500], fontWeight: 400, fontSize: 16 }}
              >
                To give you the best results we just need a few details before we start.
              </Typography>

              <Grid sx={{ width: '335px', position: 'relative', marginTop: '14px', minHeight: 120 }}>
                <Box
                  sx={{
                    width: '100%',
                    position: 'relative',
                  }}
                >
                  <Box sx={{ mt: '48px' }}>
                    <CustomLabelValueDropdown
                      placeholder="Choose sex"
                      disabled={false}
                      width="100%"
                      label={'Birth Sex'}
                      list={birthSex}
                      selected={personalInfo.user.birthSex}
                      setSelected={(value) =>
                        setPersonalInfo({ ...personalInfo, user: { ...personalInfo.user, birthSex: value } })
                      }
                    />
                  </Box>

                  <Box sx={{ mt: '48px' }}>
                    <CustomLabelValueDropdown
                      placeholder="Choose year"
                      disabled={false}
                      width="100%"
                      label={'Birth Year'}
                      list={years}
                      selected={personalInfo.user.dateOfBirth}
                      setSelected={(value) =>
                        setPersonalInfo({ ...personalInfo, user: { ...personalInfo.user, dateOfBirth: value } })
                      }
                    />
                  </Box>

                  <Box sx={{ mt: '48px' }}>
                    <CustomLabelValueDropdown
                      placeholder="Choose language"
                      disabled={false}
                      width="100%"
                      label={'Language'}
                      list={languages}
                      selected={personalInfo.language}
                      setSelected={(value) => setPersonalInfo({ ...personalInfo, language: value })}
                    />
                  </Box>

                  <Box sx={{ mt: '48px' }}>
                    <CustomLabelValueDropdown
                      placeholder="Choose assessment type"
                      disabled={false}
                      width="100%"
                      label={'Assessment type'}
                      list={assessmentType}
                      selected={personalInfo.activityType}
                      setSelected={(value) => setPersonalInfo({ ...personalInfo, activityType: value })}
                    />
                  </Box>
                </Box>
              </Grid>
            </Box>
          )}
        </Box>

        {!activityPluginData && (
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{ margin: mdDown ? '24px auto 34px' : '40px auto 24px', maxWidth: '336px', width: '100%' }}
          >
            <CustomButton type={'bigLightBlue'} text={'Cancel'} onClick={onClose} sx={{ maxWidth: 160 }} />

            <CustomButton
              disabled={!allowInitPlugin}
              type={'bigBlue'}
              text={'Next'}
              inputProps={{
                variant: 'contained',
                type: 'submit',
                endIcon: <ArrowIcon style={{ transform: 'rotate(180deg)' }} fill="#FFFFFF" width={16} />,
              }}
              sx={{ maxWidth: 160 }}
              onClick={handleInitialise}
            />
          </Box>
        )}
      </FormControl>
    </CustomDialog>
  );
};
