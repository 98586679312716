import { throttle } from 'lodash';
import { useState, useEffect, useRef } from 'react';
import useIsPageActive from '../../hooks/useIsPageActive';
import { ANALYTICS_PAGE } from '../../models/enum/ANALYTICS_PAGE';
import { getChallengeTeams } from '../../lib/api/http/requests/challenge';
import { Team } from '../../models/Team';
import { COMPETITION } from '../../models/enum/COMPETITION';
import { ChallengeAdminProgress } from '../../models/Challenge';

const SIZE_PAGE = 12;
type Props = {
  id: string;
};
export const useTeamsHandle = (params: Props, challenge: ChallengeAdminProgress | undefined) => {
  const [teams, setTeams] = useState<Team[]>([]);
  const [teamsLoading, setTeamsLoading] = useState(true);
  const [teamsPage, setTeamsPage] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);

  const visible = useIsPageActive(ANALYTICS_PAGE.CHALLENGE_ADMIN_DETAILS);
  const divTeamsRef = useRef<HTMLUListElement>(null);

  const handleNext = async () => {
    try {
      if (isFetching || !hasNextPage || teamsLoading) return;
      setIsFetching(true);
      const { data } = await getChallengeTeams({ challengeUUID: params.id, page: teamsPage, size: SIZE_PAGE });

      setTeamsPage(data.pageable.pageNumber + 1);
      setHasNextPage(!data.last);
      setTeams([...teams, ...data.content]);
      setIsFetching(false);
    } catch (err) {
      console.log('error', err);
      setHasNextPage(false);
      setIsFetching(false);
    }
  };

  const handleTeamScrollEvent = throttle(async () => {
    if (divTeamsRef.current) {
      const { scrollHeight, scrollTop, offsetHeight } = await divTeamsRef.current;
      const currentPosition = scrollTop + offsetHeight;
      const triggerPosition = scrollHeight - 40;
      if (currentPosition >= triggerPosition && (!isFetching || hasNextPage)) {
        handleNext();
      }
    }
  }, 200);

  const firstFetch = async () => {
    try {
      setTeamsLoading(true);
      const { data } = await getChallengeTeams({ challengeUUID: params.id, page: 0, size: SIZE_PAGE });

      setHasNextPage(!data.last);
      setTeams(data.content);
      setTeamsPage(data.pageable.pageNumber + 1);
      if (divTeamsRef.current && !data.last) {
        const { scrollHeight, scrollTop, offsetHeight } = await divTeamsRef.current;
        const currentPosition = scrollTop + offsetHeight;
        const triggerPosition = scrollHeight - 40;
        if (currentPosition >= triggerPosition) {
          handleNext();
        }
      }
      setTeamsLoading(false);
    } catch (err) {
      console.log(err);
      setTeamsLoading(false);
    }
  };

  useEffect(() => {
    if (challenge?.challengeCharacteristic.competition === COMPETITION.TEAM && params.id) {
      const subscribe = setTimeout(firstFetch, 500);
      return () => clearTimeout(subscribe);
    }
  }, [challenge?.challengeCharacteristic.competition, params.id, visible]);

  return {
    teams,
    teamsLoading,
    isFetching,
    divTeamsRef,
    handleTeamScrollEvent,
  };
};
