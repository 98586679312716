import { Capacitor } from '@capacitor/core';
import { SxProps, Theme } from '@mui/material';

export const styles: { [key: string]: SxProps<Theme> } = {
  cardImage: {
    flexShrink: 0,
    width: '100%',
    height: 324,
    borderRadius: '20px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  mdCardImage: {
    flexShrink: 0,
    width: '100%',
    height: 160,
    borderRadius: '20px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  labelStatusPosition: { position: 'absolute', top: '16px', right: '-7px' },
} as const;
