import { useEffect, useState } from 'react';
import { Range } from 'react-date-range';
import moment from 'moment/moment';
import { publishTemplateChallenge } from '../../lib/api/http/requests/challengeAdmin';
import { Challenge } from '../../models/Challenge';
import { handleBackendError } from '../../utils/handleBackendError';
import { Capacitor } from '@capacitor/core';

export type PublishFormData = {
  startDate?: string;
  endDate?: string;
  teamSize: null | string;
  winners: null | string;
  slackUrl: string;
};
export const usePublishHandle = (challenge: Challenge | null, onPublish: () => void) => {
  const [publishForm, setPublishForm] = useState<PublishFormData>({
    startDate: undefined,
    endDate: undefined,
    teamSize: '',
    winners: '',
    slackUrl: '',
  });
  const [range, setRange] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPublish, setIsPublish] = useState(false);

  useEffect(
    () =>
      setRange([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
        },
      ]),
    [challenge],
  );

  const getDisabledPeriod = () => {
    if (range.length === 1) {
      const { startDate, endDate } = range[0];
      return moment(startDate).isSame(endDate);
    } else return false;
  };

  const resetFormData = () =>
    setPublishForm({ startDate: undefined, endDate: undefined, teamSize: '', winners: '', slackUrl: '' });
  const handleChangeRange = (range: Range[]) => {
    setRange(range);

    const startDate = moment(range[0].startDate).format('YYYY-MM-DD').toString();
    const endDate = moment(range[0].endDate).format('YYYY-MM-DD').toString();

    setPublishForm({ ...publishForm, startDate, endDate });
  };

  const handleChangeTeamSize = (teamSize: string) => {
    setPublishForm({ ...publishForm, teamSize });
  };
  const handleChangeWinners = (winners: string) => {
    setPublishForm({ ...publishForm, winners });
  };
  const handleChangeSlackUrl = (slackUrl: string) => {
    setPublishForm({ ...publishForm, slackUrl });
  };

  const publish = async () => {
    if (!challenge || !challenge.metadata.templateChallengeUUID || isPublish) return;
    setIsLoading(true);
    try {
      setIsPublish(true);
      await publishTemplateChallenge(publishForm, challenge.metadata.templateChallengeUUID);
      resetFormData();
      setIsLoading(false);
      onPublish();
    } catch (error) {
      console.log('publish modal');
      const err = error as { response?: { data?: { message?: string } } };
      const message = Capacitor.isNativePlatform()
        ? null
        : err && err?.response && err?.response?.data && err?.response?.data?.message
        ? err?.response?.data?.message
        : null;

      if (!error || !message) {
        handleBackendError(error, 'Failed to publish challenge');
        return;
      }
      handleBackendError(error, message);
      setIsLoading(false);
    } finally {
      setIsPublish(false);
    }
  };
  return {
    range,
    publishForm,
    isLoading,
    suggestedDuration: challenge ? challenge.challengeDuration.suggestedDuration : '',
    publish,
    getDisabledPeriod,
    handleChangeRange,
    handleChangeTeamSize,
    handleChangeWinners,
    handleChangeSlackUrl,
  };
};
