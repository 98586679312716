import { useEffect, useState } from 'react';

type Props = {
  pageSize: number;
  /**
   * @param currentPage  minimum is 0
   */
  currentPage: number;
  totalItems: number;
  setPage: (p: number) => void;
};
export const usePaginateHandle = ({ pageSize, currentPage, totalItems, setPage }: Props) => {
  const [isPaginateLoading, setIsPaginateLoading] = useState(true);

  useEffect(() => {
    if (currentPage > 0) return;
    setIsPaginateLoading(true);
  }, [currentPage]);

  const handleGoNext = () => {
    if ((currentPage + 1) * pageSize < totalItems) setPage(currentPage + 1);
  };

  const goNextPaginationDisabled = () => {
    return (currentPage + 1) * pageSize >= totalItems;
  };

  const validateToNext = () => {
    if (!goNextPaginationDisabled) {
      return;
    }
    setIsPaginateLoading(false);
    handleGoNext();
  };

  return {
    isPaginateLoading,
    validateToNext,
    handleGoNext,
    goNextPaginationDisabled,
  };
};
