import { useEffect, useState } from 'react';
import { getTeamEmails } from '../../../lib/api/http/requests/challengeTeam';

export const useEmailHandle = (teamUUID: string, isOwner: boolean) => {
  const [emails, setEmails] = useState<string[]>([]);

  const getEmails = async () => {
    try {
      const { data } = (await getTeamEmails({ teamUUID })) as { data: string[] };
      setEmails(data);
    } catch (e) {}
  };

  useEffect(() => {
    if (!isOwner) return;
    getEmails();
  }, [isOwner]);

  const sendEmail = () => {
    if (!emails.length) return;
    const mailtoLink = 'mailto:' + emails.join(',');
    window.location.href = mailtoLink;
  };

  return {
    emails,
    sendEmail,
  };
};
