import Grid from '@mui/material/Grid';
import { Colors } from '../../theme/colors';
import { Box, Theme, Typography } from '@mui/material';
import React from 'react';
import { SxProps } from '@mui/system';
import { TEMPLATE_TYPE } from '../../models/enum/TEMPLATE_TYPE';
import { GROUP_CATEGORY } from '../../models/enum/GROUP_CATEGORY';
import { Challenge } from '../../models/Challenge';
import { ReactComponent as StarIcon } from '../../assets/svg/feature/star.svg';
enum BgColor {
  GREEN = 'green',
  MAGENTA = 'magenta',
  YELLOW = 'yellow',
}
type Props = {
  challenge: Challenge;
  sxContainer: SxProps<Theme>;
  type?: 'sm' | 'lg';
};
export const CardLabel: React.FC<Props> = ({ challenge, sxContainer, type = 'sm' }) => {
  const showLabel =
    challenge.metadata.isFeatured ||
    challenge.challengeCharacteristic.templateType === TEMPLATE_TYPE.WIN_WEEKLY ||
    challenge.challengeCharacteristic.groupCategory === GROUP_CATEGORY.CORPORATE;

  const label =
    challenge.challengeCharacteristic.templateType === TEMPLATE_TYPE.WIN_WEEKLY
      ? 'Weekly'
      : challenge.challengeCharacteristic.groupCategory === GROUP_CATEGORY.CORPORATE
      ? 'Corporate'
      : undefined;

  const color =
    challenge.challengeCharacteristic.templateType === TEMPLATE_TYPE.WIN_WEEKLY
      ? 'magenta'
      : challenge.challengeCharacteristic.groupCategory === GROUP_CATEGORY.CORPORATE
      ? 'green'
      : 'yellow';

  const showIcon = challenge.metadata.isFeatured;

  const colorScheme = (): { primary: string; secondary: string; dot: string } => {
    switch (color) {
      case BgColor.GREEN:
        return {
          primary: Colors.green[600],
          secondary: Colors.green[800],
          dot: Colors.green[500],
        };
        break;

      case BgColor.MAGENTA:
        return {
          primary: Colors.magenta[300],
          secondary: Colors.magenta[500],
          dot: Colors.magenta[200],
        };
        break;

      case BgColor.YELLOW:
        return {
          primary: Colors.yellow[500],
          secondary: Colors.yellow[700],
          dot: Colors.yellow[400],
        };
        break;

      default:
        return {
          primary: Colors.green[600],
          secondary: Colors.green[800],
          dot: Colors.green[500],
        };
    }
  };

  return (
    showLabel && (
      <Grid item sx={sxContainer}>
        <Grid sx={{ position: 'relative' }}>
          <Grid
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: colorScheme().primary,
              padding: type === 'lg' ? '7px 11px 7px 16px' : '7px 7px 7px 8px',
              minWidth: '40px',
              borderTopLeftRadius: '30px',
              borderBottomLeftRadius: '30px',
              borderTopRightRadius: '10px',
              maxHeight: type === 'lg' ? '32px' : '29px',
            }}
          >
            {showIcon && <StarIcon width={16} height={16} style={{ fill: Colors.white }} />}
            {label && showIcon && (
              <Box
                sx={{
                  width: '2px',
                  height: '2px',
                  backgroundColor: colorScheme().dot,
                  borderRadius: '100%',
                  marginRight: '5px',
                  marginLeft: '5px',
                }}
              />
            )}
            {label && (
              <Typography sx={{ fontSize: type === 'lg' ? 12 : 10, fontWeight: 700, color: Colors.white }}>
                {label}
              </Typography>
            )}
          </Grid>
          <Grid
            sx={{
              backgroundColor: colorScheme().primary,
              width: '7px',
              height: '7px',
              position: 'absolute',
              bottom: '-4px',
              right: '0px',
            }}
          />
          <Grid
            sx={{
              backgroundColor: colorScheme().secondary,
              width: '7px',
              height: '7px',
              borderTopRightRadius: '4px',
              borderBottomRightRadius: '4px',
              position: 'absolute',
              bottom: '-7px',
              right: '0px',
            }}
          />
        </Grid>
      </Grid>
    )
  );
};
