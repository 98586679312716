import { RewardOrder } from '../../../../models/Rewards';
import { getRequest, postRequest } from '../../../../services/HttpService';

export const getRewardsRequest = () => {
  return getRequest('/user-engagement-service/user/ranking');
};

export const getLeaderboardRewardsRequest = (params: { id: string }) => {
  return getRequest('/user-engagement-service/company/ranking', { moduleUUID: params.id });
};

export const getLeaderboardExperienceRequest = (params: { id: string; page?: number }) => {
  //CONSISTENCY
  return getRequest('/user-engagement-service/company/experience/ranking', {
    page: `${params.page || 0}`,
    size: '10',
    ...(params.id && { moduleUUID: params.id }),
  });
};

export const getLeaderboardAchievementRequest = (params: { id: string; page?: number }) => {
  //PERFORMANCE
  return getRequest('/user-engagement-service/company/achievement/ranking', {
    page: `${params.page || 0}`,
    size: '10',
    ...(params.id && { moduleUUID: params.id }),
  });
};

// Rewards mechanism
export const getCompanyConfig = () => {
  return getRequest('/user-engagement-service/rewards/company/config');
};

export const getLeaderboardPoints = (params?: { page?: string; size?: string; sort?: string }) => {
  return getRequest('/user-engagement-service/company/wallet/ranking', params);
};

export const getRewardsCompany = (params?: { page?: string; size?: string; name?: string; sort?: string }) => {
  return getRequest('/user-engagement-service/rewards/company', params);
};

export const getRewardsCatalog = () => {
  return getRequest('/user-engagement-service/rewards/catalog');
};

export const postRewardOrder = (params: { orders: RewardOrder[]; orderComment?: string }) => {
  return postRequest('/user-engagement-service/rewards/company/orders', params);
};
