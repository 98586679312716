import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { asyncGetWhoFive } from '../../../redux/slices/journeyPageSlice';

export const useFetchWhoFive = () => {
  const [showNotify, setShowNotify] = useState(false);
  const [isWhoFiveModal, setIsWhoFiveModal] = useState(false);

  const whoFiveMetrics = useSelector((state: RootState) => state.journeyPage.data.whoFiveMetrics);
  const dispatch = useDispatch();

  const openModalWho = () => {
    setIsWhoFiveModal(true);
  };

  const handleCloseNotify = (e: any) => {
    e.stopPropagation();
    setShowNotify(false);
  };

  const closeWhoFiveModal = () => setIsWhoFiveModal(false);

  useEffect(() => {
    dispatch(asyncGetWhoFive({ date: moment().format('YYYY-MM-DD') }));
  }, []);

  useEffect(() => {
    if (whoFiveMetrics?.length) {
      setShowNotify(true);
      return;
    }
    setShowNotify(false);
  }, [whoFiveMetrics]);

  return { showNotify, isWhoFiveModal, whoFiveMetrics, openModalWho, handleCloseNotify, closeWhoFiveModal };
};
