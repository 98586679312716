import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { Colors } from '../../../theme/colors';
import { unitsLabel } from '../helpers';
import { METRICS_UNIT } from '../../../models/enum/METRICS_UNIT';
import { convertThousands } from '../../../utils/numberWithSpace';
import { ReactComponent as AutoIcon } from '../../../assets/svg/feature/auto-sync.svg';
import { METRICS_TYPE } from '../../../models/enum/METRICS_TYPE';

const displayVal = (val: number, unit: string, type: string) => {
  if (type !== METRICS_TYPE.SLEEP_DURATION)
    return `${convertThousands(val)}${
      unit in unitsLabel ? ` ${unitsLabel[unit]}` : unit === METRICS_UNIT.STEPS ? ' steps' : ''
    }`;
  const minutes = val % 60;
  const hours = (val - minutes) / 60;
  return `${hours} hrs ${minutes} min`;
};

type MetricItemProps = {
  title: string;
  value: string;
  tooltip: string;
  metricUnit: string;
  isSelected?: boolean;
  isAuto?: boolean;
  isMine?: boolean;
  type: string;
  onClick: () => void;
};

export const MetricItem: React.FC<MetricItemProps> = ({
  title,
  value,
  tooltip,
  metricUnit,
  isSelected,
  isAuto,
  isMine,
  type,
  onClick,
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: '8px', cursor: 'pointer' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          boxSizing: 'border-box',
          flex: 1,
          width: { sm: 'auto', md: '378px' },
          padding: '7px 16px',
          borderRadius: '10px',

          background: isSelected
            ? isMine
              ? Colors.green[500]
              : Colors.purple[500]
            : isAuto
            ? Colors.white
            : '#F7F9F9',
          ...(isAuto && { border: `1px solid ${Colors.gray[50]}` }),
        }}
        onClick={onClick}
      >
        <Box display="flex">
          {isAuto && <AutoIcon style={{ marginRight: '8px' }} {...(isSelected && { fill: Colors.white })} />}
          <Typography sx={{ fontSize: '14px', color: isSelected ? Colors.white : Colors.gray[700] }}>
            {title}
          </Typography>
        </Box>
        <Typography sx={{ fontSize: '14px', color: isSelected ? Colors.white : Colors.gray[700] }}>
          {displayVal(Number(value), metricUnit, type)}
        </Typography>
      </Box>
      {!isMine && (
        <Tooltip title={<Typography variant={'body2'}>{tooltip}</Typography>} enterTouchDelay={0}>
          <InfoOutlined htmlColor={tooltip ? Colors.gray[400] : Colors.white} fontSize="small" sx={{ ml: '6px' }} />
        </Tooltip>
      )}
    </Box>
  );
};
