import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Container, Grid } from '@mui/material';
import { Colors } from '../../theme/colors';
import { LeaderBoardLayout, MainLayout } from './layout';
import { Header } from './layout/Header';
import { useInitPage } from './hooks/useInitPage';
import { ListLayout } from './layout/ListLayout';

type Props = RouteComponentProps<{
  id: string;
}>;

export const ChallengeActivityPage: React.FC<Props> = ({ match }) => {
  useInitPage(match.params.id);
  return (
    <IonPage>
      <Header />
      <IonContent style={{ '--padding-bottom': '36px', '--background': Colors.gray[50] }}>
        <Container maxWidth="lg">
          <Grid container spacing="24px">
            <MainLayout />
            <ListLayout />
          </Grid>
        </Container>
      </IonContent>
    </IonPage>
  );
};
