import React, { FC } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Colors } from '../../../theme/colors';
import { ReactComponent as LoadingAnimationIcon } from '../../../assets/svg/feature/loadingAnimation.svg';
import { ReactComponent as DangerIcon } from '../../../assets/svg/feature/danger.svg';
import { useIonRouter } from '@ionic/react';

interface Props {
  showHistoryLink: boolean;
  notification: string;
}
export const CalculatingSection: FC<Props> = ({ showHistoryLink, notification }) => {
  const router = useIonRouter();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', padding: '40px 24px' }}>
      <Grid sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', textAlign: 'center' }}>
        <Typography sx={{ fontSize: 18, lineHeight: 1, color: Colors.gray[700], fontWeight: 800 }}>
          Calculating your scores
        </Typography>
        <Typography sx={{ fontSize: 16, lineHeight: 1, color: Colors.gray[700], fontWeight: 400, mt: '8px' }}>
          Hang tight, this will take about 30 seconds
        </Typography>
      </Grid>

      <Box sx={{ my: '40px', textAlign: 'center' }}>
        <LoadingAnimationIcon style={{ width: 80, height: 80 }} />
      </Box>

      {notification.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: Colors.yellow[100],
            borderRadius: '30px',
            padding: '8px 16px',
          }}
        >
          <DangerIcon width={16} height={16} style={{ fill: Colors.yellow[600], flexShrink: 0 }} />
          <Typography
            sx={{
              fontSize: 12,
              lineHeight: 1.4,
              color: Colors.yellow[600],
              fontWeight: 400,
              ml: '8px',
            }}
          >
            {notification}
            {showHistoryLink && (
              <Typography
                onClick={() => router.push('/page/mental-assessment-results')}
                paragraph={false}
                sx={{
                  fontSize: 12,
                  lineHeight: 1.4,
                  color: Colors.yellow[600],
                  fontWeight: 800,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  ml: '3px',
                  display: 'inline',
                }}
              >
                history
              </Typography>
            )}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
