import { deleteRequest, getRequest, postTerraRequest, putRequest } from '../../../../services/HttpService';
import { ICompanyInfo, PersonalDetails, UserPreference } from '../../../../models/Profile';

export const getProfileDetails = () => getRequest('/organisation-management-service/user/profile_details');

export const putUserPersonalDetails = (
  personal_details: PersonalDetails,
  user_preference: UserPreference,
  country?: string,
) =>
  putRequest('/organisation-management-service/user/personal_details', {
    personal_details: personal_details,
    user_preference: user_preference,
    country,
  });

export const completeTutorialRequest = () =>
  putRequest('/organisation-management-service/user/first_login?first_login=false');

export const putUserPassword = (password: string) =>
  putRequest('/organisation-management-service/user/password', { password });

export const putUserUsername = (username: string) =>
  putRequest('/organisation-management-service/user/username', { username });

// export const putUserAvatar = (data: FormData) =>
//   putFormDataRequest('/organisation-management-service/user/avatar', data);

// export const putCompanyImage = (file: FormData) =>
//     putFormDataRequest('/organisation-management-service/company/avatar', file);

export const putUserAvatar = (base64: string) =>
  putRequest('/organisation-management-service/user/avatar', { file: base64 });

export const putCompanyImage = (base64: string) =>
  putRequest('/organisation-management-service/company/avatar', { file: base64 });

// export const putUserImageBase64 = (base64: string) => {
//   return putRequest('/organisation-management-service/user/image', { file: base64 });
// };

export const putUserConsistencyRewards = (consistency_rewards: boolean) =>
  putRequest(`/organisation-management-service/user/consistency_rewards?consistency_rewards=${consistency_rewards}`);

export const putUserUserPerformanceRewards = (performance_rewards: boolean) =>
  putRequest(`/organisation-management-service/user/performance_rewards?performance_rewards=${performance_rewards}`);

export const putEmailUnsubscribe = (unsubscribed_emails: boolean) =>
  putRequest(`/organisation-management-service/user/subscribed_emails?subscribed_emails=${unsubscribed_emails}`);

export const putQuestionnaireUnsubscribe = (unsubscribed_questionnaires: boolean) =>
  putRequest(
    `/organisation-management-service/user/subscribed_questionnaires?subscribed_questionnaires=${unsubscribed_questionnaires}`,
  );

export const deleteUserData = () => deleteRequest('/organisation-management-service/usermanagement/user/cleardata');

export const deleteAccount = () => deleteRequest('/organisation-management-service/usermanagement/user/delete');

export const getCompanyDetails = () => getRequest('/organisation-management-service/company/profile_details');

export const putTransferAdmin = (email: string) =>
  putRequest(`/organisation-management-service/company/transfer_admin?email=${email}`);

export const putCompanyDetails = (company_details: ICompanyInfo) =>
  putRequest('/organisation-management-service/company/profile_details', { ...company_details });

export const putCompanyConsistencyRewards = (consistency_rewards: boolean) =>
  putRequest(`/organisation-management-service/company/consistency_rewards?consistency_rewards=${consistency_rewards}`);

export const putCompanyPerformanceRewards = (performance_rewards: boolean) =>
  putRequest(`/organisation-management-service/company/performance_rewards?performance_rewards=${performance_rewards}`);

export const chargebeeIntegrationRequest = (redirectUrl: string) => {
  return getRequest('/organisation-management-service/integrations/chargebee/portal', { redirectUrl }, 'String');
};

export const getTerraAuthToken = () => postTerraRequest('/auth/generateAuthToken');

export const getUserPoints = () => getRequest('/user-engagement-service//user/wallet/points');
