import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { ScoreTimeItemType } from '../../../models/Shared';

export const useHandleDailyProgress = () => {
  const isLoading = useSelector((state: RootState) => state.challengeProgress.meta.isLoading);
  const progress = useSelector((state: RootState) => state.challengeProgress.data.progress);
  const detail = useSelector((state: RootState) => state.challengeProgress.data.detail);
  const duration = useSelector((state: RootState) => state.challengeProgress.data.duration);

  const getDailyScore = (data?: ScoreTimeItemType[]) => {
    if (!data || data.length === 0) {
      return 0;
    }
    const today = new Date().setHours(0, 0, 0, 0);
    const endDate = new Date(duration?.endDate ?? new Date()).setHours(0, 0, 0, 0);
    const findDay = data.find(
      (item) => (today > endDate ? endDate : today) === new Date(item.date).setHours(0, 0, 0, 0),
    );
    if (findDay) {
      return findDay.value;
    }
    return 0;
  };

  return {
    isLoading,
    dailyScore: getDailyScore(progress?.scoreTimeline),
    teamDailyScore: getDailyScore(progress?.teamScoreTimeLine),
    isTeam: detail?.isTeam,
    target: progress?.goalValue ?? 0,
    metricTitle: progress?.metricTitle,
  };
};
