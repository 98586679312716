import { IonContent, IonPage, useIonRouter } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Skeleton,
  styled,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CustomDropdown from '../components/common/CustomDropdown';
import Avatar from '../assets/svg/placeholder_avatar.svg';
import { ReactComponent as Wearable } from '../assets/svg/wearable.svg';
import { ReactComponent as Remove } from '../assets/svg/removeIcon.svg';
import StyledButton from '../components/common/Button';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store';
import AccountInfo from '../components/Profile/AccountInfo';
import CompanyInfo from '../components/Profile/CompanyInfo';
import {
  asyncGetAccountInfo,
  asyncGetCompanyInfo,
  asyncSaveAccountInfo,
  asyncSaveCompanyInfo,
  setAdminModal,
} from '../redux/slices/profileSlice';
import { UploadPictureModal } from '../components/modals/ProfileModals';
import { ReactComponent as LogoutIcon } from '../assets/svg/feature/logout.svg';
import { ReactComponent as CameraSVG } from '../assets/svg/camera.svg';
import { IAccountInfo, ICompanyInfo } from '../models/Profile';
import moment from 'moment';
import {
  connectWearable,
  disconnectWearable,
  getWearableInfo,
  openToast,
  updateWearable,
} from '../redux/slices/appSlice';
import UserService from '../services/UserService';
import GenericModal from '../components/modals/GenericModal';
import { Capacitor } from '@capacitor/core';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { PermissionStatus } from '@capacitor/camera/dist/esm/definitions';
import useViewIsVisible from '../hooks/useViewIsVisible';
import { ANALYTICS_PAGE } from '../models/enum/ANALYTICS_PAGE';
import { HeaderPage, useHeaderPageHandle } from '../components/feature/structure/HeaderPage';
import { performLogout } from '../utils/performLogout';
import { CustomButton } from '../components/features/CustomButton';

const typesOfProfile = ['Account Info', 'Organization Info'];

const Overlay = styled(Box)({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  height: '100%',
  width: '100%',
  opacity: 1,
  transition: '.3s ease',
});

const ProfilePage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const router = useIonRouter();
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));
  const breakpointDown = useMediaQuery(theme.breakpoints.down(980));
  const [fileUpload, setFileUpload] = useState<{ open: boolean; title: string }>({ open: false, title: '' });
  const [selectedImage, setSelectedImage] = useState<string | undefined>();
  const [typeOfProfile, setTypeOfProfile] = useState<string>(typesOfProfile[0]);
  const [edit, setEdit] = useState<boolean>(false);
  const [dateOfBirth, setDateOfBirth] = useState<string>();
  const [personalInfo, setPersonalInfo] = useState<IAccountInfo>({
    account_details: {
      email: '',
      password: '',
      username: '',
    },
    last_admin: false,
    first_login: false,
    avatar: '',
    company_logo: '',
    personal_details: {
      date_of_birth: '',
      first_name: '',
      gender: '',
      height: 0,
      last_name: '',
    },
    rewards: {
      company_consistency_rewards: false,
      company_performance_rewards: false,
      consistency_rewards: false,
      performance_rewards: false,
    },
    user_preference: {
      metric_system: '',
      timezone: '',
    },
    subscribed_emails: false,
    subscribed_questionnaires: false,
    user_roles: [''],
    country: '',
  });
  const [gender, setGender] = useState<string>('');
  const [timezone, setTimezone] = useState<string>('');
  const [unit, setUnit] = useState<string>('');
  const [compInfo, setCompInfo] = useState<ICompanyInfo>({
    company_details: {
      address: '',
      company_name: '',
      contact_email: '',
      country: '',
      employees_number: 0,
      industry: '',
      organisation_website: '',
      sector: '',
      size: '',
      state: '',
      slack_url: '',
    },
    company_logo: '',
    rewards: {
      consistency_rewards: false,
      performance_rewards: false,
    },
  });
  const [country, setCountry] = useState<string>('');
  const [userCountry, setUserCountry] = useState<string>('');
  const [sector, setSector] = useState<string>('');
  const [industry, setIndustry] = useState<string>('');
  const [size, setSize] = useState<string>('');
  const [wearableModal, setWearableModal] = useState<boolean>(false);
  const [imageError, setImageError] = useState<boolean>(false);
  const { accountInfo, companyInfo } = useSelector((state: RootState) => state.profile.data);
  const { adminModal, imageLoading, accountInfoLoading } = useSelector((state: RootState) => state.profile.meta);
  const { wearableConnected, wearableLoading } = useSelector((state: RootState) => state.app);
  const visible = useViewIsVisible(ANALYTICS_PAGE.PROFILE);

  const headerProps = useHeaderPageHandle();

  useEffect(() => {
    if (!visible) return;

    const uuid = UserService.getMineId();
    dispatch(getWearableInfo({ uuid }));
  }, [dispatch, visible]);

  useEffect(() => {
    if (!visible) return;

    const queryParams = new URLSearchParams(router.routeInfo.search);

    if (queryParams.toString()) {
      if (queryParams.has('organization')) {
        setTypeOfProfile(typesOfProfile[1]);
      }

      if (queryParams.has('user_id')) {
        const userId = queryParams.get('user_id') ?? '';
        const provider = queryParams.get('resource') ?? '';
        const reason = queryParams.get('reason') ?? '';
        if (reason) {
          dispatch(openToast({ text: 'Device connection failed.', type: 'error' }));
        } else {
          const uuid = UserService.getMineId();
          dispatch(updateWearable({ userId, provider, uuid }));
        }
      }

      router.push('/page/your-profile', 'none', 'replace');
    }
  }, [dispatch, router.routeInfo, visible]);

  useEffect(() => {
    if (!visible) return;

    if (typeOfProfile === 'Account Info') {
      dispatch(asyncGetAccountInfo());
    } else dispatch(asyncGetCompanyInfo());
  }, [dispatch, typeOfProfile, visible]);

  useEffect(() => {
    if (accountInfo) {
      setPersonalInfo({ ...accountInfo });
      setGender(accountInfo?.personal_details?.gender);
      setTimezone(accountInfo?.user_preference?.timezone);
      setUserCountry(accountInfo?.country ?? '');
      setUnit(accountInfo?.user_preference?.metric_system);
      if (accountInfo.personal_details.date_of_birth)
        setDateOfBirth(moment(accountInfo?.personal_details?.date_of_birth).toString());
    }
  }, [accountInfo]);

  useEffect(() => {
    if (companyInfo) {
      setCompInfo({ ...companyInfo });
      setCountry(companyInfo.company_details.country);
      setSector(companyInfo.company_details.sector);
      setIndustry(companyInfo.company_details.industry);
      setSize(companyInfo.company_details.size);
    }
  }, [companyInfo]);

  useEffect(() => {
    if (!fileUpload || !fileUpload.open) {
      setSelectedImage(undefined);
    }
  }, [fileUpload]);

  const handleToggleProfileCompany = (prof: string) => {
    setTypeOfProfile(prof);
  };

  const handleCheckForWearableDevice = () => {
    if (Capacitor.getPlatform() === 'web') {
      setWearableModal(true);
    } else {
      handleConnectWearable();
    }
  };

  const handleConnectWearable = () => {
    const uuid = UserService.getMineId();
    dispatch(connectWearable({ uuid }));
  };

  const handleDisconnectWearable = () => {
    dispatch(disconnectWearable());
  };

  const handlePickImage = (modal: { open: boolean; title: string }, source?: CameraSource) => {
    Camera.getPhoto({
      source: source ?? CameraSource.Prompt,
      resultType: CameraResultType.DataUrl,
    }).then(async (res: Photo) => {
      if (res.dataUrl) {
        setSelectedImage(res.dataUrl);
        setFileUpload({ open: true, title: modal.title });
      }
    });
  };

  const handlePhotoUpload = (modal: { open: boolean; title: string }, source?: CameraSource) => {
    if (!Capacitor.isNativePlatform()) {
      setFileUpload(modal);
      return;
    }

    Camera.checkPermissions().then((res: PermissionStatus) => {
      if (res.photos !== 'granted') {
        Camera.requestPermissions().then((res: PermissionStatus) => {
          if (res.photos === 'denied') {
            console.error('Photos permission denied');
          } else {
            handlePickImage(modal, source);
          }
        });
      } else {
        handlePickImage(modal, source);
      }
    });
  };

  const handleSave = () => {
    if (typeOfProfile === 'Account Info') {
      dispatch(
        asyncSaveAccountInfo({
          personalDetails: {
            ...personalInfo.personal_details,
            gender,
            date_of_birth: dateOfBirth ? moment(dateOfBirth).format('YYYY-MM-DD') : null,
          },
          preferenceDetails: { ...personalInfo.user_preference, metric_system: unit, timezone },
          ...(userCountry.length && { country: userCountry }),
        }),
      )
        .unwrap()
        .then(() => {
          setEdit(false);
        })
        .catch(() => {
          setEdit(true);
        });
    } else {
      let website = compInfo.company_details.organisation_website;
      if (website && !website.startsWith('https://')) {
        website = 'https://' + website;
      }
      dispatch(
        asyncSaveCompanyInfo({
          ...compInfo,
          company_details: {
            ...compInfo.company_details,
            organisation_website: website,
            country,
            size,
            sector,
            industry,
          },
        }),
      )
        .unwrap()
        .then(() => {
          setEdit(false);
        })
        .catch(() => {
          setEdit(true);
        });
    }
  };

  const handleCancel = () => {
    if (typeOfProfile === 'Account Info') {
      if (accountInfo) {
        setPersonalInfo({ ...accountInfo });
        setGender(accountInfo?.personal_details?.gender);
        setTimezone(accountInfo?.user_preference?.timezone);
        setUnit(accountInfo?.user_preference?.metric_system);
        setUserCountry(accountInfo?.country ?? '');
        setDateOfBirth(
          accountInfo?.personal_details?.date_of_birth
            ? moment(accountInfo?.personal_details?.date_of_birth).toString()
            : '',
        );
      }
    } else {
      if (companyInfo) {
        setCompInfo({ ...companyInfo });
        setCountry(companyInfo.company_details.country);
        setSector(companyInfo.company_details.sector);
        setIndustry(companyInfo.company_details.industry);
        setSize(companyInfo.company_details.size);
      }
    }
    setEdit(false);
  };

  const handleEnableEdit = () => {
    setEdit(true);
  };

  const extractHeaderButtons = () => {
    if (typeOfProfile === 'Account Info') {
      return !wearableConnected ? (
        <StyledButton
          onClick={handleCheckForWearableDevice}
          text={'Connect Device'}
          sx={xsDown ? { p: '5px 10px' } : undefined}
          inputProps={{
            startIcon: wearableLoading ? (
              <CircularProgress style={{ color: 'white', width: 20, height: 20 }} />
            ) : (
              <Wearable />
            ),
            color: 'warning',
            variant: 'contained',
          }}
        />
      ) : (
        <StyledButton
          onClick={handleDisconnectWearable}
          text={'Remove Device'}
          sx={xsDown ? { px: '5px 10px' } : undefined}
          inputProps={{
            startIcon: wearableLoading ? (
              <CircularProgress style={{ color: 'white', width: 20, height: 20 }} />
            ) : (
              <Remove />
            ),
            color: 'error',
            variant: 'contained',
          }}
        />
      );
    } else {
      return (
        <StyledButton
          sx={{ whiteSpace: 'nowrap' }}
          onClick={() => handlePhotoUpload({ open: true, title: 'Upload Company Logo' }, CameraSource.Photos)}
          text="Upload Company Logo"
          inputProps={{ color: 'warning', variant: 'contained', disabled: !edit }}
        />
      );
    }
  };
  const getImage = () => {
    if (personalInfo.avatar && !imageError) return personalInfo.avatar;
    else return Avatar;
  };
  return (
    <IonPage>
      <HeaderPage {...headerProps} />

      <IonContent style={{ '--padding-bottom': '56px' }}>
        <Box sx={{ position: 'relative', zIndex: '-10000', display: 'block', width: '100%' }}>
          <Box
            height="20vh"
            width="100%"
            sx={{ backgroundColor: 'gray', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <img
              src={'/assets/profile-bg.webp'}
              alt={'background'}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
                position: 'absolute',
              }}
            />
            {imageLoading && (
              <Box>
                <CircularProgress />
              </Box>
            )}
          </Box>
        </Box>
        <Container maxWidth="lg">
          <Grid container wrap={breakpointDown ? undefined : 'nowrap'} alignItems={'center'} spacing={2}>
            <Box
              position="relative"
              height={xsDown ? '100px' : '200px'}
              width={xsDown ? '100px' : '200px'}
              style={{
                borderRadius: '50%',
                border: '2px solid var(--ion-background-color, #fff)',
                marginTop: xsDown ? '-50px' : '-100px',
                marginLeft: 16,
                overflow: 'hidden',
              }}
            >
              <img
                alt={'profile'}
                src={getImage()}
                onError={() => setImageError(true)}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  objectPosition: 'center',
                }}
              />
              {edit && typeOfProfile === 'Account Info' && (
                <Overlay justifyContent={'center'} alignItems={'center'} display={'flex'}>
                  <SvgIcon
                    onClick={() => handlePhotoUpload({ open: true, title: 'Upload Profile Picture' })}
                    sx={{
                      fontSize: '25px',
                      width: '40px',
                      height: '40px',
                      padding: '8px',
                      background: 'radial-gradient(#000 0%,transparent 70% ,transparent 100%)',
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    }}
                  >
                    <CameraSVG />
                  </SvgIcon>
                </Overlay>
              )}
            </Box>
            <Grid item container sx={{ flex: 0 }}>
              <Grid item>
                {!accountInfo?.personal_details?.first_name && accountInfoLoading ? (
                  <Skeleton height={'32px'} width={'64px'} />
                ) : (
                  <Typography sx={{ fontWeight: 'bold' }} variant="h5">
                    {accountInfo?.personal_details?.first_name}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                {!accountInfo?.account_details?.username && accountInfoLoading ? (
                  <Skeleton height={'24px'} width={'96px'} />
                ) : (
                  <Typography>@{accountInfo?.account_details?.username}</Typography>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              item
              alignItems={xsDown ? 'flex-end' : 'center'}
              justifyContent="space-between"
              spacing={2}
              wrap={xsDown ? 'wrap' : 'nowrap'}
              xs={breakpointDown ? 12 : undefined}
              sx={{ flex: breakpointDown ? undefined : 1 }}
            >
              <Grid
                item
                container
                spacing={1}
                alignItems={'center'}
                justifyContent={xsDown ? 'space-between' : 'flex-start'}
              >
                <Grid item>{extractHeaderButtons()}</Grid>
                <Grid item>
                  {personalInfo?.user_roles?.includes('ADMIN') && (
                    <CustomDropdown
                      width={200}
                      fullWidth
                      disabled={edit}
                      list={typesOfProfile}
                      selected={typeOfProfile}
                      setSelected={handleToggleProfileCompany}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid item container justifyContent={'flex-end'} style={{ flex: xsDown ? 1 : 0 }}>
                <Grid container item alignItems={'center'} justifyContent={'flex-end'} wrap={'nowrap'} spacing={1}>
                  {!edit ? (
                    <Grid item>
                      <StyledButton onClick={handleEnableEdit} text="Edit" inputProps={{ variant: 'contained' }} />
                    </Grid>
                  ) : (
                    <>
                      <Grid item>
                        <StyledButton onClick={handleCancel} text="Cancel" inputProps={{ variant: 'outlined' }} />
                      </Grid>
                      <Grid item>
                        <StyledButton onClick={handleSave} text="Save" inputProps={{ variant: 'contained' }} />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Box sx={{ mt: 8 }}>
          {typeOfProfile === 'Account Info' ? (
            <AccountInfo
              edit={edit}
              personalInfo={personalInfo}
              setPersonalInfo={setPersonalInfo}
              gender={gender}
              setGender={setGender}
              timezone={timezone}
              setTimezone={setTimezone}
              unit={unit}
              setUnit={setUnit}
              dateOfBirth={dateOfBirth}
              setDateOfBirth={setDateOfBirth}
              country={userCountry}
              setCountry={setUserCountry}
            />
          ) : (
            <CompanyInfo
              compInfo={compInfo}
              setCompInfo={setCompInfo}
              size={size}
              setSize={setSize}
              sector={sector}
              setSector={setSector}
              country={country}
              setCountry={setCountry}
              edit={edit}
              industry={industry}
              setIndustry={setIndustry}
            />
          )}
          {xsDown && (
            <Container maxWidth="lg">
              <Grid sx={{ px: '16px' }}>
                <Box sx={{ border: '1px solid #8B8B8B', my: '30px', mx: 4 }} />
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CustomButton
                    text={'Log Out'}
                    type={'bigLightBlue'}
                    inputProps={{
                      variant: 'contained',
                      startIcon: <LogoutIcon width={16} height={16} stroke="#16BECF" />,
                    }}
                    sx={{ display: { xs: 'inline-flex', sm: 'none' }, maxWidth: '328px', width: '100%' }}
                    onClick={performLogout}
                  />
                </Box>
              </Grid>
            </Container>
          )}
        </Box>
      </IonContent>
      <UploadPictureModal
        modal={fileUpload}
        setModal={setFileUpload}
        personalInfo={personalInfo}
        setPersonalInfo={setPersonalInfo}
        companyInfo={compInfo}
        setCompanyInfo={setCompInfo}
        selectedImage={selectedImage}
      />
      <GenericModal
        title={'ADMIN WARNING'}
        description={
          'You cannot delete your account because you are the only Admin for your organization. Go to Organization info page, transfer your Admin role to another user and then proceed to this action.'
        }
        cancelText={'OK'}
        open={adminModal}
        onClose={() => dispatch(setAdminModal(false))}
      />
      <GenericModal
        title={'CONNECT DEVICE'}
        description={
          'If you want to connect your Apple Watch or Apple Health account, you need to download our mobile app. Click ok to proceed to other connections'
        }
        cancelText={'Cancel'}
        confirmText={'OK'}
        confirmFunction={handleConnectWearable}
        open={wearableModal}
        onClose={() => setWearableModal(false)}
      />
    </IonPage>
  );
};

export default ProfilePage;
