import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { fetchChallenge } from '../../../redux/slices/challengeSlice';
import { Challenge } from '../../../models/Challenge';
import { CHALLENGE_STATUS } from '../../../models/enum/CHALLENGE_STATUS';
import { CHALLENGE_SUB_CATEGORY } from '../../../models/enum/CHALLENGE_SUB_CATEGORY';
import moment from 'moment/moment';
import { COMPETITION } from '../../../models/enum/COMPETITION';
import { useIonRouter } from '@ionic/react';

type Props = {
  id: string;
};
export const pageOptionsByType = {
  ONGOING: {
    label: 'Ongoing challenges',
    challengeStatus: CHALLENGE_STATUS.ACTIVE,
  },
  OPEN_TO_JOIN: {
    label: 'Open to join challenges',
    challengeStatus: CHALLENGE_STATUS.INACTIVE,
  },
  FINISHED: { label: 'Finished challenges', challengeStatus: CHALLENGE_STATUS.COMPLETED },
};
export const useHandle = (params: Props, participantsCallback: () => Promise<void>) => {
  const dispatch = useDispatch();
  const { challenge } = useSelector((state: RootState) => state.challenge.data);
  const { challengeLoading } = useSelector((state: RootState) => state.challenge.meta);
  const [showParticipantsModal, setShowParticipantsModal] = useState(false);

  const router = useIonRouter();

  const [expanded, setExpanded] = useState(false);
  const [daysLeftToJoin, setDaysLeftToJoin] = useState(0);
  const [isTeamChallenge, setIsTeamChallenge] = useState(true);
  const queryParams = new URLSearchParams(window.location.search);
  const refreshIndicator = queryParams.get('date');
  const replaceChallengeData = (newChallengeData: Challenge) => {
    // history.replaceState(null, '', `/page/your-challenges/challenge/${newChallengeData.metadata.challengeUUID}`);
    router.push(`/page/your-challenges/challenge/${newChallengeData.metadata.challengeUUID}`, 'root', 'replace');
    loadData(undefined, newChallengeData);
  };
  const loadData = async (status?: CHALLENGE_STATUS, newChallengeData?: Challenge) => {
    if (!params) return;
    const queryParams = new URLSearchParams(window.location.search);
    const isTemplate = newChallengeData
      ? newChallengeData.challengeStatus === CHALLENGE_STATUS.TEMPLATE
        ? String(newChallengeData.challengeStatus === CHALLENGE_STATUS.TEMPLATE)
        : null
      : queryParams.get('isTemplate');
    const id = !newChallengeData
      ? params.id
      : newChallengeData.challengeStatus === CHALLENGE_STATUS.TEMPLATE &&
        newChallengeData.metadata.templateChallengeUUID
      ? newChallengeData.metadata.templateChallengeUUID
      : newChallengeData.metadata.challengeUUID
      ? newChallengeData.metadata.challengeUUID
      : params.id;

    dispatch(fetchChallenge({ id: id, isTemplate }));
    // await participantsCallback();
  };

  useEffect(() => {
    loadData();
  }, [params, refreshIndicator]);

  useEffect(() => {
    if (challenge) {
      setIsTeamChallenge(Boolean(challenge.challengeCharacteristic.competition === COMPETITION.TEAM));
    }

    if (challenge && challenge.challengeParticipation.endParticipationDate) {
      const now = moment(new Date()).format('YYYY-MM-DD');
      const futureDate = moment(challenge.challengeParticipation.endParticipationDate);
      setDaysLeftToJoin(futureDate.diff(now, 'days') + 1);
    }
  }, [challenge]);

  const challengeTitle = challenge ? challenge.challengeInfo.title : '';

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const progressBreadcrumbItem = () => {
    if (
      !challenge ||
      !challenge.challengeParticipation.isUserParticipant ||
      challenge.challengeSubCategory === CHALLENGE_SUB_CATEGORY.OPEN_TO_JOIN ||
      (challenge.challengeCharacteristic.competition === COMPETITION.TEAM &&
        !challenge.challengeParticipation.isUserTeamMember)
    )
      return [];

    return [
      { label: challengeTitle, url: `/page/your-challenges/challenge/${challenge.metadata.challengeUUID}/activity` },
    ];
  };
  const challengeStatusBreadcrumbItem =
    !challenge || !challenge.challengeSubCategory
      ? []
      : [
          {
            label: pageOptionsByType[challenge.challengeSubCategory].label,
            url: `/page/your-challenges/${
              challenge.challengeSubCategory === CHALLENGE_SUB_CATEGORY.ONGOING
                ? 'ongoing'
                : challenge.challengeSubCategory === CHALLENGE_SUB_CATEGORY.OPEN_TO_JOIN
                ? 'join'
                : 'finished'
            }`,
          },
        ];

  return {
    challenge,
    challengeTitle,
    challengeLoading,
    expanded,
    showParticipantsModal,
    progressBreadcrumbItem,
    challengeStatusBreadcrumbItem,
    daysLeftToJoin,
    isTeamChallenge,
    loadData,
    setShowParticipantsModal,
    handleExpandClick,
    replaceChallengeData,
  };
};
