import React, { FC, useEffect, useState } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../assets/svg/closeIcon.svg';
import InputField from '../common/CustomTextField';
import StyledButton from '../common/Button';
import { IMetric } from '../../models/Metrics';

interface ModalProps {
  metric?: IMetric;
  onClose: () => void;
  onUpdate: (metric: IMetric) => void;
  onDelete: (metric: IMetric) => void;
}

const MetricsEditModal: FC<ModalProps> = ({ metric, onClose, onUpdate, onDelete, ...rest }) => {
  const [metricRename, setMetricRename] = useState('');

  useEffect(() => {
    setMetricRename(metric?.title ?? '');
  }, [metric?.title]);

  const handleUpdate = () => {
    if (metric) {
      const renamedMetric = { ...metric, title: metricRename };
      onUpdate(renamedMetric);
    }
  };

  const handleDelete = () => {
    if (metric) {
      onDelete(metric);
    }
  };

  return (
    <Dialog
      maxWidth={'xs'}
      open={!!metric}
      onClose={onClose}
      PaperProps={{
        style: {
          margin: '8px',
          borderRadius: '8px',
          maxHeight: 'calc(100% - 16px)',
          minHeight: '258px',
          width: '368px',
        },
      }}
      {...rest}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            {metric?.title?.toUpperCase()}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ p: 2 }}>
        <Box display="flex" mt={4} mb={4} gap={2} alignItems="center" flexDirection="column" justifyContent="center">
          <InputField
            label={'Metric Name'}
            name={'metric-name'}
            value={metricRename}
            setValue={(e) => setMetricRename(e.target.value)}
          />
        </Box>
      </DialogContent>
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-around'} mb={3}>
        <StyledButton text="Delete" inputProps={{ color: 'error', variant: 'contained' }} onClick={handleDelete} />
        <StyledButton
          text="Update"
          inputProps={{ variant: 'contained', disabled: !metricRename || metric?.title === metricRename }}
          onClick={handleUpdate}
        />
      </Box>
    </Dialog>
  );
};

export default MetricsEditModal;
