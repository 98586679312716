import * as React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { Box, Skeleton, Typography } from '@mui/material';
import {} from '../../../utils/metricsCategories';
import { Colors } from '../../../theme/colors';

type HeaderWellbeingChartProps = {
  rating: number;
  series: number[];
  colors: string[];
  width?: string | number;
  height?: string | number;
  loading?: boolean;
};

export const HeaderWellbeingChart: React.FC<HeaderWellbeingChartProps> = ({
  rating,
  series,
  colors,
  width = 68,
  height = 68,
  loading = false,
}) => {
  const chartDataOptions: ApexOptions = {
    colors: rating === 0 ? ['#8b8b8b'] : colors,
    stroke: {
      width: 0,
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    dataLabels: {
      enabled: false,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: '75%',
        },
      },
    },
    chart: {
      width: '100%',
      events: {
        mounted: (chart) => {
          chart.windowResizeHandler();
        },
      },
      toolbar: {
        show: false,
      },
    },
  };

  return (
    <Box sx={{ position: 'relative', display: 'inline-block', height: '36px', width: '36px' }}>
      <Box
        sx={{
          position: 'absolute',
          zIndex: 10,
          top: '50%',
          left: '50%',
          transform: 'translate(-52%, -43%)',
        }}
      >
        <ReactApexChart
          options={chartDataOptions}
          series={rating === 0 ? [100] : series}
          type={'donut'}
          height={height}
          width={width}
        />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          left: '2px',
          right: '2px',
          top: '2px',
          bottom: '2px',
          borderRadius: '50%',
          background: `radial-gradient(${Colors.blue[600]}, ${Colors.blue[800]})`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {loading ? (
          <Skeleton variant={'text'} width={16} height={8} />
        ) : (
          <Typography sx={{ fontSize: '10px', fontWeight: '700', color: Colors.white }}>{rating}</Typography>
        )}
      </Box>
    </Box>
  );
};
