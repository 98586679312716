import { useEffect, useState } from 'react';
import { Challenge } from '../../../models/Challenge';
import { useDispatch, useSelector } from 'react-redux';
import { openToast } from '../../../redux/slices/appSlice';
import { EditCalendarTimelineItem } from '../../../pages/ChallengeActivityPage/types';
import { CALCULATION_TYPE } from '../../../models/enum/CALCULATION_TYPE';
import { COMPETITION } from '../../../models/enum/COMPETITION';
import moment from 'moment';
import { updateChallengeMetric, updateHabit } from '../../../redux/slices/challengeProgressSlice';
import { AppDispatch, RootState } from '../../../redux/store';
import { getChallengeProgress } from '../../../lib/api/http/requests/challenge';
import { handleBackendError } from '../../../utils/handleBackendError';
import { METRICS_TYPE } from '../../../models/enum/METRICS_TYPE';
import { metricsValidation } from '../../../utils/metricsValidation';

export const useHandleCardsLogData = (
  handleUpdateItem: () => void,
  handleUpdateChallenge: (challenge: Challenge) => void,
) => {
  const [challenge, setChallenge] = useState<Challenge | undefined>();
  const [date, setDate] = useState<Date>();
  const [input, setInput] = useState('');
  const [secondInput, setSecondInput] = useState('');
  const [listUpdatedDay, setListUpdatedDay] = useState<EditCalendarTimelineItem[]>([]);

  const isUpdating = useSelector((state: RootState) => state.challengeProgress.meta.isUpdating);

  const isBoolean = challenge?.challengeAction?.actionType === 'HABIT';

  const isTeam = challenge?.challengeCharacteristic?.competition === COMPETITION.TEAM;
  const metricTitle = challenge?.challengeAction?.title ?? '';

  const startDate = challenge?.challengeDuration?.startDate;
  const endDate = challenge?.challengeDuration?.endDate;

  const isSumNone = challenge?.challengeCharacteristic?.calculationType === CALCULATION_TYPE.NONE;
  const maxCount = challenge?.teamRanking?.team.teamSize;

  const dispatch: AppDispatch = useDispatch();

  const onClose = () => {
    setChallenge(undefined);
    setDate(undefined);
    setInput('');
    setListUpdatedDay([]);
  };

  const handleFetchChallenge = async (blockUpdate?: boolean) => {
    try {
      if (!challenge?.metadata?.challengeUUID) return;
      const response = await getChallengeProgress({ challengeUUID: challenge.metadata.challengeUUID });
      if (!blockUpdate) setChallenge(response.data);
      if (handleUpdateChallenge) {
        handleUpdateChallenge(response.data);
      }
    } catch (err) {
      handleBackendError(err, 'Error refresh challenge update');
    }
  };

  const onChangeDate = (date: Date) => {
    if (!challenge) return;
    const today = new Date().setHours(0, 0, 0, 0);
    const minDay = new Date(today).setDate(new Date(today).getDate() - 7);
    if (!challenge.challengeDuration?.startDate && !challenge.challengeDuration?.endDate) return;
    const startDate = new Date(challenge.challengeDuration.startDate).setHours(0, 0, 0, 0);
    const endDate = new Date(challenge.challengeDuration.endDate).setHours(0, 0, 0, 0);
    const selectedDay = new Date(date).setHours(0, 0, 0, 0);
    if (date.getTime() >= startDate && date.getTime() <= endDate) {
      if (today < selectedDay) {
        if (
          challenge.challengeParticipation?.joinTeamDate &&
          today < new Date(challenge.challengeParticipation?.joinTeamDate).getTime()
        ) {
          dispatch(openToast({ text: 'You cannot log data before the day you join the team', type: 'error' }));
          return;
        }
        dispatch(openToast({ text: 'The day has not yet come', type: 'error' }));
        return;
      }
      if (minDay >= selectedDay) {
        dispatch(openToast({ text: 'You can only log data for the past 7 days', type: 'error' }));
        return;
      }
      setDate(date);
      const findDateData = listUpdatedDay.find((item) => new Date(item.date).setHours(0, 0, 0, 0) === selectedDay);
      if (challenge.challengeAction.metricType === METRICS_TYPE.SLEEP_DURATION) {
        const minutes = (findDateData?.newValue ?? findDateData?.value ?? 0) % 60;
        const hours = ((findDateData?.newValue ?? findDateData?.value ?? 0) - minutes) / 60;
        setInput(hours.toString());
        setSecondInput(minutes.toString());
        return;
      }
      setInput(findDateData?.newValue?.toString() ?? findDateData?.value.toString() ?? '');
    }
  };

  const onChangeInput = (val: string) =>
    (Number(val) <= 300000 && Number(val) >= 1) || val === '' ? setInput(val) : null;

  const handleAddValue = () => {
    if (isUpdating) return;
    const newListMetric = listUpdatedDay.filter(
      (item) => item?.newValue && item.newValue >= 0 && item.value !== item.newValue,
    ) as Array<{ date: string; newValue: number }>;
    if (
      challenge &&
      newListMetric.length > 0 &&
      challenge?.challengeAction?.metricType &&
      challenge.challengeCharacteristic?.groupCategory
    ) {
      dispatch(
        updateChallengeMetric({
          metricType: challenge.challengeAction?.metricType,
          title: challenge.challengeAction?.title ?? '',
          group: challenge.challengeCharacteristic.groupCategory,
          newListMetric,
          callback: () => {
            handleUpdateItem();
            onClose();
          },
        }),
      );
    }
  };

  const showDate = endDate && new Date(endDate) < new Date() ? new Date(endDate) : undefined;

  const handlePlusButton = () => {
    if (!date) {
      dispatch(openToast({ text: 'Please select a day to log your data', type: 'error' }));
      return;
    }
    if (!challenge?.challengeAction.metricType) {
      dispatch(openToast({ text: 'Unknown metric type or missing metric type', type: 'error' }));
      return;
    }
    const validation = metricsValidation(challenge.challengeAction.metricType, input ?? '', secondInput);
    if (!validation.success) {
      dispatch(openToast({ text: validation.error ?? 'Wrong input value', type: 'error' }));
      return;
    }
    const itemIndex = listUpdatedDay.findIndex(
      (item) => new Date(item.date).setHours(0, 0, 0, 0) === new Date(date).setHours(0, 0, 0, 0),
    );
    if (itemIndex >= 0) {
      if (challenge.challengeAction.metricType === METRICS_TYPE.SLEEP_DURATION) {
        if (!Number(secondInput)) {
          setListUpdatedDay(
            listUpdatedDay.map((item, idx) =>
              idx === itemIndex
                ? {
                    ...item,
                    newValue: Math.round(Number(input) * 60),
                  }
                : item,
            ),
          );
        }
        setListUpdatedDay(
          listUpdatedDay.map((item, idx) =>
            idx === itemIndex
              ? {
                  ...item,
                  newValue: Math.round(Number(input) * 60 + Number(secondInput)),
                }
              : item,
          ),
        );
        return;
      }
      setListUpdatedDay(
        listUpdatedDay.map((item, idx) =>
          idx === itemIndex
            ? {
                ...item,
                newValue: Math.round(Number(input)),
              }
            : item,
        ),
      );
      return;
    }
    setListUpdatedDay([
      ...listUpdatedDay,
      { date: moment(date).format('YYYY-MM-DD'), value: 0, newValue: Math.round(Number(input)) },
    ]);
  };

  const handleUpdateHabit = (date: string, isSuccess: boolean) => () => {
    if (isUpdating || !challenge?.metadata?.challengeUUID) return;
    const today = new Date().setHours(0, 0, 0, 0);
    const selectedDay = new Date(date).setHours(0, 0, 0, 0);
    const minDay = new Date(today).setDate(new Date(today).getDate() - 7);
    if (today < selectedDay) {
      if (
        challenge?.challengeParticipation?.joinTeamDate &&
        today < new Date(challenge?.challengeParticipation.joinTeamDate).getTime()
      ) {
        dispatch(openToast({ text: 'You cannot log data before the day you join the team', type: 'error' }));
        return;
      }
      dispatch(openToast({ text: 'The day has not yet come', type: 'error' }));
      return;
    }
    if (minDay >= selectedDay) {
      ``;
      dispatch(openToast({ text: 'You can only log data for the past 7 days', type: 'error' }));
      return;
    }
    dispatch(updateHabit({ date, habitValue: !isSuccess, challengeUUID: challenge?.metadata?.challengeUUID }))
      .unwrap()
      .then(() => {
        handleFetchChallenge();
      });
  };

  const getTeamTimelineScore = (): EditCalendarTimelineItem[] => {
    if (challenge?.challengeCharacteristic?.calculationType !== CALCULATION_TYPE.NONE)
      return challenge?.teamRanking?.scoreTimeline ?? [];
    if (!isTeam || !challenge?.userRanking?.scoreTimeline || !challenge?.teamRanking?.scoreTimeline) return [];
    const yourScoreTimeline = new Map<string, { status?: string }>();
    (challenge?.userRanking.scoreTimeline ?? []).forEach((item) =>
      yourScoreTimeline.set(item.date, { status: item.status }),
    );
    const teamTimeline = (challenge?.teamRanking?.scoreTimeline ?? []).map((item) => ({
      ...item,
      teamValue: undefined,
      status: yourScoreTimeline.get(item.date)?.status,
    }));
    return teamTimeline;
  };

  useEffect(() => {
    if (challenge && challenge.userRanking?.scoreTimeline) {
      setListUpdatedDay(challenge.userRanking.scoreTimeline);
    }
  }, [challenge]);

  return {
    setChallenge,
    isBoolean,
    date,
    onChangeDate,
    onChangeSecondInput: setSecondInput,
    visible: !!challenge,
    input,
    secondInput,
    onChangeInput,
    onClose,
    timelineScore: listUpdatedDay.map((item) => ({ ...item, isComplete: item.status === 'SUCCESS' })) ?? [],
    teamTimelineScore: getTeamTimelineScore(),
    isTeam,
    metricTitle,
    handleAddValue,
    startDate,
    endDate,
    handlePlusButton,
    isSumNone,
    maxCount,
    handleUpdateHabit,
    showDate,
    disableLogData: listUpdatedDay.length === 0,
    metricType: challenge?.challengeAction.metricType,
  };
};
