import React from 'react';
import {
  Step,
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from '@mui/material';
import { Colors } from '../../../theme/colors';
import { SxProps } from '@mui/system';

const steps = ['Text and photo', 'Goal and award', 'Setup'];
const StepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  background: 'radial-gradient(58.21% 70.17% at 33.33% 26.67%, #F0F2F3 0%, #A7B0B5 79.58%)',
  zIndex: 1,
  color: Colors.white,
  width: 32,
  height: 32,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  ...(ownerState.active && {
    background: 'radial-gradient(58.21% 70.17% at 33.33% 26.67%, #F0F2F3 0%, #A7B0B5 79.58%)',
    border: '1px solid #D3D7DA',
  }),
  ...(ownerState.completed && {
    background: 'radial-gradient(58.21% 70.17% at 33.33% 26.67%, #B9F1DA 0%, #12A66A 79.58%)',
    border: '1px solid #A2ECCE',
  }),
}));
const StepNumber = (props: StepIconProps) => {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <Typography sx={{ fontSize: 12, fontWeight: 700, color: Colors.white }}>1 </Typography>,
    2: <Typography sx={{ fontSize: 12, fontWeight: 700, color: Colors.white }}>2 </Typography>,
    3: <Typography sx={{ fontSize: 12, fontWeight: 700, color: Colors.white }}>3 </Typography>,
  };

  return (
    <StepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </StepIconRoot>
  );
};

const Connector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 16,
    left: 'calc(-50% + 20px)',
    right: 'calc(50% + 20px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: Colors.gray[150],
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: Colors.green[200],
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: Colors.gray[150],
    borderTopWidth: 1,
    borderRadius: 0,
    borderTopStyle: 'dashed',
  },
}));
type Props = {
  sx?: SxProps;
  activeStep: number;
  onClick: (step: number) => void;
};
export const CustomStepper: React.FC<Props> = ({ sx, activeStep = 1, onClick }) => {
  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      connector={<Connector />}
      sx={[{ maxWidth: '328px', mx: 'auto', '& .MuiStep-root': { padding: 0 } }, ...(Array.isArray(sx) ? sx : [sx])]}
    >
      {steps.map((label, index) => (
        <Step key={label}>
          <StepLabel
            StepIconComponent={StepNumber}
            sx={{
              '& .MuiStepLabel-label': {
                marginTop: '8px',
                fontSize: '12px',
                fontWeight: 400,
                color: Colors.gray[300],
              },
              '& .MuiStepLabel-label.Mui-active': {
                marginTop: '8px',
                fontSize: '12px',
                fontWeight: 400,
                color: Colors.gray[300],
              },
              '& .MuiStepLabel-label.Mui-completed': {
                marginTop: '8px',
                fontSize: '12px',
                fontWeight: 700,
                color: Colors.gray[700],
              },
              '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
                marginTop: '8px',
              },
            }}
            onClick={() => onClick(index + 1)}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};
