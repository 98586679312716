import React from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import { Colors } from '../../../theme/colors';

type RemindChallengeDayProps = {
  daysPassed?: number;
  days?: number;
  isLoading?: boolean;
};

export const RemindChallengeDay: React.FC<RemindChallengeDayProps> = ({ daysPassed, days, isLoading = false }) => {
  return (
    <Box
      sx={{
        paddingInline: '32px',
        background: 'rgba(137, 149, 157, 0.1);',
        paddingTop: '8px',
        paddingBottom: '16px',
      }}
    >
      {isLoading || !days || !daysPassed ? (
        <>
          <Skeleton
            variant="rectangular"
            sx={{ height: 18, width: 120, backgroundColor: Colors.gray[400], marginBottom: '8px', borderRadius: '4px' }}
          />
          <Skeleton
            variant="rectangular"
            sx={{ height: 16, borderRadius: '30px', flex: 1, backgroundColor: Colors.gray[200] }}
          />
        </>
      ) : (
        <>
          <Typography
            sx={{
              fontSize: '12px',
              fontFamily: 'Nexa',
              color: Colors.gray[400],
              '& em': {
                fontSize: '18px',
                fontWeight: 900,
                color: Colors.gray[700],
              },
            }}
          >
            <em>{daysPassed}</em>/{days} day of the challenge
          </Typography>
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              height: 16,
              flex: 1,
              background: 'rgba(196, 202, 206, 0.3)',
              borderRadius: '30px',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {new Array(days).fill('1').map((_, idx) => (
              <Box
                key={idx}
                sx={{
                  height: '2px',
                  width: '2px',
                  borderRadius: '2px',
                  marginRight: 'auto',
                  transform: 'transformX(1px)',
                  background: idx === 0 ? 'unset' : 'rgba(137, 149, 157, 0.4)',
                }}
              />
            ))}
            <Box
              sx={{
                display: daysPassed === 0 ? 'none' : 'block',
                position: 'absolute',
                zIndex: 100,
                top: 0,
                bottom: 0,
                left: 0,
                borderRadius: '30px',
                background: 'linear-gradient(0deg, #45CBD9 0%, #A2E5EC 100%)',
                width: `calc(${(100 / days) * daysPassed}% + 8px)`,
                border: `0.5px solid ${Colors.blue[100]}`,
                boxShadow: '0px 2px 5px rgba(22, 190, 207, 0.35)',
              }}
            >
              <Box
                sx={{
                  height: '8px',
                  width: '3.41px',
                  borderRadius: '20px',
                  background: Colors.white,
                  marginTop: '4px',
                  marginBottom: '4px',
                  marginLeft: 'auto',
                  marginRight: '9px',
                }}
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};
