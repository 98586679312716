import React from 'react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { styles } from './styles';
import { Colors } from '../../../theme/colors';
import { Challenge, ChallengeAdminProgress } from '../../../models/Challenge';
import { SxProps, Theme } from '@mui/system';
import AvatarPlaceholder from '../../../assets/svg/placeholder_avatar.svg';

type Props = {
  sx?: SxProps<Theme>;
  isTeam?: boolean;
  challenge: ChallengeAdminProgress | Challenge;
  type?: 'sm' | 'lg';
  onClick?: () => void;
};
export const CustomAvatarList: React.FC<Props> = ({ sx, isTeam, challenge, type = 'sm', onClick }) => {
  const isLarge = type === 'lg';
  const isList = isTeam
    ? challenge.teamsAvatars ||
      (challenge.challengeParticipation.numberOfTeams !== undefined &&
        challenge.challengeParticipation.numberOfTeams > 0)
    : challenge.participantsAvatars || challenge.challengeParticipation.numberOfParticipants > 0;
  const isAvatars = isTeam ? !!challenge.teamsAvatars : !!challenge.participantsAvatars;
  const avatars = isTeam ? challenge.teamsAvatars : challenge.participantsAvatars;
  const countOfParticipants = isTeam
    ? challenge.challengeParticipation.numberOfTeams !== undefined
      ? challenge.challengeParticipation.numberOfTeams - (challenge.teamsAvatars ?? []).length
      : 0
    : challenge.challengeParticipation.numberOfParticipants;
  const isLeftSpace = isTeam
    ? !challenge.teamsAvatars || (challenge.teamsAvatars && challenge.teamsAvatars.length === 0)
    : !challenge.participantsAvatars || (challenge.participantsAvatars && challenge.participantsAvatars.length === 0);

  return isList ? (
    <Grid item container wrap={'nowrap'} sx={{ ...sx }} onClick={onClick}>
      {isAvatars && avatars
        ? avatars.map((item, index) => (
            <Grid key={index} item sx={!index ? {} : { ml: '-16px' }}>
              <Box sx={isLarge ? styles.avatarContainerLarge : styles.avatarContainer}>
                <img
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = AvatarPlaceholder;
                  }}
                  src={item}
                  alt={'background'}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                    position: 'absolute',
                  }}
                />
              </Box>
            </Grid>
          ))
        : null}
      {countOfParticipants > 0 && (
        <Grid
          item
          sx={{
            ml: isLeftSpace ? 0 : '-16px',
          }}
        >
          <Box
            height={isLarge ? '48px' : '36px'}
            width={isLarge ? '48px' : '36px'}
            sx={isLarge ? styles.numberOfParticipantsLarge : styles.numberOfParticipants}
          >
            <Typography color={Colors.gray[700]} sx={{ fontSize: isLarge ? '16px' : '12px' }}>
              {countOfParticipants}
            </Typography>
          </Box>
        </Grid>
      )}
    </Grid>
  ) : (
    <Box sx={{ height: isLarge ? 48 : 36, width: 1 }} />
  );
};
