export const numberWithSpaces = (x?: number | null) =>
  typeof x === 'number' ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : undefined;

export const numberLimitWithSpaces = (x: number | null, maxCount: number) => {
  if (!x) return 0;
  const digitsCount = x.toString().length;
  if (digitsCount > maxCount && maxCount - 2) {
    return Math.floor((x / Number(`1e${maxCount - 2}`)) * 10) / 10 + 'K';
  }
  return numberWithSpaces(x);
};

export const convertThousands = (value: number) => {
  if (value < 1000) return value;
  const convertedValue = Math.round(value / 100) / 10;
  return `${convertedValue}k`;
};
