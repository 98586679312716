export enum METRICS_UNIT {
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  MINUTES = 'MINUTES',
  STEPS = 'STEPS',
  PORTIONS = 'PORTIONS',
  GLASS = 'GLASS',
  TIMES = 'TIMES',
  KG = 'KG',
  KM = 'KM',
  CAL = 'CAL',
  MS = 'MS',
  BPM = 'BPM',
  LB = 'LB',
  MILES = 'MILES',
  SESSIONS = 'SESSIONS',
}
