import { useIonRouter } from '@ionic/react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { performLogout } from '../../../../utils/performLogout';

export const useHeaderPageHandle = () => {
  const profile = useSelector((state: RootState) => state.profile.data);

  const route = useIonRouter();

  const name = profile.accountInfo.personal_details.first_name || `${profile.accountInfo.account_details.username}`;
  const avatar = profile.accountInfo.avatar;

  const openTrophy = () => route.push('/page/trophy-library');
  const openNotification = () => console.log('open notification');
  const openSupport = () => route.push('/page/our-support');
  const openProfile = () => route.push('/page/your-profile');
  const logOut = performLogout;
  return {
    name,
    avatar,
    openTrophy,
    openNotification,
    openSupport,
    openProfile,
    logOut,
  };
};
