import React, { useEffect, useState } from 'react';
import { Box, Modal, Dialog, Typography, TextField } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../../assets/svg/feature/close.svg';
import { Colors } from '../../../theme/colors';
import { CustomButton } from '../../../components/features/CustomButton';
import { ReactComponent as SaveIcon } from '../../../assets/svg/feature/save.svg';
import { MetricItemTypes } from '../types';
import { EditCalendarTimelineItem } from '../../ChallengeActivityPage/types';
import { METRICS_TYPE } from '../../../models/enum/METRICS_TYPE';
import InputField from '../../../components/features/CustomTextField';
import { METRICS_UNIT } from '../../../models/enum/METRICS_UNIT';
import { CustomSlider } from '../../../components/features/CustomSlider';
import { unitsLabel } from '../helpers';
import { ReactComponent as PlusIcon } from '../../../assets/svg/plus.svg';
import { MobileSleepInput } from './MobileSleepInput';
import { useIonRouter } from '@ionic/react';
import { inputNumberValidation } from '../../../utils/inputNumberValidation';
import { ReactComponent as DeleteIcon } from '../../../assets/svg/feature/delete.svg';

type MobileLogDataProps = {
  isUpdating: boolean;
  isOpenLogData: boolean;
  handleCloseLogData: () => void;
  isCreateMetric?: boolean;
  metric?: MetricItemTypes;
  firstInput: string;
  secondInput: string;
  targetFirstInput: string;
  targetSecondInput: string;
  sliderValue: number;
  targetSlideValue: number;
  focusedDate: Date;
  timeline: EditCalendarTimelineItem[];
  hasGoal: boolean;
  setFirstInput: (val: string) => void;
  setSecondInput: (val: string) => void;
  setSliderValue: (val: number) => void;
  setTargetFirstInput: (val: string) => void;
  setTargetSecondInput: (val: string) => void;
  setTargetSlideValue: (val: number) => void;
  handleChangeDay: (date: Date) => void;
  handleShownDateChange: (date: Date) => void;
  handleAddMetric: () => void;
  handleAddGoal: () => void;
  handleMobileSave: (witGoal?: boolean, isRemove?: boolean) => () => Promise<void>;
  handleCreateMetric: (isMobile?: boolean) => Promise<void>;
};

export const MobileLogData: React.FC<MobileLogDataProps> = ({
  isUpdating,
  isOpenLogData,
  handleCloseLogData,
  isCreateMetric,
  metric,
  firstInput,
  secondInput,
  sliderValue,
  targetFirstInput,
  targetSecondInput,
  targetSlideValue,
  hasGoal,
  setFirstInput,
  setSecondInput,
  setSliderValue,
  setTargetFirstInput,
  setTargetSecondInput,
  setTargetSlideValue,
  handleMobileSave,
  handleCreateMetric,
}) => {
  const [showGoal, setShowGoal] = useState(false);
  const isSleepDuration = metric?.metricType === METRICS_TYPE.SLEEP_DURATION;
  const closeLogData = () => {
    if (isUpdating) return;
    if (isCreateMetric) router.goBack();
    setShowGoal(false);
    handleCloseLogData();
  };
  const router = useIonRouter();
  useEffect(() => {
    if (!isOpenLogData) {
      setShowGoal(false);
    }
  }, [isOpenLogData]);
  return (
    <Box>
      <Modal open={isOpenLogData} onClose={closeLogData}>
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            background: Colors.white,
            padding: '32px 16px',
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
          }}
        >
          <Box sx={{ position: 'relative', width: '100%', textAlign: 'center', mb: '32px' }}>
            <Typography sx={{ fontSize: '18px', color: Colors.gray[700], fontWeight: 700 }}>
              {isCreateMetric ? 'Add a new metric' : showGoal ? 'Add target value' : 'Add current value'}
            </Typography>
            <Box
              component="button"
              sx={{
                position: 'absolute',
                zIndex: 100,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                right: 0,
                top: '50%',
                transform: 'translate(-50%, -50%)',
                height: '28px',
                width: '28px',
                borderRadius: '50%',
                background: Colors.gray[50],
              }}
              onClick={closeLogData}
            >
              <CloseIcon fill={Colors.gray[400]} height={12} width={12} />
            </Box>
          </Box>
          <Box mb="32px">
            {metric && !isCreateMetric ? (
              <Box>
                {!metric.isAuto && (
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-around'}
                    width={'100%'}
                    mt="24px"
                    mb="32px"
                  >
                    {isSleepDuration ? (
                      <Box sx={{ flex: 1 }}>
                        <Typography sx={{ color: Colors.gray[700] }}>Sleep duration</Typography>
                        <MobileSleepInput
                          onClickDecrease={
                            showGoal
                              ? () =>
                                  Number(targetFirstInput) &&
                                  setTargetFirstInput((Number(targetFirstInput) - 1).toString())
                              : () => Number(firstInput) && setFirstInput((Number(firstInput) - 1).toString())
                          }
                          onClickIncrease={
                            showGoal
                              ? () => setTargetFirstInput((Number(targetFirstInput) + 1).toString())
                              : () => setFirstInput((Number(firstInput) + 1).toString())
                          }
                          value={showGoal ? targetFirstInput : firstInput}
                          unit={'hours'}
                          onChangeInput={showGoal ? setTargetFirstInput : setFirstInput}
                        />
                        <MobileSleepInput
                          onClickDecrease={
                            showGoal
                              ? () =>
                                  Number(targetSecondInput) &&
                                  setTargetSecondInput((Number(targetSecondInput) - 1).toString())
                              : () => Number(secondInput) && setSecondInput((Number(secondInput) - 1).toString())
                          }
                          onClickIncrease={() =>
                            showGoal
                              ? setTargetSecondInput((Number(targetSecondInput) + 1).toString())
                              : setSecondInput((Number(secondInput) + 1).toString())
                          }
                          value={showGoal ? targetSecondInput : secondInput}
                          unit={'minutes'}
                          onChangeInput={showGoal ? setTargetSecondInput : setSecondInput}
                        />
                      </Box>
                    ) : metric.metricUnit === METRICS_UNIT.NOT_AVAILABLE && metric ? (
                      <Box sx={{ flex: 1 }}>
                        <CustomSlider
                          label={showGoal ? 'Target value' : metric.title}
                          value={showGoal ? targetSlideValue : sliderValue}
                          onChange={showGoal ? setTargetSlideValue : setSliderValue}
                          min={1}
                          max={metric.maxValue}
                        />
                      </Box>
                    ) : (
                      <InputField
                        label={
                          showGoal
                            ? 'Enter target value'
                            : `${metric.title}${
                                metric.metricUnit in unitsLabel ? ` (${unitsLabel[metric.metricUnit]})` : ''
                              }`
                        }
                        setValue={(el) =>
                          inputNumberValidation(el.target.value, showGoal ? setTargetFirstInput : setFirstInput)
                        }
                        name="steps"
                        type="number"
                        value={showGoal ? targetFirstInput : firstInput}
                        placeholder={`Enter ${
                          metric.metricUnit in unitsLabel
                            ? unitsLabel[metric.metricUnit]
                            : metric.metricUnit === METRICS_UNIT.STEPS
                            ? 'steps'
                            : 'value'
                        }`}
                        inputProps={{
                          sx: {
                            marginRight: '16px',
                            '& .MuiInputLabel-root': {
                              marginTop: '-28px',
                              '&.Mui-disabled': {
                                marginTop: '-28px',
                              },
                            },
                            '& input[type=number]': {
                              '-moz-appearance': 'textfield',
                            },
                            '& input[type=number]::-webkit-outer-spin-button': {
                              '-webkit-appearance': 'none',
                              margin: 0,
                            },
                            '& input[type=number]::-webkit-inner-spin-button': {
                              '-webkit-appearance': 'none',
                              margin: 0,
                            },
                          },
                        }}
                      />
                    )}
                  </Box>
                )}
                <Box mt="32px">
                  {!showGoal && (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <CustomButton
                        type="bigMagenta"
                        text={!isCreateMetric && hasGoal ? 'Edit a goal' : 'Add a goal'}
                        sx={{ height: '38px' }}
                        inputProps={{
                          variant: 'contained',
                          startIcon: !hasGoal && <PlusIcon width={16} height={16} />,
                        }}
                        onClick={() => setShowGoal(true)}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            ) : (
              isCreateMetric && (
                <>
                  <Box>
                    <InputField
                      label="Metric name"
                      setValue={(el) => setFirstInput(el.target.value)}
                      name="metric-name"
                      type="text"
                      value={firstInput}
                      placeholder={`Enter metric name`}
                      inputProps={{
                        sx: {
                          mb: '40px',
                          marginRight: '16px',
                          '& .MuiInputLabel-root': {
                            marginTop: '-28px',
                            '&.Mui-disabled': {
                              marginTop: '-28px',
                            },
                          },
                          '& input[type=number]': {
                            '-moz-appearance': 'textfield',
                          },
                          '& input[type=number]::-webkit-outer-spin-button': {
                            '-webkit-appearance': 'none',
                            margin: 0,
                          },
                          '& input[type=number]::-webkit-inner-spin-button': {
                            '-webkit-appearance': 'none',
                            margin: 0,
                          },
                        },
                      }}
                    />
                    <InputField
                      label="Current value"
                      setValue={(el) => inputNumberValidation(el.target.value, setSecondInput)}
                      name="value"
                      type="number"
                      value={secondInput}
                      placeholder="Enter value"
                      inputProps={{
                        sx: {
                          ...(showGoal && { mb: '40px' }),
                          marginRight: '16px',
                          '& .MuiInputLabel-root': {
                            marginTop: '-28px',
                            '&.Mui-disabled': {
                              marginTop: '-28px',
                            },
                          },
                          '& input[type=number]': {
                            '-moz-appearance': 'textfield',
                          },
                          '& input[type=number]::-webkit-outer-spin-button': {
                            '-webkit-appearance': 'none',
                            margin: 0,
                          },
                          '& input[type=number]::-webkit-inner-spin-button': {
                            '-webkit-appearance': 'none',
                            margin: 0,
                          },
                        },
                      }}
                    />
                    {showGoal && (
                      <InputField
                        label="Enter target value"
                        setValue={(el) => inputNumberValidation(el.target.value, setTargetFirstInput)}
                        name="gaol"
                        type="number"
                        value={targetFirstInput}
                        placeholder="Enter goal"
                        inputProps={{
                          sx: {
                            marginRight: '16px',
                            '& .MuiInputLabel-root': {
                              marginTop: '-28px',
                              '&.Mui-disabled': {
                                marginTop: '-28px',
                              },
                            },
                            '& input[type=number]': {
                              '-moz-appearance': 'textfield',
                            },
                            '& input[type=number]::-webkit-outer-spin-button': {
                              '-webkit-appearance': 'none',
                              margin: 0,
                            },
                            '& input[type=number]::-webkit-inner-spin-button': {
                              '-webkit-appearance': 'none',
                              margin: 0,
                            },
                          },
                        }}
                      />
                    )}
                  </Box>
                  <Box mt="32px">
                    {!showGoal ? (
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CustomButton
                          type="bigMagenta"
                          text="Add a goal"
                          sx={{ height: '38px' }}
                          inputProps={{
                            variant: 'contained',
                            startIcon: <PlusIcon width={16} height={16} />,
                          }}
                          onClick={() => setShowGoal(true)}
                        />
                      </Box>
                    ) : (
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CustomButton
                          type="bigMagenta"
                          text="Cancel"
                          sx={{ height: '38px' }}
                          inputProps={{
                            variant: 'contained',
                          }}
                          onClick={() => setShowGoal(false)}
                        />
                      </Box>
                    )}
                  </Box>
                </>
              )
            )}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <CustomButton
              type="bigBlue"
              text="Save"
              disabled={isUpdating}
              minWidth="160px"
              sx={{
                padding: '10px 16px',
              }}
              inputProps={{
                variant: 'contained',
                startIcon: <SaveIcon width={16} height={16} fill={Colors.white} />,
              }}
              onClick={isCreateMetric ? () => handleCreateMetric(true) : handleMobileSave(showGoal)}
            />
            <CustomButton
              type="bigLightBlue"
              text={showGoal && !isCreateMetric && hasGoal ? 'Remove' : 'Cancel'}
              disabled={isUpdating}
              minWidth="160px"
              sx={{ padding: '10px 16px' }}
              inputProps={{
                variant: 'contained',
                ...(showGoal && !isCreateMetric && hasGoal && { startIcon: <DeleteIcon fill={Colors.blue[500]} /> }),
              }}
              onClick={showGoal && !isCreateMetric && hasGoal ? handleMobileSave(true, true) : closeLogData}
            />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
