import { Box, Typography } from '@mui/material';
import { Colors } from '../../../theme/colors';
import { ReactComponent as EmptyNotificationsIcon } from '../../../assets/svg/feature/emptyNotifications.svg';
import React from 'react';
import { SxProps } from '@mui/system';

type Props = {
  sx?: SxProps;
};
export const EmptyMessageList: React.FC<Props> = ({ sx }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: '24px', ...sx }}>
    <EmptyNotificationsIcon />
    <Typography
      sx={{
        fontSize: '18px',
        lineHeight: '18px',
        color: Colors.gray[200],
        fontWeight: 'bold',
        mt: '32px',
      }}
    >
      No notifications yet
    </Typography>
  </Box>
);
