import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ReactComponent as CloseIcon } from '../../../assets/svg/feature/close.svg';
import { ReactComponent as PlusIcon } from '../../../assets/svg/plus.svg';
import { Colors } from '../../../theme/colors';
import { CustomButton } from '../CustomButton';
import { useIonRouter } from '@ionic/react';
import { Challenge } from '../../../models/Challenge';
import Grid from '@mui/material/Grid';
import theme from '../../../theme';
import { CustomInfoDialog } from './CustomInfoDialog';
import { PublishForm } from '../forms/PublishForm/PublishForm';
import { usePublishHandle } from '../../../pages/TemplatesAdminPage/usePublishHandle';
import { COMPETITION } from '../../../models/enum/COMPETITION';
import { AWARD_TRIGGER } from '../../../models/enum/AWARD_TRIGGER';
import { GROUP_CATEGORY } from '../../../models/enum/GROUP_CATEGORY';

interface ModalProps {
  show: boolean;
  showMore?: boolean;
  challenge: Challenge | null;
  loading: boolean;
  onClose: () => void;
  onPublish: () => void;
}

export const PublishModal: FC<ModalProps> = ({
  show = false,
  showMore = true,
  challenge,
  loading,
  onClose,
  onPublish,
}) => {
  const router = useIonRouter();
  const [isTeamChallenge, setIsTeamChallenge] = useState(false);
  const [showNumberOfWinners, setShowNumberOfWinners] = useState(false);
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const { ...publishProps } = usePublishHandle(challenge, onPublish);

  useEffect(() => {
    setIsTeamChallenge(challenge?.challengeCharacteristic.competition === COMPETITION.TEAM);
    setShowNumberOfWinners(
      challenge?.challengeAward?.awardTrigger === AWARD_TRIGGER.TOP ||
        challenge?.challengeAward?.awardTrigger === AWARD_TRIGGER.FASTEST,
    );
  }, [challenge]);

  const handleOpenLink = () => {
    if (!challenge) return;
    onClose();
    router.push(`/page/employees-challenges/challenge-templates/template/${challenge.metadata.templateChallengeUUID}`);
  };

  return (
    <CustomInfoDialog show={show}>
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 2,
            background: 'rgba(65, 74, 79, 0.3)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress color="inherit" style={{ width: 30, height: 30 }} />
        </Box>
      )}

      <DialogTitle display={'flex'} flexDirection={'column'} sx={{ padding: '16px 16px 0 16px' }}>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <IconButton sx={{ width: 28, height: 28, backgroundColor: Colors.gray[50] }} onClick={onClose}>
            <CloseIcon style={{ width: 12, height: 12 }} fill={Colors.gray[400]} />
          </IconButton>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mt: '6px', maxWidth: '296px', mx: 'auto' }}
        >
          <Typography
            variant="h5"
            sx={{ textAlign: 'center', color: Colors.gray[700], fontWeight: 'bold', fontSize: 18 }}
          >
            {`Publish ${challenge?.challengeInfo.title}`}
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent
        sx={{
          padding: '0 16px 0 16px',
          height: 'auto',
          '&::-webkit-scrollbar-track': { background: '#F0F2F3!important' },
          '&::-webkit-scrollbar-thumb': { background: '#D3D7DA!important' },
          '&::-webkit-scrollbar-track:hover': { background: '#F0F2F3!important' },
          '*::-webkit-scrollbar': {
            width: '5px!important',
            height: '5px !important',
          },
        }}
      >
        <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" sx={{ mt: '10px' }}>
          <Typography
            variant="h5"
            sx={{ maxWidth: '335px', textAlign: 'center', color: Colors.gray[500], fontWeight: 400, fontSize: 16 }}
          >
            Are you sure you want to publish this challenge?
          </Typography>

          {showMore && (
            <Link sx={{ fontSize: 12, color: Colors.blue[500], cursor: 'pointer' }} onClick={handleOpenLink}>
              More details...
            </Link>
          )}

          <Grid sx={{ width: '335px', position: 'relative', marginTop: '14px' }}>
            <PublishForm
              loading={false}
              {...publishProps}
              isTeam={isTeamChallenge}
              isCorporate={Boolean(
                challenge && challenge.challengeCharacteristic.groupCategory === GROUP_CATEGORY.CORPORATE,
              )}
              showNumberOfWinners={showNumberOfWinners}
            />
          </Grid>
        </Box>
      </DialogContent>

      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{ margin: mdDown ? '28px auto 40px auto' : '49px auto 40px auto', maxWidth: 325, width: '100%' }}
      >
        <CustomButton
          disabled={
            publishProps.getDisabledPeriod() ||
            (showNumberOfWinners ? !Boolean(publishProps.publishForm.winners) : false) ||
            (isTeamChallenge ? !Boolean(publishProps.publishForm.teamSize) : false)
          }
          type={'bigOrange'}
          text={'Publish'}
          inputProps={{
            variant: 'contained',
            startIcon: <PlusIcon width={16} height={16} />,
          }}
          onClick={publishProps.publish}
        />
        <CustomButton type={'bigLightOrange'} text={'Cancel'} onClick={onClose} />
      </Box>
    </CustomInfoDialog>
  );
};
