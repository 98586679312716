import React from 'react';
import { ModalCatalogPrams } from '../types';
import { AltRewardCatalog } from '../../../models/Rewards';
import { Box, Modal, Typography } from '@mui/material';
import { Colors } from '../../../theme/colors';
import CustomDropdown from '../../../components/common/CustomDropdown';
import { CustomButton } from '../../../components/features/CustomButton';
import { ReactComponent as BulbIcon } from '../../../assets/svg/feature/bulb.svg';
import { ReactComponent as CloseIcon } from '../../../assets/svg/feature/close.svg';

type CatalogModalProps = {
  openModal: boolean;
  modalParams?: ModalCatalogPrams;
  catalog?: AltRewardCatalog;
  selectedBrandId: string;
  selectedReward: string;
  closeCatalogModal: () => void;
  onChangeReward: (brandId: string) => (val: string) => void;
  onSubmitReward: () => void;
};

export const CatalogModal: React.FC<CatalogModalProps> = ({
  openModal,
  modalParams,
  catalog,
  selectedBrandId,
  selectedReward,
  closeCatalogModal,
  onChangeReward,
  onSubmitReward,
}) => {
  return (
    <Modal
      open={openModal}
      onClose={closeCatalogModal}
      sx={{ display: 'flex', alignItems: { xs: 'flex-end', table: 'center' }, justifyContent: 'center' }}
    >
      <Box
        sx={{
          background: Colors.white,
          borderRadius: { xs: '20px 20px 0 0', sm: '20px' },
          pt: '50px',
          position: 'relative',
          height: { xs: 'calc(100vh - 64px)', sm: 'unset' },
        }}
      >
        <Box
          component="button"
          sx={{
            position: 'absolute',
            top: { xs: '24px', table: '16px' },
            right: '16px',
            height: 28,
            width: 28,
            padding: 0,
            borderRadius: 38,
            background: '#F0F2F3',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 'auto',
          }}
          onClick={closeCatalogModal}
        >
          <CloseIcon fill={Colors.gray[400]} />
        </Box>
        <Typography
          sx={{
            fontSize: '18px',
            textAlign: 'center',
            fontWeight: '600',
            color: Colors.gray[700],
            mb: { xs: '16px', table: '24px' },
          }}
        >
          Awards
        </Typography>
        <Box
          sx={{
            width: { xs: 'unset', sm: '100%' },
            maxWidth: { xs: 'unset', sm: '784px' },
            ml: { xs: '16px', sm: '32px' },
            mr: { xs: '16px', sm: '32px' },
            mb: '40px',
          }}
        >
          <Box sx={{ overflow: 'scroll' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                width: '100%',
                height: { xs: '384px', sm: '100%' },
              }}
            >
              {modalParams &&
                catalog &&
                catalog[modalParams.country].map((item, idx) => {
                  const isMultiple = item.brandItems.length > 1;
                  if (isMultiple) {
                    return (
                      <Box
                        key={item.brandId}
                        sx={{ width: { xs: 'calc((100vw - 32px - 20px)/2)', sm: '30%' }, mb: '24px' }}
                      >
                        <Box
                          sx={{
                            width: '100%',
                            height: { xs: '100px', sm: '156px' },
                            overflow: 'hidden',
                            background: Colors.gray[200],
                            borderRadius: '20px',
                            ...(selectedBrandId === item.brandId && { border: `2px solid ${Colors.blue[400]}` }),
                            mb: '16px',
                            objectFit: 'cover',
                            '& img': {
                              height: '100%',
                            },
                          }}
                        >
                          <Box component="img" src={item.rewardImageUrl} />
                        </Box>
                        <CustomDropdown
                          fullWidth
                          placeholder="-"
                          selected={selectedReward}
                          setSelected={onChangeReward(item.brandId)}
                          list={item.brandItems.map((item) => item.rewardName)}
                        />
                      </Box>
                    );
                  }
                  return (
                    <Box
                      key={item.brandId}
                      sx={{
                        width: { xs: 'calc((100vw - 32px - 20px)/2)', sm: '30%' },
                        mb: '24px',
                        cursor: 'pointer',
                        mr: '24px',
                      }}
                      onClick={() => onChangeReward(item.brandId)(item.brandItems[0].rewardName)}
                    >
                      <Box
                        sx={{
                          width: '100%',
                          height: { xs: '100px', sm: '156px' },
                          overflow: 'hidden',
                          background: Colors.gray[200],
                          ...(selectedReward === item.brandItems[0].rewardName && {
                            border: `2px solid ${Colors.blue[400]}`,
                          }),
                          borderRadius: '20px',
                          objectFit: 'cover',
                          '& img': {
                            height: '100%',
                          },
                        }}
                        component="img"
                        src={item.rewardImageUrl}
                      />
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '20px',
                            width: '20px',
                            borderRadius: '50%',
                            border: `1px solid ${Colors.blue[500]}`,
                            mr: '10px',
                          }}
                        >
                          {selectedReward === item.brandItems[0].rewardName && (
                            <Box
                              sx={{ height: '10px', width: '10px', borderRadius: '50%', background: Colors.blue[500] }}
                            />
                          )}
                        </Box>
                        <Typography sx={{ flex: 1, fontSize: '12px' }}>{item.brandItems[0].rewardName}</Typography>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              background: Colors.green[50],
              padding: '8px 16px',
              borderRadius: '30px',
              mb: '32px',
            }}
          >
            <BulbIcon fill={Colors.green[600]} />
            <Typography sx={{ flex: 1, fontSize: '12px', color: Colors.green[600], ml: '8px' }}>
              {`The merchants represented are not sponsors of the rewards or otherwise affiliated with this company. The logos and other identifying marks attached are trademarks of and owned by each represented company and/or its affiliates. Please visit each company's website for additional terms and conditions.`}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', ml: 'auto', mr: 'auto' }}>
            <CustomButton text="Proceed" width="150px" sx={{ mr: '16px' }} type="bigOrange" onClick={onSubmitReward} />
            <CustomButton text="Cancel" width="150px" type="bigLightOrange" onClick={closeCatalogModal} />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
