import React, { useState, useRef, useLayoutEffect } from 'react';
import { Box, Card, CardContent, CardMedia, SxProps, Theme, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconPlaceholder from '../../../assets/defaultBlog.png';
import { ReactComponent as ClockIcon } from '../../../assets/svg/clock.svg';
import { ReactComponent as PlusIcon } from '../../../assets/svg/plus.svg';
import { CustomAvatar } from '../CustomAvatar';
import { CardLabel } from '../CardLabel';
import { Challenge } from '../../../models/Challenge';
import { DIMENSION_LIST } from '../../../models/enum/DIMENTION_LIST';
import { COMPETITION_LIST } from '../../../models/enum/COMPETITION_LIST';
import { styles } from './styles';
import { Colors } from '../../../theme/colors';
import { daysConverter } from '../../../utils/daysConverter';
import moment from 'moment';
import { CustomButton } from '../CustomButton';
import { useIonRouter } from '@ionic/react';
import { getTooltipTitle } from '../../../utils/challengeCardTooltip';
import { CHALLENGE_TYPE_LIST } from '../../../models/enum/CHALLENGE_TYPE_LIST';

type Props = {
  challenge: Challenge;
  sx?: SxProps<Theme>;
  handlePublish: () => void;
};
export const ChallengeTemplateCard: React.FC<Props> = ({ challenge, sx, handlePublish }) => {
  const [open, setOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const router = useIonRouter();
  const handleOpenLink = () =>
    router.push(`/page/employees-challenges/challenge-templates/template/${challenge.metadata.templateChallengeUUID}`);

  const start = moment(challenge?.challengeDuration.startDate, 'YYYY-MM-DD');
  const end = moment(challenge?.challengeDuration.endDate, 'YYYY-MM-DD');
  const days = moment.duration(end.diff(start)).asDays().toFixed(0);
  const durationInDays =
    challenge && challenge.challengeDuration.suggestedDuration
      ? challenge.challengeDuration.suggestedDuration
      : daysConverter(Number(days) + 1);

  const titleRef = useRef<HTMLSpanElement>(null);
  const timeoutRef = useRef<any>();

  useLayoutEffect(() => {
    if (titleRef.current && titleRef.current.scrollHeight > titleRef.current.clientHeight) {
      setShowTooltip(true);
    }
  }, []);

  return (
    <Box sx={{ position: 'relative', height: '100%', flex: 1, cursor: 'pointer' }} onClick={handleOpenLink}>
      <Card sx={[styles.cardContainer, ...(Array.isArray(sx) ? sx : [sx])]}>
        <CardMedia
          sx={{
            flexShrink: 0,
            height: 140,
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
            background: `linear-gradient(180deg, rgba(0, 0, 0, 0.4) 18.68%, rgba(0, 0, 0, 0) 47.54%)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
          image={challenge.challengeInfo.imageUrl ?? IconPlaceholder}
        />

        <CardLabel challenge={challenge} sxContainer={styles.labelStatusPosition} />

        <CardContent sx={styles.cardContent}>
          <Box marginTop="0px" display="flex" flexDirection="column" justifyContent="center">
            <Box
              marginTop="0px"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              onTouchStart={(e) => {
                clearTimeout(timeoutRef.current);
                setOpen(!open);
                timeoutRef.current = setTimeout(() => setOpen(false), 1500);
              }}
            >
              {challenge.challengeInfo.title && (
                <Tooltip
                  open={open}
                  onMouseEnter={() => setOpen(true)}
                  onMouseLeave={() => setOpen(false)}
                  title={showTooltip && challenge.challengeInfo.title}
                >
                  <Typography
                    className={showTooltip ? 'tooltip-header' : ''}
                    ref={titleRef}
                    variant="caption"
                    sx={styles.cardTitle}
                  >
                    {challenge.challengeInfo.title}
                  </Typography>
                </Tooltip>
              )}
            </Box>
          </Box>

          <Grid container wrap={'wrap'}>
            <Grid sx={{ display: 'flex', flexWrap: 'no-wrap', alignItems: 'center', pt: '7px' }}>
              {challenge.challengeCharacteristic.challengePillars.map((challengePillar) => {
                return DIMENSION_LIST.map((item, index) =>
                  item.value === challengePillar ? (
                    <Tooltip key={item.value} title={getTooltipTitle(challengePillar)}>
                      <Grid
                        sx={{
                          mr:
                            challenge.challengeCharacteristic.challengePillars.length > 1 &&
                            index !== challenge.challengeCharacteristic.challengePillars.length - 1
                              ? '4px'
                              : 0,
                        }}
                        item
                      >
                        <CustomAvatar size={24} bgColor={item.mainColor} borderBg={item.borderColor} borderWidth={1}>
                          <item.Icon width={12} height={12} />
                        </CustomAvatar>
                      </Grid>
                    </Tooltip>
                  ) : null,
                );
              })}
              {challenge.challengeCharacteristic.challengePillars.length > 1 && (
                <Box sx={{ borderRight: '1px dashed #F2F2F2', height: '16px', width: '1px', ml: '4px' }} />
              )}
            </Grid>

            {COMPETITION_LIST.map((item) =>
              item.value === challenge.challengeCharacteristic.competition ? (
                <Tooltip key={item.value} title={getTooltipTitle(challenge.challengeCharacteristic.competition)}>
                  <Grid item sx={{ ml: '8px', mr: '8px', pt: '7px' }}>
                    <CustomAvatar size={24} bgColor={item.mainColor} borderBg={item.borderColor} borderWidth={1}>
                      <item.Icon width={12} height={12} />
                    </CustomAvatar>
                  </Grid>
                </Tooltip>
              ) : null,
            )}

            {CHALLENGE_TYPE_LIST.map((item) =>
              item.value === challenge.challengeCharacteristic.challengeType ? (
                <Tooltip key={item.value} title={getTooltipTitle(challenge.challengeCharacteristic.challengeType)}>
                  <Grid item sx={{ ml: '8px', mr: '8px', pt: '7px' }}>
                    <CustomAvatar size={24} bgColor={Colors.blue[800]} borderBg={Colors.blue[600]} borderWidth={1}>
                      <item.Icon fill={Colors.white} width={12} height={12} />
                    </CustomAvatar>
                  </Grid>
                </Tooltip>
              ) : null,
            )}

            <Grid
              item
              container
              alignItems={'center'}
              justifyContent={'flex-start'}
              wrap={'nowrap'}
              sx={{ pt: '7px', width: 'auto' }}
            >
              <ClockIcon width={16} height={16} fill={Colors.gray[400]} />
              <Typography color={Colors.gray[400]} sx={{ fontSize: '12px', lineHeight: 1.1, marginLeft: '4px' }}>
                {durationInDays}
              </Typography>
            </Grid>

            <Grid
              item
              container
              wrap={'nowrap'}
              justifyContent={challenge.participantsAvatars ? 'space-between' : 'flex-end'}
              sx={{ marginTop: '16px' }}
            >
              <CustomButton
                type={'bigOrange'}
                text={'Publish'}
                width={'117px'}
                inputProps={{
                  variant: 'contained',
                  startIcon: <PlusIcon width={16} height={16} />,
                }}
                sx={{ height: 36, fontSize: '14px', lineHeight: '14px' }}
                onClick={(e) => {
                  e.stopPropagation();
                  handlePublish();
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};
