import React from 'react';
import { Box, FormControl, MenuItem, OutlinedInput, Select, styled, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const MenuProps = {
  PaperProps: {
    style: {
      padding: '4px 8px',
      maxHeight: '400px',
      borderRadius: '20px',
      marginTop: '8px',
      boxShadow: 'var(--shadow-button)',
    },
  },
};

const StyledSelect = styled(Select)({
  fontSize: 14,
  fontWeight: 700,
  textOverflow: 'ellipsis',
  background: 'white',
  borderRadius: '999px',
  paddingInline: '8px',
  boxShadow: 'var(--shadow-button)',
  '& *': {
    border: 'none !important',
  },
});

const StyledMenuItem = styled(MenuItem)({
  mb: '4px',
  fontSize: '14px',
  borderRadius: '20px',
  fontWeight: '700',
  '&.Mui-selected': {
    backgroundColor: '#E2E8ED',
    fontWeight: '900',
  },
});

type CustomDropdownProps = {
  width?: number | string;
  label?: string;
  fullWidth?: boolean;
  small?: boolean;
  placeholder?: string;
  disabled?: boolean;
  list: string[];
  selected: string;
  setSelected: (s: string) => void;
  lowercase?: boolean;
};

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  width = 300,
  label,
  fullWidth,
  small,
  placeholder,
  list,
  disabled,
  selected,
  setSelected,
  lowercase = false,
}) => {
  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setSelected(value);
  };

  return (
    <div>
      <FormControl
        sx={{
          width: fullWidth ? '100%' : width,
          position: 'relative',
          textTransform: 'capitalize',
        }}
        disabled={disabled}
      >
        {label && (
          <Box sx={{ position: 'absolute', top: '-24px', left: '12px' }}>
            <Typography sx={{ fontWeight: 'bold', fontSize: 16, color: 'text.primary' }}>{label}</Typography>
          </Box>
        )}
        <StyledSelect
          displayEmpty
          value={selected}
          onChange={handleChange}
          input={<OutlinedInput />}
          MenuProps={MenuProps}
          IconComponent={ExpandMoreIcon}
          sx={{ height: small ? 36 : 40 }}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {placeholder && (
            <MenuItem disabled value="">
              <em>{placeholder}</em>
            </MenuItem>
          )}
          {list.map((item) => (
            <StyledMenuItem key={item} value={item} sx={{ textTransform: 'capitalize' }}>
              {lowercase ? item.toLowerCase() : item}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </div>
  );
};

export default CustomDropdown;
