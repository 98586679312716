import { IonContent, IonInfiniteScroll, IonInfiniteScrollContent, IonPage } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Skeleton, Switch, Typography, useMediaQuery, useTheme } from '@mui/material';
import EmployeesCard from '../components/EmployeesCard';
import {
  bambooIntegration,
  deleteEmployee,
  fetchMoreEmployees,
  getEmployees,
  getRegistrationLink,
  sendInvitations,
  uploadCSV,
} from '../redux/slices/employeesSlice';
import { RootState } from '../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { Employee } from '../models/Employee';
import GenericModal from '../components/modals/GenericModal';
import { useDebounce } from '../hooks/useDebounce';
import InvitationModal from '../components/modals/InvitationModal';
import useViewIsVisible from '../hooks/useViewIsVisible';
import { ANALYTICS_PAGE } from '../models/enum/ANALYTICS_PAGE';
import CustomNewHeader from '../components/features/CustomNewHeader';
import { Colors } from '../theme/colors';
import { ReactComponent as PlusIcon } from '../assets/svg/plus.svg';
import { ReactComponent as AZIcon } from '../assets/svg/feature/a-z.svg';
import { ReactComponent as SortIcon } from '../assets/svg/feature/sort.svg';
import { ReactComponent as GridIcon } from '../assets/svg/feature/grid.svg';
import { ReactComponent as ListIcon } from '../assets/svg/feature/list.svg';
import { ReactComponent as FilterIcon } from '../assets/svg/feature/filter.svg';
import CustomSearchbar from '../components/features/CustomSearchbar';
import { CustomButton } from '../components/features/CustomButton';
import { FilterSelector } from '../components/features/HeaderFilter/FilterSelector';
import { PreviewRoundItemProps } from '../models/Shared';
import FormControlLabel from '@mui/material/FormControlLabel';
import { changeEmployeeAccessRequest } from '../lib/api/http/requests/employee';
import { USER_ROLE } from '../models/enum/USER_ROLE';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ExpandMore } from '../components/features/HeaderFilter/HeaderFilter';
import { MobileFilterContainer } from '../components/features/HeaderFilter/MobileFilterContainer';
import { setShowCustomFab } from '../redux/slices/appSlice';
import UserService from '../services/UserService';

const sortingOptions = ['Recently Added', 'A -> Z'];
const sortingOptionList: PreviewRoundItemProps[] = [
  {
    value: 'A -> Z',
    label: 'A -> Z',
    Icon: AZIcon,
    mainColor: Colors.blue[800],
    backgroundColor: Colors.blue[850],
    borderColor: Colors.blue[600],
  },
  {
    value: 'Recently Added',
    label: 'Recently Added',
    Icon: SortIcon,
    mainColor: Colors.blue[800],
    backgroundColor: Colors.blue[850],
    borderColor: Colors.blue[600],
  },
];

export const enum VIEW_TYPE {
  GRID,
  LIST,
}
const viewOptionList: PreviewRoundItemProps[] = [
  {
    value: String(VIEW_TYPE.GRID),
    label: '',
    Icon: GridIcon,
    mainColor: Colors.blue[800],
    backgroundColor: Colors.blue[850],
    borderColor: Colors.blue[600],
  },
  {
    value: String(VIEW_TYPE.LIST),
    label: '',
    Icon: ListIcon,
    mainColor: Colors.blue[800],
    backgroundColor: Colors.blue[850],
    borderColor: Colors.blue[600],
  },
];

const EmployeesPage: React.FC = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const [viewType, setViewType] = useState<VIEW_TYPE>(VIEW_TYPE.GRID);
  const [expanded, setExpanded] = useState<boolean>(false);

  const [sort, setSort] = useState(sortingOptions[1]);
  const [onlyAdmins, setOnlyAdmins] = useState(false);
  const [search, setSearch] = useState('');
  const [employeeDeleteModal, setEmployeeDeleteModal] = useState<boolean>(false);
  const [refreshHelper, setRefreshHelper] = useState<number>(0);
  const [invitationModal, setInvitationModal] = useState<boolean>(false);
  const [lastAdminModal, setLastAdminModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<Employee | null>(null);
  const [page, setPage] = useState<number>(0);
  const actualSearch = useDebounce(search, 1000);
  const visible = useViewIsVisible(ANALYTICS_PAGE.EMPLOYEES);
  const [showResetFilters, setShowResetFilters] = useState(false);

  const { employees, employeesCount, maxUsers, remainingUsers, invitationLink } = useSelector(
    (state: RootState) => state.employees.data,
  );
  const { employeesLoading } = useSelector((state: RootState) => state.employees.meta);
  const userUUID: string = UserService.getMineId();

  useEffect(() => {
    if (!visible) return;

    setPage(0);
    dispatch(
      getEmployees({
        searchText: actualSearch,
        sort: sort === sortingOptions[0] ? 'userProfile.registrationDate,desc' : 'name,asc',
        onlyAdmins: onlyAdmins,
      }),
    );
    // TODO temporarily commented WEl-183
    // dispatch(getInvitationInfo());
  }, [dispatch, actualSearch, sort, onlyAdmins, refreshHelper, visible]);

  // TODO temporarily commented WEl-183
  // useEffect(() => {
  //   if (!visible) return;
  //
  //   dispatch(getInvitationInfo());
  // }, [dispatch, invitationModal, employeeDeleteModal, visible]);

  useEffect(() => {
    if (!visible) return;

    dispatch(getRegistrationLink());
  }, [dispatch, visible]);

  useEffect(() => {
    if (onlyAdmins) {
      setShowResetFilters(true);

      return;
    }
    setShowResetFilters(false);
  }, [onlyAdmins]);

  useEffect(() => {
    if (xsDown && expanded) {
      dispatch(setShowCustomFab(false));
      return;
    }
    dispatch(setShowCustomFab(true));
  }, [xsDown, expanded]);

  const handleOpenDeleteModal = (selected: Employee) => {
    if (selected.last_admin === true) {
      setLastAdminModal(true);
    } else {
      setSelectedUser(selected);
      setEmployeeDeleteModal(true);
    }
  };

  const handleDeleteUser = () => {
    setEmployeeDeleteModal(false);
    if (selectedUser) {
      dispatch(
        deleteEmployee({ body: { uuid: selectedUser.emp_uuid }, callback: () => setRefreshHelper(refreshHelper + 1) }),
      );
    }
  };

  const handleAccess = async (userRole: USER_ROLE, empUUID: string) => {
    try {
      await changeEmployeeAccessRequest({ userRole, empUUID });
      setRefreshHelper(refreshHelper + 1);
    } catch (e) {}
  };

  const handleFetchMoreEmployees = (ev: any) => {
    dispatch(
      fetchMoreEmployees({
        page: page + 1,
        searchText: actualSearch,
        sort: sort === sortingOptions[0] ? 'userProfile.registrationDate,desc' : 'name,asc',
        onlyAdmins: onlyAdmins,
        callback: () => ev.target.complete(),
      }),
    );
    setPage(page + 1);
  };

  const handleSendInvitations = (emailList: string[], file: any) => {
    if (emailList.length) {
      dispatch(sendInvitations({ emailList }));
      return;
    }
    if (file) dispatch(uploadCSV(file));
  };

  const handleBambooHRSubmit = (clientDomain: string, apiKey: string) => {
    dispatch(bambooIntegration({ clientDomain, apiKey }));
  };
  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOnlyAdmins(event.target.checked);
  };
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const returnEmployees = () => {
    if (employeesLoading && !employees.length) {
      return (
        <Grid container spacing={4} sx={{ mt: 1 }}>
          {[1, 1, 1, 1].map((employee, index) => (
            <Grid key={index} item xs={12} sm={viewType ? 12 : 6} md={viewType ? 6 : 3}>
              <Skeleton variant="rectangular" height={viewType ? 74 : 274} width="100%" sx={{ borderRadius: '8px' }} />
            </Grid>
          ))}
        </Grid>
      );
    } else if (employees.length)
      return (
        <Grid container spacing={xsDown ? 2 : 3} sx={{ my: xsDown ? 0 : 2 }}>
          {employees.map((employee: Employee) => (
            <Grid key={employee.emp_uuid} item xs={12} sm={viewType ? 12 : 6} md={viewType ? 6 : 3}>
              <EmployeesCard
                viewType={viewType}
                admin={employee.admin}
                name={`${employee.first_name || ''} ${employee.last_name || ''}`}
                icon={employee.avatar}
                email={employee.email || ''}
                isCurrent={userUUID === employee.emp_uuid}
                onGrantAccess={() => handleAccess(USER_ROLE.ADMIN, employee.emp_uuid)}
                onRevokeAccess={() => handleAccess(USER_ROLE.USER, employee.emp_uuid)}
                onDeleteClick={() => handleOpenDeleteModal(employee)}
              />
            </Grid>
          ))}
          <IonInfiniteScroll
            onIonInfinite={handleFetchMoreEmployees}
            threshold="100px"
            disabled={employees.length >= employeesCount}
          >
            <IonInfiniteScrollContent
              style={{ paddingTop: 16 }}
              loadingSpinner={null}
              loadingText="Loading more data..."
            />
          </IonInfiniteScroll>
        </Grid>
      );
    else
      return (
        <Grid container spacing={4} sx={{ my: 2 }} justifyContent={'center'}>
          <Grid item>
            <Typography
              variant="caption"
              textAlign="center"
              sx={{ padding: 10, fontSize: 16 }}
            >{`No results`}</Typography>
          </Grid>
        </Grid>
      );
  };

  const getResponsiveHeader = () => {
    if (xsDown) {
      return (
        <Grid sx={{ display: 'flex' }}>
          <CustomSearchbar
            width={mdDown ? '100%' : 320}
            searchInput={search}
            setSearchInput={setSearch}
            handleSearch={() => undefined}
          />
          <Grid
            onClick={handleExpandClick}
            container
            flexWrap={'nowrap'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{
              background: Colors.gray[100],
              borderRadius: '30px',
              maxWidth: '106px',
              padding: '2px',
              marginLeft: '16px',
              cursor: 'pointer',
            }}
          >
            {viewType ? (
              <ListIcon width={16} height={16} style={{ margin: '0 0 0 14px' }} fill={Colors.gray[400]} />
            ) : (
              <GridIcon width={16} height={16} style={{ margin: '0 0 0 14px' }} fill={Colors.gray[400]} />
            )}

            <Box
              sx={{
                display: 'block',
                height: 16,
                width: '1px',
                ml: '8px',
                mr: '8px',
                background: Colors.gray[150],
              }}
            />
            <FilterIcon width={16} height={16} style={{ margin: '0 8px 0 0' }} fill={Colors.gray[400]} />

            <Grid item>
              <ExpandMore
                expand={expanded}
                aria-expanded={expanded}
                aria-label="show more"
                sx={{
                  background: showResetFilters ? Colors.orange[500] : Colors.gray[150],
                  border: showResetFilters ? '1px solid #FCCF79' : '1px solid #E9EDEF',
                  color: showResetFilters ? Colors.white : Colors.gray[500],
                  '&:hover': {
                    background: showResetFilters ? Colors.orange[500] : Colors.gray[150],
                  },
                }}
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </Grid>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <CustomSearchbar
            width={mdDown ? '100%' : 320}
            searchInput={search}
            setSearchInput={setSearch}
            handleSearch={() => undefined}
          />
          <CustomButton
            text={'Invite users'}
            type={'bigOrange'}
            inputProps={{
              variant: 'contained',
              startIcon: <PlusIcon width={16} height={16} />,
            }}
            sx={{ height: '36px', ml: '16px' }}
            onClick={() => setInvitationModal(true)}
          />
        </Grid>
      );
    }
  };
  return (
    <IonPage>
      <CustomNewHeader
        label={'Your Employees'}
        tooltip={
          'Invite your employees to the Wellics platform, see those already registered and delete the ones that should not have access to the platform anymore.'
        }
      >
        {getResponsiveHeader()}
      </CustomNewHeader>
      {!xsDown && (
        <Box sx={{ background: Colors.gray[50], paddingBottom: '16px' }}>
          <Container maxWidth={'lg'}>
            <Grid
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', table: 'row' },
                alignItems: { xs: 'flex-start', table: 'center' },
                justifyContent: 'space-between',
              }}
            >
              <Grid sx={{ display: 'flex' }}>
                <FilterSelector
                  data={viewOptionList}
                  selected={[viewType.toString()]}
                  onClickItem={(value: string, isSelected: boolean) => () => setViewType(Number(value))}
                  sx={{ mr: '11px' }}
                />
                <FilterSelector
                  data={sortingOptionList}
                  selected={[sort]}
                  onClickItem={(value: string, isSelected: boolean) => () => setSort(value)}
                />
              </Grid>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  alignItems: 'center',
                  flexShrink: 0,
                }}
              >
                <FormControlLabel
                  sx={{ fontSize: '14px', color: Colors.gray[500], ml: { xs: 0, sm: '16px' } }}
                  label="Only Admins"
                  labelPlacement="start"
                  control={
                    <Switch
                      checked={onlyAdmins}
                      onChange={handleSwitch}
                      sx={{
                        pl: '8px',
                        pr: '8px',
                        '& .MuiSwitch-switchBase': {
                          marginTop: '5px',
                          marginBottom: '5px',
                          transform: 'translateX(1px)',
                          opacity: 1,
                          '&.Mui-checked ': {
                            transform: 'translateX(14px)',
                            '&+.MuiSwitch-track': {
                              background: Colors.blue[600],
                              border: `1px solid ${Colors.blue[300]}`,
                            },
                          },
                        },
                        '& .MuiSwitch-track': {
                          width: 29,
                          height: 16,
                          borderRadius: 100,
                          background: Colors.gray[150],
                          border: '1px solid #E2E5E6',
                        },
                        '& .MuiSwitch-thumb': {
                          height: 12,
                          width: 12,
                          boxShadow: 'none',
                          background: Colors.white,
                        },
                      }}
                      value={false}
                    />
                  }
                />
              </Box>
            </Grid>
          </Container>
        </Box>
      )}
      <IonContent id="scrollableEmployees" style={{ '--padding-bottom': '42px' }}>
        <Container maxWidth="lg">
          {returnEmployees()}
          {xsDown && (
            <Box
              sx={{
                position: 'fixed',
                bottom: 30,
                left: 30,
                zIndex: 3,
              }}
            >
              <CustomButton
                text={'Invite users'}
                type={'bigOrange'}
                inputProps={{
                  variant: 'contained',
                  startIcon: <PlusIcon width={16} height={16} />,
                }}
                sx={{ height: '48px' }}
                onClick={() => setInvitationModal(true)}
              />
            </Box>
          )}
        </Container>
        <GenericModal
          open={employeeDeleteModal}
          onClose={() => setEmployeeDeleteModal(false)}
          title={`Delete ${selectedUser ? selectedUser.first_name || selectedUser.email : 'N/A'}`}
          confirmFunction={handleDeleteUser}
        />
        <InvitationModal
          open={invitationModal}
          onClose={() => setInvitationModal(false)}
          handleFunction={handleSendInvitations}
          handleBambooHRSubmit={handleBambooHRSubmit}
          maxUsers={maxUsers}
          remainingUsers={remainingUsers}
          invitationLink={invitationLink}
        />
        <GenericModal
          title={'ADMIN WARNING'}
          description={
            'You cannot delete this account because it is the only Admin for the organization. They must transfer their Admin role to another user and then proceed to this action.'
          }
          cancelText={'OK'}
          open={lastAdminModal}
          onClose={() => setLastAdminModal(false)}
        />
      </IonContent>
      {xsDown && (
        <MobileFilterContainer
          show={expanded}
          title={'View'}
          handleClose={handleExpandClick}
          short={true}
          action={
            <Box sx={{ mt: '50px' }}>
              <CustomButton type={'bigLightOrange'} text={'Close'} onClick={handleExpandClick} width={'100%'} />
            </Box>
          }
        >
          <Grid sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Grid sx={{ display: 'flex' }}>
              <FilterSelector
                data={sortingOptionList}
                selected={[sort]}
                oneLineMode={true}
                onClickItem={(value: string, isSelected: boolean) => () => setSort(value)}
              />
            </Grid>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                mt: '16px',
                width: 'calc(100% - 15px)',
              }}
            >
              <FilterSelector
                data={viewOptionList}
                selected={[viewType.toString()]}
                oneLineMode={true}
                onClickItem={(value: string, isSelected: boolean) => () => setViewType(Number(value))}
                sx={{ mr: '11px' }}
              />
              <FormControlLabel
                sx={{ fontSize: '14px', color: Colors.gray[500], ml: { xs: 0, sm: '16px' } }}
                label="Only Admins"
                labelPlacement="start"
                control={
                  <Switch
                    checked={onlyAdmins}
                    onChange={handleSwitch}
                    sx={{
                      pl: '8px',
                      pr: '8px',
                      '& .MuiSwitch-switchBase': {
                        marginTop: '5px',
                        marginBottom: '5px',
                        transform: 'translateX(1px)',
                        opacity: 1,
                        '&.Mui-checked ': {
                          transform: 'translateX(14px)',
                          '&+.MuiSwitch-track': {
                            background: Colors.blue[600],
                            border: `1px solid ${Colors.blue[300]}`,
                          },
                        },
                      },
                      '& .MuiSwitch-track': {
                        width: 29,
                        height: 16,
                        borderRadius: 100,
                        background: Colors.gray[150],
                        border: '1px solid #E2E5E6',
                      },
                      '& .MuiSwitch-thumb': {
                        height: 12,
                        width: 12,
                        boxShadow: 'none',
                        background: Colors.white,
                      },
                    }}
                    value={false}
                  />
                }
              />
            </Box>
          </Grid>
        </MobileFilterContainer>
      )}
    </IonPage>
  );
};

export default EmployeesPage;
