import React, { useEffect, useState } from 'react';
import { App, AppState, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { useIonRouter } from '@ionic/react';
import { getWearableInfo } from '../redux/slices/appSlice';
import UserService from '../services/UserService';
import { useDispatch } from 'react-redux';
import { handlerTrackEvent } from '../utils/handlerTrackEvent';

const AppListeners: React.FC = () => {
  const [isActive, setIsActive] = useState(false);
  const router = useIonRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    const appUrlOpenFunction = (event: URLOpenListenerEvent) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const redirectUrl = process.env.REACT_APP_REDIRECT_URL!;
      const slug = event?.url?.split(redirectUrl).pop();
      console.log('App url open:', slug);
      if (slug) {
        router.push(slug);
      }
    };

    const appStateChangeFunction = (state: AppState) => {
      if (Capacitor.getPlatform() === 'ios') {
        const isActive = state?.isActive;
        console.log('App state changed. Active:', isActive);
        if (isActive && UserService.isLoggedIn()) {
          const uuid = UserService.getMineId();
          UserService.updateToken(() => dispatch(getWearableInfo({ uuid, initTerra: true })));
          handlerTrackEvent({ type: 'FOREGROUND' });
        }
      }
      if (Capacitor.getPlatform() === 'android') {
        setIsActive(state?.isActive);
      }
    };

    const appUrlOpenListener = App.addListener('appUrlOpen', appUrlOpenFunction);
    const appStateChangeListener = App.addListener('appStateChange', appStateChangeFunction);

    return () => {
      appUrlOpenListener.remove().catch(console.log);
      appStateChangeListener.remove().catch(console.log);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isActive && UserService.isLoggedIn() && Capacitor.getPlatform() === 'android') {
      console.log('App state changed. Active:', isActive);
      const uuid = UserService.getMineId();
      UserService.updateToken(() => dispatch(getWearableInfo({ uuid, initTerra: true })));
      handlerTrackEvent({ type: 'FOREGROUND' });
    }
  }, [isActive]);

  return null;
};

export default AppListeners;
