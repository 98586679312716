import React from 'react';
import { Box, Typography } from '@mui/material';
import { CustomSlider } from '../../../components/features/CustomSlider';
import BadMoodImg from '../../../assets/png/bad-mood.png';
import GoodMoodImg from '../../../assets/png/good-mood.png';
import { IMetric } from '../../../models/Metrics';
import { Colors } from '../../../theme/colors';

type WhoFiveSliderProps = {
  value: number;
  position: number;
  onChange: (e: any) => void;
  metric: IMetric;
};

export const WhoFiveSlider: React.FC<WhoFiveSliderProps> = ({ value, position, onChange, metric }) => {
  return (
    <Box mb={{ xs: '24px' }}>
      <Typography
        sx={{ fontSize: { sx: '14px', table: '16px' }, fontWeight: '700', color: Colors.gray[700], mb: '14px' }}
      >
        {position}. {metric.title}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <Box
          component="img"
          src={BadMoodImg}
          sx={{
            height: { xs: '64px', table: '80px' },
            width: { xs: '64px', table: '80px' },
            ml: '-16px',
            mr: '-16px',
            mb: '-16px',
            mt: '-8px',
            objectFit: 'cover',
          }}
        />
        <Box sx={{ flex: 1, ml: '24px', mr: '24px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingInline: '4px' }}>
            <Typography sx={{ fontSize: '12px', color: Colors.gray[400] }}>At no time</Typography>
            <Typography sx={{ fontSize: '12px', color: Colors.gray[400] }}>All of the time</Typography>
          </Box>
          <CustomSlider
            value={value ?? 1}
            min={metric.metric_characteristics.min_value}
            max={metric.metric_characteristics.max_value}
            onChange={onChange}
            variant="small"
          />
        </Box>
        <Box
          component="img"
          src={GoodMoodImg}
          sx={{
            height: { xs: '64px', table: '80px' },
            width: { xs: '64px', table: '80px' },
            ml: '-16px',
            mr: '-16px',
            mb: '-16px',
            mt: '-8px',
            objectFit: 'cover',
          }}
        />
      </Box>
    </Box>
  );
};
