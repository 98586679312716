import React from 'react';
import { Box, FormControl, MenuItem, OutlinedInput, Select, styled, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ExpandMore } from '../../../components/features/HeaderFilter/HeaderFilter';
import { ReactComponent as RightArrowIcon } from '../../../assets/svg/feature/rightArrow.svg';
import { ReactComponent as ArticleIcon } from '../../../assets/svg/feature/article.svg';
import { Colors } from '../../../theme/colors';

const MenuProps = {
  PaperProps: {
    style: {
      padding: '4px 8px',
      maxHeight: '400px',
      borderRadius: '20px',
      marginTop: '8px',
      boxShadow: 'var(--shadow-button)',
    },
  },
};

const StyledSelect = styled(Select)({
  fontSize: 14,
  fontWeight: 700,
  textOverflow: 'ellipsis',
  background: '#E2E5E6',
  borderRadius: '999px',
  paddingInline: '8px',
  paddingLeft: '2px',
  '& *': {
    border: 'none !important',
  },
  '& .MuiSelect-icon': {
    top: '4px',
    right: '4px',
  },
});

const StyledMenuItem = styled(MenuItem)({
  mb: '4px',
  fontSize: '14px',
  borderRadius: '20px',
  fontWeight: '700',
  whiteSpace: 'pre-wrap',
  '&.Mui-selected': {
    backgroundColor: '#E2E8ED',
    fontWeight: '900',
  },
});

type CustomDropdownProps = {
  width?: number | string;
  label?: string;
  fullWidth?: boolean;
  small?: boolean;
  placeholder?: string;
  disabled?: boolean;
  list: string[];
  selected: string[];
  setSelected: (s: string[]) => void;
  lowercase?: boolean;
};

const CustomFilterDropdown: React.FC<CustomDropdownProps> = ({
  width = 300,
  label,
  fullWidth,
  small,
  placeholder,
  list,
  disabled,
  selected,
  setSelected,
  lowercase = false,
}) => {
  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setSelected(value);
  };

  return (
    <FormControl
      sx={{
        width: fullWidth ? '100%' : width,
        position: 'relative',
      }}
      disabled={disabled}
    >
      {label && (
        <Box sx={{ position: 'absolute', top: '-24px', left: '12px' }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: 16, color: 'text.primary' }}>{label}</Typography>
        </Box>
      )}

      <StyledSelect
        displayEmpty
        value={selected}
        onChange={handleChange}
        input={<OutlinedInput />}
        MenuProps={MenuProps}
        IconComponent={RightArrowIcon}
        sx={{ height: small ? 36 : 40, width: { xs: '100%', sm: '214px' } }}
        inputProps={{ 'aria-label': 'Without label' }}
        multiple
        renderValue={(value) => {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ArticleIcon width={16} height={16} fill={Colors.gray[600]} />
              <Box
                sx={{
                  display: 'block',
                  height: 16,
                  width: '1px',
                  ml: '8px',
                  mr: '8px',
                  background: Colors.gray[150],
                }}
              />
              <Typography sx={{ color: Colors.gray[500], fontSize: '16px' }}>
                {(value as string[]).length ? (value as string[]).join(', ') : placeholder}
              </Typography>
            </Box>
          );
        }}
      >
        {placeholder && (
          <MenuItem disabled value="">
            <em>{placeholder}</em>
          </MenuItem>
        )}
        {list.map((item) => (
          <StyledMenuItem key={item} value={item} sx={{ textTransform: 'capitalize' }}>
            {lowercase ? item.toLowerCase() : item}
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default CustomFilterDropdown;
