import { Capacitor } from '@capacitor/core';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

const ANALYTICS_ENABLED = !!+(process.env.REACT_APP_ENABLE_ANALYTICS ?? 0);

const initializeAnalytics = () => {
  if (!ANALYTICS_ENABLED) {
    console.log('Analytics disabled');
    return;
  }

  if (Capacitor.isNativePlatform()) {
    return;
  } else {
    FirebaseAnalytics.initializeFirebase({
      apiKey: 'AIzaSyA7S4eGFGqqK99I2yTI-px8FGn3-_676aY',
      authDomain: 'wellics-3d513.firebaseapp.com',
      projectId: 'wellics-3d513',
      storageBucket: 'wellics-3d513.appspot.com',
      messagingSenderId: '123851617819',
      appId: '1:123851617819:web:f060d704a5249498b12abe',
      measurementId: 'G-8ZPR45N9RC',
    })
      .then(() => console.log('Analytics Initialized'))
      .catch(console.log);
  }
};

const trackEvent = (event: string, data?: any) => {
  if (!ANALYTICS_ENABLED) return;

  FirebaseAnalytics.logEvent({
    name: event,
    params: data,
  })
    .then(() => console.log('Analytics Event Tracked ' + event))
    .catch(console.error);
};

const trackScreen = (screenName: string) => {
  if (!ANALYTICS_ENABLED) return;

  FirebaseAnalytics.logEvent({
    name: 'screen_view',
    params: {
      screen_name: screenName,
    },
  })
    .then(() => console.log('Analytics Screen Tracked ' + screenName))
    .catch(console.error);
};

const trackIdentify = (userId: string) => {
  if (!ANALYTICS_ENABLED) return;

  FirebaseAnalytics.setUserId({
    userId: userId,
  })
    .then(() => console.log('User Identified ' + userId))
    .catch(console.error);
};

const resetIdentify = () => {
  if (!ANALYTICS_ENABLED) return;

  FirebaseAnalytics.reset()
    .then(() => console.log('Analytics Reset'))
    .catch(console.error);
};

export { initializeAnalytics, trackScreen, trackEvent, trackIdentify, resetIdentify };
