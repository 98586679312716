import { IonContent, IonPage, useIonRouter } from '@ionic/react';
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Collapse, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { JourneyComponent } from '../../components/features/JourneyComponent';
import { useDispatch, useSelector } from 'react-redux';
import {
  asyncDeleteMetric,
  asyncGetMetricsByPillar,
  asyncGetUserJourneyCards,
  asyncUpdateMetric,
  getUserGraphMetrics,
  getWellnessChartUser,
  setJourneyMetrics,
} from '../../redux/slices/journeyPageSlice';
import { RootState } from '../../redux/store';
import { MetricsContainer } from '../../components/features/Journey/MetricsContainer';
import CustomCalendar from '../../components/common/CustomCalendar';
import moment from 'moment';
import { IDeleteMetric, IMetric, IUpdateMetric } from '../../models/Metrics';
import { Range } from 'react-date-range';
import useViewIsVisible from '../../hooks/useViewIsVisible';
import CustomSubHeader from '../../components/structure/CustomSubHeader';
import MetricsEditModal from '../../components/modals/MetricsEditModal';
import { ANALYTICS_PAGE } from '../../models/enum/ANALYTICS_PAGE';
import { PERIOD } from '../../models/Shared';
import { LifeBalanceCard } from '../../components/features/cards/LifeBalanceCard';
import { WellnessCard } from '../../components/features/cards/WellnessCard';
import { metricsCategoryColors } from '../../utils/metricsCategories';
import CustomNewHeader from '../../components/features/CustomNewHeader';
import { Colors } from '../../theme/colors';
import { ReactComponent as CloseIcon } from '../../assets/svg/feature/close.svg';
import WhoFiveImage from '../../assets/png/who-5.png';
import { useHandleLifeBalance } from './hooks/useHandleLifeBalance';
import { ReactComponent as PlusIcon } from '../../assets/svg/plus.svg';
import { useFetchWhoFive } from './hooks/useFetchWhoFive';
import { WhoFiveComponent } from './components/WhoFiveComponent';
import CustomPaginator from '../../components/features/CustomPaginator';
import { TestResultsCard } from '../../components/features/cards/TestResultsCard';

export const JourneyPage: React.FC = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [date, setDate] = useState(new Date().toString());
  const [allMetrics, setAllMetrics] = useState<IMetric[]>([]);
  const [graphMetricsSelected, setGraphMetricsSelected] = useState<IMetric[]>([]);
  const [selectedGoal, setSelectedGoal] = useState<string>('Not Selected');

  const [selectedPeriod, setSelectedPeriod] = useState<PERIOD>(PERIOD.MONTH);
  const [range, setRange] = useState<Range[]>([]);

  const [editMetric, setEditMetric] = useState<IMetric>();

  const { metrics, metricsPillar, journeyCards, graph, metricsModal, graphMetrics } = useSelector(
    (state: RootState) => state.journeyPage.data,
  );
  const { journeyCardsLoading, metricsLoading } = useSelector((state: RootState) => state.journeyPage.meta);

  const { isLifeUpdating, newValues, lifeMetrics, isEdit, onLifeBalanceChange, handelSubmitLifeBalance } =
    useHandleLifeBalance(moment(date).format('YYYY-MM-DD'));

  const visible = useViewIsVisible(ANALYTICS_PAGE.JOURNEY);

  const router = useIonRouter();

  const { showNotify, isWhoFiveModal, whoFiveMetrics, openModalWho, handleCloseNotify, closeWhoFiveModal } =
    useFetchWhoFive();

  useEffect(() => {
    return () => {
      dispatch(setJourneyMetrics([]));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!visible) return;

    setDate(new Date().toString());
    setSelectedPeriod(PERIOD.MONTH);
    setRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ]);
  }, [dispatch, visible]);

  useEffect(() => {
    if (!allMetrics?.length) setAllMetrics(graphMetrics?.metrics);
    if (!graphMetricsSelected?.length) {
      const preselectedGraphs = ['WELLBEING', 'SLEEP_DURATION', 'STEPS', 'WATER_CONSUMPTION'];
      setGraphMetricsSelected(graphMetrics?.metrics.filter((m) => preselectedGraphs.includes(m.metric_type)));
    }
  }, [graphMetrics, allMetrics?.length, graphMetricsSelected?.length]);

  const fetchWellnessChart = useCallback(() => {
    if (graphMetricsSelected.length > 0 && range.length === 1) {
      const { startDate, endDate } = range[0];
      if (startDate && endDate) {
        let actualStartDate: any = startDate;
        let actualEndDate: any = endDate;
        if (moment(startDate).isSame(endDate, 'day')) {
          actualEndDate = moment();
          if (selectedPeriod === PERIOD.WEEK) {
            actualStartDate = moment().subtract(7, 'days');
          } else if (selectedPeriod === PERIOD.MONTH) {
            actualStartDate = moment().subtract(1, 'month');
          }
          if (selectedPeriod === PERIOD.YEAR) {
            actualStartDate = moment().subtract(1, 'year');
          }
        }
        dispatch(
          getWellnessChartUser({
            metrics: graphMetricsSelected,
            date_from: actualStartDate.toString(),
            date_to: actualEndDate.toString(),
          }),
        );
      }
    }
  }, [dispatch, graphMetricsSelected, range, selectedPeriod]);

  useEffect(() => {
    if (!visible) return;

    if (metricsModal.isOpen === false) {
      fetchWellnessChart();
    }
  }, [visible, fetchWellnessChart, metricsModal.isOpen]);

  useEffect(() => {
    if (metricsModal.isOpen === false) {
      dispatch(asyncGetUserJourneyCards({ date: moment(date).format('YYYY-MM-DD') }));
      dispatch(getUserGraphMetrics({ date: moment(date).format('YYYY-MM-DD') }));
    }
  }, [dispatch, date, metricsModal.isOpen]);

  useEffect(() => {
    if (metricsPillar !== undefined && metricsModal.isOpen === false) {
      dispatch(asyncGetMetricsByPillar({ date: moment(date).format('YYYY-MM-DD'), pillar: metricsPillar }));
    }
  }, [date, dispatch, metricsModal.isOpen, metricsPillar]);

  const getGoalList = () => {
    return ['Not Selected', ...graph.goals.map((g) => g.goal_category_title)];
  };

  const closeEditMetric = () => {
    setEditMetric(undefined);
  };

  const updateMetric = (metric: IMetric) => {
    if (editMetric) {
      const updateMetric: IUpdateMetric = {
        metric_category_group: editMetric.group,
        metric_value: editMetric.metric_samples?.[0]?.total_value ?? 0,
        title: metric.title,
        previous_title: editMetric.title,
        goal_value: editMetric.metric_goal?.target_value,
      };
      dispatch(
        asyncUpdateMetric({
          metric: updateMetric,
          date: moment(date).format('YYYY-MM-DD'),
        }),
      );
      setEditMetric(undefined);
    }
  };

  const deleteMetric = (metric: IMetric) => {
    if (editMetric) {
      const deleteMetric: IDeleteMetric = {
        metric_category_group: editMetric.group,
        title: metric.title,
      };
      dispatch(
        asyncDeleteMetric({
          metric: deleteMetric,
          date: moment(date).format('YYYY-MM-DD'),
        }),
      );
      setEditMetric(undefined);
    }
  };

  return (
    <IonPage>
      <CustomNewHeader
        label={'Your Journey'}
        tooltip={
          'Log your well-being data daily and see reports. Get a reflection of your current well-being status, via your Wellics™ Index (0-100).'
        }
        notificationComponent={
          <Collapse in={showNotify}>
            <Box
              sx={{
                width: '100%',
                height: { xs: '48px', sm: '40px' },
                borderBottomLeftRadius: '20px',
                borderBottomRightRadius: '20px',
                background: `linear-gradient(180deg, ${Colors.green[200]} 0%, ${Colors.green[500]} 100%)`,
                cursor: 'pointer',
                overflow: 'hidden',
              }}
              onClick={openModalWho}
            >
              <Container maxWidth="lg" sx={{ height: '100%' }}>
                <Grid
                  item
                  sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  {!xsDown && <Box sx={{ height: '22px', width: '22px' }} />}
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img
                      alt="WHO-5"
                      src={WhoFiveImage}
                      style={{ width: '70px', height: 'auto', marginRight: '16px' }}
                    />
                    <Typography sx={{ fontSize: '14px', fontWeight: '600', color: Colors.white }}>
                      Take WHO-5 questionnaire
                    </Typography>
                  </Box>
                  <Box
                    component="button"
                    className="notify-close"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '22px',
                      width: '22px',
                      borderRadius: '50%',
                      background: 'rgba(255, 255, 255, 0.50)',
                    }}
                    onClick={handleCloseNotify}
                  >
                    <CloseIcon height={12} width={12} fill={Colors.green[500]} />
                  </Box>
                </Grid>
              </Container>
            </Box>
          </Collapse>
        }
      >
        <Box
          display="flex"
          justifyContent={xsDown ? 'center' : 'flex-end'}
          sx={{ mr: xsDown ? 0 : 1, width: { xs: '100%', md: 'unset' } }}
        >
          <Box sx={{ width: { xs: '100%', md: 'unset' } }}>
            <CustomCalendar date={date} setDate={setDate} disableFuture />
          </Box>
        </Box>
      </CustomNewHeader>
      <IonContent style={{ '--padding-bottom': '42px' }}>
        <Container maxWidth={'lg'}>
          <Grid container sx={{ mt: 2, mb: 8 }}>
            <Grid item xs={12} mb="40px" mt={-1}>
              <JourneyComponent
                date={moment(date).format('YYYY-MM-DD')}
                journeyData={journeyCards}
                loading={journeyCardsLoading}
              />
            </Grid>

            <TestResultsCard visible={visible} />

            <Grid item xs={12} mb="40px">
              <LifeBalanceCard
                date={moment(date).format('YYYY-MM-DD')}
                isUpdating={isLifeUpdating}
                onChange={onLifeBalanceChange}
                onSubmit={handelSubmitLifeBalance}
                {...{ ...lifeMetrics, newValues, isEdit }}
              />
            </Grid>
            <Grid item xs={12} mb="40px">
              <Grid item container spacing={2} maxWidth="lg" justifyContent={'space-between'}>
                <Grid item md={9} table={8} xs={12}>
                  <CustomSubHeader
                    sx={{ mb: '24px' }}
                    label={`Your Progress`}
                    tooltip={
                      <>
                        {'See your progress and trends on well-being metrics. You can select '}
                        <b>up to any four</b>
                        {
                          ' metrics and change the period as you like. If one of your selections is your goal metric, then you can also display the target value on the chart.'
                        }
                      </>
                    }
                  />
                  <WellnessCard
                    colors={metricsCategoryColors}
                    metricsList={allMetrics}
                    selectedMetrics={graphMetricsSelected}
                    setSelectedMetrics={setGraphMetricsSelected}
                    period={selectedPeriod}
                    range={range}
                    setRange={setRange}
                    graph={graph}
                    setPeriod={setSelectedPeriod}
                    selectedGoal={selectedGoal}
                    setSelectedGoal={setSelectedGoal}
                    goalsList={getGoalList()}
                  />
                </Grid>
                <Grid item md={3} table={4} xs={12}>
                  <Box display="flex" flexDirection="column" sx={{ mb: -8, width: '100%' }}>
                    <Box sx={{ width: '100%', whiteSpace: 'nowrap', mb: '24px' }}>
                      <CustomSubHeader
                        label={`Your Metrics & Goals`}
                        tooltip={
                          'Log and see your data, set your goals and target values. Work on your Focus metric each quarter to collect achievement points. Add your own wellness metric through the ⊕ button on the bottom right of the page.'
                        }
                      />
                    </Box>
                    <Box sx={{ flex: 1, width: '100%', maxHeight: '430px' }}>
                      <MetricsContainer
                        metrics={metrics}
                        date={date}
                        onEdit={setEditMetric}
                        page={ANALYTICS_PAGE.JOURNEY}
                        loading={metricsLoading}
                      />
                      <Box display="flex" justifyContent="center">
                        <Button onClick={() => router.push('/page/metrics-log-data?isCreateMetric=true')}>
                          <PlusIcon height={16} width={16} fill={Colors.blue[500]} style={{ marginRight: '8px' }} />
                          <Typography sx={{ textDecoration: 'underline' }}>Create your own metric </Typography>
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <WhoFiveComponent isOpen={isWhoFiveModal} metrics={whoFiveMetrics} onClose={closeWhoFiveModal} />
        <MetricsEditModal
          metric={editMetric}
          onClose={closeEditMetric}
          onUpdate={updateMetric}
          onDelete={deleteMetric}
        />
      </IonContent>
    </IonPage>
  );
};
