import React, { useState, FC, useEffect } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../assets/svg/closeIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { asyncGetModalMetrics, setMetricsModal } from '../../redux/slices/journeyPageSlice';
import { RootState } from '../../redux/store';
import MetricsModalContent from '../Journey/MetricsModalContent';
import MetricsModalHead from '../Journey/MetricsModalHead';
import moment from 'moment';
import { IMetric } from '../../models/Metrics';

const MetricsModal: FC = () => {
  const dispatch = useDispatch();
  const [selectedMetric, setSelectedMetric] = useState<string>('');

  const [newMetric, setNewMetric] = useState<boolean>(false);
  const { metricsModalData } = useSelector((state: RootState) => state.journeyPage.data);
  const { isOpen, isCorporate, predefinedMetric, date, page } = useSelector(
    (state: RootState) => state.journeyPage.data.metricsModal,
  );
  const foundMetric = metricsModalData?.metrics?.find((metric: any) => metric.title === selectedMetric);

  useEffect(() => {
    if (isOpen && date) {
      dispatch(asyncGetModalMetrics({ date: moment(date).format('YYYY-MM-DD'), isCorporate }));
    }
  }, [dispatch, date, isCorporate, isOpen]);

  useEffect(() => {
    if (predefinedMetric && predefinedMetric !== '') {
      setSelectedMetric(predefinedMetric);
    }
  }, [predefinedMetric, metricsModalData]);

  const handleClose = () => {
    dispatch(
      setMetricsModal({
        isOpen: false,
        predefinedMetric: '',
        date: moment().format('YYYY-MM-DD'),
        page: undefined,
      }),
    );
    reset();
  };

  const extractDescription = (foundMetric: IMetric | undefined) => {
    if (newMetric) {
      return 'Create your own metric to truly own your life balance, set goals in order to achieve them!';
    } else if (foundMetric?.metric_description) {
      return foundMetric?.metric_description;
    } else if (foundMetric && foundMetric.metric_description === null) {
      return 'This is your very own metric. Log your daily values and set goals to truly own your life balance.';
    } else
      return 'Log your data on a daily basis to keep track of your progress, set goals in order to achieve them. You can select the metric you want to track from the list of existing metrics or create your own.';
  };

  const reset = () => {
    setNewMetric(false);
    setSelectedMetric('');
  };

  const handleSetDate = (date: any) => {
    dispatch(
      setMetricsModal({
        isOpen: isOpen,
        predefinedMetric: predefinedMetric,
        date: moment(date).format('YYYY-MM-DD'),
        isCorporate: isCorporate,
        page: page,
      }),
    );
  };

  return (
    <Dialog
      maxWidth={'xs'}
      open={isOpen}
      onClose={handleClose}
      PaperProps={{ style: { margin: '8px', borderRadius: '8px', maxHeight: 'calc(100% - 16px)', minHeight: '600px' } }}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            {predefinedMetric !== '' ? 'LOG DATA' : 'NEW DATA'}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ p: 2 }}>
        <Box
          marginTop={2}
          display="flex"
          gap={2}
          mb={4}
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          <MetricsModalHead
            date={date}
            setDate={handleSetDate}
            selectedMetric={selectedMetric}
            setSelectedMetric={setSelectedMetric}
            newMetric={newMetric}
            setNewMetric={setNewMetric}
          />
          <Box padding={1} mt={2}>
            <Typography variant="caption">{extractDescription(foundMetric)}</Typography>
          </Box>
          <MetricsModalContent foundMetric={foundMetric} date={date} newMetric={newMetric} reset={reset} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default MetricsModal;
