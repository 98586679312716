import { getRequest } from '../../../../services/HttpService';

export const getAwardsRequest = (params: { page?: number; size?: number }) => {
  return getRequest('/gamification-service/user/awards', {
    page: `${params.page || 0}`,
    size: `${params.size || 10}`,
  });
};

export const getPointsRequest = (params: { page?: number; size?: number }) => {
  return getRequest('/user-engagement-service/user/points', {
    page: `${params.page || 0}`,
    size: `${params.size || 10}`,
  });
};
