import React from 'react';
import { Challenge } from '../../../models/Challenge';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { Colors } from '../../../theme/colors';
import { CustomAvatarList } from '../../../components/features/CustomAvatarList';
import Grid from '@mui/material/Grid';
import { RoundIconButton } from '../../../components/features/RoundIconButton';
import theme from '../../../theme';
import { ReactComponent as EmptyParticipantsIcon } from '../../../assets/svg/feature/empty-participants.svg';
import { ReactComponent as SlackIcon } from '../../../assets/svg/feature/slack.svg';
import { ReactComponent as TimeIcon } from '../../../assets/svg/feature/time.svg';
import { Participant } from '../../../models/Participant';

type Props = {
  challenge: Challenge;
  participants: Participant[];
  challengeTitle: string;
  daysLeftToJoin: number;
  isCorporate: boolean;
  showParticipantsModal: () => void;
};
export const Participants: React.FC<Props> = ({
  challenge,
  participants,
  challengeTitle,
  daysLeftToJoin,
  isCorporate,
  showParticipantsModal,
}) => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const days = Array(10)
    .fill(0)
    .map((e, i) => i + 1)
    .reverse();
  const DaysToJoin: React.FC = () =>
    daysLeftToJoin > 0 &&
    isCorporate &&
    challenge.challengeParticipation.isUserParticipant &&
    !challenge.challengeParticipation.isUserTeamMember ? (
      <Grid sx={{ borderTop: '1px dashed #F2F2F2', pt: '16px', mt: '20px' }}>
        <Grid container>
          <Typography sx={{ fontSize: 18, lineHeight: 1, color: Colors.gray[700], fontWeight: 900, mr: '4px' }}>
            {daysLeftToJoin}
          </Typography>
          <Typography sx={{ fontSize: 14, lineHeight: 1, color: Colors.gray[400], fontWeight: 700 }}>
            days left to join the team
          </Typography>
        </Grid>
        <Grid sx={{ mt: '8px', display: 'flex', alignItems: 'center' }}>
          <Grid sx={{ display: 'flex', alignItems: 'center' }}>
            <TimeIcon width={16} height={16} fill={Colors.gray[200]} />
          </Grid>
          {days.map((item) => (
            <Grid
              key={item}
              sx={
                item > daysLeftToJoin
                  ? {
                      height: '16px',
                      width: '16px',
                      backgroundColor: Colors.gray[50],
                      borderRadius: '100%',
                      ml: '7px',
                    }
                  : {
                      height: '16px',
                      width: '16px',
                      background: 'linear-gradient(180deg, #F0B8BA 0%, #A2ECCE 0.01%, #16CF84 100%)',
                      border: '0.5px solid #B9F1DA',
                      boxShadow: '1px 1px 4px rgba(22, 207, 132, 0.45)',
                      borderRadius: '100%',
                      ml: '7px',
                    }
              }
            />
          ))}
        </Grid>
      </Grid>
    ) : null;
  return (
    <>
      <Box>
        {!mdDown && (
          <Typography sx={{ fontSize: '12px', color: Colors.gray[400], fontWeight: 900, mb: '12px' }}>
            Participants:
          </Typography>
        )}

        {participants.length ? (
          <CustomAvatarList
            sx={{ cursor: 'pointer' }}
            challenge={challenge}
            type={'lg'}
            onClick={showParticipantsModal}
          />
        ) : (
          <Grid sx={{ display: 'flex', alignItems: 'center', pl: '10px' }}>
            <EmptyParticipantsIcon width={86} height={71} />
            <Typography sx={{ fontSize: '14px', color: Colors.gray[400], fontWeight: 600, ml: '10px' }}>
              Be the first to join!
            </Typography>
          </Grid>
        )}
      </Box>

      <DaysToJoin />
      {challenge.metadata.slackURL && (
        <Box sx={{ pt: '16px', mt: '20px', borderTop: '1px dashed #F2F2F2' }}>
          <Typography sx={{ fontSize: '12px', color: Colors.gray[400], fontWeight: 700, mb: '8px' }}>
            Discussion board:
          </Typography>

          <RoundIconButton
            sx={{
              marginBottom: {
                xs: '4px',
                sm: 0,
                width: { xs: '100%', sm: 'auto' },
              },
              alignSelf: { xs: 'stretch', sm: 'unset' },
              backgroundColor: Colors.gray[50],
              width: '100%',
            }}
            Icon={SlackIcon}
            size={36}
            background={Colors.white}
            roundColor={Colors.white}
            textColor={Colors.gray[600]}
            roundBorderColor={Colors.gray[150]}
            textSx={{
              fontSize: '14px',
              maxWidth: '150px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
            label={challengeTitle}
            isDisable={!challenge.challengeParticipation.isUserParticipant}
            onClick={() =>
              challenge.metadata.slackURL && challenge.challengeParticipation.isUserParticipant
                ? window.open(challenge.metadata.slackURL)
                : undefined
            }
          />
        </Box>
      )}
    </>
  );
};
