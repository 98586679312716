import React from 'react';
import { Box, Card, CardContent, Skeleton, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import theme from '../../../theme';
import { Colors } from '../../../theme/colors';
import { CustomButton } from '../CustomButton';
import { LifeBalanceChart } from '../charts/LifeBalanceChart';

type LifeBalanceCardProps = {
  values?: number[];
  newValues: number[];
  labels?: { label: string; description: string }[];
  isUpdating?: boolean;
  isEdit?: boolean;
  onChange: (idx: number, val: number) => () => void;
  onSubmit: () => void;
};

export const LifeBalanceCard: React.FC<LifeBalanceCardProps> = ({
  values,
  newValues,
  labels,
  isUpdating,
  isEdit,
  onChange,
  onSubmit,
}) => {
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Card
      sx={{
        borderRadius: '20px',
        backgroundColor: 'common.white',
        boxShadow: `0px 4px 24px rgba(138, 138, 138, 0.25)`,
      }}
    >
      <CardContent sx={{ display: 'flex', padding: '24px', '&:last-child': { paddingBottom: '40px' } }}>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', table: 'row' } }}>
          <Box sx={{ flex: 1, mr: { xs: 'unset', table: '16px' }, mb: { xs: '32px', table: 'unset' } }}>
            <Box display="flex" alignItems="center" mb="16px">
              <Typography sx={{ fontSize: '22px', fontWeight: '700', color: Colors.gray[700], mr: '8px' }}>
                Life-balance assessment
              </Typography>
              <Tooltip
                title={
                  <Typography variant={'body2'}>
                    A life-balance assessment to help you find out more about your personal balance. We suggest you do
                    this assessment every week to keep track of the areas that need attention. You cannot do the
                    assessment more than once a day.
                  </Typography>
                }
                placement={xsDown ? 'bottom-end' : 'right-start'}
                enterTouchDelay={0}
                leaveTouchDelay={10000}
                sx={{ mx: 1, fontSize: xsDown ? 16 : 20, color: theme.palette.grey[600] }}
              >
                <InfoOutlined sx={{ fontSize: '16px', color: Colors.gray[400] }} />
              </Tooltip>
            </Box>
            <Typography sx={{ color: '#5D6C6D' }}>
              Personal growth starts with self-awareness and a clear understanding of the factors shaping your
              well-being. When life gets busy, we overlook the moments of joy or sadness. The coaching-based
              Life-Balance assessment is here to help. By taking this assessment once a day, you uncover areas that may
              need attention and rediscover positive aspects you may have overlooked.{' '}
            </Typography>
            <Box
              sx={{
                mb: '16px',
                mt: '16px',
                padding: { xs: '16px', table: '16px 32px' },
                borderRadius: '10px',
                background: '#E8F8FA',
                maxWidth: '460px',
              }}
            >
              <Typography sx={{ fontSize: '16px', fontWeight: '700', color: Colors.blue[700], mb: '16px' }}>
                Once you complete an assessment, ask yourself:
              </Typography>
              <Box>
                <Box sx={{ display: 'flex' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '20px',
                      width: '20px',
                      borderRadius: '50%',
                      background: `radial-gradient(88.44% 85.82% at 33.33% 26.67%, #A2E5EC 0%, #16BECF 79.58%);`,
                      marginRight: '8px',
                      mb: '10px',
                    }}
                  >
                    <Typography sx={{ fontSize: '10px', color: Colors.white, fontWeight: '700' }}>1</Typography>
                  </Box>
                  <Typography sx={{ fontSize: '14px', color: Colors.blue[700], flex: 1 }}>
                    How do you feel about what you see?
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '20px',
                      width: '20px',
                      borderRadius: '50%',
                      background: `radial-gradient(88.44% 85.82% at 33.33% 26.67%, #A2E5EC 0%, #16BECF 79.58%);`,
                      marginRight: '8px',
                      mb: '10px',
                    }}
                  >
                    <Typography sx={{ fontSize: '10px', color: Colors.white, fontWeight: '700' }}>2</Typography>
                  </Box>
                  <Typography sx={{ fontSize: '14px', color: Colors.blue[700], flex: 1 }}>
                    Which categories would you most like to improve?
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '20px',
                      width: '20px',
                      borderRadius: '50%',
                      background: `radial-gradient(88.44% 85.82% at 33.33% 26.67%, #A2E5EC 0%, #16BECF 79.58%);`,
                      marginRight: '8px',
                    }}
                  >
                    <Typography sx={{ fontSize: '10px', color: Colors.white, fontWeight: '700' }}>3</Typography>
                  </Box>
                  <Typography sx={{ fontSize: '14px', color: Colors.blue[700], flex: 1 }}>
                    How could you move the numbers up a level?
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Typography sx={{ fontSize: '18px', color: Colors.gray[700], fontWeight: 700, mb: '16px' }}>
              Embrace progress, not perfection!
            </Typography>
            <Typography sx={{ fontSize: '16px', color: '#5D6C6D' }}>
              Evaluate each category and rate your satisfaction level from <b>1</b> (least) to <b>10</b> (most).
              Discover where you thrive and where to grow!
            </Typography>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              ml: { xs: 'unset', table: '16px' },
            }}
          >
            {!values ? (
              <Skeleton
                variant="rectangular"
                sx={{
                  width: { xs: '280px', table: '440px' },
                  height: { xs: '280px', table: '440px' },
                  mb: '32px',
                  borderRadius: '50%',
                }}
              />
            ) : (
              <LifeBalanceChart
                values={values}
                newValues={newValues}
                labels={labels ?? []}
                onChange={isEdit ? onChange : undefined}
              />
            )}
            {isEdit && (
              <CustomButton
                type="bigBlue"
                text="Submit"
                minWidth="156px"
                disabled={isUpdating}
                sx={{ marginTop: '40px' }}
                inputProps={{
                  variant: 'contained',
                }}
                onClick={onSubmit}
              />
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
