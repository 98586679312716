import { Box, Skeleton, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Colors } from '../../../theme/colors';
import { Checkbox } from './Checkbox';
import { TableHeaderItem } from './TableHeaderItem';
import { TableUserRow } from './TableUserRow';
import { TableRow } from './TableRow';
import { TableCell } from './TableCell';
import { ModalCatalogPrams, RowInputsType, RowUserType } from '../types';
import theme from '../../../theme';
import { Capacitor } from '@capacitor/core';
import { SafeArea } from 'capacitor-plugin-safe-area';

type TableRedemptionProps = {
  selectedRowUser: RowUserType[];
  rowUsers?: RowUserType[];
  inputs: RowInputsType[];
  sort: string;
  onChangeInputs: (uuid: string, type: 'amount' | 'points') => (val: string) => void;
  handleEditUser: (uuid: string) => (isRemove?: boolean) => void;
  openCatalogModal: (params: ModalCatalogPrams) => void;
  handleFetchNextUsers: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  onChangeSort: (sort: string) => void;
  handleClearSelected: () => void;
};

export const TableRedemption: React.FC<TableRedemptionProps> = ({
  selectedRowUser,
  rowUsers,
  inputs,
  sort,
  onChangeInputs,
  handleEditUser,
  openCatalogModal,
  handleFetchNextUsers,
  onChangeSort,
  handleClearSelected,
}) => {
  const [safeAreaTop, setSafeAreaTop] = useState(0);
  const tableDown = useMediaQuery(theme.breakpoints.down('table'));

  useEffect(() => {
    const getStatusBar = async () => {
      if (Capacitor.getPlatform() === 'ios') {
        const { statusBarHeight } = await SafeArea.getStatusBarHeight();
        setSafeAreaTop(statusBarHeight - 4);
      }
    };
    getStatusBar();
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        borderRadius: '20px',
        background: Colors.white,
        boxShadow: '5px 0px 15px 0px rgba(108, 123, 132, 0.05)',
        pt: '8px',
        pb: { table: '24px' },
      }}
    >
      <Box sx={{ width: '100%', overflowX: 'scroll', height: { table: '670px' } }}>
        <Box sx={{ width: '100%', minWidth: { table: '1084px' } }}>
          {!tableDown && (
            <TableRow
              sx={{
                borderBottom: `1px solid ${Colors.gray[100]}`,
              }}
            >
              <TableCell onClick={handleClearSelected} sx={{ flex: '0 0 64px' }}>
                <Checkbox {...(selectedRowUser.length > 0 && { isSelected: true, isMultiple: true })} />
              </TableCell>
              <TableCell onClick={() => onChangeSort('combinedname')}>
                <TableHeaderItem
                  sort={sort.split(',')?.[0] === 'combinedname' ? sort.split(',')?.[1] : undefined}
                  label="Name"
                />
              </TableCell>
              <TableCell onClick={() => onChangeSort('country')}>
                <TableHeaderItem
                  sort={sort.split(',')?.[0] === 'country' ? sort.split(',')?.[1] : undefined}
                  label="Country"
                />
              </TableCell>
              <TableCell>
                <Typography sx={{ fontSize: '14px', fontWeight: '800', color: Colors.gray[500] }}>Amount</Typography>
              </TableCell>
              <TableCell onClick={() => onChangeSort('points')} sx={{ flex: '0 0 168px' }}>
                <TableHeaderItem
                  sort={sort.split(',')?.[0] === 'points' ? sort.split(',')?.[1] : undefined}
                  label="Current points"
                />
              </TableCell>
              <TableCell>
                <Typography sx={{ fontSize: '14px', fontWeight: '800', color: Colors.gray[500] }}>
                  Points redeemed
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {!rowUsers ? (
            new Array(10).fill(0).map((_, idx) => (
              <Skeleton
                variant="rectangular"
                key={idx}
                sx={{
                  height: { xs: '82px', table: '60px', borderBottom: `1px solid ${Colors.gray[100]}` },
                  width: '100%',
                }}
              />
            ))
          ) : (
            <Box
              component="div"
              sx={{
                flex: 1,
                overflow: 'scroll',
                maxHeight: { xs: `calc(100vh - 326px - ${safeAreaTop}px)`, table: '600px' },
              }}
              onScroll={handleFetchNextUsers}
            >
              {rowUsers &&
                rowUsers.map((item) => (
                  <TableUserRow
                    key={item.uuid}
                    {...item}
                    inputAmount={inputs?.find((input) => input.uuid === item.uuid)?.amount ?? ''}
                    inputPoints={inputs?.find((input) => input.uuid === item.uuid)?.points ?? ''}
                    onChangeAmount={onChangeInputs(item.uuid, 'amount')}
                    onChangePoints={onChangeInputs(item.uuid, 'points')}
                    handleEdit={handleEditUser(item.uuid)}
                    openCatalog={openCatalogModal}
                  />
                ))}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
