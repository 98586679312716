import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { COMPETITION } from '../../../models/enum/COMPETITION';

export const useHandleListLayout = () => {
  const detail = useSelector((state: RootState) => state.challengeProgress.data.detail);
  const leaderBoard = useSelector((state: RootState) => state.challengeProgress.data.leaderBoard);

  return {
    isSelf: detail?.competition === COMPETITION.SELF,
    isEmpty: leaderBoard?.instanceList && leaderBoard?.instanceList?.length <= 1,
  };
};
