import React from 'react';
import { Box, SxProps, Typography, Theme } from '@mui/material';
import { Colors } from '../../../../theme/colors';
import { ReactComponent as GlowIcon } from '../../../../assets/svg/feature/glow.svg';

type DayInRowProps = {
  isFinished?: boolean;
  days: number;
  sx?: SxProps<Theme>;
};

export const DayInRow: React.FC<DayInRowProps> = ({ isFinished = false, days, sx }) => {
  return (
    <Box sx={sx}>
      <Typography sx={{ fontSize: 10, color: Colors.gray[500], mb: '8px' }}>Day-in-a-row:</Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          position: 'relative',
          height: '31px',
          width: '41px',
          borderRadius: '10px',
          background: isFinished ? Colors.blue[700] : Colors.gray[50],
        }}
      >
        <GlowIcon style={{ position: 'absolute', top: '-2px', right: '-1px' }} />
        <Typography sx={{ fontSize: '16px', fontWeight: '700', color: isFinished ? Colors.white : Colors.blue[700] }}>
          {days}
        </Typography>
      </Box>
    </Box>
  );
};
