import React from 'react';
import { useHandleRanking } from '../hooks/useHandleRanking';
import { RankingCard, RankingCardVariant } from '../../../components/features/progress/RankingCard';

type RankingCardLayoutProps = {
  variant?: RankingCardVariant;
};

export const RankingCardLayout: React.FC<RankingCardLayoutProps> = ({ variant }) => {
  const props = useHandleRanking();
  return <RankingCard {...props} variant={variant} />;
};
