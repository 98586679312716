import { Range } from 'react-date-range';
import { ScoreTimeItemType } from '../models/Shared';

export const filterMetricSampleByRange = (
  metricSamples: { date: string; value: number; teamValue?: number }[],
  range: Range[],
) => {
  if (!range?.[0]?.startDate || !range?.[0]?.endDate || metricSamples.length === 0) return metricSamples;
  const start = new Date(range[0].startDate).setHours(0, 0, 0, 0);
  const end = new Date(range[0].endDate).setHours(0, 0, 0, 0);
  const newSample = metricSamples.filter(
    (item) =>
      new Date(item.date).setHours(0, 0, 0, 0) >= start &&
      new Date(item.date).setHours(0, 0, 0, 0) <= end &&
      item.value > 0,
  );
  return newSample;
};

export const filterPointsByRange = (points: ScoreTimeItemType[], range: Range[]) => {
  if (!range?.[0]?.startDate || !range?.[0]?.endDate || points.length === 0) return points;
  const start = new Date(range[0].startDate).setHours(0, 0, 0, 0);
  const end = new Date(range[0].endDate).setHours(0, 0, 0, 0);
  const newSample = points.filter(
    (item) => new Date(item.date).setHours(0, 0, 0, 0) >= start && new Date(item.date).setHours(0, 0, 0, 0) <= end,
  );
  return newSample;
};
