import React from 'react';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import { ReactComponent as ArrowIcon } from '../../../assets/svg/feature/arrow-full.svg';
import { Colors } from '../../../theme/colors';

type TableHeaderItemProps = {
  label: string;
  sort?: string;
};

export const TableHeaderItem: React.FC<TableHeaderItemProps> = ({ label, sort }) => (
  <Box sx={{ display: 'flex' }}>
    <ArrowIcon
      fill={Colors.gray[500]}
      style={{ marginRight: '4px', ...(sort === 'desc' && { transform: 'rotate(180deg)' }) }}
    />
    <Typography sx={{ fontSize: '14px', fontWeight: '800', color: Colors.gray[500] }}>{label}</Typography>
  </Box>
);
