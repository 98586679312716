import { IonContent, IonPage, useIonRouter } from '@ionic/react';
import React from 'react';
import CustomNewHeader from '../../components/features/CustomNewHeader';
import { Container, List, ListItem, useMediaQuery } from '@mui/material';
import { CustomBudge } from '../../components/features/CustomBudge';
import { Colors } from '../../theme/colors';
import Grid from '@mui/material/Grid';
import { ReactComponent as SettingsIcon } from '../../assets/svg/feature/settings.svg';
import { EmptyMessageList } from '../../components/features/Notifications/EmptyMessageList';
import { NotificationItem } from '../../components/features/NotificationItem';
import theme from '../../theme';
import { useNotificationHandle } from './hooks/useNotificationHandle';
export const NotificationsPage: React.FC = () => {
  const router = useIonRouter();
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));

  const { divRef, notifications, handleScrollEvent } = useNotificationHandle();

  return (
    <IonPage>
      <CustomNewHeader
        inProgress={false}
        label={'Challenge notification center'}
        rightAction={
          <Grid sx={{ alignSelf: { xs: 'stretch', md: 'center' }, marginLeft: 'auto', pl: '16px' }}>
            <CustomBudge
              key={'Settings'}
              Icon={SettingsIcon}
              boxColor={Colors.gray[150]}
              borderColor={'#E9EDEF'}
              bgColor={Colors.gray[100]}
              textColor={Colors.gray[500]}
              iconColor={Colors.gray[400]}
              label={'Settings'}
              sx={{ cursor: 'pointer' }}
              size={xsDown ? 20 : 32}
              onClick={() => router.push('/page/notifications-settings')}
            />
          </Grid>
        }
      ></CustomNewHeader>

      <IonContent scrollEvents={true}>
        <Container
          maxWidth={'lg'}
          sx={{
            mt: '4px',
            paddingBottom: '36px',
            background: { xs: Colors.white, md: Colors.gray[50] },
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
            borderBottomLeftRadius: { xs: 0, md: '20px' },
            borderBottomRightRadius: { xs: 0, md: '20px' },
            paddingRight: { xs: 0, md: '16px' },
          }}
        >
          <Grid
            sx={{
              background: { xs: 'transparent', md: Colors.white },
              boxShadow: { xs: 'none', md: '5px 0px 15px rgba(108, 123, 132, 0.05)' },
              borderRadius: '20px',
              padding: { xs: 0, md: '32px 24px' },
              maxWidth: { xs: '100%', md: '823px' },
              width: '100%',
              margin: { xs: 0, md: '8px auto 0' },
            }}
          >
            {notifications.length ? (
              <List
                ref={divRef}
                onScroll={handleScrollEvent}
                sx={{
                  margin: 0,
                  padding: { xs: '16px 16px 0 0', md: '0 9px 0 0' },
                  width: '100%',
                  position: 'relative',
                  overflow: 'auto',
                  maxHeight: '692px',
                  height: 'auto',
                  '& ul': { padding: 0 },
                  '&.MuiList-root': {
                    '&::-webkit-scrollbar-track': { background: '#F0F2F3!important' },
                    '&::-webkit-scrollbar-thumb': { background: '#D3D7DA!important' },
                    '&::-webkit-scrollbar-track:hover': { background: '#F0F2F3!important' },
                    '*::-webkit-scrollbar': {
                      width: '5px!important',
                      height: '5px !important',
                    },
                  },
                }}
              >
                {notifications.map((item, index) => (
                  <ListItem key={`item-${item.id}`} sx={{ padding: !index ? 0 : '8px 0 0 0' }}>
                    <NotificationItem notification={item} isVariantBig={true} />
                  </ListItem>
                ))}
              </List>
            ) : (
              <EmptyMessageList sx={{ mt: '18px', mb: '18px' }} />
            )}
          </Grid>
        </Container>
      </IonContent>
    </IonPage>
  );
};
