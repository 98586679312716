import { useEffect, useState } from 'react';
import { Challenge } from '../../../models/Challenge';
import { getJoinToChallenge } from '../../../redux/slices/challengeSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { CHALLENGE_STATUS } from '../../../models/enum/CHALLENGE_STATUS';
import { TEMPLATE_TYPE } from '../../../models/enum/TEMPLATE_TYPE';

export const useJoinHandle = (
  status?: CHALLENGE_STATUS,
  callback?: (challenge: Challenge) => void,
  updateData?: (type: CHALLENGE_STATUS) => void,
) => {
  const dispatch = useDispatch();
  const { joinToChallengeLoading } = useSelector((state: RootState) => state.challenge.meta);
  const [joinChallenge, setJoinChallenge] = useState<Challenge | null>(null);

  useEffect(() => {
    if (joinToChallengeLoading) {
      return;
    }
    setJoinChallenge(null);
  }, [joinToChallengeLoading]);
  const joinToChallengeRequest = async (winParams?: { action?: string; startDate: string; endDate: string }) => {
    if (!joinChallenge) return;

    const params =
      joinChallenge.challengeCharacteristic.templateType !== TEMPLATE_TYPE.WELLNESS
        ? { templateChallengeUUID: joinChallenge.metadata.templateChallengeUUID, status: status, ...winParams }
        : { challengeUUID: joinChallenge.metadata.challengeUUID, status: status, ...winParams };

    const dispatchData = await (dispatch(getJoinToChallenge(params)) as any).unwrap();
    if (updateData && dispatchData && status) {
      updateData(status);
    }
    if (callback) {
      callback(dispatchData.data);
    }
  };
  return {
    joinToChallengeLoading,
    joinChallenge,
    joinToChallengeRequest,
    setJoinChallenge,
  };
};
