import * as React from 'react';
import { Grid } from '@mui/material';

import CustomSubHeader from '../../structure/CustomSubHeader';
import { useEffect, useState } from 'react';
import { ReactComponent as PieIcon } from '../../../assets/svg/PieChart.svg';

import { getResultsLatest } from '../../../lib/api/http/requests/mentalAssessment';
import { IResult } from '../../../models/MentalAssessment';
import { CustomButton } from '../CustomButton';
import { TestResultItem } from './TestResultItem';
import { useIonRouter } from '@ionic/react';

export const TestResultsCard: React.FC<{ visible: boolean }> = ({ visible }) => {
  const router = useIonRouter();
  const [testResults, setTestResults] = useState<IResult | null>(null);

  const getResults = async () => {
    const response = await getResultsLatest();
    setTestResults(response.data);
  };

  useEffect(() => {
    getResults();
  }, []);
  useEffect(() => {
    if (!visible) return;
    getResults();
  }, [visible]);

  return testResults ? (
    <Grid item xs={12} mb="40px">
      <TestResultItem item={testResults}>
        <Grid item container maxWidth="lg" justifyContent={'space-between'} sx={{ mb: { xs: '16px', table: '8px' } }}>
          <Grid item md={9} table={12} xs={12}>
            <CustomSubHeader
              label={`WellVox results`}
              tooltip={
                'A snapshot of your current mental wellness to guide you towards a more balanced daily experience.'
              }
            />
          </Grid>
          <Grid
            item
            md={3}
            table={12}
            xs={12}
            sx={{ justifyContent: 'end', display: 'flex', mt: { xs: '8px', table: '0px' } }}
          >
            <CustomButton
              type={'white'}
              text={'History'}
              minWidth={'118px'}
              onClick={() => router.push(`/page/mental-assessment-results`)}
              sx={{ fontWeight: 700, width: { xs: '100%', table: 'auto' } }}
              endIcon={<PieIcon width={16} height={16} />}
            />
          </Grid>
        </Grid>
      </TestResultItem>
    </Grid>
  ) : null;
};
