export enum ANALYTICS_PAGE {
  FOCUS = 'focus',
  FOCUS_ADMIN = 'focus_admin',
  TOOLBOX = 'toolbox',
  TOOLBOX_ADMIN = 'toolbox_admin',
  JOURNEY = 'journey',
  JOURNEY_ADMIN = 'journey_admin',
  REWARDS = 'rewards',
  CHALLENGE_DETAILS = 'challenge-details',
  CHALLENGE_ADMIN_DETAILS = 'challenge-admin-details',
  CHALLENGES = 'challenges',
  CHALLENGE_ACTIVITY = 'challenge_activity',
  CHALLENGES_VIEW_ALL = 'challenges_view_all',
  CHALLENGES_ADMIN_VIEW_ALL = 'challenges_admin_view_all',
  CHALLENGES_ADMIN = 'challenges_admin',
  EMPLOYEES_REWARDS = 'employees_rewards',
  EMPLOYEES = 'employees',
  EMPLOYEES_STEPS = 'employees_steps',
  TEAM_DETAILS = 'team_details',
  PROFILE = 'profile',
  SUPPORT = 'support',
  LOGIN = 'login',
  NOTIFICATIONS = 'notifications',
  NOTIFICATION_SETTINGS = 'notification-settings',
  EDIT_CHALLENGE = 'edit-challenge',
  TROPHY_LIBRARY = 'trophy-library',
  POINTS = 'points',
  REDEMPTION = 'redemption',
  LOG_METRICS = 'log-metrics',
  MENTAL_ASSESSMENT_RESULTS = 'mental_assessment_results',
}
