import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import CustomSwitch from '../CustomSwitch';

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    borderRadius: '50px',
    color: theme.palette.secondary.dark,
    backgroundColor: theme.palette.common.white,
    '&.Mui-disabled': {
      color: theme.palette.common.white,
    },

    '&.Mui-error': {
      backgroundColor: theme.palette.error.light,
    },
    '&.Mui-focused': {
      color: theme.palette.secondary.dark,
    },
    '&:hover:not($disabled):not($focused):not($error)': {
      color: theme.palette.secondary.main,
    },
  },
  '& .MuiFormHelperText-root': {
    display: 'flex',
    justifyContent: 'center',
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.text.primary,
    fontSize: 22,
    fontWeight: 'bold',
    marginTop: '-15px',
    '&.Mui-disabled': {
      color: theme.palette.text.primary,
      fontSize: 22,

      marginTop: '-15px',
    },
  },
}));

interface Props {
  value: boolean;
  setValue: (value: boolean) => void;
  inputProps?: TextFieldProps;
}

const CustomTextFieldSwitch: React.FC<Props> = ({ value, setValue, inputProps }) => {
  return (
    <CustomTextField
      {...inputProps}
      sx={{ input: { color: 'text.primary' } }}
      InputProps={{
        notched: false,
        endAdornment: (
          <InputAdornment position="end">
            <CustomSwitch value={value} setValue={setValue} />
          </InputAdornment>
        ),
      }}
      disabled
      size="small"
      value="Set as goal"
      InputLabelProps={{ shrink: true, disableAnimation: true }}
      fullWidth
      variant="outlined"
      color="secondary"
    />
  );
};

export default CustomTextFieldSwitch;
