import { Capacitor } from '@capacitor/core';
import { SxProps, Theme } from '@mui/material';

export const styles: { [key: string]: SxProps<Theme> } = {
  container: {
    display: 'flex',
    alignItems: 'center',
    background: '#07393E',
    height: 64,
    // height: 64 + (Capacitor.getPlatform() === 'ios' ? 30 : 0),
    // paddingTop: Capacitor.getPlatform() === 'ios' ? '30px' : 0,
  },
  button: {
    background: '#094C53',
    height: 40,
    borderRadius: 20,
    paddingLeft: '2px',
    alignItems: 'center',
    mr: '8px',
    ml: { xs: '6px', md: '12px' },
    '&:hover': {
      background: '#0D727C',
    },
  },
  buttonBlue: {
    background: '#0D727C',
    height: 40,
    borderRadius: 20,
    paddingLeft: '2px',
    alignItems: 'center',
    mr: '8px',
    ml: { xs: '10px', md: '8px' },
    pr: { xs: '2px', md: 0 },
    '&:hover': {
      background: '#1298A6',
    },
  },
  buttonRoundIcon: {
    display: 'flex',
    height: 36,
    width: 36,
    borderRadius: '50%',
    background: '#CFB216',
    border: '1px solid #D9C145',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonBlueRoundIcon: {
    display: 'flex',
    height: 36,
    width: 36,
    borderRadius: '50%',
    background: '#094C53',
    border: '1px solid #1298A6',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonLabel: {
    display: { xs: 'none', md: 'block' },
    ml: '10px',
    mr: '16px',
    fontSize: '14px',
    color: 'white',
    fontWeight: 'regular',
  },
  fillIconButton: {
    background: '#094C53',
    height: 40,
    width: 40,
    mr: { xs: '6px', md: '12px' },
    ml: { xs: '6px', md: '12px' },
    '&:hover': {
      background: '#0D727C',
      '& svg': {
        fill: '#16BECF',
      },
    },
  },
  divider: {
    display: { xs: 'none', md: 'block' },
    height: '24px',
    width: '1px',
    background: '#094C53',
    mr: '12px',
    ml: '12px',
  },
  profileText: { display: { xs: 'none', md: 'block' }, mr: '12px', ml: '12px', fontSize: '14px', color: '#A2E5EC' },
  avatarButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    mr: { xs: '6px', md: '12px' },
    ml: { xs: '6px', md: '12px' },
  },
  iconButton: {
    height: 36,
    width: 36,
    '&:hover': {
      background: '#084349',
      '& svg': {
        stroke: '#16BECF',
      },
    },
  },
} as const;
