import React from 'react';
import { Box, SxProps, Theme } from '@mui/material';

type TableRowProps = {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
};

export const TableRow: React.FC<TableRowProps> = ({ children, sx }) => (
  <Box
    sx={{
      display: 'flex',
      height: '60px',
      width: '100%',
      alignItems: 'stretch',
      ...sx,
    }}
  >
    {children}
  </Box>
);
