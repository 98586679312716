import { TextField, TextFieldProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    borderRadius: '50px',
    color: theme.palette.secondary.dark,
    backgroundColor: theme.palette.common.white,
    '&.Mui-disabled': {
      color: theme.palette.common.white,
    },

    '&.Mui-error': {
      backgroundColor: theme.palette.error.light,
    },
    '&.Mui-focused': {
      color: theme.palette.secondary.dark,
    },
    '&:hover:not($disabled):not($focused):not($error)': {
      color: theme.palette.secondary.main,
    },
  },
  '& .MuiFormHelperText-root': {
    display: 'flex',
    justifyContent: 'center',
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.text.primary,
    fontSize: 22,
    fontWeight: 'bold',
    marginTop: '-15px',
    '&.Mui-disabled': {
      color: theme.palette.text.primary,
      fontSize: 22,

      marginTop: '-15px',
    },
  },
}));

interface Props {
  label: string;
  error?: boolean;
  inputProps?: TextFieldProps;
  name: string;
  value: any;
  bold?: boolean;
  setValue?: (value: any) => void;
  endAdornment?: any;
  type?: string;
}

const InputField: React.FC<Props> = ({
  label,
  error,
  inputProps,
  value,
  setValue,
  name,
  endAdornment,
  bold,
  type = 'text',
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const getEndAdornment = () => {
    if (showPassword) {
      return <VisibilityIcon style={{ cursor: 'pointer' }} onClick={() => setShowPassword((prev) => !prev)} />;
    } else {
      return <VisibilityOffIcon style={{ cursor: 'pointer' }} onClick={() => setShowPassword((prev) => !prev)} />;
    }
  };

  const getType = () => {
    if (type === 'password') {
      if (showPassword) return 'text';
      else return 'password';
    } else return type;
  };

  return (
    <CustomTextField
      {...inputProps}
      sx={{ input: { color: 'text.primary' }, ...inputProps?.sx }}
      InputProps={{
        notched: false,
        endAdornment: type === 'password' ? getEndAdornment() : endAdornment,
        style: { fontWeight: bold ? 'bold' : '' },
        ...inputProps?.InputProps,
      }}
      name={name}
      size="small"
      onChange={setValue}
      value={value}
      label={label}
      error={error}
      InputLabelProps={{ shrink: true, disableAnimation: true }}
      inputProps={{ inputMode: type === 'number' ? 'decimal' : undefined }}
      fullWidth
      variant="outlined"
      color="secondary"
      type={getType()}
    />
  );
};

export default InputField;
