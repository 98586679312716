import React, { useEffect } from 'react';
import { Box, Fade, List, Slide, Typography } from '@mui/material';
import { Colors } from '../../../theme/colors';
import Grid from '@mui/material/Grid';
import { ReactComponent as CloseIcon } from '../../../assets/svg/feature/close.svg';
import { Capacitor } from '@capacitor/core';

type Props = {
  show: boolean;
  title?: string;
  action: React.ReactNode;
  short?: boolean;
  handleClose: () => void;
};
export const MobileFilterContainer: React.FC<Props> = ({
  show,
  title = ' Filters',
  action,
  short,
  handleClose,
  children,
}) => {
  return (
    <>
      {short && (
        <Fade in={show} unmountOnExit>
          <Box
            sx={{
              position: 'fixed',
              right: 0,
              bottom: 0,
              top: 0,
              left: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 11,
            }}
          ></Box>
        </Fade>
      )}
      <Slide mountOnEnter unmountOnExit direction={'up'} in={show}>
        <Box
          sx={{
            position: 'fixed',
            top: 'auto',
            bottom: 0,
            background: Colors.white,
            boxShadow: '0px 4px 10px rgba(65, 74, 79, 0.05)',
            borderTopRightRadius: '20px',
            borderTopLeftRadius: '20px',
            width: '100%',
            minHeight: '281px',
            maxHeight: '100%',
            height: short ? 'auto' : '100%',
            padding: '24px 16px 47px 16px',
            paddingTop: Capacitor.getPlatform() === 'ios' ? '68px' : '24px',
            zIndex: 12,
          }}
        >
          <Grid
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            <Typography
              sx={{
                fontWeight: '700',
                fontSize: '18px',
                color: Colors.gray[700],
                textAlign: 'center',
              }}
            >
              {title}
            </Typography>

            <Box
              component="button"
              sx={{
                height: 28,
                width: 28,
                padding: 0,
                borderRadius: 38,
                background: '#F0F2F3',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                position: 'absolute',
                top: 0,
                right: 0,
              }}
              onClick={handleClose}
            >
              <CloseIcon fill={Colors.gray[400]} />
            </Box>
          </Grid>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: 'calc(100% - 27px)',
            }}
          >
            <List
              sx={{
                margin: '16px 0 0 0',
                padding: '0 0 0 0',
                width: '100%',
                position: 'relative',
                overflow: 'auto',
                maxHeight: '100%',
                height: 'calc(100% - 125px)',
                '& ul': { padding: 0 },
                '&.MuiList-root': {
                  '&::-webkit-scrollbar-track': { background: '#F0F2F3!important' },
                  '&::-webkit-scrollbar-thumb': { background: '#D3D7DA!important' },
                  '&::-webkit-scrollbar-track:hover': { background: '#F0F2F3!important' },
                  '*::-webkit-scrollbar': {
                    width: '5px!important',
                    height: '5px !important',
                  },
                },
              }}
            >
              {children}
            </List>

            {action}
          </Box>
        </Box>
      </Slide>
    </>
  );
};
