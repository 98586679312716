import React from 'react';
import { useRadialChart } from '../../../hooks/useRadialChart';

type RadialChartProps = {
  selfPercent: number;
  teamPercent?: number;
  handleOpenTooltip?: (x: number, y: number, isTeam?: boolean) => void;
  handleCloseTooltip?: () => void;
};

export const RadialChart: React.FC<RadialChartProps> = ({
  selfPercent = 0,
  teamPercent,
  handleCloseTooltip,
  handleOpenTooltip,
}) => {
  const centerX = 100;
  const centerY = 100;
  const radius = 75;

  const { describeArc } = useRadialChart({ centerX, centerY, radius });

  const handleMouseIn = (isTeam: boolean) => (event: React.MouseEvent<SVGPathElement, MouseEvent>) => {
    if (handleOpenTooltip) {
      handleOpenTooltip(event.pageX, event.pageY, isTeam);
    }
  };

  const handleMouseOut = () => {
    if (handleCloseTooltip) {
      handleCloseTooltip();
    }
  };

  return (
    <>
      <svg height="100%" width="100%" viewBox="0 0 200 200">
        <linearGradient id="gradient-1" x1="0%" y1="0%" x2="120%" y2="100%">
          <stop offset="0%" stopColor="#FAAF1F" />
          <stop offset="100%" stopColor="#FDDFA5" />
        </linearGradient>
        <linearGradient id="gradient-2" x1="0%" y1="0%" x2="120%" y2="100%">
          <stop offset="0%" stopColor="#CEA2EC" />
          <stop offset="100%" stopColor="#DAB9F1" />
        </linearGradient>
        {(teamPercent && teamPercent !== 100) || selfPercent !== 100 ? (
          <path
            d={describeArc(teamPercent && teamPercent > selfPercent ? teamPercent : selfPercent, 100)}
            stroke="rgba(255,255,255, 0.2)"
            fill="transparent"
            strokeWidth={10}
          />
        ) : null}
        {teamPercent ? (
          <path
            onMouseEnter={handleMouseIn(true)}
            onMouseOut={handleMouseOut}
            d={
              teamPercent < selfPercent
                ? describeArc(0, teamPercent)
                : describeArc(teamPercent >= selfPercent ? 0 : selfPercent, teamPercent)
            }
            filter="drop-shadow(2px 2px 5px rgba(181, 115, 226, 0.25))"
            stroke="url(#gradient-2)"
            fill="transparent"
            strokeWidth={30}
          />
        ) : null}
        {teamPercent !== selfPercent && (
          <path
            onMouseEnter={handleMouseIn(false)}
            onMouseOut={handleMouseOut}
            d={
              teamPercent && selfPercent > teamPercent
                ? describeArc(teamPercent, selfPercent)
                : describeArc(0, selfPercent)
            }
            filter="drop-shadow(-2px -2px 5px rgba(250, 175, 31, 0.35))"
            stroke="url(#gradient-1)"
            fill="transparent"
            strokeWidth={30}
          />
        )}
      </svg>
    </>
  );
};
