import { openToast } from '../redux/slices/appSlice';
import { dispatch, getStore } from '../index';
import { Capacitor } from '@capacitor/core';

export const handleBackendError = (error: any, fallbackMsg: string) => {
  console.log('Request failed', error, fallbackMsg);
  const state = getStore();

  const errorObject = Capacitor.isNativePlatform() ? JSON.parse(error?.error) : error?.response?.data;

  if ((errorObject?.custom_message || errorObject?.customMessage) && errorObject?.message) {
    dispatch(openToast({ text: errorObject.message, type: 'error' }));
  } else if (state && !state.app.wifiConnected) {
    dispatch(openToast({ text: 'Please check your internet connection or try again later', type: 'error' }));
  } else {
    dispatch(openToast({ text: fallbackMsg, type: 'error' }));
  }
};
