import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getRewardsRequest } from '../../lib/api/http/requests/rewards';
import { UserReward } from '../../models/UserReward';
import { setHeaderLoading } from './appSlice';

export const getRewards = createAsyncThunk('rewards/getRewards', async (_, thunkAPI) => {
  try {
    thunkAPI.dispatch(setHeaderLoading(true));
    const { data } = await getRewardsRequest();
    return { data };
  } catch (error) {
    console.log(error);
  } finally {
    thunkAPI.dispatch(setHeaderLoading(false));
  }
});

export interface RewardsState {
  data: UserReward;
  meta: {
    loading: boolean;
  };
}

const InitialData: UserReward = {
  emp_uuid: '',
  name: '',
  avatar: '',
  consistency_rewards: { position: null, points: null, badge: 'ΝΟΝΕ' },
  performance_rewards: { position: null, points: null, badge: 'ΝΟΝΕ' },
};

const initialState: RewardsState = {
  data: InitialData,
  meta: {
    loading: false,
  },
};

export const rewardsSlice = createSlice({
  name: 'rewards',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRewards.fulfilled, (state, action) => {
      state.data = action.payload?.data || InitialData;
      state.meta.loading = false;
    });
    builder.addCase(getRewards.rejected, (state) => {
      state.meta.loading = false;
    });
    builder.addCase(getRewards.pending, (state) => {
      state.meta.loading = true;
    });
  },
});

export default rewardsSlice.reducer;
