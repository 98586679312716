import { IonContent, IonPage } from '@ionic/react';
import React, { useMemo } from 'react';
import CustomNewHeader from '../../components/features/CustomNewHeader';
import { ReactComponent as JourneyIcon } from '../../assets/svg/journey.svg';
import { METRICS_CATEGORY } from '../../models/enum/METRICS_CATEGORY';
import { useLocation } from 'react-router';
import { Box, Container, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { useHandle } from './hooks/useHandle';
import { MetricItem } from './components/MetricItem';
import { Colors } from '../../theme/colors';
import { metricsPillar } from '../../models/MetricsPillar';
import { LogData } from './components/LogData';
import { LIST_METRIC_CATEGORY } from './helpers';
import { FilterSelector } from '../../components/features/HeaderFilter/FilterSelector';
import { ReactComponent as PlusIcon } from '../../assets/svg/plus.svg';
import { CustomButton } from '../../components/features/CustomButton';
import { ReactComponent as SaveIcon } from '../../assets/svg/feature/save.svg';
import theme from '../../theme';
import CustomCalendar from '../../components/common/CustomCalendar';
import { MobileLogData } from './components/MobileLogData';
import moment from 'moment';

export type RouteParams = {
  focusDate?: string;
  category?: METRICS_CATEGORY;
  metricType?: string;
  metricTitle?: string;
  isCreateMetric?: 'true' | 'false';
};

export const MetricsLogDataPage: React.FC = () => {
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));
  const { search } = useLocation();
  const searchParams = useMemo(() => {
    const params = new URLSearchParams(search);
    const objectParams: RouteParams = {};
    if (params.get('focusDate')) objectParams.focusDate = params.get('focusDate') as string;
    if (params.get('category')) objectParams.category = params.get('category') as METRICS_CATEGORY;
    if (params.get('metricType')) objectParams.metricType = params.get('metricType') as string;
    if (params.get('isCreateMetric')) objectParams.isCreateMetric = params.get('isCreateMetric') as 'true' | 'false';
    if (params.get('metricTitle')) objectParams.metricTitle = params.get('metricTitle') as string;
    return objectParams;
  }, [search]);
  const {
    isUpdating,
    isLoading,
    selectedCategory,
    listMetric,
    focusedMetric,
    isOpenLogData,
    focusedTitle,
    isMine,
    timeline,
    isDisableSave,
    metricTotalByFocusedDate,
    handleChangeMetricType,
    handleChangeCategory,
    openCreateMetric,
    handleSubmitUpdate,
    handleCreateMetric,
    handleCloseLogData,
    handleMobileSave,
    ...logDataProps
  } = useHandle(searchParams);

  return (
    <IonPage>
      <CustomNewHeader
        breadcrumb={{
          Icon: JourneyIcon,
          list: [
            { label: 'Your Journey', url: '/page/your-journey' },
            { label: 'Log your data', url: '/page/metrics-log-data' },
            ...(searchParams.isCreateMetric === 'true'
              ? [{ label: 'Add a new metric', url: '/page/metrics-log-data?isCreateMetric=true' }]
              : []),
          ],
        }}
        label={searchParams.isCreateMetric === 'true' ? 'Add a new metric' : 'Log your data'}
        rightAction={
          !xsDown ? (
            <Box display="flex" ml="auto">
              <CustomButton
                type="bigBlue"
                text="Save"
                disabled={(isDisableSave && !searchParams?.isCreateMetric) || isUpdating}
                minWidth="160px"
                sx={{
                  padding: '10px 16px',
                  height: '36px',
                  ...(searchParams?.isCreateMetric && { marginRight: '16px' }),
                }}
                inputProps={{
                  variant: 'contained',
                  startIcon: <SaveIcon width={16} height={16} fill={Colors.white} />,
                }}
                onClick={searchParams?.isCreateMetric ? handleCreateMetric : handleSubmitUpdate}
              />
              {searchParams?.isCreateMetric && (
                <CustomButton
                  type="bigLightBlue"
                  text="Cancel"
                  disabled={isUpdating}
                  minWidth="160px"
                  sx={{ padding: '10px 16px', height: '36px', marginLeft: 'auto', marginRight: '16px' }}
                  inputProps={{
                    variant: 'contained',
                  }}
                  onClick={() => history.back()}
                />
              )}
            </Box>
          ) : null
          // searchParams?.isCreateMetric && (
          //   <Box
          //     component="button"
          //     sx={{
          //       display: 'flex',
          //       alignItems: 'center',
          //       justifyContent: 'center',
          //       height: '36px',
          //       width: '36px',
          //       borderRadius: '50%',
          //       marginLeft: 'auto',
          //       background: Colors.blue[500],
          //     }}
          //     onClick={handleOpenLogData}
          //   >
          //     <PlusIcon />
          //   </Box>
          // )
        }
      />
      <IonContent style={{ '--padding-bottom': '36px' }} scrollEvents={true}>
        <Container
          maxWidth="lg"
          sx={{
            paddingTop: { xs: 0, sm: '24px' },
            height: { xs: '100%', sm: 'unset' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              background: Colors.white,
              width: { sm: '100%', md: 'fit-content' },
              borderRadius: '20px',
              padding: !xsDown ? '32px' : '16px',
              margin: '0 auto',
              height: { xs: '100%', sm: 'unset' },
              overflow: { xs: 'hidden', sm: 'unset' },
            }}
          >
            <Box sx={{ flex: 1, marginBottom: '24px', minWidth: { sm: 'unset', md: '378px' } }}>
              {xsDown && (
                <Box sx={{ mb: '16px' }}>
                  <CustomCalendar
                    date={logDataProps.focusedDate.toString()}
                    setDate={(date) => logDataProps.handleChangeDay(new Date(date))}
                  />
                </Box>
              )}
              <Box
                sx={{ overflow: 'scroll', height: '100%', ml: '-16px', mr: '-16px', paddingInline: '16px', pb: '16px' }}
              >
                <Box display="flex" justifyContent="center" mb="24px">
                  <FilterSelector
                    data={LIST_METRIC_CATEGORY}
                    selected={[selectedCategory]}
                    bgColor={Colors.gray[50]}
                    onClickItem={handleChangeCategory}
                    sx={{ flexDirection: 'row' }}
                    tabMode
                  />
                </Box>

                {isLoading ? (
                  <>
                    {new Array(10).fill('1').map((_, idx) => (
                      <Skeleton
                        height={30}
                        width={xsDown ? '100%' : 378}
                        sx={{ borderRadius: '10px', marginBottom: '8px' }}
                        key={`skeleton-${idx}`}
                      />
                    ))}
                  </>
                ) : (
                  metricsPillar.map((pillar) => {
                    const filteredMetric = listMetric.filter((item) => item.pillar === pillar.value);
                    if (!filteredMetric.length) return null;
                    if (selectedCategory === METRICS_CATEGORY.MINE) {
                      if (pillar.value === 'OTHER') {
                        return (
                          <Box key={pillar.value} sx={{ mb: '16px' }}>
                            {filteredMetric.map((metric) => (
                              <MetricItem
                                key={metric.title}
                                title={metric.title}
                                value={
                                  metricTotalByFocusedDate?.[`${metric.metricType}${metric.title}`] ?? metric.totalValue
                                }
                                tooltip={metric.tooltip}
                                metricUnit={metric.metricUnit}
                                isSelected={metric.metricType === focusedMetric && metric.title === focusedTitle}
                                isAuto={metric.isAuto}
                                isMine={selectedCategory === METRICS_CATEGORY.MINE}
                                type={metric.metricType}
                                onClick={handleChangeMetricType(metric.metricType, metric.title)}
                              />
                            ))}
                          </Box>
                        );
                      }
                    }
                    return (
                      <Box key={pillar.value} sx={{ mb: '16px' }}>
                        <Box display="flex" alignItems="center" sx={{ mb: '8px' }}>
                          <pillar.icon fill={Colors.gray[700]} height="16px" width="16px" />
                          <Typography sx={{ marginLeft: '8px', fontWeight: 'bold', color: Colors.gray[700] }}>
                            {pillar.label}
                          </Typography>
                        </Box>
                        {filteredMetric.map((metric) => (
                          <MetricItem
                            key={metric.metricType}
                            title={metric.title}
                            value={
                              metricTotalByFocusedDate?.[`${metric.metricType}${metric.title}`] ?? metric.totalValue
                            }
                            tooltip={metric.tooltip}
                            metricUnit={metric.metricUnit}
                            isSelected={metric.metricType === focusedMetric && metric.title === focusedTitle}
                            isAuto={metric.isAuto}
                            isMine={isMine}
                            type={metric.metricType}
                            onClick={handleChangeMetricType(metric.metricType, metric.title)}
                          />
                        ))}
                      </Box>
                    );
                  })
                )}
                {isMine && searchParams?.isCreateMetric !== 'true' && (
                  <Box
                    component="button"
                    display="flex"
                    alignItems="center"
                    sx={{ background: 'unset', marginLeft: 'auto', marginRight: 'auto' }}
                    onClick={openCreateMetric}
                  >
                    <PlusIcon fill={Colors.blue[500]} width={16} height={16} />
                    <Typography sx={{ marginLeft: '8px', color: Colors.blue[500], textDecoration: 'underline' }}>
                      Create your own metric
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
            {!xsDown ? (
              <Box>
                <LogData
                  isCreateMetric={searchParams?.isCreateMetric ? true : false}
                  metric={listMetric.find((metric) =>
                    isMine
                      ? metric.metricType === focusedMetric && metric.title === focusedTitle
                      : metric.metricType === focusedMetric,
                  )}
                  timeline={timeline.map((item) => ({ date: item.date, value: item.teamValue ?? 0 }))}
                  {...logDataProps}
                />
              </Box>
            ) : (
              <Box>
                <MobileLogData
                  isCreateMetric={searchParams?.isCreateMetric ? true : false}
                  timeline={timeline.map((item) => ({ date: item.date, value: item.teamValue ?? 0 }))}
                  metric={listMetric.find((metric) =>
                    isMine
                      ? metric.metricType === focusedMetric && metric.title === focusedTitle
                      : metric.metricType === focusedMetric,
                  )}
                  {...{
                    isOpenLogData,
                    isUpdating,
                    handleCloseLogData,
                    handleMobileSave,
                    handleCreateMetric,
                    ...logDataProps,
                  }}
                />
              </Box>
            )}
          </Box>
        </Container>
      </IonContent>
    </IonPage>
  );
};
