import React from 'react';
import { Box, SxProps, Tooltip, Typography, useMediaQuery, useTheme, Theme } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { Colors } from '../../theme/colors';
type CustomSubHeaderProps = {
  label?: string;
  tooltip?: NonNullable<React.ReactNode>;
  breakpoint?: 'xs' | 'sm' | 'md' | 'lg' | number;
  sx?: SxProps<Theme>;
};

const CustomSubHeader: React.FC<CustomSubHeaderProps> = ({ label, tooltip, breakpoint = 'sm', sx }) => {
  const theme = useTheme();
  const breakpointDown = useMediaQuery(theme.breakpoints.down(breakpoint));
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box display={'flex'} alignItems={'center'} sx={{ whiteSpace: 'nowrap', ...sx }}>
      <Typography
        sx={{
          fontSize: xsDown || breakpointDown ? 18 : 22,
          fontWeight: 700,
          textAlign: xsDown || breakpointDown ? 'center' : 'start',
          color: Colors.gray[700],
        }}
      >
        {label}
      </Typography>
      {tooltip ? (
        <Tooltip
          title={<Typography variant={'body2'}>{tooltip}</Typography>}
          placement={xsDown || breakpointDown ? 'bottom-end' : 'right-start'}
          enterTouchDelay={0}
          leaveTouchDelay={10000}
          sx={{ mx: 1, fontSize: xsDown || breakpointDown ? 16 : 20, color: theme.palette.grey[600] }}
        >
          <InfoOutlined fontSize={'inherit'} color={'inherit'} />
        </Tooltip>
      ) : null}
    </Box>
  );
};

export default CustomSubHeader;
