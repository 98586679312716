import React, { useRef } from 'react';
import { Typography, Box, Skeleton } from '@mui/material';
import Grid from '@mui/material/Grid';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useHandle } from './useHandle';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ChallengeCard } from '../ChallengeCard/ChallengeCard';
import { Swiper as SwiperType, Navigation } from 'swiper';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { Challenge } from '../../../models/Challenge';
import { CHALLENGE_STATUS } from '../../../models/enum/CHALLENGE_STATUS';
import { Colors } from '../../../theme/colors';
import { ChallengeJoinModal } from '../modals/ChallengeJoinModal';
import { useJoinHandle } from '../../../pages/ChallengesPage/hooks/useJoinHandle';
import { usePaginateHandle } from './usePaginateHandle';
import { StyledIconButton } from '../StyledIconButton';
import { EmptyState } from '../EmptyState';
import { useHandleCardsLogData } from '../CardsLogData/useHandleCardsLogData';
import { CardsLogData } from '../CardsLogData';

type Props = {
  status: CHALLENGE_STATUS;
  list: Challenge[];
  title: string;
  path: string;
  sxContainer?: SxProps<Theme>;
  isLoading?: boolean;
  showJoin?: boolean;
  isAdmin?: boolean;
  pageSize: number;
  /**
   * @param currentPage  minimum is 0
   */
  currentPage: number;
  totalItems: number;
  setPage: (p: number) => void;
  updateData?: (type: CHALLENGE_STATUS) => void;
  updateDataAfterLog?: (type: CHALLENGE_STATUS) => void;
};

export const ChallengeCardList: React.FC<Props> = ({
  status = CHALLENGE_STATUS.ACTIVE,
  list,
  title,
  path,
  sxContainer = {},
  isLoading,
  showJoin = true,
  isAdmin = false,
  pageSize,
  currentPage,
  totalItems,
  setPage,
  updateData,
  updateDataAfterLog,
}) => {
  const {
    slideOptions,
    xsDown,
    setIsSliderBeginning,
    setIsSliderEnd,
    goPreviousDisabled,
    goNextDisabled,
    handleViewAll,
    handleUpdateChallenge,
  } = useHandle(status);
  const { joinToChallengeLoading, joinChallenge, joinToChallengeRequest, setJoinChallenge } = useJoinHandle(
    status,
    () => undefined,
    updateData,
  );
  const { isPaginateLoading, validateToNext } = usePaginateHandle({ pageSize, currentPage, totalItems, setPage });
  const swiperRef = useRef<SwiperType>();

  const { setChallenge, ...cardLogDataProps } = useHandleCardsLogData(
    () => (updateDataAfterLog ? updateDataAfterLog(status) : null),
    handleUpdateChallenge,
  );

  return (
    <Box sx={sxContainer}>
      <Grid container wrap={'nowrap'} alignItems={'center'} justifyContent={'space-between'}>
        <Grid item sx={{ my: '4px' }}>
          <Typography
            sx={{
              fontSize: xsDown ? 16 : 22,
              fontWeight: 'bold',
              color: Colors.gray[700],
            }}
          >
            {title}
          </Typography>
        </Grid>

        {list.length > 0 && (
          <Grid item sx={{ my: '4px' }}>
            <Grid container wrap={'nowrap'} sx={{ whiteSpace: 'nowrap' }} alignItems={'center'}>
              <Grid item onClick={() => swiperRef.current?.slidePrev()}>
                <StyledIconButton sxContainer={{ marginRight: '10px' }} disabled={goPreviousDisabled()}>
                  <ChevronLeftIcon />
                </StyledIconButton>
              </Grid>
              <Grid item onClick={() => swiperRef.current?.slideNext()}>
                <StyledIconButton disabled={goNextDisabled() || totalItems <= 4}>
                  <ChevronRightIcon />
                </StyledIconButton>
              </Grid>

              <Grid item>
                <Typography
                  color={Colors.gray[700]}
                  sx={{
                    fontSize: 16,
                    fontWeight: 400,
                    color: 'text.primary',
                    textDecoration: 'underline',
                    marginLeft: '24px',
                    '&:hover': {
                      textDecoration: 'none',
                      cursor: totalItems <= 0 ? 'not-allowed' : 'pointer',
                    },
                  }}
                  onClick={() => (totalItems <= 0 ? undefined : handleViewAll(path))}
                >
                  View all ({totalItems})
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      {isPaginateLoading && isLoading ? (
        <Box sx={{ flex: 1, overflow: 'hidden', marginTop: '16px', paddingBottom: '26px' }}>
          <Box sx={{ width: 276 * 5, display: 'flex', flexDirection: 'row' }}>
            <Skeleton
              component="div"
              variant="rectangular"
              style={{ height: 319, width: 276, borderRadius: '20px', marginRight: '24px' }}
            />
            <Skeleton
              component="div"
              variant="rectangular"
              style={{ height: 319, width: 276, borderRadius: '20px', marginRight: '24px' }}
            />
            <Skeleton
              component="div"
              variant="rectangular"
              style={{ height: 319, width: 276, borderRadius: '20px', marginRight: '24px' }}
            />
            <Skeleton
              component="div"
              variant="rectangular"
              style={{ height: 319, width: 276, borderRadius: '20px', marginRight: '24px' }}
            />
            <Skeleton
              component="div"
              variant="rectangular"
              style={{ height: 319, width: 276, borderRadius: '20px', marginRight: '24px' }}
            />
          </Box>
        </Box>
      ) : list.length <= 0 ? (
        <EmptyState />
      ) : (
        <Swiper
          {...slideOptions}
          modules={[Navigation]}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          onReachEnd={() => {
            validateToNext();
          }}
          onSlideChange={(swiper) => {
            setIsSliderBeginning(swiper.isBeginning);
            setIsSliderEnd(swiper.isEnd);
          }}
          onSlidesLengthChange={(swiper) => {
            setTimeout(() => {
              setIsSliderBeginning(swiper.isBeginning);
              if (swiper.isBeginning) return;
              setIsSliderEnd(swiper.isEnd);
            }, 100);
          }}
          key={status}
          style={{ marginTop: 16, paddingBottom: 26 }}
        >
          {list.map((challenge, idx) => (
            <SwiperSlide
              key={challenge?.metadata?.challengeUUID ?? challenge?.metadata?.templateChallengeUUID}
              style={{ height: '319px' }}
            >
              <ChallengeCard
                status={status}
                challenge={challenge}
                showJoin={showJoin}
                isAdmin={isAdmin}
                onJoin={(e) => {
                  e.stopPropagation();
                  setJoinChallenge(challenge);
                }}
                onPressLogData={setChallenge}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}

      <ChallengeJoinModal
        show={!!joinChallenge}
        title={joinChallenge ? joinChallenge.challengeInfo.title : ''}
        challenge={joinChallenge}
        loading={joinToChallengeLoading}
        onClose={() => setJoinChallenge(null)}
        onJoin={joinToChallengeRequest}
      />
      {status !== CHALLENGE_STATUS.INACTIVE && <CardsLogData {...cardLogDataProps} />}
    </Box>
  );
};
