import React from 'react';
import Grid from '@mui/material/Grid';
import { Colors } from '../../../theme/colors';
import { Typography } from '@mui/material';
import { SxProps } from '@mui/system';

export const SectionContainer: React.FC<{ title: string; sx?: SxProps }> = ({ title, children, sx }) => (
  <Grid
    sx={{
      padding: '24px',
      background: Colors.white,
      borderRadius: '20px',
      boxShadow: '5px 0px 15px rgba(108, 123, 132, 0.05)',
      ...sx,
    }}
  >
    <Typography
      sx={{
        fontWeight: 900,
        fontSize: { xs: '16px', md: '18px' },
        color: Colors.gray[700],
      }}
    >
      {title}
    </Typography>
    {children}
  </Grid>
);
