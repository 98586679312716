import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { AWARD_TRIGGER } from '../../../models/enum/AWARD_TRIGGER';
import { CHALLENGE_TYPE } from '../../../models/enum/CHALLENGE_TYPE';
import { CALCULATION_TYPE } from '../../../models/enum/CALCULATION_TYPE';
import { COMPETITION } from '../../../models/enum/COMPETITION';

export const useHandleProgress = () => {
  const isLoading = useSelector((state: RootState) => state.challengeProgress.meta.isLoading);
  const {
    user,
    detail: detailData,
    progress: progressData,
  } = useSelector((state: RootState) => state.challengeProgress.data);

  const value = progressData?.score ?? 0;
  const teamValue = detailData?.isTeam ? progressData?.teamScore ?? 33000 : undefined;

  const getValueLeft = () => {
    const score = teamValue ?? value;
    if (
      detailData?.awardTrigger === AWARD_TRIGGER.COMPLETION ||
      detailData?.challengeTyp === CHALLENGE_TYPE.TARGET ||
      detailData?.competition === COMPETITION.SELF ||
      detailData?.awardTrigger === AWARD_TRIGGER.FASTEST
    ) {
      if (
        detailData.competition === COMPETITION.SELF &&
        detailData.awardTrigger === AWARD_TRIGGER.COMPLETION &&
        (detailData.challengeTyp === CHALLENGE_TYPE.STICK_TO_IT ||
          detailData.challengeTyp === CHALLENGE_TYPE.LONGER_STREAK)
      ) {
        const target = progressData?.daysGoalValue ?? user?.target ?? 0;
        return score > target ? 0 : score - target;
      }
      if (detailData?.competition === COMPETITION.INDIVIDUAL) {
        const target = progressData?.daysGoalValue ?? user?.target ?? 0;
        return score > target ? 0 : score - target;
      }
      const target = user?.target ?? 0;
      return score > target ? 0 : score - target;
    }
    return progressData?.scoreToTop ?? 0;
  };

  const getChartProps = () => {
    const valueLeft = getValueLeft();

    if (typeof teamValue === 'number') {
      const onlyTeam =
        detailData?.challengeTyp === CHALLENGE_TYPE.STICK_TO_IT ||
        detailData?.challengeTyp === CHALLENGE_TYPE.LONGER_STREAK;
      const teamPercent = Math.round(
        valueLeft >= 0 && teamValue > 0 ? 100 : teamValue === 0 ? 0 : (teamValue / (teamValue - valueLeft)) * 100,
      );
      const selfPercent = Math.round(
        teamPercent === 100 && teamValue > value
          ? (value / teamValue) * 100
          : valueLeft >= 0 && value > 0
          ? 100
          : value === 0
          ? 0
          : (value / (value - valueLeft)) * 100,
      );
      return {
        valueLeft,
        selfPercent,
        teamPercent,
        onlyTeam,
      };
    }
    const selfPercent = valueLeft >= 0 && value > 0 ? 100 : value === 0 ? 0 : (value / (value - valueLeft)) * 100;
    return {
      valueLeft,
      selfPercent,
    };
  };

  return {
    isLoading,
    value,
    teamValue,
    ...getChartProps(),
    syncDate: progressData?.lastSync,
    metricTitle: progressData?.metricTitle ?? '',
    challengeType: detailData?.challengeTyp,
    awardTrigger: detailData?.awardTrigger,
  };
};
