import React, { FC } from 'react';
import { Box, CircularProgress, Typography, useMediaQuery } from '@mui/material';
import { ReactComponent as PlusIcon } from '../../../assets/svg/plus.svg';
import { Colors } from '../../../theme/colors';
import { CustomButton } from '../CustomButton';
import { CustomDialog } from '../../../components/features/CustomDialog';
import theme from '../../../theme';
import InputField from '../CustomTextField';
import { UploadImage } from '../../../pages/EditChallengePage/components/UploadImage';
import { TeamForm } from '../../../pages/ChallengeDetailPage/hooks/useTeamHandle';

interface ModalProps {
  show: boolean;
  isEdit?: boolean;
  form: TeamForm;
  loading: boolean;
  handleUpdateImage: (value: string) => void;
  handleChangeTeamName: (value: string) => void;
  handleChangeSlackUrl: (value: string) => void;
  onClose: () => void;
  onSave?: () => void;
  onCreate: () => void;
}

export const TeamModal: FC<ModalProps> = ({
  show = false,
  isEdit = false,
  loading,
  form,
  handleUpdateImage,
  handleChangeTeamName,
  handleChangeSlackUrl,
  onClose,
  onSave,
  onCreate,
}) => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const isActionDisabled = Boolean(form.teamName.length && (form.imageBase64.length || form.imageUrl?.length));
  return (
    <CustomDialog onClose={onClose} open={show}>
      <Box
        sx={{
          maxWidth: '480px',
          margin: '0 auto',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        {loading && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
              zIndex: 2,
              background: 'rgba(65, 74, 79, 0.3)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress color="inherit" style={{ width: 30, height: 30 }} />
          </Box>
        )}
        <Box sx={{ maxWidth: '100%', backgroundColor: Colors.white, margin: { xs: '0 16px', md: '0 40px' } }}>
          <Box display="flex" alignItems="center" sx={{ mt: '6px', maxWidth: '296px', mx: 'auto', mb: '16px' }}>
            <Typography
              variant="h5"
              sx={{ textAlign: 'center', color: Colors.gray[700], fontWeight: 'bold', fontSize: '18px', width: '100%' }}
            >
              {isEdit ? 'Edit team' : 'Create team'}
            </Typography>
          </Box>
          <Box sx={{ marginBottom: '32px' }}>
            <InputField
              bold={true}
              label={
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: 'fit-content',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 14,
                      lineHeight: 1.4,
                      color: 'text.primary',
                      mr: '4px',
                    }}
                  >
                    {'Team name'}
                  </Typography>
                </Box>
              }
              setValue={(e) => handleChangeTeamName(e.target.value)}
              name="title"
              value={form.teamName}
              placeholder={'Enter title'}
            />
          </Box>

          <UploadImage
            label={'Upload a team picture.'}
            imageUrl={form.imageUrl ? form.imageUrl : form.imageBase64}
            adminColors={false}
            onUpload={handleUpdateImage}
          />

          <Box sx={{ mt: '32px' }}>
            <InputField
              bold={true}
              label={
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 14,
                    lineHeight: 1.4,
                    color: 'text.primary',
                    mr: '4px',
                  }}
                >
                  Slack channel
                </Typography>
              }
              setValue={(e) => handleChangeSlackUrl(e.target.value)}
              name="slackUrl"
              value={form.slackUrl}
              placeholder={'Enter Slack link'}
            />
          </Box>
        </Box>

        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{ margin: mdDown ? '24px auto 34px' : '52px auto 50px', maxWidth: '316px', width: '100%' }}
        >
          {!isEdit && (
            <CustomButton
              disabled={!isActionDisabled}
              type={'bigBlue'}
              text={'Create'}
              inputProps={{
                variant: 'contained',
                startIcon: <PlusIcon width={16} height={16} />,
              }}
              onClick={onCreate}
            />
          )}
          {isEdit && onSave && (
            <CustomButton disabled={!isActionDisabled} type={'bigBlue'} text={'Save'} onClick={onSave} />
          )}
          <CustomButton type={'bigLightBlue'} text={'Cancel'} onClick={onClose} />
        </Box>
      </Box>
    </CustomDialog>
  );
};
