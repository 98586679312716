import { IonContent, IonPage } from '@ionic/react';
import React, { useState } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import StyledButton from '../components/common/Button';
import TooltipSupportModal from '../components/modals/TooltipSupportModal';
import CustomSubHeader from '../components/structure/CustomSubHeader';
import useViewIsVisible from '../hooks/useViewIsVisible';
import { ANALYTICS_PAGE } from '../models/enum/ANALYTICS_PAGE';
import CustomNewHeader from '../components/features/CustomNewHeader';

const SupportPage: React.FC = () => {
  const visible = useViewIsVisible(ANALYTICS_PAGE.SUPPORT);
  const [tutorialModal, setTutorialModal] = useState<boolean>(false);

  return (
    <IonPage>
      <CustomNewHeader label={'Our Support'} />
      <IonContent>
        <Container maxWidth="lg" sx={{ mb: 6 }}>
          <Grid container spacing={6} pt={4}>
            <Grid item md={6} xs={12}>
              <Box display="flex" height="100%" gap={3} flexDirection="column" justifyContent="space-between">
                <CustomSubHeader label={`Get Started`} />
                <Typography>View a step-by-step guide to help you get started with the basics.</Typography>
                <Box>
                  <StyledButton
                    inputProps={{ variant: 'contained', sx: { fontWeight: 'bold' } }}
                    text="Show me"
                    onClick={() => setTutorialModal(true)}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box display="flex" height="100%" gap={3} flexDirection="column" justifyContent="space-between">
                <CustomSubHeader label={`Our Knowledge Base`} />
                <Typography>
                  Find answers to your questions on your own and get useful tips on how to maximise your experience with
                  Wellics™.
                </Typography>
                <Box>
                  <StyledButton
                    inputProps={{ variant: 'contained', sx: { fontWeight: 'bold' } }}
                    text="Take me there"
                    onClick={() => window.open('https://www.wellics.com/en/knowledge', '_blank')}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box display="flex" height="100%" gap={3} flexDirection="column" justifyContent="space-between">
                <CustomSubHeader label={`Our Privacy Notice`} />
                <Typography>Find here our Privacy Notice.</Typography>
                <Box>
                  <StyledButton
                    inputProps={{ variant: 'contained', sx: { fontWeight: 'bold' } }}
                    text="Take me there"
                    onClick={() => window.open('https://www.wellics.com/privacy-notice', '_blank')}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box display="flex" height="100%" gap={3} flexDirection="column" justifyContent="space-between">
                <CustomSubHeader label={`Ask Us Anything`} />
                <Typography>
                  Can’t find your answer in the Knowledge Base? Ask us anything and we’ll get back soon to help you out.
                </Typography>
                <Box>
                  <StyledButton
                    inputProps={{ variant: 'contained', sx: { fontWeight: 'bold' } }}
                    text="Ask a question"
                    onClick={() => window.open('https://www.wellics.com/contact', '_blank')}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box display="flex" height="100%" gap={3} flexDirection="column" justifyContent="space-between">
                <CustomSubHeader label={`Our Commitment To Your Success`} />
                <Typography>
                  Because we believe in service excellence, our regular support should be enough for most of our
                  customers. Need a little more support for your business?
                </Typography>
                <Box>
                  <StyledButton
                    inputProps={{ variant: 'contained', sx: { fontWeight: 'bold' } }}
                    text="Talk to us"
                    onClick={() => window.open('https://www.wellics.com/contact', '_blank')}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box display="flex" height="100%" gap={3} flexDirection="column" justifyContent="space-between">
                <CustomSubHeader label={`Our Wellness Services`} />
                <Typography>
                  Need support for establishing a wellness strategy in your business? Find out more about our premium
                  wellness services.
                </Typography>
                <Box>
                  <StyledButton
                    inputProps={{ variant: 'contained', sx: { fontWeight: 'bold' } }}
                    text="Talk to us"
                    onClick={() => window.open('https://www.wellics.com/contact', '_blank')}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </IonContent>
      <TooltipSupportModal open={tutorialModal} handleClose={() => setTutorialModal(false)} />
    </IonPage>
  );
};

export default SupportPage;
