import { Box, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { Colors } from '../../../theme/colors';
import { numberWithSpaces } from '../../../utils/numberWithSpace';
import { useRadialChart } from '../../../hooks/useRadialChart';

type ChartProgressProps = {
  score: number;
  leftScore: number;
  selfPercent: number;
  teamPercent?: number;
  yourScore?: number;
  teamScore?: number;
  onlyTeam?: boolean;
};

export const ChartProgress: React.FC<ChartProgressProps> = ({
  score,
  leftScore,
  selfPercent,
  teamPercent,
  yourScore,
  teamScore,
  onlyTeam = false,
}) => {
  const centerX = 100;
  const centerY = 0;
  const radius = 85;

  const { describeArc } = useRadialChart({ centerX, centerY, radius, isSemicircle: true });

  const tooltipRef = useRef<HTMLDivElement>(null);

  const handleMouseIn = (isTeam: boolean) => (event: React.MouseEvent<SVGPathElement, MouseEvent>) => {
    if (typeof yourScore !== 'number' || typeof teamScore !== 'number' || onlyTeam) return;
    if (tooltipRef.current) {
      if ((teamScore && yourScore === teamScore) || teamPercent === 100 || teamPercent === 0 || !onlyTeam) {
        tooltipRef.current.children[0].innerHTML = `<b>You:</b> ${numberWithSpaces(
          yourScore,
        )}<br/><b>Team:</b> ${numberWithSpaces(teamScore)}`;
      } else {
        tooltipRef.current.children[0].innerHTML = `<b>${isTeam ? 'Team' : 'You'}:</b> ${numberWithSpaces(
          isTeam ? teamScore : yourScore,
        )}`;
      }
      tooltipRef.current.style.display = 'block';
      tooltipRef.current.style.top = `${event.pageY}px`;
      tooltipRef.current.style.left = `${event.pageX}px`;
    }
  };

  const handleMouseOut = () => {
    if (typeof yourScore !== 'number' && typeof teamScore !== 'number') return;
    if (tooltipRef.current) {
      tooltipRef.current.style.display = 'none';
    }
  };

  const handleTouchOut = () => {
    if (tooltipRef.current) {
      tooltipRef.current.style.display = 'none';
    }
  };

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          marginRight: { xs: 0, md: '16px' },
          marginBottom: { xs: '16px', md: 0 },
          width: '100%',
          paddingTop: '12px',
        }}
      >
        <svg
          height={'100%'}
          width={'100%'}
          viewBox="0 0 200 100"
          style={{
            position: 'relative',
            zIndex: 10,
            minWidth: 180,
            transform: 'rotate(180deg)',
            width: '100%',
            maxWidth: 240,
          }}
        >
          <linearGradient id="g1" x1=".5" y1="2" x2=".5">
            <stop stopColor="#A2ECCE" />
            <stop offset="1.046" stopColor="#16CF84" />
          </linearGradient>
          <linearGradient id="g2" gradientUnits="userSpaceOnUse" x1="-10.22%" y1="28.08%" x2="110.22%" y2="71.92%">
            <stop offset=".324" stopColor="#DAB9F1" />
            <stop offset=".568" stopColor="#CEA2EC" />
          </linearGradient>
          {(typeof teamPercent === 'number' && teamPercent && teamPercent >= 100) ||
          (selfPercent >= 100 && !onlyTeam) ? null : (
            <path
              d={
                onlyTeam
                  ? describeArc(teamPercent ?? 0, 100)
                  : describeArc(teamPercent && teamPercent > selfPercent ? teamPercent : selfPercent, 100)
              }
              stroke="#F1FFFA"
              strokeWidth={24}
              fill="transparent"
            />
          )}
          {teamPercent ? (
            <path
              onMouseEnter={handleMouseIn(true)}
              onMouseOut={handleMouseOut}
              onTouchEnd={handleTouchOut}
              d={
                teamPercent > selfPercent && !onlyTeam
                  ? describeArc(selfPercent, teamPercent)
                  : describeArc(0, teamPercent)
              }
              stroke="url(#g2)"
              fill="transparent"
              strokeWidth={24.5}
            />
          ) : null}
          {teamPercent !== selfPercent && selfPercent !== 0 && !onlyTeam && (
            <path
              onMouseEnter={handleMouseIn(false)}
              onMouseOut={handleMouseOut}
              onTouchEnd={handleTouchOut}
              d={
                teamPercent && selfPercent > teamPercent
                  ? describeArc(teamPercent, selfPercent)
                  : describeArc(0, selfPercent)
              }
              stroke="url(#g1)"
              fill="transparent"
              strokeWidth={24.5}
            />
          )}
          <circle
            cx="50%"
            cy="0%"
            r={60}
            fill="transparent"
            stroke=" #8DA09D"
            strokeWidth={0.5}
            strokeDasharray={2}
            style={{ opacity: '0.5' }}
            strokeDashoffset={0.5 - (0.5 * 50) / 100}
          />
        </svg>
        <Box sx={{ position: 'absolute', zIndex: 0, left: 0, right: 0, bottom: '0px' }}>
          <Typography
            sx={{
              fontWeight: 800,
              fontSize: '18px',
              lineHeight: 1,
              color: Colors.gray[700],
              textAlign: 'center',
              marginBottom: '-3px',
            }}
          >
            {numberWithSpaces(score)}
          </Typography>
          {leftScore < 0 ? (
            <>
              <Typography sx={{ fontSize: '9px', color: '#8DA09D', textAlign: 'center' }}>out of</Typography>
              <Typography sx={{ fontSize: '11px', color: '#8DA09D', textAlign: 'center' }}>
                {numberWithSpaces(score - leftScore)}
              </Typography>
            </>
          ) : null}
        </Box>
      </Box>
      <div
        id="testTooltip"
        ref={tooltipRef}
        style={{
          display: 'none',
          borderRadius: '5px',
          padding: '8px',
          background: 'rgba(0,0,0,0.7)',
          position: 'fixed',
          zIndex: 100,
          transform: 'translate(-100%,-100%)',
          top: 0,
          left: 0,
        }}
      >
        <Typography sx={{ fontSize: '10px', color: Colors.white }}>Your: 2000</Typography>
      </div>
    </>
  );
};
