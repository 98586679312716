import { Box, CardMedia, Typography } from '@mui/material';
import React from 'react';
import { Colors } from '../../theme/colors';
import IconNoResult from '../../assets/png/no-results.png';

export const EmptyState: React.FC = () => {
  return (
    <Box sx={{ margin: { md: '55px auto 90px', xs: '50px auto 50px' }, textAlign: 'center' }}>
      <CardMedia sx={{ flexShrink: 0, height: 110, width: 154, margin: '0 auto' }} image={IconNoResult} />
      <Typography
        sx={{
          fontSize: { md: '18px', xs: '14px' },
          lineHeight: { md: '18px', xs: '14px' },
          color: Colors.gray[200],
          fontWeight: 'bold',
          mt: { md: '32px', xs: '22px' },
        }}
      >
        No results
      </Typography>
    </Box>
  );
};
