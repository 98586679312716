import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { useEffect, useMemo } from 'react';
import { asyncGetChallengeMetrics } from '../../../redux/slices/challengeAdminSlice';
import { METRICS } from '../../../models/enum/METRICS';

export const useMetricsHandle = () => {
  const dispatch = useDispatch();

  const challengeMetrics = useSelector((state: RootState) => state.challengeAdmin.data.challengeMetrics);

  const metrics = useMemo(
    () =>
      METRICS.filter((metric) => challengeMetrics.findIndex((item) => item === metric.value) >= 0).map((metric) => ({
        label: metric.label,
        value: metric.value,
      })),
    [challengeMetrics],
  );

  useEffect(() => {
    dispatch(asyncGetChallengeMetrics());
  }, []);

  return { metrics };
};
