import React, { FC } from 'react';
import { Card, CardContent, Box, IconButton, Typography } from '@mui/material';
// import Menu from '../assets/svg/dotsMenu.svg';
import { ReactComponent as CloseIcon } from '../assets/svg/feature/close.svg';
import { ReactComponent as EmailIcon } from '../assets/svg/feature/email.svg';
import { ReactComponent as CrownGoldIcon } from '../assets/svg/feature/crown-gold.svg';
import { ReactComponent as CrownIcon } from '../assets/svg/feature/crown.svg';
import { ReactComponent as CrownRevokeIcon } from '../assets/svg/feature/crown-revoke.svg';
import { ReactComponent as EmployeeAdminBgIcon } from '../assets/svg/feature/employee-admin-bg.svg';
import IconPlaceholder from '../assets/svg/placeholder_avatar.svg';
import { Colors } from '../theme/colors';
import { CustomButton } from './features/CustomButton';
import { VIEW_TYPE } from '../pages/EmployeesPage';
import { Avatar } from './features/Avatar';

interface Props {
  name: string;
  admin: boolean;
  icon: string;
  email: string;
  viewType: VIEW_TYPE;
  isCurrent?: boolean;
  onDeleteClick: () => void;
  onRevokeAccess: () => void;
  onGrantAccess: () => void;
}

const EmployeesCard: FC<Props> = ({
  admin,
  name,
  icon,
  email,
  viewType,
  isCurrent,
  onRevokeAccess,
  onGrantAccess,
  onDeleteClick,
}) => {
  return (
    <Card
      sx={{
        borderRadius: '20px',
        backgroundColor: Colors.white,
        boxShadow: `0px 4px 10px rgba(65, 74, 79, 0.05)`,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minHeight: viewType ? 74 : 274,
        height: '100%',
        ml: viewType ? '28px' : 0,
        overflow: viewType ? 'visible' : 'hidden',
      }}
    >
      <CardContent
        sx={{
          position: 'relative',
          padding: viewType ? '0 60px 0 0!important' : '4px 16px 20px',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          background: admin
            ? viewType
              ? 'linear-gradient(90.86deg, #FEF7E9 0%, #FFFFFF 99.77%)'
              : 'linear-gradient(180deg, #FEF7E9 0%, #FAEED7 0.01%, #FFFFFF 53.08%), #FFFFFF'
            : Colors.white,
          borderRadius: viewType ? '10px' : 0,
        }}
      >
        <Box
          sx={
            viewType
              ? {
                  position: 'absolute',
                  top: { xs: '12px', lg: '50%' },
                  transform: { xs: 'none', lg: 'translateY(-50%)' },
                  right: { xs: '16px', lg: '23px' },
                }
              : { position: 'absolute', top: '16px', right: '16px' }
          }
          display="flex"
          justifyContent="flex-end"
        >
          <IconButton
            sx={{
              background: viewType
                ? 'rgba(240, 242, 243, 0.7)'
                : admin
                ? 'rgba(255, 255, 255, 0.7)'
                : 'rgba(240, 242, 243, 0.7)',
              width: '28px',
              height: '28px',
            }}
            onClick={onDeleteClick}
          >
            <CloseIcon width={12} height={12} fill={Colors.gray[400]} />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: viewType ? 'row' : 'column',
            justifyContent: viewType ? 'flex-start' : 'space-between',
            alignItems: 'center',
          }}
          flex={1}
        >
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              height: viewType ? '68px' : '140px',
              width: viewType ? '56px' : '124px',
              ml: viewType ? '-28px' : 0,
              mt: viewType ? '-12px' : 0,
            }}
          >
            {admin && (
              <>
                <Box
                  sx={{
                    position: 'absolute',
                    width: viewType ? '12px' : '24px',
                    height: viewType ? '12px' : '24px',
                    top: viewType ? 0 : '9px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                  }}
                >
                  <CrownGoldIcon width={viewType ? 12 : 24} height={viewType ? 12 : 24} />
                </Box>
                {!viewType && (
                  <Box sx={{ position: 'absolute', top: 0, left: 0 }}>
                    <EmployeeAdminBgIcon width={124} height={140} />
                  </Box>
                )}
              </>
            )}

            <Avatar
              sx={
                viewType
                  ? {
                      width: '56px',
                      height: '56px',
                      borderWidth: '2px',
                      borderStyle: 'solid',
                      borderColor: admin ? '#FAEED7' : Colors.gray[50],
                      mt: '12px',
                    }
                  : {
                      width: 100,
                      height: 100,
                      borderWidth: '4px',
                      borderStyle: 'solid',
                      borderColor: admin ? '#FAEED7' : Colors.gray[50],
                      mt: '28px',
                    }
              }
              src={icon || IconPlaceholder}
            />
          </Box>
          <Box
            sx={
              viewType
                ? {
                    display: 'flex',
                    flexDirection: { xs: 'column', lg: 'row' },
                    textAlign: 'left',
                    justifyContent: 'space-between',
                    alignItems: { xs: 'flex-start', lg: 'center' },
                    width: '100%',
                    padding: { xs: '12px', lg: 0 },
                  }
                : {
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    textAlign: 'center',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }
            }
          >
            <Box sx={{ ml: { xs: 0, lg: viewType ? '12px' : 0 } }}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: '16px',
                  color: Colors.gray[700],
                  fontWeight: 'bold',
                  textAlign: viewType ? 'left' : 'center',
                }}
              >
                {name}
              </Typography>
              <Box
                sx={{ mt: viewType ? { xs: 0, lg: '4px' } : !name.trim() ? '32px' : '8px' }}
                display={'flex'}
                flex={1}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <EmailIcon width={viewType ? 12 : 16} height={viewType ? 12 : 16} fill={'#5D6C6D'} />
                <Typography variant="body2" sx={{ ml: '4px', fontSize: '12px', color: '#5D6C6D' }}>
                  {email}
                </Typography>
              </Box>
            </Box>

            {!isCurrent && (
              <CustomButton
                type={admin ? 'bigLightOrange' : 'bigOrange'}
                text={admin ? 'Remove admin' : 'Make admin'}
                width={'160px'}
                inputProps={{
                  variant: 'contained',
                  startIcon: admin ? (
                    <CrownRevokeIcon width={12} height={12} fill={Colors.orange[600]} />
                  ) : (
                    <CrownIcon width={12} height={12} fill={Colors.white} />
                  ),
                }}
                sx={{
                  height: '28px',
                  fontSize: '12px',
                  lineHeight: '12px',
                  mt: viewType ? { xs: '16px', lg: 0 } : '24px',
                }}
                onClick={admin ? onRevokeAccess : onGrantAccess}
              />
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
export default EmployeesCard;
