import React from 'react';
import { Grid, IconButton, Snackbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type CustomToastType = {
  open: boolean;
  onClose: () => void;
  message: string;
  type?: 'success' | 'error';
  position?: 'top' | 'bottom';
};

const CustomToast: React.FC<CustomToastType> = ({ open, onClose, message, type = 'success', position = 'bottom' }) => {
  const handleClose = () => {
    onClose();
  };

  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const getStyle = () => {
    if (type === 'success') {
      return {
        '& .MuiPaper-root': {
          borderRadius: '36px',
          background: 'white',
          border: '1px solid #28A745F0',
          color: '#28A745F0',
          my: '24px',
        },
      };
    } else if (type === 'error') {
      return {
        '& .MuiPaper-root': {
          borderRadius: '36px',
          background: 'white',
          border: '1px solid #DA4E53F0',
          color: '#DA4E53F0',
          my: '24px',
        },
      };
    }
  };

  const getMessage = () => {
    const messages = message.split('|||');
    return (
      <Grid container direction={'column'}>
        {messages.map((m, index) => (
          <Grid key={index} item>
            <Typography>{m}</Typography>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: position, horizontal: position === 'bottom' ? 'left' : 'right' }}
      open={open}
      className={'customSnackbar'}
      sx={{ ...getStyle() }}
      autoHideDuration={6000}
      onClose={handleClose}
      message={getMessage()}
      action={action}
    />
  );
};

export default CustomToast;
