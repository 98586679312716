import React, { FC } from 'react';
import { Checkbox, styled } from '@mui/material';

const StyledCheckbox = styled(Checkbox)({
  color: 'black',
});

interface Props {
  checked: boolean;
  setValue: (value: any) => void;
}

const CustomCheckbox: FC<Props> = ({ checked, setValue }) => {
  return <StyledCheckbox checked={checked} onChange={setValue} />;
};

export default CustomCheckbox;
