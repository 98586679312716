import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Range } from 'react-date-range';
import { RootState } from '../../../redux/store';
import { GraphMetric } from '../../../models/Metrics';
import { ScoreTimeItemType } from '../../../models/Shared';
import moment, { duration } from 'moment';
import { filterMetricSampleByRange, filterPointsByRange } from '../../../utils/filterMetricSample';
import { AWARD_TRIGGER } from '../../../models/enum/AWARD_TRIGGER';
import FlameIcon from '../../../assets/svg/feature/flame.svg';
import { CHALLENGE_TYPE } from '../../../models/enum/CHALLENGE_TYPE';

export type StatisticInstance = {
  label: string;
  place: number;
  score: number;
  isAwarded: boolean;
  startDate: string;
  endDate: string;
  metricSample: ScoreTimeItemType[];
  bestScore?: number;
  dailyAverage?: number;
};

const defaultRange = {
  startDate: new Date(),
  endDate: new Date(),
  key: 'selection',
};

const getPoints = (scoreTimeline: ScoreTimeItemType[], range: Range[]): PointAnnotations[] => {
  if (scoreTimeline.length) {
    const points = filterPointsByRange(scoreTimeline, range);
    const maxValue = Math.max(...filterMetricSampleByRange(scoreTimeline, range).map((item) => item.value));
    const achievedPoints = points.filter((item) => item.status === 'SUCCESS');

    return filterPointsByRange(achievedPoints, range).map((item) => ({
      x: item.date,
      y: Math.max(Math.floor(Number(((item.teamValue ?? item.value) * 100) / maxValue)), 0),
      marker: {
        size: 12,
        fillColor: '#FAAF1F',
        radius: 12,
        strokeWidth: 0,
      },
      image: {
        path: FlameIcon,
        width: 14,
        height: 14,
      },
    }));
  }
  return [];
};

export const useHandlePreviousInstances = () => {
  const [visible, setVisible] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [focusedInstance, setFocusedInstance] = useState<StatisticInstance>();
  const [range, setRange] = useState<Range[]>([]);
  const [selected, setSelected] = useState('');
  const [graph, setGraph] = useState<GraphMetric>();
  const [points, setPoints] = useState<PointAnnotations[]>();

  const isLoading = useSelector((state: RootState) => state.challengeProgress.meta.isLoading);
  const { user, leaderBoard, progress, detail, duration } = useSelector(
    (state: RootState) => state.challengeProgress.data,
  );

  const defaultGraphOptions = useMemo(
    () => ({
      group: detail?.groupCategory ?? '',
      metric_pillar: detail?.challengePillars ?? '',
      title: progress?.metricTitle ?? '',
      metric_type: progress?.metricType ?? '',
    }),
    [detail, progress],
  );

  const instances: StatisticInstance[] = useMemo(() => {
    if (leaderBoard?.instanceList) {
      const list = leaderBoard.instanceList.map((item) => ({
        label: `Instance ${moment(item.startDate).format('DD/MM')}-${moment(item.endDate).format('DD/MM')}`,
        place: item.place,
        score: item.score,
        isAwarded: item.isAwarded,
        startDate: item.startDate,
        endDate: item.endDate,
        bestScore: item?.bestScore ?? 0,
        dailyAverage: item.dailyAverage,
        metricSample: (item?.scoreTimeline ?? []).map((item) => ({
          ...item,
          value: item?.value ?? (item.status === 'SUCCESS' ? 1 : 0),
        })),
      }));
      return list;
    }
    return [];
  }, [leaderBoard]);

  const handleOpenInstanceModal = () => setIsModal(true);
  const handleCloseInstanceModal = () => setIsModal(false);

  const handleCloseModal = () => setVisible(false);

  const handleChangeRange = (range: Range[]) => {
    setRange(range);
    if (focusedInstance) {
      setGraph({
        goals: [],
        metrics: [
          { ...defaultGraphOptions, metric_samples: filterMetricSampleByRange(focusedInstance.metricSample, range) },
        ],
      });
      setPoints(getPoints(focusedInstance?.metricSample ?? [], range));
    }
  };

  const onChangeSelect = (val: string) => {
    const findInstance = instances.find((item) => item.label === val);
    if (!findInstance) return;
    setSelected(val);
    setFocusedInstance(findInstance);
    const range = [
      {
        startDate: new Date(findInstance?.startDate ?? new Date()),
        endDate: new Date(findInstance?.endDate ?? new Date()),
        key: 'selection',
      },
    ];
    setGraph({
      goals: [],
      metrics: [
        { ...defaultGraphOptions, metric_samples: filterMetricSampleByRange(findInstance.metricSample, range) },
      ],
    });
    setPoints(getPoints(findInstance?.metricSample ?? [], range));
    setRange(range);
  };

  const handleOpenModal = (val?: string) => () => {
    if (val) {
      onChangeSelect(val);
    }
    setVisible(true);
    setIsModal(false);
  };

  useEffect(() => {
    if (instances) {
      const firstItem = instances?.[0];
      setFocusedInstance(firstItem);
      setSelected(firstItem?.label ?? '');
      setGraph({
        goals: [],
        metrics: [{ ...defaultGraphOptions, metric_samples: filterMetricSampleByRange(firstItem.metricSample, range) }],
      });
      setPoints(getPoints(firstItem?.metricSample ?? [], range));
      setRange([{ startDate: new Date(firstItem.startDate), endDate: new Date(firstItem.endDate), key: 'selection' }]);
    }
  }, [instances]);

  return {
    visible,
    range,
    selected,
    isLoading,
    lastSync: detail?.leaderBoardSync,
    instances,
    focusedInstance,
    graph,
    points,
    target:
      detail?.awardTrigger === AWARD_TRIGGER.COMPLETION || detail?.awardTrigger === AWARD_TRIGGER.FASTEST
        ? detail?.challengeTyp === CHALLENGE_TYPE.LONGER_STREAK || detail?.challengeTyp === CHALLENGE_TYPE.STICK_TO_IT
          ? progress?.daysGoalValue ?? user?.target
          : user?.target
        : undefined,
    previousScore: progress?.scoreToPrevious ?? 0,
    isBoolean: detail?.isBoolean ?? false,
    bestScore: user?.bestScore,
    dailyAverage: user?.dailyAverage,
    startDate: duration?.startDate ?? new Date().toISOString(),
    isModal,
    handleOpenInstanceModal,
    handleCloseInstanceModal,
    handleChangeRange,
    handleOpenModal,
    handleCloseModal,
    onChangeSelect,
  };
};
