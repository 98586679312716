import React from 'react';
import Grid from '@mui/material/Grid';
import { Box, Skeleton, useMediaQuery } from '@mui/material';
import { Challenge } from '../../../models/Challenge';
import theme from '../../../theme';
export const SkeletonContent: React.FC = () => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  return mdDown ? (
    <Grid sx={{ position: 'relative', width: '100%', marginRight: '24px' }}>
      <Skeleton variant="rectangular" height={'160px'} width={'100%'} sx={{ borderRadius: '20px' }} />

      <Box sx={{ width: '100%', mt: '26px' }}>
        <Skeleton />
        <Skeleton animation="wave" />
        <Skeleton animation={false} />
      </Box>
      <Skeleton variant="rectangular" height={'54px'} sx={{ borderRadius: '20px', margin: '32px auto 0 auto' }} />
      <Skeleton variant="rectangular" height={'54px'} sx={{ borderRadius: '20px', margin: '18px auto 0 auto' }} />
    </Grid>
  ) : (
    <Grid container display={'flex'} wrap={'nowrap'}>
      <Grid sx={{ position: 'relative', width: '100%', marginRight: '24px' }}>
        <Skeleton variant="rectangular" height={'324px'} width={'100%'} sx={{ borderRadius: '20px' }} />

        <Skeleton
          variant="rectangular"
          height={'92px'}
          width={'90%'}
          sx={{ borderRadius: '20px', margin: '-46px auto 0 auto' }}
        />

        <Box sx={{ width: '100%', mt: '26px' }}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box>
      </Grid>
      <Grid
        sx={{
          width: '276px',
        }}
      >
        <Skeleton variant="rectangular" height={'212px'} width={'276px'} sx={{ borderRadius: '20px' }} />
        <Skeleton variant="rectangular" height={'46px'} width={'100%'} sx={{ mt: '40px', borderRadius: '100px' }} />
      </Grid>
    </Grid>
  );
};
