import {
  deleteRequest,
  getRequest,
  postFormDataRequest,
  postRequest,
  putRequest,
} from '../../../../services/HttpService';
import { USER_ROLE } from '../../../../models/enum/USER_ROLE';

export const getEmployeesRequest = (params: {
  page: number;
  searchText: string;
  sort: string;
  onlyAdmins: boolean;
}) => {
  return getRequest('/organisation-management-service/company/employees', {
    page: (params.page ?? 0).toString(),
    ...(params.searchText && { searchText: params.searchText }),
    ...(params.sort && { sort: params.sort }),
    onlyAdmins: params.onlyAdmins.toString(),
  });
};

export const deleteEmployeeRequest = (empUUID: string) => {
  return deleteRequest(`/organisation-management-service/employees?empUUID=${empUUID}`);
};

export const changeEmployeeAccessRequest = (params: { userRole: USER_ROLE; empUUID: string }) => {
  return putRequest(`/organisation-management-service/user/role`, params);
};
export const sendInvitationsRequest = (emailList: string[]) => {
  return postRequest(`/organisation-management-service/organisations/user_invitation`, { emailList });
};

export const postCSVRequest = (file: FormData) => {
  return postFormDataRequest('/organisation-management-service/organisations/user_invitation/csv', file);
};

export const bambooIntegrationRequest = (clientDomain: string, apiKey: string) => {
  return postRequest('/organisation-management-service/integrations/bamboo', { clientDomain, apiKey });
};

export const invitationInfo = () => {
  return getRequest('/organisation-management-service/organisations/user_invitation/info');
};

export const registrationLink = () => {
  return getRequest('/organisation-management-service/user/link_registration/manage', {}, 'String');
};
