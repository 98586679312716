import { Capacitor } from '@capacitor/core';
import { Terra, Connections } from 'terra-capacitor';
import { getTerraAuthToken } from '../lib/api/http/requests/profile';
import moment from 'moment';

const getLastUpdateDate = (date?: string) => {
  if (!date) return new Date(new Date().setHours(0, 0, 0, 0));
  const formatDate = moment(date).format('YYYY-MM-DD');
  return new Date(formatDate);
};

export const initializeTerra = async (
  connection: Connections,
  lastUpdate?: string,
): Promise<{ userId: null | string; success?: boolean; error?: string }> =>
  new Promise(async (resolve) => {
    const { data } = await getTerraAuthToken();
    if (!data?.token) {
      resolve({ userId: null, error: 'Connected device token is empty' });
    }
    const initData = await Terra.initConnection({
      connection,
      token: data.token,
      schedulerOn: true,
      customPermissions: [],
      startIntent: null,
    });

    if (!initData.success) {
      const errorMessage = initData?.error ?? 'Connect to the provider is failed';
      resolve({ userId: null, error: errorMessage });
      return;
    }

    const startDate = getLastUpdateDate(lastUpdate);
    const endDate = new Date();

    const responseTerraMethods = await Promise.all([
      Terra.getBody({ connection, startDate, endDate, toWebhook: true }),
      Terra.getActivity({ connection, startDate, endDate, toWebhook: true }),
      Terra.getDaily({ connection, startDate, endDate, toWebhook: true }),
      Terra.getNutrition({ connection, startDate, endDate, toWebhook: true }),
      Terra.getSleep({ connection, startDate, endDate, toWebhook: true }),
      Capacitor.getPlatform() === 'ios' ? Terra.getAthlete({ connection, toWebhook: true }) : undefined,
    ]);

    const errorResponse = responseTerraMethods.find((res) => !res?.success && res?.error);

    if (errorResponse && !errorResponse.success) {
      const errorMessage = errorResponse.error ?? 'Connected device sync error';
      resolve({ userId: null, error: errorMessage });
      return;
    }

    const userResponse = await Terra.getUserId({ connection });

    if (!userResponse.success || !userResponse.userId) {
      resolve({ userId: null, error: 'Connected device sync error ' });
    }
    resolve(userResponse);
  });
