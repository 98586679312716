import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  deleteMetric,
  getAdminJourneyCardMetrics,
  getAdminMetricsRequest,
  getAdminWellnessMetricsRequest,
  getLifeAssessment,
  getMetricsByCategory,
  getUserJourneyCardMetrics,
  getUserMetrics,
  getUserMetricsByPillar,
  getWellnessChartAdminRequest,
  getWellnessChartUserRequest,
  getWhoFive,
  multipleUpdateMetric,
  newMetric,
  submitMetric,
  updateMetric,
} from '../../lib/api/http/requests/journey';
import { getQuestionnaire, submitQuestionnaire } from '../../lib/api/http/requests/questionnaire';
import { JourneyCards } from '../../models/JourneyCards';
import {
  GraphMetric,
  IDeleteMetric,
  IGetMetricsByCategory,
  IMetric,
  IMetrics,
  IMultipleUpdate,
  INewMetric,
  IPostMetric,
  IUpdateMetric,
} from '../../models/Metrics';
import { IQuestionnaire, IQuestionnaireAnswers } from '../../models/Questionnaire';
import moment from 'moment';
import { handleBackendError } from '../../utils/handleBackendError';
import { metrics } from '../../utils/metrics';
import { RootState } from '../store';
import { trackEvent } from '../../utils/analytics';
import { ANALYTICS_PAGE } from '../../models/enum/ANALYTICS_PAGE';
import { setHeaderLoading } from './appSlice';
import { METRICS_CATEGORY } from '../../models/enum/METRICS_CATEGORY';
import { LocalConvenienceStoreOutlined } from '@mui/icons-material';

export interface journeyPageState {
  data: {
    questionnaire: IQuestionnaire;
    journeyCards?: JourneyCards;
    profileJourneyCards?: JourneyCards;
    journeyAdminCards?: JourneyCards;
    whoFiveMetrics?: IMetric[];
    lifeBalance?: IMetric[];
    metrics: IMetrics;
    metricsByCategory: {
      win?: IMetric[];
      extra?: IMetric[];
      mine?: IMetric[];
    };
    metricsPillar: string;
    adminMetrics: IMetrics;
    graphMetrics: IMetrics;
    graph: GraphMetric;
    adminGraph: GraphMetric;
    adminGraphMetrics: IMetrics;
    metricsModal: {
      isOpen: boolean;
      isCorporate: boolean;
      predefinedMetric?: string;
      date?: any;
      page?: ANALYTICS_PAGE | string;
    };
    metricsModalData: IMetrics;
  };
  meta: {
    metricsLoading: boolean;
    journeyCardsLoading: boolean;
    journeyAdminCardsLoading: boolean;
    modalMetricsLoading: boolean;
    questionnaireLoading: boolean;
    loading: boolean;
  };
}

const initialState: journeyPageState = {
  data: {
    graph: {
      goals: [],
      metrics: [],
    },
    adminGraph: {
      goals: [],
      metrics: [],
    },
    adminGraphMetrics: {
      metrics: [],
    },
    journeyCards: undefined,
    journeyAdminCards: undefined,
    metrics: {
      metrics: [],
    },
    metricsByCategory: {},
    metricsPillar: 'ALL',
    graphMetrics: {
      metrics: [],
    },
    adminMetrics: {
      metrics: [],
    },
    metricsModal: {
      isOpen: false,
      predefinedMetric: '',
      isCorporate: false,
      date: moment().format('YYYY-MM-DD'),
      page: undefined,
    },
    metricsModalData: {
      metrics: [],
    },
    questionnaire: {
      survey: {
        active: false,
        calculation: '',
        custom: true,
        frequencyCategory: '',
        header: {
          id: 1,
          otherInfo: '',
          surveyDescription: '',
          surveyName: '',
        },
        id: 1,
        organisationUUID: '',
        questions: [],
        scored: false,
        surveyCategory: '',
        surveyUUID: '',
        surveyVerdicts: [],
        updateable: false,
        validityPeriod: {
          validityPeriodEnd: '',
          validityPeriodStart: '',
        },
      },
      surveyAnswer: {
        questionAnswers: [],
        submission_date: '',
      },
    },
  },

  meta: {
    metricsLoading: false,
    journeyCardsLoading: false,
    journeyAdminCardsLoading: false,
    modalMetricsLoading: false,
    questionnaireLoading: false,
    loading: false,
  },
};

export const asyncGetUserJourneyCards = createAsyncThunk(
  'journey/getUserJourneyCards',
  async (params: { date: string }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setHeaderLoading(true));
      thunkAPI.dispatch(setJourneyCardsLoading(true));
      const { data } = await getUserJourneyCardMetrics(params.date);
      return { data, date: params.date };
    } catch (error) {
      console.log(error);
    } finally {
      thunkAPI.dispatch(setJourneyCardsLoading(false));
      thunkAPI.dispatch(setHeaderLoading(false));
    }
  },
);

export const asyncGetAdminJourneyCards = createAsyncThunk(
  'journey/getAdminJourneyCards',
  async (params: { date: string }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setHeaderLoading(true));
      thunkAPI.dispatch(setJourneyAdminCardsLoading(true));
      const { data } = await getAdminJourneyCardMetrics(params.date);
      return data;
    } catch (error) {
      console.log(error);
    } finally {
      thunkAPI.dispatch(setJourneyAdminCardsLoading(false));
      thunkAPI.dispatch(setHeaderLoading(false));
    }
  },
);

export const asyncGetMetricsByPillar = createAsyncThunk(
  'journey/getMetricsByPillar',
  async (params: { date: string; pillar?: string }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setMetricsLoading(true));
      const { data } = await getUserMetricsByPillar(params.date, params.pillar);
      return data;
    } catch (error) {
      console.log(error);
    } finally {
      thunkAPI.dispatch(setMetricsLoading(false));
    }
  },
);

export const asyncGetAdminMetrics = createAsyncThunk(
  'journey/asyncGetAdminMetrics',
  async (params: { date: string }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setMetricsLoading(true));
      const { data } = await getAdminMetricsRequest(params.date);
      return data;
    } catch (error) {
      console.log(error);
    } finally {
      thunkAPI.dispatch(setMetricsLoading(false));
    }
  },
);

export const getWellnessChartUser = createAsyncThunk(
  'journey/getWellnessChartUser',
  async (params: { metrics: IMetric[]; date_from: string; date_to: string }, thunkAPI) => {
    try {
      const state: RootState = thunkAPI.getState() as RootState;
      if (state.journeyPage.data.graph.metrics.length > params.metrics.length) {
        const newMetrics = state.journeyPage.data.graph.metrics.filter((metric) =>
          params.metrics.some((paramMetric) => paramMetric.title === metric.title),
        );
        const graph = { goals: state.journeyPage.data.graph.goals, metrics: newMetrics };
        return { graph };
      } else {
        const res = await getWellnessChartUserRequest({
          date_from: moment(params.date_from).format('YYYY-MM-DD'),
          date_to: moment(params.date_to).format('YYYY-MM-DD'),
          metrics: params.metrics.map((m) => {
            return {
              metric_type: m.metric_type,
              title: m.title,
            };
          }),
        });
        if (!Array.isArray(res?.data?.goals) || !Array.isArray(res?.data?.metrics)) throw Error;
        const graph = { goals: res.data.goals, metrics: res.data.metrics };
        return { graph };
      }
    } catch (error) {
      console.log(error);
    }
  },
);

export const getWellnessChartAdmin = createAsyncThunk(
  'journey/getWellnessChartAdmin',
  async (params: { metrics: IMetric[]; date_from: string; date_to: string }, thunkAPI) => {
    try {
      const state: RootState = thunkAPI.getState() as RootState;
      if (state.journeyPage.data.adminGraph.metrics.length > params.metrics.length) {
        const newMetrics = state.journeyPage.data.adminGraph.metrics.filter((metric) =>
          params.metrics.some((paramMetric) => paramMetric.title === metric.title),
        );
        const adminGraph = { goals: state.journeyPage.data.adminGraph.goals, metrics: newMetrics };
        return { adminGraph };
      } else {
        const res = await getWellnessChartAdminRequest({
          date_from: moment(params.date_from).format('YYYY-MM-DD'),
          date_to: moment(params.date_to).format('YYYY-MM-DD'),
          metrics: params.metrics.map((m) => {
            return {
              metric_type: m.metric_type,
              title: m.title,
            };
          }),
        });
        if (!Array.isArray(res?.data?.goals) || !Array.isArray(res?.data?.metrics)) throw Error;
        const adminGraph = { goals: res.data.goals, metrics: res.data.metrics };
        return { adminGraph };
      }
    } catch (error) {
      console.log(error);
    }
  },
);

export const getAdminWellnessMetrics = createAsyncThunk(
  'journey/getAdminWellnessMetrics',
  async (undefined, thunkAPI) => {
    try {
      const res = await getAdminWellnessMetricsRequest();
      thunkAPI.dispatch(setGraphAdminMetricsList(res?.data?.metrics));
    } catch (error) {
      console.log(error);
    }
  },
);

export const getUserGraphMetrics = createAsyncThunk('journey/getUserGraphMetrics', async (params: { date: string }) => {
  try {
    const { data } = await getUserMetrics(params.date);
    return { data };
  } catch (error) {
    console.log(error);
  }
});

export const asyncGetMetrics = createAsyncThunk(
  'journey/getMetrics',
  async (params: { date: string; isCorporate?: boolean }, thunkAPI) => {
    try {
      if (params.isCorporate) {
        const { data } = await getAdminMetricsRequest(params.date);
        return { data, isCorporate: true };
      } else {
        const state: RootState = thunkAPI.getState() as RootState;
        const { data } = await getUserMetricsByPillar(params.date, state.journeyPage.data.metricsPillar);
        return { data, isCorporate: false };
      }
    } catch (error) {
      console.log(error);
    }
  },
);

export const asyncGetModalMetrics = createAsyncThunk(
  'journey/getModalMetrics',
  async (params: { date: string; isCorporate?: boolean }) => {
    try {
      if (params.isCorporate) {
        const { data } = await getAdminMetricsRequest(params.date);
        return { data, isCorporate: true };
      } else {
        const { data } = await getUserMetricsByPillar(params.date, 'ALL');
        return { data, isCorporate: false };
      }
    } catch (error) {
      console.log(error);
    }
  },
);

export const asyncGetQuestionnaire = createAsyncThunk('journey/get', async () => {
  try {
    const { data } = await getQuestionnaire();
    return data;
  } catch (error) {
    console.log(error);
  }
});

export const asyncPostMetric = createAsyncThunk(
  'journey/postMetric',
  async (params: { metric: IPostMetric; date: string; page?: ANALYTICS_PAGE | string }, thunkAPI) => {
    try {
      const { data } = await submitMetric(params.metric);
      thunkAPI.dispatch(asyncGetMetrics({ date: params.date, isCorporate: params.metric.group === 'CORPORATE' }));
      trackEvent('metric_logged', {
        metric: params.metric.title,
        date: params.metric.date,
        page: params.page,
        type: 'Manual',
      });
      if (metrics) return data;
    } catch (error) {
      handleBackendError(error, 'Failed to update metric');
      throw error;
    }
  },
);

export const asyncAddNewMetric = createAsyncThunk(
  'journey/newMetric',
  async (params: { metric: INewMetric; date: string }, thunkAPI) => {
    try {
      const { data } = await newMetric(params.metric);
      thunkAPI.dispatch(
        asyncGetMetrics({ date: params.date, isCorporate: params.metric.metric_category_group === 'CORPORATE' }),
      );
      trackEvent('metric_logged_new', { metric: params.metric.title });
      return data;
    } catch (error) {
      handleBackendError(error, 'Failed to add new metric');
      throw error;
    }
  },
);

export const asyncDeleteMetric = createAsyncThunk(
  'journey/deleteMetric',
  async (params: { metric: IDeleteMetric; date: string }, thunkAPI) => {
    try {
      const { data } = await deleteMetric(params.metric);
      thunkAPI.dispatch(
        asyncGetMetrics({ date: params.date, isCorporate: params.metric.metric_category_group === 'CORPORATE' }),
      );
      trackEvent('metric_deleted', { metric: params.metric.title });
      return data;
    } catch (error) {
      handleBackendError(error, 'Failed to delete metric');
      throw error;
    }
  },
);

export const asyncUpdateMetric = createAsyncThunk(
  'journey/updateMetric',
  async (params: { metric: IUpdateMetric; date: string }, thunkAPI) => {
    try {
      const { data } = await updateMetric(params.metric);
      thunkAPI.dispatch(
        asyncGetMetrics({ date: params.date, isCorporate: params.metric.metric_category_group === 'CORPORATE' }),
      );
      trackEvent('metric_updated', { metric: params.metric.title });
      return data;
    } catch (error) {
      handleBackendError(error, 'Failed to update metric');
      throw error;
    }
  },
);

export const asyncSubmitQuestionnaire = createAsyncThunk(
  'journey/get',
  async (params: { answers: IQuestionnaireAnswers }) => {
    try {
      return await submitQuestionnaire(params.answers);
    } catch (error) {
      handleBackendError(error, 'Failed to submit questionnaire');
    }
  },
);

export const asyncGetMetricsByCategory = createAsyncThunk(
  'journey/getMetricsByCategory',
  async ({ isUpdate, category, ...reqParams }: IGetMetricsByCategory & { isUpdate?: boolean }) => {
    try {
      const response = await getMetricsByCategory({ category, ...reqParams });
      if (category === METRICS_CATEGORY.WIN) {
        return { win: response.data.metrics, isUpdate };
      }
      if (category === METRICS_CATEGORY.EXTRA) {
        return { extra: response.data.metrics, isUpdate };
      }
      if (category === METRICS_CATEGORY.MINE) {
        return { mine: response.data.metrics, isUpdate };
      }
    } catch (error) {
      console.log(error);
      handleBackendError(error, 'Failed to fetch metrics');
    }
  },
);

export const asyncGetLifeBalance = createAsyncThunk('journey/getLifeBalance', async (params: { date: string }) => {
  try {
    const response = await getLifeAssessment(params);
    return response.data.metrics;
  } catch (error) {
    console.log(error);
    handleBackendError(error, 'Failed to fetch metrics');
  }
});

export const updateMultiplyMetrics = createAsyncThunk(
  'journey/updateMultiplyMetrics',
  async (params: IMultipleUpdate) => {
    try {
      await multipleUpdateMetric(params);
    } catch (error) {
      console.log(error);
      handleBackendError(error, 'Failed to update metrics');
    }
  },
);

export const asyncGetWhoFive = createAsyncThunk('journey/asyncFetchWhoFive', async (params: { date: string }) => {
  try {
    const response = await getWhoFive(params);
    return response.data.metrics;
  } catch (error) {
    handleBackendError(error, 'Failed to update get WHO-%');
  }
});

export const journeyPageSlice = createSlice({
  name: 'journeyPage',
  initialState,
  reducers: {
    setMetricsModal: (
      state,
      action: PayloadAction<{
        isOpen: boolean;
        isCorporate?: boolean;
        predefinedMetric?: string;
        date?: any;
        page?: ANALYTICS_PAGE | string;
      }>,
    ) => {
      state.data.metricsModal.isOpen = action.payload.isOpen;
      state.data.metricsModal.predefinedMetric = action.payload.predefinedMetric;
      state.data.metricsModal.date = action.payload.date;
      state.data.metricsModal.page = action.payload.page;
      if (action.payload.isCorporate !== undefined) state.data.metricsModal.isCorporate = action.payload.isCorporate;
    },
    setGraphAdminMetricsList: (state, action) => {
      state.data.adminGraphMetrics.metrics = action.payload || [];
    },
    setJourneyMetrics: (state, action) => {
      state.data.metrics.metrics = action.payload || [];
    },
    setMetricsPillar: (state, action) => {
      state.data.metricsPillar = action.payload || 'ALL';
    },
    setJourneyCardsLoading: (state, action: PayloadAction<boolean>) => {
      state.meta.journeyCardsLoading = action.payload;
    },
    setJourneyAdminCardsLoading: (state, action: PayloadAction<boolean>) => {
      state.meta.journeyAdminCardsLoading = action.payload;
    },
    setMetricsLoading: (state, action: PayloadAction<boolean>) => {
      state.meta.metricsLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncGetQuestionnaire.fulfilled, (state, action) => {
      state.data.questionnaire = action.payload;
    });

    builder.addCase(asyncGetAdminMetrics.fulfilled, (state, action) => {
      state.data.adminMetrics = action.payload || { metrics: [] };
    });
    builder.addCase(asyncGetMetricsByPillar.fulfilled, (state, action) => {
      state.data.metrics = action.payload || { metrics: [] };
    });
    builder.addCase(asyncGetMetrics.fulfilled, (state, action) => {
      if (action.payload?.isCorporate) {
        state.data.adminMetrics = action.payload?.data || { metrics: [] };
      } else {
        state.data.metrics = action.payload?.data || { metrics: [] };
      }
    });
    builder.addCase(asyncGetModalMetrics.fulfilled, (state, action) => {
      state.data.metricsModalData = action.payload?.data || { metrics: [] };
    });
    builder.addCase(getUserGraphMetrics.fulfilled, (state, action) => {
      state.data.graphMetrics = action.payload?.data || { metrics: [] };
    });
    builder.addCase(asyncGetUserJourneyCards.fulfilled, (state, action) => {
      state.data.journeyCards = action.payload?.data;
      if (action.payload?.date === moment().format('YYYY-MM-DD')) {
        state.data.profileJourneyCards = action.payload?.data;
      }
    });

    builder.addCase(asyncGetAdminJourneyCards.fulfilled, (state, action) => {
      state.data.journeyAdminCards = action.payload;
    });

    builder.addCase(getWellnessChartUser.fulfilled, (state, action) => {
      state.data.graph = action.payload?.graph || { goals: [], metrics: [] };
    });
    builder.addCase(getWellnessChartAdmin.fulfilled, (state, action) => {
      state.data.adminGraph = action.payload?.adminGraph || { goals: [], metrics: [] };
    });

    builder.addCase(asyncGetMetricsByCategory.fulfilled, (state, action) => {
      if (action.payload?.isUpdate) {
        return;
      }
      if (action.payload?.extra) {
        state.data.metricsByCategory.extra = action.payload.extra;
        return;
      }
      if (action.payload?.win) {
        state.data.metricsByCategory.win = action.payload.win;
        return;
      }
      if (action.payload?.mine) {
        state.data.metricsByCategory.mine = action.payload.mine;
        return;
      }
    });
    builder.addCase(asyncGetWhoFive.fulfilled, (state, action) => {
      state.data.whoFiveMetrics = action.payload;
    });
    builder.addCase(asyncGetLifeBalance.fulfilled, (state, action) => {
      state.data.lifeBalance = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  setMetricsModal,
  setJourneyMetrics,
  setGraphAdminMetricsList,
  setMetricsPillar,
  setJourneyCardsLoading,
  setJourneyAdminCardsLoading,
  setMetricsLoading,
} = journeyPageSlice.actions;

export default journeyPageSlice.reducer;
