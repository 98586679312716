import React from 'react';
import { Box, Checkbox, FormControl, MenuItem, OutlinedInput, Select, SxProps, Theme, styled } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IMetric } from '../../models/Metrics';

const MenuProps = {
  PaperProps: {
    style: {
      padding: '4px 8px',
      maxHeight: '400px',
      borderRadius: '20px',
      marginTop: '8px',
      boxShadow: 'var(--shadow-button)',
    },
  },
};

const StyledSelect = styled(Select)({
  fontSize: 14,
  fontWeight: 700,
  background: 'white',
  borderRadius: '999px',
  paddingInline: '8px',
  boxShadow: 'var(--shadow-button)',
  '& *': {
    border: 'none !important',
  },
});

const StyledMenuItem = styled(MenuItem)({
  mb: '4px',
  fontSize: '14px',
  borderRadius: '20px',
  fontWeight: '700',
  '&.Mui-selected': {
    backgroundColor: 'white',
    fontWeight: '900',
  },
});

type MultipleDropdownSelectProps = {
  width?: number | string;
  fullWidth?: boolean;
  small?: boolean;
  placeholder?: string;
  list: IMetric[];
  selected: IMetric[];
  sx?: SxProps<Theme>;
  setSelected: (s: IMetric[]) => void;
};

const MultipleDropdownSelect: React.FC<MultipleDropdownSelectProps> = ({
  width = 300,
  fullWidth,
  small,
  placeholder,
  list,
  selected,
  sx,
  setSelected,
}) => {
  const toggleMetric = (m: IMetric) => {
    const foundItem = selected?.find((l) => l.title === m.title);
    if (foundItem) setSelected(selected?.filter((l) => l.title !== foundItem.title));
    else if (selected?.length < 4) setSelected([...selected, m]);
  };

  return (
    <div>
      <FormControl sx={{ width: fullWidth ? '100%' : width, ...sx }}>
        <StyledSelect
          displayEmpty
          multiple
          value={[]}
          renderValue={() => <div>{`${selected?.length} Charts (Max 4)`}</div>}
          input={<OutlinedInput />}
          MenuProps={MenuProps}
          IconComponent={ExpandMoreIcon}
          sx={{ height: small ? 36 : 40 }}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {list?.map((item, index) => (
            <StyledMenuItem key={index} onClick={() => toggleMetric(item)}>
              <Checkbox
                checked={!!selected.find((s) => s.title === item.title)}
                checkedIcon={
                  <Box
                    sx={{
                      width: 12,
                      height: 12,
                      backgroundColor: '#00B6BE',
                      border: '2px solid rgba(0, 0, 0, 0.81)',
                      borderRadius: '2px',
                    }}
                  />
                }
                icon={
                  <Box
                    sx={{
                      width: 12,
                      height: 12,
                      backgroundColor: 'none',
                      border: '2px solid rgba(0, 0, 0, 0.81)',
                      borderRadius: '2px',
                    }}
                  />
                }
              />

              {item.title}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </div>
  );
};

export default MultipleDropdownSelect;
