import { Box, Dialog, DialogProps, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { ReactComponent as CloseIcon } from '../../assets/svg/feature/close.svg';
import theme from '../../theme';
import { RoundIconButton } from '../../components/features/RoundIconButton';
import { ReactComponent as ArrowIcon } from '../../assets/svg/feature/arrow.svg';
import { Colors } from '../../theme/colors';
import { Capacitor } from '@capacitor/core';

type CustomDialogProps = DialogProps & {
  label?: string;
  labelCenter?: boolean;
  modalMaxWidth?: string | number;
  onClose: () => void;
};

export const CustomDialog: React.FC<CustomDialogProps> = ({
  children,
  onClose,
  labelCenter,
  modalMaxWidth = '800px',
  label,
  ...resProps
}) => {
  const matchXs = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Dialog
      {...resProps}
      fullScreen={matchXs}
      fullWidth={matchXs}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: { xs: 0, md: '20px' },
          paddingTop: Capacitor.getPlatform() === 'ios' ? '64px' : '40px',
          position: 'relative',
          overflow: { xs: 'auto', md: 'unset' },
          margin: { xs: 0, md: '16px' },
          maxWidth: modalMaxWidth,
          '&::-webkit-scrollbar': {
            width: '5px !important',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#D3D7DA',
          },
        },
      }}
      onClose={onClose}
    >
      <Box sx={{ paddingInline: '16px' }}>
        {matchXs ? (
          <RoundIconButton
            sx={{
              marginBottom: '24px',
              alignSelf: { xs: 'stretch', sm: 'unset' },

              '& p': {
                fontSize: '14px',
                color: Colors.gray[500],
              },
            }}
            Icon={() => <ArrowIcon height="16px" width="16px" fill={Colors.gray[500]} />}
            size={26}
            background={Colors.gray[100]}
            roundColor={Colors.gray[150]}
            roundBorderColor="#E9EDEF"
            label="Back"
            onClick={onClose}
          />
        ) : (
          <Box
            component="button"
            sx={{
              position: 'absolute',
              top: '16px',
              right: '16px',
              height: 28,
              width: 28,
              padding: 0,
              borderRadius: 38,
              background: '#F0F2F3',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={onClose}
          >
            <CloseIcon fill={Colors.gray[400]} />
          </Box>
        )}
        {label && (
          <Typography
            sx={{
              fontFamily: 'Nexa',
              textAlign: { xs: labelCenter ? 'center' : 'left', md: 'center' },
              fontSize: { xs: '24px', md: '18px' },
              fontWeight: 900,
              marginBottom: '24px',
            }}
          >
            {label}
          </Typography>
        )}
      </Box>
      {children}
    </Dialog>
  );
};
