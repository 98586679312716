import { Box, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { Colors } from '../../../theme/colors';
import theme from '../../../theme';
import { ReactComponent as TrophyIcon } from '../../../assets/svg/feature/awards/trophy.svg';
import { numberWithSpaces } from '../../../utils/numberWithSpace';
import { ReactComponent as ArrowUpIcon } from '../../../assets/svg/feature/arrow-up.svg';

type InstanceListItemProps = {
  label: string;
  isAwarded: boolean;
  place: number;
  score: number;
  target?: number | null;
  isCurrent?: boolean;
  scoreToPrevious?: number;
  onClick: () => void;
};

export const InstanceListItem: React.FC<InstanceListItemProps> = ({
  label,
  isAwarded,
  place,
  score,
  target,
  isCurrent,
  scoreToPrevious,
  onClick,
}) => {
  const matchXs = useMediaQuery(theme.breakpoints.up('md'));
  const percent = target ? (score >= target ? 100 : Math.round((score / target) * 100)) : 0;
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        height: { xs: '56px', md: '64px' },
        marginLeft: '8px',
        borderRadius: '10px',
        paddingInline: { xs: '16px', md: '24px' },
        background: isAwarded
          ? `linear-gradient(91.55deg, ${Colors.blue[100]} 2.44%, #E8F8FA 100%)`
          : isCurrent
          ? `linear-gradient(88.79deg, ${Colors.magenta[150]} 0%, ${Colors.magenta[50]} 104.83%)`
          : `linear-gradient(89.56deg, ${Colors.gray[50]} -1.42%, #F7F9F9 99.73%)`,
        marginBottom: { xs: '8px', md: '16px' },
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      {isCurrent && scoreToPrevious ? (
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            top: 0,
            right: '10px',
            height: 20,
            transform: 'translateY(-50%)',
            border: `1px solid ${Colors.orange[300]}`,
            background: Colors.orange[500],
            paddingInline: '8px',
            borderRadius: '20px',
          }}
        >
          <ArrowUpIcon
            height={matchXs ? '10px' : '12px'}
            width={matchXs ? '10px' : '12px'}
            fill={Colors.white}
            style={{ marginRight: '8px' }}
          />
          <Typography
            sx={{ fontSize: { xs: '10px', md: '14px' }, fontWeight: 900, color: Colors.white, marginTop: '4px' }}
          >
            {numberWithSpaces(scoreToPrevious)}
          </Typography>
        </Box>
      ) : null}
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          top: '50%',
          display: 'flex',
          height: { xs: '24px', md: '30px' },
          width: { xs: '24px', md: '30px' },
          borderRadius: { xs: '24px', md: '30px' },
          border: `2px solid ${Colors.white}`,
          justifyContent: 'center',
          alignItems: 'center',
          background: isAwarded
            ? `linear-gradient(268.6deg, ${Colors.blue[600]} 8.42%, ${Colors.blue[500]} 99.55%)`
            : isCurrent
            ? `linear-gradient(268.52deg, ${Colors.magenta[400]} -2.46%, ${Colors.magenta[400]} 99.53%)`
            : `linear-gradient(236.78deg, ${Colors.gray[300]} -0.57%, ${Colors.gray[100]} 82.01%)`,
          transform: 'translateX(-50%) translateY(-50%)',
        }}
      >
        {isAwarded ? (
          <TrophyIcon height={matchXs ? '16px' : '12px'} width={matchXs ? '16px' : '12px'} fill={Colors.white} />
        ) : (
          <Typography
            sx={{ fontSize: { xs: '10px', md: '12px' }, color: Colors.white, fontWeight: 800, marginTop: '4px' }}
          >
            {place}
          </Typography>
        )}
      </Box>
      <Box sx={{ flex: 1 }}>
        <Typography sx={{ fontSize: { xs: '10px', md: '12px' }, fontWeight: 800, color: Colors.gray[700] }}>
          {label}
        </Typography>
        <Box
          sx={{
            position: 'relative',
            overflow: 'hidden',

            height: { xs: 18, md: target ? 16 : 24 },
            width: '100%',
            borderRadius: '5px',
            border: `1px solid ${isAwarded ? Colors.blue[500] : isCurrent ? Colors.magenta[300] : Colors.gray[100]}`,
            background: isAwarded ? Colors.blue[500] : isCurrent ? Colors.magenta[300] : Colors.gray[100],
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              top: 0,
              bottom: 0,
              left: 0,
              width: !target || score >= target ? '100%' : `${percent}%`,
              borderRadius: '4px',
              background: isAwarded
                ? `linear-gradient(270deg, ${Colors.blue[600]} 0%, ${Colors.blue[500]} 88%)`
                : isCurrent
                ? `linear-gradient(268.52deg, ${Colors.magenta[400]} -2.46%, ${Colors.magenta[400]} 99.53%)`
                : `linear-gradient(270deg, ${Colors.gray[300]} 0%, ${Colors.gray[100]} 100%)`,
            }}
          >
            <Typography sx={{ position: 'relative', fontSize: '10px', color: Colors.white, lineHeight: 1 }}>
              {target ? (percent > 7 ? `${percent}%` : '') : numberWithSpaces(score)}
            </Typography>
          </Box>
        </Box>
        {target ? (
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <Typography
              sx={{
                fontSize: '10px',
                color: isAwarded ? Colors.blue[600] : isCurrent ? Colors.magenta[400] : Colors.gray[500],
              }}
            >
              {numberWithSpaces(score)}
            </Typography>
            <Typography
              sx={{
                fontSize: '10px',
                color: isAwarded ? Colors.blue[400] : isCurrent ? Colors.magenta[200] : Colors.gray[300],
              }}
            >
              {numberWithSpaces(target)}
            </Typography>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
