import * as React from 'react';
import { alpha, Box, Card, CardContent, CardHeader, Skeleton, styled, Typography } from '@mui/material';
import { IonIcon } from '@ionic/react';
import IncreaseIcon from '../../assets/svg/increase_arrow.svg';
import DecreaseIcon from '../../assets/svg/decrease_arrow.svg';
import StableIcon from '../../assets/svg/stable_dash.svg';
import { IMetric } from '../../models/Metrics';
import { calculateTime } from './MetricsCard';

interface RatingCardProps {
  info:
    | {
        title: string;
        metrics: IMetric[];
        color: string;
        deviation: string;
        score: number;
        icon: string;
      }
    | undefined;
  loading?: boolean;
}

const CardIcon = styled(IonIcon)(({ theme }) => ({
  fontSize: theme.typography.h5.fontSize,
}));

const ArrowIcon = styled(IonIcon)(({ theme }) => ({
  fontSize: theme.typography.h5.fontSize,
  marginTop: 16,
  marginLeft: 8,
}));

const RatingCard: React.FC<RatingCardProps | undefined> = ({ info, loading }) => {
  const changeArrow = (change?: string) => {
    switch (change) {
      case 'INCREASE':
        return <ArrowIcon icon={IncreaseIcon} />;
      case 'DECREASE':
        return <ArrowIcon icon={DecreaseIcon} />;
      default:
        return <ArrowIcon icon={StableIcon} />;
    }
  };

  const extractSuffix = (metric: IMetric) => {
    if (metric?.metric_characteristics?.metric_unit !== 'NOT_AVAILABLE') {
      if (metric?.metric_characteristics?.metric_unit?.toLowerCase() === 'glass') {
        return 'glasses';
      } else return metric?.metric_characteristics?.metric_unit?.toLowerCase();
    }
  };

  if (info !== undefined) {
    return (
      <Card
        sx={{
          borderRadius: 2,
          backgroundColor: info.color,
          boxShadow: `0px 4px 24px ${alpha(info.color ?? '#000000', 0.25)}`,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          width: '100%',
          height: '100%',
        }}
        elevation={0}
      >
        <CardHeader
          sx={{ pb: 1 }}
          avatar={<CardIcon icon={info.icon} color={'light'} />}
          title={info.title}
          titleTypographyProps={{
            variant: 'h6',
            color: 'common.white',
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        />
        <CardContent
          sx={{
            pt: 0,
            pb: 1,
            '&:last-child': { pb: 1 },
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box width="100%" height="70px" display="flex" flexDirection="column" paddingRight={2}>
            {loading ? (
              <>
                <Skeleton height={20} width={120} />
                <Skeleton height={20} width={100} />
              </>
            ) : (
              info?.metrics.map((metric, index) => (
                <Box key={index} display="flex" justifyContent="space-between">
                  <Typography variant="caption" color="common.white">
                    {metric.title}
                  </Typography>
                  <Typography variant="caption" color="common.white">
                    {metric.metric_samples.length
                      ? metric.title === 'Sleep duration'
                        ? calculateTime(metric.metric_samples[0].total_value, 12)
                        : metric.metric_samples[0].total_value
                      : metric.title === 'Sleep duration'
                      ? calculateTime(0, 12)
                      : 0}{' '}
                    {metric.title !== 'Sleep duration' && extractSuffix(metric)}
                  </Typography>
                </Box>
              ))
            )}
          </Box>
          {loading ? (
            <Box display={'flex'}>
              <Skeleton height={72} width={64} />
              <Skeleton height={32} width={24} sx={{ mt: 1, ml: 1 }} />
            </Box>
          ) : (
            <Box display={'flex'}>
              <Typography variant={'h2'} fontWeight={'bold'} color="common.white">
                {info?.score}
              </Typography>
              {changeArrow(info?.deviation)}
            </Box>
          )}
        </CardContent>
      </Card>
    );
  } else {
    return (
      <Card
        sx={{
          borderRadius: 2,
          backgroundColor: '#000000',
          boxShadow: `0px 4px 24px ${alpha('#000000', 0.25)}`,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          width: '100%',
          height: '100%',
        }}
        elevation={0}
      >
        <CardContent
          sx={{
            pt: 0,
            pb: 1,
            '&:last-child': { pb: 1 },
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box width="100%" height="70px" textAlign="center">
            <Typography variant="h6">No data available</Typography>
          </Box>
        </CardContent>
      </Card>
    );
  }
};

export default RatingCard;
