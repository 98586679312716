import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import CustomNewHeader from '../../components/features/CustomNewHeader';
import { ReactComponent as GoalIcon } from '../../assets/svg/goal.svg';
import { Box, CardMedia, Container, DialogContent, List, Typography, useMediaQuery } from '@mui/material';
import theme from '../../theme';
import Grid from '@mui/material/Grid';
import { Colors } from '../../theme/colors';
import { RouteComponentProps } from 'react-router';
import { useHandleFetch } from './hooks/useHandleFetch';
import CustomSearchbar from '../../components/features/CustomSearchbar';
import { CustomButton } from '../../components/features/CustomButton';
import { TeamJoinModal } from '../../components/features/modals/TeamJoinModal';
import { TeamAbortModal } from '../../components/features/modals/TeamAbortModal';
import { TeamModal } from '../../components/features/modals/TeamModal';
import { ReactComponent as EmptyParticipantsIcon } from '../../assets/svg/feature/empty-participants.svg';
import { ReactComponent as EmailsIcon } from '../../assets/svg/feature/emails.svg';
import { ReactComponent as EditIcon } from '../../assets/svg/feature/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/svg/feature/delete.svg';
import IconPlaceholder from '../../assets/svg/placeholder_avatar.svg';
import { ReactComponent as ParticipantsIcon } from '../../assets/svg/feature/participants.svg';
import { ReactComponent as CreatorIcon } from '../../assets/svg/feature/creator.svg';
import UserService from '../../services/UserService';
import { ReactComponent as SlackIcon } from '../../assets/svg/feature/slack.svg';
import { RoundIconButton } from '../../components/features/RoundIconButton';
import { ReactComponent as TimeIcon } from '../../assets/svg/feature/time.svg';
import { Challenge } from '../../models/Challenge';
import { TeamDetails } from '../../models/Team';
import { useJoinHandle } from '../ChallengeDetailPage/hooks/useTeamJoinHandle';
import { useTeamAbortHandle } from '../ChallengeDetailPage/hooks/useTeamAbortHandle';
import { TeamDeleteModal } from '../../components/features/modals/TeamDeleteModal';
import { useDeleteHandle } from './hooks/useDeleteHandle';
import { useEmailHandle } from './hooks/useEmailHandle';
import { Actions } from './components/Actions';
import { SkeletonContent } from './components/SkeletonContent';
import { pluralWordRule } from '../../utils/pluralWordRule';
import { ReactComponent as ExportIcon } from '../../assets/svg/feature/export.svg';
import { CHALLENGE_SUB_CATEGORY } from '../../models/enum/CHALLENGE_SUB_CATEGORY';
import { Avatar } from '../../components/features/Avatar';
import { Capacitor } from '@capacitor/core';

type Props = RouteComponentProps<{
  id: string;
  teamId: string;
}>;
type RouteParams = {
  id: string;
  teamId: string;
};
export const TeamDetailPage: React.FC<Props> = ({ match }) => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const uuid = UserService.getMineId();
  const isWeb = !Capacitor.isNativePlatform();
  const {
    items,
    challenge,
    challengeLoading,
    loading,
    teamDetails,
    searchValue,
    daysLeftToJoin,
    challengeStatusBreadcrumbItem,
    form,
    teamModalLoading,
    showTeamModal,
    progressBreadcrumbItem,
    challengeBreadcrumbItem,
    handleUpdateData,
    setSearchValue,
    setShowTeamModal,
    handleUpdateImage,
    handleChangeSlackUrl,
    handleChangeTeamName,
    updateTeamRequest,
    handleExport,
  } = useHandleFetch(match.params as RouteParams);
  const challengeUUID = challenge && challenge.metadata.challengeUUID ? challenge.metadata.challengeUUID : '';
  const { joinLoading, joinTeam, setJoinTeam, joinToTeamRequest } = useJoinHandle(challengeUUID, handleUpdateData);
  const { abortLoading, abortTeamItem, setAbortTeamItem, abortRequest } = useTeamAbortHandle(
    challengeUUID,
    handleUpdateData,
  );
  const { showDelete, deleteLoading, setShowDelete, deleteRequest } = useDeleteHandle(
    teamDetails ? teamDetails.teamUUID : '',
    challenge,
  );
  const { emails, sendEmail } = useEmailHandle(
    match.params.teamId,
    teamDetails ? teamDetails.createdBy === uuid : false,
  );

  const days = Array(10)
    .fill(0)
    .map((e, i) => i + 1)
    .reverse();
  const spotsLeft = Array(
    challenge && challenge.challengeParticipation.maxTeamSize ? challenge.challengeParticipation.maxTeamSize : 0,
  )
    .fill(0)
    .map((e, i) => i + 1)
    .reverse();

  const DaysToJoin: React.FC = () =>
    daysLeftToJoin > 0 &&
    challenge &&
    challenge.challengeParticipation.isUserParticipant &&
    !(challenge.challengeParticipation.isUserTeamMember && !teamDetails?.isUserTeamMember) ? (
      <Grid sx={{ borderTop: '1px dashed #F2F2F2', pt: '16px', mt: '20px' }}>
        <Grid container>
          <Typography sx={{ fontSize: 18, lineHeight: 1, color: Colors.gray[700], fontWeight: 900, mr: '4px' }}>
            {daysLeftToJoin}
          </Typography>
          <Typography sx={{ fontSize: 14, lineHeight: 1, color: Colors.gray[400], fontWeight: 700 }}>
            day{pluralWordRule(Number(daysLeftToJoin))} left to join the team
          </Typography>
        </Grid>
        <Grid sx={{ mt: '8px', display: 'flex', alignItems: 'center' }}>
          <Grid sx={{ display: 'flex', alignItems: 'center' }}>
            <TimeIcon width={16} height={16} fill={Colors.gray[200]} />
          </Grid>
          {days.map((item) => (
            <Grid
              key={item}
              sx={
                item > daysLeftToJoin
                  ? {
                      height: '16px',
                      width: '16px',
                      backgroundColor: Colors.gray[50],
                      borderRadius: '100%',
                      ml: '7px',
                    }
                  : {
                      height: '16px',
                      width: '16px',
                      background: 'linear-gradient(180deg, #F0B8BA 0%, #A2ECCE 0.01%, #16CF84 100%)',
                      border: '0.5px solid #B9F1DA',
                      boxShadow: '1px 1px 4px rgba(22, 207, 132, 0.45)',
                      borderRadius: '100%',
                      ml: '7px',
                    }
              }
            />
          ))}
        </Grid>
      </Grid>
    ) : null;

  const SpotsLeft: React.FC<{ challenge: Challenge | null; teamDetails: TeamDetails | null }> = ({
    challenge,
    teamDetails,
  }) =>
    challenge &&
    teamDetails &&
    challenge.challengeParticipation.maxTeamSize &&
    daysLeftToJoin > 0 &&
    challenge.challengeParticipation.isUserParticipant &&
    !(challenge.challengeParticipation.isUserTeamMember && !teamDetails?.isUserTeamMember) ? (
      <Grid sx={{ borderTop: '1px dashed #F2F2F2', pt: '16px', mt: '20px' }}>
        <Grid container>
          <Typography sx={{ fontSize: 18, lineHeight: 1, color: Colors.gray[700], fontWeight: 900, mr: '4px' }}>
            {challenge && challenge.challengeParticipation.maxTeamSize
              ? challenge.challengeParticipation.maxTeamSize - teamDetails.teamSize
              : 0}
          </Typography>
          <Typography sx={{ fontSize: 14, lineHeight: 1, color: Colors.gray[400], fontWeight: 700 }}>
            spot
            {pluralWordRule(
              Number(
                challenge && challenge.challengeParticipation.maxTeamSize
                  ? challenge.challengeParticipation.maxTeamSize - teamDetails.teamSize
                  : 0,
              ),
            )}{' '}
            left
          </Typography>
        </Grid>
        <Grid sx={{ mt: '8px', display: 'flex', alignItems: 'center' }}>
          <Grid sx={{ display: 'flex', alignItems: 'center' }}>
            <ParticipantsIcon width={16} height={16} fill={Colors.gray[200]} />
          </Grid>
          {spotsLeft.map((item) => (
            <Grid
              key={item}
              sx={
                item > teamDetails.teamSize
                  ? {
                      height: '16px',
                      width: '10px',
                      backgroundColor: Colors.gray[50],
                      borderRadius: '2px',
                      ml: '5px',
                    }
                  : {
                      height: '16px',
                      width: '10px',
                      background: 'linear-gradient(180deg, #A2E5EC 0%, #45CBD9 100%)',
                      border: '0.5px solid #D0F2F5',
                      boxShadow: '1px 1px 4px rgba(22, 190, 207, 0.35)',
                      borderRadius: '2px',
                      ml: '5px',
                    }
              }
            />
          ))}
        </Grid>
      </Grid>
    ) : null;

  const Information: React.FC<{ uuid: string; challenge: Challenge | null; teamDetails: TeamDetails | null }> = ({
    uuid,
    challenge,
    teamDetails,
  }) => (
    <Grid
      sx={{
        padding: '24px 16px',
        backgroundColor: Colors.white,
        borderRadius: '20px',
        borderTop: `5px solid ${Colors.green[500]}`,
        boxShadow: '5px 0px 15px rgba(108, 123, 132, 0.05)',
      }}
    >
      <CardMedia
        sx={{
          flexShrink: 0,
          width: '100%',
          height: 136,
          borderRadius: '15px',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
        image={teamDetails && teamDetails.avatar ? teamDetails.avatar : IconPlaceholder}
      />

      {teamDetails &&
        teamDetails.teamSlackURL &&
        challenge &&
        challenge.challengeParticipation.isUserParticipant &&
        challenge.challengeParticipation.isUserTeamMember &&
        !(challenge.challengeParticipation.isUserTeamMember && !teamDetails?.isUserTeamMember) && (
          <Box sx={{ pt: '16px', mt: '20px', borderTop: '1px dashed #F2F2F2' }}>
            <Typography sx={{ fontSize: '12px', color: Colors.gray[400], fontWeight: 700, mb: '8px' }}>
              Team private channel:
            </Typography>

            <RoundIconButton
              sx={{
                marginBottom: {
                  xs: '4px',
                  sm: 0,
                  width: { xs: '100%', sm: 'auto' },
                },
                alignSelf: { xs: 'stretch', sm: 'unset' },
                backgroundColor: Colors.gray[50],
                width: '100%',
              }}
              Icon={SlackIcon}
              size={36}
              background={Colors.white}
              roundColor={Colors.white}
              textColor={Colors.gray[600]}
              roundBorderColor={Colors.gray[150]}
              textSx={{
                fontSize: '14px',
                maxWidth: '150px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
              label={teamDetails.name}
              onClick={() => window.open(teamDetails.teamSlackURL)}
            />
          </Box>
        )}
      {challenge && !challenge.challengeParticipation.isUserTeamMember && uuid !== teamDetails?.createdBy && (
        <>
          <DaysToJoin />

          <SpotsLeft challenge={challenge} teamDetails={teamDetails} />
        </>
      )}
    </Grid>
  );

  const AdminActions: React.FC<{
    onSendEmail: () => void;
    onEdit: () => void;
    onDelete: () => void;
    handleExport: () => void;
  }> = ({ onSendEmail, onEdit, onDelete, handleExport }) => {
    return teamDetails && teamDetails.createdBy === uuid ? (
      <Box sx={{ mt: mdDown ? '10px' : '58px' }}>
        {emails.length > 0 ? (
          <CustomButton
            sx={{
              maxWidth: '100%',
            }}
            minWidth={mdDown ? '118px' : '94px'}
            inputProps={{
              variant: 'contained',
              startIcon: <EmailsIcon width={16} height={16} style={{ fill: Colors.blue[500] }} />,
            }}
            type={'bigLightBlue'}
            text={'Send email'}
            onClick={onSendEmail}
          />
        ) : null}
        <CustomButton
          sx={{
            mt: emails.length > 0 ? '16px' : 0,
            maxWidth: '100%',
          }}
          minWidth={mdDown ? '118px' : '94px'}
          inputProps={{
            variant: 'contained',
            startIcon: <EditIcon width={16} height={16} style={{ fill: '#FAAF1F' }} />,
          }}
          type={'bigLightOrange'}
          text={'Edit'}
          onClick={onEdit}
        />
        <CustomButton
          sx={{
            mt: '16px',
            maxWidth: '100%',
          }}
          minWidth={mdDown ? '118px' : '94px'}
          inputProps={{
            variant: 'contained',
            startIcon: <DeleteIcon width={16} height={16} style={{ fill: Colors.red[500] }} />,
          }}
          type={'bigLightRed'}
          text={'Delete team'}
          onClick={onDelete}
        />
      </Box>
    ) : null;
  };
  return (
    <IonPage>
      <CustomNewHeader
        breadcrumb={{
          Icon: GoalIcon,
          list: [
            { label: 'Your Challenges', url: '/page/your-challenges' },
            ...challengeStatusBreadcrumbItem,
            ...progressBreadcrumbItem(),
            ...challengeBreadcrumbItem(),
            { label: teamDetails ? teamDetails.name : '', url: '/' },
          ],
        }}
        inProgress={challengeLoading || loading}
        label={teamDetails ? teamDetails.name : ''}
      />

      <IonContent style={{ '--padding-bottom': '36px' }} scrollEvents={true}>
        <Container
          maxWidth={'lg'}
          sx={{
            paddingBottom: teamDetails && !teamDetails.isUserTeamMember && mdDown ? '56px' : 0,
          }}
        >
          {challengeLoading ? (
            <SkeletonContent />
          ) : challenge ? (
            <>
              {mdDown && <Information challenge={challenge} teamDetails={teamDetails} uuid={uuid} />}
              <Grid
                container
                display={'flex'}
                sx={{ mt: { xs: '18px', md: 0 }, flexWrap: { xs: 'wrap', md: 'noWrap' } }}
              >
                <Grid sx={{ position: 'relative', width: '100%', marginRight: mdDown ? 0 : '24px' }}>
                  <Grid
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: { xs: '16px 16px 24px 16px', md: '21px 16px' },
                      background: Colors.white,
                      borderTop: { xs: '5px solid #73D8E2', md: 'none' },
                      borderLeft: { xs: 'none', md: '5px solid #73D8E2' },
                      boxShadow: '5px 0px 15px rgba(108, 123, 132, 0.05)',
                      borderRadius: '20px',
                      margin: '0 0 40px 0',
                    }}
                  >
                    <Grid
                      sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        alignItems: { xs: 'flex-start', md: 'center' },
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: '16px', md: '22px' },
                          lineHeight: { xs: '16px', md: '22px' },
                          color: Colors.gray[700],
                          fontWeight: 900,
                          flexShrink: 0,
                        }}
                      >
                        Team members
                      </Typography>
                      <Grid
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          flexWrap: 'wrap-reverse',
                          width: { xs: '100%', md: 'auto' },
                        }}
                      >
                        {teamDetails && teamDetails.createdBy === uuid && isWeb && (
                          <Box
                            component="button"
                            sx={{
                              display: 'flex',
                              alignItems: 'baseline',
                              marginRight: '16px',
                              marginTop: { xs: '16px', md: 'unset' },
                              cursor: 'pointer',
                              background: 'unset',
                            }}
                            onClick={handleExport}
                          >
                            <ExportIcon fill={Colors.gray[400]} style={{ transform: 'translateY(4px)' }} />
                            <Typography sx={{ fontSize: '12px', textDecoration: 'underline', color: Colors.gray[400] }}>
                              Export as .xls file
                            </Typography>
                          </Box>
                        )}
                        <Grid sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', mr: '24px' }}>
                          <ParticipantsIcon width={16} height={16} fill={Colors.gray[300]} />
                          <Typography
                            sx={{
                              color: Colors.gray[300],
                              fontSize: '12px',
                              lineHeight: '12px',
                              ml: '8px',
                              mt: '2px',
                            }}
                            component="span"
                            variant="body2"
                          >
                            <b>{items.length}</b> member{pluralWordRule(Number(items.length))}
                          </Typography>
                        </Grid>

                        <CustomSearchbar
                          width={mdDown ? '100%' : 276}
                          sx={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', mt: { xs: '16px', md: 0 } }}
                          searchInput={searchValue}
                          setSearchInput={setSearchValue}
                          handleSearch={() => undefined}
                        />
                      </Grid>
                    </Grid>

                    {items.length ? (
                      <DialogContent
                        sx={{
                          marginTop: '24px',
                          padding: mdDown ? 0 : '0 10px 0 0',
                          '&::-webkit-scrollbar-track': { background: '#F0F2F3!important' },
                          '&::-webkit-scrollbar-thumb': { background: '#D3D7DA!important' },
                          '&::-webkit-scrollbar-track:hover': { background: '#F0F2F3!important' },
                          '*::-webkit-scrollbar': {
                            width: '5px!important',
                            height: '5px !important',
                          },
                        }}
                      >
                        <Box
                          sx={{
                            width: '100%',
                            maxHeight: '272px',
                            maxWidth: '100%',
                            backgroundColor: 'background.paper',
                            margin: '0 auto',
                          }}
                        >
                          <List
                            sx={{
                              width: '100%',
                              maxWidth: '100%',
                              position: 'relative',
                              overflowY: 'auto',
                              padding: 0,
                              '& ul': { padding: 0, margin: 0 },
                            }}
                          >
                            <Grid sx={{ display: 'flex', flexWrap: 'wrap' }}>
                              {items.map((item, index) => (
                                <Grid sx={{ width: { xs: '100%', md: '50%' } }} key={index}>
                                  <Grid
                                    sx={[
                                      {
                                        display: 'flex',
                                        alignItems: 'center',
                                        background: Colors.gray[50],
                                        borderRadius: '10px',
                                        ml: '20px',
                                        mt: index ? '8px' : 0,
                                        padding: '4px 8px 4px 0',
                                      },
                                      mdDown
                                        ? {}
                                        : (index + 1) % 2
                                        ? {
                                            marginTop: index === 0 || index === 1 ? 0 : '8px',
                                            marginRight: '8px',
                                          }
                                        : {
                                            marginTop: index === 0 || index === 1 ? 0 : '8px',
                                            marginLeft: '28px',
                                          },
                                    ]}
                                  >
                                    <Grid sx={{ ml: '-20px' }}>
                                      <Box
                                        sx={{
                                          height: '40px',
                                          width: '40px',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          borderWidth: 2,
                                          borderColor: Colors.white,
                                          borderStyle: 'solid',
                                          position: 'relative',
                                          overflow: 'hidden',
                                          borderRadius: '100%',
                                        }}
                                      >
                                        <Avatar
                                          src={item.avatar ? item.avatar : IconPlaceholder}
                                          alt={'background'}
                                          sx={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                            objectPosition: 'center',
                                            position: 'absolute',
                                          }}
                                        />
                                      </Box>
                                    </Grid>
                                    <Grid
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'flex-start',
                                        width: '100%',
                                        ml: '8px',
                                      }}
                                    >
                                      <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography
                                          sx={{ color: Colors.gray[700], fontSize: '14px', lineHeight: '14px' }}
                                          component="span"
                                          variant="body2"
                                        >
                                          {item.name}
                                        </Typography>
                                        <Typography
                                          sx={{
                                            color: Colors.gray[400],
                                            fontSize: '10px',
                                            lineHeight: '10px',
                                            mt: '4px',
                                          }}
                                          component="span"
                                          variant="body2"
                                        >
                                          {item.email}
                                        </Typography>
                                      </Grid>
                                      {item.isTeamCreator ? (
                                        <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                                          <Typography
                                            sx={{
                                              color: Colors.gray[400],
                                              fontSize: '10px',
                                              lineHeight: '10px',
                                              mr: '4px',
                                            }}
                                            component="span"
                                            variant="body2"
                                          >
                                            Creator
                                          </Typography>
                                          <CreatorIcon width={12} height={12} />
                                        </Grid>
                                      ) : null}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ))}
                            </Grid>
                          </List>
                        </Box>
                      </DialogContent>
                    ) : (
                      <Grid
                        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pb: '20px', mt: '24px' }}
                      >
                        <EmptyParticipantsIcon width={100} height={84} />
                        <Typography sx={{ fontSize: '14px', color: Colors.gray[400], fontWeight: 400, mt: '10px' }}>
                          Be the first person to join this team!
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                {!mdDown && (
                  <Grid sx={{ width: '276px', flexShrink: 0 }}>
                    <Information challenge={challenge} teamDetails={teamDetails} uuid={uuid} />
                    {challenge.challengeSubCategory !== CHALLENGE_SUB_CATEGORY.FINISHED &&
                      !teamDetails?.hasUserAlreadyJoinThisTeam && (
                        <Actions
                          challenge={challenge}
                          teamDetails={teamDetails}
                          daysLeftToJoin={daysLeftToJoin}
                          onJoin={() => setJoinTeam(teamDetails)}
                          onAbort={() => setAbortTeamItem(teamDetails)}
                        />
                      )}
                    {challenge.challengeSubCategory !== CHALLENGE_SUB_CATEGORY.FINISHED && (
                      <AdminActions
                        onEdit={() => setShowTeamModal(true)}
                        onDelete={() => setShowDelete(true)}
                        onSendEmail={sendEmail}
                        handleExport={handleExport}
                      />
                    )}
                  </Grid>
                )}
              </Grid>
              {mdDown && (
                <>
                  <Box
                    sx={{
                      position: teamDetails && !teamDetails.isUserTeamMember ? 'fixed' : 'relative',
                      bottom: teamDetails && !teamDetails.isUserTeamMember ? 30 : 'auto',
                      left: teamDetails && !teamDetails.isUserTeamMember ? 30 : 'auto',
                      zIndex: 3,
                      maxWidth: teamDetails && !teamDetails.isUserTeamMember ? '118px' : '328px',
                    }}
                  >
                    {challenge.challengeSubCategory !== CHALLENGE_SUB_CATEGORY.FINISHED &&
                      !teamDetails?.hasUserAlreadyJoinThisTeam && (
                        <Actions
                          challenge={challenge}
                          teamDetails={teamDetails}
                          daysLeftToJoin={daysLeftToJoin}
                          onJoin={() => setJoinTeam(teamDetails)}
                          onAbort={() => setAbortTeamItem(teamDetails)}
                        />
                      )}
                  </Box>
                  {challenge.challengeSubCategory !== CHALLENGE_SUB_CATEGORY.FINISHED && (
                    <AdminActions
                      onEdit={() => setShowTeamModal(true)}
                      onDelete={() => setShowDelete(true)}
                      onSendEmail={sendEmail}
                      handleExport={handleExport}
                    />
                  )}
                </>
              )}
            </>
          ) : null}
        </Container>
        <TeamJoinModal
          show={!!joinTeam}
          showMore={false}
          title={challenge ? challenge.challengeInfo.title : ''}
          challenge={challenge}
          loading={joinLoading}
          url={'/'}
          onClose={() => setJoinTeam(null)}
          onJoin={joinToTeamRequest}
        />
        <TeamAbortModal
          show={!!abortTeamItem}
          loading={abortLoading}
          daysLeftToJoin={daysLeftToJoin}
          onClose={() => setAbortTeamItem(null)}
          onAbort={abortRequest}
        />
        <TeamModal
          show={showTeamModal}
          isEdit={true}
          form={form}
          loading={teamModalLoading}
          handleChangeTeamName={handleChangeTeamName}
          handleUpdateImage={handleUpdateImage}
          handleChangeSlackUrl={handleChangeSlackUrl}
          onClose={() => setShowTeamModal(false)}
          onCreate={updateTeamRequest}
          onSave={updateTeamRequest}
        />
        <TeamDeleteModal
          show={showDelete}
          loading={deleteLoading}
          onClose={() => setShowDelete(false)}
          onDelete={() => deleteRequest()}
        />
      </IonContent>
    </IonPage>
  );
};
