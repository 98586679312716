import React from 'react';
import { Grid } from '@mui/material';

import { RatingCard } from './cards/RatingCard';
import { WellbeingCard } from './cards/WellbeingCard';
import { JourneyCards } from '../../models/JourneyCards';
import { getMetricsCategory, categories } from '../../utils/metricsCategories';

type JourneyComponentProps = {
  journeyData?: JourneyCards;
  admin?: boolean;
  loading?: boolean;
  date: string;
};

export const JourneyComponent: React.FC<JourneyComponentProps> = ({ journeyData, admin, loading, date }) => {
  return (
    <Grid container spacing={2} justifyContent={'center'}>
      <Grid item container md={6} xs={12}>
        <WellbeingCard
          admin={admin}
          date={date}
          rating={journeyData?.scores?.wellbeing_index_number ?? 0}
          series={categories.map((category) => getMetricsCategory(category, journeyData)?.score ?? 0)}
          colors={categories.map((category) => getMetricsCategory(category, journeyData)?.color ?? '#fff')}
          loading={loading}
        />
      </Grid>
      <Grid item md={6} xs={12} spacing={2} container>
        <Grid item sm={6} xs={12}>
          <RatingCard info={getMetricsCategory('mental_health', journeyData)} loading={loading} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <RatingCard info={getMetricsCategory('sleep', journeyData)} loading={loading} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <RatingCard info={getMetricsCategory('nutrition', journeyData)} loading={loading} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <RatingCard info={getMetricsCategory('physical_health', journeyData)} loading={loading} />
        </Grid>
      </Grid>
    </Grid>
  );
};
