import { useEffect } from 'react';
import { Challenge } from '../../../models/Challenge';
import { COMPETITION } from '../../../models/enum/COMPETITION';
import { CHALLENGE_STATUS } from '../../../models/enum/CHALLENGE_STATUS';
import { dayIsFinished } from '../../../utils/dayComparingUtils';

export const useHandleProgress = (challenge: Challenge) => {
  const ranking = challenge?.teamRanking ?? challenge?.userRanking;
  const isMember =
    challenge?.challengeStatus === CHALLENGE_STATUS.ACTIVE ||
    challenge?.challengeStatus === CHALLENGE_STATUS.IN_GRACE_PERIOD ||
    challenge?.challengeStatus === CHALLENGE_STATUS.COMPLETED
      ? challenge?.challengeCharacteristic?.competition === COMPETITION.TEAM
        ? challenge?.challengeParticipation?.isUserTeamMember
        : challenge?.challengeParticipation?.isUserParticipant
      : false;
  const percent = ranking?.completionPercentage;
  const isLogButtonHidden =
    dayIsFinished(challenge?.challengeDuration.gracePeriodEnd ?? new Date()) ||
    challenge?.challengeAction.actionSource === 'AUTO';
  return { ranking, isMember, percent, isLogButtonHidden };
};
