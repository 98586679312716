import { METRICS_UNIT } from '../../models/enum/METRICS_UNIT';

import { Colors } from '../../theme/colors';
import { ReactComponent as WinIcon } from '../../assets/svg/feature/challengeType/target.svg';
import { ReactComponent as ExtraIcon } from '../../assets/svg/feature/winner.svg';
import { ReactComponent as MineIcon } from '../../assets/svg/feature/meditation.svg';
import { METRICS_CATEGORY } from '../../models/enum/METRICS_CATEGORY';
import { PreviewRoundItemProps } from '../../models/Shared';

export const unitsLabel = {
  [METRICS_UNIT.MINUTES as string]: 'minutes',
  [METRICS_UNIT.GLASS as string]: 'glasses',
  [METRICS_UNIT.PORTIONS as string]: 'portions',
  [METRICS_UNIT.TIMES as string]: 'times',
  [METRICS_UNIT.KG as string]: 'kg',
  [METRICS_UNIT.KM as string]: 'km',
  [METRICS_UNIT.CAL as string]: 'calories',
  [METRICS_UNIT.MS as string]: 'ms',
  [METRICS_UNIT.BPM as string]: 'bmp',
  [METRICS_UNIT.LB as string]: 'lbs',
  [METRICS_UNIT.MILES as string]: 'miles',
  [METRICS_UNIT.SESSIONS as string]: 'sessions',
};

export const LIST_METRIC_CATEGORY: PreviewRoundItemProps[] = [
  {
    value: METRICS_CATEGORY.WIN,
    label: 'WIN',
    Icon: WinIcon,
    mainColor: Colors.blue[700],
    borderColor: Colors.blue[600],
    backgroundColor: Colors.blue[800],
  },
  {
    value: METRICS_CATEGORY.EXTRA,
    label: 'Extra',
    Icon: ExtraIcon,
    mainColor: Colors.blue[700],
    borderColor: Colors.blue[600],
    backgroundColor: Colors.blue[800],
  },
  {
    value: METRICS_CATEGORY.MINE,
    label: 'Mine',
    Icon: MineIcon,
    mainColor: Colors.blue[700],
    borderColor: Colors.blue[600],
    backgroundColor: Colors.blue[800],
  },
];
