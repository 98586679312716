import { useEffect, useState } from 'react';
import { Challenge } from '../../../models/Challenge';
import { useIonRouter } from '@ionic/react';
import { deleteTeam } from '../../../lib/api/http/requests/challengeTeam';

export const useDeleteHandle = (teamUUID: string, challenge: Challenge | null) => {
  const router = useIonRouter();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    if (deleteLoading) return;

    setShowDelete(false);
  }, [deleteLoading]);
  const deleteRequest = async () => {
    setDeleteLoading(true);
    try {
      await deleteTeam({ teamUUID: teamUUID });

      setDeleteLoading(false);
      if (!challenge) return;
      router.push(`/page/your-challenges/challenge/${challenge.metadata.challengeUUID}`);
    } catch {
      setDeleteLoading(false);
    }
  };

  return {
    deleteLoading,
    showDelete,
    deleteRequest,
    setShowDelete,
  };
};
