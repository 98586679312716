import React, { FC } from 'react';
import { Box, CircularProgress, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../../assets/svg/feature/close.svg';
import { ReactComponent as BulbIcon } from '../../../assets/svg/feature/bulb.svg';
import { Colors } from '../../../theme/colors';
import { CustomButton } from '../CustomButton';
import { CustomInfoDialog } from './CustomInfoDialog';
import { COMPETITION } from '../../../models/enum/COMPETITION';
import { Challenge } from '../../../models/Challenge';

interface ModalProps {
  show: boolean;
  challenge: Challenge | null;
  isTeam?: boolean;
  loading: boolean;
  onClose: () => void;
  onAbort: () => void;
}

export const ChallengeAbortModal: FC<ModalProps> = ({
  show = false,
  challenge,
  isTeam = false,
  loading,
  onClose,
  onAbort,
}) => {
  return (
    <CustomInfoDialog show={show}>
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 2,
            background: 'rgba(65, 74, 79, 0.3)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress color="inherit" style={{ width: 30, height: 30 }} />
        </Box>
      )}

      <DialogTitle display={'flex'} flexDirection={'column'} sx={{ padding: '16px 16px 0 16px' }}>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <IconButton sx={{ width: 28, height: 28, backgroundColor: Colors.gray[50] }} onClick={onClose}>
            <CloseIcon style={{ width: 12, height: 12 }} fill={Colors.gray[400]} />
          </IconButton>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mt: '6px', maxWidth: '296px', mx: 'auto' }}
        >
          <Typography
            variant="h5"
            sx={{ textAlign: 'center', color: Colors.gray[700], fontWeight: 'bold', fontSize: 18 }}
          >
            Abort the challenge
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ padding: '0 32px 0 32px' }}>
        <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" sx={{ mt: '16px' }}>
          <Typography
            variant="h5"
            sx={{ textAlign: 'center', color: Colors.gray[500], fontWeight: 400, fontSize: 16, whiteSpace: 'pre-line' }}
          >
            {isTeam ||
            (challenge &&
              (challenge.challengeCharacteristic.competition === COMPETITION.INDIVIDUAL ||
                challenge.challengeCharacteristic.competition === COMPETITION.TEAM))
              ? 'Are you sure you want to abort the challenge?\n You cannot re-join if you change your mind.'
              : ' Are you sure you want to abort the challenge?'}
          </Typography>
          {isTeam && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: Colors.green[50],
                borderRadius: '30px',
                padding: '8px 16px',
                mt: '8px',
              }}
            >
              <BulbIcon width={16} height={16} style={{ fill: Colors.green[600], flexShrink: 0 }} />
              <Typography sx={{ fontSize: 12, lineHeight: 1.4, color: Colors.green[600], ml: '8px' }}>
                Your data will stay with the team. Make sure that you have synced (or logged) everything until now.{' '}
              </Typography>
            </Box>
          )}
        </Box>
      </DialogContent>

      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{ margin: '24px auto 50px auto', maxWidth: 320, width: '100%' }}
      >
        <CustomButton type={'bigBlue'} text={'Abort'} onClick={onAbort} />
        <CustomButton type={'bigLightBlue'} text={'Cancel'} onClick={onClose} />
      </Box>
    </CustomInfoDialog>
  );
};
