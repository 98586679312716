import React from 'react';
import { Box, Skeleton, styled, useMediaQuery, useTheme } from '@mui/material';

const StyledBox = styled(Box)({
  position: 'relative',
  minHeight: '540px',
  height: '540px',
});

const SkeletonBlogPostCard: React.FC = () => {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <StyledBox sx={xsDown ? { height: 440, minHeight: 440 } : undefined}>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '0',
          paddingBottom: '56.25%',
          backgroundColor: '#8B8B8B50',
          borderRadius: '8px',
        }}
      />
      <Skeleton width={'100%'} height={'18px'} sx={{ borderRadius: '8px', mt: 4 }} />
      <Skeleton width={'100%'} height={'32px'} sx={{ borderRadius: '8px' }} />
      <Skeleton width={'100%'} height={'120px'} sx={{ borderRadius: '8px' }} />
    </StyledBox>
  );
};

export default SkeletonBlogPostCard;
