import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { COMPETITION } from '../../../models/enum/COMPETITION';

export const useHandleRanking = () => {
  const isLoading = useSelector((state: RootState) => state.challengeProgress.meta.isLoading);
  const userData = useSelector((state: RootState) => state.challengeProgress.data.user);
  const profile = useSelector((state: RootState) => state.profile.data.accountInfo);
  const detail = useSelector((state: RootState) => state.challengeProgress.data.detail);

  return {
    isLoading,
    avatar: userData?.avatar,
    name: detail?.isTeam
      ? userData?.name
      : profile?.personal_details?.first_name?.length
      ? profile?.personal_details?.first_name
      : profile?.account_details?.username,
    place: userData?.position,
    isSelf: detail?.competition === COMPETITION.SELF,
  };
};
