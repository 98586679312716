import React, { FC } from 'react';
import { Box, Skeleton, Typography, alpha } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SwiperProps } from 'swiper/react/swiper-react';
import { IMetric, IMetrics } from '../../../models/Metrics';
import { MetricsCard } from '../cards/MetricsCard';
import { ANALYTICS_PAGE } from '../../../models/enum/ANALYTICS_PAGE';
import { Mousewheel, Scrollbar } from 'swiper';

interface Props {
  metrics: IMetrics;
  date: any;
  onEdit: (metric: IMetric) => void;
  page?: ANALYTICS_PAGE;
  loading?: boolean;
}

const slideOptions: SwiperProps = {
  direction: 'vertical',
  slidesPerView: 3,
  slidesPerGroupSkip: 3,
  spaceBetween: 8,
};

export const MetricsContainer: FC<Props> = ({ metrics, date, onEdit, page, loading }) => {
  if (loading)
    return (
      <Box sx={{ width: '100%' }}>
        {[0, 1, 2].map((_, index) => (
          <Skeleton
            key={index}
            variant={'rectangular'}
            width={'100%'}
            height={120}
            sx={{
              borderRadius: '10px',
              mb: '8px',
            }}
          />
        ))}
      </Box>
    );
  if (!metrics?.metrics || !metrics?.metrics.length)
    return (
      <Box display="flex" width="100%" justifyContent="center" sx={{ p: 3 }}>
        <Typography fontWeight="bold" variant="body1">
          No metrics available
        </Typography>
      </Box>
    );
  return (
    <Swiper
      initialSlide={0}
      scrollbar={true}
      mousewheel={true}
      modules={[Scrollbar, Mousewheel]}
      {...slideOptions}
      style={{
        width: 'calc(100% + 32px)',
        height: '400px',
        paddingLeft: '16px',
        paddingRight: '8px',
        paddingTop: '8px',
        paddingBottom: '8px',
        marginLeft: '-16px',
        marginRight: '-8px',
        marginTop: '-8px',
        overflow: 'auto',
      }}
    >
      {metrics.metrics.map((metric, index) => (
        <SwiperSlide key={metric.metric_type + metric.title}>
          <MetricsCard key={index} metric={metric} date={date} onEdit={onEdit} page={page} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default MetricsContainer;
