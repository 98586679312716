import { Colors } from '../../../theme/colors';
import Grid from '@mui/material/Grid';
import { Box, Tooltip, Typography, tooltipClasses } from '@mui/material';
import IconPlaceholder from '../../../assets/svg/placeholder_avatar.svg';
import React from 'react';
import { Team } from '../../../models/Team';
import { SxProps } from '@mui/system';
import { ReactComponent as ParticipantsIcon } from '../../../assets/svg/feature/participants.svg';
import { ReactComponent as PlusIcon } from '../../../assets/svg/plus.svg';
import { ReactComponent as MinusIcon } from '../../../assets/svg/feature/minus.svg';
import { CustomButton } from '../../../components/features/CustomButton';
import UserService from '../../../services/UserService';
import { pluralWordRule } from '../../../utils/pluralWordRule';
type Props = {
  team: Team;
  showActions: boolean;
  sx?: SxProps;
  showJoin: boolean;
  onJoin: (e: any) => void;
  onAbort: (e: any) => void;
  onClick: () => void;
};
export const TeamItem: React.FC<Props> = ({ team, showActions, sx, showJoin, onJoin, onAbort, onClick }) => {
  const uuid = UserService.getMineId();
  const isMyTeam = Boolean(uuid === team.createdBy);
  const Actions: React.FC<{
    uuid: string;
    team: Team;
    sx?: SxProps;
    onJoin: (e: any) => void;
    onAbort: (e: any) => void;
  }> = ({ uuid, team, sx, onJoin, onAbort }) =>
    !(uuid === team.createdBy) && showActions ? (
      <Grid sx={{ ml: '16px', ...sx }}>
        {showJoin && !team.isUserTeamMember && !team.hasUserAlreadyJoinThisTeam && team.spotsLeft > 0 && (
          <CustomButton
            type={'smallMagenta'}
            text={'Join'}
            inputProps={{
              variant: 'contained',
              startIcon: <PlusIcon width={12} height={12} />,
            }}
            onClick={onJoin}
          />
        )}
        {team.isUserTeamMember && (
          <CustomButton
            type={'smallMagenta'}
            text={'Leave'}
            inputProps={{
              variant: 'contained',
              startIcon: <MinusIcon width={12} height={12} fill={Colors.white} />,
            }}
            onClick={onAbort}
          />
        )}
      </Grid>
    ) : null;
  return (
    <Tooltip title={team.hasUserAlreadyJoinThisTeam ? 'You have recently left this team and cannot rejoin' : ''}>
      <Grid
        sx={{
          display: 'flex',
          alignItems: 'center',
          background:
            isMyTeam || team.isUserTeamMember
              ? 'linear-gradient(88.79deg, #DAB9F1 0%, #F3E8FA 104.83%)'
              : Colors.gray[50],
          borderRadius: '10px',
          ml: '20px',
          mt: '8px',
          padding: { xs: '12px 8px 12px 0', md: '7px 8px 7px 0' },
          cursor: 'pointer',
          ...sx,
        }}
        onClick={onClick}
      >
        <Grid sx={{ ml: '-20px' }}>
          <Box
            sx={{
              height: '40px',
              width: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderWidth: 2,
              borderColor: Colors.white,
              borderStyle: 'solid',
              position: 'relative',
              overflow: 'hidden',
              borderRadius: '100%',
            }}
          >
            <img
              src={team.avatar ? team.avatar : IconPlaceholder}
              alt={'background'}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
                position: 'absolute',
              }}
            />
          </Box>
        </Grid>
        <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', ml: '8px' }}>
          <Grid sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Typography
              sx={{
                color: isMyTeam || team.isUserTeamMember ? '#626470' : Colors.gray[700],
                fontSize: '14px',
                lineHeight: '14px',
                fontWeight: 700,
              }}
              component="span"
              variant="body2"
            >
              {team.name}
            </Typography>
            <Grid
              sx={{
                display: 'flex',
                mt: { xs: !(uuid === team.createdBy) && showActions ? '10px' : '4px', md: '4px' },
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Grid
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: { xs: !(uuid === team.createdBy) && showActions ? 'column' : 'row', sm: 'row' },
                }}
              >
                <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                  <ParticipantsIcon
                    width={12}
                    height={12}
                    fill={isMyTeam || team.isUserTeamMember ? '#626470' : Colors.gray[400]}
                  />
                  <Typography
                    sx={{
                      color: isMyTeam || team.isUserTeamMember ? '#626470' : Colors.gray[400],
                      fontSize: '10px',
                      lineHeight: '10px',
                      ml: '4px',
                      mt: '2px',
                    }}
                    component="span"
                    variant="body2"
                  >
                    <b>{team.teamSize}</b> participant{pluralWordRule(team.teamSize)}
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    margin: '6px 8px 0 8px',
                    width: '2px',
                    height: '2px',
                    backgroundColor: isMyTeam || team.isUserTeamMember ? '#626470' : Colors.gray[400],
                    borderRadius: '100%',
                    display: { xs: !(uuid === team.createdBy) && showActions ? 'none' : 'flex', sm: 'flex' },
                  }}
                />
                <Typography
                  sx={{
                    color: isMyTeam || team.isUserTeamMember ? '#626470' : Colors.gray[400],
                    fontSize: '10px',
                    lineHeight: '10px',
                    mt: { xs: !(uuid === team.createdBy) && showActions ? '4px' : '2px', md: '2px' },
                  }}
                  component="span"
                  variant="body2"
                >
                  <b>{team.spotsLeft}</b> spot{pluralWordRule(team.spotsLeft)} left
                </Typography>
              </Grid>
              <Actions
                uuid={uuid}
                team={team}
                sx={{ display: { xs: 'flex', sm: 'none' } }}
                onAbort={onAbort}
                onJoin={onJoin}
              />
            </Grid>
          </Grid>

          <Actions
            uuid={uuid}
            team={team}
            sx={{ display: { xs: 'none', sm: 'flex' } }}
            onAbort={onAbort}
            onJoin={onJoin}
          />
        </Grid>
      </Grid>
    </Tooltip>
  );
};
