import { SxProps, Theme } from '@mui/material';
import { Colors } from '../../../theme/colors';

export const styles: { [key: string]: SxProps<Theme> } = {
  cardContainer: {
    position: 'relative',
    borderRadius: '20px',
    backgroundColor: Colors.white,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 276,
    minHeight: 255,
    height: '100%',
    width: '100%',
    boxShadow: 'none',
    overflow: 'visible',
    zIndex: 3,
  },
  cardImage: { flexShrink: 0, height: 140, borderTopLeftRadius: '20px', borderTopRightRadius: '20px' },

  labelStatusPosition: { position: 'absolute', top: '10px', right: '-7px' },

  cardContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    px: '16px',
    pt: '15px',
    pb: '24px!important',
  },
  cardTitle: {
    fontSize: 16,
    lineHeight: 1.4,
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
  },
} as const;
