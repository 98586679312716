import { Box, Grid } from '@mui/material';
import React from 'react';
import { Colors } from '../../../theme/colors';
import ContainerBg from '../../../assets/svg/feature/ranking/container-bg.svg';

type ProgressWrapperProps = {
  xs?: number;
  table?: number;
  md?: number;
  lg?: number;
};

export const ProgressWrapper: React.FC<ProgressWrapperProps> = ({ children, xs = 12, table = 7, md, lg }) => {
  return (
    <Grid item xs={xs} table={table} md={md} lg={lg} sx={{ flexBasis: 800 }}>
      <Box sx={{ paddingTop: '5px', borderRadius: '30px', background: Colors.green[500] }}>
        <Box
          sx={{
            position: 'relative',
            flex: 1,
            background: 'white',
            borderRadius: '30px',
            paddingTop: '20px',
            overflow: 'hidden',
          }}
        >
          <img
            src={ContainerBg}
            style={{ position: 'absolute', top: '-5px', left: '-14px', height: '182px', width: '364px' }}
          />
          {children}
        </Box>
      </Box>
    </Grid>
  );
};

export const LeaderBoardProgressWrapper: React.FC = ({ children }) => {
  return (
    <Grid item xs={12} table={5}>
      <Box
        sx={{
          height: '100%',
          paddingTop: '5px',
          borderRadius: '30px',
          background: Colors.magenta[200],
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            height: '100%',
            background: 'white',
            borderRadius: '30px',
            paddingTop: '19px',
            paddingBottom: '24px',
            overflow: 'hidden',
          }}
        >
          {children}
        </Box>
      </Box>
    </Grid>
  );
};
