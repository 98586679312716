import { Box, Typography } from '@mui/material';
import React from 'react';
import { CustomButton } from '../../../../components/features/CustomButton';
import { DayInRow } from './DayInRow';
import { TargetProgress } from './TargetProgress';
import { Award } from './Award';

type WeeklyChallengeResultSectionProps = {
  isTarget: boolean;
  score: number;
  percent: number;
  awardUrl?: string;
  isAward?: boolean;
  onClickJoin: (e: any) => void;
};

export const WeeklyChallengeResultSection: React.FC<WeeklyChallengeResultSectionProps> = ({
  isTarget,
  score,
  percent,
  awardUrl,
  isAward,
  onClickJoin,
}) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      {isTarget ? (
        <TargetProgress percent={percent} />
      ) : (
        <Box display="flex">
          <DayInRow isFinished days={score} sx={{ marginRight: '16px' }} />
          <Award isCompleted={isAward} awardUrl={awardUrl} />
        </Box>
      )}
      <CustomButton
        sx={{ height: '48px' }}
        text={'Take again'}
        minWidth="160px"
        inputProps={{
          variant: 'contained',
        }}
        onClick={onClickJoin}
      />
    </Box>
  );
};
