import { ChallengeRankingItem } from '../models/Challenge';
import { InstanceItemData } from '../models/Shared';

export const formatLeaderboardList = (list: ChallengeRankingItem[]) =>
  list.map((item) => ({
    place: item.position,
    score: item.score,
    isAwarded: item.isAwarded,
    name: item?.team?.name ?? item?.participant?.name ?? item?.participant?.username ?? '',
    avatar: item?.team?.avatar ?? item?.participant?.avatar ?? null,
    uuid: item?.team?.teamUUID ?? item?.participant?.participantUUID ?? '',
  }));

export const formatInstanceList = (list: InstanceItemData[]) =>
  list.map((item) => ({
    place: item.position,
    score: item.score,
    isAwarded: item.isAwarded,
    startDate: item.startDate,
    endDate: item.endDate,
    scoreTimeline: item.scoreTimeline,
    bestScore: item.bestScore,
    dailyAverage: item.dailyAverage,
  }));
