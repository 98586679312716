import React from 'react';
import { Box, CircularProgress, Container, Grid, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import BurgerButton from './BurgerButton';
import { IonHeader, IonToolbar } from '@ionic/react';
import { Capacitor } from '@capacitor/core';
import { InfoOutlined } from '@mui/icons-material';
import { HeaderPage, useHeaderPageHandle } from '../feature/structure/HeaderPage';
type CustomHeaderProps = {
  label?: string;
  tooltip?: NonNullable<React.ReactNode>;
  breakpoint?: 'xs' | 'sm' | 'md' | 'lg' | number;
};

const CustomHeader: React.FC<CustomHeaderProps> = ({ children, label, tooltip, breakpoint = 'sm' }) => {
  const theme = useTheme();
  const breakpointDown = useMediaQuery(theme.breakpoints.down(breakpoint));
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));
  const loading = useSelector((root: RootState) => root.app.headerLoading);

  const headerProps = useHeaderPageHandle();

  const getHeader = () => {
    return (
      <Container maxWidth={'lg'}>
        <Grid
          container
          wrap={xsDown || breakpointDown ? 'wrap' : 'nowrap'}
          alignItems={'center'}
          sx={{ pt: Capacitor.getPlatform() === 'ios' ? 3 : 2, pb: xsDown || breakpointDown ? 0 : 2 }}
        >
          <Grid
            item
            sx={{ flex: xsDown || breakpointDown ? undefined : 0, whiteSpace: 'nowrap' }}
            container
            wrap={'nowrap'}
            alignItems={'center'}
            xs={xsDown || breakpointDown ? 12 : false}
          >
            <Grid item container wrap={'nowrap'} alignItems={'center'} justifyContent={xsDown ? 'center' : 'start'}>
              <Box position={'relative'} alignItems={'center'} display={'flex'}>
                <Typography
                  sx={{
                    fontSize: { xs: 24, md: 32 },
                    fontWeight: 900,
                    color: 'text.primary',
                  }}
                >
                  {label}
                </Typography>
                {tooltip ? (
                  <Tooltip
                    title={<Typography variant={'body2'}>{tooltip}</Typography>}
                    placement={xsDown || breakpointDown ? 'bottom-end' : 'right-start'}
                    enterTouchDelay={0}
                    leaveTouchDelay={10000}
                    sx={{ mx: 1, fontSize: xsDown || breakpointDown ? 16 : 20, color: theme.palette.grey[600] }}
                  >
                    <InfoOutlined fontSize={'inherit'} color={'inherit'} />
                  </Tooltip>
                ) : null}
                {loading && (
                  <CircularProgress
                    size={xsDown || breakpointDown ? 16 : 24}
                    sx={{
                      position: 'absolute',
                      right: xsDown || breakpointDown ? -16 : -24,
                      top: 4,
                    }}
                  />
                )}
              </Box>
            </Grid>
            {(xsDown || breakpointDown) && <Box style={{ width: '38px', height: '36px', marginLeft: 8 }} />}
          </Grid>
          <Grid
            item
            sx={{ flex: 1, py: xsDown || breakpointDown ? 1 : undefined }}
            xs={xsDown || breakpointDown ? 12 : undefined}
          >
            {children}
          </Grid>
        </Grid>
      </Container>
    );
  };

  return (
    <IonHeader>
      <HeaderPage {...headerProps} />
      <IonToolbar
        style={{
          '--background': '#F0F2F3',
          '--border-width': 0,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            color: 'text.primary',
            background: '#F0F2F3',
            width: '100%',
          }}
          color={'text'}
        >
          {getHeader()}
        </Box>
      </IonToolbar>
    </IonHeader>
  );
};

export default CustomHeader;
