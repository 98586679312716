import { Avatar, Box, Typography } from '@mui/material';
import React from 'react';
import { Colors } from '../../../theme/colors';

type EmployeePointItemProps = {
  position: number;
  name: string;
  avatar: string | null;
  email: string;
  points: number;
};

export const EmployeePointItem: React.FC<EmployeePointItemProps> = ({ position, name, avatar, email, points }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        flexDirection: { xs: 'column', table: 'row' },
        height: 'auto',
        marginLeft: '14px',
        borderRadius: '10px',
        paddingInline: { xs: '16px', md: '24px' },
        paddingTop: '8px',
        paddingBottom: '8px',
        background: `linear-gradient(89.56deg, ${Colors.gray[50]} -1.42%, #F7F9F9 99.73%)`,
        marginBottom: { xs: '8px', md: '16px' },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          top: '50%',
          display: 'flex',
          height: { xs: '24px', md: '30px' },
          width: { xs: '24px', md: '30px' },
          borderRadius: { xs: '24px', md: '30px' },
          border: `2px solid ${Colors.white}`,
          justifyContent: 'center',
          alignItems: 'center',
          background: `linear-gradient(236.78deg, ${Colors.gray[300]} -0.57%, ${Colors.gray[100]} 82.01%)`,
          transform: 'translateX(-50%) translateY(-50%)',
          fontSize: { xs: '10px', md: '12px' },
          color: Colors.white,
          fontWeight: 800,
        }}
      >
        {position}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: { xs: '100%', table: 'unset' },
          mb: { xs: '8px', table: 'unset' },
        }}
      >
        <Avatar
          alt={name}
          sx={{
            height: { xs: 40, md: 48 },
            width: { xs: 40, md: 48 },
            border: `1px solid ${Colors.white}`,
            marginRight: { xs: '8px', md: '16px' },
          }}
          src={avatar ?? undefined}
        />
        <Box>
          <Typography sx={{ fontSize: { xs: '14px', md: '16px' }, fontWeight: 800, color: Colors.gray[700] }}>
            {name}
          </Typography>
          <Typography sx={{ fontSize: { xs: '10px', md: '12px' }, color: Colors.gray[700] }}>{email}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          height: { xs: 18, md: 24 },
          width: '100%',
          maxWidth: { table: '337px' },
          borderRadius: '5px',
          border: `1px solid ${Colors.gray[100]}`,
          background: Colors.gray[100],
          ml: 'auto',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            top: 0,
            bottom: 0,
            left: 0,
            width: '100%',
            borderRadius: '4px',
            background: `linear-gradient(270deg, ${Colors.gray[300]} 0%, ${Colors.gray[100]} 100%)`,
          }}
        >
          <Typography sx={{ position: 'relative', fontSize: '10px', color: Colors.white, lineHeight: 1 }}>
            {Math.floor(points)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
