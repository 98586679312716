export enum CONTENT_TYPE {
  EBOOK = 'EBOOKS',
  BOOKS = 'BOOKS',
  PRODUCTS = 'PRODUCTS',
  PODCAST = 'PODCASTS',
  REPORT = 'REPORTS',
  TOOL = 'TOOLS',
  VIDEO = 'VIDEOS',
  CASE_STUDY = 'CASE_STUDIES',
  ARTICLE = 'ARTICLES',
  JOURNALS = 'JOURNALS',
  BLOG = 'BLOG',
}
