import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getBlogsRequest, getModuleSelectorRequest, postEngagementRequest } from '../../lib/api/http/requests/blogs';
import { Blog } from '../../models/Blog';
import { setHeaderLoading } from './appSlice';
import { handleBackendError } from '../../utils/handleBackendError';

export const getModuleSelector = createAsyncThunk(
  'focusPage/getModuleSelector',
  async (params: { callback?: () => void }, thunkAPI) => {
    try {
      const res = await getModuleSelectorRequest('USER');
      const modules = res.data.selector;
      if (modules && modules.length) {
        thunkAPI.dispatch(setModules(modules || []));
      }
    } catch (error) {
      handleBackendError(error, 'Failed to fetch the selector');
    } finally {
      if (params.callback) params.callback();
    }
  },
);

export const emitEventEngagement = createAsyncThunk(
  'focusPage/emitEventEngagement',
  async (params: { content_type: string; module_uuid: string }) => {
    try {
      await postEngagementRequest(params);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
);

export const getFocusBlogs = createAsyncThunk(
  'focusPage/getFocusBlogs',
  async (params: { pagination: { module?: number; week?: number }; callback?: () => void }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setHeaderLoading(true));
      const res = await getBlogsRequest({ userRole: 'USER', ...params.pagination });
      const blogs: Blog[] = res.data.other;
      const videoData: Array<{ location: string; contentUUID: string }> = res.data.videos;
      const language = res.data.language;
      let url = '';
      let videoUUID: string | undefined;
      if (videoData && videoData.length >= 1) {
        url = videoData[0].location;
        videoUUID = videoData[0]?.contentUUID;
      }
      return { blogs, url, language, videoUUID };
    } catch (error) {
      handleBackendError(error, 'Failed to fetch blogs');
    } finally {
      thunkAPI.dispatch(setHeaderLoading(false));
      if (params.callback) params.callback();
    }
  },
);

export type ModuleType = {
  moduleId: number;
  moduleTitle: string;
  moduleUUID: string;
  periods: Array<{ periodIndex: number; periodTitle: string; current: boolean }>;
};

export interface FocusPageState {
  data: {
    savedVideoTime: number;
    url: string | undefined;
    videoUUID?: string;
    blogs?: Blog[];
    blogsCount: number;
    modules: ModuleType[];
    language: string | null;
  };
  meta: {
    blogsLoading: boolean;
  };
}

const initialState: FocusPageState = {
  data: {
    savedVideoTime: 0,
    url: undefined,
    blogs: undefined,
    blogsCount: 0,
    modules: [],
    language: null,
  },
  meta: {
    blogsLoading: false,
  },
};

export const focusPageSlice = createSlice({
  name: 'focusPage',
  initialState,
  reducers: {
    setModules(state, action) {
      state.data.modules = action.payload;
    },
    setSavedVideoTime(state, action) {
      state.data.savedVideoTime = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFocusBlogs.pending, (state) => {
      state.meta.blogsLoading = true;
    });
    builder.addCase(getFocusBlogs.fulfilled, (state, action) => {
      state.meta.blogsLoading = false;
      state.data.blogs = action.payload?.blogs || [];
      state.data.url = action.payload?.url || '';
      state.data.language = action.payload?.language || null;
      state.data.videoUUID = action.payload?.videoUUID;
    });
    builder.addCase(getFocusBlogs.rejected, (state) => {
      state.meta.blogsLoading = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setModules, setSavedVideoTime } = focusPageSlice.actions;

export default focusPageSlice.reducer;
