import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

export const useHandleRemind = () => {
  const isLoading = useSelector((state: RootState) => state.challengeProgress.meta.isLoading);
  const duration = useSelector((state: RootState) => state.challengeProgress.data.duration);

  return {
    isLoading,
    daysPassed: duration
      ? duration.remainingDays > duration.challengeDays
        ? duration.challengeDays
        : duration.remainingDays
      : 0,
    days: duration?.challengeDays,
  };
};
