import { combineReducers, configureStore } from '@reduxjs/toolkit';
import appReducer from './slices/appSlice';
import focusPageReducer from './slices/focusPageSlice';
import focusAdminPageReducer from './slices/focusAdminSlice';
import toolboxPageReducer from './slices/toolboxPageSlice';
import journeyPageReducer from './slices/journeyPageSlice';
import employeesReducer from './slices/employeesSlice';
import employeesRewardsReducer from './slices/employeesRewardsSlice';
import profileReducer from './slices/profileSlice';
import rewardsReducer from './slices/rewardsSlice';
import employeesStepsReducer from './slices/employeesStepsSlice';
import challengeReducer from './slices/challengeSlice';
import challengeProgress from './slices/challengeProgressSlice';
import challengeAdminReducer from './slices/challengeAdminSlice';
import mentalAssessmentReducer from './slices/mentalAssessmentSlice';

const combinedReducer = combineReducers({
  app: appReducer,
  focusPage: focusPageReducer,
  focusAdminPage: focusAdminPageReducer,
  toolboxPage: toolboxPageReducer,
  journeyPage: journeyPageReducer,
  employees: employeesReducer,
  rewards: rewardsReducer,
  employeesRewards: employeesRewardsReducer,
  employeesSteps: employeesStepsReducer,
  profile: profileReducer,
  challenge: challengeReducer,
  challengeProgress: challengeProgress,
  challengeAdmin: challengeAdminReducer,
  mentalAssessmentSlice: mentalAssessmentReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'STORE_RESET') {
    return combinedReducer(undefined, action);
  }
  return combinedReducer(state, action);
};

export default () => {
  return configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    enhancers: [],
  });
};
