import { useEffect, useState } from 'react';
import { handleBackendError } from '../../../utils/handleBackendError';
import { getLeaderboardPoints, getCompanyConfig } from '../../../lib/api/http/requests/rewards';
import { ConfigCompany, RewardEmployees } from '../../../models/Rewards';
import useIsPageActive from '../../../hooks/useIsPageActive';
import { ANALYTICS_PAGE } from '../../../models/enum/ANALYTICS_PAGE';

const PAGE_SIZE = '15';

export const useHandler = () => {
  const [page, setPage] = useState('0');
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<RewardEmployees[]>();
  const [config, setConfig] = useState<ConfigCompany>();

  const visible = useIsPageActive(ANALYTICS_PAGE.POINTS);

  const handleFetchUser = async ({ page }: { page: string }) => {
    try {
      setIsLoading(true);
      const response = await getLeaderboardPoints({ page, size: PAGE_SIZE });
      if (response.data.content) {
        setHasNextPage(!response.data.last);
        setPage(page);
        if (page === '0') {
          setUsers(response.data.content);
          return;
        }
        setUsers([...(users ?? []), ...response.data.content]);
      }
    } catch (err) {
      console.log(err);
      handleBackendError(err, 'Fetch list error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleFetchNextUsers = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (
      e.currentTarget.scrollTop >= e.currentTarget.scrollHeight - e.currentTarget.offsetHeight &&
      !isLoading &&
      hasNextPage
    ) {
      handleFetchUser({ page: (Number(page) + 1).toString() });
    }
  };

  useEffect(() => {
    if (!visible) return;
    setPage('0');
    const fetchLeaderboardPoints = async () => {
      try {
        const response = await getLeaderboardPoints({ page: '0', size: PAGE_SIZE });
        if (response.data.content) {
          setUsers(response.data.content);
          setHasNextPage(!response.last);
        }
      } catch (err) {}
    };
    const fetchCompanyConfig = async () => {
      try {
        const response = await getCompanyConfig();
        if (response.data) {
          setConfig(response.data);
        }
      } catch (err) {}
    };
    fetchLeaderboardPoints();
    fetchCompanyConfig();
  }, [visible]);
  return { users, config, handleFetchNextUsers };
};
