import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { fetchChallenge } from '../../../redux/slices/challengeSlice';
import { AWARD_LIST } from '../../../models/enum/AWARD_LIST';
import { Challenge } from '../../../models/Challenge';
import { CHALLENGE_STATUS } from '../../../models/enum/CHALLENGE_STATUS';
import { CHALLENGE_SUB_CATEGORY } from '../../../models/enum/CHALLENGE_SUB_CATEGORY';
import moment from 'moment';
import { daysConverter } from '../../../utils/daysConverter';
import { useIonRouter } from '@ionic/react';
import UserService from '../../../services/UserService';
import { getRequest } from '../../../services/HttpService';
import { openToast } from '../../../redux/slices/appSlice';
import { handleBackendError } from '../../../utils/handleBackendError';
import { useExportExcel } from '../../../hooks/useExportExcel';

type Props = {
  id: string;
};
const pageOptionsByType = {
  ONGOING: {
    label: 'Ongoing challenges',
    challengeStatus: CHALLENGE_STATUS.ACTIVE,
  },
  OPEN_TO_JOIN: {
    label: 'Starting soon challenges',
    challengeStatus: CHALLENGE_STATUS.INACTIVE,
  },
  FINISHED: { label: 'Finished challenges', challengeStatus: CHALLENGE_STATUS.COMPLETED },
};
export const useHandle = (params: Props, participantsCallback: () => Promise<void>) => {
  const router = useIonRouter();
  const dispatch = useDispatch();
  const { challenge } = useSelector((state: RootState) => state.challenge.data);
  const { challengeLoading } = useSelector((state: RootState) => state.challenge.meta);
  const [showParticipantsModal, setShowParticipantsModal] = useState(false);
  const [showSeparateParticipantsModal, setShowSeparateParticipantsModal] = useState(false);
  const [showEditAction, setShowEditAction] = useState(false);
  const [isModalTeam, setIsModalTeam] = useState(false);

  const [expanded, setExpanded] = useState(false);

  const { exportToExcel, exportFileName } = useExportExcel();

  const replaceChallengeData = (newChallengeData: Challenge) => {
    history.replaceState(null, '', `/page/your-challenges/challenge/${newChallengeData.metadata.challengeUUID}`);
    loadData(undefined, newChallengeData);
  };
  const loadData = async (status?: CHALLENGE_STATUS, newChallengeData?: Challenge) => {
    if (!params) return;
    const queryParams = new URLSearchParams(window.location.search);
    const isTemplate = newChallengeData
      ? newChallengeData.challengeStatus === CHALLENGE_STATUS.TEMPLATE
        ? String(newChallengeData.challengeStatus === CHALLENGE_STATUS.TEMPLATE)
        : null
      : queryParams.get('isTemplate');
    const id = !newChallengeData
      ? params.id
      : newChallengeData.challengeStatus === CHALLENGE_STATUS.TEMPLATE &&
        newChallengeData.metadata.templateChallengeUUID
      ? newChallengeData.metadata.templateChallengeUUID
      : newChallengeData.metadata.challengeUUID
      ? newChallengeData.metadata.challengeUUID
      : params.id;

    dispatch(fetchChallenge({ id: id, isTemplate }));
    await participantsCallback();
  };

  const handleExportParticipants = async () => {
    try {
      const response: { data?: string } = await getRequest(
        '/gamification-service/admin/challenge/participants/export',
        {
          challengeUUID: params.id,
        },
      );
      if (response.data) {
        await exportToExcel(
          response.data,
          exportFileName('participants-export', challenge?.metadata.challengeUUID, challenge?.challengeInfo.title),
        );
        return;
      }
      dispatch(openToast({ type: 'error', text: 'Export file is empty' }));
    } catch (err) {
      handleBackendError(err, 'Fail to export participants');
    }
  };

  const handleExportTeams = async () => {
    try {
      const response: { data?: string } = await getRequest('/gamification-service/admin/challenge/teams/export', {
        challengeUUID: params.id,
      });
      if (response.data) {
        await exportToExcel(
          response.data,
          exportFileName('teams-export', challenge?.metadata.challengeUUID, challenge?.challengeInfo.title),
        );
        return;
      }
      dispatch(openToast({ type: 'error', text: 'Export file is empty' }));
    } catch (err) {
      handleBackendError(err, 'Fail to export teams');
    }
  };

  useEffect(() => {
    loadData();
  }, [params]);

  useEffect(() => {
    if (!challenge) return;
    const uuid = UserService.getMineId();

    setShowEditAction(
      challenge.metadata.admin === uuid && challenge.challengeSubCategory === CHALLENGE_SUB_CATEGORY.OPEN_TO_JOIN,
    );
  }, [challenge]);

  const challengeTitle = challenge ? challenge.challengeInfo.title : '';
  const formattedStartDate = () => {
    if (!challenge || !challenge.challengeDuration.startDate) return null;
    const parts = challenge.challengeDuration.startDate.split('-');

    return `${parts[2]}/${parts[1]}`;
  };
  const formattedEndDate = () => {
    if (!challenge || !challenge.challengeDuration.endDate) return null;
    const parts = challenge.challengeDuration.endDate.split('-');

    return `${parts[2]}/${parts[1]}`;
  };

  const handleOpenParticipant = (isTeam?: boolean) => () => {
    if (isTeam) {
      setIsModalTeam(true);
    } else {
      setIsModalTeam(false);
    }
    setShowParticipantsModal(true);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const emptyTrophy = AWARD_LIST[AWARD_LIST.length - 1];
  // TODO must be changes
  const progressBreadcrumbItem = () => {
    if (!challenge || challenge.challengeSubCategory === CHALLENGE_SUB_CATEGORY.OPEN_TO_JOIN) return [];
    const uuid =
      challenge.challengeStatus === CHALLENGE_STATUS.TEMPLATE
        ? challenge.metadata.templateChallengeUUID
        : challenge.metadata.challengeUUID;

    return [
      {
        label: challengeTitle,
        url: `/page/employees-challenges/challenge/${challenge.metadata.challengeUUID}/results`,
      },
    ];
  };
  const challengeStatusBreadcrumbItem =
    !challenge || !challenge.challengeSubCategory
      ? []
      : [
          {
            label: pageOptionsByType[challenge.challengeSubCategory].label,
            url: `/page/employees-challenges/${
              challenge.challengeSubCategory === CHALLENGE_SUB_CATEGORY.ONGOING
                ? 'ongoing'
                : challenge.challengeSubCategory === CHALLENGE_SUB_CATEGORY.OPEN_TO_JOIN
                ? 'join'
                : 'finished'
            }`,
          },
        ];

  const start = moment(challenge?.challengeDuration.startDate, 'YYYY-MM-DD');
  const end = moment(challenge?.challengeDuration.endDate, 'YYYY-MM-DD');

  const days = moment.duration(end.diff(start)).asDays().toFixed(0);
  const durationInDays =
    challenge && challenge.challengeDuration.suggestedDuration
      ? challenge.challengeDuration.suggestedDuration
      : daysConverter(Number(days) + 1);

  const navigateToDetailPage = () => {
    if (!challenge) return;
    const uuid =
      challenge.challengeStatus === CHALLENGE_STATUS.TEMPLATE
        ? challenge.metadata.templateChallengeUUID
        : challenge.metadata.challengeUUID;
    const queryParam = challenge.challengeStatus === CHALLENGE_STATUS.TEMPLATE ? '?isTemplate=true' : '';
    router.push(`/page/employees-challenges/edit-challenge/${uuid}${queryParam}`);
  };

  return {
    challenge,
    challengeTitle,
    challengeLoading,
    emptyTrophy,
    expanded,
    showEditAction,
    showParticipantsModal,
    showSeparateParticipantsModal,
    setShowSeparateParticipantsModal,
    progressBreadcrumbItem,
    challengeStatusBreadcrumbItem,
    durationInDays,
    isModalTeam,
    loadData,
    handleOpenParticipant,
    setShowParticipantsModal,
    handleExpandClick,
    formattedStartDate,
    formattedEndDate,
    replaceChallengeData,
    navigateToDetailPage,
    handleExportTeams,
    handleExportParticipants,
  };
};
