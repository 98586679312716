import React from 'react';
import { IonContent, IonPage, useIonRouter } from '@ionic/react';
import { Container, Grid, Skeleton } from '@mui/material';
import { HeaderFilter, useHandleFilter } from '../../components/features/HeaderFilter';
import { ReactComponent as GoalIcon } from '../../assets/svg/goal.svg';
import { useHandleFetch } from './useHandleFetch';
import { EmptyState } from '../../components/features/EmptyState';
import { ChallengeTemplateCard } from '../../components/features/ChallengeTemplateCard/ChallengeTemplateCard';
import { PublishModal } from '../../components/features/modals/PublishModal';
import { useMetricsHandle } from './useMetricsHandle';

const SkeletonList: React.FC = () => (
  <>
    {new Array(12).fill('1').map((_, idx) => (
      <Grid item key={idx.toString()} xs={12} sm={6} md={3}>
        <Skeleton variant="rectangular" style={{ height: 270, width: '100%', borderRadius: '20px' }} />
      </Grid>
    ))}
  </>
);

const TemplatesAdminPage: React.FC = () => {
  const router = useIonRouter();
  const { queryFilter, ...headerFilterProps } = useHandleFilter();
  const { metrics } = useMetricsHandle();

  const { items, loading, isFetching, divRef, selectedChallenge, setSelectedChallenge, handleScrollEvent } =
    useHandleFetch(queryFilter);

  return (
    <IonPage>
      <HeaderFilter
        breadcrumbs={{
          Icon: GoalIcon,
          list: [
            { label: "Your Employees' Challenges", url: '/page/employees-challenges' },
            { label: 'Challenge templates', url: '/' },
          ],
        }}
        tooltip={
          <>
            {'A variety of challenges that Wellics has designed to motivate your people. Select '}
            <b>individual</b> {'challenges for personal motivation or '}
            <b>team</b> {'challenges for team bonding. '}
            <b>Leaderboard</b> {'mode encourages people to reach the top spot, '}
            <b>streak</b> {'or '}
            <b>stick-to-it</b> {'modes build healthy habits and '}
            <b>target</b> {"pushes people's boundaries."}
          </>
        }
        isAdmin={true}
        isTemplates={true}
        loading={loading || isFetching}
        label={'Challenge templates'}
        {...headerFilterProps}
        metrics={metrics}
      />
      <IonContent
        ref={divRef}
        onIonScroll={handleScrollEvent}
        style={{ '--padding-bottom': '36px' }}
        scrollEvents={true}
      >
        <Container maxWidth={'lg'}>
          <Grid container spacing="24px">
            {loading ? (
              <SkeletonList />
            ) : items.length <= 0 ? (
              <EmptyState />
            ) : (
              <>
                {items.map((item, index) => (
                  <Grid item key={index} xs={12} sm={6} md={3}>
                    <ChallengeTemplateCard
                      challenge={item}
                      sx={{ maxWidth: 'unset' }}
                      handlePublish={() => setSelectedChallenge(item)}
                    />
                  </Grid>
                ))}
                {isFetching ? <SkeletonList /> : null}
              </>
            )}
          </Grid>
        </Container>

        <PublishModal
          show={!!selectedChallenge}
          challenge={selectedChallenge}
          loading={false}
          onClose={() => setSelectedChallenge(null)}
          onPublish={() => {
            setSelectedChallenge(null);
            router.push('/page/employees-challenges');
          }}
        />
      </IonContent>
    </IonPage>
  );
};

export default TemplatesAdminPage;
