import { Box, List, ListItem, Slide, Typography, useMediaQuery } from '@mui/material';
import { Colors } from '../../../theme/colors';
import Grid from '@mui/material/Grid';
import { CustomBudge } from '../CustomBudge';
import { NotificationCategory, NotificationItem } from '../NotificationItem';
import React, { MutableRefObject, useEffect } from 'react';
import { EmptyMessageList } from './EmptyMessageList';
import { ReactComponent as SettingsIcon } from '../../../assets/svg/feature/settings.svg';
import { ReactComponent as CloseIcon } from '../../../assets/svg/feature/close.svg';
import theme from '../../../theme';
import { setShowCustomFab } from '../../../redux/slices/appSlice';
import { useIonRouter } from '@ionic/react';
import { useDispatch } from 'react-redux';
import { NotificationContent } from '../../../models/NotificationContent';
import { Capacitor } from '@capacitor/core';

export const ModalContent: React.FC<{
  show: boolean;
  notifications: NotificationContent[];
  handleClose: () => void;
  containerRef?: MutableRefObject<null>;
}> = ({ show, notifications, handleClose, containerRef }) => {
  const router = useIonRouter();
  const dispatch = useDispatch();
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (xsDown && show) {
      dispatch(setShowCustomFab(false));
      return;
    }
    dispatch(setShowCustomFab(true));
  }, [xsDown, show]);

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) {
      return;
    }
    const redirectUrl = process.env.REACT_APP_REDIRECT_URL!;
    const handleLinkClick = (event: any) => {
      event.preventDefault();
      const redirectLink = event.target.href.split(redirectUrl).pop();
      router.push(redirectLink);
      handleClose();
    };

    const links = document.getElementsByTagName('a');
    Array.from(links).forEach((link) => {
      if (link.href.length && redirectUrl.includes(link.hostname)) {
        link.addEventListener('click', handleLinkClick);
      }
    });

    return () => {
      Array.from(links).forEach((link) => {
        if (link.href.length && redirectUrl.includes(link.hostname)) {
          link.addEventListener('click', handleLinkClick);
        }
      });
    };
  }, [xsDown, show]);

  return (
    <Slide
      mountOnEnter
      unmountOnExit
      direction={'up'}
      in={show}
      container={containerRef ? containerRef.current : undefined}
    >
      <Box
        sx={{
          position: { xs: 'fixed', sm: 'absolute' },
          top: { xs: 'auto', sm: '68px' },
          bottom: { xs: 0, sm: 'auto' },
          right: { xs: 0, sm: '24px' },
          background: Colors.white,
          boxShadow: '0px 4px 10px rgba(65, 74, 79, 0.05)',
          borderRadius: '20px',
          borderBottomRightRadius: { xs: 0, sm: '20px' },
          borderBottomLeftRadius: { xs: 0, sm: '20px' },
          width: { xs: '100%', sm: '440px' },
          minHeight: '281px',
          maxHeight: { xs: '100%', sm: '494px' },
          height: { xs: '100%', sm: 'auto' },
          padding: { xs: '26px 16px 32px 16px', sm: '30px 10px 32px 24px' },
          zIndex: 12,
        }}
      >
        <Grid
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: { xs: 'none', sm: '1px dashed #F2F2F2' },
            paddingBottom: { xs: '10px', sm: '16px' },
            mr: { xs: 0, sm: '14px' },
            paddingTop: Capacitor.getPlatform() === 'ios' ? '44px' : 0,
          }}
        >
          <Typography
            sx={{ display: { xs: 'none', sm: 'flex' }, fontWeight: '700', fontSize: '16px', color: Colors.gray[700] }}
          >
            Notifications
          </Typography>
          <Grid sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'row-reverse', sm: 'row' } }}>
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 400,
                color: Colors.blue[500],
                textDecoration: 'underline',
                marginLeft: '24px',
                '&:hover': {
                  textDecoration: 'none',
                  cursor: 'pointer',
                },
              }}
              onClick={() => {
                handleClose();
                router.push('/page/notifications');
              }}
            >
              View all
            </Typography>
            <Grid sx={{ ml: { xs: 0, sm: '16px' } }}>
              <CustomBudge
                key={'Settings'}
                Icon={SettingsIcon}
                boxColor={Colors.gray[150]}
                borderColor={'#E9EDEF'}
                bgColor={Colors.gray[50]}
                textColor={Colors.gray[500]}
                iconColor={Colors.gray[500]}
                label={'Settings'}
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  handleClose();
                  router.push('/page/notifications-settings');
                }}
              />
            </Grid>
          </Grid>

          <Box
            component="button"
            sx={{
              height: 28,
              width: 28,
              padding: 0,
              borderRadius: 38,
              background: '#F0F2F3',
              alignItems: 'center',
              justifyContent: 'center',
              display: { xs: 'flex', sm: 'none' },
            }}
            onClick={handleClose}
          >
            <CloseIcon fill={Colors.gray[400]} />
          </Box>
        </Grid>
        <Typography
          sx={{
            display: { xs: 'block', sm: 'none' },
            fontWeight: '700',
            fontSize: '18px',
            color: Colors.gray[700],
            textAlign: 'center',
          }}
        >
          Notifications
        </Typography>

        {notifications.length ? (
          <List
            sx={{
              margin: '16px 0 0 0',
              padding: { xs: '0 0 0 0', sm: '0 9px 0 0' },
              width: '100%',
              position: 'relative',
              overflow: 'auto',
              maxHeight: { xs: '100%', sm: '376px' },
              height: {
                xs: Capacitor.getPlatform() === 'ios' ? 'calc(100% - 109px)' : 'calc(100% - 65px)',
                sm: 'auto',
              },
              '& ul': { padding: 0 },
              '&.MuiList-root': {
                '&::-webkit-scrollbar-track': { background: '#F0F2F3!important' },
                '&::-webkit-scrollbar-thumb': { background: '#D3D7DA!important' },
                '&::-webkit-scrollbar-track:hover': { background: '#F0F2F3!important' },
                '*::-webkit-scrollbar': {
                  width: '5px!important',
                  height: '5px !important',
                },
              },
            }}
          >
            {notifications.map((item, index) => (
              <ListItem key={`item-${item.id}`} sx={{ padding: !index ? 0 : '8px 0 0 0' }}>
                <NotificationItem notification={item} />
              </ListItem>
            ))}
          </List>
        ) : (
          <Box sx={{ mr: '14px' }}>
            <EmptyMessageList />
          </Box>
        )}
      </Box>
    </Slide>
  );
};
