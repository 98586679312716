import { useEffect, useMemo, useState } from 'react';
import { Range } from 'react-date-range';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import { GraphMetric } from '../../../models/Metrics';
import moment from 'moment';
import { openToast } from '../../../redux/slices/appSlice';
import { updateChallengeMetric } from '../../../redux/slices/challengeProgressSlice';
import FlameIcon from '../../../assets/svg/feature/flame.svg';
import { EditCalendarTimelineItem } from '../types';
import { CHALLENGE_TYPE } from '../../../models/enum/CHALLENGE_TYPE';
import { CALCULATION_TYPE } from '../../../models/enum/CALCULATION_TYPE';
import { filterMetricSampleByRange, filterPointsByRange } from '../../../utils/filterMetricSample';
import { metricsValidation } from '../../../utils/metricsValidation';
import { METRICS_TYPE } from '../../../models/enum/METRICS_TYPE';

export const useHandleActionButtons = () => {
  const [visibleLogData, setVisibleLogData] = useState(false);
  const [visibleStatistic, setVisibleStatistic] = useState(false);
  const [range, setRange] = useState<Range[]>([]);
  const [input, setInput] = useState<string>();
  const [secondInput, setSecondInput] = useState<string>();
  const [date, setDate] = useState<Date>();
  const [listUpdatedDay, setListUpdatedDay] = useState<EditCalendarTimelineItem[]>([]);

  const dispatch: AppDispatch = useDispatch();

  const { isLoading, isUpdating } = useSelector((state: RootState) => state.challengeProgress.meta);
  const { duration, progress, detail, user } = useSelector((state: RootState) => state.challengeProgress.data);

  const openLogData = () => setVisibleLogData(true);
  const openStatistic = () => {
    setVisibleStatistic(true);
  };
  const onCloseLogData = () => {
    setInput('');
    setSecondInput('');
    setDate(undefined);
    setListUpdatedDay(progress?.scoreTimeline ?? []);
    setVisibleLogData(false);
  };
  const onCloseStatistic = () => setVisibleStatistic(false);

  const onChangeDate = (date: Date) => {
    const today = new Date().setHours(0, 0, 0, 0);
    const minDay = new Date(today).setDate(new Date(today).getDate() - 7);
    if (!duration?.startDate && !duration?.endDate) return;
    const startDate = new Date(duration.startDate).setHours(0, 0, 0, 0);
    const endDate = new Date(duration.endDate).setHours(0, 0, 0, 0);
    const selectedDay = new Date(date).setHours(0, 0, 0, 0);
    if (date.getTime() >= startDate && date.getTime() <= endDate) {
      if (today < selectedDay) {
        if (user?.joinTeamDate && today < new Date(user.joinTeamDate).getTime()) {
          dispatch(openToast({ text: 'You cannot log data before the day you join the team', type: 'error' }));
          return;
        }
        dispatch(openToast({ text: 'The day has not yet come', type: 'error' }));
        return;
      }
      if (minDay >= selectedDay) {
        dispatch(openToast({ text: 'You can only log data for the past 7 days', type: 'error' }));
        return;
      }
      setDate(date);
      const findDateData = listUpdatedDay.find((item) => new Date(item.date).setHours(0, 0, 0, 0) === selectedDay);
      if (progress?.metricType === METRICS_TYPE.SLEEP_DURATION) {
        const minutes = (findDateData?.newValue ?? findDateData?.value ?? 0) % 60;
        const hours = ((findDateData?.newValue ?? findDateData?.value ?? 0) - minutes) / 60;
        setInput(hours.toString());
        setSecondInput(minutes.toString());
        return;
      }
      setInput(findDateData?.newValue?.toString() ?? findDateData?.value.toString() ?? '');
    }
  };

  const graph: GraphMetric = useMemo(() => {
    const metrics = [
      {
        group: detail?.groupCategory ?? '',
        metric_pillar: detail?.challengePillars ?? '',
        metric_samples: filterMetricSampleByRange(progress?.scoreTimeline ?? [], range),
        title: progress?.metricTitle ?? '',
        metric_type: progress?.metricType ?? '',
      },
    ];
    if (detail?.isTeam) {
      metrics.push({
        group: detail?.groupCategory ?? '',
        metric_pillar: detail?.challengePillars ?? '',
        metric_samples: filterMetricSampleByRange(
          progress?.teamScoreTimeLine?.map((item) => (item.teamValue ? { ...item, value: item.teamValue } : item)) ??
            [],
          range,
        ),
        title: progress?.metricTitle ?? '',
        metric_type: progress?.metricType ?? '',
      });
    }
    return {
      goals: [],
      metrics,
    };
  }, [detail, progress, range]);

  const handlePlusButton = () => {
    if (!date) {
      dispatch(openToast({ text: 'Please select a day to log your data', type: 'error' }));
      return;
    }
    if (!progress?.metricType) {
      dispatch(openToast({ text: 'Unknown metric type or missing metric type', type: 'error' }));
      return;
    }
    const validation = metricsValidation(progress.metricType, input ?? '', secondInput);
    if (!validation.success) {
      dispatch(openToast({ text: validation.error ?? 'Wrong input value', type: 'error' }));
      return;
    }
    const itemIndex = listUpdatedDay.findIndex(
      (item) => new Date(item.date).setHours(0, 0, 0, 0) === new Date(date).setHours(0, 0, 0, 0),
    );
    if (itemIndex >= 0) {
      if (progress?.metricType === METRICS_TYPE.SLEEP_DURATION) {
        if (!Number(secondInput)) {
          setListUpdatedDay(
            listUpdatedDay.map((item, idx) =>
              idx === itemIndex
                ? {
                    ...item,
                    newValue: Math.round(Number(input) * 60),
                  }
                : item,
            ),
          );
        }
        setListUpdatedDay(
          listUpdatedDay.map((item, idx) =>
            idx === itemIndex
              ? {
                  ...item,
                  newValue: Math.round(Number(input) * 60 + Number(secondInput)),
                }
              : item,
          ),
        );
        return;
      }
      setListUpdatedDay(
        listUpdatedDay.map((item, idx) =>
          idx === itemIndex
            ? {
                ...item,
                newValue: Math.round(Number(input)),
              }
            : item,
        ),
      );
      return;
    }
    setListUpdatedDay([
      ...listUpdatedDay,
      { date: moment(date).format('YYYY-MM-DD'), value: 0, newValue: Math.round(Number(input)) },
    ]);
  };

  const handleAddValue = async () => {
    if (isUpdating) return;
    const newListMetric = listUpdatedDay.filter(
      (item) => item?.newValue && item.newValue >= 0 && item.value !== item.newValue,
    ) as Array<{ date: string; newValue: number }>;
    if (newListMetric.length > 0 && progress?.metricType && detail?.groupCategory) {
      dispatch(
        updateChallengeMetric({
          metricType: progress?.metricType,
          title: progress?.metricTitle,
          group: detail.groupCategory,
          newListMetric,
          callback: onCloseLogData,
        }),
      );
    }
  };

  const getPoints = (): PointAnnotations[] => {
    if (
      (progress?.teamScoreTimeLine && progress.teamScoreTimeLine.length > 0) ||
      (progress?.scoreTimeline && progress.scoreTimeline.length > 0)
    ) {
      const points = filterPointsByRange(
        (detail?.isTeam && detail?.calculationType !== CALCULATION_TYPE.NONE
          ? progress?.teamScoreTimeLine
          : progress.scoreTimeline) ?? [],
        range,
      );
      const maxValue = Math.max(
        ...filterMetricSampleByRange(
          [...(progress?.teamScoreTimeLine ?? []), ...(progress.scoreTimeline ?? [])],
          range,
        ).map((item) => item.teamValue ?? item.value),
      );
      const achievedPoints = points.filter((item) => item.status === 'SUCCESS');

      return filterPointsByRange(achievedPoints, range).map((item) => ({
        x: item.date,
        y: Math.max(Math.floor(Number(((item.teamValue ?? item.value) * 100) / maxValue)), 0),
        marker: {
          size: 12,
          fillColor: '#FAAF1F',
          radius: 12,
          strokeWidth: 0,
        },
        image: {
          path: FlameIcon,
          width: 14,
          height: 14,
        },
      }));
    }
    return [];
  };

  useEffect(() => {
    if (progress?.scoreTimeline) {
      setListUpdatedDay(progress.scoreTimeline);
    }
  }, [progress?.scoreTimeline]);

  useEffect(() => {
    if (duration?.startDate && duration?.endDate) {
      const today = new Date();
      const endDate = new Date(duration.endDate);
      setRange([
        {
          startDate: new Date(duration.startDate),
          endDate: today.setHours(0, 0, 0, 0) > endDate.setHours(0, 0, 0, 0) ? endDate : new Date(),
          key: 'selection',
        },
      ]);
    }
  }, [duration]);

  const getTeamTimelineScore = (): EditCalendarTimelineItem[] => {
    if (detail?.calculationType !== CALCULATION_TYPE.NONE) return progress?.teamScoreTimeLine ?? [];
    if (!detail?.isTeam || !progress?.scoreTimeline || !progress?.teamScoreTimeLine) return [];
    const yourScoreTimeline = new Map<string, { status?: string }>();
    const isTeamGoal = detail?.isTeam && detail?.challengeTyp !== CHALLENGE_TYPE.LEADERBOARD;
    progress.scoreTimeline.forEach((item) => yourScoreTimeline.set(item.date, { status: item.status }));
    const teamTimeline = progress.teamScoreTimeLine.map((item) => ({
      ...item,
      teamValue: undefined,
      // isCompleted: isTeamGoal ? item.status === 'SUCCESS' : yourScoreTimeline.get(item.date)?.status === 'SUCCESS',
      status: yourScoreTimeline.get(item.date)?.status,
    }));
    return teamTimeline;
  };

  return {
    isLoading,
    isAuto: detail?.isAuto ?? false,
    visibleLogData,
    visibleStatistic,
    range,
    input,
    secondInput,
    timelineScore: listUpdatedDay,
    teamTimelineScore: getTeamTimelineScore(),
    isSumNone: detail?.calculationType === CALCULATION_TYPE.NONE,
    graph,
    date,
    startDate: duration?.startDate,
    endDate: duration?.endDate,
    metricTitle: progress?.metricTitle,
    metricType: progress?.metricType,
    bestScore: user?.bestScore,
    dailyAverage: user?.dailyAverage,
    teamBestScore: user?.teamBestScore,
    teamDailyAverage: user?.teamDailyAverage,
    points: getPoints(),
    isGracePeriodEnd: duration?.isGracePeriodEnd,
    isTeam: detail?.isTeam,
    maxCount: detail?.isTeam ? detail?.teamSize : undefined,
    disableLogData: listUpdatedDay.length === 0,
    setRange: (range: Range[]) => setRange(range),
    onChangeInput: setInput,
    onChangeSecondInput: setSecondInput,
    onChangeDate,
    openLogData,
    openStatistic,
    onCloseLogData,
    onCloseStatistic,
    handleAddValue,
    handlePlusButton,
  };
};
