import React from 'react';
import { useTheme } from '@mui/material';

import { IonButton, IonMenuToggle } from '@ionic/react';
import { ReactComponent as MenuIcon } from '../../assets/svg/feature/menu.svg';

type BurgerButton = {
  style?: React.CSSProperties;
  size?: number;
};
const BurgerButton: React.FC<BurgerButton> = ({ style, size = 24 }) => {
  const theme = useTheme();
  return (
    <IonMenuToggle style={style}>
      <IonButton
        style={{
          '--background': 'none',
          '--background-activated': 'none',
          '--background-hover': 'none',
          '--padding-start': 'none',
          '--padding-end': 'none',
          '--padding-top': 'none',
          '--padding-bottom': 'none',
          '--color': theme.palette.primary.main,
          maxHeight: 30,
        }}
      >
        <MenuIcon style={{ height: size, width: size }} />
      </IonButton>
    </IonMenuToggle>
  );
};

export default BurgerButton;
