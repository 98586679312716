import { useState } from 'react';
import { IMetric, IMultipleItem } from '../../../models/Metrics';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { asyncGetWhoFive, updateMultiplyMetrics } from '../../../redux/slices/journeyPageSlice';
import moment from 'moment';

export const useWhoFive = (onClose: () => void, metrics?: IMetric[]) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [slidersValue, setSlidersValue] = useState<{ [key: string]: number }>({});

  const dispatch: AppDispatch = useDispatch();

  const handleSubmitWho = async () => {
    try {
      if (isUpdating || !metrics) return;
      setIsUpdating(true);
      const logMetricAndGoalList: IMultipleItem[] = [];
      metrics.forEach((metric) => {
        logMetricAndGoalList.push({
          metric_type: metric.metric_type,
          title: metric.title,
          group: metric.group,
          metric_samples: [
            {
              date: moment().format('YYYY-MM-DD'),
              metric_value: (slidersValue[metric.metric_type] ?? 1).toString(),
            },
          ],
        });
      });
      await dispatch(updateMultiplyMetrics({ logMetricAndGoalList })).unwrap();
      dispatch(asyncGetWhoFive({ date: moment().format('YYYY-MM-DD') }));
      setSlidersValue({});
      onClose();
    } catch (err) {
      console.log(err);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleChangeSliderValue = (type: string) => (val: number) => {
    setSlidersValue({ ...slidersValue, [type]: val });
  };

  const handleClose = () => !isUpdating && onClose();

  return {
    isUpdating,
    slidersValue,
    setIsUpdating,
    handleSubmitWho,
    handleChangeSliderValue,
    handleClose,
  };
};
