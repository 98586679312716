import * as React from 'react';
import { Box, Card, CardContent, CardHeader, Typography, useMediaQuery, useTheme } from '@mui/material';
import WellbeingPieChart from '../../charts/WellbeingPieChart';
import { CustomButton } from '../CustomButton';
import { ReactComponent as ImproveIcon } from '../../../assets/svg/upwards.svg';
import { useIonRouter } from '@ionic/react';
import { Colors } from '../../../theme/colors';

interface WellbeingCardProps {
  rating: number;
  series: number[];
  colors: string[];
  date: string;
  admin?: boolean;
  loading?: boolean;
}

export const WellbeingCard: React.FC<WellbeingCardProps> = ({
  rating,
  series,
  colors,
  date,
  admin = false,
  loading = false,
}) => {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));
  const router = useIonRouter();
  return (
    <Card
      sx={{
        borderRadius: '20px',
        backgroundColor: 'common.white',
        boxShadow: `0px 4px 24px rgba(138, 138, 138, 0.25)`,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        // p: xsDown ? 2 : undefined,
      }}
      elevation={0}
    >
      <CardHeader
        sx={{ pb: 1 }}
        title={!admin ? `Wellics™ Index` : `Your Organization's Wellics™ Index`}
        titleTypographyProps={{
          variant: 'h5',
          color: 'common.black',
          fontWeight: 'bold',
          textAlign: 'left',
        }}
      />
      <CardContent
        sx={{
          pt: 0,
          '&:last-child': { pb: 1 },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: { xs: 'wrap', table: 'nowrap' },
            alignItems: 'center',
            xs: 'column',
            sm: 'row',
            marginBottom: { xs: '40px', table: 0 },
          }}
        >
          <WellbeingPieChart rating={rating} series={series} colors={colors} loading={loading} />
          <Box pr={1}>
            <Typography sx={{ lineHeight: 1, fontWeight: 'bold' }} variant={'body1'}>
              Wellics™ Index (WIN)
            </Typography>
            <Typography sx={{ fontSize: '12px', mt: 1, lineHeight: 1.35, color: '#5D6C6D' }} variant={'body2'}>
              {!admin ? (
                <span>
                  {`Your WIN is calculated by combining the scores in the four different aspects of your lifestyle. 
                WIN increases within the day as more data comes in.`}
                </span>
              ) : (
                `This is your organization's Wellics™ Index.`
              )}
            </Typography>
            <Typography sx={{ fontSize: '12px', mt: 1, lineHeight: 1.35, color: '#5D6C6D' }} variant={'body2'}>
              {!admin
                ? `Your WIN keeps you aware of your status and helps you identify trends and actions that impact on your well-being.`
                : 'Use this number to explore well-being trends across your staff and what can be learned that is unique to your workplace.'}
            </Typography>
          </Box>
        </Box>
        <CustomButton
          type="smallMagenta"
          text="Improve your WIN"
          minWidth="190px"
          sx={{
            height: { xs: '48px', table: '32px' },
            ml: 'auto',
            mr: 'auto',
            mb: '16px',
            fontSize: { xs: '16px', table: '12px' },
          }}
          inputProps={{
            variant: 'contained',
            startIcon: <ImproveIcon width={16} height={16} fill={Colors.white} />,
          }}
          onClick={() => router.push(`/page/metrics-log-data?category=WIN&focusDate=${date}`)}
        />
      </CardContent>
    </Card>
  );
};
