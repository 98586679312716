import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import CustomNewHeader from '../../../components/features/CustomNewHeader';
import { Container, Grid, useMediaQuery } from '@mui/material';
import CustomLabelValueDropdown from '../../../components/features/CustomLabelValueDropdown';
import theme from '../../../theme';
import { useHandle } from './hooks/useHandle';
import CustomSubHeader from '../../../components/structure/CustomSubHeader';
import SkeletonBlogPostCard from '../../../components/common/SkeletonBlogPostCard';
import { BlogPostCard } from '../../../components/features/BlogPostCard/BlogPostCard';
import { VideoComponent } from '../../../components/features/VideoComponent';
import { useHandlePostCard } from './hooks/useHandlePostCard';

export const FocusAdminPage: React.FC = () => {
  const {
    week,
    url,
    language,
    modules,
    module,
    savedVideoTime,
    disabled,
    list,
    blogs,
    blogsLoading,
    foundModule,
    showVideo,
    onTimeUpdate,
    handleChangeModule,
    handleChangeWeek,
    onPlayVideo,
  } = useHandle();
  const { getPostCardProps } = useHandlePostCard();

  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <IonPage>
      <CustomNewHeader
        label="Your Employees' Focus"
        tooltip="Our educational system comprises of quarterly modules each focusing on one primary behavior or dimension of well-being. Each month you will receive new content about how different well-being aspects affect your workplace and your employees’ health and productivity."
      >
        <Grid container item justifyContent={'flex-end'} wrap={xsDown ? 'nowrap' : undefined}>
          <Grid item sx={{ pr: 1, my: '4px' }} xs={xsDown ? 6 : undefined}>
            <CustomLabelValueDropdown
              disabled={modules.length === 0}
              width={xsDown ? '100%' : 200}
              list={modules?.map((i) => {
                return { label: i.moduleTitle, value: String(i.moduleId) };
              })}
              selected={module}
              setSelected={handleChangeModule}
            />
          </Grid>
          <Grid item sx={{ my: '4px' }} xs={xsDown ? 6 : undefined}>
            <CustomLabelValueDropdown
              disabled={disabled}
              width={xsDown ? '100%' : 350}
              list={list}
              selected={week}
              setSelected={handleChangeWeek}
            />
          </Grid>
        </Grid>
      </CustomNewHeader>
      <IonContent style={{ '--padding-bottom': '36px' }} scrollEvents={true}>
        <Container maxWidth={'lg'}>
          <Grid container item xs={12} mb="50px">
            <Grid
              item
              xs={12}
              md={12}
              container
              justifyContent="center"
              sx={{ maxHeight: '60vh', overflow: 'hidden', borderRadius: '8px' }}
            >
              {showVideo && url && !blogsLoading && (
                <VideoComponent
                  url={url}
                  isLoading={false}
                  lang={language}
                  startTime={savedVideoTime}
                  onTimeUpdate={onTimeUpdate}
                  onPlayVideo={onPlayVideo}
                />
              )}
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item xs={12} sx={{ position: 'relative', zIndex: 10 }}>
              <CustomSubHeader
                label={`Linked Content`}
                tooltip={
                  'Additional resources for this week’s topic. Click on all items on the list to collect all experience points available.'
                }
              />
            </Grid>
            <Grid container item xs={12} spacing={4} mt="-16px">
              {!blogs || blogsLoading
                ? [1, 1, 1, 1].map((i, index) => (
                    <Grid item md={4} table={6} xs={12} key={index}>
                      <SkeletonBlogPostCard />
                    </Grid>
                  ))
                : (blogs ?? []).map((blog, index) => (
                    <Grid item md={4} table={6} xs={12} key={blog.title + index} flex={1} className={'blogGridItem'}>
                      <BlogPostCard {...getPostCardProps(blog)} />
                    </Grid>
                  ))}
            </Grid>
          </Grid>
        </Container>
      </IonContent>
    </IonPage>
  );
};
