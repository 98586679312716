import Keycloak, { KeycloakAdapter, KeycloakLoginOptions } from 'keycloak-js';
import { InAppBrowser, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser';

const keycloakConfig = {
  authServerUrl: process.env.REACT_APP_AUTH_URL,
  realm: 'organisation',
  credentials: null,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  redirectUri: `${process.env.REACT_APP_REDIRECT_URL}/page/your_focus`,
};

function createPromise() {
  // Need to create a native Promise which also preserves the
  // interface of the custom promise type previously used by the API
  const p: any = {
    setSuccess: (result: any) => {
      p.resolve(result);
    },

    setError: (result: any) => {
      p.reject(result);
    },
  };
  p.promise = new Promise((resolve, reject) => {
    p.resolve = resolve;
    p.reject = reject;
  });

  p.promise.success = (callback: any) => {
    p.promise.then(function handleSuccess(value: any) {
      callback(value);
    });

    return;
  };

  p.promise.error = (callback: any) => {
    p.promise.catch(function handleError(error: any) {
      callback(error);
    });

    return;
  };

  return p;
}

function getInappBrowser(url: string) {
  const options: InAppBrowserOptions = {
    zoom: 'no',
    location: 'no',
    fullscreen: 'yes',
    hidenavigationbuttons: 'yes',
    cleardata: 'no',
    clearcache: 'no',
    clearsessioncache: 'no',
    usewkwebview: 'yes',
    closebuttoncaption: 'Cancel',
  };
  return InAppBrowser.create(url, '_blank', options);
}
// add
// kc.parseCallback = parseCallback; on lines 702 in keycloak.mjs
// kc.processCallback = processCallback;
export const adapter = (kc: Keycloak): KeycloakAdapter => {
  return {
    // options are getting overriden by react events
    // we should provide the options correctly here
    login: (options: KeycloakLoginOptions) => {
      const promise = createPromise();
      const url = kc.createLoginUrl(keycloakConfig);
      const browser = getInappBrowser(url);

      const listener = browser.on('loadstart').subscribe((event: any) => {
        const callback = encodeURI(event.url);
        //Check the redirect uri
        if (callback.indexOf(keycloakConfig.redirectUri) > -1) {
          listener.unsubscribe();
          const oauth: any = (kc as any).parseCallback(event.url);
          (kc as any).processCallback(oauth, promise);
          browser.close();
        }
      });
      return promise.promise;
    },

    logout: () => {
      const promise = createPromise();
      const logoutUrl = kc.createLogoutUrl(keycloakConfig);
      const browser = getInappBrowser(logoutUrl);

      const listener = browser.on('loadstart').subscribe((event: any) => {
        const callback = encodeURI(event.url);
        //Check the redirect uri
        if (callback.indexOf(keycloakConfig.redirectUri) > -1) {
          listener.unsubscribe();
          browser.close();
          kc.clearToken();
          promise.setSuccess();
        }
      });
      return promise.promise;
    },

    register: (options) => {
      const promise = createPromise();
      const registerUrl = kc.createRegisterUrl(options);
      const browser = getInappBrowser(registerUrl);
      const listener = browser.on('loadstart').subscribe((event: any) => {
        const callback = encodeURI(event.url);
        //Check the redirect uri
        if (callback.indexOf(keycloakConfig.redirectUri) > -1) {
          listener.unsubscribe();
          browser.close();
          const oauth: any = (kc as any).parseCallback(event.url);
          (kc as any).processCallback(oauth, promise);
        }
      });
      return promise.promise;
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    accountManagement: () => {
      const accountUrl = kc.createAccountUrl();
      if (typeof accountUrl !== 'undefined') {
        getInappBrowser(accountUrl);
      } else {
        throw 'Not supported by the OIDC server';
      }
    },

    redirectUri: (options) => {
      if (options && options.redirectUri) {
        return options.redirectUri;
      } else if (keycloakConfig.redirectUri) {
        return keycloakConfig.redirectUri;
      } else {
        return 'http://localhost';
      }
    },
  };
};
