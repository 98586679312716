import { Box, CircularProgress, Container, Grid, useMediaQuery } from '@mui/material';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import StyledButton from '../common/Button';
import CustomDropdown from '../common/CustomDropdown';
import CustomTextField from '../common/CustomTextField';
import { IAccountInfo } from '../../models/Profile';
import AccountInfoSubSection from './AccountInfoSubSection';
import { ChangePassword, ChangeUsername } from '../modals/ProfileModals';
import CustomLabelValueDropdown from '../common/CustomLabelValueDropdown';
import CustomCalendar from '../common/CustomCalendar';
import 'moment-timezone';
import { TIMEZONES } from '../../models/Timezones';
import { useTheme } from '@mui/material/styles';
import CustomSubHeader from '../structure/CustomSubHeader';
import { Countries } from '../../models/Countries';

interface Props {
  edit: boolean;
  personalInfo: IAccountInfo;
  setPersonalInfo: (personalInfo: IAccountInfo) => void;
  gender: string;
  setGender: (gender: string) => void;
  unit: string;
  setUnit: (unit: string) => void;
  timezone: string;
  setTimezone: (timezone: string) => void;
  dateOfBirth: any;
  setDateOfBirth: (dateOfBirth: any) => void;
  country: string;
  setCountry: (country: string) => void;
}

const genders = [
  { label: 'Male', value: 'MALE' },
  { label: 'Female', value: 'FEMALE' },
  { label: 'Other', value: 'OTHER' },
];

const units = [
  { label: 'Imperial', value: 'IMPERIAL' },
  { label: 'Metric', value: 'METRIC' },
];

const AccountInfo: FC<Props> = ({
  edit,
  personalInfo,
  setPersonalInfo,
  gender,
  setGender,
  timezone,
  setTimezone,
  unit,
  setUnit,
  dateOfBirth,
  setDateOfBirth,
  country,
  setCountry,
}) => {
  const [passwordModal, setPasswordModal] = useState(false);
  const [usernameModal, setUsernameModal] = useState(false);

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('md'));
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));
  const { accountInfoLoading } = useSelector((state: RootState) => state.profile.meta);

  const handlePersonalDetails = (e: React.ChangeEvent<{ name: string; value: any; type: any }>) => {
    if (e.target.type === 'number') {
      setPersonalInfo({
        ...personalInfo,
        personal_details: { ...personalInfo.personal_details, [e.target.name]: parseInt(e.target.value) },
      });
    } else {
      setPersonalInfo({
        ...personalInfo,
        personal_details: { ...personalInfo.personal_details, [e.target.name]: e.target.value },
      });
    }
  };

  const handleAccountDetails = (e: React.ChangeEvent<{ name: string; value: any }>) => {
    setPersonalInfo({
      ...personalInfo,
      account_details: { ...personalInfo.account_details, [e.target.name]: e.target.value },
    });
  };
  return (
    <Container maxWidth="lg">
      <Grid container alignItems="flex-start" spacing={4}>
        <Grid item container spacing={5} xs={12} md={4}>
          <Grid item xs={12} container wrap={'nowrap'}>
            <Grid item>
              <CustomSubHeader
                label={`Personal Details`}
                tooltip={
                  'Add information about yourself and connect your wearable so that your data automatically feed in the application.'
                }
              />
            </Grid>
            {accountInfoLoading && <CircularProgress size={xsDown ? 16 : 24} />}
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              name="first_name"
              value={personalInfo?.personal_details?.first_name ?? ''}
              setValue={handlePersonalDetails}
              label={'First Name'}
              inputProps={{ disabled: !edit }}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              name="last_name"
              value={personalInfo?.personal_details?.last_name ?? ''}
              setValue={handlePersonalDetails}
              label={'Last Name'}
              inputProps={{ disabled: !edit }}
            />
          </Grid>
          <Grid item xs={12} container wrap={'nowrap'} spacing={1}>
            <Grid item sx={{ flex: 1 }}>
              <CustomCalendar
                disabled={!edit}
                anchorLeft
                disableFuture
                date={dateOfBirth}
                setDate={setDateOfBirth}
                label="Date of birth"
              />
            </Grid>
            <Grid item sx={{ maxWidth: '100px' }}>
              <CustomTextField
                name="height"
                value={personalInfo?.personal_details?.height ?? ''}
                setValue={handlePersonalDetails}
                type={'number'}
                label={'Height'}
                inputProps={{ disabled: !edit, type: 'number' }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} mb={'4px'}>
            <CustomDropdown
              placeholder="-"
              width={'100%'}
              disabled={!edit}
              label="Country"
              list={Countries}
              selected={country}
              setSelected={setCountry}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={5} xs={12} md={4}>
          {!smDown && (
            <Grid item xs={12}>
              <Box height="34px" />
            </Grid>
          )}
          <Grid item xs={12}>
            <CustomLabelValueDropdown
              placeholder="-"
              width="100%"
              disabled={!edit}
              label={'Gender'}
              list={genders}
              selected={gender}
              setSelected={setGender}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomDropdown
              label={'Timezone'}
              disabled={!edit}
              width="100%"
              list={TIMEZONES}
              placeholder="-"
              selected={timezone}
              setSelected={setTimezone}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomLabelValueDropdown
              placeholder="-"
              width="100%"
              disabled={!edit}
              label={'Unit'}
              list={units}
              selected={unit}
              setSelected={setUnit}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={5} xs={12} md={4}>
          <Grid item xs={12}>
            <CustomSubHeader label={` Account Details`} />
          </Grid>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} md={12}>
              <CustomTextField
                name="email"
                value={personalInfo?.account_details?.email ?? ''}
                setValue={handleAccountDetails}
                label={'Email Address'}
                inputProps={{ disabled: true }}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={1}>
            <Grid item sx={{ flex: 1 }}>
              <CustomTextField
                name="username"
                value={personalInfo?.account_details?.username ?? ''}
                label={'Username'}
                inputProps={{ disabled: true }}
              />
            </Grid>
            <Grid item sx={{ flex: 0 }}>
              <StyledButton
                onClick={() => setUsernameModal(true)}
                text="Change"
                inputProps={{ color: 'warning', variant: 'contained', disabled: !edit }}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={1}>
            <Grid item sx={{ flex: 1 }}>
              <CustomTextField
                name="password"
                value={'1245678910'}
                label={'Password'}
                type={'password'}
                inputProps={{
                  disabled: true,
                  type: 'password',
                }}
              />
            </Grid>
            <Grid item sx={{ flex: 0 }}>
              <StyledButton
                onClick={() => setPasswordModal(true)}
                text="Change"
                inputProps={{ color: 'warning', variant: 'contained', disabled: !edit }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AccountInfoSubSection personalInfo={personalInfo} setPersonalInfo={setPersonalInfo} />
      <ChangePassword open={passwordModal} setOpen={setPasswordModal} />
      <ChangeUsername open={usernameModal} setOpen={setUsernameModal} />
    </Container>
  );
};

export default AccountInfo;
