import React from 'react';
import { Box } from '@mui/material';

type Props = {
  size: number;
  bgColor: string;
  borderBg: string;
  borderWidth: number;
};
export const CustomAvatar: React.FC<Props> = ({
  children,
  size = 24,
  bgColor = '#ffffff',
  borderBg = '#ffffff',
  borderWidth = 1,
}) => {
  return (
    <Box
      alignItems={'center'}
      justifyContent={'center'}
      display={'flex'}
      sx={{
        width: size,
        height: size,
        backgroundColor: bgColor,
        borderWidth: borderWidth,
        borderColor: borderBg,
        borderRadius: 100,
        borderStyle: 'solid',
      }}
    >
      {children}
    </Box>
  );
};
