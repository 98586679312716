import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchChallengeProgress } from '../../../redux/slices/challengeProgressSlice';
import useIsPageActive from '../../../hooks/useIsPageActive';
import { ANALYTICS_PAGE } from '../../../models/enum/ANALYTICS_PAGE';

export const useInitPage = (uuid: string) => {
  const dispatch = useDispatch();
  const visible = useIsPageActive(ANALYTICS_PAGE.CHALLENGE_ACTIVITY);
  useEffect(() => {
    if (!visible) return;
    dispatch(fetchChallengeProgress({ challengeUUID: uuid }));
  }, [uuid, visible]);
};
