import { AWARD_TYPE } from '../models/enum/AWARD_TYPE';
import { CHALLENGE_TYPE } from '../models/enum/CHALLENGE_TYPE';
import { COMPETITION } from '../models/enum/COMPETITION';
import { DIMENSION } from '../models/enum/DIMENSION';

export const getTooltipTitle = (type: string) => {
  switch (type) {
    case DIMENSION.MENTAL_FITNESS:
      return 'Mental well-being';
    case DIMENSION.NUTRITION:
      return 'Nutrition';
    case DIMENSION.PHYSICAL_ACTIVITY:
      return 'Physical Activity';
    case DIMENSION.SLEEP:
      return 'Sleep';
    case COMPETITION.INDIVIDUAL:
      return 'Individual';
    case COMPETITION.SELF:
      return 'Self';
    case COMPETITION.TEAM:
      return 'Team';
    case AWARD_TYPE.BADGE:
      return 'Badge';
    case AWARD_TYPE.NONE:
      return 'None';
    case AWARD_TYPE.OTHER:
      return 'Other';
    case AWARD_TYPE.POINT:
      return 'Points';
    case CHALLENGE_TYPE.LEADERBOARD:
      return 'Leaderboard';
    case CHALLENGE_TYPE.LONGER_STREAK:
      return 'Longer Streak';
    case CHALLENGE_TYPE.STICK_TO_IT:
      return 'Stick to it';
    case CHALLENGE_TYPE.TARGET:
      return 'Target';
    default:
      return '';
  }
};
