import { useEffect, useState } from 'react';
import { useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import { App, AppState } from '@capacitor/app';
import { trackScreen } from '../utils/analytics';

const useViewIsVisible = (pageName: string) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    const appStateChangeFunction = (state: AppState) => {
      setIsActive(state?.isActive);
    };
    const appStateChangeListener = App.addListener('appStateChange', appStateChangeFunction);
    return () => {
      appStateChangeListener?.remove().catch(console.log);
    };
  }, []);

  useIonViewWillEnter(() => {
    setIsVisible(true);
    trackScreen(pageName);
  });

  useIonViewWillLeave(() => {
    setIsVisible(false);
  });

  return isActive && isVisible;
};

export default useViewIsVisible;
