import React from 'react';
import { Box } from '@mui/material';
import TargetImage from '../../../assets/svg/feature/colored-target.svg';
import { Colors } from '../../../theme/colors';

type ProgressToTargetProps = {
  percent?: number;
};

export const ProgressToTarget: React.FC<ProgressToTargetProps> = ({ percent = 0 }) => {
  const mainPercent = percent > 100 ? 100 : percent;
  return (
    <Box
      sx={{
        position: 'relative',
        height: '20px',
        width: '80px',
        background: '#EDEFF0',
        borderRadius: '6px',
        paddingRight: '15px',
        marginRight: '5px',
      }}
    >
      <Box
        sx={{
          width: `${mainPercent}%`,
          height: '100%',
          borderRadius: '6px',
          border: mainPercent >= 1 ? `1px solid ${Colors.magenta[100]}` : 'unset',
          background: 'linear-gradient(180deg, #E6D0F5 0%, #9D45D9 100%)',
          boxShadow: '0px 2px 5px rgba(181, 115, 226, 0.3)',
        }}
      ></Box>
      <Box
        component="img"
        src={TargetImage}
        sx={{
          position: 'absolute',
          height: '28px',
          width: '28px',
          right: '-5px',
          top: '50%',
          transform: 'translateY(-50%)',
          borderRadius: '26px',
          background: '#EDEFF0',
          padding: '2px',
          ...(percent < 100 && { filter: 'grayscale(100%)' }),
        }}
      />
    </Box>
  );
};
