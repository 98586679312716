import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Employee } from '../../models/Employee';
import {
  bambooIntegrationRequest,
  deleteEmployeeRequest,
  getEmployeesRequest,
  invitationInfo,
  postCSVRequest,
  registrationLink,
  sendInvitationsRequest,
} from '../../lib/api/http/requests/employee';
import { RootState } from '../store';
import { openToast, setHeaderLoading } from './appSlice';
import { handleBackendError } from '../../utils/handleBackendError';

export interface EmployeesState {
  data: {
    employees: Employee[];
    employeesCount: number;
    maxUsers: number | null;
    remainingUsers: number | null;
    subscriptionPlan?: 'ENTERPRISE' | 'PROFESSIONAL' | 'STARTER';
    invitationLink?: string;
  };
  meta: {
    employeesLoading: boolean;
  };
}

const initialState: EmployeesState = {
  data: {
    employees: [],
    employeesCount: 0,
    maxUsers: null,
    remainingUsers: null,
  },
  meta: {
    employeesLoading: false,
  },
};

export const getEmployees = createAsyncThunk(
  'employees/getEmployees',
  async (params: { searchText: string; sort: string; onlyAdmins: boolean }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setHeaderLoading(true));
      const { data } = await getEmployeesRequest({
        page: 0,
        searchText: params.searchText,
        sort: params.sort,
        onlyAdmins: params.onlyAdmins,
      });
      const employeesCount = data.totalElements;
      const employees: Employee[] = data.content;
      return { employees, employeesCount };
    } catch (error) {
      handleBackendError(error, 'Failed to fetch employees');
    } finally {
      thunkAPI.dispatch(setHeaderLoading(false));
    }
  },
);

export const fetchMoreEmployees = createAsyncThunk(
  'employees/fetchMoreEmployees',
  async (
    params: { page: number; searchText: string; sort: string; onlyAdmins: boolean; callback?: () => void },
    thunkApi,
  ) => {
    try {
      const { data } = await getEmployeesRequest({
        page: params.page,
        searchText: params.searchText,
        sort: params.sort,
        onlyAdmins: params.onlyAdmins,
      });
      const employeesCount = data.totalElements;
      let employees: Employee[] = data.content;
      const state: RootState = thunkApi.getState() as RootState;
      employees = [...state.employees.data.employees, ...employees];
      return { employees, employeesCount };
    } catch (error) {
      handleBackendError(error, 'Failed to fetch more employees');
    } finally {
      if (params.callback) params.callback();
    }
  },
);

export const deleteEmployee = createAsyncThunk(
  'employees/deleteEmployee',
  async (params: { body: { uuid: string }; callback?: () => void }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setHeaderLoading(true));
      await deleteEmployeeRequest(params.body.uuid);
      thunkAPI.dispatch(openToast({ text: 'Employee Deleted' }));
    } catch (error) {
      handleBackendError(error, 'Failed to Delete Employee');
    } finally {
      thunkAPI.dispatch(setHeaderLoading(false));
      if (params.callback) params.callback();
    }
  },
);

export const sendInvitations = createAsyncThunk(
  'employees/sendInvitations',
  async (params: { emailList: string[] }) => {
    try {
      await sendInvitationsRequest(params.emailList);
    } catch (error) {
      handleBackendError(error, 'Failed to invite users');
    }
  },
);

export const uploadCSV = createAsyncThunk('profile/asyncUploadCSV', async (file: string, thunkAPI) => {
  try {
    const formData = new FormData();
    formData.append('csvFile', file);
    await postCSVRequest(formData);
    thunkAPI.dispatch(openToast({ text: 'CSV uploaded!', type: 'success' }));
  } catch (error) {
    handleBackendError(error, 'Failed to upload CSV');
  }
});

export const bambooIntegration = createAsyncThunk(
  'employees/bambooIntegration',
  async (params: { clientDomain: string; apiKey: string }, thunkAPI) => {
    try {
      await bambooIntegrationRequest(params.clientDomain, params.apiKey);
      thunkAPI.dispatch(openToast({ text: 'Successful integration with Bamboo HR' }));
    } catch (error) {
      handleBackendError(error, 'Failed integration with Bamboo HR');
    }
  },
);

export const getInvitationInfo = createAsyncThunk('employees/invitationInfo', async () => {
  try {
    const { data } = await invitationInfo();
    const maxUsers: number | null = data?.max_number_of_users;
    const remainingUsers: number | null = data?.remaining_number_of_users;
    const subscriptionPlan: 'ENTERPRISE' | 'PROFESSIONAL' | 'STARTER' = data.subscription_plan;
    return { maxUsers, remainingUsers, subscriptionPlan };
  } catch (error) {
    handleBackendError(error, 'Failed to fetch invitation info');
  }
});

export const getRegistrationLink = createAsyncThunk('employees/registrationLink', async () => {
  try {
    const res = await registrationLink();
    const invitationLink: string = res.data;
    return { invitationLink };
  } catch (error) {
    handleBackendError(error, 'Failed to fetch invitation info');
  }
});

export const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEmployees.fulfilled, (state, action) => {
      state.data.employees = action.payload?.employees || [];
      state.data.employeesCount = action.payload?.employeesCount || 0;
      state.meta.employeesLoading = false;
    });
    builder.addCase(getEmployees.rejected, (state) => {
      state.meta.employeesLoading = false;
    });
    builder.addCase(getEmployees.pending, (state) => {
      state.meta.employeesLoading = true;
    });
    builder.addCase(fetchMoreEmployees.fulfilled, (state, action) => {
      state.data.employees = action.payload?.employees || [];
      state.data.employeesCount = action.payload?.employeesCount || 0;
      state.meta.employeesLoading = false;
    });
    builder.addCase(fetchMoreEmployees.rejected, (state) => {
      state.meta.employeesLoading = false;
    });
    builder.addCase(fetchMoreEmployees.pending, (state) => {
      state.meta.employeesLoading = true;
    });
    builder.addCase(getInvitationInfo.fulfilled, (state, action) => {
      state.data.maxUsers = action.payload?.maxUsers ?? null;
      state.data.remainingUsers = action.payload?.remainingUsers ?? null;
      state.data.subscriptionPlan = action.payload?.subscriptionPlan;
    });
    builder.addCase(getRegistrationLink.fulfilled, (state, action) => {
      state.data.invitationLink = action.payload?.invitationLink;
    });
  },
});

export default employeesSlice.reducer;
