export enum METRICS_TYPE {
  MOOD = 'MOOD',
  MEDITATION_MINUTES = 'MEDITATION_MINUTES',
  MENTAL_ENERGY_AUDIT = 'MENTAL_ENERGY_AUDIT',
  SLEEP_DURATION = 'SLEEP_DURATION',
  // SLEEP_SCORE = 'SLEEP_SCORE',
  SLEEP_QUALITY = 'SLEEP_QUALITY',
  WATER_CONSUMPTION = 'WATER_CONSUMPTION',
  FRUIT_CONSUMPTION = 'FRUIT_CONSUMPTION',
  VEGETABLES_CONSUMPTION = 'VEGETABLES_CONSUMPTION',
  CALORIES_BURNED = 'CALORIES_BURNED',
  CALORIES_INTAKE = 'CALORIES_INTAKE',
  STEPS = 'STEPS',
  EXERCISE_SESSIONS = 'EXERCISE_SESSIONS',
  DISTANCE = 'DISTANCE',
  EXERCISE_MINUTES = 'EXERCISE_MINUTES',
  FREQUENT_MOVEMENT = 'FREQUENT_MOVEMENT',
  WEIGHT = 'WEIGHT',
}
