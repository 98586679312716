import { Box, TextField, TextFieldProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Colors } from '../../theme/colors';

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    mt: '26px',
    borderRadius: '100px',
    color: Colors.gray[400],
    backgroundColor: theme.palette.common.white,
    borderColor: Colors.gray[400],
    '&.Mui-disabled': {
      color: Colors.gray[400],
    },

    '&.Mui-error': {
      backgroundColor: theme.palette.error.light,
    },
    '&.Mui-focused': {
      color: Colors.gray[400],
      borderColor: Colors.gray[400],
    },
    '&:hover:not($disabled):not($focused):not($error)': {
      color: theme.palette.secondary.main,
    },
  },
  '& .MuiFormHelperText-root': {
    display: 'flex',
    justifyContent: 'center',
  },
  '& .MuiInputLabel-root': {
    color: Colors.gray[700],
    fontSize: '14px',
    fontWeight: 'bold',
    position: 'relative',
    top: 'auto',
    left: 'auto',
    transform: 'none',
    marginBottom: '8px',
    marginLeft: '16px',
    '&.Mui-disabled': {
      color: theme.palette.text.primary,
      fontSize: '14px',
    },
    '&.Mui-focused': {
      color: Colors.gray[700],
    },
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: Colors.gray[400],
      borderWidth: '1px',
    },
    '&:hover fieldset': {
      borderColor: Colors.gray[700],
      borderWidth: '1px',
    },
    '&.Mui-focused fieldset': {
      borderColor: Colors.gray[700],
      borderWidth: '1px',
    },
  },
}));

interface Props {
  label: string | React.ReactNode;
  error?: boolean;
  disabled?: boolean;
  inputProps?: TextFieldProps;
  name: string;
  icon?: React.ReactNode;
  value: any;
  bold?: boolean;
  setValue?: (value: any) => void;
  endAdornment?: any;
  type?: string;
  placeholder: string;
}

const InputField: React.FC<Props> = ({
  label,
  error,
  inputProps,
  value,
  setValue,
  name,
  icon,
  endAdornment,
  bold,
  type = 'text',
  placeholder,
  disabled = false,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const getEndAdornment = () => {
    if (showPassword) {
      return <VisibilityIcon style={{ cursor: 'pointer' }} onClick={() => setShowPassword((prev) => !prev)} />;
    } else {
      return <VisibilityOffIcon style={{ cursor: 'pointer' }} onClick={() => setShowPassword((prev) => !prev)} />;
    }
  };

  const getType = () => {
    if (type === 'password') {
      if (showPassword) return 'text';
      else return 'password';
    } else return type;
  };

  return (
    <CustomTextField
      {...inputProps}
      sx={{ input: { color: 'text.primary' }, ...inputProps?.sx }}
      InputProps={{
        notched: false,
        endAdornment: type === 'password' ? getEndAdornment() : endAdornment,
        startAdornment: icon ? (
          <Box sx={{ borderRight: '1px solid #F0F2F3', paddingRight: '10px', height: '16px', marginRight: '10px' }}>
            {icon}
          </Box>
        ) : null,
        style: { fontWeight: bold ? 'bold' : '' },
        ...inputProps?.InputProps,
      }}
      name={name}
      placeholder={placeholder}
      size="small"
      onChange={setValue}
      value={value}
      label={label}
      error={error}
      disabled={disabled}
      InputLabelProps={{ shrink: true, disableAnimation: true }}
      inputProps={{ inputMode: type === 'number' ? 'decimal' : undefined }}
      fullWidth
      variant="outlined"
      color="secondary"
      type={getType()}
    />
  );
};

export default InputField;
