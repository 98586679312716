import React from 'react';
import { Box, Button, Card, CardMedia, Typography } from '@mui/material';
import DefaultImage from '../../../assets/defaultBlog.png';
import Avatar from '../../../assets/svg/avatar.svg';
import { ReactComponent as AvatarIcon } from '../../../assets/svg/avatar.svg';
import { ReactComponent as LeftArrow } from '../../../assets/svg/feature/big-arrow.svg';
import { ReactComponent as ClockIcon } from '../../../assets/svg/clock.svg';
import DOMPurify from 'dompurify';
import { Colors } from '../../../theme/colors';
import { ReactComponent as Divider } from './divider.svg';
import { categoryByType } from './helper';
import moment from 'moment/moment';

type BlogPostCardProps = {
  title: string;
  img?: string;
  date: string;
  content: string;
  category: string;
  hashtags: string[];
  ctaText: string;
  author?: {
    img?: string;
    name?: string;
    occupation?: string;
  };
  featured?: boolean;
  handleOpenLink: () => void;
  handleTagClick?: (val: string) => void;
};

export const BlogPostCard: React.FC<BlogPostCardProps> = ({
  title,
  img,
  date,
  content,
  category,
  hashtags,
  author,
  ctaText,
  featured,
  handleOpenLink,
  handleTagClick,
}) => {
  const Icon = categoryByType[category]?.Icon;
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        height: '100%',
        boxShadow: 'unset',
        borderRadius: '20px',
        cursor: 'pointer',
      }}
      onClick={(e: any) => {
        if (e.target.classList.contains('tag-item') && handleTagClick) {
          e.stopPropagation();
          return;
        }
        handleOpenLink();
      }}
    >
      <CardMedia
        sx={{ height: { xs: '200px', table: '220px', md: '200px' }, cursor: 'pointer' }}
        image={img || DefaultImage}
      />
      {category && categoryByType[category] && (
        <Box
          sx={{
            position: 'absolute',
            top: '16px',
            left: '16px',
            display: 'flex',
            paddingRight: '8px',
            alignItems: 'center',
            borderRadius: '30px',
            background: 'rgba(65, 74, 79, 0.5)',
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              height: '22px',
              width: '22px',
              borderRadius: '50%',
              border: `1px solid ${Colors.gray[400]}`,
              background: Colors.gray[700],
              marginRight: '4px',
            }}
          >
            {Icon && <Icon fill={Colors.white} height={12} width={12} />}
          </Box>
          <Typography
            sx={{
              fontSize: '10px',
              lineHeight: 1.5,
              fontWeight: 300,
              color: Colors.white,
              textAlign: 'center',
            }}
          >
            {categoryByType[category].label}
          </Typography>
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="column"
        flex={1}
        sx={{ paddingInline: '16px', paddingTop: '16px', paddingBottom: '24px' }}
      >
        <Typography
          sx={{ fontSize: '16px', lineHeight: 1.4, fontWeight: 600, color: Colors.gray[700], marginBottom: '8px' }}
        >
          {title}
        </Typography>
        {date && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', mb: '8px', width: 'auto' }}>
            <ClockIcon width={16} height={16} fill={Colors.gray[400]} />
            <Typography color={Colors.gray[400]} sx={{ fontSize: '12px', lineHeight: '12px', marginLeft: '4px' }}>
              {moment(date).format('DD/MM/YY')}
            </Typography>
          </Box>
        )}
        <Box display="flex" flexWrap="wrap" mb="8px">
          {hashtags.length
            ? hashtags.map((tag) => (
                <Box
                  key={tag}
                  sx={{ marginRight: '2px' }}
                  onClick={handleTagClick ? () => handleTagClick(tag) : undefined}
                >
                  <Typography
                    className={'tag-item'}
                    sx={{ fontSize: '12px', lineHeight: 1.33, fontWeight: 300, color: Colors.blue[500] }}
                  >
                    #{tag}
                  </Typography>
                </Box>
              ))
            : null}
        </Box>
        <Box
          sx={{ fontSize: '14px', lineHeight: 1.43, fontWeight: 300, color: Colors.gray[700] }}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}
        />
        <Divider width="100%" style={{ marginTop: '20px', marginBottom: '20px' }} />
        <Box display="flex" alignItems="center" justifyContent="space-between" marginTop="auto">
          <Box display="flex" alignItems="center" flex={1} mr="16px">
            {author && author.img ? (
              <img
                src={author.img}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = Avatar;
                }}
                alt={'Author image'}
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: '999px',
                  objectFit: 'cover',
                  objectPosition: 'center',
                  marginRight: '8px',
                }}
              />
            ) : (
              <AvatarIcon style={{ width: 40, height: 40, marginRight: '8px' }} />
            )}
            <Box>
              <Typography
                sx={{
                  fontSize: 12,
                  fontWeight: '600',
                  lineHeight: 1,
                  color: Colors.gray[600],
                  mb: author?.occupation ? '8px' : 0,
                }}
              >
                {author && author.name ? (
                  <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(author.name) }} />
                ) : (
                  ''
                )}
              </Typography>
              <Typography sx={{ fontSize: 12, lineHeight: 1.33, fontWeight: '300', color: Colors.gray[400] }}>
                {author && author.occupation ? (
                  <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(author.occupation) }} />
                ) : (
                  ''
                )}
              </Typography>
            </Box>
          </Box>
          <Button
            sx={{
              background: Colors.magenta[300],
              paddingTop: '13px',
              paddingBottom: '13px',
              paddingLeft: '26px',
              paddingRight: '18px',
              borderRadius: '100px',
              '&:hover': {
                background: Colors.magenta[400],
              },
              cursor: 'pointer',
            }}
          >
            <Typography
              sx={{ fontSize: '14px', lineHeight: 1, fontWeight: '300', color: Colors.white, marginRight: '4px' }}
            >
              {ctaText}
            </Typography>
            <LeftArrow />
          </Button>
        </Box>
      </Box>
    </Card>
  );
};
