import { getNotifications } from '../../../lib/api/http/requests/notification';
import { useEffect, useRef, useState } from 'react';
import { NotificationContent } from '../../../models/NotificationContent';
import { throttle } from 'lodash';
import useIsPageActive from '../../../hooks/useIsPageActive';
import { ANALYTICS_PAGE } from '../../../models/enum/ANALYTICS_PAGE';
import { Capacitor } from '@capacitor/core';
import { useIonRouter } from '@ionic/react';

const SIZE_PAGE = 8;
export const useNotificationHandle = () => {
  const router = useIonRouter();

  const [notifications, setNotifications] = useState<NotificationContent[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);

  const divRef = useRef<HTMLUListElement>(null);
  const visible = useIsPageActive(ANALYTICS_PAGE.NOTIFICATIONS);

  const handleNext = async () => {
    try {
      if (isFetching || !hasNextPage || loading) return;
      setIsFetching(true);
      const { data } = await getNotifications({
        page: page,
        size: SIZE_PAGE,
      });
      setPage(data.pageable.pageNumber + 1);
      setHasNextPage(!data.last);
      setNotifications([...notifications, ...data.content]);
      setIsFetching(false);
    } catch (err) {
      console.log('error', err);
      setHasNextPage(false);
      setIsFetching(false);
    }
  };

  const handleScrollEvent = throttle(async () => {
    if (divRef.current) {
      const { scrollHeight, scrollTop, offsetHeight } = await divRef.current;
      const currentPosition = scrollTop + offsetHeight;
      const triggerPosition = scrollHeight - 40;
      if (currentPosition >= triggerPosition && (!isFetching || hasNextPage)) {
        handleNext();
      }
    }
  }, 200);

  const scrollToTop = () => {
    divRef.current &&
      divRef.current.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
  };
  const firstFetch = async () => {
    try {
      scrollToTop();
      setLoading(true);
      const { data } = await getNotifications({
        page: 0,
        size: SIZE_PAGE,
      });
      setHasNextPage(!data.last);
      setNotifications(data.content);
      setPage(data.pageable.pageNumber + 1);
      if (divRef.current && !data.last) {
        const { scrollHeight, scrollTop, offsetHeight } = await divRef.current;
        const currentPosition = scrollTop + offsetHeight;
        const triggerPosition = scrollHeight - 40;
        if (currentPosition >= triggerPosition) {
          handleNext();
        }
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    firstFetch();
  }, [visible]);

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) {
      return;
    }
    const redirectUrl = process.env.REACT_APP_REDIRECT_URL!;
    const handleLinkClick = (event: any) => {
      event.preventDefault();
      const redirectLink = event.target.href.split(redirectUrl).pop();
      router.push(redirectLink);
    };

    const links = document.getElementsByTagName('a');
    Array.from(links).forEach((link) => {
      if (link.href.length && redirectUrl.includes(link.hostname)) {
        link.addEventListener('click', handleLinkClick);
      }
    });

    return () => {
      Array.from(links).forEach((link) => {
        if (link.href.length && redirectUrl.includes(link.hostname)) {
          link.addEventListener('click', handleLinkClick);
        }
      });
    };
  }, [visible, notifications.length]);
  return {
    divRef,
    notifications,
    handleScrollEvent,
  };
};
