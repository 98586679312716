import { updateNotificationsSettings } from '../../../lib/api/http/requests/notification';
import {
  ChallengeNotificationSettingsForm,
  GeneralNotificationSettings,
  NotificationChallengeSettings,
  NotificationSettingsForm,
} from '../../../models/NotificationSettings';
import { useState } from 'react';

export const useHandleFetch = (
  generalSettings: NotificationSettingsForm,
  challengesSettings: ChallengeNotificationSettingsForm[] | null,
) => {
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const updateSettings = async () => {
    try {
      setIsSaveLoading(true);
      const generalSettingsRequestData: GeneralNotificationSettings = {
        isGeneralChallengeNotificationEnabled: generalSettings.general,
        isGlobalNotificationEnabled: generalSettings.receive,
        isMailEnabled: generalSettings.email,
        isNotificationEnabled: generalSettings.push,
        isReminderEnabled: generalSettings.reminders,
        isChallengeUpdateEnabled: generalSettings.updates,
      };
      const challengesSettingsList: NotificationChallengeSettings[] = !challengesSettings
        ? []
        : challengesSettings.map((item) => ({
            challengeUUID: item.challengeUUID,
            title: item.title,
            isMailEnabled: item.email,
            isNotificationEnabled: item.push,
            isReminderEnabled: item.reminders,
            isChallengeUpdateEnabled: item.updates,
          }));
      const params = {
        UserChallengeNotificationSettingsDTO: generalSettingsRequestData,
        specificChallengeNotificationInfoList: challengesSettingsList,
      };

      const response = await updateNotificationsSettings(params);
      setIsSaveLoading(false);
    } catch (e) {
      setIsSaveLoading(false);
    }
  };

  return { isSaveLoading, updateSettings };
};
