import { Box, Fab, Skeleton, useMediaQuery } from '@mui/material';
import React from 'react';
import { CustomButton } from '../../../components/features/CustomButton';
import theme from '../../../theme';
import { ReactComponent as LogIcon } from '../../../assets/svg/feature/log.svg';
import { ReactComponent as StatsIcon } from '../../../assets/svg/feature/stats.svg';
import { Colors } from '../../../theme/colors';
import { StatisticModal } from './StatisticModal';
import { LogDataModal } from './LogDataModal';
import { Range } from 'react-date-range';
import { EditCalendarTimelineItem } from '../types';
import { GraphMetric } from '../../../models/Metrics';
import { useHandleActionButtons } from '../hooks/useHandleActionButtons';

type ActionButtonsProps = {
  isLoading: boolean;
  isAuto?: boolean;
  visibleLogData: boolean;
  visibleStatistic: boolean;
  range: Range[];
  metricTitle?: string;
  metricType?: string;
  bestScore?: number | null;
  dailyAverage?: number | null;
  teamBestScore?: number | null;
  teamDailyAverage?: number | null;
  date?: Date;
  timelineScore?: EditCalendarTimelineItem[];
  teamTimelineScore?: EditCalendarTimelineItem[];
  startDate?: string;
  endDate?: string;
  graph?: GraphMetric;
  input?: string;
  secondInput?: string;
  points?: PointAnnotations[];
  isGracePeriodEnd?: boolean;
  isTeam?: boolean;
  isSumNone: boolean;
  variant?: 'lg' | 'md';
  maxCount?: number;
  disableLogData: boolean;
  onChangeInput: (val: string) => void;
  onChangeSecondInput: (val: string) => void;
  onChangeDate: (date: Date) => void;
  handleAddValue: () => void;
  onCloseLogData: () => void;
  onCloseStatistic: () => void;
  setRange: (range: Range[]) => void;
  openLogData?: () => void;
  openStatistic?: () => void;
  handlePlusButton: () => void;
};

export const ActionButtons: React.FC<ActionButtonsProps> = ({
  isLoading,
  isAuto,
  visibleLogData,
  visibleStatistic,
  range,
  metricTitle,
  metricType,
  bestScore,
  dailyAverage,
  teamBestScore,
  teamDailyAverage,
  date,
  timelineScore = [],
  teamTimelineScore = [],
  startDate,
  endDate,
  graph,
  input,
  secondInput,
  disableLogData,
  points,
  isGracePeriodEnd,
  isTeam,
  isSumNone,
  variant = 'md',
  maxCount,
  onChangeInput,
  onChangeSecondInput,
  onChangeDate,
  handleAddValue,
  onCloseLogData,
  onCloseStatistic,
  setRange,
  openLogData = () => null,
  openStatistic = () => null,
  handlePlusButton,
}) => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const tableDown = useMediaQuery(theme.breakpoints.down('table'));
  if (isLoading) {
    return (
      <>
        <Skeleton
          variant="rectangular"
          sx={{ width: '100%', height: 36, marginBottom: '8px', borderRadius: '100px', background: Colors.blue[500] }}
        />
        <Skeleton
          variant="rectangular"
          sx={{ width: '100%', height: 36, borderRadius: '100px', background: Colors.blue[100] }}
        />
      </>
    );
  }

  return (
    <>
      <Box sx={{ marginTop: '16px', ...(variant === 'lg' ? { display: { xs: 'unset', md: 'flex' } } : { flex: 1 }) }}>
        {!isAuto && !isGracePeriodEnd ? (
          (!mdDown && variant === 'md') || (!tableDown && variant === 'lg') ? (
            <CustomButton
              width="100%"
              sx={{
                fontWeight: 700,
                marginBottom: '8px',
                maxWidth: 'unset',
                fontSize: { xs: 14, md: 16 },
                height: { xs: 36, md: 46 },
                ...(variant === 'lg' && { marginRight: { xs: 'unset', md: '8px' } }),
              }}
              type={'bigBlue'}
              startIcon={<LogIcon fill={Colors.white} height="16px" width="16px" />}
              text="Log data"
              onClick={openLogData}
            />
          ) : (
            <Fab
              onClick={openLogData}
              sx={{
                position: 'fixed',
                left: '32px',
                bottom: '28px',
                background: Colors.blue[500],
                boxShadow: '4px 4px 15px rgba(22, 190, 207, 0.35)',
              }}
            >
              <LogIcon fill={Colors.white} height="24px" width="24px" />
            </Fab>
          )
        ) : null}
        <CustomButton
          width="100%"
          sx={{ fontWeight: 700, maxWidth: 'unset', fontSize: { xs: 14, md: 16 }, height: { xs: 36, md: 46 } }}
          startIcon={<StatsIcon fill={Colors.blue[500]} height="16px" width="16px" />}
          type={'bigLightBlue'}
          text="Statistics"
          onClick={openStatistic}
        />
      </Box>
      <StatisticModal
        visible={visibleStatistic}
        onClose={onCloseStatistic}
        range={range}
        setRange={setRange}
        metricTitle={metricTitle}
        graph={graph}
        points={points}
        bestScore={bestScore}
        dailyAverage={dailyAverage}
        teamBestScore={teamBestScore}
        teamDailyAverage={teamDailyAverage}
        isTeam={isTeam}
        startDate={startDate}
        endDate={endDate}
      />
      {(!isAuto || !isGracePeriodEnd) && (
        <LogDataModal
          date={date}
          onChangeDate={onChangeDate}
          visible={visibleLogData}
          input={input}
          secondInput={secondInput}
          onChangeInput={onChangeInput}
          onChangeSecondInput={onChangeSecondInput}
          onClose={onCloseLogData}
          timelineScore={timelineScore}
          teamTimelineScore={teamTimelineScore}
          isTeam={isTeam}
          metricTitle={metricTitle ?? ''}
          handleAddValue={handleAddValue}
          startDate={startDate}
          endDate={endDate}
          handlePlusButton={handlePlusButton}
          isSumNone={isSumNone}
          maxCount={maxCount}
          disableLogData={disableLogData}
          metricType={metricType}
        />
      )}
    </>
  );
};

export const ActionButtonsLayout: React.FC<{ variant?: 'lg' | 'md' }> = ({ variant }) => {
  const props = useHandleActionButtons();
  return <ActionButtons {...props} variant={variant} />;
};
