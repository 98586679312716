import React, { useEffect, useRef, useState } from 'react';
import { RowInputsType, RowUserType } from '../types';
import { Box, Modal, Typography, useMediaQuery } from '@mui/material';
import { Colors } from '../../../theme/colors';
import { CustomButton } from '../../../components/features/CustomButton';
import { ReactComponent as CloseIcon } from '../../../assets/svg/feature/close.svg';
import CustomTextField from '../../../components/common/CustomTextField';
import { Avatar } from '../../../components/features/Avatar';
import { ReactComponent as TrophyIcon } from '../../../assets/svg/feature/trophy.svg';
import { TableRow } from './TableRow';
import { TableCell } from './TableCell';
import theme from '../../../theme';

type RedemptionDetailsModalProps = {
  openModal: boolean;
  selectedRowUser: RowUserType[];
  inputs: RowInputsType[];
  isUpdating: boolean;
  comment: string;
  onChangeComment: (val: string) => void;
  closeRedeemModal: () => void;
  submitOrder: () => void;
};

export const RedemptionDetailsModal: React.FC<RedemptionDetailsModalProps> = ({
  openModal,
  selectedRowUser,
  inputs,
  isUpdating,
  comment,
  closeRedeemModal,
  onChangeComment,
  submitOrder,
}) => {
  const [input, setInput] = useState('');

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const timeoutRef = useRef<any>(null);

  useEffect(() => {
    const timeoutCallback = setTimeout(() => onChangeComment(input), 1000);
    return () => clearTimeout(timeoutCallback);
  }, [input]);

  const onChange = (e: any) => {
    const val = e.target.value;
    setInput(val);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => onChangeComment(val), 700);
  };

  useEffect(() => {
    setInput(comment);
  }, [comment]);
  return (
    <Modal
      open={openModal}
      onClose={closeRedeemModal}
      sx={{ display: 'flex', alignItems: { xs: 'flex-end', table: 'center' }, justifyContent: 'center' }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: { xs: 1, sm: 'unset' },
          background: Colors.white,
          borderRadius: { xs: '20px 20px 0 0', sm: '20px' },
          pt: '50px',
          position: 'relative',
          height: { xs: 'calc(100vh - 64px)', sm: 'unset' },
        }}
      >
        <Box
          component="button"
          sx={{
            position: 'absolute',
            top: { xs: '24px', table: '16px' },
            right: '16px',
            height: 28,
            width: 28,
            padding: 0,
            borderRadius: 38,
            background: '#F0F2F3',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 'auto',
          }}
          onClick={closeRedeemModal}
        >
          <CloseIcon fill={Colors.gray[400]} />
        </Box>
        <Typography
          sx={{
            fontSize: '18px',
            textAlign: 'center',
            fontWeight: '600',
            color: Colors.gray[700],
            mb: { xs: '16px', table: '24px' },
          }}
        >
          Redemption details
        </Typography>
        <Box
          sx={{
            width: { xs: '100%', sm: '874px' },
            mb: smDown ? 0 : '40px',
            flex: 1,
            overflowY: smDown ? 'auto' : 'initial',
          }}
        >
          {smDown ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
              {selectedRowUser.map((user) => {
                const input = inputs.find((item) => item.uuid === user.uuid);
                return (
                  <Box key={user.uuid} sx={{ borderBottom: `1px solid ${Colors.gray[100]}` }}>
                    <Box sx={{ padding: '16px 12px', borderBottom: `1px solid ${Colors.gray[100]}` }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar
                          alt={user.name}
                          src={user.avatar ?? undefined}
                          sx={{
                            mr: '16px',
                            height: '32px',
                            width: '32px',
                          }}
                        />
                        <Typography
                          sx={{
                            flex: 1,
                            fontSize: '14 px',
                            fontWeight: 800,
                            color: Colors.gray[700],
                          }}
                        >
                          {user.name}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '16px 12px',
                      }}
                    >
                      <Typography sx={{ fontSize: '14px', fontWeight: 800, color: Colors.gray[500] }}>
                        Country
                      </Typography>
                      <Typography sx={{ fontSize: '16px', color: Colors.gray[700] }}>{user.country ?? '-'}</Typography>
                    </Box>
                    <Box
                      sx={{
                        padding: '16px 12px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 800,
                          color: Colors.gray[500],
                        }}
                      >
                        Amount
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                          sx={{
                            fontSize: '16px',
                            fontWeight: 800,
                            color: Colors.gray[700],
                          }}
                        >
                          {input?.amount}
                        </Typography>
                        {!user.isRewardLink && (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              height: '28px',
                              width: '28px',
                              borderRadius: '50%',
                              background: Colors.orange[500],
                              border: `1px solid ${Colors.orange[200]}`,
                              ml: '16px',
                            }}
                          >
                            <TrophyIcon height={12} width={12} fill={Colors.white} />
                          </Box>
                        )}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        padding: '16px 12px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 800,
                          color: Colors.gray[500],
                        }}
                      >
                        Points redeemed
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: 800,
                          color: Colors.gray[700],
                        }}
                      >
                        {input?.points}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}

              <Box sx={{ pb: '40px', mt: '48px', paddingInline: '24px' }}>
                <CustomTextField
                  label="Comment"
                  name="comment"
                  inputProps={{
                    rows: 4,
                    sx: {
                      mb: '50px',
                      '& .MuiInputBase-root': {
                        borderRadius: '20px',
                        color: Colors.gray[700],
                        '&.Mui-focused': {
                          color: Colors.gray[700],
                        },
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: Colors.gray[700],
                      },
                    },
                    multiline: true,
                    maxRows: 4,
                  }}
                  value={input}
                  setValue={onChange}
                />
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
                  <CustomButton
                    type="bigOrange"
                    width="150px"
                    sx={{ mr: '16px' }}
                    disabled={isUpdating}
                    text="Proceed"
                    onClick={submitOrder}
                  />
                  <CustomButton
                    type="bigLightOrange"
                    width="150px"
                    text="Cancel"
                    disabled={isUpdating}
                    onClick={closeRedeemModal}
                  />
                </Box>
              </Box>
            </Box>
          ) : (
            <>
              <TableRow
                sx={{
                  borderBottom: `1px solid ${Colors.gray[100]}`,
                }}
              >
                <TableCell>
                  <Typography sx={{ fontSize: '14px', fontWeight: '800', color: Colors.gray[500] }}>Name</Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontSize: '14px', fontWeight: '800', color: Colors.gray[500] }}>Country</Typography>
                </TableCell>
                <TableCell sx={{ flex: '0 0 160px' }}>
                  <Typography sx={{ fontSize: '14px', fontWeight: '800', color: Colors.gray[500] }}>Amount</Typography>
                </TableCell>
                <TableCell sx={{ flex: '0 0 120px' }}>
                  <Typography sx={{ fontSize: '14px', fontWeight: '800', color: Colors.gray[500] }}>
                    Points redeemed
                  </Typography>
                </TableCell>
              </TableRow>
              <Box
                sx={{
                  borderBottom: `1px solid ${Colors.gray[100]}`,
                  flex: 1,
                  overflow: 'scroll',
                  maxHeight: '310px',
                }}
              >
                {selectedRowUser.map((user) => {
                  const input = inputs.find((item) => item.uuid === user.uuid);
                  return (
                    <TableRow key={user.uuid}>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Avatar alt={user.name} src={user.avatar ?? undefined} sx={{ mr: '16px' }} />
                          <Typography
                            sx={{
                              flex: 1,
                              fontSize: '16px',
                              fontWeight: 800,
                              color: Colors.gray[700],
                            }}
                          >
                            {user.name}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Typography sx={{ fontSize: '16px', color: Colors.gray[700] }}>
                            {user.country ?? '-'}
                          </Typography>
                        </Box>
                      </TableCell>

                      <TableCell sx={{ flex: '0 0 160px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography sx={{ flex: 1, fontSize: '14px', fontWeight: '800', color: Colors.gray[700] }}>
                            {input?.amount ?? ''}
                          </Typography>
                          {!user.isRewardLink && (
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '28px',
                                width: '28px',
                                borderRadius: '50%',
                                background: Colors.orange[500],
                                border: `1px solid ${Colors.orange[200]}`,
                                ml: '8px',
                              }}
                            >
                              <TrophyIcon height={12} width={12} fill={Colors.white} />
                            </Box>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell sx={{ flex: '0 0 120px' }}>
                        <Typography sx={{ fontSize: '14px', fontWeight: '800', color: Colors.gray[700] }}>
                          {input?.points ?? ''}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </Box>
            </>
          )}
        </Box>
        {!smDown && (
          <Box sx={{ mb: '40px', mt: '24px', paddingInline: '24px' }}>
            <CustomTextField
              label="Comment"
              name="comment"
              inputProps={{
                rows: 4,
                sx: {
                  mb: '50px',
                  '& .MuiInputBase-root': {
                    borderRadius: '20px',
                    color: Colors.gray[700],
                    '&.Mui-focused': {
                      color: Colors.gray[700],
                    },
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: Colors.gray[700],
                  },
                },
                multiline: true,
                maxRows: 4,
              }}
              value={input}
              setValue={onChange}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
              <CustomButton
                type="bigOrange"
                width="150px"
                sx={{ mr: '16px' }}
                disabled={isUpdating}
                text="Proceed"
                onClick={submitOrder}
              />
              <CustomButton
                type="bigLightOrange"
                width="150px"
                text="Cancel"
                disabled={isUpdating}
                onClick={closeRedeemModal}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};
