import { useEffect, useState } from 'react';
import { fetchChallenge, getAbortChallenge } from '../../../redux/slices/challengeSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import { Challenge } from '../../../models/Challenge';
import { CHALLENGE_STATUS } from '../../../models/enum/CHALLENGE_STATUS';
import { useIonRouter } from '@ionic/react';

type Props = {
  id: string;
};
export const useAbortHandle = (params: Props, challenge: Challenge | null, callback?: () => Promise<void>) => {
  const dispatch: AppDispatch = useDispatch();
  const { abortChallengeLoading } = useSelector((state: RootState) => state.challenge.meta);
  const [showAbort, setShowAbort] = useState(false);

  const router = useIonRouter();

  useEffect(() => {
    if (abortChallengeLoading) return;

    setShowAbort(false);
  }, [abortChallengeLoading]);
  const abortChallengeRequest = async () => {
    if (!params.id) return;
    const queryParams = new URLSearchParams(window.location.search);
    const isTemplate = queryParams.get('isTemplate');

    const requestParams = challenge
      ? { templateChallengeUUID: challenge.metadata.challengeUUID, challengeUUID: challenge.metadata.challengeUUID }
      : { templateChallengeUUID: params.id, challengeUUID: params.id };

    const dispatchData = await dispatch(getAbortChallenge(requestParams)).unwrap();

    const id: string = dispatchData
      ? (dispatchData.data.challengeStatus === CHALLENGE_STATUS.TEMPLATE
          ? dispatchData.data.metadata.templateChallengeUUID
          : dispatchData.data.metadata?.challengeUUID) ?? params.id
      : params.id;

    const isTemplateParam = dispatchData
      ? dispatchData.data.challengeStatus === CHALLENGE_STATUS.TEMPLATE
        ? String(dispatchData.data.challengeStatus === CHALLENGE_STATUS.TEMPLATE)
        : null
      : isTemplate;

    await dispatch(fetchChallenge({ id: id, isTemplate: isTemplateParam })).unwrap();

    const uuid =
      dispatchData?.data.challengeStatus === CHALLENGE_STATUS.TEMPLATE
        ? dispatchData.data.metadata.templateChallengeUUID
        : dispatchData?.data.metadata.challengeUUID;
    const queryParam = dispatchData?.data.challengeStatus === CHALLENGE_STATUS.TEMPLATE ? '?isTemplate=true' : '';

    const isLastPathProgress = router.routeInfo.lastPathname?.includes(`${params.id}/activity`);

    if (isLastPathProgress) {
      router.push(`/page/your-challenges/challenge/${uuid}${queryParam}`, 'root', 'replace');
    } else {
      // history.replaceState(null, '', `/page/your-challenges/challenge/${uuid}${queryParam}`);
      router.push(`/page/your-challenges/challenge/${uuid}${queryParam}`, 'root', 'replace');
    }

    if (callback) {
      // setTimeout(callback, 2000);
      // callback();
    }
  };
  return {
    abortChallengeLoading,
    showAbort,
    abortChallengeRequest,
    setShowAbort,
  };
};
