import * as React from 'react';
import {
  Box,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { EmployeeSteps } from '../../models/EmployeeSteps';

interface EmployeesStepsTableProps {
  employeesSteps?: EmployeeSteps[];
  loading?: boolean;
  page?: number;
}

const EmployeesStepsTable: React.FC<EmployeesStepsTableProps> = ({ employeesSteps, loading, page = 0 }) => {
  const theme = useTheme();

  const username = (firstName = '', lastName = '') => {
    return firstName.concat(' ', lastName);
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="steps table">
        <TableHead sx={{ backgroundColor: theme.palette.secondary.main }}>
          <TableRow>
            <TableCell align="center" sx={{ padding: 1, fontWeight: 700 }}>
              #
            </TableCell>
            <TableCell align="center" sx={{ padding: 1, fontWeight: 700, width: '35%' }}>
              User
            </TableCell>
            <TableCell align="center" sx={{ padding: 1, fontWeight: 700, width: '70%' }}>
              Email
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && page === 0
            ? [1, 2, 3].map((row, index) => (
                <TableRow key={'row-' + (index + 1)} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="center" component="th" scope="row" padding={'none'}>
                    {rankIcon(index + 1)}
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                </TableRow>
              ))
            : employeesSteps?.map((row, index) => (
                <TableRow key={'row-' + (index + 1)} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="center" component="th" scope="row" padding={'none'}>
                    {rankIcon(index + 1)}
                  </TableCell>
                  <TableCell align="center">{username(row.first_name, row.last_name)}</TableCell>
                  <TableCell align="center">{row.email}</TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const rankIcon = (rank: number) => {
  const rankColor = () => {
    switch (rank) {
      case 1:
        return '#FAAF1F';
      case 2:
        return '#D6D6D6';
      case 3:
        return '#97542F';
      default:
        return '#347374';
    }
  };

  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} padding={1}>
      <Box
        sx={{
          backgroundColor: rankColor(),
          width: 32,
          height: 32,
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        key={'rank-' + rank}
        fontSize={'inherit'}
      >
        <Typography fontSize={'inherit'} sx={{ fontWeight: 700, color: 'white' }}>
          {rank}
        </Typography>
      </Box>
    </Box>
  );
};

export default EmployeesStepsTable;
