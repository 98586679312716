import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { handleBackendError } from '../../utils/handleBackendError';
import {
  getChallengeLeaderBoard,
  getChallengeProgress,
  getChallengeWinWeekly,
  setChallengeHabit,
} from '../../lib/api/http/requests/challenge';
import {
  ChallengeProgress,
  ChallengeProgressRanking,
  ChallengeRankingItem,
  ChallengeWeekly,
} from '../../models/Challenge';
import { CHALLENGE_STATUS } from '../../models/enum/CHALLENGE_STATUS';
import { AWARD_TYPE } from '../../models/enum/AWARD_TYPE';
import { CHALLENGE_TYPE } from '../../models/enum/CHALLENGE_TYPE';
import { CALCULATION_TYPE } from '../../models/enum/CALCULATION_TYPE';
import { AWARD_TRIGGER } from '../../models/enum/AWARD_TRIGGER';
import moment from 'moment';
import { CHALLENGE_SUB_CATEGORY } from '../../models/enum/CHALLENGE_SUB_CATEGORY';
import { RootState } from '../store';
import { submitMetric } from '../../lib/api/http/requests/journey';
import { InstanceItemData, ScoreTimeItemType } from '../../models/Shared';
import { COMPETITION } from '../../models/enum/COMPETITION';
import { formatInstanceList, formatLeaderboardList } from '../../utils/formatData';
import { setHeaderLoading } from './appSlice';

type ReqChallengeWeeklyParams = {
  moduleOrder: number;
  periodIndex: number;
};

type ReqProgressPrams = {
  challengeUUID: string;
};

type ReqUpdateHabit = {
  date: string;
  challengeUUID: string;
  habitValue: boolean;
};

type ReqUpdateMetric = {
  blockRefetch?: boolean;
  callback?: () => void;
  metricType: string | null;
  group: string;
  title: string;
  newListMetric: {
    date: string;
    newValue: number;
  }[];
};

export interface ChallengeProgressState {
  data: {
    challengeWeekly?: ChallengeWeekly;
    detail: {
      description: string;
      awardTrigger: AWARD_TRIGGER;
      challengeTyp: CHALLENGE_TYPE;
      calculationType: CALCULATION_TYPE;
      competition: COMPETITION;
      participantsNumber: number;
      groupCategory: string;
      leaderBoardSync: string;
      isAuto: boolean;
      isTeam: boolean;
      isBoolean: boolean;
      challengePillars: string;
      teamSize?: number;
    } | null;
    user: {
      name: string;
      position: number;
      avatar: string | null;
      userUUID: string;
      bestScore: number | null;
      dailyAverage: number | null;
      target: number | null;
      teamBestScore?: number;
      teamDailyAverage?: number;
      joinTeamDate?: string;
    } | null;
    duration: {
      startDate: string;
      endDate: string;
      challengeDays: number;
      remainingDays: number;
      isGracePeriodEnd: boolean;
    } | null;
    progress: {
      lastSync: string;
      score: number;
      scoreToTop: number;
      scoreToPrevious: number;
      metricTitle: string;
      metricType: string;
      metricActions?: string[];
      goalValue: number;
      daysGoalValue?: number;
      scoreTimeline: ScoreTimeItemType[];
      teamScoreTimeLine?: ScoreTimeItemType[];
      teamScore?: number;
      completionPercentage: number;
    } | null;
    award: {
      type: AWARD_TYPE | null;
      points: number | null;
      getAward: boolean;
      triggerLimit: number | null;
      awardIcon?: string | null;
    } | null;
    navigation: {
      challengeStatus: CHALLENGE_STATUS;
      uuid: string;
      templateUUID: string;
      previousChallengeUUID: string | null;
      nextChallengeUUID: string | null;
      subcategory: CHALLENGE_SUB_CATEGORY;
      title: string;
      slackUrl?: string | null;
    } | null;
    previousUser: {
      avatar: string | null;
      name: string;
      position: number;
      uuid: string;
      score: number;
      isAward: boolean | null;
    } | null;
    leaderBoard: {
      isLastPage: boolean;
      list: Array<{
        place: number;
        isAwarded: boolean;
        score: number;
        avatar: string | null;
        name: string;
        uuid: string;
      }>;
      instanceList: Array<{
        place: number;
        isAwarded: boolean;
        score: number;
        scoreTimeline: ScoreTimeItemType[];
        startDate: string;
        endDate: string;
        bestScore?: number;
        dailyAverage?: number;
      }>;
      page: number;
    } | null;
  };
  meta: {
    isLoading: boolean;
    isUpdating: boolean;
    isFetching: boolean;
    weeklyMeta?: ReqChallengeWeeklyParams & {
      uuid: string;
    };
  };
}

export const fetchWeeklyChallenge = createAsyncThunk(
  'challengeProgress/fetchWeeklyChallenge',
  async ({ moduleOrder, periodIndex }: ReqChallengeWeeklyParams, thunkAPI) => {
    try {
      thunkAPI.dispatch(setHeaderLoading(true));
      thunkAPI.dispatch(challengeProgressSlice.actions.setIsLoading(true));
      const { data }: { data: ChallengeWeekly } = await getChallengeWinWeekly({ moduleOrder, periodIndex });
      return { data, params: { moduleOrder, periodIndex } as ReqChallengeWeeklyParams };
    } catch (err) {
      handleBackendError(err, 'Failed to fetch win weekly challenges');
    } finally {
      thunkAPI.dispatch(setHeaderLoading(false));
      thunkAPI.dispatch(challengeProgressSlice.actions.setIsLoading(false));
    }
  },
);

export const fetchChallengeProgress = createAsyncThunk(
  'challengeProgress/fetchChallengeProgress',
  async (params: ReqProgressPrams, thunkAPI) => {
    try {
      thunkAPI.dispatch(setHeaderLoading(true));
      thunkAPI.dispatch(challengeProgressSlice.actions.setIsLoading(true));
      const { data: challenge }: { data: ChallengeProgress } = await getChallengeProgress(params);
      const { data: list }: { data: ChallengeProgressRanking } = await getChallengeLeaderBoard({
        ...params,
        page: '0',
        size: '12',
      });
      return { challenge, list };
    } catch (err) {
      handleBackendError(err, 'Failed to fetch finished challenges');
    } finally {
      thunkAPI.dispatch(challengeProgressSlice.actions.setIsLoading(false));
      thunkAPI.dispatch(setHeaderLoading(false));
    }
  },
);

export const updateHabit = createAsyncThunk(
  'challengeProgress/updateHabit',
  async (params: ReqUpdateHabit, thunkAPI) => {
    try {
      thunkAPI.dispatch(challengeProgressSlice.actions.setIsUpdating(true));
      await setChallengeHabit(params);
      thunkAPI.dispatch(refetchProgressChallenge({ challengeUUID: params.challengeUUID }));
    } catch (err) {
      handleBackendError(err, 'Failed to update challenge habit');
    } finally {
      thunkAPI.dispatch(challengeProgressSlice.actions.setIsUpdating(false));
    }
  },
);
export const updateChallengeMetric = createAsyncThunk(
  'challengeProgress/updateChallengeMetric',
  async ({ metricType, title, newListMetric, blockRefetch, callback }: ReqUpdateMetric, thunkAPI) => {
    try {
      thunkAPI.dispatch(challengeProgressSlice.actions.setIsUpdating(true));
      await Promise.all(
        newListMetric.map((item) =>
          submitMetric({
            date: moment(item.date).format('YYYY-MM-DD'),
            metric_type: metricType,
            group: 'CORPORATE',
            title,
            metric_value: item.newValue,
          }),
        ),
      );
      if (blockRefetch) return;
      const uuid = (thunkAPI.getState() as RootState).challengeProgress.data.navigation?.uuid;
      if (uuid) {
        await new Promise((resolve) => setTimeout(() => resolve(true), 400));
        thunkAPI.dispatch(refetchProgressChallenge({ challengeUUID: uuid }));
      }
      if (callback) {
        callback();
        thunkAPI.dispatch(challengeProgressSlice.actions.setIsUpdating(false));
      }
    } catch (err) {
      handleBackendError(err, 'Failed metric update');
      thunkAPI.dispatch(challengeProgressSlice.actions.setIsUpdating(false));
    }
  },
);

export const refetchProgressChallenge = createAsyncThunk(
  'challengeProgress/refetchProgressChallenge',
  async (params: ReqProgressPrams, thunkAPI) => {
    try {
      thunkAPI.dispatch(challengeProgressSlice.actions.setIsUpdating(true));
      const { data: challenge }: { data: ChallengeProgress } = await getChallengeProgress(params);
      const { data: list }: { data: ChallengeProgressRanking } = await getChallengeLeaderBoard({
        ...params,
        page: '0',
        size: '12',
      });
      const weeklyMeta = (thunkAPI.getState() as RootState).challengeProgress.meta.weeklyMeta;
      if (weeklyMeta && weeklyMeta.uuid === challenge.metadata.challengeUUID) {
        await thunkAPI.dispatch(
          fetchWeeklyChallenge({ moduleOrder: weeklyMeta.moduleOrder, periodIndex: weeklyMeta.periodIndex }),
        );
      }
      return { challenge, list };
    } catch (err) {
      handleBackendError(err, 'Failed to refetch finished challenges');
    } finally {
      thunkAPI.dispatch(challengeProgressSlice.actions.setIsUpdating(false));
    }
  },
);

export const fetchNextPageLeaderBoard = createAsyncThunk(
  'challengeProgress/fetchNextPageLeaderBoard',
  async (params: ReqProgressPrams & { page: string }) => {
    try {
      const { data: list }: { data: ChallengeProgressRanking } = await getChallengeLeaderBoard({
        ...params,
        size: '12',
      });
      return list;
    } catch (err) {
      handleBackendError(err, 'Failed to fetch next leaderboard users');
    }
  },
);

const initialState: ChallengeProgressState = {
  data: {
    detail: null,
    user: null,
    duration: null,
    progress: null,
    award: null,
    navigation: null,
    previousUser: null,
    leaderBoard: null,
  },
  meta: {
    isLoading: false,
    isUpdating: false,
    isFetching: false,
  },
};

export const challengeProgressSlice = createSlice({
  name: 'challengeProgress',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.meta.isLoading = action.payload;
    },
    setIsUpdating: (state, action: PayloadAction<boolean>) => {
      state.meta.isUpdating = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWeeklyChallenge.fulfilled, (state, action) => {
      if (action.payload?.data) {
        state.data.challengeWeekly = action.payload?.data;
      } else {
        state.data.challengeWeekly = undefined;
      }

      if (action?.payload?.data?.metadata?.challengeUUID && action.payload?.params) {
        state.meta.weeklyMeta = { uuid: action.payload.data.metadata.challengeUUID, ...action.payload.params };
      } else {
        state.meta.weeklyMeta = undefined;
      }
    });
    builder.addCase(fetchChallengeProgress.fulfilled, (state, action) => {
      const challenge = action.payload?.challenge;
      const leaderList = action.payload?.list;
      if (challenge && leaderList) {
        state.data.award = {
          type: challenge.challengeAward?.awards?.[0]?.awardType ?? null,
          points: challenge.challengeAward?.awards?.[0]?.points ?? null,
          getAward: challenge.userRanking?.award ? true : false,
          triggerLimit: challenge.challengeAward?.awardTriggerLimit ?? null,
          awardIcon: challenge?.challengeAward?.awards?.[0]?.badgeImageUrl,
        };
        state.data.detail = {
          description: challenge.challengeInfo.shortDescription ?? '',
          awardTrigger: challenge.challengeAward?.awardTrigger,
          challengeTyp: challenge.challengeCharacteristic.challengeType,
          calculationType: challenge.challengeCharacteristic.calculationType,
          participantsNumber:
            challenge.challengeParticipation?.numberOfTeams ?? challenge.challengeParticipation.numberOfParticipants,
          leaderBoardSync: moment(challenge.leaderboardLastSync ?? challenge.challengeDuration.startDate).format(
            'DD/MM/YY',
          ),
          isAuto: challenge.challengeAction.actionSource === 'AUTO',
          isBoolean: challenge.challengeAction.actionType === 'HABIT',
          groupCategory: challenge.challengeCharacteristic.groupCategory,
          challengePillars: challenge.challengeCharacteristic.challengePillars[0],
          isTeam: challenge.challengeCharacteristic.competition === COMPETITION.TEAM,
          teamSize: challenge?.teamRanking?.team?.teamSize,
          competition: challenge.challengeCharacteristic.competition,
        };
        state.data.duration = {
          startDate: challenge.challengeDuration.startDate,
          endDate: challenge.challengeDuration.endDate,
          remainingDays: challenge.challengeDuration.currentDayInChallenge,
          challengeDays: challenge.challengeDuration.durationInDays,
          isGracePeriodEnd: challenge.challengeDuration.gracePeriodEnd
            ? new Date().setHours(0, 0, 0, 0) >
              new Date(challenge.challengeDuration.gracePeriodEnd).setHours(0, 0, 0, 0)
            : false,
        };
        const content: unknown = leaderList.content;
        state.data.leaderBoard = {
          isLastPage: leaderList.last,
          list:
            challenge.challengeCharacteristic.competition !== COMPETITION.SELF
              ? formatLeaderboardList(content as ChallengeRankingItem[])
              : [],
          instanceList:
            challenge.challengeCharacteristic.competition === COMPETITION.SELF
              ? formatInstanceList(content as InstanceItemData[])
              : [],
          page: leaderList.pageable.pageNumber,
        };
        state.data.navigation = {
          challengeStatus: challenge.challengeStatus,
          uuid: challenge.metadata.challengeUUID,
          templateUUID: challenge.metadata.templateChallengeUUID,
          previousChallengeUUID: challenge.previousChallengeUUID,
          nextChallengeUUID: challenge.nextChallengeUUID,
          subcategory: challenge.challengeSubCategory,
          title: challenge.challengeInfo.title,
          slackUrl: challenge.metadata.slackURL,
        };
        state.data.previousUser = challenge.previousTeamRanking
          ? {
              avatar: challenge.previousTeamRanking.team.imageURL,
              name: challenge.previousTeamRanking.team.name,
              score: challenge.previousTeamRanking.score,
              uuid: challenge.previousTeamRanking.team.teamUUID,
              position: challenge.previousTeamRanking.position,
              isAward: challenge.previousTeamRanking.award ? true : false,
            }
          : challenge.previousUserRanking
          ? {
              avatar: challenge.previousUserRanking.participant.avatar,
              name:
                challenge.previousUserRanking.participant.name ?? challenge.previousUserRanking.participant.username,
              score: challenge.previousUserRanking.score,
              isAward: challenge.previousUserRanking.award ? true : false,
              uuid: challenge.previousUserRanking.participant.participantUUID,
              position: challenge.previousUserRanking.position,
            }
          : null;
        state.data.progress = {
          lastSync: moment(
            challenge?.teamRanking?.lastUpdate ??
              challenge?.userRanking.lastUpdate ??
              challenge.challengeDuration.startDate,
          ).format('DD/MM/YY'),
          score: challenge.userRanking.score,
          teamScore: challenge?.teamRanking?.score,
          scoreToTop:
            leaderList.content && leaderList.content.length > 0 && challenge?.teamRanking?.remainderToTarget !== 0
              ? (challenge?.teamRanking?.score ?? challenge.userRanking.score ?? 0) -
                (leaderList.content[0]?.score ?? 0)
              : 0,
          scoreToPrevious:
            challenge?.teamRanking?.differenceFromPrevious ?? challenge.userRanking.differenceFromPrevious ?? 0,
          metricTitle: challenge.challengeAction.title,
          metricType: challenge.challengeAction.metricType,
          scoreTimeline: challenge.userRanking?.scoreTimeline ?? [],
          teamScoreTimeLine: challenge?.teamRanking?.scoreTimeline,

          goalValue: challenge.challengeGoal?.goalValue ?? 0,
          daysGoalValue: challenge.challengeGoal?.daysGoalValue,
          completionPercentage:
            challenge?.teamRanking?.completionPercentage ?? challenge.userRanking.completionPercentage,
          metricActions: challenge.challengeAction?.action ? challenge.challengeAction.action.split(' | ') : undefined,
        };
        state.data.user = {
          name:
            challenge?.teamRanking?.team?.name ??
            challenge.userRanking.participant.name ??
            challenge.userRanking.participant.username,
          position:
            challenge?.teamRanking?.position ??
            challenge.userRanking.position ??
            challenge.challengeParticipation.numberOfParticipants,
          avatar: challenge?.teamRanking
            ? challenge.teamRanking.team.imageURL
            : challenge.userRanking.participant.avatar,
          userUUID: challenge?.teamRanking?.team?.teamUUID ?? challenge.userRanking.participant.participantUUID,
          bestScore: challenge.userRanking.bestScore,
          dailyAverage: challenge.userRanking.dailyAverage,
          target: challenge?.teamRanking?.target ?? challenge.userRanking.target,
          teamBestScore: challenge?.teamRanking?.bestScore,
          teamDailyAverage: challenge?.teamRanking?.dailyAverage,
          joinTeamDate: challenge?.challengeParticipation?.joinTeamDate,
        };
      }
    });
    builder.addCase(fetchNextPageLeaderBoard.pending, (state) => {
      state.meta.isFetching = true;
    });
    builder.addCase(fetchNextPageLeaderBoard.rejected, (state) => {
      state.meta.isFetching = false;
    });
    builder.addCase(fetchNextPageLeaderBoard.fulfilled, (state, action) => {
      const content: unknown = action.payload?.content ?? [];
      if (!state.data.detail || !action.payload) return;
      state.data.leaderBoard = {
        page: action.payload.pageable.pageNumber,
        isLastPage: action.payload.last,
        list: [
          ...(state.data.leaderBoard?.list ?? []),
          ...(state.data.detail.competition !== COMPETITION.SELF
            ? formatLeaderboardList(content as ChallengeRankingItem[])
            : []),
        ],
        instanceList: [
          ...(state.data.leaderBoard?.instanceList ?? []),
          ...(state.data.detail.competition === COMPETITION.SELF
            ? formatInstanceList(content as InstanceItemData[])
            : []),
        ],
      };
      state.meta.isFetching = false;
    });
    builder.addCase(refetchProgressChallenge.fulfilled, (state, action) => {
      const challenge = action.payload?.challenge;
      const leaderList = action.payload?.list;
      if (challenge && leaderList) {
        state.data.award = {
          type: challenge.challengeAward?.awards?.[0]?.awardType ?? null,
          points: challenge.challengeAward?.awards?.[0]?.points ?? null,
          getAward: challenge.userRanking?.award ? true : false,
          triggerLimit: challenge.challengeAward?.awardTriggerLimit ?? null,
          awardIcon: challenge?.challengeAward?.awards?.[0]?.badgeImageUrl,
        };
        state.data.detail = {
          description: challenge.challengeInfo.shortDescription ?? '',
          awardTrigger: challenge.challengeAward?.awardTrigger,
          challengeTyp: challenge.challengeCharacteristic.challengeType,
          calculationType: challenge.challengeCharacteristic.calculationType,
          participantsNumber:
            challenge.challengeParticipation?.numberOfTeams ?? challenge.challengeParticipation.numberOfParticipants,
          leaderBoardSync: moment(challenge.leaderboardLastSync ?? challenge.challengeDuration.startDate).format(
            'DD/MM/YY',
          ),
          isAuto: challenge.challengeAction.actionSource === 'AUTO',
          isBoolean: challenge.challengeAction.actionType === 'HABIT',
          groupCategory: challenge.challengeCharacteristic.groupCategory,
          challengePillars: challenge.challengeCharacteristic.challengePillars[0],
          isTeam: challenge.challengeCharacteristic.competition === COMPETITION.TEAM,
          teamSize: challenge?.teamRanking?.team?.teamSize,
          competition: challenge.challengeCharacteristic.competition,
        };
        state.data.duration = {
          startDate: challenge.challengeDuration.startDate,
          endDate: challenge.challengeDuration.endDate,
          remainingDays: challenge.challengeDuration.currentDayInChallenge,
          challengeDays: challenge.challengeDuration.durationInDays,
          isGracePeriodEnd: challenge.challengeDuration.gracePeriodEnd
            ? new Date().setHours(0, 0, 0, 0) >
              new Date(challenge.challengeDuration.gracePeriodEnd).setHours(0, 0, 0, 0)
            : false,
        };
        const content: unknown = leaderList.content;
        state.data.leaderBoard = {
          isLastPage: leaderList.last,
          list:
            challenge.challengeCharacteristic.competition !== COMPETITION.SELF
              ? formatLeaderboardList(content as ChallengeRankingItem[])
              : [],
          instanceList:
            challenge.challengeCharacteristic.competition === COMPETITION.SELF
              ? formatInstanceList(content as InstanceItemData[])
              : [],
          page: leaderList.pageable.pageNumber,
        };
        state.data.navigation = {
          challengeStatus: challenge.challengeStatus,
          uuid: challenge.metadata.challengeUUID,
          templateUUID: challenge.metadata.templateChallengeUUID,
          previousChallengeUUID: challenge.previousChallengeUUID,
          nextChallengeUUID: challenge.nextChallengeUUID,
          subcategory: challenge.challengeSubCategory,
          title: challenge.challengeInfo.title,
        };
        state.data.previousUser = challenge.previousTeamRanking
          ? {
              avatar: challenge.previousTeamRanking.team.imageURL,
              name: challenge.previousTeamRanking.team.name,
              score: challenge.previousTeamRanking.score,
              uuid: challenge.previousTeamRanking.team.teamUUID,
              position: challenge.previousTeamRanking.position,
              isAward: challenge.previousTeamRanking.award ? true : false,
            }
          : challenge.previousUserRanking
          ? {
              avatar: challenge.previousUserRanking.participant.avatar,
              name:
                challenge.previousUserRanking.participant.name ?? challenge.previousUserRanking.participant.username,
              score: challenge.previousUserRanking.score,
              isAward: challenge.previousUserRanking.award ? true : false,
              uuid: challenge.previousUserRanking.participant.participantUUID,
              position: challenge.previousUserRanking.position,
            }
          : null;
        state.data.progress = {
          lastSync: moment(
            challenge?.teamRanking?.lastUpdate ??
              challenge?.userRanking.lastUpdate ??
              challenge.challengeDuration.startDate,
          ).format('DD/MM/YY'),
          score: challenge.userRanking.score,
          teamScore: challenge?.teamRanking?.score,
          scoreToTop:
            leaderList.content && leaderList.content.length > 0 && challenge?.teamRanking?.remainderToTarget !== 0
              ? (challenge?.teamRanking?.score ?? challenge.userRanking.score ?? 0) -
                (leaderList.content[0]?.score ?? 0)
              : 0,
          scoreToPrevious:
            challenge?.teamRanking?.differenceFromPrevious ?? challenge.userRanking.differenceFromPrevious ?? 0,
          metricTitle: challenge.challengeAction.title,
          metricType: challenge.challengeAction.metricType,
          scoreTimeline: challenge.userRanking?.scoreTimeline ?? [],
          teamScoreTimeLine: challenge?.teamRanking?.scoreTimeline,
          goalValue: challenge.challengeGoal?.goalValue ?? 0,
          daysGoalValue: challenge.challengeGoal?.daysGoalValue,
          completionPercentage:
            challenge?.teamRanking?.completionPercentage ?? challenge.userRanking.completionPercentage,
          metricActions: challenge.challengeAction?.action ? challenge.challengeAction.action.split(' | ') : undefined,
        };
        state.data.user = {
          name:
            challenge?.teamRanking?.team?.name ??
            challenge.userRanking.participant.name ??
            challenge.userRanking.participant.username,
          position:
            challenge?.teamRanking?.position ??
            challenge.userRanking.position ??
            challenge.challengeParticipation.numberOfParticipants,
          avatar: challenge?.teamRanking
            ? challenge.teamRanking.team.imageURL
            : challenge.userRanking.participant.avatar,
          userUUID: challenge?.teamRanking?.team?.teamUUID ?? challenge.userRanking.participant.participantUUID,
          bestScore: challenge.userRanking.bestScore,
          dailyAverage: challenge.userRanking.dailyAverage,
          target: challenge?.teamRanking?.target ?? challenge.userRanking.target,
          teamBestScore: challenge?.teamRanking?.bestScore,
          teamDailyAverage: challenge?.teamRanking?.dailyAverage,
          joinTeamDate: challenge?.challengeParticipation?.joinTeamDate,
        };
      }
    });
  },
});
export default challengeProgressSlice.reducer;
