import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { useIonRouter } from '@ionic/react';
import { CHALLENGE_STATUS } from '../../../models/enum/CHALLENGE_STATUS';
import { useMediaQuery } from '@mui/material';
import theme from '../../../theme';

export const useHandleHeader = () => {
  const navigation = useSelector((state: RootState) => state.challengeProgress.data.navigation);
  const detail = useSelector((state: RootState) => state.challengeProgress.data.detail);
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const router = useIonRouter();

  const openDetailPage = () => {
    if (navigation?.uuid) {
      const queryParam = navigation?.challengeStatus === CHALLENGE_STATUS.TEMPLATE ? '?isTemplate=true' : '';
      router.push(
        `/page/your-challenges/challenge/${
          navigation?.challengeStatus === CHALLENGE_STATUS.TEMPLATE ? navigation.templateUUID : navigation.uuid
        }${queryParam}`,
      );
    }
  };

  const openSlack = () => (navigation?.slackUrl ? window.open(navigation.slackUrl) : null);

  return {
    subcategory: navigation?.subcategory,
    title: navigation?.title,
    nextLink: navigation?.nextChallengeUUID ?? null,
    prevLink: navigation?.previousChallengeUUID ?? null,
    mdDown,
    hasSlackButton: navigation?.slackUrl,
    shortDescription: detail?.description ?? '',
    openDetailPage,
    openSlack,
  };
};
