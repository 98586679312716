import React from 'react';
import { CustomDialog } from '../CustomDialog';
import { Box, List, Typography } from '@mui/material';
import { ColorType, LeaderBoardItem } from './LeaderBoardList';
import { LeaderBoardItemData, LeaderBoardType } from '../../../models/Shared';
import { ReactComponent as ExportIcon } from '../../../assets/svg/feature/export.svg';
import { Colors } from '../../../theme/colors';
import { Capacitor } from '@capacitor/core';

type LeaderBoardModalProps = {
  visible: boolean;
  onClose: () => void;
  data?: LeaderBoardItemData[];
  target?: number | null;
  isLoading?: boolean;
  type: LeaderBoardType;
  isGracePeriodEnd: boolean;
  triggerLimit?: number | null;
  userUUID?: string;
  handleExport?: () => void;
  listRef: React.RefObject<HTMLUListElement>;
  handleScrollEvent: () => void;
};

export const LeaderBoardModal: React.FC<LeaderBoardModalProps> = ({
  visible,
  onClose,
  data = [],
  target,
  isLoading,
  type,
  isGracePeriodEnd,
  triggerLimit,
  userUUID,
  handleExport,
  listRef,
  handleScrollEvent,
}) => {
  const isWeb = !Capacitor.isNativePlatform();
  return (
    <CustomDialog onClose={onClose} open={visible} label="Full leaderboard">
      <List
        ref={listRef}
        onScroll={handleScrollEvent}
        sx={{
          overflow: 'auto',
          height: { xs: 'auto', md: 416 },
          width: { xs: 'auto', md: 470 },
          marginInline: '12px',
          paddingInline: '12px',
          marginBottom: { xs: 0, md: '34px' },
          paddingBottom: { xs: '34px', md: 0 },
          '&::-webkit-scrollbar': {
            width: '5px !important',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#D3D7DA',
          },
        }}
      >
        {data?.map((item, idx) => {
          let itemType: ColorType = 'default';
          if (type === 'Top' && item.score >= 0) {
            if (isGracePeriodEnd) {
              if (item.isAwarded) {
                itemType = 'award';
              }
            } else {
              if (triggerLimit && triggerLimit > idx) {
                itemType = 'top';
              }
            }
          }
          if ((type === 'Target' || type === 'Completion') && item.score > 0) {
            if (item.isAwarded) {
              itemType = 'award';
            } else {
              if (idx < 3) {
                itemType = 'top';
              }
            }
          }
          const isYou = userUUID === item.uuid;
          return (
            <LeaderBoardItem
              key={item.uuid}
              {...item}
              target={target}
              color={itemType}
              isYou={isYou}
              {...(isYou && idx > 0 && { valueLeft: item.score - data[idx - 1].score })}
            />
          );
        })}
      </List>
      {handleExport && isWeb && (
        <Box
          component="button"
          sx={{
            display: 'flex',
            alignItems: 'baseline',
            cursor: 'pointer',
            marginInline: '24px',
            marginBottom: '34px',
            background: 'unset',
          }}
          onClick={handleExport}
        >
          <ExportIcon fill={Colors.gray[400]} style={{ transform: 'translateY(4px)' }} />
          <Typography sx={{ fontSize: '12px', textDecoration: 'underline', color: Colors.gray[400] }}>
            Export as .xls file
          </Typography>
        </Box>
      )}
    </CustomDialog>
  );
};
