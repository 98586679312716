import { useEffect, useState } from 'react';
import {
  getFinishedChallenges,
  getOngoingChallenges,
  getOpenToJoinChallenges,
} from '../../../redux/slices/challengeAdminSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import useIsPageActive from '../../../hooks/useIsPageActive';
import { ANALYTICS_PAGE } from '../../../models/enum/ANALYTICS_PAGE';
import { QueryFilterData } from '../../../components/features/HeaderFilter/useHandleFilter';
import { getRequest } from '../../../services/HttpService';
import { openToast } from '../../../redux/slices/appSlice';
import { handleBackendError } from '../../../utils/handleBackendError';
import { useExportExcel } from '../../../hooks/useExportExcel';
import { CHALLENGE_STATUS } from '../../../models/enum/CHALLENGE_STATUS';

const allChallengeStatus = [
  CHALLENGE_STATUS.ACTIVE,
  CHALLENGE_STATUS.INACTIVE,
  CHALLENGE_STATUS.COMPLETED,
  CHALLENGE_STATUS.IN_GRACE_PERIOD,
];

export const useHandle = (queryFilter: QueryFilterData, PAGE_SIZE: number) => {
  const dispatch = useDispatch();
  const [isFirstOngoingLoading, setIsFirstOngoingLoading] = useState(false);
  const [isFirstOpenToJoinLoading, setIsOpenToJoinLoading] = useState(false);
  const [isFirstFinishedLoading, setIsFirstFinishedLoading] = useState(false);

  const [ongoingPage, setOngoingPage] = useState<number>(0);
  const [openToJoinPage, setOpenToJoinPage] = useState<number>(0);
  const [finishedPage, setFinishedPage] = useState<number>(0);

  const { exportToExcel, exportFileName } = useExportExcel();

  const visible = useIsPageActive(ANALYTICS_PAGE.CHALLENGES_ADMIN);

  const {
    challengesOngoing,
    challengesOpenToJoin,
    challengesFinished,
    challengesOngoingCount,
    challengesOpenToJoinCount,
    challengesFinishedCount,
  } = useSelector((state: RootState) => state.challengeAdmin.data);

  const { challengesOngoingLoading, challengesOpenToJoinLoading, challengesFinishedLoading } = useSelector(
    (state: RootState) => state.challengeAdmin.meta,
  );

  const handleExport = async () => {
    try {
      const params = allChallengeStatus.map((item) => `challengeStatus=${item}`).join('&');
      const response: { data?: string } = await getRequest(`/gamification-service/admin/challenges/export?${params}`);
      if (response.data) {
        await exportToExcel(response.data, exportFileName('challenge-export'));
        return;
      }
      dispatch(openToast({ type: 'error', text: 'Export file is empty' }));
    } catch (err) {
      handleBackendError(err, 'Fail to export challenges');
    }
  };

  useEffect(() => {
    setIsFirstOngoingLoading(false);
  }, [challengesOngoingLoading]);
  useEffect(() => {
    setIsOpenToJoinLoading(false);
  }, [challengesOpenToJoinLoading]);
  useEffect(() => {
    setIsFirstFinishedLoading(false);
  }, [challengesFinishedLoading]);

  useEffect(() => {
    if (!visible) return;
    setIsFirstOngoingLoading(true);
    setIsOpenToJoinLoading(true);
    setIsFirstFinishedLoading(true);

    setOngoingPage(0);
    setOpenToJoinPage(0);
    setFinishedPage(0);
  }, [queryFilter, visible]);

  useEffect(() => {
    if (!visible) return;

    const subscribe = setTimeout(() => {
      dispatch(getOngoingChallenges({ page: ongoingPage, size: PAGE_SIZE, ...queryFilter }));
    }, 600);
    return () => clearTimeout(subscribe);
  }, [ongoingPage, queryFilter, visible]);
  useEffect(() => {
    if (!visible) return;

    const subscribe = setTimeout(() => {
      dispatch(getOpenToJoinChallenges({ page: openToJoinPage, size: PAGE_SIZE, ...queryFilter }));
    }, 600);
    return () => clearTimeout(subscribe);
  }, [openToJoinPage, queryFilter, visible]);
  useEffect(() => {
    if (!visible) return;

    const subscribe = setTimeout(() => {
      dispatch(getFinishedChallenges({ page: finishedPage, size: PAGE_SIZE, ...queryFilter }));
    }, 600);
    return () => clearTimeout(subscribe);
  }, [finishedPage, queryFilter, visible]);
  return {
    challengesOngoing,
    challengesOpenToJoin,
    challengesFinished,

    challengesOngoingCount,
    challengesOpenToJoinCount,
    challengesFinishedCount,

    isFirstOngoingLoading,
    isFirstOpenToJoinLoading,
    isFirstFinishedLoading,

    challengesOngoingLoading,
    challengesOpenToJoinLoading,
    challengesFinishedLoading,

    ongoingPage,
    openToJoinPage,
    finishedPage,

    setOngoingPage,
    setOpenToJoinPage,
    setFinishedPage,
    handleExport,
  };
};
