import React, { FC, RefObject } from 'react';
import { Box, CircularProgress, DialogContent, List, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { ReactComponent as PlusIcon } from '../../../assets/svg/plus.svg';
import { ReactComponent as ExportIcon } from '../../../assets/svg/feature/export.svg';
import { Colors } from '../../../theme/colors';
import { CustomButton } from '../CustomButton';
import IconPlaceholder from '../../../assets/svg/placeholder_avatar.svg';
import Grid from '@mui/material/Grid';
import { Participant } from '../../../models/Participant';
import theme from '../../../theme';
import { CustomDialog } from '../CustomDialog';
import { Team } from '../../../models/Team';
import { pluralWordRule } from '../../../utils/pluralWordRule';
import { Avatar } from '../Avatar';
import { Capacitor } from '@capacitor/core';

interface ModalProps {
  show: boolean;
  isSelf?: boolean;
  isAdmin?: boolean;
  isParticipationLocked: boolean;
  isUserParticipant: boolean;
  participants: Participant[];
  teams?: Team[];
  loading: boolean;
  isTeam?: boolean;
  divRef: RefObject<HTMLUListElement>;
  handleScrollEvent: () => void;
  onClose: () => void;
  onAbort?: () => void;
  onJoin?: () => void;
  handleExport?: () => void;
}

export const ChallengeParticipantsModal: FC<ModalProps> = ({
  show = false,
  isSelf = false,
  isAdmin = false,
  isParticipationLocked,
  isUserParticipant,
  participants,
  teams = [],
  loading,
  isTeam,
  divRef,
  handleScrollEvent,
  onClose,
  onAbort,
  onJoin,
  handleExport,
}) => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const isWeb = !Capacitor.isNativePlatform();
  const data = isTeam ? teams : participants;

  return (
    <CustomDialog onClose={onClose} open={show}>
      <Box
        sx={{
          maxWidth: '400px',
          margin: '0 auto',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mt: '6px', maxWidth: '296px', mx: 'auto', mb: '16px' }}
        >
          <Typography
            variant="h5"
            sx={{ textAlign: 'center', color: Colors.gray[700], fontWeight: 'bold', fontSize: 18 }}
          >
            {isSelf ? 'Today’s participants' : isTeam ? 'All teams' : 'All participants'}
          </Typography>
        </Box>
        {loading && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
              zIndex: 2,
              background: 'rgba(65, 74, 79, 0.3)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress color="inherit" style={{ width: 30, height: 30 }} />
          </Box>
        )}
        <DialogContent
          ref={divRef}
          onScroll={handleScrollEvent}
          sx={{
            margin: mdDown ? '0 16px' : '0 25px 0 38px',
            padding: mdDown ? 0 : '0 10px 0 0',
            '&::-webkit-scrollbar-track': { background: '#F0F2F3!important' },
            '&::-webkit-scrollbar-thumb': { background: '#D3D7DA!important' },
            '&::-webkit-scrollbar-track:hover': { background: '#F0F2F3!important' },
            '*::-webkit-scrollbar': {
              width: '5px!important',
              height: '5px !important',
            },
          }}
        >
          <Box sx={{ width: '316px', height: 272, maxWidth: '100%', bgcolor: 'background.paper', margin: '0 auto' }}>
            <List
              sx={{
                width: '100%',
                maxWidth: '100%',
                position: 'relative',
                overflowY: 'auto',
                padding: 0,
                '& ul': { padding: 0, margin: 0 },
              }}
            >
              {data.map((item, index) => (
                <Grid
                  key={index}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    background: Colors.gray[50],
                    borderRadius: '10px',
                    ml: '20px',
                    mt: index ? '8px' : 0,
                    padding: '4px 8px 4px 0',
                  }}
                >
                  <Grid sx={{ ml: '-20px' }}>
                    <Box
                      sx={{
                        height: '40px',
                        width: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderWidth: 2,
                        borderColor: Colors.white,
                        borderStyle: 'solid',
                        position: 'relative',
                        overflow: 'hidden',
                        borderRadius: '100%',
                      }}
                    >
                      <Avatar
                        src={item.avatar ? item.avatar : IconPlaceholder}
                        alt={'background'}
                        sx={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          position: 'absolute',
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      ml: '8px',
                      width: '100%',
                    }}
                  >
                    <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography
                        sx={{ color: Colors.gray[700], fontSize: '14px', lineHeight: '14px' }}
                        component="span"
                        variant="body2"
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        sx={{ color: Colors.gray[400], fontSize: '10px', lineHeight: '10px', mt: '4px' }}
                        component="span"
                        variant="body2"
                      >
                        {isTeam
                          ? `${(item as Team).teamSize} participant${pluralWordRule((item as Team).teamSize)}`
                          : (item as Participant).email}
                      </Typography>
                    </Grid>

                    {'teamName' in item && item.teamName !== 'No Team' && (
                      <Tooltip
                        title={
                          <Typography sx={{ fontSize: '10px', color: Colors.white }} variant={'body2'}>
                            {item.teamName}
                          </Typography>
                        }
                        placement={'top'}
                        enterTouchDelay={0}
                        leaveTouchDelay={10000}
                        sx={{ mx: 0, color: Colors.white }}
                      >
                        <Box
                          sx={{
                            height: '28px',
                            width: '28px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderWidth: 2,
                            borderColor: '#F7F9F9',
                            borderStyle: 'solid',
                            position: 'relative',
                            overflow: 'hidden',
                            borderRadius: '100%',
                          }}
                        >
                          <img
                            src={'teamAvatar' in item ? item.teamAvatar : IconPlaceholder}
                            alt={'background'}
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              objectPosition: 'center',
                              position: 'absolute',
                            }}
                          />
                        </Box>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              ))}
            </List>
          </Box>
        </DialogContent>

        {onAbort && onJoin && !handleExport ? (
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={
              (!isParticipationLocked && !isUserParticipant) || isUserParticipant ? 'space-between' : 'center'
            }
            sx={{ margin: mdDown ? '24px auto 34px' : '24px auto 40px', maxWidth: '316px', width: '100%' }}
          >
            {!isParticipationLocked && !isUserParticipant && (
              <CustomButton
                type={'bigBlue'}
                text={'Join'}
                inputProps={{
                  variant: 'contained',
                  startIcon: <PlusIcon width={12} height={12} />,
                }}
                onClick={onJoin}
              />
            )}
            {isUserParticipant && <CustomButton type={'bigLightBlue'} text={'Abort'} onClick={onAbort} />}
            <CustomButton type={isUserParticipant ? 'bigBlue' : 'bigLightBlue'} text={'Cancel'} onClick={onClose} />
          </Box>
        ) : handleExport && isWeb ? (
          <Box
            component="button"
            display={'flex'}
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            sx={{ margin: '16px auto 24px', maxWidth: '316px', width: '100%', background: 'unset' }}
            onClick={handleExport}
          >
            <Box sx={{ display: 'flex', cursor: 'pointer' }}>
              <ExportIcon height={16} width={16} fill={Colors.gray[400]} />
              <Typography
                sx={{ fontSize: '12px', ml: '4px', textDecorationLine: 'underline', color: Colors.gray[400] }}
              >
                Export {isTeam ? 'teams' : 'participants'}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box sx={{ mt: '44px' }} />
        )}
      </Box>
    </CustomDialog>
  );
};
