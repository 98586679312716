import React from 'react';
import { Box, Tooltip, Typography, useMediaQuery } from '@mui/material';
import theme from '../../../theme';
import Grid from '@mui/material/Grid';
import InputField from '../../../components/features/CustomTextField';
import { InfoOutlined } from '@mui/icons-material';
import { CustomButton } from '../../../components/features/CustomButton';
import CustomRangePicker from '../../../components/common/CustomRangePicker';
import { EditForm } from '../hooks/useHandle';
import { Range } from 'react-date-range';
import CustomLabelValueDropdown from '../../../components/features/CustomLabelValueDropdown';
import { AWARD_TYPE } from '../../../models/enum/AWARD_TYPE';
import { TRACKING_TYPE } from '../../../models/enum/TRACKING_TYPE';
import { Challenge } from '../../../models/Challenge';
import { COMPETITION } from '../../../models/enum/COMPETITION';
import { AWARD_TRIGGER } from '../../../models/enum/AWARD_TRIGGER';
import { GROUP_CATEGORY } from '../../../models/enum/GROUP_CATEGORY';

const TrackingTypeOptions = [
  { label: 'Auto', value: TRACKING_TYPE.AUTO },
  { label: 'Manual', value: TRACKING_TYPE.MANUAL },
  { label: 'Hybrid', value: TRACKING_TYPE.HYBRID },
];
type Props = {
  form: EditForm;
  challenge: Challenge;
  range: Range[];
  isAvailablePublish: boolean;
  handleChangeTrackingType: (value: TRACKING_TYPE) => void;
  handleChangeTeamSize: (value: string) => void;
  handleChangeWinners: (value: string) => void;
  handleChangeSlackUrl: (value: string) => void;
  handleChangeRange: (range: Range[]) => void;
  onBack: () => void;
  onPublish: () => void;
};
export const ThirdForm: React.FC<Props> = ({
  form,
  challenge,
  range,
  isAvailablePublish,
  handleChangeRange,
  onBack,
  onPublish,
  handleChangeTeamSize,
  handleChangeWinners,
  handleChangeSlackUrl,
  handleChangeTrackingType,
}) => {
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));
  const isIndividual = challenge.challengeCharacteristic.competition === COMPETITION.INDIVIDUAL;
  const isCompletion = challenge.challengeAward && challenge.challengeAward.awardTrigger === AWARD_TRIGGER.COMPLETION;
  return (
    <Grid>
      <Grid container sx={{ pt: '24px' }}>
        <Grid xs={12} md={3} sx={{ paddingTop: { xs: 0, md: '20px' } }}>
          <Typography
            sx={{
              fontWeight: { xs: 900, md: 'bold' },
              fontSize: 14,
              lineHeight: 1.4,
              color: 'text.primary',
            }}
          >
            {'Setup details'}
          </Typography>
        </Grid>
        <Grid xs={12} md={9} sx={{ mt: { xs: '16px', md: 0 } }}>
          <Grid container>
            <Grid
              xs={12}
              md={challenge && challenge.challengeAction.actionSource === TRACKING_TYPE.HYBRID ? 6 : 12}
              sx={{
                paddingRight: {
                  xs: 0,
                  md: challenge && challenge.challengeAction.actionSource === TRACKING_TYPE.HYBRID ? '12px' : 0,
                },
              }}
            >
              <Box
                sx={{
                  marginLeft: '16px',
                  marginBottom: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  width: 'fit-content',
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 14,
                    lineHeight: 1.4,
                    color: 'text.primary',
                    mr: '4px',
                  }}
                >
                  {'StartDate - EndDate'}
                </Typography>
              </Box>

              <CustomRangePicker
                disablePastWithToday={Boolean(
                  challenge && challenge.challengeCharacteristic.groupCategory === GROUP_CATEGORY.CORPORATE,
                )}
                disablePast={true}
                mini={xsDown}
                setRange={handleChangeRange}
                range={range}
              />
            </Grid>
            {challenge && challenge.challengeAction.actionSource === TRACKING_TYPE.HYBRID && (
              <Grid xs={12} md={6} sx={{ paddingLeft: { xs: 0, md: '12px' }, mt: { xs: '24px', md: 0 } }}>
                <Tooltip
                  title={
                    <Typography sx={{ fontSize: '10px', maxWidth: 166 }} variant={'body2'}>
                      Select how the metric should be tracked by the participants.
                    </Typography>
                  }
                  placement={xsDown ? 'bottom-end' : 'top-start'}
                  enterTouchDelay={0}
                  leaveTouchDelay={10000}
                  sx={{ mx: 1, color: theme.palette.grey[600] }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: 'fit-content',
                      marginBottom: '8px',
                      marginLeft: '16px',
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        fontSize: 14,
                        lineHeight: 1.4,
                        color: 'text.primary',
                        mr: '4px',
                      }}
                    >
                      Tracking
                    </Typography>
                    <InfoOutlined style={{ width: '12px', height: '12px' }} fontSize={'inherit'} color={'inherit'} />
                  </Box>
                </Tooltip>
                <CustomLabelValueDropdown
                  width={'100%'}
                  list={TrackingTypeOptions}
                  placeholder="Choose"
                  selected={form.trackingType ? form.trackingType : ''}
                  disabled={false}
                  setSelected={(value) => handleChangeTrackingType(value as TRACKING_TYPE)}
                />
              </Grid>
            )}
          </Grid>

          {!(isIndividual && isCompletion) && (
            <Grid container sx={{ mt: '24px' }}>
              {!isIndividual && (
                <Grid xs={12} sm={isCompletion ? 12 : 6} sx={{ paddingRight: isCompletion ? 0 : { sm: '12px' } }}>
                  <InputField
                    bold={true}
                    label={
                      <Tooltip
                        title={
                          <Typography sx={{ fontSize: '10px', maxWidth: 166 }} variant={'body2'}>
                            Maximum number of team members should be calculated so as to have at least 10 teams within
                            your organization. So for 100 employees, the max team size should be up to 10.
                          </Typography>
                        }
                        placement={xsDown ? 'bottom-end' : 'top-start'}
                        enterTouchDelay={0}
                        leaveTouchDelay={10000}
                        sx={{ mx: 1, color: theme.palette.grey[600] }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: 'fit-content',
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 'bold',
                              fontSize: 14,
                              lineHeight: 1.4,
                              color: 'text.primary',
                              mr: '4px',
                            }}
                          >
                            {'Max team size'}
                          </Typography>
                          <InfoOutlined
                            style={{ width: '12px', height: '12px' }}
                            fontSize={'inherit'}
                            color={'inherit'}
                          />
                        </Box>
                      </Tooltip>
                    }
                    setValue={(e) => handleChangeTeamSize(e.target.value)}
                    name="steps"
                    type="number"
                    value={form.teamSize}
                    placeholder={'Enter number'}
                  />
                </Grid>
              )}

              {!isCompletion && (
                <Grid
                  xs={12}
                  sm={isIndividual ? 12 : 6}
                  sx={{
                    paddingLeft: { xs: 0, sm: isIndividual ? 0 : '12px' },
                    mt: { xs: isIndividual ? 0 : '24px', sm: 0 },
                  }}
                >
                  <InputField
                    bold={true}
                    label={
                      <Tooltip
                        title={
                          <Typography sx={{ fontSize: '10px', maxWidth: 166 }} variant={'body2'}>
                            How many people will win the award? We suggest up to 5% of employees headcount.
                          </Typography>
                        }
                        placement={xsDown ? 'bottom-end' : 'top-start'}
                        enterTouchDelay={0}
                        leaveTouchDelay={10000}
                        sx={{ mx: 1, color: theme.palette.grey[600] }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: 'fit-content',
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 'bold',
                              fontSize: 14,
                              lineHeight: 1.4,
                              color: 'text.primary',
                              mr: '4px',
                            }}
                          >
                            {'Number of winners'}
                          </Typography>
                          <InfoOutlined
                            style={{ width: '12px', height: '12px' }}
                            fontSize={'inherit'}
                            color={'inherit'}
                          />
                        </Box>
                      </Tooltip>
                    }
                    setValue={(e) => handleChangeWinners(e.target.value)}
                    name="steps"
                    type="number"
                    value={form.winners}
                    placeholder={'Enter number'}
                  />
                </Grid>
              )}
            </Grid>
          )}
          <Box sx={{ mt: '24px' }}>
            <InputField
              bold={true}
              label={
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 14,
                    lineHeight: 1.4,
                    color: 'text.primary',
                    mr: '4px',
                  }}
                >
                  Slack channel
                </Typography>
              }
              setValue={(e) => handleChangeSlackUrl(e.target.value)}
              name="slackUrl"
              value={form.slackUrl}
              placeholder={'Enter Slack link'}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid sx={{ mt: { xs: '50px', md: '40px' }, justifyContent: 'center', display: 'flex' }}>
        <CustomButton
          type={'bigLightOrange'}
          text={'Back'}
          width={'160px'}
          sx={{ height: 36, fontSize: '14px', lineHeight: '14px', mr: { xs: '8px', md: '12px' } }}
          onClick={onBack}
        />
        <CustomButton
          type={'bigOrange'}
          text={'Publish'}
          width={'160px'}
          sx={{ height: 36, fontSize: '14px', lineHeight: '14px', ml: { xs: '8px', md: '12px' } }}
          disabled={!isAvailablePublish}
          onClick={onPublish}
        />
      </Grid>
    </Grid>
  );
};
