import React, { useRef, useState, useLayoutEffect } from 'react';
import { Box, Card, CardContent, CardMedia, SxProps, Theme, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconPlaceholder from '../../../assets/defaultBlog.png';
import { CustomAvatar } from '../CustomAvatar';
import { Colors } from '../../../theme/colors';
import { ReactComponent as ClockIcon } from '../../../assets/svg/clock.svg';
import { ReactComponent as PlusIcon } from '../../../assets/svg/plus.svg';
import { ReactComponent as GoalIcon } from '../../../assets/svg/feature/goal.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/svg/feature/calendar.svg';
import { ReactComponent as ExclamationMarkIcon } from '../../../assets/svg/feature/exclamation-mark.svg';
import { ReactComponent as LogIcon } from '../../../assets/svg/feature/log.svg';
import { ReactComponent as WearableIcon } from '../../../assets/svg/wearable.svg';
import { CustomButton } from '../CustomButton';
import { CardLabel } from '../CardLabel';
import { Challenge } from '../../../models/Challenge';
import { CHALLENGE_STATUS } from '../../../models/enum/CHALLENGE_STATUS';
import { DIMENSION_LIST } from '../../../models/enum/DIMENTION_LIST';
import { COMPETITION_LIST } from '../../../models/enum/COMPETITION_LIST';
import { AWARD_LIST } from '../../../models/enum/AWARD_LIST';
import { useHandle } from './useHandler';
import { styles } from './styles';
import { CustomAvatarList } from '../CustomAvatarList';
import { getTooltipTitle } from '../../../utils/challengeCardTooltip';
import { ProgressToTarget } from '../progress/ProgressToTarget';
import { minPercentOutput } from '../../../utils/minPercentOutput';
import { useHandleProgress } from './useHandleProgress';
import { COMPETITION } from '../../../models/enum/COMPETITION';
import { TRACKING_TYPE } from '../../../models/enum/TRACKING_TYPE';
import { capitalize } from '../../../utils/stringCapitalize';

type Props = {
  status: CHALLENGE_STATUS;
  challenge: Challenge;
  showJoin?: boolean;
  isAdmin?: boolean;
  sx?: SxProps<Theme>;
  onJoin: (e: any) => void;
  onPressLogData?: (challenge: Challenge) => void;
};
export const ChallengeCard: React.FC<Props> = ({
  status,
  challenge,
  showJoin = true,
  isAdmin = false,
  sx,
  onJoin,
  onPressLogData,
}) => {
  const { daysTitleByStatus, emptyTrophy, daysLeftByStatus, durationInDays, handleOpenLink } = useHandle(
    challenge,
    status,
    isAdmin,
  );
  const [open, setOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const { ranking, isMember, percent, isLogButtonHidden } = useHandleProgress(challenge);
  const titleRef = useRef<HTMLSpanElement>(null);
  const timeoutRef = useRef<any>();

  useLayoutEffect(() => {
    if (titleRef.current && titleRef.current.scrollHeight > titleRef.current.clientHeight) {
      setShowTooltip(true);
    }
  }, []);

  return (
    <Box sx={{ position: 'relative', height: '100%', flex: 1, cursor: 'pointer' }} onClick={handleOpenLink}>
      <Card
        sx={[
          challenge.challengeParticipation.isUserParticipant ? styles.selfCardContainer : styles.cardContainer,
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <CardMedia
          sx={{
            flexShrink: 0,
            height: 140,
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
            background: `linear-gradient(180deg, rgba(0, 0, 0, 0.4) 18.68%, rgba(0, 0, 0, 0) 47.54%)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
          image={challenge.challengeInfo.imageUrl ?? IconPlaceholder}
        />

        <Grid container sx={styles.daysLeftPosition}>
          <Grid item sx={styles.daysLeftContainer}>
            <Box sx={styles.daysLeftCircle}>
              {status === CHALLENGE_STATUS.ACTIVE && challenge.challengeDuration.daysLeft === 0 ? (
                <ExclamationMarkIcon width={12} height={12} style={{ fill: Colors.white }} />
              ) : (status === CHALLENGE_STATUS.INACTIVE && challenge.challengeDuration.daysLeft === null) ||
                (status === CHALLENGE_STATUS.INACTIVE && !('daysLeft' in challenge.challengeDuration)) ? (
                <CalendarIcon style={{ fill: Colors.white }} />
              ) : (
                <Typography sx={{ fontSize: 10, fontWeight: 700, color: Colors.white }}>{daysLeftByStatus}</Typography>
              )}
            </Box>

            <Typography sx={{ fontSize: 10, fontWeight: 600, marginLeft: '4px', color: Colors.white }}>
              {daysTitleByStatus}
            </Typography>
          </Grid>
        </Grid>

        <CardLabel challenge={challenge} sxContainer={styles.labelStatusPosition} />

        <CardContent
          sx={challenge.challengeParticipation.isUserParticipant ? styles.selfCardContent : styles.cardContent}
        >
          <Box
            marginTop="0px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            onTouchStart={(e) => {
              clearTimeout(timeoutRef.current);
              setOpen(!open);
              timeoutRef.current = setTimeout(() => setOpen(false), 1500);
            }}
          >
            {challenge.challengeInfo.title && (
              <Tooltip
                open={open}
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
                title={showTooltip && challenge.challengeInfo.title}
              >
                <Typography
                  className={showTooltip ? 'tooltip-header' : ''}
                  ref={titleRef}
                  variant="caption"
                  sx={styles.cardTitle}
                >
                  {challenge.challengeInfo.title}
                </Typography>
              </Tooltip>
            )}
          </Box>
          <Box>
            <Grid container wrap={'wrap'} sx={styles.decorationLine}>
              <Grid sx={{ display: 'flex', flexWrap: 'no-wrap', alignItems: 'center', pt: '7px' }}>
                {challenge.challengeCharacteristic.challengePillars.map((challengePillar) => {
                  return DIMENSION_LIST.map((item, index) =>
                    item.value === challengePillar ? (
                      <Tooltip key={item.value} title={getTooltipTitle(challengePillar)}>
                        <Grid
                          sx={{
                            mr:
                              challenge.challengeCharacteristic.challengePillars.length > 1 &&
                              index !== challenge.challengeCharacteristic.challengePillars.length - 1
                                ? '4px'
                                : 0,
                          }}
                          item
                        >
                          <CustomAvatar size={24} bgColor={item.mainColor} borderBg={item.borderColor} borderWidth={1}>
                            <item.Icon width={12} height={12} />
                          </CustomAvatar>
                        </Grid>
                      </Tooltip>
                    ) : null,
                  );
                })}
                {challenge.challengeCharacteristic.challengePillars.length > 1 && (
                  <Box sx={{ borderRight: '1px dashed #F2F2F2', height: '16px', width: '1px', ml: '4px' }} />
                )}
              </Grid>

              {COMPETITION_LIST.map((item) =>
                item.value === challenge.challengeCharacteristic.competition ? (
                  <Tooltip key={item.value} title={getTooltipTitle(challenge.challengeCharacteristic.competition)}>
                    <Grid
                      item
                      sx={{
                        ml: challenge.challengeCharacteristic.challengePillars.length <= 1 ? '4px' : '8px',
                        mr: '4px',
                        pt: '7px',
                      }}
                    >
                      <CustomAvatar size={24} bgColor={item.mainColor} borderBg={item.borderColor} borderWidth={1}>
                        <item.Icon width={12} height={12} />
                      </CustomAvatar>
                    </Grid>
                  </Tooltip>
                ) : null,
              )}

              {!challenge?.challengeAward?.awards?.length ? (
                <Tooltip title="None">
                  <Grid item sx={{ mr: '4px', pt: '7px' }}>
                    <CustomAvatar
                      size={24}
                      bgColor={emptyTrophy.mainColor}
                      borderBg={emptyTrophy.borderColor}
                      borderWidth={1}
                    >
                      <emptyTrophy.Icon width={12} height={12} />
                    </CustomAvatar>
                  </Grid>
                </Tooltip>
              ) : (
                AWARD_LIST.map((item) =>
                  challenge.challengeAward &&
                  challenge.challengeAward.awards.length &&
                  item.value === challenge.challengeAward.awards[0].awardType ? (
                    <Tooltip key={item.value} title={getTooltipTitle(challenge.challengeAward.awards[0].awardType)}>
                      <Grid item sx={{ mr: '4px', pt: '7px' }}>
                        <CustomAvatar size={24} bgColor={item.mainColor} borderBg={item.borderColor} borderWidth={1}>
                          <item.Icon width={12} height={12} />
                        </CustomAvatar>
                      </Grid>
                    </Tooltip>
                  ) : null,
                )
              )}

              <Tooltip title={capitalize(challenge.challengeAction.actionSource.toLowerCase())}>
                <Grid item sx={{ mr: '4px', pt: '7px' }}>
                  <Box
                    alignItems={'center'}
                    justifyContent={'center'}
                    display={'flex'}
                    sx={{
                      width: challenge.challengeAction.actionSource === TRACKING_TYPE.HYBRID ? '100%' : 24,
                      height: 24,
                      px: challenge.challengeAction.actionSource === TRACKING_TYPE.HYBRID ? '6px' : 0,
                      backgroundColor: emptyTrophy.mainColor,
                      borderWidth: 1,
                      borderColor: emptyTrophy.borderColor,
                      borderRadius: 100,
                      borderStyle: 'solid',
                    }}
                  >
                    {(challenge.challengeAction.actionSource === TRACKING_TYPE.HYBRID ||
                      challenge.challengeAction.actionSource === TRACKING_TYPE.MANUAL) && (
                      <LogIcon width={12} height={12} fill={Colors.white} />
                    )}

                    {challenge.challengeAction.actionSource === TRACKING_TYPE.HYBRID && (
                      <Typography sx={{ fontSize: 12, color: Colors.white, lineHeight: 12 }}>+</Typography>
                    )}
                    {(challenge.challengeAction.actionSource === TRACKING_TYPE.HYBRID ||
                      challenge.challengeAction.actionSource === TRACKING_TYPE.AUTO) && (
                      <WearableIcon width={12} height={12} />
                    )}
                  </Box>
                </Grid>
              </Tooltip>

              <Grid
                item
                container
                alignItems={'center'}
                justifyContent={'flex-start'}
                wrap={'nowrap'}
                sx={{ pt: '7px', width: 'auto' }}
              >
                <ClockIcon width={16} height={16} fill={Colors.gray[400]} />
                <Typography color={Colors.gray[400]} sx={{ fontSize: '12px', lineHeight: 1.1, marginLeft: '4px' }}>
                  {durationInDays}
                </Typography>
              </Grid>
            </Grid>

            <Grid item container wrap={'nowrap'} justifyContent={'space-between'} sx={{ marginTop: '11px' }}>
              {isMember && ranking && onPressLogData ? (
                <Box display="flex" flex={1} justifyContent="space-between" alignItems="center">
                  <ProgressToTarget percent={minPercentOutput(percent ?? 0, 10)} />
                  {!isLogButtonHidden && (
                    <CustomButton
                      text={'Log data'}
                      type="lightBlue"
                      inputProps={{
                        variant: 'contained',
                        startIcon: <LogIcon fill={Colors.blue[500]} width={16} height={16} />,
                      }}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        onPressLogData(challenge);
                      }}
                    />
                  )}
                </Box>
              ) : (
                <>
                  <CustomAvatarList challenge={challenge} />

                  {showJoin &&
                    status !== CHALLENGE_STATUS.COMPLETED &&
                    !challenge.challengeParticipation.isParticipationLocked &&
                    !challenge.challengeParticipation.isUserParticipant && (
                      <Grid item>
                        <CustomButton
                          text={'Join'}
                          inputProps={{
                            variant: 'contained',
                            startIcon: <PlusIcon width={12} height={12} />,
                          }}
                          onClick={onJoin}
                        ></CustomButton>
                      </Grid>
                    )}
                  {challenge.challengeParticipation.userCountParticipationToChallenge &&
                  challenge.challengeParticipation.userCountParticipationToChallenge > 1 &&
                  status === CHALLENGE_STATUS.COMPLETED ? (
                    <Grid display={'flex'} alignItems={'center'} justifyContent={'center'}>
                      <GoalIcon width={16} height={16} />
                      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} sx={{ marginLeft: '4px' }}>
                        <Typography color={Colors.gray[400]} sx={{ fontSize: '12px', lineHeight: 1.3 }}>
                          finished
                        </Typography>
                        <Typography
                          color={Colors.gray[400]}
                          sx={{
                            fontSize: '12px',
                            lineHeight: 1.3,
                            fontWeight: 'bold',
                            marginLeft: '4px',
                            marginRight: '4px',
                          }}
                        >
                          {challenge.challengeParticipation.userCountParticipationToChallenge}
                        </Typography>
                        <Typography color={Colors.gray[400]} sx={{ fontSize: '12px', lineHeight: 1.3 }}>
                          times
                        </Typography>
                      </Box>
                    </Grid>
                  ) : null}
                </>
              )}
              {challenge.challengeParticipation.isUserParticipant &&
                challenge.challengeCharacteristic.competition === COMPETITION.TEAM &&
                !challenge.challengeParticipation.isUserTeamMember && (
                  <Grid display="flex" alignItems="center" item>
                    <Tooltip
                      title={
                        <>
                          <b>Action required:</b> Join or create a team to participate in this challenge.
                        </>
                      }
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          background: Colors.red[100],
                          height: '32px',
                          width: '32px',
                          borderRadius: '50%',
                        }}
                      >
                        <ExclamationMarkIcon fill={Colors.red[500]} height={16} width={16} />
                      </Box>
                    </Tooltip>
                  </Grid>
                )}
            </Grid>
          </Box>
        </CardContent>
      </Card>

      {status === CHALLENGE_STATUS.COMPLETED &&
      challenge.challengeParticipation.userCountParticipationToChallenge &&
      challenge.challengeParticipation.userCountParticipationToChallenge > 1 ? (
        <>
          <Grid sx={styles.firstLayout} />
          <Grid sx={styles.secondLayout} />
        </>
      ) : null}
    </Box>
  );
};
