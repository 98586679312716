import React, { FC } from 'react';
import { IMetric, IMetrics } from '../../models/Metrics';
import { MetricsCard } from '../cards/MetricsCard';
import CustomSnapBox from '../common/CustomSnapBox';
import { ANALYTICS_PAGE } from '../../models/enum/ANALYTICS_PAGE';
import { Skeleton } from '@mui/material';

interface Props {
  metrics: IMetrics;
  date: any;
  onEdit: (metric: IMetric) => void;
  page?: ANALYTICS_PAGE;
  loading?: boolean;
}

const MetricsContainer: FC<Props> = ({ metrics, date, onEdit, page, loading }) => {
  return (
    <CustomSnapBox column={3} pb={1} px={[2, 1, 3]} gap={3}>
      {loading
        ? [0, 1, 2].map((_, index) => (
            <Skeleton
              key={index}
              variant={'rectangular'}
              width={'100%'}
              height={250}
              sx={{
                borderRadius: 2,
              }}
            />
          ))
        : metrics?.metrics?.map((metric, index) => (
            <MetricsCard key={index} metric={metric} date={date} onEdit={onEdit} page={page} />
          ))}
    </CustomSnapBox>
  );
};

export default MetricsContainer;
