import React, { useEffect } from 'react';
import {
  Box,
  Collapse,
  Container,
  Grid,
  IconButton,
  IconButtonProps,
  styled,
  Switch,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Colors } from '../../../theme/colors';
import CustomNewHeader from '../CustomNewHeader';
import CustomSearchbar from '../CustomSearchbar';
import { ReactComponent as FilterIcon } from '../../../assets/svg/feature/filter.svg';
import { ReactComponent as FilterResetIcon } from '../../../assets/svg/feature/filter-reset.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomLabelValueDropdown from '../CustomLabelValueDropdown';
import { DIMENSION_LIST } from '../../../models/enum/DIMENTION_LIST';
import { FilterSelector } from './FilterSelector';
import { ADMIN_COMPETITION_LIST, COMPETITION_LIST } from '../../../models/enum/COMPETITION_LIST';
import FormControlLabel from '@mui/material/FormControlLabel';
import { BreadcrumbProps, FilterFormParams } from '../../../models/Shared';
import theme from '../../../theme';
import { CHALLENGE_TYPE_LIST } from '../../../models/enum/CHALLENGE_TYPE_LIST';
import { MobileFilterContainer } from './MobileFilterContainer';
import { CustomButton } from '../CustomButton';
import { setShowCustomFab } from '../../../redux/slices/appSlice';
import { useDispatch } from 'react-redux';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

export const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  width: '32px',
  height: '32px',
  background: Colors.gray[150],
  border: '1px solid #E9EDEF',
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

type HeaderFilterProps = {
  label: string;
  isAdmin?: boolean;
  isTemplates?: boolean;
  loading?: boolean;
  tooltip?: NonNullable<React.ReactNode>;
  breadcrumbs?: BreadcrumbProps;
  searchValue: string;
  filterParams: FilterFormParams;
  expanded: boolean;
  showResetFilters: boolean;
  actions?: React.ReactNode;
  metrics: { label: string; value: string }[];
  onChangeSearch: (value: string) => void;
  handleExpandClick: () => void;
  handleChangeSortBy: (sortBy: string) => void;
  handleItemDimention: (value: string, isSelected: boolean) => () => void;
  handleItemCompetition: (value: string, isSelected: boolean) => () => void;
  handleItemChallengeType: (value: string, isSelected: boolean) => () => void;
  handleSwitchCorporate: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSwitchPublisher: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleResetFilters: () => void;
};
export const HeaderFilter: React.FC<HeaderFilterProps> = ({
  label,
  isAdmin,
  isTemplates,
  loading,
  tooltip,
  breadcrumbs,
  searchValue,
  filterParams,
  expanded,
  showResetFilters,
  actions,
  metrics,
  onChangeSearch,
  handleExpandClick,
  handleChangeSortBy,
  handleItemDimention,
  handleItemCompetition,
  handleItemChallengeType,
  handleSwitchCorporate,
  handleSwitchPublisher,
  handleResetFilters,
}) => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useDispatch();

  useEffect(() => {
    if (xsDown && expanded) {
      dispatch(setShowCustomFab(false));
      return;
    }
    dispatch(setShowCustomFab(true));
  }, [xsDown, expanded]);

  const Filters: React.FC<{ isMobile?: boolean }> = ({ isMobile = false }) => (
    <>
      <Grid
        container
        spacing={1}
        sx={{
          paddingTop: { xs: '16px', sm: 0 },
          paddingBottom: '24px',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
        }}
      >
        <Grid item>
          <FilterSelector
            data={DIMENSION_LIST}
            selected={filterParams.dimention}
            bgColor={isMobile ? Colors.gray[50] : undefined}
            disabledRoundColor={isMobile ? Colors.gray[150] : undefined}
            disabledRoundBorderColor={isMobile ? Colors.gray[100] : undefined}
            sx={isMobile ? { p: '10px', borderRadius: '20px' } : undefined}
            onClickItem={handleItemDimention}
          />
        </Grid>
        <Grid item>
          <FilterSelector
            data={isAdmin ? ADMIN_COMPETITION_LIST : COMPETITION_LIST}
            selected={filterParams.competition}
            bgColor={isMobile ? Colors.gray[50] : undefined}
            disabledRoundColor={isMobile ? Colors.gray[150] : undefined}
            disabledRoundBorderColor={isMobile ? Colors.gray[100] : undefined}
            sx={isMobile ? { p: '10px', mt: '8px', borderRadius: '20px' } : undefined}
            onClickItem={handleItemCompetition}
          />
        </Grid>
      </Grid>
      <Grid
        container
        wrap={'wrap'}
        alignItems={'flex-start'}
        justifyContent="space-between"
        spacing={1}
        sx={{ flexDirection: isMobile ? 'column' : 'row' }}
      >
        <Grid item sx={{ my: '4px' }}>
          <CustomLabelValueDropdown
            width={320}
            list={metrics}
            placeholder="Select metric"
            selected={filterParams?.metric}
            disabled={false}
            setSelected={handleChangeSortBy}
          />
        </Grid>
        {isTemplates && (
          <Grid item sx={{ width: { xs: '100%', sm: 'auto' }, mt: { xs: '16px', sm: 0 } }}>
            <FilterSelector
              data={CHALLENGE_TYPE_LIST}
              selected={filterParams.challengeType}
              bgColor={isMobile ? Colors.gray[50] : undefined}
              disabledRoundColor={isMobile ? Colors.gray[150] : undefined}
              disabledRoundBorderColor={isMobile ? Colors.gray[100] : undefined}
              sx={isMobile ? { p: '10px', borderRadius: '20px' } : undefined}
              onClickItem={handleItemChallengeType}
            />
          </Grid>
        )}
        {isAdmin && !isTemplates && (
          <Grid item>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center' }}>
              <FormControlLabel
                sx={{ fontSize: '14px', color: Colors.gray[500], ml: { xs: 0, sm: '16px' } }}
                label="Published by me"
                labelPlacement="start"
                control={
                  <Switch
                    checked={filterParams.publisher}
                    onChange={handleSwitchPublisher}
                    sx={{
                      pl: '8px',
                      pr: '8px',
                      '& .MuiSwitch-switchBase': {
                        marginTop: '5px',
                        marginBottom: '5px',
                        transform: 'translateX(1px)',
                        opacity: 1,
                        '&.Mui-checked ': {
                          transform: 'translateX(14px)',
                          '&+.MuiSwitch-track': {
                            background: Colors.blue[600],
                            border: `1px solid ${Colors.blue[300]}`,
                          },
                        },
                      },
                      '& .MuiSwitch-track': {
                        width: 29,
                        height: 16,
                        borderRadius: 100,
                        background: Colors.gray[150],
                        border: '1px solid #E2E5E6',
                      },
                      '& .MuiSwitch-thumb': {
                        height: 12,
                        width: 12,
                        boxShadow: 'none',
                        background: Colors.white,
                      },
                    }}
                    value={true}
                  />
                }
              />
            </Box>
          </Grid>
        )}
        {!isAdmin && (
          <Grid item>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center' }}>
              <FormControlLabel
                sx={{ fontSize: '14px', color: Colors.gray[500], ml: { xs: 0, sm: '16px' } }}
                label="Only Corporate challenges"
                labelPlacement="start"
                control={
                  <Switch
                    checked={filterParams.onlyCorporate}
                    onChange={handleSwitchCorporate}
                    sx={{
                      pl: '8px',
                      pr: '8px',
                      '& .MuiSwitch-switchBase': {
                        marginTop: '5px',
                        marginBottom: '5px',
                        transform: 'translateX(1px)',
                        opacity: 1,
                        '&.Mui-checked ': {
                          transform: 'translateX(14px)',
                          '&+.MuiSwitch-track': {
                            background: Colors.blue[600],
                            border: `1px solid ${Colors.blue[300]}`,
                          },
                        },
                      },
                      '& .MuiSwitch-track': {
                        width: 29,
                        height: 16,
                        borderRadius: 100,
                        background: Colors.gray[150],
                        border: '1px solid #E2E5E6',
                      },
                      '& .MuiSwitch-thumb': {
                        height: 12,
                        width: 12,
                        boxShadow: 'none',
                        background: Colors.white,
                      },
                    }}
                    value={true}
                  />
                }
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );

  return (
    <>
      <CustomNewHeader breadcrumb={breadcrumbs} label={label} tooltip={tooltip} inProgress={loading}>
        <Box
          sx={{
            display: 'flex',
            alignItems: mdDown ? 'flex-end' : 'center',
            justifyContent: 'flex-end',
            flexDirection: mdDown ? 'column' : 'row',
          }}
        >
          {actions}

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CustomSearchbar
              width={mdDown ? '100%' : isAdmin ? 200 : undefined}
              searchInput={searchValue}
              setSearchInput={onChangeSearch}
              handleSearch={() => undefined}
            />

            {!xsDown && showResetFilters && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '91px',
                  height: '36px',
                  background: isAdmin ? '#FAEED7' : Colors.blue[100],
                  border: isAdmin ? '1px solid #FCCF79' : '1px solid #A2E5EC',
                  borderRadius: '100px',
                  px: '16px',
                  marginLeft: '16px',
                  cursor: 'pointer',
                }}
                onClick={handleResetFilters}
              >
                <FilterResetIcon width={16} height={16} fill={isAdmin ? Colors.orange[500] : Colors.blue[500]} />
                <Typography
                  variant="h5"
                  sx={{
                    ml: '4px',
                    textAlign: 'center',
                    color: isAdmin ? Colors.orange[500] : Colors.blue[500],
                    fontSize: 14,
                  }}
                >
                  Reset
                </Typography>
              </Box>
            )}

            <Grid
              onClick={handleExpandClick}
              container
              flexWrap={'nowrap'}
              alignItems={'center'}
              justifyContent={'space-between'}
              sx={{
                background: Colors.gray[100],
                borderRadius: '30px',
                maxWidth: '78px',
                padding: '2px',
                marginLeft: '16px',
                cursor: 'pointer',
              }}
            >
              <FilterIcon width={16} height={16} style={{ margin: '0 8px 0 16px' }} />

              <Grid item>
                <ExpandMore
                  expand={expanded}
                  aria-expanded={expanded}
                  sx={{
                    background: showResetFilters ? (isAdmin ? Colors.orange[500] : '#26ACBA') : Colors.gray[150],
                    border: showResetFilters
                      ? isAdmin
                        ? '1px solid #FCCF79'
                        : '1px solid #73D8E2'
                      : '1px solid #E9EDEF',
                    color: showResetFilters ? Colors.white : Colors.gray[500],
                    '&:hover': {
                      background: showResetFilters ? (isAdmin ? Colors.orange[500] : '#26ACBA') : Colors.gray[150],
                    },
                  }}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </CustomNewHeader>
      {!xsDown && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box sx={{ background: Colors.gray[50], paddingBottom: '16px' }}>
            <Container maxWidth={'lg'}>
              <Filters />
            </Container>
          </Box>
        </Collapse>
      )}
      {xsDown && (
        <MobileFilterContainer
          show={expanded}
          handleClose={handleExpandClick}
          action={
            <Box sx={{ mt: '24px' }}>
              {showResetFilters && (
                <CustomButton
                  type={isAdmin ? 'bigOrange' : 'bigBlue'}
                  text={'Reset'}
                  inputProps={{
                    variant: 'contained',
                    startIcon: <FilterResetIcon width={16} height={16} fill={Colors.white} />,
                  }}
                  onClick={() => {
                    handleResetFilters();
                    handleExpandClick();
                  }}
                  width={'100%'}
                />
              )}
              <CustomButton
                type={isAdmin ? 'bigLightOrange' : 'bigLightBlue'}
                text={'Close'}
                onClick={handleExpandClick}
                width={'100%'}
                sx={{ mt: showResetFilters ? '16px' : 0 }}
              />
            </Box>
          }
        >
          <Filters isMobile={true} />
        </MobileFilterContainer>
      )}
    </>
  );
};
