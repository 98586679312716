import { throttle } from 'lodash';
import { useState, useEffect, useRef } from 'react';
import useIsPageActive from '../../../hooks/useIsPageActive';
import { ANALYTICS_PAGE } from '../../../models/enum/ANALYTICS_PAGE';
import { getChallengeParticipants } from '../../../lib/api/http/requests/challenge';
import { Participant } from '../../../models/Participant';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

const SIZE_PAGE = 12;
type Props = {
  id: string;
};
export const useParticipantsHandle = (params: Props) => {
  const [participants, setParticipantsItems] = useState<Participant[]>([]);
  const [participantsLoading, setParticipantsLoading] = useState(true);
  const [participantsPage, setParticipantsPage] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);

  const challenge = useSelector((state: RootState) => state.challenge.data.challenge);

  const visible = useIsPageActive(ANALYTICS_PAGE.CHALLENGE_ADMIN_DETAILS);
  const divParticipantsRef = useRef<HTMLUListElement>(null);

  const queryParams = new URLSearchParams(window.location.search);
  const isTemplate = queryParams.get('isTemplate') === 'true' ? true : false;

  const handleNext = async () => {
    try {
      if (isFetching || !hasNextPage || participantsLoading || !challenge) return;
      setIsFetching(true);
      const requestParams: { challengeUUID?: string; templateChallengeUUID?: string; page: number; size: number } = {
        ...(challenge.metadata.challengeUUID
          ? { challengeUUID: challenge?.metadata.challengeUUID }
          : { templateChallengeUUID: challenge.metadata.templateChallengeUUID }),
        page: participantsPage,
        size: SIZE_PAGE,
      };
      // isTemplate
      //   ? { templateChallengeUUID: params.id, page: participantsPage, size: SIZE_PAGE }
      //   : {
      //       challengeUUID: params.id,
      //       // templateChallengeUUID: params.id,
      //       page: participantsPage,
      //       size: SIZE_PAGE,
      //     };

      const { data } = await getChallengeParticipants(requestParams);

      setParticipantsPage(data.pageable.pageNumber + 1);
      setHasNextPage(!data.last);
      setParticipantsItems([...participants, ...data.content]);
      setIsFetching(false);
    } catch (err) {
      console.log('error', err);
      setHasNextPage(false);
      setIsFetching(false);
    }
  };

  const handleScrollEvent = throttle(async () => {
    if (divParticipantsRef.current) {
      const { scrollHeight, scrollTop, offsetHeight } = await divParticipantsRef.current;
      const currentPosition = scrollTop + offsetHeight;
      const triggerPosition = scrollHeight - 40;
      if (currentPosition >= triggerPosition && (!isFetching || hasNextPage)) {
        handleNext();
      }
    }
  }, 200);

  const firstFetch = async () => {
    try {
      if (!challenge) return;
      setParticipantsLoading(true);
      const requestParams: { challengeUUID?: string; templateChallengeUUID?: string; page: number; size: number } = {
        ...(challenge.metadata.challengeUUID
          ? { challengeUUID: challenge?.metadata.challengeUUID }
          : { templateChallengeUUID: challenge.metadata.templateChallengeUUID }),
        page: 0,
        size: SIZE_PAGE,
      };

      const { data } = await getChallengeParticipants(requestParams);

      setHasNextPage(!data.last);
      setParticipantsItems(data.content);
      setParticipantsPage(data.pageable.pageNumber + 1);
      if (divParticipantsRef.current && !data.last) {
        const { scrollHeight, scrollTop, offsetHeight } = await divParticipantsRef.current;
        const currentPosition = scrollTop + offsetHeight;
        const triggerPosition = scrollHeight - 40;
        if (currentPosition >= triggerPosition) {
          handleNext();
        }
      }
      setParticipantsLoading(false);
    } catch (err) {
      console.log(err);
      setParticipantsLoading(false);
    }
  };

  useEffect(() => {
    if (!challenge) return;
    const subscribe = setTimeout(firstFetch, 500);
    return () => clearTimeout(subscribe);
  }, [visible, challenge]);

  const handleUpdateParticipants = async () => await firstFetch();

  return {
    participants,
    participantsLoading,
    isFetching,
    divParticipantsRef,
    handleScrollEvent,
    handleUpdateParticipants,
  };
};
