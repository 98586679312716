import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import { AWARD_TRIGGER } from '../../../models/enum/AWARD_TRIGGER';
import { CHALLENGE_TYPE } from '../../../models/enum/CHALLENGE_TYPE';
import { LeaderBoardItemData, LeaderBoardType } from '../../../models/Shared';
import { COMPETITION } from '../../../models/enum/COMPETITION';
import { throttle } from 'lodash';
import { fetchNextPageLeaderBoard } from '../../../redux/slices/challengeProgressSlice';

export const useHandleLeaderBoard = () => {
  const [visible, setVisible] = useState(false);

  const dispatch: AppDispatch = useDispatch();

  const isLoading = useSelector((state: RootState) => state.challengeProgress.meta.isLoading);
  const { user, leaderBoard, previousUser, progress, award, detail, duration, navigation } = useSelector(
    (state: RootState) => state.challengeProgress.data,
  );
  const isFetching = useSelector((state: RootState) => state.challengeProgress.meta.isFetching);

  const openModal = () => setVisible(true);
  const closeModal = () => setVisible(false);

  const listRef = useRef<HTMLUListElement>(null);

  const placeInList = leaderBoard?.list?.findIndex((item) => item.uuid === user?.userUUID) ?? -1;

  const handleScrollEvent = throttle(() => {
    if (listRef.current) {
      const { scrollHeight, scrollTop, offsetHeight } = listRef.current;
      const currentPosition = scrollTop + offsetHeight;
      const triggerPosition = scrollHeight - 40;
      if (currentPosition >= triggerPosition && !isFetching) {
        fetchNextPage();
      }
    }
  }, 300);

  const fetchNextPage = () => {
    if (!navigation || !leaderBoard || leaderBoard.isLastPage) return;
    dispatch(fetchNextPageLeaderBoard({ challengeUUID: navigation.uuid, page: (leaderBoard.page + 1).toString() }));
  };

  const outLeaderBoardList: [LeaderBoardItemData, LeaderBoardItemData] | undefined =
    previousUser && user && (placeInList < 0 || placeInList > 4)
      ? [
          {
            place: previousUser.position,
            score: previousUser.score,
            name: previousUser.name,
            isAwarded: previousUser.isAward ?? false,
            avatar: previousUser.avatar,
            uuid: previousUser.uuid,
          },
          {
            place: user.position,
            score: progress?.teamScore ?? progress?.score ?? 0,
            name: user.name,
            isAwarded: award?.getAward ?? false,
            avatar: user.avatar,
            uuid: user.userUUID,
          },
        ]
      : undefined;

  const type: LeaderBoardType =
    detail?.challengeTyp === CHALLENGE_TYPE.TARGET && detail?.awardTrigger !== AWARD_TRIGGER.FASTEST
      ? 'Target'
      : detail?.awardTrigger === AWARD_TRIGGER.TOP || detail?.awardTrigger === AWARD_TRIGGER.FASTEST
      ? 'Top'
      : 'Completion';

  return {
    isLoading,
    visible,
    leaderBoardList: leaderBoard?.list ?? [],
    position: user?.position ?? detail?.participantsNumber ?? 0,
    userUUID: user?.userUUID,
    outLeaderBoardList,
    lastSync: detail?.leaderBoardSync ?? new Date().toISOString(),
    participantsNumber: detail?.participantsNumber ?? 0,
    target:
      detail?.awardTrigger === AWARD_TRIGGER.COMPLETION || detail?.awardTrigger === AWARD_TRIGGER.FASTEST
        ? detail?.competition === COMPETITION.INDIVIDUAL
          ? progress?.daysGoalValue ?? user?.target
          : user?.target
        : undefined,
    type,
    isGracePeriodEnd: duration?.isGracePeriodEnd ?? false,
    triggerLimit: award?.triggerLimit,
    isTeam: detail?.isTeam ?? false,
    openModal,
    closeModal,
    listRef,
    handleScrollEvent,
  };
};
