import { Box } from '@mui/material';
import React, { useState } from 'react';
import { Calendar } from 'react-date-range';
import { Colors } from '../../../theme/colors';

import './BooleanLogDataModal.css';
import { CalendarTimelineItem } from '../types';
import { RoundIconButton } from '../../../components/features/RoundIconButton';
import { ReactComponent as TeamIcon } from '../../../assets/svg/feature/team.svg';
import { ReactComponent as UserIcon } from '../../../assets/svg/feature/avatar.svg';
import { CalendarDayItemBoolean } from './CalendarDayItem';
import { CustomDialog } from '../../../components/features/CustomDialog';
import moment from 'moment';

type BooleanLogDataModal = {
  visible?: boolean;
  date?: Date;
  timelineScore: CalendarTimelineItem[];
  teamTimelineScore: CalendarTimelineItem[];
  startDate?: string;
  endDate?: string;
  isTeam?: boolean;
  showDate?: Date;
  maxCount?: number;
  onClose: () => void;
  handleUpdateHabit: (date: string, isSuccess: boolean) => () => void;
};

export const BooleanLogDataModal: React.FC<BooleanLogDataModal> = ({
  date,
  visible = false,
  timelineScore,
  teamTimelineScore,
  startDate,
  endDate,
  isTeam = false,
  showDate,
  maxCount,
  onClose,
  handleUpdateHabit,
}) => {
  const [type, setType] = useState<'you' | 'team'>('you');
  const handleClose = () => {
    setTimeout(() => setType('you'), 400);
    onClose();
  };
  return (
    <CustomDialog open={visible} onClose={handleClose} label={isTeam ? 'Challenge data' : 'Calendar'}>
      {isTeam && (
        <Box>
          <Box
            sx={{
              height: {
                sm: 44,
              },
              display: 'flex',
              borderRadius: '30px',
              alignItems: {
                xs: 'flex-start',
                sm: 'center',
              },
              p: '4px',
              alignSelf: {
                xs: 'stretch',
              },
              width: 'fit-content',
              mr: 'auto',
              ml: 'auto',
              mb: '16px',
              backgroundColor: Colors.gray[100],
            }}
          >
            <RoundIconButton
              sx={{
                alignSelf: { xs: 'stretch', sm: 'unset' },
              }}
              Icon={UserIcon}
              size={36}
              background={Colors.green[400]}
              roundColor={Colors.green[600]}
              roundBorderColor={Colors.green[200]}
              label="You"
              isDisable={type !== 'you'}
              onClick={() => setType('you')}
            />
            <Box
              sx={{
                height: 24,
                width: '1px',
                ml: '10px',
                mr: '10px',
                background: Colors.gray[150],
              }}
            />
            <RoundIconButton
              sx={{
                alignSelf: { xs: 'stretch', sm: 'unset' },
              }}
              Icon={TeamIcon}
              size={36}
              background={Colors.magenta[300]}
              roundColor={Colors.magenta[500]}
              roundBorderColor={Colors.magenta[200]}
              label="Team"
              isDisable={type !== 'team'}
              onClick={() => setType('team')}
            />
          </Box>
        </Box>
      )}
      <Calendar
        className="booleanLogData"
        date={date}
        weekStartsOn={1}
        scroll={{ monthHeight: 480 }}
        shownDate={showDate}
        dayContentRenderer={(props) => (
          <CalendarDayItemBoolean
            focusDate={date}
            day={props}
            timelineScore={type === 'you' ? timelineScore : teamTimelineScore}
            startRange={startDate}
            endRange={moment(endDate).isAfter(moment(), 'days') ? new Date().toISOString() : endDate}
            handleUpdateHabit={type === 'team' ? () => () => null : handleUpdateHabit}
            maxCount={type === 'team' ? maxCount : undefined}
          />
        )}
      />
    </CustomDialog>
  );
};
