import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { useHandleMainLayout } from '../hooks/useHandleMainLayout';
import { AwardCardLayout } from '../components/AwardCardLayout';
import { ProgressCardLayout } from '../components/ProgressCard';
import { RankingCardLayout } from '../components/RankingCardLayout';
import { ActionButtonsLayout } from '../components/ActionButtons';
import theme from '../../../theme';
import { RemindChallengeDayLayout } from '../components/RemindChallengeDayLayout';
import { CHALLENGE_TYPE } from '../../../models/enum/CHALLENGE_TYPE';
import { DailyProgressLayout } from '../components/DailyProgress';
import { BooleanCalendarLayout } from '../components/BooleanCalendar';
import { TeamDailyProgressLayout } from '../components/TeamDailyProgress';
import { CALCULATION_TYPE } from '../../../models/enum/CALCULATION_TYPE';
import { ProgressWrapper } from '../../../components/features/progress/ProgressWrapper';

export const MainLayout: React.FC = () => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const tableDown = useMediaQuery(theme.breakpoints.down('table'));
  const { detail, isSelf, isFullScreen, layoutVariant } = useHandleMainLayout();

  return (
    <ProgressWrapper table={isSelf && isFullScreen ? 12 : 7}>
      <RemindChallengeDayLayout />

      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexWrap: { xs: 'wrap', md: 'unset' },
          background: 'white',
          padding: { xs: '20px 24px', sm: '18px 24px 25px' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginRight: { xs: 0, ...(isFullScreen ? { table: '16px' } : { md: '16px' }) },
            width: '100%',

            ...(isSelf && isFullScreen
              ? { flex: 1 }
              : {
                  flex: { xs: 'unset', md: '1 1 160px' },
                }),
            marginBottom: { xs: '24px', md: 0 },
          }}
        >
          <RankingCardLayout variant={isFullScreen ? 'lg' : 'md'} />
          {((!mdDown && !isFullScreen) || (!tableDown && isFullScreen)) &&
            (layoutVariant === 'boolean' ? (
              !isFullScreen ? (
                <AwardCardLayout type="sm" />
              ) : null
            ) : (
              <ActionButtonsLayout variant={isFullScreen ? 'lg' : 'md'} />
            ))}
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            ...(isSelf && isFullScreen
              ? { flex: { xs: 'unset', table: 2 }, flexWrap: 'wrap' }
              : { flex: { xs: 'unset', md: 6 }, flexWrap: 'wrap' }),
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: { xs: '100%', ...(isFullScreen ? { table: 'unset' } : { md: 'unset' }) },
              flex: { xs: 'unset', ...(isFullScreen ? { table: 1 } : { md: 1 }) },
              ...((detail?.challengeTyp === CHALLENGE_TYPE.STICK_TO_IT ||
                detail?.challengeTyp === CHALLENGE_TYPE.LONGER_STREAK) &&
                isFullScreen && { minWidth: { xs: 'unset', md: detail?.isBoolean ? '396px' : '380px' } }),
              ...(isFullScreen && { marginRight: { md: '16px' } }),
            }}
          >
            <ProgressCardLayout variant={isFullScreen && layoutVariant === 'numeric' ? 'lg' : 'md'} />
            <>
              {layoutVariant === 'boolean' && (
                <BooleanCalendarLayout sx={{ ...(mdDown && { marginBottom: '16px' }) }} />
              )}
              {layoutVariant === 'daily' && (
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', table: 'row' }, alignSelf: 'stretch' }}>
                  {isSelf && isFullScreen ? (
                    <DailyProgressLayout
                      sx={{ ...(isFullScreen && mdDown && { marginBottom: '16px' }) }}
                      variant="lg"
                    />
                  ) : (
                    <>
                      <Box
                        sx={{
                          flex: 1,
                          marginRight: { xs: 0, table: '8px' },
                          marginBottom: { xs: '16px', table: 'unset' },
                        }}
                      >
                        {detail?.isTeam && detail?.calculationType === CALCULATION_TYPE.NONE ? (
                          <TeamDailyProgressLayout />
                        ) : (
                          <DailyProgressLayout />
                        )}
                      </Box>
                      <Box sx={{ flex: 1, marginLeft: { xs: 0, table: '8px' } }}>
                        <AwardCardLayout type="md" />
                      </Box>
                    </>
                  )}
                </Box>
              )}
              {layoutVariant === 'numeric' && !isFullScreen && <AwardCardLayout />}
            </>
          </Box>
          {isFullScreen && (
            <Box sx={{ display: 'flex', flex: { xs: 'unset', md: 1 }, width: '100%' }}>
              <AwardCardLayout type="xl" />
            </Box>
          )}
        </Box>
        {((mdDown && !isFullScreen) || (tableDown && isFullScreen)) && (
          <Box sx={{ width: '100%' }}>
            {layoutVariant === 'boolean' ? (
              !isFullScreen && <AwardCardLayout />
            ) : (
              <>
                <ActionButtonsLayout />
              </>
            )}
          </Box>
        )}
      </Box>
    </ProgressWrapper>
  );
};
