import React, { useState } from 'react';
import { Box, Tooltip, Typography, alpha, useMediaQuery } from '@mui/material';
import { Colors } from '../../../theme/colors';
import { InfoOutlined } from '@mui/icons-material';
import theme from '../../../theme';

const chartColors = ['#DA4E53', '#16CF84', '#16BECF', '#FAAF1F', '#C154B0', '#D9C145', '#A7B0B5', '#9D45D9'];

const polarToCartesian = (x: number, y: number, r: number, degrees: number) => {
  const radians = (degrees * Math.PI) / 180.0;
  return [x + r * Math.cos(radians), y + r * Math.sin(radians)];
};

const segmentPath = (x: number, y: number, r0: number, r1: number, d0: number, d1: number) => {
  const arc = Math.abs(d0 - d1) > 180 ? 1 : 0;
  const point = (radius: number, degree: number) =>
    polarToCartesian(x, y, radius, degree)
      .map((n) => n.toPrecision(5))
      .join(',');
  return [
    `M${point(r0, d0)}`,
    `A${r0},${r0},0,${arc},1,${point(r0, d1)}`,
    `L${point(r1, d1)}`,
    `A${r1},${r1},0,${arc},0,${point(r1, d0)}`,
    'Z',
  ].join('');
};

const plotPoint = (radius: number, numberOfPoints: number, idx: number) => {
  const stepDegree = 360 / numberOfPoints;
  const pointStart = 180 + stepDegree / 2;
  const pointDegree = pointStart + idx * stepDegree;
  const theta = (Math.PI * (pointDegree >= 360 ? pointDegree - 360 : pointDegree)) / 180;

  const x = radius - radius * Math.sin(theta);
  const y = radius + radius * Math.cos(theta);
  return { top: y + 'px', left: x + 'px', transform: `rotate(${stepDegree / 2 + stepDegree * idx}deg)` };
};

const CircleOfFifthsWedge = ({
  x,
  y,
  r0,
  r1,
  d0,
  d1,
  onClick,
  onTouchStart,
  onTouchEnd,
  onTouchMove,
  opacity = 1,
  fill = '#ffffff',
  stroke,
}: {
  x: number;
  y: number;
  r0: number;
  r1: number;
  d0: number;
  d1: number;
  fill?: string;
  stroke?: string;
  opacity?: number;
  onClick?: () => void;
  onTouchStart?: React.TouchEventHandler<SVGPathElement>;
  onTouchEnd?: React.TouchEventHandler<SVGPathElement>;
  onTouchMove?: React.TouchEventHandler<SVGPathElement>;
}) => {
  return (
    <path
      d={segmentPath(x, y, r0, r1, d0, d1)}
      stroke={stroke}
      fill={alpha(fill, opacity)}
      onClick={onClick}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onTouchMove={onTouchMove}
    />
  );
};

type LifeBalanceChartProps = {
  values: number[];
  newValues: number[];
  labels: { label: string; description: string }[];
  onChange?: (idx: number, val: number) => () => void;
};

export const LifeBalanceChart: React.FC<LifeBalanceChartProps> = ({ values, newValues, labels, onChange }) => {
  const [startPosition, setStartPosition] = useState<{ x: number; y: number } | undefined>();
  const [changedValues, setChangedValues] = useState<number[]>([]);
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const onTouchStart: React.TouchEventHandler<SVGPathElement> = (e) => {
    setStartPosition({ x: e.touches[0].clientX, y: e.touches[0].clientY });
    setChangedValues(newValues);
  };
  const createOnTouchMove: (idx: number, item: number) => React.TouchEventHandler<SVGPathElement> = (idx) => (e) => {
    if (!startPosition) return;
    const x = Math.round((startPosition.x - e.touches[0].clientX) / 11);
    const y = Math.round((startPosition.y - e.touches[0].clientY) / 11);
    let newValue = changedValues[idx];
    if (y !== 0) {
      if (idx === 0 || idx === 7) {
        newValue = changedValues[idx] + y;
      }
      if (idx === 3 || idx === 4) {
        newValue = changedValues[idx] - y;
      }
    }
    if (x !== 0) {
      if (idx === 1 || idx === 2) {
        newValue = changedValues[idx] - x;
      }
      if (idx === 5 || idx === 6) {
        newValue = changedValues[idx] + x;
      }
    }
    if (!onChange) return;
    if (newValue < 0) {
      onChange(idx, 0)();
      return;
    }
    if (newValue > 10) {
      onChange(idx, 10)();
      return;
    }
    onChange(idx, newValue)();
  };
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: { xs: '260px', sm: '320px' },
        width: { xs: '260px', sm: '320px' },
      }}
    >
      {labels &&
        labels.map((item, idx) => (
          <Box
            key={idx}
            sx={{
              position: 'absolute',
              zIndex: 100,
              ...plotPoint(smDown ? 130 : 160, 8, idx),
              textAlign: 'center',
              transformOrigin: 'center',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                top: '50%',
                left: '50%',
                width: { xs: '90px', sm: '120px' },
                transform: `translate(-50%, -50%)${idx > 1 && idx < 6 ? ' rotate(180deg)' : ''}`,
              }}
            >
              <Typography sx={{ fontSize: '10px', fontWeight: 'bold', lineHeight: '1.1' }}>{item.label}</Typography>
              <Tooltip
                enterTouchDelay={0}
                title={item.description ? <Typography variant={'body2'}>{item.description}</Typography> : ''}
              >
                <InfoOutlined sx={{ fontSize: '10px', ml: '4px' }} />
              </Tooltip>
            </Box>
          </Box>
        ))}
      <Box sx={{ position: 'relative', height: { xs: '231px', sm: '267px' }, width: { xs: '231px', sm: '267px' } }}>
        <Box
          sx={{
            position: 'absolute',
            zIndex: 10,
            top: 0,
            left: '50%',
            bottom: '50%',
            transform: 'translate(-50%, -5px)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          {new Array(10).fill(0).map((_, idx) => (
            <Box
              key={idx}
              component="span"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '11px',
                width: '20px',
                background: 'rgba(255, 255, 255, 0.50)',
                borderRadius: '5px',
                fontSize: '10px',
                textAlign: 'center',
                color: Colors.gray[700],
                fontWeight: '700',
              }}
            >
              {10 - idx}
            </Box>
          ))}
        </Box>
        <svg
          className="m-auto text-lime-900"
          version="1.1"
          width="100%"
          height="100%"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 400 400"
          style={{ rotate: '-90deg' }}
        >
          {new Array(10).fill(0).map((item, idx) => {
            return new Array(8).fill(0).map((v, i) => {
              return (
                <CircleOfFifthsWedge
                  key={i}
                  x={200}
                  y={200}
                  r0={20 * (idx + 1)}
                  r1={20 * idx}
                  d0={i * 45}
                  d1={(i + 1) * 45 + 1}
                  stroke="#E2E5E6"
                />
              );
            });
          })}
          {values.map((item, idx) => {
            return (
              <CircleOfFifthsWedge
                key={`val-${idx}`}
                x={200}
                y={200}
                r0={0}
                r1={20 * item}
                d0={idx * 45}
                d1={(idx + 1) * 45}
                stroke="white"
                opacity={0.5}
                fill={chartColors[idx]}
              />
            );
          })}
          {newValues.map((item, idx) => {
            return (
              <CircleOfFifthsWedge
                key={`val-${idx}`}
                x={200}
                y={200}
                r0={0}
                r1={20 * item}
                d0={idx * 45}
                d1={(idx + 1) * 45}
                opacity={0.5}
                fill={chartColors[idx]}
                stroke="white"
              />
            );
          })}
        </svg>
        <svg
          width="100%"
          height="100%"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 400 400"
          style={{
            rotate: '-90deg',
            touchAction: 'none',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 10,
          }}
        >
          {new Array(10).fill(0).map((item, idx) => {
            return new Array(8).fill(0).map((v, i) => {
              return (
                <CircleOfFifthsWedge
                  key={i}
                  x={200}
                  y={200}
                  r0={20 * (idx + 1)}
                  r1={20 * idx}
                  d0={i * 45}
                  d1={(i + 1) * 45 + 1}
                  opacity={0}
                  onClick={onChange && onChange(i, idx + 1)}
                  onTouchStart={onTouchStart}
                  onTouchMove={createOnTouchMove(i, idx + 1)}
                />
              );
            });
          })}
        </svg>
      </Box>
    </Box>
  );
};
