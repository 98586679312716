import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { fetchChallenge } from '../../../redux/slices/challengeSlice';
import moment from 'moment';
import { daysConverter } from '../../../utils/daysConverter';
import { CHALLENGE_SUB_CATEGORY } from '../../../models/enum/CHALLENGE_SUB_CATEGORY';
import { CHALLENGE_STATUS } from '../../../models/enum/CHALLENGE_STATUS';
import { AWARD_TYPE } from '../../../models/enum/AWARD_TYPE';
import { Range } from 'react-date-range';
import { TRACKING_TYPE } from '../../../models/enum/TRACKING_TYPE';
import { publishFullTemplateChallenge, updateChallenge } from '../../../lib/api/http/requests/challengeAdmin';
import { handleBackendError } from '../../../utils/handleBackendError';
import { useIonRouter } from '@ionic/react';
import { CHALLENGE_TYPE } from '../../../models/enum/CHALLENGE_TYPE';
import { Capacitor } from '@capacitor/core';

type Props = {
  id: string;
};
const pageOptionsByType = {
  ONGOING: {
    label: 'Ongoing challenges',
    challengeStatus: CHALLENGE_STATUS.ACTIVE,
  },
  OPEN_TO_JOIN: {
    label: 'Starting soon challenges',
    challengeStatus: CHALLENGE_STATUS.INACTIVE,
  },
  FINISHED: { label: 'Finished challenges', challengeStatus: CHALLENGE_STATUS.COMPLETED },
};
export type EditForm = {
  imageUrl: string | null;
  imageBase64: string | null;
  title: string;
  shortDescription: string;
  totalGoal: string | null;
  numberOfDays: string | null;
  dailyGoal: string | null;
  awardType: AWARD_TYPE | null;
  awardTitle: string | null;
  points: string | null;
  badgeImageUrl: string | null;
  badgeImageBase64: string | null;
  startDate: string | null;
  endDate: string | null;
  trackingType: TRACKING_TYPE | null;
  teamSize: string | null;
  winners: string | null;
  slackUrl: string | null;
};

const InitForm = {
  imageUrl: null,
  imageBase64: null,
  title: '',
  shortDescription: '',

  totalGoal: null,
  numberOfDays: null,
  dailyGoal: null,
  awardType: null,
  awardTitle: null,
  points: null,
  badgeImageUrl: null,
  badgeImageBase64: null,

  startDate: null,
  endDate: null,
  trackingType: null,
  teamSize: null,
  winners: null,
  slackUrl: null,
};
export const useHandle = (params: Props) => {
  const dispatch = useDispatch();
  const router = useIonRouter();
  const [form, setForm] = useState<EditForm>(InitForm);
  const [isPublish, setIsPublish] = useState(false);
  const { challenge } = useSelector((state: RootState) => state.challenge.data);
  const { challengeLoading } = useSelector((state: RootState) => state.challenge.meta);
  const [range, setRange] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const handleUpdateImage = (value: string) => setForm((prevForm) => ({ ...prevForm, imageBase64: value }));
  const handleChangeTitle = (value: string) => setForm({ ...form, title: value });
  const handleChangeShortDescription = (value: string) => setForm({ ...form, shortDescription: value });
  const handleUpdateBadgeImage = (value: string | null) => {
    setForm((prevForm) => ({ ...prevForm, badgeImageBase64: value }));
  };
  const handleUpdateAwardType = (value: AWARD_TYPE | null) => setForm({ ...form, awardType: value });
  const handleUpdateAwardTitle = (value: string) => setForm({ ...form, awardTitle: value });
  const handleUpdateTotalGoal = (value: string) => setForm({ ...form, totalGoal: value });
  const handleChangeRange = (range: Range[]) => {
    setRange(range);

    const startDate = moment(range[0].startDate).format('YYYY-MM-DD').toString();
    const endDate = moment(range[0].endDate).format('YYYY-MM-DD').toString();
    setForm({ ...form, startDate, endDate });
  };

  const handleChangeTeamSize = (teamSize: string) => {
    setForm({ ...form, teamSize });
  };
  const handleChangeWinners = (winners: string) => {
    setForm({ ...form, winners });
  };
  const handleChangeSlackUrl = (slackUrl: string) => {
    setForm({ ...form, slackUrl });
  };
  const handleChangeTrackingType = (trackingType: TRACKING_TYPE) => {
    setForm({ ...form, trackingType });
  };

  const handleChangeNumberOfDays = (numberOfDays: string) => {
    setForm({ ...form, numberOfDays });
  };
  const handleChangeNumberOfPoints = (points: string) => {
    setForm({ ...form, points });
  };
  const handleChangeDailyGoal = (dailyGoal: string) => {
    setForm({ ...form, dailyGoal });
  };

  const challengeStatusBreadcrumbItem =
    !challenge || !challenge.challengeSubCategory || challenge.challengeStatus === CHALLENGE_STATUS.TEMPLATE
      ? []
      : [
          {
            label: pageOptionsByType[challenge.challengeSubCategory].label,
            url: `/page/employees-challenges/${
              challenge.challengeSubCategory === CHALLENGE_SUB_CATEGORY.ONGOING
                ? 'ongoing'
                : challenge.challengeSubCategory === CHALLENGE_SUB_CATEGORY.OPEN_TO_JOIN
                ? 'join'
                : 'finished'
            }`,
          },
        ];
  const templatesBreadcrumbItem =
    !challenge || challenge.challengeStatus !== CHALLENGE_STATUS.TEMPLATE
      ? []
      : [{ label: 'Challenge templates', url: '/page/employees-challenges/challenge-templates' }];
  const detailsPageBreadcrumbItem =
    challenge && challenge.challengeStatus === CHALLENGE_STATUS.TEMPLATE
      ? [
          {
            label: `Detailed: ${challenge.challengeInfo.title}`,
            url: `/page/employees-challenges/challenge-templates/template/${challenge.metadata.templateChallengeUUID}`,
          },
        ]
      : challenge
      ? [
          {
            label: `Detailed: ${challenge.challengeInfo.title}`,
            url: `/page/employees-challenges/challenge/${
              'challengeUUID' in challenge.metadata && challenge.metadata.challengeUUID
                ? challenge.metadata.challengeUUID
                : challenge.metadata.templateChallengeUUID
            }${'challengeUUID' in challenge.metadata && challenge.metadata.challengeUUID ? '' : '?isTemplate=true'}`,
          },
        ]
      : [];

  useEffect(() => {
    if (!params) return;
    const queryParams = new URLSearchParams(window.location.search);
    const isTemplate = queryParams.get('isTemplate');
    const id = params.id;

    dispatch(fetchChallenge({ id: id, isTemplate }));
  }, [params]);
  useEffect(() => {
    if (!challenge) return;

    setForm({
      ...form,
      title: challenge.challengeInfo.title,
      imageUrl: challenge.challengeInfo.imageUrl,
      imageBase64: null,
      shortDescription: challenge.challengeInfo.shortDescription,
      totalGoal:
        challenge.challengeGoal && challenge.challengeGoal.totalGoalValue !== null
          ? String(challenge.challengeGoal.totalGoalValue)
          : null,
      numberOfDays:
        challenge.challengeGoal && challenge.challengeGoal.daysGoalValue !== null
          ? String(challenge.challengeGoal.daysGoalValue)
          : null,
      dailyGoal:
        challenge.challengeGoal && challenge.challengeGoal.goalValue !== null
          ? String(challenge.challengeGoal.goalValue)
          : null,
      awardType:
        challenge.challengeAward && challenge.challengeAward.awards.length
          ? (challenge.challengeAward.awards[0].awardType as AWARD_TYPE)
          : null,
      awardTitle:
        challenge.challengeAward &&
        challenge.challengeAward.awards.length &&
        challenge.challengeAward.awards[0].awardTitle
          ? challenge.challengeAward.awards[0].awardTitle
          : null,
      points:
        challenge.challengeAward &&
        challenge.challengeAward.awards.length &&
        challenge.challengeAward.awards[0].points !== undefined &&
        challenge.challengeAward.awards[0].points !== null
          ? String(challenge.challengeAward.awards[0].points)
          : null,
      badgeImageUrl:
        challenge.challengeAward &&
        challenge.challengeAward.awards.length &&
        challenge.challengeAward.awards[0].badgeImageUrl
          ? challenge.challengeAward.awards[0].badgeImageUrl
          : null,
      badgeImageBase64: null,
      startDate: challenge.challengeDuration.startDate ? challenge.challengeDuration.startDate : null,
      endDate: challenge.challengeDuration.endDate ? challenge.challengeDuration.endDate : null,
      trackingType: challenge.challengeAction.actionSource as TRACKING_TYPE,
      teamSize:
        challenge.challengeParticipation.maxTeamSize !== undefined
          ? String(challenge.challengeParticipation.maxTeamSize)
          : null,
      winners:
        challenge.challengeAward &&
        challenge.challengeAward.awardTriggerLimit !== undefined &&
        challenge.challengeAward.awardTriggerLimit !== null
          ? String(challenge.challengeAward.awardTriggerLimit)
          : null,
      slackUrl: challenge.metadata.slackURL,
    });
    if (challenge.challengeDuration.startDate && challenge.challengeDuration.endDate) {
      const formatString = 'ddd MMM DD YYYY HH:mm:ss';
      const formattedDateStartDate = moment(challenge.challengeDuration.startDate, 'YYYY-MM-DD').format(formatString);

      const formattedDateEndDate = moment(challenge.challengeDuration.endDate, 'YYYY-MM-DD').format(formatString);
      setRange([
        {
          startDate: moment(formattedDateStartDate).toDate(),
          endDate: moment(formattedDateEndDate).toDate(),
          key: 'selection',
        },
      ]);
    } else {
      setRange([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
        },
      ]);
    }
  }, [challenge]);

  const challengeTitle = challenge ? challenge.challengeInfo.title : '';

  const start = moment(challenge?.challengeDuration.startDate, 'YYYY-MM-DD');
  const end = moment(challenge?.challengeDuration.endDate, 'YYYY-MM-DD');

  const days = moment.duration(end.diff(start)).asDays().toFixed(0);
  const durationInDays =
    challenge && challenge.challengeDuration.suggestedDuration
      ? challenge.challengeDuration.suggestedDuration
      : daysConverter(Number(days) + 1);

  const Publish = async () => {
    const requestParams: {
      [key: string]: any;
    } = {};

    const isLeaderboard = challenge && challenge.challengeCharacteristic.challengeType === CHALLENGE_TYPE.LEADERBOARD;

    if (form.title.length) {
      requestParams.title = form.title;
    }
    if (form.shortDescription.length) {
      requestParams.shortDescription = form.shortDescription;
    }
    if (form.trackingType) {
      requestParams.actionSource = form.trackingType;
    }
    if (!isLeaderboard) {
      if (form.totalGoal !== null && form.totalGoal.length) {
        requestParams.totalGoalValue = Number(form.totalGoal);
      }
      if (form.dailyGoal !== null && form.dailyGoal.length) {
        requestParams.goalValue = Number(form.dailyGoal);
      }
      if (form.numberOfDays != null && form.numberOfDays.length) {
        requestParams.daysGoalValue = Number(form.numberOfDays);
      }
    }
    if (form.winners !== null && form.winners.length) {
      requestParams.awardTriggerLimit = Number(form.winners);
    }
    if (form.awardTitle?.length && form.awardType?.length && form.awardType === AWARD_TYPE.OTHER) {
      requestParams.awardTitle = form.awardTitle;
    }
    if (form.points !== null && form.points.length && form.awardType?.length && form.awardType === AWARD_TYPE.POINT) {
      requestParams.points = Number(form.points);
    }
    if (form.awardType?.length) {
      requestParams.awardType = form.awardType;
    }
    if (form.badgeImageUrl?.length && !form.badgeImageBase64) {
      requestParams.badgeImageUrl = form.badgeImageUrl;
    }
    if (form.badgeImageBase64) {
      requestParams.badgeBase64File = {
        file: form.badgeImageBase64,
      };
    }
    if (form.imageUrl?.length && !form.imageBase64) {
      requestParams.challengeImageUrl = form.imageUrl;
    }
    if (form.imageBase64) {
      requestParams.challengeBase64File = {
        file: form.imageBase64,
      };
    }
    if (form.startDate && form.endDate) {
      requestParams.startDate = form.startDate;
      requestParams.endDate = form.endDate;
    }

    if (form.teamSize && form.teamSize.length > 0) {
      requestParams.maxTeamSize = Number(form.teamSize);
    }

    if (form.winners !== null && form.winners.length > 0) {
      requestParams.awardTriggerLimit = Number(form.winners);
    }
    if (form.slackUrl && form.slackUrl.length > 0) {
      requestParams.slackURL = form.slackUrl;
    }

    try {
      setIsPublish(true);
      if (
        challenge &&
        challenge.challengeStatus === CHALLENGE_STATUS.TEMPLATE &&
        challenge.metadata.templateChallengeUUID
      ) {
        await publishFullTemplateChallenge(requestParams, challenge.metadata.templateChallengeUUID);
        router.push('/page/employees-challenges');
        return;
      }
      if (!challenge || !challenge.metadata.challengeUUID) return;
      await updateChallenge(requestParams, challenge.metadata.challengeUUID);
      router.push('/page/employees-challenges');
    } catch (error) {
      console.log(error);
      const err = error as { response: { data: { message: string } } };
      const message = Capacitor.isNativePlatform()
        ? null
        : err && err.response && err.response.data && err.response.data.message
        ? err?.response?.data?.message
        : null;

      if (!error || !message) {
        handleBackendError(error, 'Failed to publish challenge');
        return;
      }
      handleBackendError(error, message);
    } finally {
      setIsPublish(false);
    }
  };

  return {
    challenge,
    challengeLoading,
    challengeTitle,
    durationInDays,
    challengeStatusBreadcrumbItem,
    templatesBreadcrumbItem,
    detailsPageBreadcrumbItem,
    form,
    range,
    isPublish,
    handleUpdateImage,
    handleChangeTitle,
    handleChangeShortDescription,
    handleUpdateBadgeImage,
    handleUpdateAwardType,
    handleUpdateAwardTitle,
    handleUpdateTotalGoal,
    handleChangeRange,
    handleChangeTeamSize,
    handleChangeWinners,
    handleChangeSlackUrl,
    handleChangeTrackingType,
    handleChangeNumberOfDays,
    handleChangeDailyGoal,
    handleChangeNumberOfPoints,
    Publish,
  };
};
