import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getLeaderboardAchievementRequest,
  getLeaderboardExperienceRequest,
  getLeaderboardRewardsRequest,
} from '../../lib/api/http/requests/rewards';
import { UserReward } from '../../models/UserReward';
import { appSlice, setHeaderLoading } from './appSlice';
import { RootState } from '../store';
import { handleBackendError } from '../../utils/handleBackendError';

export interface EmployeesState {
  data: {
    consistency: UserReward[];
    performance: UserReward[];
    consistencyOption: boolean;
    performanceOption: boolean;
  };
  meta: {
    performanceCount: number;
    consistencyCount: number;
    loading: boolean;
  };
}

const initialState: EmployeesState = {
  data: {
    consistency: [],
    performance: [],
    consistencyOption: false,
    performanceOption: false,
  },
  meta: {
    loading: false,
    performanceCount: 0,
    consistencyCount: 0,
  },
};
export const fetchLeaderBoardExperience = createAsyncThunk(
  'employeesRewards/fetchLeaderBoardExperience',
  async (params: { id: string }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setHeaderLoading(true));
      const res = await getLeaderboardExperienceRequest({ id: params.id });
      const data = res.data;
      const consistency = data.content || [];
      const consistencyOption = true;
      const consistencyCount = data.totalElements || 0;
      return { consistency, consistencyOption, consistencyCount };
    } catch (error) {
      handleBackendError(error, 'Failed to fetch Experience Leaderboard');
    } finally {
      thunkAPI.dispatch(setHeaderLoading(false));
    }
  },
);

export const fetchLeaderBoardAchievement = createAsyncThunk(
  'employeesRewards/fetchLeaderBoardAchievement',
  async (params: { id: string }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setHeaderLoading(true));
      const { data } = await getLeaderboardAchievementRequest({ id: params.id });
      const performance = data.content || [];
      const performanceOption = true;
      const performanceCount = data.totalElements || 0;
      return { performance, performanceOption, performanceCount };
    } catch (error) {
      handleBackendError(error, 'Failed to fetch Achievement Leaderboard');
    } finally {
      thunkAPI.dispatch(setHeaderLoading(false));
    }
  },
);

export const fetchLeaderBoard = createAsyncThunk(
  'employeesRewards/fetchLeaderBoard',
  async (params: { id: string }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setHeaderLoading(true));
      const { data } = await getLeaderboardRewardsRequest({ id: params.id });
      const {
        company_performance_rewards_list,
        company_consistency_rewards_list,
        company_consistency_reward_option,
        company_perf_reward_option,
      } = data;
      const performance = company_performance_rewards_list || [];
      const consistency = company_consistency_rewards_list || [];
      const consistencyOption = company_consistency_reward_option || false;
      const performanceOption = company_perf_reward_option || false;

      return { consistency, performance, consistencyOption, performanceOption };
    } catch (error) {
      handleBackendError(error, 'Failed to fetch Leaderboards');
    } finally {
      thunkAPI.dispatch(setHeaderLoading(false));
    }
  },
);

export const fetchMoreLeaderBoardExperience = createAsyncThunk(
  'employeesRewards/fetchMoreLeaderBoardExperience',
  async (params: { id: string; page: number }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setHeaderLoading(true));
      const { data } = await getLeaderboardExperienceRequest({ id: params.id, page: params.page });
      let consistency = data.content || [];
      const consistencyOption = true;
      const consistencyCount = data.totalElements || 0;
      const state: RootState = thunkAPI.getState() as RootState;
      consistency = [...state.employeesRewards.data.consistency, ...consistency];
      return { consistency, consistencyOption, consistencyCount };
    } catch (error) {
      handleBackendError(error, 'Failed to fetch more Experience Leaderboard');
    } finally {
      thunkAPI.dispatch(setHeaderLoading(false));
    }
  },
);

export const fetchMoreLeaderBoardAchievement = createAsyncThunk(
  'employeesRewards/fetchMoreLeaderBoardAchievement',
  async (params: { id: string; page: number }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setHeaderLoading(true));
      const { data } = await getLeaderboardAchievementRequest({ id: params.id, page: params.page });
      let performance = data.content || [];
      const performanceOption = true;
      const performanceCount = data.totalElements || 0;
      const state: RootState = thunkAPI.getState() as RootState;
      performance = [...state.employeesRewards.data.performance, ...performance];
      return { performance, performanceOption, performanceCount };
    } catch (error) {
      handleBackendError(error, 'Failed to fetch more Achievement Leaderboard');
    } finally {
      thunkAPI.dispatch(setHeaderLoading(false));
    }
  },
);

export const employeesRewardsSlice = createSlice({
  name: 'employeesRewards',
  initialState,
  reducers: {
    setPerformance: (state, action) => {
      state.data.performance = action.payload.performance;
      state.meta.performanceCount = action.payload.performanceCount;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLeaderBoard.pending, (state) => {
      state.meta.loading = true;
    });
    builder.addCase(fetchLeaderBoard.fulfilled, (state, action) => {
      state.data.performance = action.payload?.performance || [];
      state.data.consistency = action.payload?.consistency || [];
      state.data.performanceOption = action.payload?.performanceOption || false;
      state.data.consistencyOption = action.payload?.consistencyOption || false;
      state.meta.loading = false;
    });
    builder.addCase(fetchLeaderBoard.rejected, (state) => {
      state.meta.loading = false;
    });
    builder.addCase(fetchLeaderBoardAchievement.pending, (state) => {
      state.meta.loading = true;
    });
    builder.addCase(fetchLeaderBoardAchievement.fulfilled, (state, action) => {
      state.data.performance = action.payload?.performance || [];
      state.data.performanceOption = action.payload?.performanceOption || false;
      state.meta.performanceCount = action.payload?.performanceCount || 0;
      state.meta.loading = false;
    });
    builder.addCase(fetchLeaderBoardAchievement.rejected, (state) => {
      state.meta.loading = false;
    });
    builder.addCase(fetchLeaderBoardExperience.pending, (state) => {
      state.meta.loading = true;
    });
    builder.addCase(fetchLeaderBoardExperience.fulfilled, (state, action) => {
      state.data.consistency = action.payload?.consistency || [];
      state.data.consistencyOption = action.payload?.consistencyOption || false;
      state.meta.consistencyCount = action.payload?.consistencyCount || 0;
      state.meta.loading = false;
    });
    builder.addCase(fetchLeaderBoardExperience.rejected, (state) => {
      state.meta.loading = false;
    });
    builder.addCase(fetchMoreLeaderBoardAchievement.fulfilled, (state, action) => {
      state.data.performance = action.payload?.performance || [];
      state.meta.performanceCount = action.payload?.performanceCount || 0;
    });
    builder.addCase(fetchMoreLeaderBoardExperience.fulfilled, (state, action) => {
      state.data.consistency = action.payload?.consistency || [];
      state.meta.consistencyCount = action.payload?.consistencyCount || 0;
    });
  },
});

export const { setPerformance } = employeesRewardsSlice.actions;
export default employeesRewardsSlice.reducer;
