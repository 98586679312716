import { throttle } from 'lodash';
import { useState, useEffect, useRef } from 'react';
import useIsPageActive from '../../../hooks/useIsPageActive';
import { ANALYTICS_PAGE } from '../../../models/enum/ANALYTICS_PAGE';
import { createTeam, getTeams } from '../../../lib/api/http/requests/challengeTeam';
import { Team } from '../../../models/Team';
import UserService from '../../../services/UserService';
import { useExportExcel } from '../../../hooks/useExportExcel';
import { getRequest } from '../../../services/HttpService';
import { openToast } from '../../../redux/slices/appSlice';
import { handleBackendError } from '../../../utils/handleBackendError';
import { useDispatch } from 'react-redux';

const SIZE_PAGE = 12;
export type TeamForm = {
  teamName: string;
  imageBase64: string;
  imageUrl?: string;
  slackUrl: string;
};
export const InitFormData = {
  teamName: '',
  imageBase64: '',
  imageUrl: '',
  slackUrl: '',
};
export const useTeamHandle = (challengeUUID: string, isTeamChallenge: boolean, callback?: () => void) => {
  const [items, setItems] = useState<Team[]>([]);
  const [loading, setLoading] = useState(isTeamChallenge);
  const [page, setPage] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const [form, setForm] = useState<TeamForm>(InitFormData);
  const [showTeamModal, setShowTeamModal] = useState(false);
  const [teamModalLoading, setTeamModalLoading] = useState(false);

  const uuid = UserService.getMineId();

  const dispatch = useDispatch();
  const { exportToExcel, exportFileName } = useExportExcel();

  const visible = useIsPageActive(ANALYTICS_PAGE.CHALLENGE_DETAILS);
  const divRef = useRef<HTMLIonContentElement>(null);

  const handleUpdateImage = (value: string) => setForm((prevForm) => ({ ...prevForm, imageBase64: value }));
  const handleChangeTeamName = (value: string) => setForm({ ...form, teamName: value });
  const handleChangeSlackUrl = (slackUrl: string) => {
    setForm({ ...form, slackUrl });
  };
  const handleNext = async () => {
    try {
      if (isFetching || !hasNextPage || loading) return;
      setIsFetching(true);
      const { data } = await getTeams({
        challengeUUID,
        searchText: searchValue,
        page: page,
        size: SIZE_PAGE,
      });
      setPage(data.pageable.pageNumber + 1);
      setHasNextPage(!data.last);
      setItems([...items, ...data.content]);
      setIsFetching(false);
    } catch (err) {
      console.log('error', err);
      setHasNextPage(false);
      setIsFetching(false);
    }
  };

  const handleScrollEvent = throttle(async () => {
    if (divRef.current) {
      const { scrollHeight, scrollTop, offsetHeight } = await divRef.current.getScrollElement();
      const currentPosition = scrollTop + offsetHeight;
      const triggerPosition = scrollHeight - 40;
      if (currentPosition >= triggerPosition && (!isFetching || hasNextPage)) {
        handleNext();
      }
    }
  }, 200);

  const firstFetch = async () => {
    try {
      scrollToTop();
      setLoading(true);
      const { data } = await getTeams({
        challengeUUID,
        searchText: searchValue,
        page: 0,
        size: SIZE_PAGE,
      });
      setHasNextPage(!data.last);
      setItems(data.content);
      setPage(data.pageable.pageNumber + 1);
      if (divRef.current && !data.last) {
        const { scrollHeight, scrollTop, offsetHeight } = await divRef.current.getScrollElement();
        const currentPosition = scrollTop + offsetHeight;
        const triggerPosition = scrollHeight - 40;
        if (currentPosition >= triggerPosition) {
          handleNext();
        }
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const scrollToTop = () => {
    divRef.current && divRef.current.scrollToTop();
  };

  useEffect(() => {
    setLoading(true);
    if (!isTeamChallenge) {
      setLoading(false);
      return;
    }
    const subscribe = setTimeout(firstFetch, 500);
    return () => clearTimeout(subscribe);
  }, [searchValue, visible, isTeamChallenge]);

  const handleUpdateData = async () => {
    await firstFetch();

    if (callback) {
      callback();
    }
  };

  const createTeamRequest = async () => {
    try {
      setTeamModalLoading(true);
      const params = {
        name: form.teamName,
        base64File: {
          file: form.imageBase64,
        },
        teamSlackURL: form.slackUrl,
      };
      await createTeam(challengeUUID, params);
      setTeamModalLoading(false);
      setShowTeamModal(false);
      await firstFetch();
      setForm(InitFormData);
      if (callback) {
        callback();
      }
    } catch (e) {
      setTeamModalLoading(false);
    }
  };

  const handleExport = async () => {
    try {
      const team = items.find((item) => item.createdBy === uuid);
      const teamUUID = team?.teamUUID;
      const teamTitle = team?.name;
      if (!teamUUID) return;
      const response: { data?: string } = await getRequest('/gamification-service/challenge/team/participants/export', {
        teamUUID,
      });
      if (response.data) {
        await exportToExcel(response.data, exportFileName('team-participants-export', teamUUID, teamTitle));
        return;
      }
      dispatch(openToast({ type: 'error', text: 'Export file is empty' }));
    } catch (err) {
      handleBackendError(err, 'Fail to export participants of team');
    }
  };

  const onCloseTeamModal = () => {
    setShowTeamModal(false);
    setForm(InitFormData);
  };

  return {
    items,
    loading,
    isFetching,
    divRef,
    searchValue,
    form,
    showTeamModal,
    teamModalLoading,
    isTeamCreator: items.findIndex((item) => item.createdBy === uuid) >= 0,
    setShowTeamModal,
    handleUpdateImage,
    handleChangeTeamName,
    handleChangeSlackUrl,
    handleScrollEvent,
    handleUpdateData,
    setSearchValue,
    onCloseTeamModal,
    createTeamRequest,
    handleExport,
  };
};
