import { useEffect, useState } from 'react';
import {
  ChallengeNotificationSettingsForm,
  GeneralNotificationSettings,
  NotificationChallengeSettings,
  NotificationSettingsForm,
} from '../../../models/NotificationSettings';
import { getNotificationsSettings } from '../../../lib/api/http/requests/notification';
import useIsPageActive from '../../../hooks/useIsPageActive';
import { ANALYTICS_PAGE } from '../../../models/enum/ANALYTICS_PAGE';

const InitialSettingsForm: NotificationSettingsForm = {
  receive: true,
  email: true,
  push: true,
  updates: true,
  reminders: true,
  general: true,
};
export enum FieldsName {
  RECEIVE = 'receive',
  EMAIL = 'email',
  PUSH = 'push',
  UPDATES = 'updates',
  REMINDERS = 'reminders',
  GENERAL = 'general',
}
export const useSettingsHandle = () => {
  const [generalSettings, setGeneralSettings] = useState<NotificationSettingsForm>(InitialSettingsForm);
  const [challengesSettings, setChallengesSettings] = useState<ChallengeNotificationSettingsForm[] | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAllUpdates, setIsAllUpdates] = useState(true);
  const [isAllReminders, setIsAllReminders] = useState(true);

  const visible = useIsPageActive(ANALYTICS_PAGE.NOTIFICATION_SETTINGS);

  useEffect(() => {
    const getSettings = async () => {
      setIsLoading(true);
      const { data } = await getNotificationsSettings();
      const userChallengeNotificationSettingsDTO: GeneralNotificationSettings =
        data.UserChallengeNotificationSettingsDTO;
      const specificChallengeNotificationInfoList: NotificationChallengeSettings[] =
        data.specificChallengeNotificationInfoList;

      const generalSettingsForm: NotificationSettingsForm = {
        general: userChallengeNotificationSettingsDTO.isGeneralChallengeNotificationEnabled,
        receive: userChallengeNotificationSettingsDTO.isGlobalNotificationEnabled,
        email: userChallengeNotificationSettingsDTO.isMailEnabled,
        push: userChallengeNotificationSettingsDTO.isNotificationEnabled,
        updates: userChallengeNotificationSettingsDTO.isChallengeUpdateEnabled,
        reminders: userChallengeNotificationSettingsDTO.isReminderEnabled,
      };
      setGeneralSettings(generalSettingsForm);

      const challengesSettingsList: ChallengeNotificationSettingsForm[] = specificChallengeNotificationInfoList.map(
        (item) => ({
          challengeUUID: item.challengeUUID,
          title: item.title,
          receive: Boolean(item.isChallengeUpdateEnabled || item.isReminderEnabled),
          email: item.isMailEnabled,
          push: item.isNotificationEnabled,
          updates: item.isChallengeUpdateEnabled,
          reminders: item.isReminderEnabled,
        }),
      );
      setChallengesSettings(challengesSettingsList);
      const allReminderItems = challengesSettingsList.filter((item) => item.reminders);
      const allUpdatesItems = challengesSettingsList.filter((item) => item.updates);
      setIsAllReminders(allReminderItems.length === challengesSettingsList.length);
      setIsAllUpdates(allUpdatesItems.length === challengesSettingsList.length);
      setIsLoading(false);
    };
    try {
      if (!visible) return;
      getSettings();
    } catch (e) {
      setIsLoading(false);
    }
  }, [visible]);
  const handleGeneralSettingsChange = (index: number, name: FieldsName, value: boolean) => {
    const settings = { ...generalSettings };

    if (name === FieldsName.RECEIVE) {
      settings.receive = value;
      settings.general = value;

      if (!value) {
        settings.updates = false;
        settings.reminders = false;
        if (!challengesSettings) return;
        setChallengesSettings(
          challengesSettings.map((item) => ({ ...item, reminders: false, updates: false, receive: false })),
        );
      }
    }
    if (name === FieldsName.EMAIL) {
      settings.email = value;
      if (challengesSettings) {
        setChallengesSettings(challengesSettings.map((item) => ({ ...item, email: value, push: settings.push })));
      }
      setGeneralSettings(settings);
      return;
    }
    if (name === FieldsName.PUSH) {
      settings.push = value;
      if (challengesSettings) {
        setChallengesSettings(challengesSettings.map((item) => ({ ...item, email: settings.email, push: value })));
      }
      setGeneralSettings(settings);
      return;
    }
    if (name === FieldsName.UPDATES) {
      settings.updates = value;

      setIsAllUpdates(value);
      if (challengesSettings) {
        setChallengesSettings(
          challengesSettings.map((item) => ({ ...item, receive: value || item.reminders, updates: value })),
        );
      }
    }
    if (name === FieldsName.REMINDERS) {
      settings.reminders = value;

      setIsAllReminders(value);
      if (challengesSettings) {
        setChallengesSettings(
          challengesSettings.map((item) => ({ ...item, receive: value || item.updates, reminders: value })),
        );
      }
    }
    if (name === FieldsName.GENERAL) {
      settings.general = value;
    }

    if (settings.reminders || settings.updates || settings.general) {
      settings.receive = true;
    }

    if (!settings.reminders && !settings.updates && !settings.general) {
      settings.receive = false;
    }
    if (!settings.reminders && !settings.updates && challengesSettings) {
      setChallengesSettings(
        challengesSettings.map((item) => ({ ...item, receive: false, updates: false, reminders: false })),
      );
    }
    setGeneralSettings(settings);
  };

  const handleChallengeSettingsChange = (index: number, name: FieldsName, value: boolean) => {
    if (!challengesSettings || name === FieldsName.GENERAL) return;
    const data = [...challengesSettings];
    data[index][name] = value;

    if (name === FieldsName.PUSH && value) {
      setGeneralSettings((prevState) => ({
        ...prevState,
        push: true,
      }));
    }

    if (name === FieldsName.EMAIL && value) {
      setGeneralSettings((prevState) => ({
        ...prevState,
        email: true,
      }));
    }

    if (name === FieldsName.RECEIVE && value) {
      data[index].updates = true;
      data[index].reminders = true;

      setGeneralSettings((prevState) => ({
        ...prevState,
        receive: true,
        updates: true,
        reminders: true,
      }));
    }
    if (name === FieldsName.RECEIVE && !value) {
      data[index].updates = false;
      data[index].reminders = false;
    }

    if (name === FieldsName.REMINDERS && !value && !data[index].updates) {
      data[index].receive = false;
    }
    if (name === FieldsName.UPDATES && !value && !data[index].reminders) {
      data[index].receive = false;
    }
    setChallengesSettings(data);

    if (name === FieldsName.RECEIVE || name === FieldsName.REMINDERS || name === FieldsName.UPDATES) {
      const allReceive = data.filter((item) => item.receive);
      if ((allReceive.length && data.length) || generalSettings.general) {
        setGeneralSettings((prevState) => ({
          ...prevState,
          receive: true,
          general: true,
        }));
      }
      if (!allReceive.length && data.length && !generalSettings.general) {
        setGeneralSettings((prevState) => ({
          ...prevState,
          receive: false,
        }));
      }

      const allReminderItems = data.filter((item) => item.reminders);
      const allUpdatesItems = data.filter((item) => item.updates);
      setIsAllReminders(allReminderItems.length === challengesSettings.length);
      setIsAllUpdates(allUpdatesItems.length === challengesSettings.length);

      if (name === FieldsName.REMINDERS && allReminderItems.length <= 0) {
        setGeneralSettings((prevState) => ({
          ...prevState,
          reminders: false,
        }));
      }
      if (name === FieldsName.UPDATES && allUpdatesItems.length <= 0) {
        setGeneralSettings((prevState) => ({
          ...prevState,
          updates: false,
        }));
      }

      if (name === FieldsName.REMINDERS && allReminderItems.length > 0) {
        setGeneralSettings((prevState) => ({
          ...prevState,
          reminders: true,
        }));
      }
      if (name === FieldsName.UPDATES && allUpdatesItems.length > 0) {
        setGeneralSettings((prevState) => ({
          ...prevState,
          updates: true,
        }));
      }
    }
  };

  return {
    isLoading,
    isAllUpdates,
    isAllReminders,
    generalSettings,
    challengesSettings,
    setGeneralSettings,
    setChallengesSettings,
    handleGeneralSettingsChange,
    handleChallengeSettingsChange,
  };
};
