import React from 'react';
import { Grid, IconButton, Snackbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useIonRouter } from '@ionic/react';
import { getRole } from '../../utils/getRole';
import { NOTIFICATION_ACTION_SOURCE } from '../../models/enum/NOTIFICATION_ACTION_SOURCE';
import { setHandleNotificationCallback } from '../../redux/slices/appSlice';

type CustomToastType = {
  open: boolean;
  onClose: () => void;
  message: string;
  category: string;
  challengeUuid: string;
  challengeActionSource: string;
  isUser: boolean;
  isParticipant: boolean;
};

const PushNotificationToast: React.FC<CustomToastType> = ({
  open,
  onClose,
  message,
  category,
  challengeUuid,
  challengeActionSource,
  isUser,
  isParticipant,
}) => {
  const router = useIonRouter();
  const handleClose = () => {
    onClose();
  };

  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleReroute = () => {
    const isAdmin = !isUser;
    switch (category) {
      case 'AABOMD':
      case 'AABOM':
        if (isAdmin) router.push('/page/employees-focus');
        break;
      case 'AUBOW':
      case 'AABOW':
      case 'AUBOMD':
        router.push('/page/your-focus');
        break;
      case 'RAEOM':
        if (isAdmin) router.push('/page/employees-journey');
        break;
      case 'RAEOW':
      case 'RUEOW':
        router.push('/page/your-journey');
        break;
      case 'CJOI':
        // challenge datail page
        router.push(`/page/your-challenges/challenge/${challengeUuid}`);
        break;
      case 'TMIS':
        // challenge datail page
        router.push(`/page/your-challenges/challenge/${challengeUuid}`);
        break;
      case 'CUPD':
        // challenge datail page
        router.push(`/page/your-challenges/challenge/${challengeUuid}?date=${new Date()}`);
        break;
      case 'CACT':
        // challenge datail page
        router.push(`/page/your-challenges/challenge/${challengeUuid}`);
        break;
      case 'DWEE':
        if (challengeActionSource === NOTIFICATION_ACTION_SOURCE.MANUAL) {
          router.push(`/page/your-challenges/challenge/${challengeUuid}/activity`);
        }
        if (
          challengeActionSource === NOTIFICATION_ACTION_SOURCE.AUTO ||
          challengeActionSource === NOTIFICATION_ACTION_SOURCE.HYBRID
        ) {
          router.push('/page/your-profile');
        }
        break;
      case 'UDAL':
        router.push(`/page/your-challenges/challenge/${challengeUuid}/activity`);
        break;
      case 'CWEE':
        // challenge progress page
        router.push(`/page/your-challenges/challenge/${challengeUuid}/activity`);
        break;
      case 'CFIN':
        // challenge activity page
        router.push(`/page/your-challenges/challenge/${challengeUuid}/activity`);
        break;
      case 'CSTR':
        if (isAdmin) {
          router.push(`/page/employees-challenges/challenge/${challengeUuid}/results`);
        } else if (isParticipant) {
          router.push(`/page/your-challenges/challenge/${challengeUuid}/activity`);
        } else {
          router.push(`/page/your-challenges/challenge/${challengeUuid}`);
        }
        break;
      case 'CEND':
        // Insert Admin challenge results page link]
        isAdmin
          ? router.push(`/page/employees-challenges/challenge/${challengeUuid}/results`)
          : router.push(`/page/your-challenges/challenge/${challengeUuid}/activity`);
        break;

      // case 'UTRG':
      // case 'CDEL':
      // case 'TDEL':
      // case 'TTRG':
      //   // nothing to do
      //   break;
    }

    handleClose();
  };

  const getMessage = () => {
    const actualMsg = message.length > 255 ? message.slice(0, 250) + '..' : message;
    return (
      <Grid container wrap={'nowrap'} onClick={handleReroute} alignItems={'center'}>
        <Grid item sx={{ flex: 0, pr: 1 }}>
          <NotificationsIcon />
        </Grid>
        <Grid item>
          <Typography>{actualMsg}</Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      className={'customSnackbar'}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '8px',
          background: '#00B6BE',
          // border: '1px solid #DA4E53F0',
          color: 'white',
          my: '24px',
        },
      }}
      // autoHideDuration={8000}
      onClose={handleClose}
      message={getMessage()}
      action={action}
    />
  );
};

export default PushNotificationToast;
