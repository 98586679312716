import React from 'react';
import { Challenge } from '../../../models/Challenge';
import { TeamDetails } from '../../../models/Team';
import UserService from '../../../services/UserService';
import { Box, useMediaQuery } from '@mui/material';
import { CustomButton } from '../../../components/features/CustomButton';
import { Colors } from '../../../theme/colors';
import theme from '../../../theme';
import { ReactComponent as PlusIcon } from '../../../assets/svg/plus.svg';
import { ReactComponent as MinusIcon } from '../../../assets/svg/feature/minus.svg';

export const Actions: React.FC<{
  challenge: Challenge | null;
  teamDetails: TeamDetails | null;
  daysLeftToJoin: number;
  onJoin: () => void;
  onAbort: () => void;
}> = ({ challenge, teamDetails, daysLeftToJoin, onJoin, onAbort }) => {
  const uuid = UserService.getMineId();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  return challenge &&
    teamDetails &&
    uuid !== teamDetails.createdBy &&
    challenge.challengeParticipation.isUserParticipant ? (
    <Box sx={{ mt: mdDown ? 0 : '40px' }}>
      {challenge &&
        teamDetails &&
        !teamDetails.isUserTeamMember &&
        daysLeftToJoin > 0 &&
        challenge.challengeParticipation.maxTeamSize &&
        !(challenge.challengeParticipation.isUserTeamMember && !teamDetails?.isUserTeamMember) &&
        challenge.challengeParticipation.maxTeamSize > teamDetails.teamSize && (
          <CustomButton
            sx={{
              maxWidth: '100%',
              height: mdDown ? 56 : 48,
              boxShadow: mdDown ? '4px 4px 15px rgba(181, 115, 226, 0.35);' : 'none',
            }}
            minWidth={mdDown ? '118px' : '94px'}
            inputProps={{
              variant: 'contained',
              startIcon: <PlusIcon width={16} height={16} />,
            }}
            type={'bigMagenta'}
            text={'Join'}
            onClick={onJoin}
          />
        )}
      {teamDetails && teamDetails.isUserTeamMember && (
        <CustomButton
          sx={{
            maxWidth: '100%',
            height: mdDown ? 56 : 48,
            boxShadow: mdDown ? '4px 4px 15px rgba(137, 149, 157, 0.15)' : 'none',
          }}
          minWidth={mdDown ? '118px' : '94px'}
          inputProps={{
            variant: 'contained',
            startIcon: <MinusIcon width={16} height={16} fill={Colors.magenta[400]} />,
          }}
          type={'bigLightMagenta'}
          text={'Leave'}
          onClick={onAbort}
        />
      )}
    </Box>
  ) : null;
};
