import { CHALLENGE_STATUS } from '../../../models/enum/CHALLENGE_STATUS';
import { AWARD_LIST } from '../../../models/enum/AWARD_LIST';
import { Challenge } from '../../../models/Challenge';
import { useIonRouter } from '@ionic/react';
import moment from 'moment/moment';
import { daysConverter } from '../../../utils/daysConverter';
import { COMPETITION } from '../../../models/enum/COMPETITION';

export const useHandle = (challenge: Challenge, status: CHALLENGE_STATUS, isAdmin = false) => {
  const router = useIonRouter();

  const daysTitleByStatus =
    status === CHALLENGE_STATUS.ACTIVE && challenge.challengeDuration.daysLeft === 0
      ? 'Last day'
      : (status === CHALLENGE_STATUS.INACTIVE && challenge.challengeDuration.daysLeft === null) ||
        (status === CHALLENGE_STATUS.INACTIVE && !('daysLeft' in challenge.challengeDuration))
      ? 'Open'
      : status === CHALLENGE_STATUS.ACTIVE
      ? 'days left'
      : status === CHALLENGE_STATUS.INACTIVE
      ? 'days to start'
      : 'ended';

  const emptyTrophy = AWARD_LIST[AWARD_LIST.length - 1];
  const formattedDate = () => {
    const parts = challenge.challengeDuration.endDate.split('-');

    return `${parts[2]}.${parts[1]}`;
  };

  const daysLeftByStatus =
    status === CHALLENGE_STATUS.COMPLETED ? formattedDate() : challenge.challengeDuration.daysLeft;

  const handleOpenLink = (e: any) => {
    if (e.target.classList.contains('tooltip-header')) {
      return;
    }
    const uuid =
      challenge.challengeStatus === CHALLENGE_STATUS.TEMPLATE
        ? challenge.metadata.templateChallengeUUID
        : challenge.metadata.challengeUUID;
    const queryParam = challenge.challengeStatus === CHALLENGE_STATUS.TEMPLATE ? '?isTemplate=true' : '';

    if (
      challenge.challengeStatus === CHALLENGE_STATUS.ACTIVE ||
      challenge.challengeStatus === CHALLENGE_STATUS.COMPLETED ||
      challenge.challengeStatus === CHALLENGE_STATUS.IN_GRACE_PERIOD
    ) {
      if (isAdmin) {
        router.push(`/page/employees-challenges/challenge/${uuid}/results`);
        return;
      }
      if (challenge.challengeParticipation.isUserParticipant) {
        if (
          challenge.challengeCharacteristic.competition === COMPETITION.TEAM &&
          !challenge.challengeParticipation.isUserTeamMember
        ) {
          router.push(`/page/your-challenges/challenge/${uuid}${queryParam}`);
          return;
        }
        router.push(`/page/your-challenges/challenge/${uuid}/activity`);
        return;
      }
    }

    if (isAdmin) {
      router.push(`/page/employees-challenges/challenge/${uuid}${queryParam}`);
      return;
    }
    router.push(`/page/your-challenges/challenge/${uuid}${queryParam}`);
  };

  const start = moment(challenge?.challengeDuration.startDate, 'YYYY-MM-DD');
  const end = moment(challenge?.challengeDuration.endDate, 'YYYY-MM-DD');

  const days = moment.duration(end.diff(start)).asDays().toFixed(0);
  const durationInDays =
    challenge && challenge.challengeDuration.suggestedDuration
      ? challenge.challengeDuration.suggestedDuration
      : daysConverter(Number(days) + 1);

  return {
    daysTitleByStatus,
    emptyTrophy,
    daysLeftByStatus,
    durationInDays,
    handleOpenLink,
    formattedDate,
  };
};
