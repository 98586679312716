import { Box, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import { Calendar } from 'react-date-range';
import InputField from '../../../components/features/CustomTextField';
import { CustomButton } from '../../../components/features/CustomButton';
import { Colors } from '../../../theme/colors';
import { ReactComponent as LogIcon } from '../../../assets/svg/feature/log.svg';
import { ReactComponent as AddIcon } from '../../../assets/svg/feature/addIcon.svg';

import './LogDataModal.css';
import { EditCalendarTimelineItem } from '../types';
import theme from '../../../theme';
import { RoundIconButton } from '../../../components/features/RoundIconButton';
import { ReactComponent as TeamIcon } from '../../../assets/svg/feature/team.svg';
import { ReactComponent as UserIcon } from '../../../assets/svg/feature/avatar.svg';
import { CalendarDayItemBoolean, CalendarDayItemNumber } from './CalendarDayItem';
import { CustomDialog } from '../../../components/features/CustomDialog';
import moment from 'moment';
import { METRICS_TYPE } from '../../../models/enum/METRICS_TYPE';

type LogDataModal = {
  visible?: boolean;
  date?: Date;
  metricTitle: string;
  metricType?: string;
  input?: string;
  secondInput?: string;
  timelineScore: EditCalendarTimelineItem[];
  teamTimelineScore: EditCalendarTimelineItem[];
  startDate?: string;
  endDate?: string;
  isTeam?: boolean;
  isSumNone: boolean;
  maxCount?: number;
  disableLogData: boolean;
  onChangeInput: (val: string) => void;
  onChangeSecondInput: (val: string) => void;
  onChangeDate: (date: Date) => void;
  handleAddValue: () => void;
  onClose: () => void;
  handlePlusButton: () => void;
};

export const LogDataModal: React.FC<LogDataModal> = ({
  date,
  visible = false,
  metricTitle,
  metricType,
  input = '',
  secondInput,
  timelineScore,
  teamTimelineScore,
  startDate,
  endDate,
  isTeam,
  isSumNone,
  maxCount,
  disableLogData,
  onChangeDate,
  onClose,
  handleAddValue,
  onChangeInput,
  onChangeSecondInput,
  handlePlusButton,
}) => {
  const [type, setType] = useState<'you' | 'team'>('you');
  const handleClose = () => {
    setTimeout(() => setType('you'), 400);
    onClose();
  };
  const matchXs = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <CustomDialog open={visible} onClose={handleClose} label={isTeam ? 'Challenge data' : 'Log data'}>
      {isTeam && (
        <Box>
          <Box
            sx={{
              height: {
                sm: 44,
              },
              display: 'flex',
              borderRadius: '30px',
              alignItems: {
                xs: 'flex-start',
                sm: 'center',
              },
              p: '4px',
              alignSelf: {
                xs: 'stretch',
              },
              width: 'fit-content',
              mr: 'auto',
              ml: 'auto',
              mb: '16px',
              backgroundColor: Colors.gray[100],
            }}
          >
            <RoundIconButton
              sx={{
                alignSelf: { xs: 'stretch', sm: 'unset' },
              }}
              Icon={UserIcon}
              size={36}
              background={Colors.green[400]}
              roundColor={Colors.green[600]}
              roundBorderColor={Colors.green[200]}
              label="You"
              isDisable={type !== 'you'}
              onClick={() => setType('you')}
            />
            <Box
              sx={{
                height: 24,
                width: '1px',
                ml: '10px',
                mr: '10px',
                background: Colors.gray[150],
              }}
            />
            <RoundIconButton
              sx={{
                alignSelf: { xs: 'stretch', sm: 'unset' },
              }}
              Icon={TeamIcon}
              size={36}
              background={Colors.magenta[300]}
              roundColor={Colors.magenta[500]}
              roundBorderColor={Colors.magenta[200]}
              label="Team"
              isDisable={type !== 'team'}
              onClick={() => setType('team')}
            />
          </Box>
        </Box>
      )}
      <Calendar
        className="logModal"
        date={date}
        weekStartsOn={1}
        onChange={type === 'team' ? () => null : onChangeDate}
        scroll={{ monthHeight: 480 }}
        dayContentRenderer={(props) =>
          isTeam && isSumNone && type === 'team' ? (
            <CalendarDayItemBoolean
              focusDate={date}
              day={props}
              timelineScore={teamTimelineScore}
              startRange={startDate}
              endRange={moment(endDate).isAfter(moment(), 'days') ? new Date().toISOString() : endDate}
              maxCount={maxCount}
            />
          ) : (
            <CalendarDayItemNumber
              focusDate={date}
              day={props}
              timelineScore={type === 'you' ? timelineScore : teamTimelineScore}
              startRange={startDate}
              endRange={moment(endDate).isAfter(moment(), 'days') ? new Date().toISOString() : endDate}
            />
          )
        }
      />
      <Box
        sx={{
          paddingInline: { xs: '16px', md: '40px' },
          marginBottom: '50px',
          marginTop: '24px',
          maxHeight: '160px',
          height: '240px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        {type === 'you' && (
          <Box
            sx={{
              display: 'flex',
              maxWidth: '380px',
            }}
          >
            <Box sx={{ display: 'flex', flex: 1, marginRight: '16px' }}>
              <InputField
                label={
                  metricType === METRICS_TYPE.SLEEP_DURATION
                    ? 'Hours'
                    : metricTitle.charAt(0)?.toUpperCase() + metricTitle.slice(1)
                }
                setValue={(el) => onChangeInput(el.target.value)}
                name="steps"
                type="number"
                value={input}
                placeholder={metricType === METRICS_TYPE.SLEEP_DURATION ? 'Enter hours' : 'Enter number'}
                inputProps={{
                  sx: {
                    ...(metricType === METRICS_TYPE.SLEEP_DURATION && { marginRight: '16px' }),
                    '& .MuiInputLabel-root': {
                      marginTop: '-28px',
                      '&.Mui-disabled': {
                        marginTop: '-28px',
                      },
                    },
                    '& input[type=number]': {
                      '-moz-appearance': 'textfield',
                    },
                    '& input[type=number]::-webkit-outer-spin-button': {
                      '-webkit-appearance': 'none',
                      margin: 0,
                    },
                    '& input[type=number]::-webkit-inner-spin-button': {
                      '-webkit-appearance': 'none',
                      margin: 0,
                    },
                  },
                }}
              />
              {metricType === METRICS_TYPE.SLEEP_DURATION && (
                <InputField
                  label={'Minutes'}
                  setValue={(el) => onChangeSecondInput(el.target.value)}
                  name="steps"
                  type="number"
                  value={secondInput}
                  placeholder="Enter minutes"
                  inputProps={{
                    sx: {
                      '& .MuiInputLabel-root': {
                        marginTop: '-28px',
                        '&.Mui-disabled': {
                          marginTop: '-28px',
                        },
                      },
                      '& input[type=number]': {
                        '-moz-appearance': 'textfield',
                      },
                      '& input[type=number]::-webkit-outer-spin-button': {
                        '-webkit-appearance': 'none',
                        margin: 0,
                      },
                      '& input[type=number]::-webkit-inner-spin-button': {
                        '-webkit-appearance': 'none',
                        margin: 0,
                      },
                    },
                  }}
                />
              )}
            </Box>
            <Box
              sx={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 36,
                width: 36,
                borderRadius: 36,
                background: Colors.blue[500],
              }}
              onClick={handlePlusButton}
            >
              <AddIcon fill={Colors.white} />
            </Box>
          </Box>
        )}
        <Box
          sx={{
            display: { xs: 'block', md: 'flex' },
            justifyContent: 'space-between',
            paddingTop: '16px',
          }}
        >
          {type === 'you' && (
            <CustomButton
              width={matchXs ? 'auto' : '183px'}
              sx={{ marginBottom: '8px', maxHeight: '400px', marginRight: { xs: 'unset', md: '16px' } }}
              type={'bigBlue'}
              startIcon={<LogIcon fill={disableLogData ? Colors.gray[200] : Colors.white} height="16px" width="16px" />}
              text="Log data"
              onClick={handleAddValue}
              disabled={disableLogData}
            />
          )}
          <CustomButton width={'auto'} type={'bigLightBlue'} text="Cancel" onClick={onClose} />
        </Box>
      </Box>
    </CustomDialog>
  );
};
