import Dialog from '@mui/material/Dialog/Dialog';
import { Box, Slide, useMediaQuery } from '@mui/material';
import theme from '../../../theme';
import React from 'react';
import { TransitionProps } from '@mui/material/transitions';

type Props = {
  show: boolean;
};
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const CustomInfoDialog: React.FC<Props> = ({ children, show }) => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Dialog
      keepMounted
      maxWidth={'xs'}
      TransitionComponent={mdDown ? Transition : undefined}
      open={show}
      PaperProps={{
        style: {
          position: 'relative',
          margin: mdDown ? '8px 0 0 0' : '8px',
          borderRadius: '20px',
          borderBottomLeftRadius: mdDown ? 0 : '20px',
          borderBottomRightRadius: mdDown ? 0 : '20px',
          maxHeight: 'calc(100% - 16px)',
          minHeight: '258px',
          width: mdDown ? '100%' : '400px',
          maxWidth: mdDown ? '100%' : '444px',
          boxShadow: '4px 4px 15px rgba(108, 123, 132, 0.15)',
        },
      }}
      sx={{ '& .MuiDialog-container': { alignItems: mdDown ? 'flex-end' : 'center' } }}
    >
      {children}
    </Dialog>
  );
};
