import { useEffect, useState } from 'react';
import { Team } from '../../../models/Team';
import { joinToTeam } from '../../../lib/api/http/requests/challengeTeam';

export const useJoinHandle = (challengeUUID: string, callback?: () => void) => {
  const [joinLoading, setJoinLoading] = useState(false);
  const [joinTeam, setJoinTeam] = useState<Team | null>(null);

  useEffect(() => {
    if (joinLoading) {
      return;
    }
    setJoinTeam(null);
  }, [joinLoading]);
  const joinToTeamRequest = async () => {
    if (!joinTeam) return;

    try {
      setJoinLoading(true);
      await joinToTeam({ challengeUUID: challengeUUID, teamUUID: joinTeam.teamUUID });
      if (callback) {
        callback();
      }
      setJoinLoading(false);
    } catch (e) {
      setJoinLoading(false);
    }
  };
  return {
    joinLoading,
    joinTeam,
    setJoinTeam,
    joinToTeamRequest,
  };
};
