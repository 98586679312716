import { FunctionComponent, SVGProps } from 'react';
import { AWARD_TYPE } from './enum/AWARD_TYPE';

export type PreviewRoundItemProps = {
  value: string;
  label: string;
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  mainColor: string;
  backgroundColor: string;
  borderColor: string;
};

export type FilterFormParams = {
  dimention: string[];
  competition: string[];
  challengeType?: string[];
  metric: string;
  onlyCorporate: boolean;
  publisher: boolean;
};

export type BreadcrumbProps = {
  Icon: React.FunctionComponent<SVGProps<SVGSVGElement>>;
  list: {
    label: string;
    url: string;
  }[];
};

export type AwardType = {
  awardUUID: string;
  awardTitle: string;
  awardDescription: string;
  position: number | null;
  awardType: AWARD_TYPE;
  code: string;
  points: number | null;
  badgeImageUrl?: string | null;
  base64File: {
    file: string;
  } | null;
};

export type ParticipantType = {
  participantUUID: string;
  name: string | null;
  avatar: string | null;
  username: string;
  participationStatus?: string;
};

export type TeamType = {
  name: string;
  imageURL: string | null;
  createdBy: string;
  creationDate: string;
  teamSlackURL: string | null;
  participants?: ParticipantType[];
  teamSize: number;
  teamUUID: string;
};

export type TargetBasedOnChallengeGoalType = {
  goalValue: number;
  totalGoalValue: number;
  daysGoalValue: number;
  goalRepetition: string;
  userProgressTargetValue: number;
};

export type ScoreTimeItemType = {
  date: string;
  value: number;
  status?: string;
  teamValue?: number;
};

export type RankingType = {
  initRemainderToTargetBasedOnChallengeGoal?: TargetBasedOnChallengeGoalType;
  challengeTargetBasedOnChallengeGoal?: TargetBasedOnChallengeGoalType;
  position: number;
  score: number;
  points: number | null;
  scoreboard: number | null;
  remainderToTarget: number;
  completionPercentage: number;
  differenceFromPrevious?: number;
  dailyAverage: number;
  bestScore: number;
  target: number;
  lastUpdate: string | null;
  award: AwardType | null;
  scoreTimeline?: ScoreTimeItemType[];
};

export type LeaderBoardItemData = {
  place: number;
  isAwarded: boolean;
  score: number;
  avatar: string | null;
  name: string;
  uuid: string;
};

export type InstanceItemData = {
  startDate: string;
  endDate: string;
  position: number;
  score: number;
  isAwarded: boolean;
  completionPercentage: number;
  scoreTimeline: ScoreTimeItemType[];
  bestScore?: number;
  dailyAverage?: number;
};

export type LeaderBoardType = 'Top' | 'Completion' | 'Target' | 'None';

export type RangeMap = Array<{
  date: string;
  day: number;
  isToday: boolean;
  isSuccess: boolean;
  isComplete?: boolean;
  label?: string;
}>;

export enum PERIOD {
  WEEK = 'This Week',
  MONTH = 'This Month',
  YEAR = 'This Year',
}
