import React from 'react';
import Grid from '@mui/material/Grid';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { StyledIconButton } from './StyledIconButton';
import { Typography, useMediaQuery } from '@mui/material';
import { Colors } from '../../theme/colors';
import { useIonRouter } from '@ionic/react';
import theme from '../../theme';

type Props = {
  nextChallengeUUID: string | null;
  previousChallengeUUID: string | null;
  title: string;
  isActivity?: boolean;
};
export const ChallengePagination: React.FC<Props> = ({
  title,
  nextChallengeUUID,
  previousChallengeUUID,
  isActivity = false,
}) => {
  const router = useIonRouter();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  if (!nextChallengeUUID && !previousChallengeUUID) return null;
  const handleGoNext = () =>
    router.push(
      isActivity
        ? `/page/your-challenges/challenge/${nextChallengeUUID}/activity`
        : `/page/your-challenges/challenge/${nextChallengeUUID}`,
    );
  const handleGoPrevious = () =>
    router.push(
      isActivity
        ? `/page/your-challenges/challenge/${previousChallengeUUID}/activity`
        : `/page/your-challenges/challenge/${previousChallengeUUID}`,
    );
  return (
    <Grid container wrap={'nowrap'} sx={{ whiteSpace: 'nowrap', width: 'auto' }} alignItems={'center'}>
      <Grid item sx={{ mx: mdDown ? '4px' : 0 }}>
        <StyledIconButton
          sxContainer={{ width: mdDown ? 32 : 28, height: mdDown ? 32 : 28 }}
          disabled={!previousChallengeUUID}
          onClick={handleGoPrevious}
        >
          <ChevronLeftIcon />
        </StyledIconButton>
      </Grid>

      {!mdDown && (
        <Grid
          sx={{
            background: Colors.white,
            borderColor: Colors.gray[100],
            borderWidth: '1px',
            borderStyle: 'solid',
            borderRadius: '30px',
            padding: '8px 16px',
            mx: '10px',
            width: '187px',
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: '600',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {title}
          </Typography>
        </Grid>
      )}
      <Grid item sx={{ ml: mdDown ? '4px' : 0 }}>
        <StyledIconButton
          sxContainer={{ width: mdDown ? 32 : 28, height: mdDown ? 32 : 28 }}
          disabled={!nextChallengeUUID}
          onClick={handleGoNext}
        >
          <ChevronRightIcon />
        </StyledIconButton>
      </Grid>
    </Grid>
  );
};
