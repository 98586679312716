import { Box } from '@mui/material';
import React, { Fragment } from 'react';
import { PreviewRoundItemProps } from '../../../models/Shared';
import { Colors } from '../../../theme/colors';
import { RoundIconButton } from '../RoundIconButton';
import { SxProps } from '@mui/system';

type FilterSelectorProps = {
  data: PreviewRoundItemProps[];
  selected?: string[];
  bgColor?: string;
  disabledRoundColor?: string;
  disabledRoundBorderColor?: string;
  sx?: SxProps;
  roundIconButtonSx?: SxProps;
  roundIconTextSx?: SxProps;
  roundIconSize?: number;
  iconSize?: number;
  tabMode?: boolean;
  oneLineMode?: boolean;
  onClickItem: (value: string, isSelected: boolean) => () => void;
};

export const FilterSelector: React.FC<FilterSelectorProps> = ({
  data,
  selected = [],
  onClickItem,
  bgColor = Colors.gray[100],
  disabledRoundColor,
  disabledRoundBorderColor,
  tabMode,
  oneLineMode,
  sx,
  roundIconButtonSx,
  roundIconSize = 36,
  roundIconTextSx,
  iconSize,
}) => (
  <Box
    sx={{
      height: {
        sm: 44,
      },
      display: 'flex',
      flexDirection: {
        xs: tabMode || oneLineMode ? 'row' : 'column',
        sm: 'row',
      },
      borderRadius: '30px',
      alignItems: {
        xs: tabMode || oneLineMode ? 'center' : 'flex-start',
        sm: 'center',
      },
      p: '4px',
      alignSelf: {
        xs: 'stretch',
      },
      backgroundColor: bgColor,
      ...sx,
    }}
  >
    {data.map((item, idx) => {
      const isSelected = selected.findIndex((selectItem) => selectItem === item.value) >= 0;
      return (
        <Fragment key={item.value}>
          <RoundIconButton
            sx={{
              marginBottom: {
                xs: tabMode || oneLineMode ? 0 : '4px',
                sm: 0,
                width: { xs: tabMode ? '50%' : '100%', sm: 'auto' },
              },
              alignSelf: { xs: 'stretch', sm: 'unset' },
              width: tabMode ? '50%' : 'auto',
              ...roundIconButtonSx,
            }}
            Icon={item.Icon}
            size={roundIconSize}
            iconSize={iconSize}
            background={item.mainColor}
            roundColor={item.backgroundColor}
            roundBorderColor={item.borderColor}
            label={item.label}
            isDisable={!isSelected}
            disableColor={bgColor}
            disabledRoundColor={disabledRoundColor}
            disabledRoundBorderColor={disabledRoundBorderColor}
            onClick={onClickItem(item.value, isSelected)}
            textSx={tabMode ? { fontSize: '12px', mr: '4px', ml: '4px' } : { ...roundIconTextSx }}
          />
          {idx < data.length - 1 && (
            <Box
              sx={{
                display: { xs: tabMode || oneLineMode ? 'block' : 'none', sm: 'block' },
                height: 24,
                width: '1px',
                ml: '10px',
                mr: '10px',
                background: Colors.gray[150],
              }}
            />
          )}
        </Fragment>
      );
    })}
  </Box>
);
