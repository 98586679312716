import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import { Box, Container, Grid, useMediaQuery } from '@mui/material';
import { useHandler } from './hooks/useHandler';
import { TableRedemption } from './components/TableRedemption';
import { CustomButton } from '../../components/features/CustomButton';
import { CatalogModal } from './components/CatalogModal';
import CustomNewHeader from '../../components/features/CustomNewHeader';
import { SearchBar } from './components/SearchBar';
import { ReactComponent as ExchangeIcon } from '../../assets/svg/feature/exchange.svg';
import { ReactComponent as RewardsIcon } from '../../assets/svg/reward.svg';
import { Colors } from '../../theme/colors';
import theme from '../../theme';
import { RedemptionDetailsModal } from './components/RedemptionDetailsModal';

export const StartRedemptionPage: React.FC = () => {
  const {
    catalog,
    openModal,
    modalParams,
    selectedReward,
    selectedBrandId,
    onChangeReward,
    onSubmitReward,
    openCatalogModal,
    closeCatalogModal,
    rowUsers,
    selectedRowUser,
    inputs,
    comment,
    openRedeemModal,
    search,
    sort,
    isUpdating,
    onChangeInputs,
    handleEditUser,
    handleOpenRedeem,
    closeRedeemModal,
    setComment,
    submitOrder,
    onChangeSearch,
    handleFetchNextUsers,
    onChangeSort,
    handleClearSelected,
  } = useHandler();

  const tableDown = useMediaQuery(theme.breakpoints.down('table'));
  return (
    <IonPage>
      <CustomNewHeader
        label={`Start redemption`}
        breadcrumb={{
          Icon: () => <RewardsIcon height={12} width={12} stroke={Colors.gray[400]} />,
          list: [
            { label: "Your Employees' Rewards", url: '/page/employees-rewards' },
            { label: 'Start redemption', url: '/' },
          ],
        }}
        rightAction={
          !tableDown && (
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
              <SearchBar searchInput={search} setSearchInput={onChangeSearch} />
              <CustomButton
                type="bigOrange"
                text="Redeem now"
                sx={{ height: '36px', fontSize: '14px', ml: '16px' }}
                inputProps={{
                  variant: 'contained',
                  startIcon: <ExchangeIcon />,
                }}
                onClick={handleOpenRedeem}
              />
            </Box>
          )
        }
      />
      <IonContent>
        <Container maxWidth="lg" sx={{ height: '100%', pb: '38px' }}>
          {tableDown && (
            <Grid item sx={{ mb: '16px' }}>
              <SearchBar searchInput={search} setSearchInput={onChangeSearch} />
            </Grid>
          )}
          <Grid item display="flex" justifyContent="center" flexDirection="column">
            <TableRedemption
              {...{
                selectedRowUser,
                rowUsers,
                inputs: inputs.current,
                sort,
                onChangeInputs,
                handleEditUser,
                openCatalogModal,
                handleFetchNextUsers,
                onChangeSort,
                handleClearSelected,
              }}
            />
            {tableDown && (
              <Box sx={{ mt: '4px' }}>
                <CustomButton
                  type="bigOrange"
                  text="Redeem now"
                  width="100%"
                  inputProps={{
                    variant: 'contained',
                    startIcon: <ExchangeIcon />,
                  }}
                  onClick={handleOpenRedeem}
                />
              </Box>
            )}
          </Grid>
        </Container>
        <CatalogModal
          {...{
            openModal,
            modalParams,
            closeCatalogModal,
            catalog,
            selectedBrandId,
            selectedReward,
            onChangeReward,
            onSubmitReward,
          }}
        />
        <RedemptionDetailsModal
          openModal={openRedeemModal}
          onChangeComment={setComment}
          {...{ selectedRowUser, comment, closeRedeemModal, isUpdating, inputs: inputs.current, submitOrder }}
        />
      </IonContent>
    </IonPage>
  );
};
