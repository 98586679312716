import { Box, Skeleton, Typography, useMediaQuery, Theme, SxProps } from '@mui/material';
import React, { useRef } from 'react';
import { useHandleDailyProgress } from '../hooks/useHandleDailyProgress';
import { Colors } from '../../../theme/colors';
import { numberWithSpaces } from '../../../utils/numberWithSpace';
import { RadialChart } from './RadialChart';
import ContainerBg from '../../../assets/svg/feature/daily-progress-bg.svg';
import theme from '../../../theme';

const limitMaxPercent = (percent: number) => {
  if (percent > 100) return 100;
  return percent;
};

type Variant = 'lg' | 'md';

type DailyProgressProps = {
  sx?: SxProps<Theme>;
  isLoading?: boolean;
  dailyScore?: number;
  teamDailyScore: number;
  target?: number;
  isTeam?: boolean;
  metricTitle?: string;
  variant?: Variant;
};

export const DailyProgress: React.FC<DailyProgressProps> = ({
  sx,
  isLoading,
  dailyScore = 0,
  teamDailyScore,
  target = 0,
  isTeam,
  metricTitle = '',
  variant = 'md',
}) => {
  const tooltipRef = useRef<HTMLDivElement>(null);

  const handleOpenTooltip = (x: number, y: number, isFocusTeam?: boolean) => {
    if (!tooltipRef.current || !isTeam) return;
    if (dailyScore === teamDailyScore) {
      tooltipRef.current.children[0].innerHTML = `<b>You:</b> ${numberWithSpaces(
        dailyScore,
      )}<br/><b>Team:</b> ${numberWithSpaces(teamDailyScore)}`;
    } else if (isFocusTeam) {
      tooltipRef.current.children[0].innerHTML = `<b>Team:</b> ${numberWithSpaces(teamDailyScore)}`;
    } else {
      tooltipRef.current.children[0].innerHTML = `<b>You:</b> ${numberWithSpaces(dailyScore)}`;
    }
    tooltipRef.current.style.display = 'block';
    tooltipRef.current.style.top = `${y}px`;
    tooltipRef.current.style.left = `${x}px`;
  };
  const handleCloseTooltip = () => {
    if (!tooltipRef.current) return;
    tooltipRef.current.style.display = 'none';
  };

  const tableDown = useMediaQuery(theme.breakpoints.down('table'));
  if (isLoading) {
    return <Skeleton variant="rectangular" sx={{ height: 234, width: '50%', borderRadius: '10px' }} />;
  }
  return (
    <Box
      sx={{
        flex: 1,
        position: 'relative',
        paddingTop: '11px',
        paddingBottom: '21px',
        paddingInline: '14px',
        border: `5px solid #FEF7E9`,
        borderRadius: '20px',
        overflow: 'hidden',
        background: `linear-gradient(180deg, #FEF7E9 0%, #FDE7BC 100%)`,
        ...sx,
      }}
    >
      <img
        src={ContainerBg}
        style={{ position: 'absolute', zIndex: 0, top: 0, left: 0, bottom: 0, right: 0, objectFit: 'cover' }}
      />
      <Box sx={{ position: 'relative' }}>
        <Typography sx={{ fontWeight: 700, fontSize: '14px', color: Colors.gray[700], marginBottom: '16px' }}>
          {isTeam ? 'Team`s daily progress' : 'Daily progress'}
        </Typography>

        {variant === 'md' && (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Typography
              sx={{ fontWeight: 900, fontSize: '16px', color: Colors.gray[700], textAlign: 'center', lineHeight: 1 }}
            >
              {numberWithSpaces(
                isTeam
                  ? teamDailyScore > target
                    ? 0
                    : target - teamDailyScore
                  : dailyScore > target
                  ? 0
                  : target - dailyScore,
              )}
            </Typography>
            <Typography sx={{ fontSize: '10px', color: '#9F9988', textAlign: 'center' }}>
              {metricTitle.charAt(0).toUpperCase() + metricTitle.slice(1)} more to the target
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              table: variant === 'lg' ? 'row-reverse' : 'column',
            },
            ...(variant === 'lg' && {
              justifyContent: 'center',
            }),
          }}
        >
          {variant === 'lg' && (
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Typography
                sx={{ fontWeight: 900, fontSize: '16px', color: Colors.gray[700], textAlign: 'center', lineHeight: 1 }}
              >
                {numberWithSpaces(
                  isTeam
                    ? teamDailyScore > target
                      ? 0
                      : target - teamDailyScore
                    : dailyScore > target
                    ? 0
                    : target - dailyScore,
                )}
              </Typography>
              <Typography sx={{ fontSize: '10px', color: '#9F9988', textAlign: 'center' }}>
                {metricTitle.charAt(0).toUpperCase() + metricTitle.slice(1)} more to the target
              </Typography>
              <Box
                sx={{
                  display: { xs: 'none', table: 'flex' },
                  flexDirection: 'column',
                  marginTop: '24px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    marginBottom: { xs: isTeam ? '8px' : '4px', table: '4px' },
                    alignItems: 'baseline',
                    marginTop: { xs: isTeam ? 'none' : 'auto', table: 'auto' },
                    paddingInline: '4px',
                  }}
                >
                  <Box
                    sx={{
                      height: '8px',
                      width: '8px',
                      borderRadius: '8px',
                      background: `linear-gradient(120deg, ${Colors.orange[500]} 0%, ${Colors.orange[200]} 100%)`,
                      marginRight: '5px',
                      border: `1px solid ${Colors.orange[200]}`,
                    }}
                  />
                  <Typography sx={{ fontSize: '10px', lineHeight: 1, color: '#9F9988' }}>Your {metricTitle}</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    marginBottom: { xs: isTeam ? '8px' : '4px', table: '4px' },
                    alignItems: 'baseline',
                    marginTop: { xs: isTeam ? 'none' : 'auto', table: 'auto' },
                    paddingInline: '4px',
                  }}
                >
                  <Box
                    sx={{
                      height: '8px',
                      width: '8px',
                      borderRadius: '8px',
                      background: Colors.white,
                      marginRight: '5px',
                    }}
                  />
                  <Typography sx={{ fontSize: '10px', lineHeight: 1, color: '#9F9988' }}>
                    {metricTitle.charAt(0).toUpperCase() + metricTitle.slice(1)} left
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 106,
              width: 106,
              ...(variant === 'lg'
                ? { marginLeft: { xs: 'auto', table: 'unset' }, marginRight: { xs: 'auto', table: '24px' } }
                : { marginInline: 'auto' }),
              marginTop: '8px',
              marginBottom: '8px',
            }}
          >
            <Box sx={{ position: 'absolute', zIndex: 10, top: 0, left: 0, height: 106, width: 106 }}>
              <RadialChart
                selfPercent={limitMaxPercent(
                  Math.round(
                    teamDailyScore > target && dailyScore > 0
                      ? (dailyScore / teamDailyScore) * 100
                      : dailyScore > target
                      ? 100
                      : dailyScore === 0
                      ? 0
                      : (dailyScore / target) * 100,
                  ),
                )}
                teamPercent={limitMaxPercent(
                  Math.round(
                    teamDailyScore > target ? 100 : teamDailyScore === 0 ? 0 : (teamDailyScore / target) * 100,
                  ),
                )}
                handleOpenTooltip={handleOpenTooltip}
                handleCloseTooltip={handleCloseTooltip}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: 64,
                width: 64,
                borderRadius: '100%',
                background: Colors.white,
              }}
            >
              <Typography sx={{ fontWeight: 700, fontSize: '12px', color: Colors.gray[700], textAlign: 'center' }}>
                {numberWithSpaces(isTeam ? teamDailyScore : dailyScore)}
              </Typography>
              <Typography sx={{ fontSize: '8px', color: '#9F9988', textAlign: 'center' }}>out of</Typography>
              <Typography sx={{ fontSize: '8px', color: '#9F9988', textAlign: 'center' }}>
                {numberWithSpaces(target)}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: 'flex', table: variant === 'lg' ? 'none' : 'flex' },
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                marginBottom: { xs: isTeam ? '8px' : '4px', table: '4px' },
                alignItems: 'baseline',
                marginTop: { xs: isTeam ? 'none' : 'auto', table: 'auto' },
                paddingInline: '4px',
              }}
            >
              <Box
                sx={{
                  height: '8px',
                  width: '8px',
                  borderRadius: '8px',
                  background: `linear-gradient(120deg, ${Colors.orange[500]} 0%, ${Colors.orange[200]} 100%)`,
                  marginRight: '5px',
                  border: `1px solid ${Colors.orange[200]}`,
                }}
              />
              <Typography sx={{ fontSize: '10px', lineHeight: 1, color: '#9F9988' }}>You</Typography>
            </Box>
            {isTeam && (
              <Box
                sx={{
                  display: 'flex',
                  marginBottom: { xs: isTeam ? '8px' : '4px', table: '4px' },
                  alignItems: 'baseline',
                  marginTop: { xs: isTeam ? 'none' : 'auto', table: 'auto' },
                  paddingInline: '4px',
                }}
              >
                <Box
                  sx={{
                    height: '8px',
                    width: '8px',
                    borderRadius: '8px',
                    background: `linear-gradient(180deg, ${Colors.magenta[150]} 0%, #D3ABEE 60.11%, ${Colors.magenta[200]} 100%)`,
                    marginRight: '5px',
                    border: `1px solid ${Colors.magenta[200]}`,
                  }}
                />
                <Typography sx={{ fontSize: '10px', lineHeight: 1, color: '#9F9988' }}>Team</Typography>
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                marginBottom: { xs: isTeam ? '8px' : '4px', table: '4px' },
                alignItems: 'baseline',
                marginTop: { xs: isTeam ? 'none' : 'auto', table: 'auto' },
                paddingInline: '4px',
              }}
            >
              <Box
                sx={{
                  height: '8px',
                  width: '8px',
                  borderRadius: '8px',
                  background: Colors.white,
                  marginRight: '5px',
                }}
              />
              <Typography sx={{ fontSize: '10px', lineHeight: 1, color: '#9F9988' }}>Remaining</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <div
        ref={tooltipRef}
        style={{
          display: 'none',
          borderRadius: '5px',
          padding: '8px',
          background: 'rgba(0,0,0,0.7)',
          position: 'fixed',
          zIndex: 100,
          transform: 'translate(-100%,-100%)',
          top: 0,
          left: 0,
        }}
      >
        <Typography sx={{ fontSize: '10px', color: Colors.white }}></Typography>
      </div>
    </Box>
  );
};

export const DailyProgressLayout: React.FC<{ variant?: Variant; sx?: SxProps<Theme> }> = ({ variant, sx }) => {
  const props = useHandleDailyProgress();
  return <DailyProgress sx={sx} {...props} variant={variant} />;
};
