import { Box, Typography, useMediaQuery } from '@mui/material';
import React, { useRef } from 'react';
import { Swiper as SwiperType, Navigation } from 'swiper';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import theme from '../../../../theme';
import { ReactComponent as ArrowIcon } from '../../../../assets/svg/feature/arrow.svg';
import { ReactComponent as FlameIcon } from '../../../../assets/svg/feature/flame.svg';
import { RangeMap } from '../../../../models/Shared';
import { Colors } from '../../../../theme/colors';

const slideOptions: SwiperProps = {
  spaceBetween: 0,
  speed: 400,
  centeredSlidesBounds: true,
  slideToClickedSlide: false,
  threshold: 15,
  slidesOffsetAfter: 0,
  slidesOffsetBefore: 0,
  breakpoints: {
    320: {
      slidesPerView: 7,
      slidesPerGroup: 1,
    },
    600: {
      slidesPerView: 7,
      slidesPerGroup: 1,
    },
  },
  allowSlideNext: true,
  allowSlidePrev: true,
};

type SmallBooleanCalendarProps = {
  rangeData: RangeMap;
  handleUpdate: (date: string, isSuccess: boolean) => void;
};

export const SmallBooleanCalendar: React.FC<SmallBooleanCalendarProps> = ({ rangeData, handleUpdate }) => {
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const swiperRef = useRef<SwiperType>();
  return (
    <Box
      sx={{
        display: 'flex',
        background: '#FEF7E9',
        flex: 1,
        width: '100%',
        maxWidth: '380px',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: '15px',
        paddingInline: rangeData.length <= 7 ? '8px' : '0',
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {!smDown && rangeData.length > 7 && (
        <Box
          component="button"
          sx={{
            height: 44,
            width: 20,
            background: 'unset',
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={() => swiperRef.current?.slidePrev()}
        >
          <ArrowIcon height="12px" width="12px" fill="#9FA09C" />
        </Box>
      )}
      <Swiper
        {...slideOptions}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        key={'boolean-calendar'}
        modules={[Navigation]}
        // initialSlide={
        //   new Date(endDate ?? new Date()) < new Date()
        //     ? rangeDate.length
        //     : getInitialSlide(rangeDate.findIndex((item) => item.isToday))
        // }
      >
        {rangeData.map((item) => (
          <SwiperSlide key={item.date}>
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: { xs: 44, sm: 44 },
                width: { xs: 44, sm: 44 },
                cursor: 'pointer',
              }}
              onClick={() => handleUpdate(item.date, item.isSuccess)}
            >
              {item.isSuccess ? (
                <>
                  <Typography
                    sx={{
                      position: 'absolute',
                      fontSize: { xs: '6px', table: '8px' },
                      fontWeight: '300',
                      top: '2px',
                      left: '0',
                    }}
                  >
                    {item.day}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: { xs: 30, sm: 30 },
                      width: { xs: 30, sm: 30 },
                      borderRadius: '100%',
                      border: `1px solid ${Colors.orange[200]}`,
                      background: `radial-gradient(circle, ${Colors.orange[200]} 0%, ${Colors.orange[500]} 100%)`,
                    }}
                  >
                    {item.isComplete || !item?.label ? (
                      <FlameIcon height={16} width={16} fill={Colors.white} />
                    ) : (
                      <Typography sx={{ fontSize: { xs: '14px' }, fontWeight: '700', color: Colors.white }}>
                        {item.label}
                      </Typography>
                    )}
                  </Box>
                </>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: { xs: 30, sm: 30 },
                    width: { xs: 30, sm: 30 },
                    borderRadius: '100%',
                    background: Colors.white,
                    border: `1px solid ${Colors.orange[200]}`,
                  }}
                >
                  <Typography sx={{ fontSize: { xs: '12px' }, fontWeight: '300' }}>{item.day}</Typography>
                </Box>
              )}

              {item.isToday && (
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: 29,
                    height: 3,
                    background: Colors.magenta[300],
                    borderTopLeftRadius: '100px',
                    borderTopRightRadius: '100px',
                  }}
                />
              )}
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
      {!smDown && rangeData.length > 7 && (
        <Box
          component="button"
          sx={{
            height: 44,
            width: 20,
            padding: 0,
            background: 'unset',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={() => swiperRef.current?.slideNext()}
        >
          <ArrowIcon height="12px" width="12px" fill="#9FA09C" style={{ transform: 'rotate(180deg)' }} />
        </Box>
      )}
    </Box>
  );
};
