import React, { useState } from 'react';
import { Box, SxProps, Theme } from '@mui/material';
import DefaultImage from '../../assets/svg/placeholder_avatar.svg';

type AvatarProps = {
  alt?: string;
  src?: string;
  sx?: SxProps<Theme>;
};

export const Avatar: React.FC<AvatarProps> = ({ alt = 'profile', src, sx }) => {
  const [loadError, setLoadError] = useState(false);
  return (
    <Box sx={{ height: '40px', width: '40px', overflow: 'hidden', borderRadius: '50%', ...sx }}>
      <img
        alt={alt}
        src={loadError ? DefaultImage : src ?? DefaultImage}
        onError={() => setLoadError(true)}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          objectPosition: 'center',
        }}
      />
    </Box>
  );
};
