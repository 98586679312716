import { Grid } from '@mui/material';
import React from 'react';
import { metricsCategoryColors } from '../utils/metricsCategories';
import WellnessCard from './cards/WellnessCard';
import { GraphMetric, IMetric } from '../models/Metrics';
import { Range } from 'react-date-range';
import { PERIOD } from '../models/Shared';

export interface WellnessComponentProps {
  metricsList: IMetric[];
  setSelectedMetrics: (s: IMetric[]) => void;
  selectedMetrics: IMetric[];
  setRange: (range: Range[]) => void;
  range: Range[];
  graph: GraphMetric;
  period: PERIOD;
  setPeriod: (p: PERIOD) => void;
  selectedGoal: string;
  setSelectedGoal: (s: string) => void;
  goalsList: string[];
}

const WellnessComponent: React.FC<WellnessComponentProps> = ({
  metricsList,
  selectedMetrics,
  setSelectedMetrics,
  setRange,
  range,
  graph,
  period,
  setPeriod,
  selectedGoal,
  setSelectedGoal,
  goalsList,
}) => {
  return (
    <Grid container spacing={2} justifyContent={'center'}>
      <Grid item container xs={12}>
        <WellnessCard
          colors={metricsCategoryColors}
          metricsList={metricsList}
          selectedMetrics={selectedMetrics}
          setSelectedMetrics={setSelectedMetrics}
          range={range}
          setRange={setRange}
          graph={graph}
          period={period}
          setPeriod={setPeriod}
          selectedGoal={selectedGoal}
          setSelectedGoal={setSelectedGoal}
          goalsList={goalsList}
        />
      </Grid>
    </Grid>
  );
};

export default WellnessComponent;
