import {
  IDeleteMetric,
  IGetMetricsByCategory,
  IMultipleUpdate,
  INewMetric,
  IPostMetric,
  IUpdateMetric,
} from '../../../../models/Metrics';
import { deleteRequest, getRequest, postRequest, putRequest } from '../../../../services/HttpService';

export const getUserJourneyCardMetrics = (date: string) => getRequest(`/metrics-service/user/journey?date=${date}`);

export const getAdminJourneyCardMetrics = (date: string) => getRequest(`/metrics-service/admin/journey?date=${date}`);

export const getUserMetricsByPillar = (date: string, pillar?: string) => {
  return getRequest(
    `/metrics-service/user/metrics_by_pillar?date=${date}${pillar !== 'ALL' ? `&pillar=${pillar}` : ``}`,
  );
};

export const getAdminMetricsRequest = (date: string) => {
  return getRequest(`/metrics-service/admin/metrics?date=${date}`);
};

export const getWellnessChartUserRequest = (body: {
  date_from: string;
  date_to: string;
  metrics: Array<{ metric_type: string; title: string }>;
}) => {
  return postRequest(`/metrics-service/user/wellness_chart`, body);
};

export const getWellnessChartAdminRequest = (body: {
  date_from: string;
  date_to: string;
  metrics: Array<{ metric_type: string; title: string }>;
}) => {
  return postRequest(`/metrics-service/admin/wellness_chart`, body);
};

export const getAdminWellnessMetricsRequest = () => {
  return getRequest(`/metrics-service/admin/wellness_chart/metrics`);
};

export const getUserMetrics = (date: string) => {
  return getRequest(`/metrics-service/user/metrics?date=${date}`);
};

export const submitMetric = (metric: IPostMetric) => {
  return postRequest('/metrics-service/metrics', { ...metric });
};

export const newMetric = (metric: INewMetric) => {
  return postRequest('/metrics-service/custom/metrics', { ...metric });
};

export const deleteMetric = (metric: IDeleteMetric) => {
  return deleteRequest('/metrics-service/custom/metrics', { ...metric });
};

export const updateMetric = (metric: IUpdateMetric) => {
  return putRequest('/metrics-service/custom/metrics', { ...metric });
};

export const getMetricsByCategory = (params: IGetMetricsByCategory) => {
  return getRequest('/metrics-service/user/metrics_by_category', params);
};

export const multipleUpdateMetric = (params: IMultipleUpdate) => {
  return postRequest('/metrics-service/multi/metrics', params);
};

export const getWhoFive = ({ date }: { date: string }) => {
  return getRequest('/metrics-service/user/who_5', { date });
};

export const getLifeAssessment = (params: { date: string }) => {
  return getRequest('/metrics-service/user/life_assessment', params);
};
