import React, { useEffect, useState } from 'react';
import { Calendar } from 'react-date-range';
import { Box } from '@mui/material';
import { CalendarDayItemNumber } from '../../ChallengeActivityPage/components/CalendarDayItem';
import '../../ChallengeActivityPage/components/LogDataModal.css';
import { METRICS_TYPE } from '../../../models/enum/METRICS_TYPE';
import InputField from '../../../components/features/CustomTextField';
import { ReactComponent as PlusIcon } from '../../../assets/svg/plus.svg';
import { Colors } from '../../../theme/colors';
import { MetricItemTypes } from '../types';
import { unitsLabel } from '../helpers';
import { METRICS_UNIT } from '../../../models/enum/METRICS_UNIT';
import { CustomSlider } from '../../../components/features/CustomSlider';
import { CustomButton } from '../../../components/features/CustomButton';
import moment from 'moment';
import { EditCalendarTimelineItem } from '../../ChallengeActivityPage/types';
import { inputNumberValidation } from '../../../utils/inputNumberValidation';

const minutesToHours = (min: number) => Math.round((min / 60) * 10) / 10;

type LogDataProps = {
  isCreateMetric?: boolean;
  metric?: MetricItemTypes;
  firstInput: string;
  secondInput: string;
  targetFirstInput: string;
  targetSecondInput: string;
  sliderValue: number;
  targetSlideValue: number;
  focusedDate: Date;
  timeline: EditCalendarTimelineItem[];
  hasGoal: boolean;
  handleRemoveGoal: () => void;
  setFirstInput: (val: string) => void;
  setSecondInput: (val: string) => void;
  setSliderValue: (val: number) => void;
  setTargetFirstInput: (val: string) => void;
  setTargetSecondInput: (val: string) => void;
  setTargetSlideValue: (val: number) => void;
  handleChangeDay: (date: Date) => void;
  handleShownDateChange: (date: Date) => void;
  handleAddMetric: () => void;
  handleAddGoal: () => void;
};

export const LogData: React.FC<LogDataProps> = ({
  isCreateMetric,
  metric,
  firstInput,
  secondInput,
  sliderValue,
  targetFirstInput,
  targetSecondInput,
  targetSlideValue,
  focusedDate,
  timeline,
  hasGoal,
  handleRemoveGoal,
  setFirstInput,
  setSecondInput,
  setSliderValue,
  setTargetFirstInput,
  setTargetSecondInput,
  setTargetSlideValue,
  handleChangeDay,
  handleShownDateChange,
  handleAddMetric,
  handleAddGoal,
}) => {
  const [showGoal, setShowGoal] = useState(false);
  const isSleepDuration = metric?.metricType === METRICS_TYPE.SLEEP_DURATION;

  useEffect(() => {
    if (isCreateMetric) return;
    setShowGoal(hasGoal);
  }, [isCreateMetric, hasGoal]);

  return (
    <Box sx={{ width: '382px', ml: '28px' }}>
      <Calendar
        className="logModal logData"
        date={focusedDate}
        weekStartsOn={1}
        onShownDateChange={handleShownDateChange}
        onChange={handleChangeDay}
        scroll={{ monthHeight: 480, monthWidth: 382 }}
        dayContentRenderer={(props) => (
          <CalendarDayItemNumber
            focusDate={focusedDate}
            day={props}
            timelineScore={
              metric?.metricType === METRICS_TYPE.SLEEP_DURATION
                ? timeline.map((item) => ({
                    ...item,
                    ...(item.value && { value: minutesToHours(item.value) }),
                    ...(item.newValue && { newValue: minutesToHours(item.newValue) }),
                    ...(item.teamValue && { teamValue: minutesToHours(item.teamValue) }),
                  }))
                : timeline
            }
            startRange={moment().add(-1, 'years').format('YYYY-MM-DD')}
            endRange={new Date().toISOString()}
          />
        )}
      />
      {isCreateMetric ? (
        <Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-around'}
            width={'100%'}
            mt="24px"
            mb="32px"
          >
            <Box sx={{ flex: 1 }}>
              <InputField
                label={'Metric name'}
                setValue={(el) => setFirstInput(el.target.value)}
                name="firstInput"
                type="text"
                value={firstInput}
                placeholder={'Enter name'}
                inputProps={{
                  sx: {
                    '& .MuiInputLabel-root': {
                      marginTop: '-28px',
                      '&.Mui-disabled': {
                        marginTop: '-28px',
                      },
                    },
                    '& input[type=text]': {
                      '-moz-appearance': 'textfield',
                    },
                    '& input[type=text]::-webkit-outer-spin-button': {
                      '-webkit-appearance': 'none',
                      margin: 0,
                    },
                    '& input[type=text]::-webkit-inner-spin-button': {
                      '-webkit-appearance': 'none',
                      margin: 0,
                    },
                  },
                }}
              />
              <InputField
                label={'Current value'}
                setValue={(el) => {
                  inputNumberValidation(el.target.value, setSecondInput);
                }}
                name="secondInput"
                value={secondInput}
                placeholder={'Enter value'}
                inputProps={{
                  type: 'number',
                  sx: {
                    mt: '50px',
                    '& .MuiInputLabel-root': {
                      marginTop: '-28px',
                      '&.Mui-disabled': {
                        marginTop: '-28px',
                      },
                    },
                    '& input[type=number]': {
                      '-moz-appearance': 'textfield',
                    },
                    '& input[type=number]::-webkit-outer-spin-button': {
                      '-webkit-appearance': 'none',
                      margin: 0,
                    },
                    '& input[type=number]::-webkit-inner-spin-button': {
                      '-webkit-appearance': 'none',
                      margin: 0,
                    },
                  },
                }}
              />
            </Box>
          </Box>
          <Box>
            {showGoal ? (
              <Box>
                <InputField
                  label={'Enter target value'}
                  setValue={(el) => inputNumberValidation(el.target.value, setTargetFirstInput)}
                  name="targetFirstInput"
                  value={targetFirstInput}
                  placeholder={'Enter value'}
                  inputProps={{
                    type: 'number',
                    sx: {
                      mt: '24px',
                      '& .MuiInputLabel-root': {
                        marginTop: '-28px',
                        '&.Mui-disabled': {
                          marginTop: '-28px',
                        },
                      },
                      '& input[type=number]': {
                        '-moz-appearance': 'textfield',
                      },
                      '& input[type=number]::-webkit-outer-spin-button': {
                        '-webkit-appearance': 'none',
                        margin: 0,
                      },
                      '& input[type=number]::-webkit-inner-spin-button': {
                        '-webkit-appearance': 'none',
                        margin: 0,
                      },
                    },
                  }}
                />
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: '32px' }}>
                  <CustomButton
                    type="bigMagenta"
                    text="Cancel"
                    inputProps={{
                      variant: 'contained',
                    }}
                    onClick={() => setShowGoal(false)}
                  />
                </Box>
              </Box>
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CustomButton
                  type="bigMagenta"
                  text="Add a goal"
                  inputProps={{
                    variant: 'contained',
                    startIcon: <PlusIcon width={16} height={16} />,
                  }}
                  onClick={() => setShowGoal(true)}
                />
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        metric && (
          <Box>
            {!metric.isAuto && (
              <>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-around'}
                  width={'100%'}
                  mt="24px"
                  mb="32px"
                >
                  {isSleepDuration ? (
                    <Box sx={{ flex: 1, mr: '16px' }}>
                      <InputField
                        label={'Sleep duration (hours)'}
                        setValue={(el) => inputNumberValidation(el.target.value, setFirstInput)}
                        name="firstInput"
                        value={firstInput}
                        placeholder={'Enter hours'}
                        inputProps={{
                          type: 'number',
                          sx: {
                            '& .MuiInputLabel-root': {
                              marginTop: '-28px',
                              '&.Mui-disabled': {
                                marginTop: '-28px',
                              },
                            },
                            '& input[type=number]': {
                              '-moz-appearance': 'textfield',
                            },
                            '& input[type=number]::-webkit-outer-spin-button': {
                              '-webkit-appearance': 'none',
                              margin: 0,
                            },
                            '& input[type=number]::-webkit-inner-spin-button': {
                              '-webkit-appearance': 'none',
                              margin: 0,
                            },
                          },
                        }}
                      />
                      <InputField
                        label={'Sleep duration (minutes)'}
                        setValue={(el) => inputNumberValidation(el.target.value, setSecondInput)}
                        name="secondInput"
                        value={secondInput}
                        placeholder={'Enter minutes'}
                        inputProps={{
                          type: 'number',
                          sx: {
                            mt: '50px',
                            '& .MuiInputLabel-root': {
                              marginTop: '-28px',
                              '&.Mui-disabled': {
                                marginTop: '-28px',
                              },
                            },
                            '& input[type=number]': {
                              '-moz-appearance': 'textfield',
                            },
                            '& input[type=number]::-webkit-outer-spin-button': {
                              '-webkit-appearance': 'none',
                              margin: 0,
                            },
                            '& input[type=number]::-webkit-inner-spin-button': {
                              '-webkit-appearance': 'none',
                              margin: 0,
                            },
                          },
                        }}
                      />
                    </Box>
                  ) : metric.metricUnit === METRICS_UNIT.NOT_AVAILABLE && metric ? (
                    <Box sx={{ flex: 1, marginRight: '16px' }}>
                      <CustomSlider
                        label={metric.title}
                        value={sliderValue}
                        onChange={setSliderValue}
                        min={1}
                        max={metric.maxValue}
                      />
                    </Box>
                  ) : (
                    <Box sx={{ flex: 1, marginRight: '16px' }}>
                      <InputField
                        label={`${metric.title}${
                          metric.metricUnit in unitsLabel ? ` (${unitsLabel[metric.metricUnit]})` : ''
                        }`}
                        setValue={(el) => inputNumberValidation(el.target.value, setFirstInput)}
                        name="steps"
                        value={firstInput}
                        placeholder={`Enter ${
                          metric.metricUnit in unitsLabel
                            ? unitsLabel[metric.metricUnit]
                            : metric.metricUnit === METRICS_UNIT.STEPS
                            ? 'steps'
                            : 'value'
                        }`}
                        inputProps={{
                          type: 'number',
                          sx: {
                            '& .MuiInputLabel-root': {
                              marginTop: '-28px',
                              '&.Mui-disabled': {
                                marginTop: '-28px',
                              },
                            },
                            '& input[type=number]': {
                              '-moz-appearance': 'textfield',
                            },
                            '& input[type=number]::-webkit-outer-spin-button': {
                              '-webkit-appearance': 'none',
                              margin: 0,
                            },
                            '& input[type=number]::-webkit-inner-spin-button': {
                              '-webkit-appearance': 'none',
                              margin: 0,
                            },
                          },
                        }}
                      />
                    </Box>
                  )}
                  <Box
                    component="button"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '36px',
                      width: '36px',
                      flexShrink: '36px',
                      borderRadius: '50%',
                      background: Colors.blue[500],
                    }}
                    onClick={handleAddMetric}
                  >
                    <PlusIcon />
                  </Box>
                </Box>
                <Box>
                  {showGoal ? (
                    <Box
                      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
                    >
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'space-around'}
                        width={'100%'}
                        mt="24px"
                        mb="32px"
                      >
                        {isSleepDuration ? (
                          <Box sx={{ flex: 1, mr: '16px' }}>
                            <InputField
                              label={'Sleep duration (hours)'}
                              setValue={(el) => inputNumberValidation(el.target.value, setTargetFirstInput)}
                              name="firstInput"
                              value={targetFirstInput}
                              placeholder={'Enter hours'}
                              inputProps={{
                                type: 'number',
                                sx: {
                                  '& .MuiInputLabel-root': {
                                    marginTop: '-28px',
                                    '&.Mui-disabled': {
                                      marginTop: '-28px',
                                    },
                                  },
                                  '& input[type=number]': {
                                    '-moz-appearance': 'textfield',
                                  },
                                  '& input[type=number]::-webkit-outer-spin-button': {
                                    '-webkit-appearance': 'none',
                                    margin: 0,
                                  },
                                  '& input[type=number]::-webkit-inner-spin-button': {
                                    '-webkit-appearance': 'none',
                                    margin: 0,
                                  },
                                },
                              }}
                            />
                            <InputField
                              label={'Sleep duration (minutes)'}
                              setValue={(el) => inputNumberValidation(el.target.value, setTargetSecondInput)}
                              name="secondInput"
                              value={targetSecondInput}
                              placeholder={'Enter minutes'}
                              inputProps={{
                                type: 'number',
                                sx: {
                                  mt: '50px',
                                  '& .MuiInputLabel-root': {
                                    marginTop: '-28px',
                                    '&.Mui-disabled': {
                                      marginTop: '-28px',
                                    },
                                  },
                                  '& input[type=number]': {
                                    '-moz-appearance': 'textfield',
                                  },
                                  '& input[type=number]::-webkit-outer-spin-button': {
                                    '-webkit-appearance': 'none',
                                    margin: 0,
                                  },
                                  '& input[type=number]::-webkit-inner-spin-button': {
                                    '-webkit-appearance': 'none',
                                    margin: 0,
                                  },
                                },
                              }}
                            />
                          </Box>
                        ) : metric.metricUnit === METRICS_UNIT.NOT_AVAILABLE && metric ? (
                          <Box sx={{ flex: 1, marginRight: '16px' }}>
                            <CustomSlider
                              value={targetSlideValue}
                              label={'Target value'}
                              onChange={setTargetSlideValue}
                              min={1}
                              max={metric.maxValue}
                            />
                          </Box>
                        ) : (
                          <Box sx={{ flex: 1, marginRight: '16px' }}>
                            <InputField
                              label={'Enter target value'}
                              setValue={(el) => inputNumberValidation(el.target.value, setTargetFirstInput)}
                              name="steps"
                              value={targetFirstInput}
                              placeholder={`Enter ${
                                metric.metricUnit in unitsLabel
                                  ? unitsLabel[metric.metricUnit]
                                  : metric.metricUnit === METRICS_UNIT.STEPS
                                  ? 'steps'
                                  : 'value'
                              }`}
                              inputProps={{
                                type: 'number',
                                sx: {
                                  marginRight: '16px',
                                  '& .MuiInputLabel-root': {
                                    marginTop: '-28px',
                                    '&.Mui-disabled': {
                                      marginTop: '-28px',
                                    },
                                  },
                                  '& input[type=number]': {
                                    '-moz-appearance': 'textfield',
                                  },
                                  '& input[type=number]::-webkit-outer-spin-button': {
                                    '-webkit-appearance': 'none',
                                    margin: 0,
                                  },
                                  '& input[type=number]::-webkit-inner-spin-button': {
                                    '-webkit-appearance': 'none',
                                    margin: 0,
                                  },
                                },
                              }}
                            />
                          </Box>
                        )}
                        <Box
                          component="button"
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '36px',
                            width: '36px',
                            borderRadius: '50%',
                            background: Colors.magenta[300],
                          }}
                          onClick={handleAddGoal}
                        >
                          <PlusIcon />
                        </Box>
                      </Box>
                      <CustomButton
                        type="bigMagenta"
                        text={hasGoal ? 'Remove target' : 'Cancel'}
                        inputProps={{
                          variant: 'contained',
                        }}
                        onClick={() => {
                          hasGoal ? handleRemoveGoal() : setShowGoal(false);
                        }}
                      />
                    </Box>
                  ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <CustomButton
                        type="bigMagenta"
                        text="Add a goal"
                        inputProps={{
                          variant: 'contained',
                          startIcon: <PlusIcon width={16} height={16} />,
                        }}
                        onClick={() => setShowGoal(true)}
                      />
                    </Box>
                  )}
                </Box>
              </>
            )}
          </Box>
        )
      )}
    </Box>
  );
};
