import { useEffect, useState } from 'react';
import { EditForm } from './useHandle';
import { AWARD_TRIGGER } from '../../../models/enum/AWARD_TRIGGER';
import { Challenge } from '../../../models/Challenge';
import { CHALLENGE_TYPE } from '../../../models/enum/CHALLENGE_TYPE';
import { AWARD_TYPE } from '../../../models/enum/AWARD_TYPE';
import moment from 'moment/moment';
import { COMPETITION } from '../../../models/enum/COMPETITION';
import { TRACKING_TYPE } from '../../../models/enum/TRACKING_TYPE';
import useIsPageActive from '../../../hooks/useIsPageActive';
import { ANALYTICS_PAGE } from '../../../models/enum/ANALYTICS_PAGE';

export const useStepHandle = (form: EditForm, challenge: Challenge | null) => {
  const [activeStep, setActiveStep] = useState(1);
  const [isAvailableToSecondForm, setIsAvailableToSecondForm] = useState(false);
  const [isAvailableToThirdForm, setIsAvailableToThirdForm] = useState(false);
  const [isAvailablePublish, setIsAvailablePublish] = useState(false);

  const visible = useIsPageActive(ANALYTICS_PAGE.EDIT_CHALLENGE);

  const isBoolean = challenge && challenge.challengeAction.actionType === 'HABIT';
  const isCompletion =
    challenge && challenge.challengeAward && challenge.challengeAward.awardTrigger === AWARD_TRIGGER.COMPLETION;
  const showTotalGoal =
    challenge &&
    (challenge.challengeCharacteristic.challengeType === CHALLENGE_TYPE.LEADERBOARD ||
      challenge.challengeCharacteristic.challengeType === CHALLENGE_TYPE.TARGET);
  const isLeaderboard = challenge && challenge.challengeCharacteristic.challengeType === CHALLENGE_TYPE.LEADERBOARD;

  useEffect(() => {
    const availableToSecondForm = Boolean(
      (form.imageUrl || form.imageBase64) && form.title.length && form.shortDescription.length,
    );
    setIsAvailableToSecondForm(availableToSecondForm);

    const isStartAndEndDate = form.startDate && form.endDate && !moment(form.startDate).isSame(form.endDate);
    const isIndividual = challenge && challenge.challengeCharacteristic.competition === COMPETITION.INDIVIDUAL;
    const isMaxTeamSize = !isIndividual ? form.teamSize !== null && form.teamSize.length : true;
    const isWinners = !isCompletion ? form.winners !== null && form.winners.length : true;
    const isTracking =
      challenge && challenge.challengeAction.actionSource === TRACKING_TYPE.HYBRID
        ? form.trackingType && form.trackingType.length
        : true;
    setIsAvailablePublish(Boolean(isStartAndEndDate && isMaxTeamSize && isWinners && isTracking));

    const isTotalGoal = Boolean(showTotalGoal ? form.totalGoal && form.totalGoal.length : true);
    const isDailyGoal = Boolean(!showTotalGoal && !isBoolean ? form.dailyGoal && form.dailyGoal.length : true);
    const isNumbersOfDay = Boolean(
      !showTotalGoal && isCompletion ? form.numberOfDays && form.numberOfDays.length : true,
    );
    const isGoalBlock = Boolean(isLeaderboard ? true : isTotalGoal && isDailyGoal && isNumbersOfDay);
    const isAwardType = Boolean(form.awardType && form.awardType.length);
    const isBudge = Boolean(
      isAwardType && form.awardType === AWARD_TYPE.BADGE ? form.badgeImageUrl || form.badgeImageBase64 : true,
    );
    const isPoint = Boolean(
      isAwardType && form.awardType === AWARD_TYPE.POINT ? form.points !== null && form.points.length : true,
    );

    setIsAvailableToThirdForm(isGoalBlock && isAwardType && isBudge && isPoint);
  }, [form]);

  useEffect(() => handleStep(1), [visible]);
  const handleStep = (step: number) => {
    if (step === 1) {
      setActiveStep(step);
      return;
    }
    if (step === 2 && isAvailableToSecondForm) {
      setActiveStep(step);
      return;
    }
    if (step === 3 && isAvailableToSecondForm && isAvailableToThirdForm) {
      setActiveStep(step);
      return;
    }
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return {
    activeStep,
    isAvailableToSecondForm,
    isAvailableToThirdForm,
    isAvailablePublish,
    handleStep,
    handleNext,
    handleBack,
  };
};
