import React from 'react';
import { useWhoFive } from '../hooks/useWhoFive';
import { WhoFiveModal } from './WhoFiveModal';
import { IMetric } from '../../../models/Metrics';

type WhoFiveComponentProps = {
  isOpen: boolean;
  metrics?: IMetric[];
  onClose: () => void;
};

export const WhoFiveComponent: React.FC<WhoFiveComponentProps> = ({ isOpen, metrics, onClose }) => {
  const { isUpdating, slidersValue, handleSubmitWho, handleChangeSliderValue, handleClose } = useWhoFive(
    onClose,
    metrics,
  );
  return (
    <WhoFiveModal
      isOpen={isOpen}
      metrics={metrics}
      onClose={handleClose}
      isUpdating={isUpdating}
      handleOnChange={handleChangeSliderValue}
      values={slidersValue}
      handleSubmit={handleSubmitWho}
    />
  );
};
