import React from 'react';
import { Box, FormControl, Grid, MenuItem, OutlinedInput, Select, styled, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const MenuProps = {
  PaperProps: {
    style: {
      padding: '4px 8px',
      maxHeight: '400px',
      borderRadius: '20px',
      marginTop: '8px',
      boxShadow: 'var(--shadow-button)',
    },
  },
};

const StyledSelect = styled(Select)({
  fontSize: 14,
  fontWeight: 700,
  background: 'white',
  borderRadius: '999px',
  paddingInline: '8px',
  boxShadow: 'var(--shadow-button)',
  '& *': {
    border: 'none !important',
  },
});

const StyledMenuItem = styled(MenuItem)({
  mb: '4px',
  fontSize: '14px',
  borderRadius: '20px',
  fontWeight: '700',
  '&.Mui-selected': {
    backgroundColor: '#E2E8ED',
    fontWeight: '900',
  },
});

type CustomDropdownProps = {
  width?: number | string;
  label?: string;
  fullWidth?: boolean;
  small?: boolean;
  fontSize?: number;
  placeholder?: string;
  disabled?: boolean;
  list: { label: string; value: string }[];
  selected: string;
  setSelected: (s: string) => void;
};

const CustomGoalDropdown: React.FC<CustomDropdownProps> = ({
  width = 300,
  label,
  fullWidth,
  small,
  placeholder,
  list,
  disabled,
  selected,
  setSelected,
  fontSize = 16,
}) => {
  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setSelected(value);
  };

  return (
    <div>
      <FormControl sx={{ width: fullWidth ? '100%' : width, position: 'relative' }} disabled={disabled}>
        {label && (
          <Box sx={{ position: 'absolute', top: '-24px', left: '12px' }}>
            <Grid container wrap={'nowrap'} alignItems={'center'}>
              <Typography sx={{ color: 'red', fontWeight: 'bold', pr: 1, fontSize }}>Goal:</Typography> {label}
            </Grid>
          </Box>
        )}
        <StyledSelect
          displayEmpty
          value={selected}
          label={list.find((i) => i.value === selected)?.label}
          onChange={handleChange}
          input={<OutlinedInput />}
          MenuProps={MenuProps}
          IconComponent={ExpandMoreIcon}
          sx={{ height: small ? 36 : 40 }}
          inputProps={{ 'aria-label': 'Without label', fontSize }}
        >
          {placeholder && (
            <MenuItem disabled value="">
              <em>{placeholder}</em>
            </MenuItem>
          )}
          {list.map((item) => (
            <StyledMenuItem key={item.value} value={item.value}>
              <Grid
                container
                wrap={'nowrap'}
                alignItems={'center'}
                sx={{ color: item.value === 'Not Selected' ? '#8B8B8B' : undefined }}
              >
                <Grid container wrap={'nowrap'} alignItems={'center'}>
                  <Typography sx={{ color: 'red', fontWeight: 'bold', pr: 1, fontSize }}>Goal:</Typography> {item.label}
                </Grid>
              </Grid>
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </div>
  );
};

export default CustomGoalDropdown;
