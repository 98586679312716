import { throttle } from 'lodash';
import { useState, useEffect, useRef } from 'react';
import useIsPageActive from '../../../hooks/useIsPageActive';
import { ANALYTICS_PAGE } from '../../../models/enum/ANALYTICS_PAGE';
import { getTeamDetails, getTeamMembers, updateTeam } from '../../../lib/api/http/requests/challengeTeam';
import { fetchChallenge } from '../../../redux/slices/challengeSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { InitFormData, TeamForm } from '../../ChallengeDetailPage/hooks/useTeamHandle';
import moment from 'moment';
import { TeamDetails, TeamMember } from '../../../models/Team';
import { CHALLENGE_SUB_CATEGORY } from '../../../models/enum/CHALLENGE_SUB_CATEGORY';
import { COMPETITION } from '../../../models/enum/COMPETITION';
import { pageOptionsByType } from '../../ChallengeDetailPage/hooks/useHandle';
import { useExportExcel } from '../../../hooks/useExportExcel';
import { getRequest } from '../../../services/HttpService';
import { openToast } from '../../../redux/slices/appSlice';
import { handleBackendError } from '../../../utils/handleBackendError';

const SIZE_PAGE = 12;
type Props = {
  id: string;
  teamId: string;
};
export const useHandleFetch = (props: Props) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState<TeamMember[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);

  const { challenge } = useSelector((state: RootState) => state.challenge.data);
  const { challengeLoading } = useSelector((state: RootState) => state.challenge.meta);

  const [teamDetails, setTeamDetails] = useState<TeamDetails | null>(null);

  const [daysLeftToJoin, setDaysLeftToJoin] = useState(0);
  const [searchValue, setSearchValue] = useState<string>('');
  const [form, setForm] = useState<TeamForm>(InitFormData);
  const [showTeamModal, setShowTeamModal] = useState(false);
  const [teamModalLoading, setTeamModalLoading] = useState(false);

  const visible = useIsPageActive(ANALYTICS_PAGE.TEAM_DETAILS);
  const divRef = useRef<HTMLIonContentElement>(null);

  const { exportToExcel, exportFileName } = useExportExcel();

  const handleNext = async () => {
    try {
      if (isFetching || !hasNextPage || loading) return;
      setIsFetching(true);
      const { data } = await getTeamMembers({
        teamUUID: props.teamId,
        searchText: searchValue,
        page: page,
        size: SIZE_PAGE,
      });
      setPage(data.pageable.pageNumber + 1);
      setHasNextPage(!data.last);
      setItems([...items, ...data.content]);
      setIsFetching(false);
    } catch (err) {
      console.log('error', err);
      setHasNextPage(false);
      setIsFetching(false);
    }
  };

  const handleScrollEvent = throttle(async () => {
    if (divRef.current) {
      const { scrollHeight, scrollTop, offsetHeight } = await divRef.current.getScrollElement();
      const currentPosition = scrollTop + offsetHeight;
      const triggerPosition = scrollHeight - 40;
      if (currentPosition >= triggerPosition && (!isFetching || hasNextPage)) {
        handleNext();
      }
    }
  }, 200);

  const fetchTeamDetails = async () => {
    try {
      const { data } = (await getTeamDetails({ teamUUID: props.teamId })) as { data: TeamDetails };
      setForm({ teamName: data.name, imageBase64: '', imageUrl: data.avatar, slackUrl: data.teamSlackURL });
      setTeamDetails(data);
    } catch (e) {}
  };

  const firstFetch = async () => {
    try {
      scrollToTop();
      setLoading(true);

      const { data } = await getTeamMembers({
        teamUUID: props.teamId,
        searchText: searchValue,
        page: 0,
        size: SIZE_PAGE,
      });
      setHasNextPage(!data.last);
      setItems(data.content);
      setPage(data.pageable.pageNumber + 1);
      if (divRef.current && !data.last) {
        const { scrollHeight, scrollTop, offsetHeight } = await divRef.current.getScrollElement();
        const currentPosition = scrollTop + offsetHeight;
        const triggerPosition = scrollHeight - 40;
        if (currentPosition >= triggerPosition) {
          handleNext();
        }
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const scrollToTop = () => {
    divRef.current && divRef.current.scrollToTop();
  };

  useEffect(() => {
    const subscribe = setTimeout(firstFetch, 600);
    return () => clearTimeout(subscribe);
  }, [searchValue, visible, props]);

  const handleUpdateData = async () => {
    await firstFetch();
    loadOtherData();
  };

  const handleUpdateImage = (value: string) => setForm((prevForm) => ({ ...prevForm, imageBase64: value }));
  const handleChangeTeamName = (value: string) => setForm({ ...form, teamName: value });
  const handleChangeSlackUrl = (slackUrl: string) => {
    setForm({ ...form, slackUrl });
  };

  const updateTeamRequest = async () => {
    try {
      setTeamModalLoading(true);
      const params: {
        name: string;
        imageURL?: string;
        base64File?: {
          file: string;
        };
        teamSlackURL: string;
      } = {
        name: form.teamName,
        teamSlackURL: form.slackUrl,
      };
      if (form.imageBase64.length) {
        params.base64File = {
          file: form.imageBase64,
        };
      }
      if (!form.imageBase64.length && form.imageUrl && form.imageUrl.length) {
        params.imageURL = form.imageUrl;
      }
      await updateTeam(props.id, props.teamId, params);
      setTeamModalLoading(false);
      setShowTeamModal(false);
      await handleUpdateData();
    } catch (e) {
      setTeamModalLoading(false);
    }
  };

  const challengeTitle = challenge ? challenge.challengeInfo.title : '';

  const challengeBreadcrumbItem = () => {
    if (!challenge) return [];
    return [
      {
        label: `Detailed:${challengeTitle}`,
        url: `/page/your-challenges/challenge/${challenge ? challenge.metadata.challengeUUID : ''}`,
      },
    ];
  };
  const progressBreadcrumbItem = () => {
    if (
      !challenge ||
      !challenge.challengeParticipation.isUserParticipant ||
      challenge.challengeSubCategory === CHALLENGE_SUB_CATEGORY.OPEN_TO_JOIN ||
      (challenge.challengeCharacteristic.competition === COMPETITION.TEAM &&
        !challenge.challengeParticipation.isUserTeamMember)
    )
      return [];

    return [
      { label: challengeTitle, url: `/page/your-challenges/challenge/${challenge.metadata.challengeUUID}/activity` },
    ];
  };

  const challengeStatusBreadcrumbItem =
    !challenge || !challenge.challengeSubCategory
      ? []
      : [
          {
            label: pageOptionsByType[challenge.challengeSubCategory].label,
            url: `/page/your-challenges/${
              challenge.challengeSubCategory === CHALLENGE_SUB_CATEGORY.ONGOING
                ? 'ongoing'
                : challenge.challengeSubCategory === CHALLENGE_SUB_CATEGORY.OPEN_TO_JOIN
                ? 'join'
                : 'finished'
            }`,
          },
        ];

  const loadOtherData = async () => {
    await dispatch(fetchChallenge({ id: props.id, isTemplate: null }));
    await fetchTeamDetails();
  };
  useEffect(() => {
    loadOtherData();
  }, [props]);
  useEffect(() => {
    if (challenge && challenge.challengeParticipation.endParticipationDate) {
      const now = moment(new Date()).format('YYYY-MM-DD');
      const futureDate = moment(challenge.challengeParticipation.endParticipationDate);
      setDaysLeftToJoin(futureDate.diff(now, 'days') + 1);
    }
  }, [challenge, props]);

  const handleExport = async () => {
    try {
      if (!teamDetails) return;
      const response: { data?: string } = await getRequest('/gamification-service/challenge/team/participants/export', {
        teamUUID: teamDetails.teamUUID,
      });
      if (response.data) {
        await exportToExcel(
          response.data,
          exportFileName('team-participants-export', teamDetails.teamUUID, teamDetails.name),
        );
        return;
      }
      dispatch(openToast({ type: 'error', text: 'Export file is empty' }));
    } catch (err) {
      handleBackendError(err, 'Fail to export participants of team');
    }
  };

  return {
    items,
    challenge,
    challengeLoading,
    teamDetails,
    loading,
    isFetching,
    divRef,
    searchValue,
    daysLeftToJoin,
    challengeTitle,
    challengeStatusBreadcrumbItem,
    form,
    teamModalLoading,
    showTeamModal,
    progressBreadcrumbItem,
    challengeBreadcrumbItem,
    setSearchValue,
    handleScrollEvent,
    handleUpdateData,
    setShowTeamModal,
    handleUpdateImage,
    handleChangeSlackUrl,
    handleChangeTeamName,
    updateTeamRequest,
    handleExport,
  };
};
