import { SxProps, Theme } from '@mui/material';
import { Colors } from '../../../theme/colors';

export const styles: { [key: string]: SxProps<Theme> } = {
  avatarContainerLarge: {
    height: '48px',
    width: '48px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 4,
    borderColor: Colors.white,
    borderStyle: 'solid',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '100%',
  },
  avatarContainer: {
    height: '36px',
    width: '36px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
    borderColor: Colors.white,
    borderStyle: 'solid',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '100%',
  },
  numberOfParticipants: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
    borderColor: Colors.white,
    backgroundColor: Colors.gray[100],
    borderStyle: 'solid',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '100%',
  },
  numberOfParticipantsLarge: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 4,
    borderColor: Colors.white,
    backgroundColor: Colors.gray[100],
    borderStyle: 'solid',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '100%',
  },
} as const;
