import React from 'react';
import { IonContent, IonPage, useIonRouter } from '@ionic/react';
import CustomNewHeader from '../../components/features/CustomNewHeader';
import { Box, CardMedia, Container, Grid, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { useHandleFetch } from './hooks/useHandleFetch';
import { Colors } from '../../theme/colors';
import EmptyTrophyIcon from '../../assets/png/empty-trophy.png';
import { ReactComponent as TrophyIcon } from '../../assets/svg/feature/awards/trophy_old.svg';
import { ReactComponent as PointIcon } from '../../assets/svg/feature/awards/trophy.svg';
import { FilterSelector } from '../../components/features/HeaderFilter/FilterSelector';
import { PreviewRoundItemProps } from '../../models/Shared';
import theme from '../../theme';
import { PointCard } from './components/PointCard';

export const AWARD_TYPE_LIST: PreviewRoundItemProps[] = [
  {
    value: 'awards',
    label: 'Challenge awards',
    Icon: TrophyIcon,
    mainColor: Colors.blue[800],
    backgroundColor: Colors.blue[700],
    borderColor: Colors.blue[600],
  },
  {
    value: 'points',
    label: 'Platform points',
    Icon: PointIcon,
    mainColor: Colors.blue[700],
    backgroundColor: Colors.blue[800],
    borderColor: Colors.blue[600],
  },
];
export const PointsPage: React.FC = () => {
  const router = useIonRouter();
  const smDown = useMediaQuery(theme.breakpoints.down('md'));
  const { loading, items, isFetching, divRef, handleScrollEvent } = useHandleFetch();

  const handleNavigation = (value: string, isSelected: boolean) => {
    if (value === 'awards' && !isSelected) {
      router.push('/page/trophy-library');
    }
  };

  const SkeletonList: React.FC = () => (
    <>
      {new Array(8).fill('1').map((_, idx) => (
        <Grid item key={idx.toString()} xs={12} sm={6} md={4}>
          <Skeleton variant="rectangular" style={{ height: 312, width: '100%', borderRadius: '20px' }} />
        </Grid>
      ))}
    </>
  );

  const EmptyState: React.FC = () => (
    <Grid sx={{ margin: '50px auto', textAlign: 'center' }}>
      <CardMedia sx={{ flexShrink: 0, height: 105, width: 133, margin: '0 auto' }} image={EmptyTrophyIcon} />

      <Typography
        sx={{ fontSize: '18px', lineHeight: '23px', color: Colors.gray[200], fontWeight: 'bold', mt: '32px' }}
      >
        Start watching, reading and logging your data!
      </Typography>
    </Grid>
  );

  return (
    <IonPage>
      <CustomNewHeader
        label={'Your platform points'}
        tooltip={
          <>
            {'View your '}
            <b>Experience points</b>
            {' related to the experience you gain on well-being through your engagement with the content of '}
            <i>Your Focus</i>
            {' page or your '}
            <b>Achievement points</b>
            {' related to your achievements as tracked through '}
            <i>Your Journey</i>
            {' page.'}
          </>
        }
        inProgress={loading}
        upBox={
          smDown ? (
            <Box sx={{ mb: '24px' }}>
              <FilterSelector
                tabMode={true}
                data={AWARD_TYPE_LIST}
                selected={['points']}
                onClickItem={(value: string, isSelected: boolean) => () => {
                  handleNavigation(value, isSelected);
                }}
                sx={{ width: '100%' }}
              />
            </Box>
          ) : null
        }
        rightAction={
          !smDown ? (
            <Box sx={{ alignSelf: { xs: 'stretch', md: 'center' }, marginLeft: 'auto' }}>
              <FilterSelector
                data={AWARD_TYPE_LIST}
                selected={['points']}
                onClickItem={(value: string, isSelected: boolean) => () => {
                  handleNavigation(value, isSelected);
                }}
              />
            </Box>
          ) : null
        }
      />
      <IonContent
        ref={divRef}
        onIonScroll={handleScrollEvent}
        style={{ '--padding-bottom': '36px' }}
        scrollEvents={true}
      >
        <Container maxWidth={'lg'}>
          <Grid container spacing={'24px'}>
            {!items ? (
              <SkeletonList />
            ) : items.length > 0 ? (
              <>
                {items.map((item, index) => (
                  <Grid item key={index} xs={12} sm={6} md={4}>
                    <PointCard point={item} />
                  </Grid>
                ))}
                {isFetching ? <SkeletonList /> : null}
              </>
            ) : (
              <EmptyState />
            )}
          </Grid>
        </Container>
      </IonContent>
    </IonPage>
  );
};
