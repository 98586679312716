import React, { useState } from 'react';
import { Box, CircularProgress, Tooltip, Typography, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import theme from '../../../../theme';
import { InfoOutlined } from '@mui/icons-material';
import CustomRangePicker from '../../../common/CustomRangePicker';
import { Colors } from '../../../../theme/colors';
import { ReactComponent as BulbIcon } from '../../../../assets/svg/feature/bulb.svg';
import { ReactComponent as StarIcon } from '../../../../assets/svg/feature/star.svg';
import { ReactComponent as TeamIcon } from '../../../../assets/svg/feature/team.svg';
import { Range } from 'react-date-range';
import InputField from '../../../features/CustomTextField';
import { PublishFormData } from '../../../../pages/TemplatesAdminPage/usePublishHandle';

type Props = {
  loading: boolean;
  publishForm: PublishFormData;
  range: Range[];
  suggestedDuration: string;
  isTeam: boolean;
  isCorporate: boolean;
  showNumberOfWinners: boolean;
  handleChangeRange: (range: Range[]) => void;
  handleChangeTeamSize: (teamSize: string) => void;
  handleChangeWinners: (teamSize: string) => void;
  handleChangeSlackUrl: (teamSize: string) => void;
};
export const PublishForm: React.FC<Props> = ({
  loading,
  range,
  suggestedDuration,
  isTeam,
  isCorporate,
  publishForm,
  showNumberOfWinners,
  handleChangeRange,
  handleChangeTeamSize,
  handleChangeWinners,
  handleChangeSlackUrl,
}) => {
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 2,
            background: 'rgba(65, 74, 79, 0.3)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress color="inherit" style={{ width: 30, height: 30 }} />
        </Box>
      )}

      <Grid sx={{ paddingBottom: '24px', borderBottom: '1px dashed #F2F2F2' }}>
        <Tooltip
          title={
            <Typography sx={{ fontSize: '10px', maxWidth: 166 }} variant={'body2'}>
              {'When will you start and finish the challenge?'}
            </Typography>
          }
          placement={xsDown ? 'bottom-end' : 'top-start'}
          enterTouchDelay={0}
          leaveTouchDelay={10000}
          sx={{ mx: 1, color: theme.palette.grey[600] }}
        >
          <Box
            sx={{
              marginLeft: '16px',
              marginBottom: '8px',
              display: 'flex',
              alignItems: 'center',
              width: 'fit-content',
            }}
          >
            <Typography
              sx={{
                fontWeight: 'bold',
                fontSize: 14,
                lineHeight: 1.4,
                color: 'text.primary',
                mr: '4px',
              }}
            >
              {'Range'}
            </Typography>
            <InfoOutlined style={{ width: '12px', height: '12px' }} fontSize={'inherit'} color={'inherit'} />
          </Box>
        </Tooltip>

        <CustomRangePicker
          disablePastWithToday={isCorporate}
          disablePast={true}
          mini={xsDown}
          setRange={handleChangeRange}
          range={range}
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            backgroundColor: Colors.green[50],
            borderRadius: '30px',
            padding: '5px 9px',
            mt: '16px',
          }}
        >
          <BulbIcon width={16} height={16} style={{ fill: Colors.green[600], flexShrink: 0 }} />
          <Typography sx={{ fontSize: 12, color: Colors.green[600], ml: '10px', whiteSpace: 'pre-line' }}>
            {`Range must be greater than 1 day\n Suggested duration of the challenge is ${suggestedDuration}`}
          </Typography>
        </Box>
      </Grid>

      {(showNumberOfWinners || isTeam) && (
        <Box sx={{ borderBottom: '1px dashed #F2F2F2', pb: showNumberOfWinners ? 0 : '16px' }}>
          <Typography
            sx={{ fontSize: '12px', lineHeight: '12px', color: Colors.gray[400], fontWeight: 700, mt: '16px' }}
          >
            Participants:
          </Typography>
          {isTeam && (
            <Box sx={{ mt: '12px' }}>
              <InputField
                bold={true}
                label={
                  <Tooltip
                    title={
                      <Typography sx={{ fontSize: '10px', maxWidth: 166 }} variant={'body2'}>
                        {
                          'Maximum number of team members should be calculated so as to have at least 10 teams within your organization. So for 100 employees, the max team size should be up to 10.'
                        }
                      </Typography>
                    }
                    placement={xsDown ? 'bottom-end' : 'top-start'}
                    enterTouchDelay={0}
                    leaveTouchDelay={10000}
                    sx={{ mx: 1, color: theme.palette.grey[600] }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: 'fit-content',
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          fontSize: 14,
                          lineHeight: 1.4,
                          color: 'text.primary',
                          mr: '4px',
                        }}
                      >
                        {'Max team size'}
                      </Typography>
                      <InfoOutlined style={{ width: '12px', height: '12px' }} fontSize={'inherit'} color={'inherit'} />
                    </Box>
                  </Tooltip>
                }
                setValue={(e) => handleChangeTeamSize(e.target.value)}
                name="steps"
                type="number"
                value={publishForm.teamSize}
                placeholder={'Enter number'}
                icon={<TeamIcon style={{ fill: Colors.gray[400] }} />}
              />
            </Box>
          )}
          {showNumberOfWinners && (
            <Box sx={{ mt: '20px', mb: '16px' }}>
              <InputField
                bold={true}
                label={
                  <Tooltip
                    title={
                      <Typography sx={{ fontSize: '10px', maxWidth: 166 }} variant={'body2'}>
                        {'How many people will win the award? We suggest up to 5% of employees headcount.'}
                      </Typography>
                    }
                    placement={xsDown ? 'bottom-end' : 'top-start'}
                    enterTouchDelay={0}
                    leaveTouchDelay={10000}
                    sx={{ mx: 1, color: theme.palette.grey[600] }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: 'fit-content',
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          fontSize: 14,
                          lineHeight: 1.4,
                          color: 'text.primary',
                          mr: '4px',
                        }}
                      >
                        {'Number of winners'}
                      </Typography>
                      <InfoOutlined style={{ width: '12px', height: '12px' }} fontSize={'inherit'} color={'inherit'} />
                    </Box>
                  </Tooltip>
                }
                setValue={(e) => handleChangeWinners(e.target.value)}
                name="steps"
                type="number"
                value={publishForm.winners}
                placeholder={'Enter number'}
                icon={<StarIcon style={{ fill: Colors.gray[400] }} />}
              />
            </Box>
          )}
        </Box>
      )}

      <Box sx={{ mt: '16px' }}>
        <Typography sx={{ fontSize: '12px', lineHeight: '12px', color: Colors.gray[400], fontWeight: 700, mt: '16px' }}>
          Discussion board:
        </Typography>
        <Box sx={{ mt: '10px' }}>
          <InputField
            bold={true}
            label={''}
            setValue={(e) => handleChangeSlackUrl(e.target.value)}
            name="steps"
            value={publishForm.slackUrl}
            placeholder={'Enter Slack link'}
          />
        </Box>
      </Box>
    </Box>
  );
};
