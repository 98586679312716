import { useEffect, useState } from 'react';
import { FieldsName } from '../../NotificationsSettingsPage/hooks/useSettingsHandle';
import { ChallengeNotificationSettingsForm, NotificationChallengeSettings } from '../../../models/NotificationSettings';
import {
  getChallengeNotificationsSettings,
  updateChallengeNotificationSettings,
} from '../../../lib/api/http/requests/notification';
import { Challenge } from '../../../models/Challenge';

const InitialSettingsForm: ChallengeNotificationSettingsForm = {
  challengeUUID: '',
  title: '',
  receive: true,
  email: true,
  push: true,
  updates: true,
  reminders: true,
};
export const useNotificationsHandle = (challenge: Challenge | null) => {
  const [challengeSettings, setChallengeSettings] = useState<ChallengeNotificationSettingsForm>(InitialSettingsForm);
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  useEffect(() => {
    if (!challenge) return;
    const getSettings = async () => {
      if (!challenge.metadata?.challengeUUID) return;
      const { data } = await getChallengeNotificationsSettings(challenge.metadata.challengeUUID);

      if (!data) return;
      const challengesSettings: ChallengeNotificationSettingsForm = {
        challengeUUID: data.challengeUUID,
        title: data.title,
        receive: Boolean(data.isChallengeUpdateEnabled || data.isReminderEnabled),
        email: data.isMailEnabled,
        push: data.isNotificationEnabled,
        updates: data.isChallengeUpdateEnabled,
        reminders: data.isReminderEnabled,
      };
      setChallengeSettings((prevState) => ({
        ...prevState,
        ...challengesSettings,
      }));
    };
    try {
      getSettings();
    } catch (e) {}
  }, [challenge]);

  const handleChallengeSettingsChange = async (index: number, name: FieldsName, value: boolean) => {
    const form: ChallengeNotificationSettingsForm = { ...challengeSettings };

    if (name === FieldsName.RECEIVE) {
      form.receive = value;
      form.reminders = value;
      form.updates = value;
    }
    if (name === FieldsName.EMAIL) {
      form.email = value;
    }
    if (name === FieldsName.PUSH) {
      form.push = value;
    }
    if (name === FieldsName.UPDATES) {
      form.updates = value;
    }
    if (name === FieldsName.REMINDERS) {
      form.reminders = value;
    }

    if (name === FieldsName.REMINDERS && (value || form.updates)) {
      form.receive = true;
    }
    if (name === FieldsName.UPDATES && (form.reminders || value)) {
      form.receive = true;
    }

    if (name === FieldsName.UPDATES && !form.reminders && !value) {
      form.receive = false;
    }
    if (name === FieldsName.REMINDERS && !value && !form.updates) {
      form.receive = false;
    }

    setChallengeSettings(form);
    await updateSettings(form);
  };

  const updateSettings = async (form: ChallengeNotificationSettingsForm) => {
    try {
      setIsSaveLoading(true);
      const challengesSetting: NotificationChallengeSettings = {
        challengeUUID: form?.challengeUUID,
        title: form.title,
        isMailEnabled: form.email,
        isNotificationEnabled: form.push,
        isReminderEnabled: form.reminders,
        isChallengeUpdateEnabled: form.updates,
      };

      const response = await updateChallengeNotificationSettings(challengesSetting);
      setIsSaveLoading(false);
    } catch (e) {
      setIsSaveLoading(false);
    }
  };

  return { challengeSettings, isSaveLoading, handleChallengeSettingsChange };
};
