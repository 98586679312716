import React, { useEffect, useState } from 'react';
import CustomSearchbar from '../../../components/features/CustomSearchbar';

type SearchBarProps = {
  searchInput: string;
  setSearchInput: (val: string) => void;
};

export const SearchBar: React.FC<SearchBarProps> = ({ searchInput, setSearchInput }) => {
  const [search, setSearch] = useState('');
  useEffect(() => {
    const timeoutCallback = setTimeout(() => setSearchInput(search), 1000);
    return () => clearTimeout(timeoutCallback);
  }, [search]);

  useEffect(() => {
    setSearch(searchInput);
  }, [searchInput]);
  return <CustomSearchbar width={'100%'} searchInput={search} setSearchInput={setSearch} />;
};
