import { getRequest, postRequest } from '../../../../services/HttpService';
import { IMentalAssessmentForm } from '../../../../models/MentalAssessment';

export const getResult = (activity: string) => {
  return getRequest(`/metrics-service/thymia/activity/result`, { activity: activity });
};

export const startPoll = (data: { activityId: string }) => {
  return postRequest(`/metrics-service/thymia/activity/startpoll?activityId=${data.activityId}`);
};
export const submitInitialise = (data: IMentalAssessmentForm) => {
  return postRequest('/metrics-service/thymia/activity/initialise', { ...data });
};
export const getResultsRequest = (params: { page: number; size: number }) => {
  return getRequest('/metrics-service/thymia/activity/results', {
    page: `${params.page || 0}`,
    size: `${params.size || 10}`,
  });
};
export const getResultsLatest = () => {
  return getRequest('/metrics-service/thymia/activity/latest');
};
export const getIsUserEligible = () => {
  return getRequest('/metrics-service/thymia/activity/eligible');
};
