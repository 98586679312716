import { Box, Link, Skeleton, Typography } from '@mui/material';
import React from 'react';
import { Colors } from '../../../theme/colors';
import { ReactComponent as SyncIcon } from '../../../assets/svg/feature/sync.svg';
import { ReactComponent as EmployIcon } from '../../../assets/svg/feature/employees.svg';
import { useHandleLeaderBoard } from '../hooks/useHandleLederBoard';
import { LeaderBoardProgressWrapper } from '../../../components/features/progress/ProgressWrapper';
import { LeaderBoardList } from '../../../components/features/progress/LeaderBoardList';
import { LeaderBoardModal } from '../../../components/features/progress/LeaderBoardModal';
import { pluralWordRule } from '../../../utils/pluralWordRule';

export const LeaderBoardLayout: React.FC = () => {
  const {
    isLoading,
    visible,
    lastSync,
    participantsNumber,
    leaderBoardList,
    position,
    userUUID,
    outLeaderBoardList,
    target,
    type,
    isGracePeriodEnd,
    triggerLimit,
    isTeam,
    openModal,
    closeModal,
    listRef,
    handleScrollEvent,
  } = useHandleLeaderBoard();
  return (
    <>
      <LeaderBoardProgressWrapper>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            paddingInline: '24px',
          }}
        >
          <Typography sx={{ fontWeight: 900, fontSize: '20px', color: Colors.gray[700] }}>Leaderboard</Typography>
          <Link sx={{ fontSize: '12px', cursor: 'pointer' }} onClick={openModal}>
            View full
          </Link>
        </Box>
        <Box sx={{ paddingInline: '8px', marginTop: '24px' }}>
          <LeaderBoardList
            listRef={listRef}
            handleScrollEvent={handleScrollEvent}
            isLoading={isLoading}
            data={leaderBoardList}
            place={position}
            userUuid={userUUID}
            target={target}
            outLeaderBoardList={outLeaderBoardList}
            type={type}
            isGracePeriodEnd={isGracePeriodEnd}
            triggerLimit={triggerLimit}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            paddingInline: '24px',
            marginTop: '32px',
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <SyncIcon height="16px" width="16px" fill="#AAC3BB" style={{ marginRight: '8px' }} />
            {isLoading ? (
              <Skeleton variant="rectangular" sx={{ height: 12, width: 100, borderRadius: '4px' }} />
            ) : (
              <Typography sx={{ fontSize: '12px', color: '#AAC3BB', '& b': { fontWeight: 700 } }}>
                Last sync: <b>{lastSync}</b>
              </Typography>
            )}
          </Box>
          <Box sx={{ display: 'flex' }}>
            <EmployIcon height="16px" width="16px" fill="#AAC3BB" style={{ marginRight: '8px' }} />
            {isLoading ? (
              <Skeleton variant="rectangular" sx={{ height: 12, width: 100, borderRadius: '2px' }} />
            ) : (
              <Typography sx={{ fontSize: '12px', color: '#AAC3BB', '& b': { fontWeight: 700 } }}>
                <b>{participantsNumber}</b>{' '}
                {isTeam
                  ? `team${pluralWordRule(participantsNumber)}`
                  : `participant${pluralWordRule(participantsNumber)}`}
              </Typography>
            )}
          </Box>
        </Box>
      </LeaderBoardProgressWrapper>
      <LeaderBoardModal
        listRef={listRef}
        handleScrollEvent={handleScrollEvent}
        visible={visible}
        onClose={closeModal}
        data={leaderBoardList}
        target={target}
        isLoading={isLoading}
        type={type}
        isGracePeriodEnd={isGracePeriodEnd}
        triggerLimit={triggerLimit}
        userUUID={userUUID}
      />
    </>
  );
};
