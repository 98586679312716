import React from 'react';
import { Challenge } from '../../../models/Challenge';
import { Box, useMediaQuery } from '@mui/material';
import { CustomButton } from '../../../components/features/CustomButton';
import theme from '../../../theme';
import { ReactComponent as PlusIcon } from '../../../assets/svg/plus.svg';
import { CHALLENGE_SUB_CATEGORY } from '../../../models/enum/CHALLENGE_SUB_CATEGORY';

type Props = {
  challenge: Challenge;
  onJoin: () => void;
  onAbort: () => void;
};
export const Actions: React.FC<Props> = ({ challenge, onJoin, onAbort }) => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (!challenge.challengeParticipation.isParticipationLocked &&
    !challenge.challengeParticipation.isUserParticipant) ||
    (challenge.challengeParticipation.isParticipationLocked &&
      challenge.challengeParticipation.isUserParticipant &&
      challenge.challengeSubCategory !== CHALLENGE_SUB_CATEGORY.FINISHED) ? (
    <Box sx={{ mt: mdDown ? 0 : '50px' }}>
      {!challenge.challengeParticipation.isUserParticipant && (
        <CustomButton
          sx={{
            maxWidth: '100%',
            height: mdDown ? 56 : 48,
            boxShadow: mdDown ? '4px 4px 15px rgba(22, 190, 207, 0.35)' : 'none',
          }}
          minWidth={mdDown ? '118px' : '94px'}
          inputProps={{
            variant: 'contained',
            startIcon: <PlusIcon width={12} height={12} />,
          }}
          type={'bigBlue'}
          text={'Join'}
          onClick={onJoin}
        />
      )}
      {challenge.challengeParticipation.isUserParticipant && (
        <CustomButton
          sx={{
            maxWidth: '100%',
            height: mdDown ? 56 : 48,
            boxShadow: mdDown ? '4px 4px 15px rgba(137, 149, 157, 0.15)' : 'none',
          }}
          minWidth={mdDown ? '118px' : '94px'}
          type={'bigLightBlue'}
          text={'Abort'}
          onClick={onAbort}
        />
      )}
    </Box>
  ) : null;
};
