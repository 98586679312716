import React from 'react';
import { LeaderBoardProgressWrapper } from '../../../components/features/progress/ProgressWrapper';
import { Box, Link, List, Skeleton, Typography } from '@mui/material';
import { Colors } from '../../../theme/colors';
import { useHandlePreviousInstances } from '../hooks/useHandlePreviousInstances';
import { ReactComponent as SyncIcon } from '../../../assets/svg/feature/sync.svg';
import moment from 'moment';
import { InstanceListItem } from '../../../components/features/progress/InstanceListItem';
import { StatisticModal } from '../components/StatisticModal';
import { InstancesModal } from '../../../components/features/progress/InstancesModal';

export const PreviousInstancesLayout: React.FC = () => {
  const {
    visible,
    range,
    selected,
    isLoading,
    lastSync,
    instances,
    focusedInstance,
    graph,
    points,
    isBoolean,
    target,
    previousScore,
    startDate,
    isModal,
    handleOpenInstanceModal,
    handleCloseInstanceModal,
    handleChangeRange,
    handleOpenModal,
    handleCloseModal,
    onChangeSelect,
  } = useHandlePreviousInstances();
  return (
    <LeaderBoardProgressWrapper>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
          paddingInline: '24px',
        }}
      >
        <Typography sx={{ fontWeight: 900, fontSize: '20px', color: Colors.gray[700] }}>Comparisons</Typography>
        <Link sx={{ fontSize: '12px', cursor: 'pointer' }} onClick={handleOpenInstanceModal}>
          View full
        </Link>
      </Box>
      <Box sx={{ paddingInline: '8px', marginTop: '24px' }}>
        {isLoading ? (
          new Array(5).fill('1').map((_, idx) => (
            <Skeleton
              key={`leaderBoard${idx}`}
              variant="rectangular"
              sx={{
                width: '100%',
                height: '64px',
                marginLeft: '8px',
                background: Colors.gray[300],
                borderRadius: '10px',
                marginTop: '16px',
                marginBottom: '16px',
              }}
            />
          ))
        ) : (
          <List
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
              paddingInline: '16px',
              maxHeight: { xs: 416, table: 'unset' },
              height: {
                xs: 320,
                table: 704,
                md: 400,
              },
              overflow: 'auto',
              '&::-webkit-scrollbar': {
                width: '5px !important',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#D3D7DA',
              },
            }}
          >
            {instances.map((item) => {
              const label = `${moment(item.startDate).format('DD MMM')} - ${moment(item.endDate).format('DD MMM')}`;
              return (
                <InstanceListItem
                  key={label}
                  label={label}
                  score={item.score}
                  place={item.place}
                  target={target}
                  scoreToPrevious={previousScore}
                  isCurrent={item.startDate === startDate}
                  isAwarded={item.isAwarded}
                  onClick={handleOpenModal(item.label)}
                />
              );
            })}
          </List>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          paddingInline: '24px',
          marginTop: '32px',
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <SyncIcon height="16px" width="16px" fill="#AAC3BB" style={{ marginRight: '8px' }} />
          {isLoading ? (
            <Skeleton variant="rectangular" sx={{ height: 12, width: 100, borderRadius: '4px' }} />
          ) : (
            <Typography sx={{ fontSize: '12px', color: '#AAC3BB', '& b': { fontWeight: 700 } }}>
              Last sync: <b>{lastSync}</b>
            </Typography>
          )}
        </Box>
      </Box>
      <StatisticModal
        visible={visible}
        onClose={handleCloseModal}
        range={range}
        graph={graph}
        points={points}
        startDate={focusedInstance?.startDate}
        endDate={focusedInstance?.endDate}
        setRange={handleChangeRange}
        selectOptions={instances.map((item) => item.label)}
        selected={selected}
        setSelected={onChangeSelect}
        isBoolean={isBoolean}
        bestScore={focusedInstance?.bestScore}
        dailyAverage={focusedInstance?.dailyAverage}
        isSelf
      />
      <InstancesModal
        visible={isModal}
        onClose={handleCloseInstanceModal}
        data={instances}
        target={target}
        isLoading={isLoading}
        previousScore={previousScore}
        startDate={startDate}
        handleOpenModal={handleOpenModal}
      />
    </LeaderBoardProgressWrapper>
  );
};
