import React, { FC } from 'react';
import { Box, CircularProgress, DialogContent, DialogTitle, IconButton, Link, Typography } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../../assets/svg/feature/close.svg';
import { ReactComponent as PlusIcon } from '../../../assets/svg/plus.svg';
import { Colors } from '../../../theme/colors';
import { CustomButton } from '../CustomButton';
import { Challenge } from '../../../models/Challenge';
import { CustomInfoDialog } from './CustomInfoDialog';
import { useIonRouter } from '@ionic/react';

interface ModalProps {
  show: boolean;
  showMore?: boolean;
  title: string;
  challenge: Challenge | null;
  loading: boolean;
  url: string;
  onClose: () => void;
  onJoin: (params?: { action: string; startDate: string; endDate: string }) => void;
}

export const TeamJoinModal: FC<ModalProps> = ({
  show = false,
  showMore = true,
  loading,
  title,
  url,
  onClose,
  onJoin,
}) => {
  const router = useIonRouter();
  return (
    <CustomInfoDialog show={show}>
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 2,
            background: 'rgba(65, 74, 79, 0.3)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress color="inherit" style={{ width: 30, height: 30 }} />
        </Box>
      )}

      <DialogTitle display={'flex'} flexDirection={'column'} sx={{ padding: '16px 16px 0 16px' }}>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <IconButton sx={{ width: 28, height: 28, backgroundColor: Colors.gray[50] }} onClick={onClose}>
            <CloseIcon style={{ width: 12, height: 12 }} fill={Colors.gray[400]} />
          </IconButton>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mt: '6px', maxWidth: '296px', mx: 'auto' }}
        >
          <Typography
            variant="h5"
            sx={{ textAlign: 'center', color: Colors.gray[700], fontWeight: 'bold', fontSize: 18 }}
          >
            Join the team
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent
        sx={{
          padding: '0 16px 0 16px',
          height: 'auto',
        }}
      >
        <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" sx={{ mt: '10px' }}>
          <Typography variant="h5" sx={{ textAlign: 'center', color: Colors.gray[500], fontWeight: 400, fontSize: 16 }}>
            Are you sure you want to join the team?
          </Typography>

          {showMore && (
            <Link sx={{ fontSize: 12, color: Colors.blue[500], cursor: 'pointer' }} onClick={() => router.push(url)}>
              More details...
            </Link>
          )}
        </Box>
      </DialogContent>

      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{ margin: '24px auto 50px auto', maxWidth: 320, width: '100%' }}
      >
        <CustomButton
          type={'bigBlue'}
          text={'Join'}
          inputProps={{
            variant: 'contained',
            startIcon: <PlusIcon width={12} height={12} />,
          }}
          onClick={onJoin}
        />
        <CustomButton type={'bigLightBlue'} text={'Cancel'} onClick={onClose} />
      </Box>
    </CustomInfoDialog>
  );
};
