import { Colors } from '../../theme/colors';
import { ReactComponent as LeaderboardIcon } from '../../assets/svg/feature/challengeType/leaderboard.svg';
import { ReactComponent as LongerStreakIcon } from '../../assets/svg/feature/challengeType/longer-streak.svg';
import { ReactComponent as StickToItIcon } from '../../assets/svg/feature/challengeType/stick-to-it.svg';
import { ReactComponent as TargetIcon } from '../../assets/svg/feature/challengeType/target.svg';
import { PreviewRoundItemProps } from '../Shared';
import { CHALLENGE_TYPE_LABEL } from './CHALLENGE_TYPE_LABEL';
import { CHALLENGE_TYPE } from './CHALLENGE_TYPE';
export const CHALLENGE_TYPE_LIST: PreviewRoundItemProps[] = [
  {
    value: CHALLENGE_TYPE.LEADERBOARD,
    label: CHALLENGE_TYPE_LABEL.LEADERBOARD,
    Icon: LeaderboardIcon,
    mainColor: Colors.magenta[300],
    backgroundColor: Colors.magenta[400],
    borderColor: Colors.magenta[200],
  },
  {
    value: CHALLENGE_TYPE.LONGER_STREAK,
    label: CHALLENGE_TYPE_LABEL.LONGER_STREAK,
    Icon: LongerStreakIcon,
    mainColor: Colors.magenta[300],
    backgroundColor: Colors.magenta[400],
    borderColor: Colors.magenta[200],
  },
  {
    value: CHALLENGE_TYPE.STICK_TO_IT,
    label: CHALLENGE_TYPE_LABEL.STICK_TO_IT,
    Icon: StickToItIcon,
    mainColor: Colors.magenta[300],
    backgroundColor: Colors.magenta[400],
    borderColor: Colors.magenta[200],
  },
  {
    value: CHALLENGE_TYPE.TARGET,
    label: CHALLENGE_TYPE_LABEL.TARGET,
    Icon: TargetIcon,
    mainColor: Colors.magenta[300],
    backgroundColor: Colors.magenta[400],
    borderColor: Colors.magenta[200],
  },
];
