import { useDispatch } from 'react-redux';
import { Blog } from '../../../../models/Blog';
import { ANALYTICS_PAGE } from '../../../../models/enum/ANALYTICS_PAGE';
import { trackEvent } from '../../../../utils/analytics';
import { emitEventEngagement } from '../../../../redux/slices/focusPageSlice';
import { handlerTrackEvent } from '../../../../utils/handlerTrackEvent';

export const useHandlePostCard = () => {
  const dispatch = useDispatch();

  const getPostCardProps = (blog: Blog, moduleUUID?: string, tagClick?: (val: string) => void) => {
    const category = blog.type ?? '';
    const analyticsPage = ANALYTICS_PAGE.FOCUS;
    const ctaLink = blog.location ?? '';

    const handleOpenLink = () => {
      trackEvent('blog_cta', { page: analyticsPage, content_type: category });
      handlerTrackEvent({ type: 'CONTENT', uuid: blog.contentUUID });
      if (moduleUUID && category) {
        dispatch(emitEventEngagement({ content_type: category, module_uuid: moduleUUID }));
      }
      if (ctaLink) window.open(ctaLink, '_blank');
    };

    const handleTagClick = tagClick
      ? (t: string) => {
          if (tagClick) tagClick(t);
        }
      : undefined;

    return {
      title: blog.title ?? '',
      img: blog.image,
      date: blog.creationDate,
      content: blog.description ?? '',
      category,
      hashtags: blog.tags ?? [],
      ctaText: blog.ctaText ?? 'Read more',
      author:
        blog.authorThumbnail || blog.authorName || blog.authorJob
          ? {
              img: blog?.authorThumbnail,
              name: blog?.authorName,
              occupation: blog?.authorJob,
            }
          : undefined,
      handleOpenLink,
      handleTagClick,
    };
  };
  return {
    getPostCardProps,
  };
};
