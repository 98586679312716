import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  styled,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ReactComponent as CloseIcon } from '../../../assets/svg/feature/close.svg';
import { ReactComponent as PlusIcon } from '../../../assets/svg/plus.svg';
import { ReactComponent as BulbIcon } from '../../../assets/svg/feature/bulb.svg';
import { Colors } from '../../../theme/colors';
import { CustomButton } from '../CustomButton';
import { CHALLENGE_STATUS } from '../../../models/enum/CHALLENGE_STATUS';
import { useIonRouter } from '@ionic/react';
import { Challenge, ChallengeProgress } from '../../../models/Challenge';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomRangePicker from '../../common/CustomRangePicker';
import { Range } from 'react-date-range';
import theme from '../../../theme';
import { TEMPLATE_TYPE } from '../../../models/enum/TEMPLATE_TYPE';
import { InfoOutlined } from '@mui/icons-material';
import moment from 'moment/moment';
import { CustomInfoDialog } from './CustomInfoDialog';

interface ModalProps {
  show: boolean;
  showMore?: boolean;
  title: string;
  challenge: Challenge | ChallengeProgress | null;
  loading: boolean;
  onClose: () => void;
  onJoin: (params?: { action?: string; startDate: string; endDate: string }) => void;
}

const StyledSelect = styled(Select)({
  fontSize: 14,
  fontWeight: 700,
  background: 'white',
  borderRadius: '999px',
  paddingInline: '8px',
  boxShadow: 'var(--shadow-button)',
  '& *': {
    border: 'none !important',
  },
});

const StyledMenuItem = styled(MenuItem)({
  marginBottom: '4px',
  fontSize: '14px',
  borderRadius: '20px',
  fontWeight: '700',
  whiteSpace: 'pre-wrap',
  '&.Mui-selected': {
    backgroundColor: '#E2E8ED',
    fontWeight: '900',
  },
});
const MenuProps = {
  PaperProps: {
    style: {
      padding: '4px 8px',
      maxHeight: '400px',
      borderRadius: '20px',
      marginTop: '8px',
      boxShadow: 'var(--shadow-button)',
    },
  },
};

export const ChallengeJoinModal: FC<ModalProps> = ({
  show = false,
  showMore = true,
  challenge,
  loading,
  title,
  onClose,
  onJoin,
}) => {
  const router = useIonRouter();
  const [actions, setActions] = useState<string[]>([]);
  const [range, setRange] = useState<Range[]>([]);
  const [isWinChallenge, setIsWinChallenge] = useState(false);
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));
  const breakpointDown = useMediaQuery(theme.breakpoints.down('sm'));

  const actionList = challenge?.challengeAction.action?.split('|');
  const joinRequest = () => {
    if (challenge?.challengeCharacteristic.templateType !== TEMPLATE_TYPE.WIN) {
      onJoin();
      return;
    }
    const params = {
      action: actionList ? actions.join(' | ') : undefined,
      startDate: moment(range[0].startDate).format('YYYY-MM-DD').toString(),
      endDate: moment(range[0].endDate).format('YYYY-MM-DD').toString(),
    };
    onJoin(params);
  };

  useEffect(() => {
    setRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ]);
    setActions([]);
    setIsWinChallenge(challenge?.challengeCharacteristic.templateType === TEMPLATE_TYPE.WIN);
  }, [challenge]);
  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;

    setActions(typeof value === 'string' ? value.split(',') : value);
  };
  const handleOpenLink = () => {
    if (!challenge) return;
    const uuid =
      challenge.challengeStatus === CHALLENGE_STATUS.TEMPLATE
        ? challenge.metadata.templateChallengeUUID
        : challenge.metadata.challengeUUID;
    const queryParam = challenge.challengeStatus === CHALLENGE_STATUS.TEMPLATE ? '?isTemplate=true' : '';

    if (
      challenge.challengeParticipation.isUserParticipant &&
      (challenge.challengeStatus === CHALLENGE_STATUS.ACTIVE ||
        challenge.challengeStatus === CHALLENGE_STATUS.COMPLETED ||
        challenge.challengeStatus === CHALLENGE_STATUS.IN_GRACE_PERIOD)
    ) {
      onClose();
      router.push(`/page/your-challenges/challenge/${uuid}/activity`);
      return;
    }

    onClose();
    router.push(`/page/your-challenges/challenge/${uuid}${queryParam}`);
  };

  const isSameDates =
    isWinChallenge && range.length
      ? moment(range[0].startDate).isSame(range[0].endDate, 'day') &&
        moment(range[0].startDate).isSame(range[0].endDate, 'month') &&
        moment(range[0].startDate).isSame(range[0].endDate, 'year')
      : false;

  return (
    <CustomInfoDialog show={show}>
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 2,
            background: 'rgba(65, 74, 79, 0.3)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress color="inherit" style={{ width: 30, height: 30 }} />
        </Box>
      )}

      <DialogTitle display={'flex'} flexDirection={'column'} sx={{ padding: '16px 16px 0 16px' }}>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <IconButton sx={{ width: 28, height: 28, backgroundColor: Colors.gray[50] }} onClick={onClose}>
            <CloseIcon style={{ width: 12, height: 12 }} fill={Colors.gray[400]} />
          </IconButton>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mt: '6px', maxWidth: '296px', mx: 'auto' }}
        >
          <Typography
            variant="h5"
            sx={{ textAlign: 'center', color: Colors.gray[700], fontWeight: 'bold', fontSize: 18 }}
          >
            {`Join ${title}`}
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent
        sx={{
          padding: '0 16px 16px 16px',
          height: 'auto',
        }}
      >
        <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" sx={{ mt: '10px' }}>
          <Typography variant="h5" sx={{ textAlign: 'center', color: Colors.gray[500], fontWeight: 400, fontSize: 16 }}>
            Are you sure you want to join the challenge?
          </Typography>

          {showMore && (
            <Link sx={{ fontSize: 12, color: Colors.blue[500], cursor: 'pointer' }} onClick={handleOpenLink}>
              More details...
            </Link>
          )}

          {isWinChallenge && (
            <Grid>
              <Grid sx={{ marginTop: '28px' }}>
                <Tooltip
                  title={
                    <Typography sx={{ fontSize: '10px', maxWidth: 166 }} variant={'body2'}>
                      {'When will you start and finish the challenge?'}
                    </Typography>
                  }
                  placement={xsDown || breakpointDown ? 'bottom-end' : 'top-start'}
                  enterTouchDelay={0}
                  leaveTouchDelay={10000}
                  sx={{ mx: 1, color: theme.palette.grey[600] }}
                >
                  <Box
                    sx={{
                      marginLeft: '16px',
                      marginBottom: '8px',
                      display: 'flex',
                      alignItems: 'center',
                      width: 'fit-content',
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        fontSize: 16,
                        color: 'text.primary',
                        mr: '4px',
                      }}
                    >
                      {'Range'}
                    </Typography>
                    <InfoOutlined style={{ width: '12px', height: '12px' }} fontSize={'inherit'} color={'inherit'} />
                  </Box>
                </Tooltip>

                <CustomRangePicker
                  disablePast={true}
                  mini={xsDown}
                  setRange={setRange}
                  range={range}
                  // moveRangeOnFirstSelection={!!challenge?.challengeDuration?.suggestedDurationToInt}
                />
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: Colors.green[50],
                    borderRadius: '30px',
                    padding: '5px 9px',
                    mt: '16px',
                  }}
                >
                  <BulbIcon width={16} height={16} style={{ fill: Colors.green[600] }} />
                  <Typography
                    sx={{ fontSize: 12, lineHeight: 1.5, color: Colors.green[600], ml: '10px', whiteSpace: 'pre-line' }}
                  >
                    {challenge?.challengeDuration?.suggestedDuration?.length
                      ? `Range must be greater than 1 day\n Suggested duration of the challenge is ${challenge.challengeDuration.suggestedDuration}`
                      : 'Range must be greater than 1 day'}
                  </Typography>
                </Box>
              </Grid>

              {actionList && (
                <FormControl sx={{ width: '336px', position: 'relative', marginTop: '28px' }}>
                  <Tooltip
                    title={
                      <Typography sx={{ fontSize: '10px', maxWidth: 166 }} variant={'body2'}>
                        {'Which actions will you work on? Please select one or more.'}
                      </Typography>
                    }
                    placement={xsDown || breakpointDown ? 'bottom-end' : 'top-start'}
                    enterTouchDelay={0}
                    leaveTouchDelay={10000}
                    sx={{ mx: 1, color: theme.palette.grey[600] }}
                  >
                    <Box
                      sx={{
                        marginLeft: '16px',
                        marginBottom: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        width: 'fit-content',
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          fontSize: 16,
                          color: 'text.primary',
                          mr: '4px',
                        }}
                      >
                        {'Action'}
                      </Typography>
                      <InfoOutlined style={{ width: '12px', height: '12px' }} fontSize={'inherit'} color={'inherit'} />
                    </Box>
                  </Tooltip>
                  <StyledSelect
                    displayEmpty
                    id="multiple-actions"
                    input={<OutlinedInput />}
                    multiple
                    value={actions}
                    onChange={handleChange}
                    sx={{ height: 40 }}
                    IconComponent={ExpandMoreIcon}
                    MenuProps={MenuProps}
                    inputProps={{ 'aria-label': 'Without label' }}
                    renderValue={(selected) => {
                      if ((selected as string[]).length === 0) {
                        return (
                          <em
                            style={{ fontSize: '16px', fontWeight: 600, color: Colors.gray[200], fontStyle: 'normal' }}
                          >
                            Select action
                          </em>
                        );
                      }

                      return (selected as string[]).join(', ');
                    }}
                  >
                    <MenuItem disabled value="">
                      <em>{'Select action'}</em>
                    </MenuItem>
                    {actionList.map((name) => (
                      <StyledMenuItem key={name} value={name}>
                        {name}
                      </StyledMenuItem>
                    ))}
                  </StyledSelect>
                </FormControl>
              )}
            </Grid>
          )}
        </Box>
      </DialogContent>

      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{ margin: '24px auto 50px auto', maxWidth: 320, width: '100%' }}
      >
        <CustomButton
          disabled={(isWinChallenge && !actions.length && !!actionList) || isSameDates}
          type={'bigBlue'}
          text={'Join'}
          inputProps={{
            variant: 'contained',
            startIcon: <PlusIcon width={12} height={12} />,
          }}
          onClick={joinRequest}
        />
        <CustomButton type={'bigLightBlue'} text={'Cancel'} onClick={onClose} />
      </Box>
    </CustomInfoDialog>
  );
};
