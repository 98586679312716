import { deleteRequest, getRequest, postRequest, putRequest } from '../../../../services/HttpService';

export const getTeams = (params: { challengeUUID: string; page?: number; size?: number; searchText: string }) => {
  return getRequest(
    `/gamification-service/challenge/teams/?challengeUUID=${params.challengeUUID}&page=${params.page || 0}&size=${
      params.size || 10
    }${params.searchText.length ? '&name=' + params.searchText.trim() : ''}`,
  );
};
export const joinToTeam = (params: { challengeUUID: string; teamUUID: string }) => {
  return putRequest(
    `/gamification-service/challenge/team/join?teamUUID=${params.teamUUID}&challengeUUID=${params.challengeUUID}`,
  );
};
export const abortTeam = (params: { challengeUUID: string; teamUUID: string }) => {
  return putRequest(
    `/gamification-service/challenge/team/abort?teamUUID=${params.teamUUID}&challengeUUID=${params.challengeUUID}`,
  );
};
export const createTeam = (
  challengeUUID: string,
  params: {
    name: string;
    imageURL?: string;
    base64File?: {
      file: string;
    };
    teamSlackURL: string;
  },
) => {
  return postRequest(`/gamification-service/challenge/team?challengeUUID=${challengeUUID}`, { ...params });
};
export const getTeamMembers = (params: { teamUUID: string; page?: number; size?: number; searchText: string }) => {
  return getRequest(
    `/gamification-service/challenge/team/participants?teamUUID=${params.teamUUID}&page=${params.page || 0}&size=${
      params.size || 10
    }${params.searchText.length ? '&name=' + params.searchText.trim() : ''}`,
  );
};
export const getTeamDetails = (params: { teamUUID: string }) => {
  return getRequest(`/gamification-service/challenge/team/details?teamUUID=${params.teamUUID}`);
};
export const deleteTeam = (params: { teamUUID: string }) => {
  return deleteRequest('/gamification-service/challenge/team', { ...params });
};
export const getTeamEmails = (params: { teamUUID: string }) => {
  return getRequest(`/gamification-service/challenge/team/emails?teamUUID=${params.teamUUID}`);
};
export const updateTeam = (
  challengeUUID: string,
  teamUUID: string,
  params: {
    name: string;
    imageURL?: string;
    base64File?: {
      file: string;
    };
    teamSlackURL: string;
  },
) => {
  return putRequest(`/gamification-service/challenge/team?challengeUUID=${challengeUUID}&teamUUID=${teamUUID}`, {
    ...params,
  });
};
