import * as React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { GraphMetric } from '../../models/Metrics';
import moment from 'moment';
import { useEffect } from 'react';

interface WellicsLineChartProps {
  colors: string[];
  newCategoryColors?: string[];
  width?: string | number;
  height?: string | number;
  graph: GraphMetric;
  selectedGoal?: string;
  startDate?: number;
  endDate?: number;
  points?: PointAnnotations[];
  notCommonMaxValue?: boolean;
}

const metricsCategoryColors = ['#00B6BE', '#DA4E53', '#FAAF1F', '#C154B0'];

const reorderSvg = () => {
  const xcross = document.querySelector('.apexcharts-xcrosshairs');
  const gridBorders = document.querySelector('.apexcharts-grid-borders');
  if (xcross && gridBorders) {
    xcross.before(gridBorders);
  }
};

let localMetrics: Array<{
  group: string;
  metric_pillar: string;
  metric_type: string;
  title: string;
  metric_samples: Array<{
    date: string;
    value: number;
  }>;
}> = [];

let dashController = [0, 0, 0, 0];

const getSeries = ({
  graph,
  selectedGoal,
  colors,
  notCommonMaxValue,
  minDateDefault,
  maxDateDefault,
}: {
  graph: GraphMetric;
  selectedGoal?: string | string[];
  colors?: string[];
  notCommonMaxValue?: boolean;
  minDateDefault?: number;
  maxDateDefault?: number;
}) => {
  const lineColors = colors ?? metricsCategoryColors;

  const { metrics, goals } = graph;
  if (metrics) {
    let minDate = 0;
    let maxDate = 0;
    const foundMatchGoal = { found: false, name: '', color: '', value: 0 };
    const listMaxValue = metrics.map((m) => Math.max(...m.metric_samples.map((sample) => sample.value)));
    let series = metrics.slice(0, 4).map((m, i) => {
      const foundGoal = goals.find((g) => g.goal_category_title === m.title && g.goal_category_title === selectedGoal);
      let maxValue = 100;
      if (foundGoal && selectedGoal && typeof selectedGoal !== 'object') {
        maxValue = foundGoal.target_value;
        foundMatchGoal.found = true;
        foundMatchGoal.name = selectedGoal;
        foundMatchGoal.color = lineColors[i];
        foundMatchGoal.value = 100;
      } else
        maxValue = Math.max(...(notCommonMaxValue ? m.metric_samples.map((sample) => sample.value) : listMaxValue));

      let data = m.metric_samples.map((sample) => {
        const tempDate = Number(moment(sample.date).format('x'));
        if (minDate && maxDate) {
          minDate = Math.min(minDate, tempDate);
          maxDate = Math.max(maxDate, tempDate);
        } else {
          minDate = tempDate;
          maxDate = tempDate;
        }

        let percentValue = 0;
        if (maxValue === 0) {
          //do nothing
        } else {
          // const percentValue = Math.min(Math.max(Math.floor(Number((sample.value * 100) / maxValue)), 0), 100);
          percentValue = Math.max(Math.floor(Number((sample.value * 100) / maxValue)), 0);
        }

        return [tempDate, percentValue];
      });

      if (data.length === 1) data = [...data, ...data];
      // data.sort((a, b) => (a[0] < b[0] ? -1 : 1));
      return {
        name: m.title,
        color: lineColors[i],
        data: data,
      };
    });

    if (foundMatchGoal.found) {
      series = [
        {
          name: 'Goal ' + foundMatchGoal.name,
          color: foundMatchGoal.color,
          data: [
            [minDateDefault ?? minDate, foundMatchGoal.value],
            [maxDateDefault ?? maxDate, foundMatchGoal.value],
          ],
        },
        ...series,
      ];

      dashController = [8, 0, 0, 0, 0];
    } else {
      dashController = [0, 0, 0, 0];
    }
    return series;
  } else return undefined;
};

const WellicsLineChart: React.FC<WellicsLineChartProps> = ({
  colors,
  width = '100%',
  height,
  startDate,
  endDate,
  graph,
  selectedGoal,
  points = [],
  notCommonMaxValue = false,
  newCategoryColors,
}: WellicsLineChartProps) => {
  useEffect(() => {
    if (graph?.metrics?.length) {
      ApexCharts.exec('main-graph', 'resetSeries');
    }
    localMetrics = graph.metrics;
  }, [graph, selectedGoal]);

  const localSeries = getSeries({
    graph,
    selectedGoal,
    colors: newCategoryColors,
    notCommonMaxValue,
    minDateDefault: endDate,
    maxDateDefault: startDate,
  }) as ApexAxisChartSeries;

  const chartDataOptions: ApexOptions = {
    colors: colors,
    xaxis: {
      type: 'datetime',
      labels: {
        show: true,
        datetimeUTC: false,
        style: {
          colors: '#999999',
          fontSize: '10px',
        },
      },
      axisTicks: {
        show: false,
      },
      min: startDate,
      max: endDate,
    },
    yaxis: {
      show: false,
      min: 0,
      // max: 100,
      tickAmount: 4,
      crosshairs: {
        show: true,
      },
    },
    stroke: {
      width: 2,
      curve: 'smooth',
      dashArray: dashController,
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: true,
      // intersect: true,
      y: {
        formatter(val: number, opts: { dataPointIndex: number; seriesIndex: number }): string {
          const goalEnabled = dashController[0] !== 0;
          const foundMetric = localMetrics[goalEnabled ? opts.seriesIndex - 1 : opts.seriesIndex];

          if (foundMetric) {
            const foundData = foundMetric.metric_samples[opts.dataPointIndex];
            if (foundData) {
              if (foundMetric.metric_type === 'SLEEP_DURATION') {
                return String((foundData.value / 60).toFixed(1) + ' hrs');
              } else {
                return String(foundData.value);
              }
            } else return '-';
          } else if (goalEnabled) {
            const foundData = graph.goals.find((g) => g.goal_category_title === selectedGoal);
            if (foundData) {
              if (foundData.metric_type === 'SLEEP_DURATION') {
                return String((foundData.target_value / 60).toFixed(1) + ' hrs');
              } else {
                return String(foundData.target_value);
              }
            } else return '-';
          }
          return '-';
        },
      },
    },
    markers: {
      size: 4,
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: dashController[0] === 0 ? [0, 1, 2, 3] : [1, 2, 3, 4],
      formatter(): string {
        return '.';
      },
      style: {
        fontSize: '2px',
      },
      background: {
        borderRadius: 100,
        foreColor: 'transparent',
        dropShadow: {},
      },
    },
    chart: {
      events: {
        mounted: () => {
          const observer = new MutationObserver(reorderSvg);
          reorderSvg();
          const mainGraph = document.querySelector('#main-graph');
          if (mainGraph) {
            observer.observe(mainGraph, { childList: true });
          }
        },
      },
      toolbar: {
        show: false,
        tools: {
          download: false,
        },
      },
    },
    annotations: {
      points,
    },
  };

  return (
    <ReactApexChart
      id={'main-graph'}
      options={chartDataOptions}
      series={localSeries}
      type={'line'}
      width={width}
      height={height ?? 266}
    />
  );
};

export default React.memo(WellicsLineChart);
