import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#6EE2E4',
      main: '#00B6BE',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#FAF6EE',
      main: '#EDDFC3',
      dark: '#E6D2AA',
      contrastText: 'rgba(0, 0, 0, 0.81)',
    },
    text: {
      primary: '#414A4F',
      secondary: '#FFFFFF',
      disabled: '#8B8B8B',
    },
    error: {
      main: '#DA4E53',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#FAAF1F',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#73C41D',
      contrastText: '#FFFFFF',
    },
    background: {
      default: '#F0F2F3',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
    fontFamily: 'Nexa',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1115,
      lg: 1280,
      xl: 1920,
      table: 900,
      mobile: 400,
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

export default theme;

declare module '@mui/system' {
  interface BreakpointOverrides {
    table: true;
    mobile: true;
  }
}
