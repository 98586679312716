import React from 'react';
import { Box, Typography } from '@mui/material';
import { useRadialChart } from '../../../../hooks/useRadialChart';
import { Colors } from '../../../../theme/colors';
import { ReactComponent as WreathIcon } from '../../../../assets/svg/feature/small-wreath.svg';

type TargetProgress = {
  percent: number;
};

export const TargetProgress: React.FC<TargetProgress> = ({ percent }) => {
  const centerX = 100;
  const centerY = 100;
  const radius = 75;

  const { describeArc } = useRadialChart({ centerX, centerY, radius });
  return (
    <Box>
      <Typography sx={{ fontSize: 10, color: Colors.gray[500], mb: '8px' }}>Progress:</Typography>
      <Box display="flex" alignItems="center">
        <Box sx={{ position: 'relative', height: '32px', width: '32px', mr: '5px' }}>
          <svg height="100%" width="100%" viewBox="0 0 200 200">
            <path d={describeArc(percent, 100)} stroke={Colors.gray[50]} fill="transparent" strokeWidth={20} />
            {percent > 0 && (
              <path
                d={describeArc(0, percent)}
                stroke={percent >= 100 ? Colors.orange[500] : Colors.blue[700]}
                fill="transparent"
                strokeWidth={20}
              />
            )}
          </svg>
          {percent >= 100 && (
            <WreathIcon
              style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
              fill={Colors.yellow[300]}
            />
          )}
        </Box>
        <Typography sx={{ fontSize: '14px', fontWeight: '700', color: Colors.gray[700] }}>{percent}%</Typography>
      </Box>
    </Box>
  );
};
