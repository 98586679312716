import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getCompanyDetails,
  getProfileDetails,
  putCompanyDetails,
  putCompanyImage,
  putUserAvatar,
  putUserConsistencyRewards,
  putUserPassword,
  putUserUserPerformanceRewards,
  putUserPersonalDetails,
  putUserUsername,
  putCompanyConsistencyRewards,
  putCompanyPerformanceRewards,
  putEmailUnsubscribe,
  deleteAccount,
  deleteUserData,
  putTransferAdmin,
  chargebeeIntegrationRequest,
  getUserPoints,
  putQuestionnaireUnsubscribe,
} from '../../lib/api/http/requests/profile';
import { IAccountInfo, ICompanyInfo, PersonalDetails, UserPreference } from '../../models/Profile';
import { openToast } from './appSlice';
import { handleBackendError } from '../../utils/handleBackendError';
import { performLogout } from '../../utils/performLogout';
import { getRole } from '../../utils/getRole';
import { Capacitor } from '@capacitor/core';
import { InAppBrowser, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser';

export interface ProfileState {
  data: {
    points: number;
    accountInfo: IAccountInfo;
    companyInfo: ICompanyInfo;
  };
  meta: {
    adminModal: boolean;
    accountInfoLoading: boolean;
    companyInfoLoading: boolean;
    imageLoading: boolean;
  };
}

const initialState: ProfileState = {
  data: {
    points: 0,
    accountInfo: {
      last_admin: false,
      first_login: false,
      account_details: {
        email: '',
        password: '',
        username: '',
      },
      avatar: '',
      company_logo: '',
      personal_details: {
        date_of_birth: '',
        first_name: '',
        gender: '',
        height: 0,
        last_name: '',
      },
      rewards: {
        company_consistency_rewards: false,
        company_performance_rewards: false,
        consistency_rewards: false,
        performance_rewards: false,
      },
      user_preference: {
        metric_system: '',
        timezone: '',
      },
      user_roles: [],
      subscribed_emails: false,
      subscribed_questionnaires: false,
      country: null,
    },
    companyInfo: {
      company_details: {
        address: '',
        company_name: '',
        contact_email: '',
        country: '',
        employees_number: 0,
        industry: '',
        organisation_website: '',
        sector: '',
        size: '',
        state: '',
        slack_url: '',
      },
      company_logo: '',
      rewards: {
        consistency_rewards: false,
        performance_rewards: false,
      },
    },
  },
  meta: {
    accountInfoLoading: false,
    companyInfoLoading: false,
    adminModal: false,
    imageLoading: false,
  },
};

export const asyncGetAccountInfo = createAsyncThunk('profile/getAccountInfo', async (_, thunkAPI) => {
  try {
    thunkAPI.dispatch(setAccLoading(true));
    const { data } = await getProfileDetails();
    if (data) return data;
    else thunkAPI.rejectWithValue(null);
  } catch (error) {
    handleBackendError(error, 'Failed to retrieve Company Details');
  } finally {
    thunkAPI.dispatch(setImageLoading(false));
    thunkAPI.dispatch(setAccLoading(false));
  }
});

export const asyncGetCompanyInfo = createAsyncThunk('profile/getCompanyInfo', async (_, thunkAPI) => {
  if (getRole() === 'ADMIN')
    try {
      thunkAPI.dispatch(setCompLoading(true));
      const { data } = await getCompanyDetails();
      return data;
    } catch (error) {
      handleBackendError(error, 'Failed to retrieve Company Details');
    } finally {
      thunkAPI.dispatch(setCompLoading(false));
    }
});

export const asyncSaveAccountInfo = createAsyncThunk(
  'profile/saveAccountInfo',
  async (
    params: { personalDetails: PersonalDetails; preferenceDetails: UserPreference; country?: string },
    thunkApi,
  ) => {
    try {
      thunkApi.dispatch(setAccLoading(true));
      await putUserPersonalDetails(params.personalDetails, params.preferenceDetails, params.country);
      thunkApi.dispatch(asyncGetAccountInfo());
      thunkApi.dispatch(openToast({ text: 'Profile updated' }));
    } catch (error) {
      handleBackendError(error, 'Update failed');
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setAccLoading(false));
    }
  },
);

export const asyncUploadCover = createAsyncThunk(
  'profile/saveAccountInfo',
  async (
    params: { personalDetails: PersonalDetails; preferenceDetails: UserPreference; country?: string },
    thunkAPI,
  ) => {
    try {
      await putUserPersonalDetails(params.personalDetails, params.preferenceDetails, params.country);
      thunkAPI.dispatch(openToast({ text: 'Image uploaded' }));
    } catch (error) {
      handleBackendError(error, 'Upload failed');
    }
  },
);

export const asyncUploadAvatar = createAsyncThunk('profile/uploadAvatar', async (params: string, thunkAPI) => {
  try {
    thunkAPI.dispatch(setImageLoading(true));
    await putUserAvatar(params);
    // await putUserImageBase64(params);
    // if (Capacitor.isNativePlatform()) {
    //   await putUserImageBase64(params);
    // } else {
    //   const formData = new FormData();
    //   formData.append('file', params);
    //   await putUserAvatar(formData);
    // }

    await thunkAPI.dispatch(asyncGetAccountInfo());
    thunkAPI.dispatch(openToast({ text: 'Image uploaded' }));
  } catch (error) {
    thunkAPI.dispatch(setImageLoading(false));
    handleBackendError(error, 'Upload failed');
  }
});

export const asyncTransferAdmin = createAsyncThunk(
  'profile/transferAdmin',
  async (params: { email: string }, thunkAPI) => {
    try {
      await putTransferAdmin(params.email);
      performLogout();
      // thunkAPI.dispatch(openToast({ text: 'Admin transferred' }));
    } catch (error) {
      handleBackendError(error, 'Failed to transfer Admin');
    }
  },
);

export const asyncChangeUsername = createAsyncThunk(
  'profile/changeUsername',
  async (params: { username: string }, thunkAPI) => {
    try {
      await putUserUsername(params.username);
      thunkAPI.dispatch(asyncGetAccountInfo());
      thunkAPI.dispatch(openToast({ text: 'Username changed' }));
    } catch (error) {
      handleBackendError(error, 'Changing username failed!');
    }
  },
);

export const asyncChangePassword = createAsyncThunk(
  'profile/changePassword',
  async (params: { password: string }, thunkAPI) => {
    try {
      await putUserPassword(params.password);
      thunkAPI.dispatch(asyncGetAccountInfo());
      thunkAPI.dispatch(openToast({ text: 'Password changed' }));
    } catch (error) {
      handleBackendError(error, 'Action Failed');
    }
  },
);

export const asyncUserConsistencyRewards = createAsyncThunk(
  'profile/consistencyRewards',
  async (params: { consistency: boolean }) => {
    try {
      await putUserConsistencyRewards(params.consistency);
    } catch (error) {
      handleBackendError(error, 'Action Failed');
    }
  },
);

export const asyncUserPerformanceRewards = createAsyncThunk(
  'profile/performanceRewards',
  async (params: { performance: boolean }) => {
    try {
      await putUserUserPerformanceRewards(params.performance);
    } catch (error) {
      handleBackendError(error, 'Action Failed');
    }
  },
);

export const asyncEmailUnsubscribe = createAsyncThunk(
  'profile/performanceRewards',
  async (params: { unsubscribed_emails: boolean }) => {
    try {
      await putEmailUnsubscribe(params.unsubscribed_emails);
    } catch (error) {
      handleBackendError(error, 'Action Failed');
    }
  },
);

export const asyncQuestionnaireUnsubscribe = createAsyncThunk(
  'profile/performanceRewards',
  async (params: { unsubscribed_questionnaires: boolean }) => {
    try {
      await putQuestionnaireUnsubscribe(params.unsubscribed_questionnaires);
    } catch (error) {
      handleBackendError(error, 'Action Failed');
    }
  },
);

export const asyncClearData = createAsyncThunk('profile/clearData', async (params, thunkAPI) => {
  try {
    await deleteUserData();
    thunkAPI.dispatch(openToast({ text: 'Data cleared' }));
  } catch (error) {
    handleBackendError(error, 'Action Failed');
  }
});

export const asyncDeleteAccount = createAsyncThunk('profile/deleteAccount', async (params, thunkAPI) => {
  try {
    await deleteAccount();
    performLogout();
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (error.response && error.response.status === 406) {
      thunkAPI.dispatch(setAdminModal(true));
    } else {
      handleBackendError(error, 'Action Failed');
    }
  }
});

export const asyncSaveCompanyInfo = createAsyncThunk(
  'profile/saveCompanyInfo',
  async (params: ICompanyInfo, thunkApi) => {
    try {
      thunkApi.dispatch(setCompLoading(true));
      await putCompanyDetails(params);
      thunkApi.dispatch(asyncGetCompanyInfo());
      thunkApi.dispatch(openToast({ text: 'Company updated' }));
      return params;
    } catch (error) {
      handleBackendError(error, 'Failed to update company info');
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setCompLoading(false));
    }
  },
);

export const asyncUploadCompanyImage = createAsyncThunk('profile/uploadImage', async (file: string, thunkAPI) => {
  try {
    thunkAPI.dispatch(setImageLoading(true));
    // const formData = new FormData();
    // formData.append('file', file);
    await putCompanyImage(file);
    thunkAPI.dispatch(asyncGetCompanyInfo());
    thunkAPI.dispatch(asyncGetAccountInfo());
  } catch (error) {
    thunkAPI.dispatch(setImageLoading(false));
    handleBackendError(error, 'Failed to upload logo');
  }
});

export const asyncCompanyPerformanceRewards = createAsyncThunk(
  'profile/companyPerformance',
  async (params: { performance: boolean }) => {
    try {
      await putCompanyPerformanceRewards(params.performance);
    } catch (error) {
      handleBackendError(error, 'Action Failed');
    }
  },
);

export const asynCompanyConsistencyRewards = createAsyncThunk(
  'profile/companyConsistency',
  async (params: { consistency: boolean }) => {
    try {
      await putCompanyConsistencyRewards(params.consistency);
    } catch (error) {
      handleBackendError(error, 'Action Failed');
    }
  },
);

export const chargebeeIntegration = createAsyncThunk('employees/chargebeeIntegration', async () => {
  try {
    const redirectUrl = `${process.env.REACT_APP_REDIRECT_URL}/page/your-profile` + '?organization';
    const res = await chargebeeIntegrationRequest(redirectUrl);

    if (res.data) {
      if (!Capacitor.isNativePlatform()) {
        window.location = res.data;
      } else {
        const options: InAppBrowserOptions = {
          zoom: 'no',
          location: 'no',
          fullscreen: 'yes',
          hidenavigationbuttons: 'yes',
          cleardata: 'no',
          clearcache: 'no',
          clearsessioncache: 'no',
          usewkwebview: 'yes',
          closebuttoncaption: 'Cancel',
        };
        const browser = InAppBrowser.create(res.data, '_blank', options);
        const listener = browser.on('loadstart').subscribe((event: any) => {
          const callback = encodeURI(event.url);
          //Check the redirect uri
          if (callback.indexOf(redirectUrl) > -1) {
            listener.unsubscribe();
            browser.close();
          }
        });
      }
    }
  } catch (error) {
    handleBackendError(error, 'Failed integration with Chargebee');
  }
});

export const asyncGetUserPoints = createAsyncThunk('/profile/getUserPoints', async () => {
  try {
    const response = await getUserPoints();
    return response.data.points;
  } catch (error) {
    handleBackendError(error, 'Failed get user points');
  }
});

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setAccLoading: (state, action) => {
      state.meta.accountInfoLoading = action.payload;
    },
    setCompLoading: (state, action) => {
      state.meta.companyInfoLoading = action.payload;
    },
    setAdminModal: (state, action) => {
      state.meta.adminModal = action.payload;
    },
    setImageLoading: (state, action) => {
      state.meta.imageLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncGetAccountInfo.fulfilled, (state, action) => {
      if (action.payload) {
        state.data.accountInfo = action.payload;
      }
    });
    builder.addCase(asyncGetCompanyInfo.fulfilled, (state, action) => {
      if (action.payload) {
        state.data.companyInfo = action.payload;
      }
    });
    builder.addCase(asyncSaveCompanyInfo.fulfilled, (state, action) => {
      if (action.payload) {
        state.data.companyInfo = action.payload;
      }
    });
    builder.addCase(asyncGetUserPoints.fulfilled, (state, action) => {
      if (action.payload) {
        state.data.points = action.payload;
      }
    });
  },
});
export const { setAdminModal, setAccLoading, setCompLoading, setImageLoading } = profileSlice.actions;

export default profileSlice.reducer;
