export const metrics = [
  {
    title: 'Rate your mood',
    category: 'slider',
    introText:
      'Ultrices rhoncus, imperdiet odio a in. Enim, sed et senectus quisque orci vulputate. Tempus, posuere quam suscipit non proin tempor, in eros scelerisque.',
  },
  {
    title: 'Guided meditation minutes',
    category: 'manual',
    input: 'Guided meditation minutes',
    introText:
      'Ultrices rhoncus, imperdiet odio a in. Enim, sed et senectus quisque orci vulputate. Tempus, posuere quam suscipit non proin tempor, in eros scelerisque.',
  },
  {
    title: 'How was your week',
    category: 'slider',
    introText:
      'Ultrices rhoncus, imperdiet odio a in. Enim, sed et senectus quisque orci vulputate. Tempus, posuere quam suscipit non proin tempor, in eros scelerisque.',
  },
  {
    title: 'Sleep duration',
    category: 'manual',
    input: 'Sleep duration (hrs)',
    introText:
      'Ultrices rhoncus, imperdiet odio a in. Enim, sed et senectus quisque orci vulputate. Tempus, posuere quam suscipit non proin tempor, in eros scelerisque.',
  },
  {
    title: 'Rate your sleep',
    category: 'slider',
    introText:
      'Ultrices rhoncus, imperdiet odio a in. Enim, sed et senectus quisque orci vulputate. Tempus, posuere quam suscipit non proin tempor, in eros scelerisque.',
  },
  {
    title: 'Sleep score',
    category: 'slider',
    introText:
      'Ultrices rhoncus, imperdiet odio a in. Enim, sed et senectus quisque orci vulputate. Tempus, posuere quam suscipit non proin tempor, in eros scelerisque.',
  },
  {
    title: 'Water consumption',
    category: 'manual',
    input: 'Water consumed today (lt)',
    introText:
      'Ultrices rhoncus, imperdiet odio a in. Enim, sed et senectus quisque orci vulputate. Tempus, posuere quam suscipit non proin tempor, in eros scelerisque.',
  },
  {
    title: 'Fruit consumption',
    category: 'manual',
    input: 'Fruits consumed today (portions)',
    introText:
      'Ultrices rhoncus, imperdiet odio a in. Enim, sed et senectus quisque orci vulputate. Tempus, posuere quam suscipit non proin tempor, in eros scelerisque.',
  },
  {
    title: 'Vegetable consumption',
    category: 'manual',
    input: 'Vegetables consumed today (portions)',
    introText:
      'Ultrices rhoncus, imperdiet odio a in. Enim, sed et senectus quisque orci vulputate. Tempus, posuere quam suscipit non proin tempor, in eros scelerisque.',
  },
  {
    title: 'Calories intake',
    category: 'slider',
    introText:
      'Ultrices rhoncus, imperdiet odio a in. Enim, sed et senectus quisque orci vulputate. Tempus, posuere quam suscipit non proin tempor, in eros scelerisque.',
  },
  {
    title: 'Calories burned',
    category: 'slider',
    introText:
      'Ultrices rhoncus, imperdiet odio a in. Enim, sed et senectus quisque orci vulputate. Tempus, posuere quam suscipit non proin tempor, in eros scelerisque.',
  },
  {
    title: 'Steps',
    category: 'manual',
    input: 'Steps (/day)',
    introText:
      'Ultrices rhoncus, imperdiet odio a in. Enim, sed et senectus quisque orci vulputate. Tempus, posuere quam suscipit non proin tempor, in eros scelerisque.',
  },
  {
    title: 'Distance (km/miles)',
    category: 'manual',
    input: 'Distance (km)',
    introText:
      'Ultrices rhoncus, imperdiet odio a in. Enim, sed et senectus quisque orci vulputate. Tempus, posuere quam suscipit non proin tempor, in eros scelerisque.',
  },
  {
    title: 'Number of exercise sessions',
    category: 'manual',
    input: 'Number of exercise sessions',
    introText:
      'Ultrices rhoncus, imperdiet odio a in. Enim, sed et senectus quisque orci vulputate. Tempus, posuere quam suscipit non proin tempor, in eros scelerisque.',
  },
  {
    title: 'Exercise minutes',
    category: 'manual',
    input: 'Exercise minutes',
    introText:
      'Ultrices rhoncus, imperdiet odio a in. Enim, sed et senectus quisque orci vulputate. Tempus, posuere quam suscipit non proin tempor, in eros scelerisque.',
  },
  {
    title: 'Move every 50 minutes',
    category: 'manual',
    input: 'Times moved today',
    introText:
      'Ultrices rhoncus, imperdiet odio a in. Enim, sed et senectus quisque orci vulputate. Tempus, posuere quam suscipit non proin tempor, in eros scelerisque.',
  },
  {
    title: 'Weight',
    category: 'manual',
    input: 'Your weight',
    introText:
      'Ultrices rhoncus, imperdiet odio a in. Enim, sed et senectus quisque orci vulputate. Tempus, posuere quam suscipit non proin tempor, in eros scelerisque.',
  },
  {
    title: 'Heart rate variability',
    category: 'watch',
    introText:
      'Ultrices rhoncus, imperdiet odio a in. Enim, sed et senectus quisque orci vulputate. Tempus, posuere quam suscipit non proin tempor, in eros scelerisque.',
  },
  {
    title: 'Resting heart rate',
    category: 'watch',
    introText:
      'Ultrices rhoncus, imperdiet odio a in. Enim, sed et senectus quisque orci vulputate. Tempus, posuere quam suscipit non proin tempor, in eros scelerisque.',
  },
  {
    title: 'BMI',
    category: 'watch',
    introText:
      'Ultrices rhoncus, imperdiet odio a in. Enim, sed et senectus quisque orci vulputate. Tempus, posuere quam suscipit non proin tempor, in eros scelerisque.',
  },
];
