import { IonContent, IonPage } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { ChallengeCardList } from '../../components/features/ChallengeCardList/ChallengeCardList';
import { Colors } from '../../theme/colors';

import {
  getFinishedChallenges,
  getOngoingChallenges,
  getOpenToJoinChallenges,
} from '../../redux/slices/challengeSlice';
import { CHALLENGE_STATUS } from '../../models/enum/CHALLENGE_STATUS';
import { HeaderFilter, useHandleFilter } from '../../components/features/HeaderFilter';
import { ANALYTICS_PAGE } from '../../models/enum/ANALYTICS_PAGE';
import useIsPageActive from '../../hooks/useIsPageActive';
import { useMetricsHandle } from '../ChallengeDetailPage/hooks/useMetricsHandle';

const PAGE_SIZE = 8;
export const ChallengesPage: React.FC = () => {
  const dispatch = useDispatch();
  const [isFirstOngoingLoading, setIsFirstOngoingLoading] = useState(false);
  const [isFirstOpenToJoinLoading, setIsOpenToJoinLoading] = useState(false);
  const [isFirstFinishedLoading, setIsFirstFinishedLoading] = useState(false);
  const [ongoingPage, setOngoingPage] = useState<number>(0);
  const [openToJoinPage, setOpenToJoinPage] = useState<number>(0);
  const [finishedPage, setFinishedPage] = useState<number>(0);

  const [ongoingForceUpdate, setOngoingForceUpdate] = useState<boolean>(false);
  const [openToJoinForceUpdate, setOpenToJoinForceUpdate] = useState<boolean>(false);
  const [finishedForceUpdate, setFinishedForceUpdate] = useState<boolean>(false);
  const visible = useIsPageActive(ANALYTICS_PAGE.CHALLENGES);

  const { queryFilter, ...headerFilterProps } = useHandleFilter();
  const { metrics } = useMetricsHandle();

  const {
    challengesOngoing,
    challengesOpenToJoin,
    challengesFinished,
    challengesOngoingCount,
    challengesOpenToJoinCount,
    challengesFinishedCount,
  } = useSelector((state: RootState) => state.challenge.data);

  const { challengesOngoingLoading, challengesOpenToJoinLoading, challengesFinishedLoading } = useSelector(
    (state: RootState) => state.challenge.meta,
  );

  useEffect(() => {
    setIsFirstOngoingLoading(false);
  }, [challengesOngoingLoading]);
  useEffect(() => {
    setIsOpenToJoinLoading(false);
  }, [challengesOpenToJoinLoading]);
  useEffect(() => {
    setIsFirstFinishedLoading(false);
  }, [challengesFinishedLoading]);

  useEffect(() => {
    if (!visible) return;
    setIsFirstOngoingLoading(true);
    setIsOpenToJoinLoading(true);
    setIsFirstFinishedLoading(true);

    setOngoingPage(0);
    setOpenToJoinPage(0);
    setFinishedPage(0);
  }, [queryFilter, visible]);

  useEffect(() => {
    if (!visible) return;

    const subscribe = setTimeout(() => {
      dispatch(getOngoingChallenges({ page: ongoingPage, size: PAGE_SIZE, ...queryFilter }));
    }, 600);
    return () => clearTimeout(subscribe);
  }, [ongoingPage, queryFilter, visible, ongoingForceUpdate]);
  useEffect(() => {
    if (!visible) return;

    const subscribe = setTimeout(() => {
      dispatch(getOpenToJoinChallenges({ page: openToJoinPage, size: PAGE_SIZE, ...queryFilter }));
    }, 600);
    return () => clearTimeout(subscribe);
  }, [openToJoinPage, queryFilter, visible, openToJoinForceUpdate]);
  useEffect(() => {
    if (!visible) return;

    const subscribe = setTimeout(() => {
      dispatch(getFinishedChallenges({ page: finishedPage, size: PAGE_SIZE, ...queryFilter }));
    }, 600);
    return () => clearTimeout(subscribe);
  }, [finishedPage, queryFilter, visible, finishedForceUpdate]);

  const handleUpdateDataAfterJoin = async (status: CHALLENGE_STATUS) => {
    if (status === CHALLENGE_STATUS.ACTIVE) {
      setOngoingPage(0);
      setOngoingForceUpdate(!ongoingForceUpdate);
      return;
    }
    if (status === CHALLENGE_STATUS.INACTIVE) {
      setOngoingPage(0);
      setOngoingForceUpdate(!ongoingForceUpdate);

      setOpenToJoinPage(0);
      setOpenToJoinForceUpdate(!openToJoinForceUpdate);
      return;
    }
  };

  const handleUpdateDataAfterLog = (status: CHALLENGE_STATUS) => {
    if (status === CHALLENGE_STATUS.ACTIVE || status === CHALLENGE_STATUS.COMPLETED) {
      setOngoingPage(0);
      setOngoingForceUpdate(!ongoingForceUpdate);

      setFinishedPage(0);
      setFinishedForceUpdate(!finishedForceUpdate);
      return;
    }
  };

  return (
    <IonPage>
      <HeaderFilter
        label="Your Challenges"
        tooltip={
          <>
            {'Join challenges to build new habits and improve your wellbeing. '}
            <b>Corporate</b> {'challenges are initiated by your Admin as a company-wide initiative. '}
            <b>Weekly</b> {'challenges are linked to the videos available in'}
            <i>Your Focus</i> {'page.'}
          </>
        }
        {...headerFilterProps}
        metrics={metrics}
      />

      <IonContent style={{ '--padding-bottom': '36px' }} scrollEvents={true}>
        <Container maxWidth={'lg'}>
          <ChallengeCardList
            status={CHALLENGE_STATUS.ACTIVE}
            title={'Ongoing'}
            list={challengesOngoing}
            isLoading={challengesOngoingLoading || isFirstOngoingLoading}
            path={'/page/your-challenges/ongoing'}
            sxContainer={{ marginBottom: '30px' }}
            currentPage={ongoingPage}
            pageSize={PAGE_SIZE}
            setPage={setOngoingPage}
            totalItems={challengesOngoingCount}
            updateData={handleUpdateDataAfterJoin}
            updateDataAfterLog={handleUpdateDataAfterLog}
          />

          <ChallengeCardList
            status={CHALLENGE_STATUS.INACTIVE}
            title={'Open to join'}
            list={challengesOpenToJoin}
            isLoading={challengesOpenToJoinLoading || isFirstOpenToJoinLoading}
            path={'/page/your-challenges/join'}
            sxContainer={{ marginBottom: '30px' }}
            currentPage={openToJoinPage}
            pageSize={PAGE_SIZE}
            setPage={setOpenToJoinPage}
            totalItems={challengesOpenToJoinCount}
            updateData={handleUpdateDataAfterJoin}
          />

          <ChallengeCardList
            status={CHALLENGE_STATUS.COMPLETED}
            title={'Finished'}
            list={challengesFinished}
            isLoading={challengesFinishedLoading || isFirstFinishedLoading}
            path={'/page/your-challenges/finished'}
            sxContainer={{ marginBottom: '30px' }}
            currentPage={finishedPage}
            pageSize={PAGE_SIZE}
            setPage={setFinishedPage}
            totalItems={challengesFinishedCount}
            updateDataAfterLog={handleUpdateDataAfterLog}
          />
        </Container>
      </IonContent>
    </IonPage>
  );
};
