import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { setHeaderLoading } from './appSlice';
import { handleBackendError } from '../../utils/handleBackendError';
import {
  getChallengeLeaderBoard,
  getChallengeParticipants,
  getChallengeTeams,
} from '../../lib/api/http/requests/challenge';
import {
  getAdminChallengeProgress,
  getChallenge,
  getChallengeAdminMetrics,
  getChallengesRequest,
  getTemplatesMetrics,
} from '../../lib/api/http/requests/challengeAdmin';
import { Challenge, ChallengeAdminProgress, ChallengeProgressRanking } from '../../models/Challenge';
import { CHALLENGE_STATUS } from '../../models/enum/CHALLENGE_STATUS';
import { DIMENSION } from '../../models/enum/DIMENSION';
import { COMPETITION } from '../../models/enum/COMPETITION';
import { Participant } from '../../models/Participant';
import { Team } from '../../models/Team';
import UserService from '../../services/UserService';

export interface ChallengesState {
  data: {
    challengeMetrics: string[];
    templatesMetrics: string[];
    challenge: Challenge | null;
    challengeResult?: ChallengeAdminProgress;
    challengeResultRanking?: ChallengeProgressRanking;
    challengeResultParticipants?: {
      participants: Participant[];
      teams: Team[];
    };

    challengesOngoing: Challenge[];
    challengesOpenToJoin: Challenge[];
    challengesFinished: Challenge[];
    challengesAllView: Challenge[];

    challengesOngoingCount: number;
    challengesOpenToJoinCount: number;
    challengesFinishedCount: number;

    pageOngoing: number;
    pageJoin: number;
    pageFinished: number;
  };
  meta: {
    challengeLoading: boolean;
    participantsLoading: boolean;
    challengeResultLoading: boolean;

    challengesOngoingLoading: boolean;
    challengesOpenToJoinLoading: boolean;
    challengesFinishedLoading: boolean;
    challengesAllViewLoading: boolean;

    joinToChallengeLoading: boolean;
    abortChallengeLoading: boolean;

    isFetching: boolean;
  };
}

const initialState: ChallengesState = {
  data: {
    challengeMetrics: [],
    templatesMetrics: [],
    challenge: null,

    challengesOngoing: [],
    challengesOpenToJoin: [],
    challengesFinished: [],
    challengesAllView: [],

    challengesOngoingCount: 0,
    challengesOpenToJoinCount: 0,
    challengesFinishedCount: 0,
    pageOngoing: 0,
    pageJoin: 0,
    pageFinished: 0,
  },
  meta: {
    challengeLoading: false,
    participantsLoading: false,
    challengeResultLoading: false,

    challengesOngoingLoading: false,
    challengesOpenToJoinLoading: false,
    challengesFinishedLoading: false,
    challengesAllViewLoading: false,

    joinToChallengeLoading: false,
    abortChallengeLoading: false,

    isFetching: false,
  },
};

export interface ChallengeParams {
  page: number;
  size: number;
  searchText: string;
  challengeStatus?: CHALLENGE_STATUS[];
  challengePillars?: DIMENSION[];
  competition?: COMPETITION[];
  metric?: string;
  onlyCorporate?: boolean;
  publisher?: boolean;
}

export const getOngoingChallenges = createAsyncThunk(
  'challengeAdmin/getOngoingChallenges',
  async (params: ChallengeParams, thunkAPI) => {
    try {
      thunkAPI.dispatch(setHeaderLoading(true));
      const uuid = UserService.getMineId();
      const { data } = await getChallengesRequest({
        searchText: params.searchText,
        challengePillars: params.challengePillars,
        competition: params.competition,
        page: params.page,
        size: params.size,
        metric: params.metric,
        challengeStatus: [CHALLENGE_STATUS.ACTIVE],
        publisher: params.publisher ? uuid : undefined,
      });

      const challenges: Challenge[] = data.content;
      const count = data.totalElements;
      return { challenges, count, page: params.page };
    } catch (error) {
      handleBackendError(error, 'Failed to fetch ongoing challenges');
    } finally {
      thunkAPI.dispatch(setHeaderLoading(false));
    }
  },
);

export const getOpenToJoinChallenges = createAsyncThunk(
  'challengeAdmin/getOpenToJoinChallenges',
  async (params: ChallengeParams, thunkAPI) => {
    try {
      thunkAPI.dispatch(setHeaderLoading(true));
      const uuid = UserService.getMineId();
      const { data } = await getChallengesRequest({
        searchText: params.searchText,
        challengePillars: params.challengePillars,
        competition: params.competition,
        page: params.page,
        size: params.size,
        metric: params.metric,
        challengeStatus: [CHALLENGE_STATUS.INACTIVE],
        publisher: params.publisher ? uuid : undefined,
      });

      const challenges: Challenge[] = data.content;
      const count = data.totalElements;
      return { challenges, count, page: params.page };
    } catch (error) {
      handleBackendError(error, 'Failed to fetch to join challenges');
    } finally {
      thunkAPI.dispatch(setHeaderLoading(false));
    }
  },
);

export const getFinishedChallenges = createAsyncThunk(
  'challengeAdmin/getFinishedChallenges',
  async (params: ChallengeParams, thunkAPI) => {
    try {
      thunkAPI.dispatch(setHeaderLoading(true));
      const uuid = UserService.getMineId();
      const { data } = await getChallengesRequest({
        searchText: params.searchText,
        challengePillars: params.challengePillars,
        competition: params.competition,
        page: params.page,
        size: params.size,
        metric: params.metric,
        challengeStatus: [CHALLENGE_STATUS.COMPLETED, CHALLENGE_STATUS.IN_GRACE_PERIOD],
        publisher: params.publisher ? uuid : undefined,
      });

      const challenges: Challenge[] = data.content;
      const count = data.totalElements;
      return { challenges, count, page: params.page };
    } catch (error) {
      handleBackendError(error, 'Failed to fetch finished challenges');
    } finally {
      thunkAPI.dispatch(setHeaderLoading(false));
    }
  },
);

export const getChallengeViewAll = createAsyncThunk(
  'challengeAdmin/getChallengeViewAll',
  async (params: ChallengeParams, thunkAPI) => {
    try {
      thunkAPI.dispatch(setHeaderLoading(true));
      const uuid = UserService.getMineId();
      const { data } = await getChallengesRequest({
        searchText: params.searchText,
        challengePillars: params.challengePillars,
        competition: params.competition,
        page: params.page,
        size: params.size,
        metric: params.metric,
        challengeStatus: params.challengeStatus,
        publisher: params.publisher ? uuid : undefined,
      });
      const challenges: Challenge[] = data.content;
      return { challenges };
    } catch (error) {
      handleBackendError(error, 'Failed to fetch ongoing challenges');
    } finally {
      thunkAPI.dispatch(setHeaderLoading(false));
    }
  },
);

export const asyncGetChallengeMetrics = createAsyncThunk('challengeAdmin/asyncGetChallengeMetrics', async () => {
  try {
    const { data } = await getChallengeAdminMetrics();
    return { data };
  } catch (err) {
    console.log(err);
  }
});
export const asyncGetTemplatesMetrics = createAsyncThunk('challengeAdmin/asyncGetTemplatesMetrics', async () => {
  try {
    const { data } = await getTemplatesMetrics();
    return { data };
  } catch (err) {
    console.log(err);
  }
});

export const fetchChallenge = createAsyncThunk(
  'challengeAdmin/fetchChallenge',
  async (params: { id: string; isTemplate: string | null }) => {
    try {
      const requestParams: { challengeUUID?: string; templateChallengeUUID: string } = params.isTemplate
        ? { templateChallengeUUID: params.id }
        : {
            challengeUUID: params.id,
            templateChallengeUUID: params.id,
          };

      const { data } = await getChallenge(requestParams);
      const challenge: Challenge = data;

      return { challenge };
    } catch (error) {
      handleBackendError(error, 'Failed to load the challenge');
    } finally {
    }
  },
);

export const fetchChallengeResult = createAsyncThunk(
  'challengeAdmin/fetchChallengeResult',
  async (params: { challengeUUID: string }) => {
    try {
      const { data: challenge }: { data: ChallengeAdminProgress } = await getAdminChallengeProgress(params);
      const { data: list }: { data: ChallengeProgressRanking } = await getChallengeLeaderBoard({
        ...params,
        page: '0',
        size: '12',
      });

      return { challenge, list };
    } catch (error) {
      handleBackendError(error, 'Failed to load the challenge result');
    }
  },
);

export const fetchNextPageAdminLeaderBoard = createAsyncThunk(
  'challengeAdmin/fetchNextPageAdminLeaderBoard',
  async (params: { challengeUUID: string; page: string }) => {
    try {
      const { data: list }: { data: ChallengeProgressRanking } = await getChallengeLeaderBoard({
        ...params,
        size: '12',
      });

      return list;
    } catch (error) {
      handleBackendError(error, 'Failed to load the next page of the leaderboard');
    }
  },
);

export const fetchChallengeResultParticipants = createAsyncThunk(
  'challengeAdmin/fetchChallengeREsultParticipants',
  async (params: { challengeUUID: string; isTeam: boolean }) => {
    try {
      let team: Team[] = [];
      const { data: dataParticipants }: { data: { content: Participant[] } } = await getChallengeParticipants(params);
      if (params.isTeam) {
        const { data: dataTeams }: { data: { content: Team[] } } = await getChallengeTeams(params);
        team = dataTeams.content;
      }

      return { participants: dataParticipants.content, team };
    } catch (error) {
      handleBackendError(error, 'Failed to load the participants');
    }
  },
);

export const challengeAdminSlice = createSlice({
  name: 'challengeAdmin',
  initialState,
  reducers: {
    setIsLoadingChallengeResult: (state, action: PayloadAction<boolean>) => {
      state.meta.challengeResultLoading = action.payload;
    },
    setOpenToJoin: (state, action: PayloadAction<boolean>) => {
      state.meta.challengesOpenToJoinLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOngoingChallenges.fulfilled, (state, action) => {
      const challenges = action.payload?.challenges || [];
      if (action.payload?.page && action.payload.page > 0) {
        state.data.challengesOngoing.push(...challenges);
      } else {
        state.data.challengesOngoing = challenges;
      }
      state.data.challengesOngoingCount = action.payload?.count || 0;
      state.meta.challengesOngoingLoading = false;
    });
    builder.addCase(getOngoingChallenges.rejected, (state) => {
      state.meta.challengesOngoingLoading = false;
    });
    builder.addCase(getOngoingChallenges.pending, (state) => {
      state.meta.challengesOngoingLoading = true;
    });

    builder.addCase(getOpenToJoinChallenges.fulfilled, (state, action) => {
      const challenges = action.payload?.challenges || [];
      if (action.payload?.page && action.payload.page > 0) {
        state.data.challengesOpenToJoin.push(...challenges);
      } else {
        state.data.challengesOpenToJoin = challenges;
      }
      state.data.challengesOpenToJoinCount = action.payload?.count || 0;
      state.meta.challengesOpenToJoinLoading = false;
    });
    builder.addCase(getOpenToJoinChallenges.rejected, (state) => {
      state.meta.challengesOpenToJoinLoading = false;
    });
    builder.addCase(getOpenToJoinChallenges.pending, (state) => {
      state.meta.challengesOpenToJoinLoading = true;
    });

    builder.addCase(getFinishedChallenges.fulfilled, (state, action) => {
      const challenges = action.payload?.challenges || [];
      if (action.payload?.page && action.payload.page > 0) {
        state.data.challengesFinished.push(...challenges);
      } else {
        state.data.challengesFinished = challenges;
      }
      state.data.challengesFinishedCount = action.payload?.count || 0;
      state.meta.challengesFinishedLoading = false;
    });
    builder.addCase(getFinishedChallenges.rejected, (state) => {
      state.meta.challengesFinishedLoading = false;
    });
    builder.addCase(getFinishedChallenges.pending, (state) => {
      state.meta.challengesFinishedLoading = true;
    });

    builder.addCase(getChallengeViewAll.fulfilled, (state, action) => {
      state.data.challengesAllView = action.payload?.challenges || [];
      state.meta.challengesAllViewLoading = false;
    });
    builder.addCase(getChallengeViewAll.rejected, (state) => {
      state.meta.challengesAllViewLoading = false;
    });
    builder.addCase(getChallengeViewAll.pending, (state) => {
      state.meta.challengesAllViewLoading = true;
    });

    builder.addCase(asyncGetChallengeMetrics.fulfilled, (state, action) => {
      state.data.challengeMetrics = action.payload?.data || [];
    });
    builder.addCase(asyncGetTemplatesMetrics.fulfilled, (state, action) => {
      state.data.templatesMetrics = action.payload?.data || [];
    });

    builder.addCase(fetchChallenge.fulfilled, (state, action) => {
      state.data.challenge = action.payload?.challenge || null;
      state.meta.challengeLoading = false;
    });
    builder.addCase(fetchChallenge.rejected, (state) => {
      state.meta.challengeLoading = false;
    });
    builder.addCase(fetchChallenge.pending, (state) => {
      state.meta.challengeLoading = true;
    });
    builder.addCase(fetchChallengeResult.fulfilled, (state, action) => {
      state.data.challengeResult = action.payload?.challenge;
      state.data.challengeResultRanking = action.payload?.list;
      state.meta.challengeResultLoading = false;
    });
    builder.addCase(fetchChallengeResult.rejected, (state) => {
      state.meta.challengeResultLoading = false;
    });
    builder.addCase(fetchChallengeResult.pending, (state) => {
      state.meta.challengeResultLoading = true;
    });
    builder.addCase(fetchChallengeResultParticipants.fulfilled, (state, action) => {
      state.data.challengeResultParticipants = {
        participants: action.payload?.participants ?? [],
        teams: action.payload?.team ?? [],
      };
    });
    builder.addCase(fetchNextPageAdminLeaderBoard.pending, (state) => {
      state.meta.isFetching = true;
    });
    builder.addCase(fetchNextPageAdminLeaderBoard.rejected, (state) => {
      state.meta.isFetching = false;
    });
    builder.addCase(fetchNextPageAdminLeaderBoard.fulfilled, (state, action) => {
      if (action.payload) {
        state.data.challengeResultRanking = {
          ...action.payload,
          content: [...(state.data.challengeResultRanking?.content ?? []), ...action.payload.content],
        };
      }
      state.meta.isFetching = false;
    });
  },
});
export default challengeAdminSlice.reducer;
