import React, { FC, useEffect, useState } from 'react';
import { Box, Dialog, DialogContent, Grid, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { getRole } from '../../utils/getRole';

import AddIcon from '@mui/icons-material/Add';
import { ReactComponent as FocusIcon } from '../../assets/svg/focus.svg';
import { ReactComponent as GoalIcon } from '../../assets/svg/feature/goal.svg';
import { ReactComponent as TrophyIcon } from '../../assets/svg/feature/trophy.svg';
import { ReactComponent as ToolboxIcon } from '../../assets/svg/book.svg';
import { ReactComponent as JourneyIcon } from '../../assets/svg/journey.svg';
import { ReactComponent as RewardsIcon } from '../../assets/svg/reward.svg';
import { ReactComponent as EmployeesIcon } from '../../assets/svg/employees.svg';
import { ReactComponent as AvatarIcon } from '../../assets/svg/avatar.svg';
import { ReactComponent as StepsIcon } from '../../assets/svg/steps.svg';
import { useIonRouter } from '@ionic/react';
import DOMPurify from 'dompurify';

const StyledButton = styled(Box)(() => ({
  padding: '12px 16px',
  borderRadius: '99px',
  width: '160px',
  fontSize: 16,
  fontWeight: 'bold',
  cursor: 'pointer',
  background: '#6EE2E4',
  userSelect: 'none',
  color: 'white',
}));

const userTooltips: Array<{ Icon?: any; url?: string; title: string; content: string; isFillIcon?: boolean }> = [
  {
    url: '/page/your-focus',
    Icon: FocusIcon,
    title: 'YOUR FOCUS',
    content:
      'Learn about well-being, get useful tips, put them into practice and see your well-being improving. Every week our experts will deliver new content to keep you going and every three months the focus module will cover a different topic. The more content you read, the bigger your experience and the more points you collect!',
  },
  {
    url: '/page/your-toolbox',
    Icon: ToolboxIcon,
    title: 'YOUR TRUSTED TOOLBOX',
    content:
      'When you want to learn more on well-being aspects, we have prepared a library of useful content for you that is constantly updated.',
  },
  {
    url: '/page/your-journey',
    Icon: JourneyIcon,
    title: 'YOUR JOURNEY',
    content: `Log daily your data related to well-being metrics and view your progress over time. Get a reflection of your current well-being status, via your Wellics™ Index (WIN) and insights on how each aspect affects your well-being. Your achievements for each three-month module will give you more points!`,
  },
  {
    Icon: AddIcon,
    title: 'BUTTON',
    content: 'Click the bottom-right button to quickly log new data each day and set goals.',
  },
  {
    url: '/page/trophy-library',
    Icon: TrophyIcon,
    title: 'TROPHY LIBRARY',
    content:
      'View the awards you have collected by participating in challenges as well the points you have collected based on your experience with <i>Your Focus</i> content or your achievements logged in <i>Your Journey</i> during each three-month module.',
    isFillIcon: true,
  },
  {
    url: '/page/your-profile',
    Icon: AvatarIcon,
    title: 'YOUR PROFILE',
    content:
      'Connect your wearable or activity tracker so that your data automatically feed in the application. Add information about yourself and manage your account and preferences.',
  },
  {
    url: '/page/your-challenges',
    Icon: GoalIcon,
    title: 'YOUR CHALLENGES',
    content:
      'Enhance your well-being while engaging in friendly competition with your colleagues, either individually or as part of a team. Click on a challenge to view its details and click "Join" to participate. Throughout the challenge, track your progress and log your activities related to the challenge. <b>Corporate</b> challenges are initiated by your Admin as company-wide initiatives. <b>Weekly</b> challenges are linked to the videos found in <i>Your Focus</i> page.',
    isFillIcon: true,
  },
];

const adminTooltips: Array<{ Icon?: any; url?: string; title: string; content: string; isFillIcon?: boolean }> = [
  {
    url: '/page/employees-focus',
    Icon: FocusIcon,
    title: `YOUR EMPLOYEES' FOCUS`,
    content:
      'Learn how different well-being aspects affect your workplace and your employees’ health and productivity. Every month our experts will deliver new content to keep you going and every three months the focus module will cover a different topic.',
  },
  {
    url: '/page/employees-journey',
    Icon: JourneyIcon,
    title: "YOUR EMPLOYEES' JOURNEY",
    content: `View your employees’ trends over time related to various well-being metrics and get insights on how each aspect affects your organization’s well-being. The Wellics™ Index (WIN) reflects the average well-being of your team.`,
  },
  {
    Icon: AddIcon,
    title: 'BUTTON',
    content:
      'Click the bottom-right button to create your own metric that is linked to your corporate well-being. Tracking it on a regular basis will allow you to see how the improvement of your team’s well-being impacts this metric.',
  },
  {
    url: '/page/employees-rewards',
    Icon: RewardsIcon,
    title: "YOUR EMPLOYEES' POINTS",
    content: `View the leaderboards of your employees and reward them based on their gained experience or their achievements during each three-month module. Don’t forget to activate the rewards from the organization view in Your profile.`,
  },
  {
    url: '/page/employees',
    Icon: EmployeesIcon,
    title: 'YOUR EMPLOYEES',
    content: `Invite your employees to the Wellics™ platform, see those already registered and delete the ones that should not have access to the platform anymore.`,
  },
  {
    url: '/page/your-profile',
    Icon: AvatarIcon,
    title: 'YOUR PROFILE',
    content:
      'Add information about your organization and activate rewards for your employees. On a personal level, connect your wearable or activity tracker so that your data are automatically tracked by the application. Add information about yourself and manage your account.',
  },
  {
    url: '/page/employees-challenges',
    Icon: GoalIcon,
    title: "YOUR EMPLOYEES' CHALLENGES",
    content:
      "Cultivate social connection, teamwork, and a vibrant wellness culture within your organization through engaging organization-wide challenges. Uncover a diverse range of challenge templates provided by Wellics, or narrow down your search to find the ones that align with your interests. Customize and publish challenges that best suit your company's needs. View the leaderboards in real-time and reward the winners for their achievements.",
    isFillIcon: true,
  },
];

interface TooltipSupportModalInterface {
  open: boolean;
  handleClose: () => void;
  routingEnabled?: boolean;
}

const TooltipOrderForAdmin = [
  adminTooltips[5],
  adminTooltips[4],
  adminTooltips[1],
  adminTooltips[6],
  adminTooltips[2],
  adminTooltips[0],
  adminTooltips[3],
  userTooltips[2],
  userTooltips[4],
  userTooltips[1],
];

const TooltipOrderForUser = [
  userTooltips[5],
  userTooltips[0],
  userTooltips[6],
  userTooltips[2],
  userTooltips[4],
  userTooltips[3],
  userTooltips[1],
];

const getRoleTooltips = () => {
  return getRole() === 'ADMIN' ? TooltipOrderForAdmin : TooltipOrderForUser;
};

const TooltipSupportModal: FC<TooltipSupportModalInterface> = ({ open, handleClose, routingEnabled = false }) => {
  const theme = useTheme();
  const router = useIonRouter();
  const [tooltipIndex, setTooltipIndex] = useState<number>(0);
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (open) setTooltipIndex(0);
  }, [open]);

  useEffect(() => {
    if (open && routingEnabled) {
      const currentTooltip = getRoleTooltips()[tooltipIndex];
      if (currentTooltip.url) router.push(currentTooltip.url);
    }
  }, [open, tooltipIndex]);

  const hasMore = () => {
    return tooltipIndex + 1 < getRoleTooltips().length;
  };

  const handleGoNext = () => {
    if (hasMore()) setTooltipIndex(tooltipIndex + 1);
    else handleClose();
  };

  const handleGoPrev = () => {
    if (tooltipIndex > 0) setTooltipIndex(tooltipIndex - 1);
    else handleClose();
  };

  const handleSkip = () => {
    handleClose();
  };

  const getTitle = () => {
    const Icon = getRoleTooltips()[tooltipIndex].Icon;
    const title = getRoleTooltips()[tooltipIndex].title || '';
    const isFillIcon = getRoleTooltips()[tooltipIndex].isFillIcon;
    return (
      <Grid item container wrap={'nowrap'} alignItems={'center'} sx={{ flex: 0, whiteSpace: 'nowrap', mb: 4 }}>
        {Icon && (
          <Grid
            item
            container
            alignItems={'center'}
            sx={{
              marginRight: '8px',
              padding: title === 'BUTTON' ? '8px' : '0',
              borderRadius: '99px',
              boxShadow: title === 'BUTTON' ? '0 0 5px #00000050' : undefined,
            }}
          >
            <Icon
              className={isFillIcon ? 'whiteFillIcon' : 'whiteIcon'}
              // style={{
              //   marginRight: '8px',
              //   // padding: '8px',
              //   borderRadius: '99px',
              //   boxShadow: title === 'BUTTON' ? '0 0 5px black' : undefined,
              // }}
            />
          </Grid>
        )}
        <Grid item container alignItems={'center'}>
          <Typography sx={{ fontWeight: 700, fontSize: 20 }}>{title}</Typography>
        </Grid>
      </Grid>
    );
  };
  const getContent = () => {
    return getRoleTooltips()[tooltipIndex].content || '';
  };

  const getBulletPagination = () => {
    return getRoleTooltips().map((b, i) => (
      <Box key={i}>
        <Box
          sx={{
            width: tooltipIndex === i ? 10 : 6,
            height: tooltipIndex === i ? 10 : 6,
            opacity: tooltipIndex === i ? 1 : 0.5,
            backgroundColor: getRole() === 'ADMIN' ? '#FFD075' : '#6EE2E4',
            m: '2px',
            borderRadius: 8,
          }}
        />
      </Box>
    ));
  };
  return (
    <Dialog
      maxWidth={'sm'}
      open={open}
      sx={{ textAlign: 'center' }}
      PaperProps={{
        style: {
          margin: '8px',
          // minHeight: 500,
          borderRadius: '8px',
          color: 'white',
          backgroundColor: getRole() === 'ADMIN' ? '#FAAF1F' : '#00B6BE',
          flex: xsDown ? 1 : undefined,
        },
      }}
    >
      <DialogContent sx={{ minWidth: xsDown ? undefined : 420, maxWidth: 420 }}>
        <Grid container>
          <Grid item xs={12} container justifyContent={'center'}>
            {getTitle()}
          </Grid>
          <Grid item xs={12} sx={{ minHeight: '180px' }}>
            <Typography
              sx={{ fontWeight: 400, fontSize: 16 }}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getContent()) }}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} justifyContent={'center'} alignItems={'center'} sx={{ mt: 4 }}>
          {getBulletPagination()}
        </Grid>
        <Grid item xs={12} container sx={{ mt: 4 }}>
          <Grid item xs={12} container justifyContent={'center'} sx={{ mb: 1 }}>
            <Box sx={{ position: 'relative' }}>
              {tooltipIndex > 0 && (
                <Box sx={{ position: 'absolute', left: '-120px' }}>
                  <StyledButton
                    sx={{ background: 'none', color: getRole() === 'ADMIN' ? '#FFD075' : '#6EE2E4' }}
                    onClick={handleGoPrev}
                  >
                    Back
                  </StyledButton>
                </Box>
              )}
              <StyledButton
                onClick={handleGoNext}
                sx={{ backgroundColor: getRole() === 'ADMIN' ? '#FFD075' : '#6EE2E4' }}
              >
                Next
              </StyledButton>
            </Box>
          </Grid>
          {hasMore() && (
            <Grid item xs={12} container justifyContent={'center'}>
              <StyledButton
                sx={{ background: 'none', color: getRole() === 'ADMIN' ? '#FFD075' : '#6EE2E4' }}
                onClick={handleSkip}
              >
                Skip
              </StyledButton>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default TooltipSupportModal;
