import { Avatar, Box, Skeleton, Typography } from '@mui/material';
import React from 'react';
import IconPlaceholder from '../../../assets/svg/placeholder_avatar.svg';
import { Colors } from '../../../theme/colors';
import PlaceBgSvg from '../../../assets/svg/feature/ranking-place-bg.svg';
import FirstPlaceBgSvg from '../../../assets/svg/feature/ranking-first-place-bg.svg';
import Confetti from '../../../assets/svg/feature/confetti.svg';
import Wreath from '../../../assets/svg/feature/wreath.svg';
import RankingBg from '../../../assets/svg/feature/ranking/ranking-card-bg.svg';
import SelfRankingBg from '../../../assets/svg/feature/ranking/self-ranking-bg.svg';
import RankingFirstBg from '../../../assets/svg/feature/ranking/ranking-first-card-bg.svg';

export type RankingCardVariant = 'lg' | 'md';

type RankingCardProps = {
  avatar?: string | null;
  name?: string;
  place?: number;
  isLoading?: boolean;
  isSelf?: boolean;
  variant?: RankingCardVariant;
};

export const RankingCard: React.FC<RankingCardProps> = ({
  avatar,
  name,
  place,
  isLoading = false,
  isSelf,
  variant = 'md',
}) => {
  if (isLoading || !name) {
    return (
      <Skeleton
        variant="rectangular"
        sx={{ borderRadius: '10px', width: '100%', height: 302, marginBottom: '16px', background: Colors.blue[800] }}
      />
    );
  }
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: isSelf ? 'center' : 'space-between',
        borderRadius: '10px',
        width: '100%',
        minHeight: 272,
        maxHeight: variant === 'lg' ? 'unset' : 302,
        flex: 1,
        border: `5px solid ${place === 1 ? '#B9EBF1' : Colors.blue[700]}`,
        background: `linear-gradient(63deg, ${!isSelf && place === 1 ? Colors.blue[400] : Colors.blue[800]} 0%, ${
          !isSelf && place === 1 ? Colors.blue[100] : Colors.blue[700]
        } 100%)`,
        overflow: 'hidden',
        paddingTop: '14px',
        marginBottom: { xs: 'unset', md: variant === 'lg' ? 'unset' : '16px' },
      }}
    >
      <img
        style={{
          position: 'absolute',
          inset: variant === 'lg' ? 'bottom' : 0,
          height: variant === 'lg' ? '100%' : 302,
          objectFit: 'cover',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
        src={!isSelf && place === 1 ? RankingFirstBg : isSelf ? SelfRankingBg : RankingBg}
      />
      {!isSelf && (
        <Typography
          sx={{
            position: 'relative',
            color: place === 1 ? Colors.gray[700] : Colors.white,
            textAlign: 'center',
            fontWeight: 800,
            fontSize: '14px',
          }}
        >
          Ranking
        </Typography>
      )}
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '4px',
        }}
      >
        {!isSelf && place === 1 && (
          <img src={Confetti} style={{ position: 'absolute', zIndex: 0, top: -16, width: 132, height: 68 }} />
        )}
        <Box
          style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: variant === 'lg' ? 120 : 100,
            width: variant === 'lg' ? 120 : 100,
            borderRadius: '50%',
            marginBottom: '8px',
            background: `linear-gradient(328deg, ${Colors.magenta[300]} 0%, ${Colors.magenta[200]} 100%)`,
          }}
        >
          <Avatar alt={name} sx={{ height: 89, width: 89, border: 'none' }} src={avatar || IconPlaceholder} />
        </Box>
        {!isSelf && place === 1 && (
          <img src={Wreath} style={{ position: 'absolute', zIndex: 0, bottom: -8, height: 96, width: 137 }} />
        )}
        <Box
          sx={{
            position: 'relative',
            padding: '6px 8px',
            borderRadius: 100,
            border: `1px solid ${Colors.magenta[150]}`,
            background: Colors.magenta[300],
          }}
        >
          <Typography sx={{ fontSize: 12, fontWeight: 700, lineHeight: 1, color: Colors.white }}>{name}</Typography>
        </Box>
      </Box>
      {!isSelf && (
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: place === 1 ? '10px' : '15px',
            height: 52,
            width: 52,
          }}
        >
          <img
            style={{ position: 'absolute', zIndex: 0, top: 0, bottom: 0, left: 0, right: 0 }}
            src={place === 1 ? FirstPlaceBgSvg : PlaceBgSvg}
          />
          <Typography
            style={{
              position: 'relative',
              fontSize: 22,
              fontWeight: 900,
              lineHeight: 1,
              color: Colors.blue[100],
              marginTop: '4px',
              textShadow: '0px 1px 3px rgba(9, 76, 83, 0.35)',
            }}
          >
            {place ?? 0}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
