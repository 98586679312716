import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import { Button, ButtonProps } from '@mui/material';
import { SxProps } from '@mui/system';

interface Props {
  text: string;
  startIcon?: any;
  onClick: any;
  inputProps?: ButtonProps;
  sx?: SxProps;
  disabled?: boolean;
}

const CustomButton = styled(Button)(({ theme }) => ({
  borderRadius: '999px',
  padding: '5px 25px 5px 25px',
  '&.MuiButton-text': {
    color: theme.palette.common.white,
  },
}));

const StyledButton: FC<Props> = ({ text, sx, inputProps, startIcon, onClick, disabled }) => {
  return (
    <CustomButton sx={sx} disabled={disabled} startIcon={startIcon} {...inputProps} onClick={onClick}>
      {text}
    </CustomButton>
  );
};
export default StyledButton;
