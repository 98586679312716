type useRadialChartProps = {
  centerX: number;
  centerY: number;
  radius: number;
  isSemicircle?: boolean;
};

export const useRadialChart = ({ centerX, centerY, radius, isSemicircle }: useRadialChartProps) => {
  function getAnglesArc(start: number, end: number) {
    if (isSemicircle) {
      return {
        startAngle: start === 0 ? 90 : 90 + 180 * (start / 100),
        endAngle: end === 0 ? 270 : 90 + 180 * (end / 100),
      };
    }
    return {
      startAngle: start === 0 ? 0 : 359.99 * (start / 100),
      endAngle: end === 0 ? 0 : 359.99 * (end / 100),
    };
  }

  function polarToCartesian(angleInDegrees: number) {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  }

  function describeArc(startPercent: number, endPercent: number) {
    const { startAngle, endAngle } = getAnglesArc(startPercent, endPercent);

    const start = polarToCartesian(endAngle);
    const end = polarToCartesian(startAngle);

    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    const d = `M${start.x},${start.y}A${radius}, ${radius}, 0, ${largeArcFlag}, 0, ${end.x}, ${end.y}`;

    return d;
  }

  return { describeArc };
};
