export enum DIMENSIONS {
  HL = 'HL',
  PA = 'PA',
  NU = 'NU',
  MW = 'MW',
  WE = 'WE',
  SL = 'SL',
  WB = 'WB',
}

export const DIMENSIONS_DATA = [
  { value: DIMENSIONS.MW, label: 'Mental well-being' },
  { value: DIMENSIONS.NU, label: 'Nutrition' },
  { value: DIMENSIONS.PA, label: 'Physical activity' },
  { value: DIMENSIONS.HL, label: 'Physical health' },
  { value: DIMENSIONS.SL, label: 'Sleep' },
  { value: DIMENSIONS.WB, label: 'Well-being' },
  { value: DIMENSIONS.WE, label: 'Working environment' },
];
