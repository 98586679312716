import Grid from '@mui/material/Grid';
import React, { useEffect } from 'react';
import { Box, Tooltip, Typography, useMediaQuery } from '@mui/material';
import InputField from '../../../components/features/CustomTextField';
import theme from '../../../theme';
import { InfoOutlined } from '@mui/icons-material';
import { CustomButton } from '../../../components/features/CustomButton';
import { UploadImage } from './UploadImage';
import CustomLabelValueDropdown from '../../../components/features/CustomLabelValueDropdown';
import { AWARD_TYPE } from '../../../models/enum/AWARD_TYPE';
import { EditForm } from '../hooks/useHandle';
import { Challenge } from '../../../models/Challenge';
import { CALCULATION_TYPE } from '../../../models/enum/CALCULATION_TYPE';
import { CHALLENGE_TYPE } from '../../../models/enum/CHALLENGE_TYPE';
import { COMPETITION } from '../../../models/enum/COMPETITION';
import { AWARD_TRIGGER } from '../../../models/enum/AWARD_TRIGGER';

type Props = {
  form: EditForm;
  isAvailableToThirdForm: boolean;
  challenge: Challenge;
  onUpload: (file: string | null) => void;
  onBack: () => void;
  onContinue: () => void;
  handleChangeAwardType: (awardType: AWARD_TYPE) => void;
  handleChangeAwardTitle: (value: string) => void;
  handleChangeNumberOfPoints: (value: string) => void;
  handleUpdateTotalGoal: (value: string) => void;
  handleChangeNumberOfDays: (value: string) => void;
  handleChangeDailyGoal: (value: string) => void;
};
const AwardOptions = [
  { label: 'Badge', value: AWARD_TYPE.BADGE },
  { label: 'Points', value: AWARD_TYPE.POINT },
  { label: 'Other', value: AWARD_TYPE.OTHER },
  { label: 'None', value: AWARD_TYPE.NONE },
];
export const SecondForm: React.FC<Props> = ({
  form,
  challenge,
  isAvailableToThirdForm,
  onUpload,
  onBack,
  onContinue,
  handleChangeAwardType,
  handleChangeAwardTitle,
  handleChangeNumberOfPoints,
  handleUpdateTotalGoal,
  handleChangeNumberOfDays,
  handleChangeDailyGoal,
}) => {
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));
  const showTotalGoal =
    challenge.challengeCharacteristic.challengeType === CHALLENGE_TYPE.LEADERBOARD ||
    challenge.challengeCharacteristic.challengeType === CHALLENGE_TYPE.TARGET;
  const TotalGoalTooltipText =
    challenge.challengeCharacteristic.calculationType === CALCULATION_TYPE.AVERAGE
      ? 'To reach on average for the challenge duration'
      : 'To reach in total for the challenge duration';
  const NumberOfDaysTooltipText =
    challenge.challengeCharacteristic.challengeType === CHALLENGE_TYPE.LONGER_STREAK
      ? 'Days-in-a-row to reach the daily goal'
      : challenge.challengeCharacteristic.challengeType === CHALLENGE_TYPE.STICK_TO_IT
      ? 'Days to reach the daily goal'
      : '';
  const isStreakOrStick =
    challenge.challengeCharacteristic.challengeType === CHALLENGE_TYPE.STICK_TO_IT ||
    challenge.challengeCharacteristic.challengeType === CHALLENGE_TYPE.LONGER_STREAK;
  const isLeaderboard = challenge && challenge.challengeCharacteristic.challengeType === CHALLENGE_TYPE.LEADERBOARD;

  const isCalculationTypeSum = challenge.challengeCharacteristic.calculationType === CALCULATION_TYPE.SUM;
  const isCalculationTypeAverage = challenge.challengeCharacteristic.calculationType === CALCULATION_TYPE.AVERAGE;
  const isCalculationTypeNone = challenge.challengeCharacteristic.calculationType === CALCULATION_TYPE.NONE;

  const isTeam = challenge.challengeCharacteristic.competition === COMPETITION.TEAM;
  const isIndividual = challenge.challengeCharacteristic.competition === COMPETITION.INDIVIDUAL;
  const dailyGoalTeamTooltipText =
    isTeam && isCalculationTypeSum
      ? 'For whole team to reach each day'
      : isTeam && isCalculationTypeAverage
      ? 'For whole team to reach on average each day'
      : isTeam && isCalculationTypeNone
      ? 'For each member to reach each day'
      : '';
  const dailyGoalIndividualTooltipText =
    isIndividual && (isCalculationTypeSum || isCalculationTypeNone)
      ? 'To reach each day'
      : isIndividual && isCalculationTypeAverage
      ? 'To reach on average each day'
      : '';
  const isCompletion = challenge.challengeAward && challenge.challengeAward.awardTrigger === AWARD_TRIGGER.COMPLETION;
  const isBoolean = challenge.challengeAction.actionType === 'HABIT';
  const isFastest =
    challenge.challengeAward &&
    challenge.challengeAward.awardTrigger === AWARD_TRIGGER.FASTEST &&
    (challenge?.challengeCharacteristic.challengeType === CHALLENGE_TYPE.STICK_TO_IT ||
      challenge?.challengeCharacteristic.challengeType === CHALLENGE_TYPE.LONGER_STREAK);
  useEffect(() => {
    if (form.awardType !== AWARD_TYPE.BADGE) {
      onUpload(null);
    }
  }, [form.awardType]);

  return (
    <Grid>
      {!isLeaderboard && (showTotalGoal || isCompletion || isFastest || !isBoolean) && (
        <Grid container sx={{ py: '24px', borderBottom: '1px dashed #F2F2F2' }}>
          <Grid xs={12} md={3} sx={{ display: 'flex', alignItems: 'center', paddingTop: { xs: 0, md: '20px' } }}>
            <Typography
              sx={{
                fontWeight: { xs: 900, md: 'bold' },
                fontSize: 14,
                lineHeight: 1.4,
                color: 'text.primary',
              }}
            >
              {'Goal'}
            </Typography>
          </Grid>
          <Grid xs={12} md={9} sx={{ mt: { xs: '16px', md: 0 } }}>
            {showTotalGoal ? (
              <InputField
                bold={true}
                label={
                  <Tooltip
                    title={
                      <Typography sx={{ fontSize: '10px', maxWidth: 166 }} variant={'body2'}>
                        {TotalGoalTooltipText}
                      </Typography>
                    }
                    placement={xsDown ? 'bottom-end' : 'top-start'}
                    enterTouchDelay={0}
                    leaveTouchDelay={10000}
                    sx={{ mx: 1, color: theme.palette.grey[600] }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: 'fit-content',
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          fontSize: 14,
                          lineHeight: 1.4,
                          color: 'text.primary',
                          mr: '4px',
                        }}
                      >
                        {'Total goal'}
                      </Typography>
                      <InfoOutlined style={{ width: '12px', height: '12px' }} fontSize={'inherit'} color={'inherit'} />
                    </Box>
                  </Tooltip>
                }
                setValue={(e) => handleUpdateTotalGoal(e.target.value)}
                name="Goal"
                type="number"
                value={form.totalGoal}
                placeholder={'Enter number'}
              />
            ) : (
              <Grid container>
                {(isCompletion || isFastest) && (
                  <Grid xs={12} md={isBoolean ? 12 : 6}>
                    <InputField
                      bold={true}
                      label={
                        isStreakOrStick ? (
                          <Tooltip
                            title={
                              <Typography sx={{ fontSize: '10px', maxWidth: 166 }} variant={'body2'}>
                                {NumberOfDaysTooltipText}
                              </Typography>
                            }
                            placement={xsDown ? 'bottom-end' : 'top-start'}
                            enterTouchDelay={0}
                            leaveTouchDelay={10000}
                            sx={{ mx: 1, color: theme.palette.grey[600] }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                width: 'fit-content',
                              }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: 14,
                                  lineHeight: 1.4,
                                  color: 'text.primary',
                                  mr: '4px',
                                }}
                              >
                                Number of days
                              </Typography>
                              <InfoOutlined
                                style={{ width: '12px', height: '12px' }}
                                fontSize={'inherit'}
                                color={'inherit'}
                              />
                            </Box>
                          </Tooltip>
                        ) : (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              width: 'fit-content',
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: 'bold',
                                fontSize: 14,
                                lineHeight: 1.4,
                                color: 'text.primary',
                                mr: '4px',
                              }}
                            >
                              Number of days
                            </Typography>
                          </Box>
                        )
                      }
                      setValue={(e) => handleChangeNumberOfDays(e.target.value)}
                      name="number-of-day"
                      type={'number'}
                      value={form.numberOfDays}
                      placeholder={'Enter number'}
                      inputProps={{ sx: { paddingRight: { xs: 0, md: '12px' } } }}
                    />
                  </Grid>
                )}
                {!isBoolean && (
                  <Grid
                    xs={12}
                    md={!isCompletion && !isFastest ? 12 : 6}
                    sx={{ mt: { xs: isCompletion ? '24px' : 0, md: 0 } }}
                  >
                    <InputField
                      bold={true}
                      label={
                        <Tooltip
                          title={
                            <Typography sx={{ fontSize: '10px', maxWidth: 166 }} variant={'body2'}>
                              {isTeam ? dailyGoalTeamTooltipText : dailyGoalIndividualTooltipText}
                            </Typography>
                          }
                          placement={xsDown ? 'bottom-end' : 'top-start'}
                          enterTouchDelay={0}
                          leaveTouchDelay={10000}
                          sx={{ mx: 1, color: theme.palette.grey[600] }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              width: 'fit-content',
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: 'bold',
                                fontSize: 14,
                                lineHeight: 1.4,
                                color: 'text.primary',
                                mr: '4px',
                              }}
                            >
                              Daily goal
                            </Typography>
                            <InfoOutlined
                              style={{ width: '12px', height: '12px' }}
                              fontSize={'inherit'}
                              color={'inherit'}
                            />
                          </Box>
                        </Tooltip>
                      }
                      setValue={(e) => handleChangeDailyGoal(e.target.value)}
                      name="Daily-goal"
                      type={'number'}
                      value={form.dailyGoal}
                      placeholder={'Enter number'}
                      inputProps={{ sx: { paddingLeft: { xs: 0, md: !isCompletion ? 0 : '12px' } } }}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      )}

      <Grid container sx={{ pt: '24px' }}>
        <Grid xs={12} md={3} sx={{ paddingTop: { xs: 0, md: '32px' } }}>
          <Typography
            sx={{
              fontWeight: { xs: 900, md: 'bold' },
              fontSize: 14,
              lineHeight: 1.4,
              color: 'text.primary',
            }}
          >
            Award
          </Typography>
        </Grid>
        <Grid xs={12} md={9} sx={{ mt: { xs: '16px', md: 0 } }}>
          <Grid container sx={{ mb: '24px' }}>
            <Grid
              xs={12}
              md={
                !form.awardType?.length || form.awardType === AWARD_TYPE.BADGE || form.awardType === AWARD_TYPE.NONE
                  ? 12
                  : 6
              }
              sx={{
                paddingRight: {
                  xs: 0,
                  md: form.awardType === AWARD_TYPE.BADGE || form.awardType === AWARD_TYPE.NONE ? 0 : '12px',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: 'fit-content',
                  marginBottom: '8px',
                  marginLeft: '16px',
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 14,
                    lineHeight: 1.4,
                    color: 'text.primary',
                    mr: '4px',
                  }}
                >
                  Award type
                </Typography>
              </Box>
              <CustomLabelValueDropdown
                width={'100%'}
                list={AwardOptions}
                placeholder="Choose"
                selected={form.awardType ? form.awardType : ''}
                disabled={false}
                setSelected={(value) => handleChangeAwardType(value as AWARD_TYPE)}
              />
            </Grid>
            {form.awardType !== AWARD_TYPE.BADGE &&
              form.awardType !== AWARD_TYPE.NONE &&
              form.awardType &&
              form.awardType.length && (
                <Grid xs={12} md={6} sx={{ mt: { xs: '16px', md: 0 } }}>
                  {form.awardType !== AWARD_TYPE.POINT ? (
                    <InputField
                      key={'Award text'}
                      bold={true}
                      label={
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: 'fit-content',
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 'bold',
                              fontSize: 14,
                              lineHeight: 1.4,
                              color: 'text.primary',
                              mr: '4px',
                            }}
                          >
                            {form.awardType === AWARD_TYPE.OTHER ? 'Prize' : 'Award text'}
                          </Typography>
                        </Box>
                      }
                      setValue={(e) => handleChangeAwardTitle(e.target.value)}
                      name="Award-text"
                      value={form.awardTitle}
                      placeholder={'Enter text'}
                      inputProps={{ sx: { paddingLeft: { xs: 0, md: '12px' } } }}
                    />
                  ) : (
                    <InputField
                      key={'Number of points'}
                      bold={true}
                      label={
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: 'fit-content',
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 'bold',
                              fontSize: 14,
                              lineHeight: 1.4,
                              color: 'text.primary',
                              mr: '4px',
                            }}
                          >
                            Number of points
                          </Typography>
                        </Box>
                      }
                      setValue={(e) => handleChangeNumberOfPoints(e.target.value)}
                      name="points"
                      type={'number'}
                      value={form.points}
                      placeholder={'Enter number'}
                      inputProps={{ sx: { paddingLeft: { xs: 0, md: '12px' } } }}
                    />
                  )}
                </Grid>
              )}
          </Grid>

          {form.awardType && form.awardType === AWARD_TYPE.BADGE && (
            <UploadImage
              label={'Upload a badge picture.'}
              imageUrl={form.badgeImageBase64 ? form.badgeImageBase64 : form.badgeImageUrl}
              onUpload={onUpload}
            />
          )}
        </Grid>
      </Grid>

      <Grid sx={{ mt: { xs: '50px', md: '40px' }, justifyContent: 'center', display: 'flex' }}>
        <CustomButton
          type={'bigLightOrange'}
          text={'Back'}
          width={xsDown ? '140px' : '160px'}
          sx={{ height: 36, fontSize: '14px', lineHeight: '14px', mr: { xs: '8px', md: '12px' } }}
          onClick={onBack}
        />
        <CustomButton
          type={'bigOrange'}
          text={'Continue'}
          width={xsDown ? '140px' : '160px'}
          sx={{ height: 36, fontSize: '14px', lineHeight: '14px', ml: { xs: '8px', md: '12px' } }}
          disabled={!isAvailableToThirdForm}
          onClick={onContinue}
        />
      </Grid>
    </Grid>
  );
};
