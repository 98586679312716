export const daysConverter = (days: number) => {
  if (days > 0 && days % 30 === 0) {
    const month = (days / 30).toFixed(0);
    return `${month} month${Number(month) === 1 ? '' : 's'}`;
  }

  if (days > 0 && days % 7 === 0) {
    const weeks = Math.round(days / 7);
    return `${weeks} week${weeks === 1 ? '' : 's'}`;
  }

  return `${days} day${days === 1 ? '' : 's'}`;
};
