import React from 'react';
import { Box, Modal, Typography, useMediaQuery } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../../assets/svg/feature/close.svg';
import { Colors } from '../../../theme/colors';
import { CustomButton } from '../../../components/features/CustomButton';
import { IMetric } from '../../../models/Metrics';
import { WhoFiveSlider } from './WhoFiveSlider';
import theme from '../../../theme';

type WhoFiveModalProps = {
  isOpen: boolean;
  isUpdating: boolean;
  values: { [key: string]: number };
  metrics?: IMetric[];
  onClose: () => void;
  handleSubmit: () => void;
  handleOnChange: (type: string) => (val: number) => void;
};

export const WhoFiveModal: React.FC<WhoFiveModalProps> = ({
  isOpen,
  isUpdating,
  metrics,
  values,
  onClose,
  handleSubmit,
  handleOnChange,
}) => {
  const tableDown = useMediaQuery(theme.breakpoints.down('table'));
  if (!metrics) return null;
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        display: 'flex',
        alignItems: { xs: 'flex-end', table: 'center' },
        justifyContent: 'center',
        paddingTop: { xs: '60px', table: '16px' },
        paddingBottom: { table: '16px' },
      }}
    >
      <Box
        sx={{
          position: 'relative',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          maxWidth: { table: '648px' },
          maxHeight: '924px',
          margin: { xs: 'unset', table: '16px' },
          padding: { xs: '30px 16px 40px', table: '50px 50px 40px 50px' },
          height: '100%',
          background: Colors.white,
          ...(tableDown
            ? { marginTop: '40px', borderTopRightRadius: '20px', borderTopLeftRadius: '20px' }
            : { borderRadius: '20px' }),
          overflowY: 'scroll',
        }}
      >
        <Box
          component="button"
          sx={{
            position: 'absolute',
            top: { xs: '24px', table: '16px' },
            right: '16px',
            height: 28,
            width: 28,
            padding: 0,
            borderRadius: 38,
            background: '#F0F2F3',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 'auto',
          }}
          onClick={onClose}
        >
          <CloseIcon fill={Colors.gray[400]} />
        </Box>
        <Typography
          sx={{
            fontSize: '18px',
            textAlign: 'center',
            fontWeight: '600',
            color: Colors.gray[700],
            mb: { xs: '16px', table: '24px' },
          }}
        >
          WHO-5 Well-being Index
        </Typography>
        <Typography sx={{ fontSize: '14px', color: '#5D6C6D', mb: '30px' }}>
          World Health Organisation- Five Well-Being Index (WHO-5) is a short self-reported measure of current mental
          wellbeing.
          <br />
          Please respond to each item by marking one box per row, regarding how you felt in the last two weeks.
        </Typography>
        <Box
          sx={{
            flex: 1,
            overflow: 'scroll',
            mb: '32px',
            ml: { xs: '-16px', table: '-50px' },
            mr: { xs: '-16px', table: '-50px' },
            paddingInline: { xs: '16px', table: '50px' },
          }}
        >
          <Box>
            {metrics.map((metric, idx) => (
              <WhoFiveSlider
                key={metric.metric_type}
                position={idx + 1}
                metric={metric}
                value={values[metric.metric_type]}
                onChange={handleOnChange(metric.metric_type)}
              />
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            maxWidth: { xs: '330px', table: '416px' },
            ml: 'auto',
            mr: 'auto',
          }}
        >
          <CustomButton
            type="bigBlue"
            text="Submit"
            disabled={isUpdating}
            minWidth={tableDown ? '152px' : '200px'}
            sx={{
              padding: '10px 16px',
            }}
            inputProps={{
              variant: 'contained',
            }}
            onClick={handleSubmit}
          />
          <CustomButton
            type="bigLightBlue"
            text="Cancel"
            disabled={isUpdating}
            minWidth={tableDown ? '152px' : '200px'}
            sx={{ padding: '10px 16px' }}
            inputProps={{
              variant: 'contained',
            }}
            onClick={onClose}
          />
        </Box>
      </Box>
    </Modal>
  );
};
