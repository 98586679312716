import React from 'react';
import { IonContent, IonPage, useIonRouter } from '@ionic/react';
import { Box, CardMedia, Container, Grid, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { Colors } from '../../theme/colors';
import CustomNewHeader from '../../components/features/CustomNewHeader';
import { useHandleFetch } from './hooks/useHandleFetch';
import { TrophyCard } from '../../components/features/TrophyCard';
import EmptyTrophyIcon from '../../assets/png/empty-trophy.png';
import { FilterSelector } from '../../components/features/HeaderFilter/FilterSelector';
import { AWARD_TYPE_LIST } from '../PointsPage/PointsPage';
import theme from '../../theme';

const SkeletonList: React.FC = () => (
  <>
    {new Array(8).fill('1').map((_, idx) => (
      <Grid item key={idx.toString()} xs={12} sm={6} md={3}>
        <Skeleton variant="rectangular" style={{ height: 275, width: '100%', borderRadius: '20px' }} />
      </Grid>
    ))}
  </>
);

const EmptyState: React.FC = () => (
  <Grid sx={{ margin: '50px auto', textAlign: 'center' }}>
    <CardMedia sx={{ flexShrink: 0, height: 105, width: 133, margin: '0 auto' }} image={EmptyTrophyIcon} />
    <Typography sx={{ fontSize: '18px', lineHeight: '23px', color: Colors.gray[200], fontWeight: 'bold', mt: '32px' }}>
      Start collecting, start challenging yourself!
    </Typography>
  </Grid>
);

const TrophyLibraryPage: React.FC = () => {
  const router = useIonRouter();
  const smDown = useMediaQuery(theme.breakpoints.down('md'));
  const { loading, items, isFetching, divRef, handleScrollEvent, navigateToProgressPage } = useHandleFetch();
  const handleNavigation = (value: string, isSelected: boolean) => {
    if (value === 'points' && !isSelected) {
      router.push('/page/your-points');
    }
  };
  return (
    <IonPage>
      <CustomNewHeader
        label={'Trophy library'}
        inProgress={loading}
        upBox={
          smDown ? (
            <Box sx={{ mb: '24px' }}>
              <FilterSelector
                tabMode={true}
                data={AWARD_TYPE_LIST}
                selected={['awards']}
                onClickItem={(value: string, isSelected: boolean) => () => {
                  handleNavigation(value, isSelected);
                }}
              />
            </Box>
          ) : null
        }
        rightAction={
          !smDown ? (
            <Box sx={{ alignSelf: { xs: 'stretch', md: 'center' }, marginLeft: 'auto' }}>
              <FilterSelector
                data={AWARD_TYPE_LIST}
                selected={['awards']}
                onClickItem={(value: string, isSelected: boolean) => () => {
                  handleNavigation(value, isSelected);
                }}
              />
            </Box>
          ) : null
        }
      />
      <IonContent
        ref={divRef}
        onIonScroll={handleScrollEvent}
        style={{ '--padding-bottom': '36px' }}
        scrollEvents={true}
      >
        <Container maxWidth={'lg'}>
          <Grid container spacing={'24px'}>
            {!items ? (
              <SkeletonList />
            ) : items.length > 0 ? (
              <>
                {items.map((item) => (
                  <Grid item key={item.awardUUID} xs={12} sm={6} md={3}>
                    <TrophyCard award={item} onClick={() => navigateToProgressPage(item.challengeUUID)} />
                  </Grid>
                ))}
                {isFetching ? <SkeletonList /> : null}
              </>
            ) : (
              <EmptyState />
            )}
          </Grid>
        </Container>
      </IonContent>
    </IonPage>
  );
};

export default TrophyLibraryPage;
