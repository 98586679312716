import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { updateHabit } from '../../../redux/slices/challengeProgressSlice';
import { openToast } from '../../../redux/slices/appSlice';
import { CalendarTimelineItem } from '../types';
import { CHALLENGE_TYPE } from '../../../models/enum/CHALLENGE_TYPE';
import { RangeMap } from '../../../models/Shared';
import { CALCULATION_TYPE } from '../../../models/enum/CALCULATION_TYPE';

const getPeriodRange = (
  start: string,
  end: string,
  timeLine: CalendarTimelineItem[],
  teamTimeLine?: CalendarTimelineItem[],
  maxSize?: number,
  isTeamGoal?: boolean,
) => {
  const timelineRange = new Map<string, { date: string; value?: number; status?: string }>();
  const today = moment();
  timeLine.forEach((item) => timelineRange.set(moment(item.date).format('YYYY-MM-DD'), item));
  const fromDate = moment(start);
  const toDate = moment(end);
  const diff = toDate.isAfter(today, 'days') ? today.diff(fromDate, 'days') : toDate.diff(fromDate, 'days');
  const rangeDate: RangeMap = [];
  if (teamTimeLine) {
    const teamTimelineRange = new Map<string, { date: string; value?: number; status?: string }>();
    teamTimeLine.forEach((item) =>
      teamTimelineRange.set(
        moment(item.date).format('YYYY-MM-DD'),
        item.teamValue ? { ...item, value: item.teamValue } : item,
      ),
    );
    for (let i = 0; i <= diff; i++) {
      const day = moment(start).add(i, 'days');
      const key = day.format('YYYY-MM-DD');
      rangeDate.push({
        date: key,
        day: day.date(),
        isToday: day.isSame(today, 'day'),
        label:
          typeof teamTimelineRange.get(key)?.value === 'number'
            ? `${teamTimelineRange.get(key)?.value}/${maxSize}`
            : `0/${maxSize}`,
        isSuccess: timelineRange.get(key)?.status === 'SUCCESS',
        isComplete: isTeamGoal ? teamTimelineRange.get(key)?.status === 'SUCCESS' : false,
      });
    }
    return rangeDate;
  }

  for (let i = 0; i <= diff; i++) {
    const day = moment(start).add(i, 'days');
    const key = day.format('YYYY-MM-DD');
    rangeDate.push({
      date: key,
      day: day.date(),
      isToday: day.isSame(today, 'day'),
      isSuccess: timelineRange.get(key)?.status === 'SUCCESS',
    });
  }
  return rangeDate;
};

export const useHandleBooleanCalendar = () => {
  const [visible, setVisible] = useState(false);

  const dispatch = useDispatch();
  const { isLoading, isUpdating } = useSelector((state: RootState) => state.challengeProgress.meta);

  const { duration, progress, navigation, detail, user } = useSelector(
    (state: RootState) => state.challengeProgress.data,
  );

  const rangeDate: RangeMap = useMemo(() => {
    const isTeamGoal = detail?.isTeam && detail?.challengeTyp !== CHALLENGE_TYPE.LEADERBOARD;
    return duration?.startDate && duration?.endDate
      ? getPeriodRange(
          duration.startDate,
          duration.endDate,
          progress?.scoreTimeline ?? [],
          progress?.teamScoreTimeLine,
          detail?.calculationType === CALCULATION_TYPE.SUM
            ? !progress?.goalValue
              ? detail?.teamSize
              : progress.goalValue
            : detail?.teamSize,
          isTeamGoal,
        )
      : [];
  }, [duration, progress]);

  const handleUpdateHabit = (date: string, isSuccess: boolean) => () => {
    if (duration?.isGracePeriodEnd) return;
    if (isUpdating || !navigation?.uuid) return;
    const today = new Date().setHours(0, 0, 0, 0);
    const selectedDay = new Date(date).setHours(0, 0, 0, 0);
    const minDay = new Date(today).setDate(new Date(today).getDate() - 7);
    if (today < selectedDay) {
      if (user?.joinTeamDate && today < new Date(user.joinTeamDate).getTime()) {
        dispatch(openToast({ text: 'You cannot log data before the day you join the team', type: 'error' }));
        return;
      }
      dispatch(openToast({ text: 'The day has not yet come', type: 'error' }));
      return;
    }
    if (minDay >= selectedDay) {
      dispatch(openToast({ text: 'You can only log data for the past 7 days', type: 'error' }));
      return;
    }
    dispatch(updateHabit({ date, habitValue: !isSuccess, challengeUUID: navigation.uuid }));
  };

  const openModal = () => setVisible(true);
  const onClose = () => setVisible(false);

  const getTeamTimelineScore = (): CalendarTimelineItem[] => {
    if (!detail?.isTeam || !progress?.scoreTimeline || !progress?.teamScoreTimeLine) return [];
    const yourScoreTimeline = new Map<string, { status?: string }>();
    const isTeamGoal = detail?.isTeam && detail?.challengeTyp !== CHALLENGE_TYPE.LEADERBOARD;
    progress.scoreTimeline.forEach((item) => yourScoreTimeline.set(item.date, { status: item.status }));
    const teamTimeline = progress.teamScoreTimeLine.map((item) => ({
      ...item,
      status: yourScoreTimeline.get(item.date)?.status,
    }));
    return teamTimeline;
  };

  const showDate =
    duration?.endDate && new Date(duration.endDate) < new Date() ? new Date(duration.endDate) : undefined;

  return {
    isLoading,
    rangeDate,
    visible,
    timelineScore: progress?.scoreTimeline.map((item) => ({ ...item, isComplete: item.status === 'SUCCESS' })) ?? [],
    teamTimelineScore: getTeamTimelineScore(),
    startDate: duration?.startDate,
    endDate: duration?.endDate,
    isTeam: detail?.isTeam,
    showDate,
    actions: progress?.metricActions,
    maxCount: detail?.isTeam
      ? detail?.calculationType === CALCULATION_TYPE.SUM
        ? !progress?.goalValue
          ? detail.teamSize
          : progress?.goalValue
        : detail?.teamSize
      : undefined,
    handleUpdateHabit,
    openModal,
    onClose,
  };
};
