import { Box, IconButton, Typography } from '@mui/material';
import { Colors } from '../../../theme/colors';
import React, { useCallback, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import EmptyUploadImage from '../../../assets/png/upload.png';
import { ReactComponent as UploadIcon } from '../../../assets/svg/feature/upload.svg';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import { PermissionStatus } from '@capacitor/camera/dist/esm/definitions';

type Props = {
  label: string;
  adminColors?: boolean;
  imageUrl: string | null;
  onUpload: (file: string) => void;
};
export const UploadImage: React.FC<Props> = ({ label, imageUrl, adminColors = true, onUpload }) => {
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [imageSrc, setImageSrc] = useState<string>();

  function readFile(file: File) {
    return new Promise<string>((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader?.result as string), false);
      reader.readAsDataURL(file);
    });
  }
  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (acceptedFiles?.length > 0) {
      setErrorMsg('');

      const file = acceptedFiles[0];
      const imageDataUrl = await readFile(file);
      setImageSrc(imageDataUrl);
      onUpload(imageDataUrl);
    }
  }, []);
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/*',
    maxSize: 2e6,
    onDrop,
    onDropRejected: (e: FileRejection[]) => handleReject(e),
    multiple: false,
  });
  const handleReject = (e: FileRejection[]) => {
    if (e.length > 0) {
      const firstFile = e[0];
      if (firstFile.errors[0].code === 'file-too-large') {
        setErrorMsg('The image file should be up to 2MB');
      } else if (firstFile.errors.length > 0) {
        setErrorMsg(firstFile.errors[0].message);
      }
    }
  };

  const handlePhotoUpload = (source?: CameraSource) => {
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    Camera.checkPermissions().then((res: PermissionStatus) => {
      if (res.photos !== 'granted') {
        Camera.requestPermissions().then((res: PermissionStatus) => {
          if (res.photos === 'denied') {
            console.error('Photos permission denied');
          } else {
            handlePickImage(source);
          }
        });
      } else {
        handlePickImage(source);
      }
    });
  };
  const handlePickImage = (source?: CameraSource) => {
    Camera.getPhoto({
      source: source ?? CameraSource.Prompt,
      resultType: CameraResultType.DataUrl,
    }).then(async (res: Photo) => {
      if (res.dataUrl) {
        setImageSrc(res.dataUrl);
        onUpload(res.dataUrl);
      }
    });
  };

  const isWeb = !Capacitor.isNativePlatform();

  return isWeb ? (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          border: '1px dashed #D3D7DA',
          borderRadius: '15px',
          padding: '10px 24px 10px 10px',
          cursor: 'pointer',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{ width: '78px', height: '78px', borderRadius: '10px', overflow: 'hidden', mr: '16px', flexShrink: 0 }}
          >
            <img
              src={imageSrc || imageUrl || EmptyUploadImage}
              alt={'upload'}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          </Box>
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: { xs: 12, md: 14 },
              lineHeight: 1.4,
              color: Colors.gray[700],
            }}
          >
            {label} Max size 2MB
          </Typography>
        </Box>
        <IconButton
          sx={
            adminColors
              ? {
                  width: 36,
                  height: 36,
                  backgroundColor: '#FAEED7',
                  border: '1px solid #FCCF79',
                  display: { xs: 'none', md: 'flex' },
                  marginLeft: '16px',
                }
              : {
                  width: 36,
                  height: 36,
                  backgroundColor: '#D0F2F5',
                  border: '1px solid #16BECF',
                  display: { xs: 'none', md: 'flex' },
                  marginLeft: '16px',
                }
          }
        >
          <UploadIcon style={{ width: 16, height: 16, fill: adminColors ? '#FAAF1F' : '#16BECF' }} />
        </IconButton>
      </Box>
      {errorMsg ? <Typography style={{ fontSize: 12, color: '#DA4E53', margin: '4px 0' }}>{errorMsg}</Typography> : ''}
    </div>
  ) : (
    <Box onClick={() => handlePhotoUpload(CameraSource.Photos)}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          border: '1px dashed #D3D7DA',
          borderRadius: '15px',
          padding: '10px 24px 10px 10px',
          cursor: 'pointer',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{ width: '78px', height: '78px', borderRadius: '10px', overflow: 'hidden', mr: '16px', flexShrink: 0 }}
          >
            <img
              src={imageSrc || imageUrl || EmptyUploadImage}
              alt={'upload'}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          </Box>
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: { xs: 12, md: 14 },
              lineHeight: 1.4,
              color: Colors.gray[700],
            }}
          >
            {label} Max size 2MB
          </Typography>
        </Box>
        <IconButton
          sx={
            adminColors
              ? {
                  width: 36,
                  height: 36,
                  backgroundColor: '#FAEED7',
                  border: '1px solid #FCCF79',
                  display: { xs: 'none', md: 'flex' },
                  marginLeft: '16px',
                }
              : {
                  width: 36,
                  height: 36,
                  backgroundColor: '#D0F2F5',
                  border: '1px solid #16BECF',
                  display: { xs: 'none', md: 'flex' },
                  marginLeft: '16px',
                }
          }
        >
          <UploadIcon style={{ width: 16, height: 16, fill: adminColors ? '#FAAF1F' : '#16BECF' }} />
        </IconButton>
      </Box>
    </Box>
  );
};
