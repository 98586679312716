import MentalHealthIcon from '../assets/svg/feature/dimentions/mental-fitness.svg';
import PhysicalActivityIcon from '../assets/svg/feature/dimentions/sport.svg';
import NutritionIcon from '../assets/svg/feature/dimentions/nutrition.svg';
import SleepIcon from '../assets/svg/feature/dimentions/sleep.svg';
import { JourneyCards } from '../models/JourneyCards';
import { IMetric } from '../models/Metrics';

export interface MetricsCategory {
  title: string;
  metrics: IMetric[];
  color: string;
  score: number;
  icon: string;
  deviation: string;
}

export const metricsCategoryColors = ['#00B6BE', '#DA4E53', '#FAAF1F', '#C154B0'];

export const categories = ['mental_health', 'nutrition', 'sleep', 'physical_health'];

export const getMetricsCategory = (categoryId?: string, data?: JourneyCards): MetricsCategory | undefined => {
  switch (categoryId) {
    case 'mental_health':
      return {
        title: 'Mental Well-being',
        metrics: data?.mental_health ?? [],
        color: '#00B6BE',
        score: data?.scores?.mental_health ?? 0,
        icon: MentalHealthIcon,
        deviation: data?.scores?.mental_health_deviation_sign ?? '',
      };
    case 'physical_health':
      return {
        title: 'Physical Activity',
        metrics: data?.physical_health ?? [],
        color: '#DA4E53',
        icon: PhysicalActivityIcon,
        score: data?.scores?.physical_health ?? 0,
        deviation: data?.scores?.physical_health_deviation_sign ?? '',
      };
    case 'nutrition':
      return {
        title: 'Nutrition',
        metrics: data?.nutrition ?? [],
        color: '#FAAF1F',
        score: data?.scores?.nutrition ?? 0,
        icon: NutritionIcon,
        deviation: data?.scores?.nutrition_deviation_sign ?? '',
      };
    case 'sleep':
      return {
        title: 'Sleep',
        metrics: data?.sleep ?? [],
        color: '#C154B0',
        score: data?.scores?.sleep ?? 0,
        icon: SleepIcon,
        deviation: data?.scores?.sleep_deviation_sign ?? '',
      };
    default:
      return undefined;
  }
};
