import React, { FC } from 'react';
import { Checkbox, CheckboxProps, styled, SxProps } from '@mui/material';
import { Colors } from '../../theme/colors';
import FormControlLabel from '@mui/material/FormControlLabel';

interface Props {
  label: string;
  checked: boolean;
  disabled?: boolean;
  setValue: (value: any) => void;
  labelWidth?: string;
  customSx?: SxProps;
}

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  border: '1px solid #73D8E2',
  backgroundColor: Colors.white,
  '.Mui-focusVisible &': {
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    border: '1px solid #E9EDEF',
    background: Colors.gray[100],
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: Colors.blue[500],
  '&:before': {
    display: 'block',
    width: 14,
    height: 14,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: Colors.blue[500],
  },
  'input:disabled ~ &': {
    background: Colors.gray[100],
  },
});

// Inspired by blueprintjs
function BpCheckbox(props: CheckboxProps & { customSx?: SxProps }) {
  return (
    <Checkbox
      sx={{
        '&.MuiCheckbox-root': {
          padding: 0,
        },
        '&:hover': { bgcolor: 'transparent' },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon sx={{ ...props.customSx }} />}
      icon={<BpIcon />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      {...props}
    />
  );
}

const CustomCheckbox: FC<Props> = ({ label, checked, disabled, setValue, labelWidth, customSx }) => {
  return (
    <FormControlLabel
      value="start"
      control={<BpCheckbox checked={checked} onChange={setValue} customSx={customSx} />}
      label={label}
      labelPlacement="start"
      disabled={disabled}
      sx={{
        '&.MuiFormControlLabel-root': {
          margin: '0',
        },
        '.MuiFormControlLabel-label': {
          color: Colors.gray[700],
          fontSize: '14px',
          lineHeight: '14px',
          marginRight: '16px',
          width: labelWidth,
        },
      }}
    />
  );
};

export default CustomCheckbox;
