import UserService from './UserService';
import { HTTP } from '@awesome-cordova-plugins/http';
import axios from 'axios';
import { Capacitor } from '@capacitor/core';

type Options = {
  method: 'get' | 'post' | 'put' | 'patch' | 'head' | 'delete' | 'options' | 'upload' | 'download';
  data?: {
    [index: string]: any;
  };
  params?: {
    [index: string]: string | number;
  };
  serializer?: 'json' | 'urlencoded' | 'utf8' | 'multipart' | 'raw';
  timeout?: number;
  headers?: {
    [index: string]: string;
  };
  filePath?: string | string[];
  name?: string | string[];
  responseType?: 'text' | 'arraybuffer' | 'blob' | 'json';
};

const TERRA_API_URL = 'https://api.tryterra.co/v2';

const _axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeoutErrorMessage: 'Request took long to complete, times up!',
});

const _axiosTerra = axios.create({
  baseURL: TERRA_API_URL,
  timeoutErrorMessage: 'Terra Request took long to complete, times up!',
});

const configureKeycloak = () => {
  if (!Capacitor.isNativePlatform()) {
    _axios.interceptors.request.use((config) => {
      if (UserService.isLoggedIn()) {
        const cb = () => {
          if (config.headers === undefined) {
            config.headers = {};
          }
          config.headers.Authorization = `Bearer ${UserService.getToken()}`;
          return Promise.resolve(config);
        };
        return UserService.updateToken(cb);
      }
    });
  } else {
    if (UserService.isLoggedIn()) {
      UserService.updateToken(() => console.log('configured Keycloak'));
    }
  }
};

const setSSLPinning = () => {
  if (!Capacitor.isNativePlatform()) return;
  HTTP.setServerTrustMode('pinned')
    .then(() => console.log('SSL Pinning Success!'))
    .catch((reason) => console.log('SSL Pinning Failed: ' + reason));
};

export const postRequest = (endpoint: string, body?: any, params?: any): Promise<any> => {
  if (!Capacitor.isNativePlatform()) return _axios.post(endpoint, body, { params });

  const options: Options = {
    method: 'post',
    data: body ?? {},
    params: params,
    headers: {
      accept: 'application/json, text/plain, */*',
    },
    serializer: 'json',
    responseType: 'json',
  };
  if (UserService.isLoggedIn()) options.headers!.Authorization = `Bearer ${UserService.getToken()}`;

  return HTTP.sendRequest(`${process.env.REACT_APP_API_URL}${endpoint}`, options);
};

export const putRequest = (endpoint: string, body?: any, params?: any): Promise<any> => {
  if (!Capacitor.isNativePlatform()) return _axios.put(endpoint, body, { params });

  const options: Options = {
    method: 'put',
    data: body ?? {},
    params: params,
    headers: {
      accept: 'application/json, text/plain, */*',
    },
    serializer: 'json',
    responseType: 'json',
  };
  if (UserService.isLoggedIn()) options.headers!.Authorization = `Bearer ${UserService.getToken()}`;

  return HTTP.sendRequest(`${process.env.REACT_APP_API_URL}${endpoint}`, options);
};

export const getRequest = (endpoint: string, params?: any, responseType?: 'JSON' | 'String'): Promise<any> => {
  if (!Capacitor.isNativePlatform()) return _axios.get(endpoint, { params });

  const options: Options = {
    method: 'get',
    params: params,
    headers: {
      accept: 'application/json, text/plain, */*',
      'Access-Control-Expose-Headers': 'Content-Encoding',
    },
    serializer: responseType === 'JSON' || !responseType ? 'json' : 'utf8',
    responseType: responseType === 'JSON' || !responseType ? 'json' : 'text',
  };
  if (UserService.isLoggedIn()) options.headers!.Authorization = `Bearer ${UserService.getToken()}`;

  return HTTP.sendRequest(`${process.env.REACT_APP_API_URL}${endpoint}`, options);
};

export const deleteRequest = (endpoint: string, params?: any): Promise<any> => {
  if (!Capacitor.isNativePlatform()) return _axios.delete(endpoint, { params });

  const options: Options = {
    method: 'delete',
    params: params,
    headers: {
      accept: 'application/json, text/plain, */*',
    },
    serializer: 'json',
    responseType: 'json',
  };
  if (UserService.isLoggedIn()) options.headers!.Authorization = `Bearer ${UserService.getToken()}`;

  return HTTP.sendRequest(`${process.env.REACT_APP_API_URL}${endpoint}`, options);
};

export const postFormDataRequest = (endpoint: string, data?: FormData, params?: any): Promise<any> => {
  if (!Capacitor.isNativePlatform())
    return _axios({
      url: endpoint,
      method: 'POST',
      data: data,
      params: params,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

  const options: Options = {
    method: 'post',
    data: data,
    params: params,
    headers: {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json, text/plain, */*',
    },
    serializer: 'multipart',
    responseType: 'json',
  };
  if (UserService.isLoggedIn()) options.headers!.Authorization = `Bearer ${UserService.getToken()}`;

  return HTTP.sendRequest(`${process.env.REACT_APP_API_URL}${endpoint}`, options);
};

export const putFormDataRequest = (endpoint: string, data?: FormData, params?: any): Promise<any> => {
  if (!Capacitor.isNativePlatform())
    return _axios({
      url: endpoint,
      method: 'PUT',
      data: data,
      params: params,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

  const options: Options = {
    method: 'put',
    data: data,
    params: params,
    headers: {
      'Content-Type': 'multipart/form-data',
      accept: 'application/json, text/plain, */*',
    },
    serializer: 'multipart',
    responseType: 'json',
  };
  if (UserService.isLoggedIn()) options.headers!.Authorization = `Bearer ${UserService.getToken()}`;

  return HTTP.sendRequest(`${process.env.REACT_APP_API_URL}${endpoint}`, options);
};

export const postTerraRequest = (endpoint: string, body?: any, params?: any): Promise<any> => {
  const headers = {
    accept: 'application/json, text/plain, */*',
    'dev-id': process.env.REACT_APP_TERRA_DEV_ID!,
    'x-api-key': process.env.REACT_APP_TERRA_API_KEY!,
  };
  if (!Capacitor.isNativePlatform()) return _axiosTerra.post(endpoint, body, { headers, params });

  const options: Options = {
    method: 'post',
    data: body ?? {},
    params: params,
    headers,
    serializer: 'json',
    responseType: 'json',
  };

  return HTTP.sendRequest(`${TERRA_API_URL}${endpoint}`, options);
};

const HttpService = {
  setSSLPinning,
  configureKeycloak,
};

export default HttpService;
