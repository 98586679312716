import React, { useEffect } from 'react';
import { IonContent, IonPage, useIonRouter } from '@ionic/react';
import { Box, Container, Grid, Skeleton, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

import { CustomButton } from '../../components/features/CustomButton';
import { Colors } from '../../theme/colors';
import { useHandler } from './hooks/useHandler';
import CustomNewHeader from '../../components/features/CustomNewHeader';
import { ReactComponent as HistoryIcon } from '../../assets/svg/feature/history.svg';
import { ReactComponent as ExchangeIcon } from '../../assets/svg/feature/exchange.svg';
import { EmployeePointItem } from './components/EmployeePointItem';
import theme from '../../theme';

const EXTERNAL_PLATFORM_LINK = 'https://manage.rewardsgenius.com';

export const EmployeesPoints: React.FC = () => {
  const { users, config, handleFetchNextUsers } = useHandler();
  const router = useIonRouter();
  const tableDown = useMediaQuery(theme.breakpoints.down('table'));
  return (
    <IonPage>
      <CustomNewHeader
        label={`Your Employees' Rewards`}
        tooltip={`Check the leaderboard to view employees' points and pick rewards based on your company's strategy.`}
      />

      <IonContent>
        <Container maxWidth="lg" sx={{ height: '100%', pb: { table: '38px' } }}>
          <Grid item display="flex" justifyContent="center" height="100%" position="relative">
            {tableDown && (
              <Tooltip
                title={
                  !config?.rewardsEnabled && (
                    <Typography variant={'body2'}>
                      {`We are sorry to inform you that you are unable to access the redemption process. Don't worry
                though, our support team is here to help you out! Just reach out to them and they will assist
                you further.`}
                    </Typography>
                  )
                }
                enterTouchDelay={50}
              >
                <Box sx={{ position: 'absolute', zIndex: 100, bottom: '38px', left: '16px' }}>
                  <CustomButton
                    type="bigOrange"
                    text="Start redemption"
                    disabled={!config?.rewardsEnabled}
                    width="202px"
                    inputProps={{
                      variant: 'contained',
                      startIcon: <ExchangeIcon height={16} width={16} />,
                      sx: {
                        '&.Mui-disabled': {
                          background: '#F9DFAD',
                          color: Colors.white,
                          border: 'unset',
                        },
                      },
                    }}
                    onClick={() => router.push('/page/employees-rewards/redemption')}
                  />
                </Box>
              </Tooltip>
            )}
            <Box
              sx={{
                borderRadius: '20px',
                background: Colors.white,
                boxShadow: '5px 0px 15px 0px rgba(108, 123, 132, 0.05)',
                padding: { xs: '24px 16px', table: '32px' },
                width: '100%',
                height: '100%',
                maxWidth: '832px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: { xs: 'column', table: 'row' },
                  alignItems: { table: 'center' },
                  mb: '16px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: '16px', table: '18px' },
                    fontWeight: 900,
                    color: Colors.gray[700],
                  }}
                >
                  Leaderboard
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', flex: 1, justifyContent: { table: 'flex-end' } }}>
                  {config?.rewardsEnabled && (
                    <Box
                      component="button"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        background: 'unset',
                        mr: '16px',
                        mt: { xs: '16px', table: 'unset' },
                      }}
                      onClick={() => window.open(EXTERNAL_PLATFORM_LINK, '_blank')}
                    >
                      <HistoryIcon />
                      <Typography
                        sx={{
                          fontSize: { xs: '12px', table: '14px' },
                          textDecoration: 'underline',
                          color: Colors.gray[500],
                          ml: '8px',
                        }}
                      >
                        Redemption history
                      </Typography>
                    </Box>
                  )}
                  {!tableDown && (
                    <CustomButton
                      type="bigOrange"
                      text="Start redemption"
                      disabled={!config?.rewardsEnabled}
                      inputProps={{
                        variant: 'contained',
                        startIcon: <ExchangeIcon height={12} width={12} />,
                        sx: {
                          height: '28px',
                          fontSize: '12px',
                          '&.Mui-disabled': {
                            background: '#F9DFAD',
                            color: Colors.white,
                            border: 'unset',
                          },
                        },
                      }}
                      onClick={() => router.push('/page/employees-rewards/redemption')}
                    />
                  )}
                  {!tableDown && !config?.rewardsEnabled && (
                    <Tooltip
                      title={
                        <Typography variant={'body2'}>
                          {`We are sorry to inform you that you are unable to access the redemption process. Don't worry
                          though, our support team is here to help you out! Just reach out to them and they will assist
                          you further.`}
                        </Typography>
                      }
                    >
                      <InfoOutlined sx={{ color: Colors.gray[400], fontSize: '16px', ml: '8px' }} />
                    </Tooltip>
                  )}
                </Box>
              </Box>
              <Box
                component="div"
                sx={{
                  flex: 1,
                  overflow: 'scroll',
                  maxHeight: { xs: '95%', table: '90%' },
                  mb: { xs: '16px', table: '32px' },
                  pb: { xs: '64px', table: 'unset' },
                }}
                onScroll={handleFetchNextUsers}
              >
                {users
                  ? users.map((item, idx) => (
                      <EmployeePointItem
                        key={item.emp_uuid}
                        position={idx + 1}
                        name={item.name ?? item.username}
                        avatar={item.avatar}
                        email={item.email}
                        points={item.points}
                      />
                    ))
                  : new Array(9).fill(0).map((_, idx) => (
                      <Skeleton
                        variant="rectangular"
                        key={idx}
                        sx={{
                          height: { xs: '82px', table: '64px', borderRadius: '10px', marginBottom: '8px' },
                          width: '100%',
                        }}
                      />
                    ))}
              </Box>
            </Box>
          </Grid>
        </Container>
      </IonContent>
    </IonPage>
  );
};
