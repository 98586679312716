import React from 'react';
import { EditCalendarTimelineItem } from '../types';
import { Box, Typography } from '@mui/material';
import { Colors } from '../../../theme/colors';
import { ReactComponent as FlameIcon } from '../../../assets/svg/feature/flame.svg';
import moment from 'moment';
import { convertThousands } from '../../../utils/numberWithSpace';

type CalendarDayItemNumberProps = {
  day: Date;
  startRange?: string;
  endRange?: string;
  timelineScore: EditCalendarTimelineItem[];
  focusDate?: Date;
};

export const CalendarDayItemNumber: React.FC<CalendarDayItemNumberProps> = ({
  day,
  startRange,
  endRange,
  timelineScore,
  focusDate,
}) => {
  const monthDay = new Date(day).getDate();
  if (
    startRange &&
    endRange &&
    (new Date(day).setHours(0, 0, 0, 0) < new Date(startRange).setHours(0, 0, 0, 0) ||
      new Date(day).setHours(0, 0, 0, 0) > new Date(endRange).setHours(0, 0, 0, 0))
  ) {
    return (
      <Box className="dayWrapper emptyDayWrapper">
        <Typography sx={{ fontSize: '12px' }} className="dayLabel">
          {monthDay}
        </Typography>
        <span className="todayLine" />
      </Box>
    );
  }
  const isStartDate = startRange
    ? new Date(day).setHours(0, 0, 0, 0) === new Date(startRange)?.setHours(0, 0, 0, 0)
    : false;
  const isEndDate = endRange ? new Date(day).setHours(0, 0, 0, 0) === new Date(endRange)?.setHours(0, 0, 0, 0) : false;
  const dayScore = timelineScore.find(
    (item) => new Date(item.date).setHours(0, 0, 0, 0) === new Date(day).setHours(0, 0, 0, 0),
  );
  const isSelected = focusDate ? moment(focusDate).format('YYYY-MM-DD') === moment(day).format('YYYY-MM-DD') : false;

  if (dayScore && (dayScore?.value >= 0 || dayScore?.newValue)) {
    return (
      <Box
        className={`dayWrapper${isSelected ? ' selectedDayWrapper' : ' defaultDayWrapper'}`}
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderTopLeftRadius: isStartDate ? '5px' : 0,
          borderBottomLeftRadius: isStartDate ? '5px' : 0,
          borderTopRightRadius: isEndDate ? '5px' : 0,
          borderBottomRightRadius: isEndDate ? '5px' : 0,
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: '6px', md: '8px' },
            fontWeight: '300',
            color: isSelected ? Colors.white : Colors.gray[700],
            position: 'absolute',
            top: '3px',
            left: '6px',
          }}
        >
          {monthDay}
        </Typography>
        <Box
          sx={{
            height: 32,
            width: 32,
            borderRadius: 32,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borer: `1px solid ${Colors.blue[300]}`,
            background:
              dayScore?.status?.toLocaleLowerCase() === 'success'
                ? `radial-gradient(circle, ${Colors.blue[200]} 0%, ${Colors.blue[500]} 80%)`
                : `radial-gradient(circle, #E8F8FA 0%, ${Colors.blue[200]} 100%)`,
          }}
        >
          <Typography
            sx={{
              fontSize: '8px',
              fontWeight: 700,
              color: dayScore?.status?.toLocaleLowerCase() === 'success' ? Colors.white : Colors.gray[700],
            }}
            className="dayLabel"
          >
            {convertThousands(dayScore.newValue ?? dayScore.value)}
          </Typography>
        </Box>
        <span className="todayLine" />
      </Box>
    );
  }
  return (
    <Box
      className={`dayWrapper${isSelected ? ' selectedDayWrapper' : ' defaultDayWrapper'}`}
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderTopLeftRadius: isStartDate ? '5px' : 0,
        borderBottomLeftRadius: isStartDate ? '5px' : 0,
        borderTopRightRadius: isEndDate ? '5px' : 0,
        borderBottomRightRadius: isEndDate ? '5px' : 0,
      }}
    >
      <Typography sx={{ fontSize: '12px', color: isSelected ? Colors.white : Colors.gray[700] }} className="dayLabel">
        {monthDay}
      </Typography>
      <span className="todayLine" />
    </Box>
  );
};

type CalendarDayItemBooleanProps = {
  day: Date;
  startRange?: string;
  endRange?: string;
  timelineScore: EditCalendarTimelineItem[];
  focusDate?: Date;
  maxCount?: number;
  handleUpdateHabit?: (date: string, isSuccess: boolean) => () => void;
};

export const CalendarDayItemBoolean: React.FC<CalendarDayItemBooleanProps> = ({
  day,
  startRange,
  endRange,
  timelineScore,
  focusDate,
  maxCount,
  handleUpdateHabit,
}) => {
  const monthDay = new Date(day).getDate();
  if (
    startRange &&
    endRange &&
    (new Date(day).setHours(0, 0, 0, 0) < new Date(startRange).setHours(0, 0, 0, 0) ||
      new Date(day).setHours(0, 0, 0, 0) > new Date(endRange).setHours(0, 0, 0, 0))
  ) {
    return (
      <Box className="dayWrapper emptyDayWrapper">
        <Typography sx={{ fontSize: '12px' }} className="dayLabel">
          {monthDay}
        </Typography>
        <span className="todayLine" />
      </Box>
    );
  }
  const isStartDate = startRange
    ? new Date(day).setHours(0, 0, 0, 0) === new Date(startRange)?.setHours(0, 0, 0, 0)
    : false;
  const isEndDate = endRange ? new Date(day).setHours(0, 0, 0, 0) === new Date(endRange)?.setHours(0, 0, 0, 0) : false;
  const dayScore = timelineScore.find(
    (item) => new Date(item.date).setHours(0, 0, 0, 0) === new Date(day).setHours(0, 0, 0, 0),
  );
  const isSelected = focusDate ? focusDate.getTime() === new Date(day).getTime() : false;
  if (dayScore?.status === 'SUCCESS') {
    return (
      <Box
        className={`dayWrapper${isSelected ? ' selectedDayWrapper' : ' defaultDayWrapper'} booleanDay`}
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderTopLeftRadius: isStartDate ? '5px' : 0,
          borderBottomLeftRadius: isStartDate ? '5px' : 0,
          borderTopRightRadius: isEndDate ? '5px' : 0,
          borderBottomRightRadius: isEndDate ? '5px' : 0,
        }}
        onClick={
          handleUpdateHabit && handleUpdateHabit(moment(day).format('YYYY-MM-DD'), dayScore.status === 'SUCCESS')
        }
      >
        <Typography
          sx={{
            fontSize: { xs: '6px', md: '8px' },
            fontWeight: '300',
            color: isSelected ? Colors.white : Colors.gray[700],
            position: 'absolute',
            top: '3px',
            left: '6px',
          }}
        >
          {monthDay}
        </Typography>
        <Box
          sx={{
            height: 32,
            width: 32,
            borderRadius: 32,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: `1px solid ${Colors.orange[200]}`,
            background: `radial-gradient(circle, ${Colors.orange[200]} 0%, ${Colors.orange[500]} 100%)`,
          }}
        >
          {dayScore?.isComplete ? (
            <FlameIcon height={20} width={20} fill={Colors.white} />
          ) : (
            <Typography sx={{ fontWeight: 700, fontSize: '10px', color: Colors.white }}>{`${
              dayScore?.teamValue ?? dayScore.value
            }/${maxCount}`}</Typography>
          )}
        </Box>
        <span className="todayLine" />
      </Box>
    );
  }
  return (
    <Box
      className={`dayWrapper${isSelected ? ' selectedDayWrapper' : ' defaultDayWrapper'} booleanDay`}
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderTopLeftRadius: isStartDate ? '5px' : 0,
        borderBottomLeftRadius: isStartDate ? '5px' : 0,
        borderTopRightRadius: isEndDate ? '5px' : 0,
        borderBottomRightRadius: isEndDate ? '5px' : 0,
      }}
      onClick={handleUpdateHabit && handleUpdateHabit(moment(day).format('YYYY-MM-DD'), false)}
    >
      {typeof maxCount === 'number' && (
        <Typography
          sx={{
            fontSize: { xs: '6px', md: '8px' },
            fontWeight: '300',
            color: isSelected ? Colors.white : Colors.gray[700],
            position: 'absolute',
            top: '3px',
            left: '6px',
          }}
        >
          {monthDay}
        </Typography>
      )}
      <Box
        sx={{
          height: 32,
          width: 32,
          borderRadius: 32,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: Colors.white,
          border: `1px solid ${Colors.orange[200]}`,
        }}
      >
        {dayScore?.isComplete ? (
          <FlameIcon height={20} width={20} fill={Colors.orange[500]} />
        ) : (
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: maxCount ? '10px' : '12px',
              color: maxCount ? Colors.orange[500] : Colors.gray[700],
            }}
          >
            {maxCount ? `${dayScore?.teamValue ?? dayScore?.value ?? 0}/${maxCount}` : monthDay}
          </Typography>
        )}
      </Box>
      <span className="todayLine" />
    </Box>
  );
};
