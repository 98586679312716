import { ReactComponent as MentalIcon } from '../assets/svg/feature/dimentions/mental-fitness.svg';
import { ReactComponent as SleepIcon } from '../assets/svg/feature/dimentions/sleep.svg';
import { ReactComponent as NutritionIcon } from '../assets/svg/feature/dimentions/nutrition.svg';
import { ReactComponent as WinnerIcon } from '../assets/svg/feature/winner.svg';
import { ReactComponent as SportIcon } from '../assets/svg/feature/dimentions/sport.svg';
import { ReactComponent as StatIcon } from '../assets/svg/feature/stats.svg';

export const metricsPillar = [
  { label: 'Mental Well-being', value: 'MENTAL_HEALTH', icon: MentalIcon },
  { label: 'Sleep', value: 'SLEEP', icon: SleepIcon },
  { label: 'Nutrition', value: 'NUTRITION', icon: NutritionIcon },
  { label: 'Physical Activity', value: 'PHYSICAL_ACTIVITY', icon: SportIcon },
  { label: 'Physical Health', value: 'HEALTH', icon: WinnerIcon },
  { label: 'Other', value: 'OTHER', icon: StatIcon },
];
