import React, { FC } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const StyledButton = styled(Box)(({ theme }) => ({
  padding: '8px 16px',
  borderRadius: '16px',
  fontSize: 16,
  fontWeight: 'bold',
  minWidth: '160px',
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    minWidth: 'unset',
  },
}));

interface GenericModalInterface {
  open: boolean;
  onClose: () => void;
  title?: string;
  description?: string;
  confirmText?: string;
  confirmFunction?: () => void;
  cancelText?: string;
}

const GenericModal: FC<GenericModalInterface> = ({
  open,
  onClose,
  title = 'Confirmation',
  description = 'Are you sure?',
  confirmText = 'Confirm',
  confirmFunction,
  cancelText = 'Cancel',
}) => {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    onClose();
  };
  return (
    <Dialog
      maxWidth={'sm'}
      open={open}
      onClose={handleClose}
      sx={{ textAlign: 'center' }}
      PaperProps={{ style: { margin: '8px', borderRadius: '8px' } }}
    >
      <DialogTitle sx={{ minWidth: xsDown ? undefined : 420, maxWidth: 420 }}>
        <Typography sx={{ fontSize: 24, fontWeight: 'bold' }}>{title}</Typography>
      </DialogTitle>
      <DialogContent sx={{ minWidth: xsDown ? undefined : 420, maxWidth: 420 }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography sx={{ color: '#8B8B8B', fontWeight: 400 }}>{description}</Typography>
          </Grid>
          <Grid item xs={12} container justifyContent={confirmFunction ? 'space-evenly' : 'center'} sx={{ mt: 6 }}>
            <Grid item>
              <StyledButton onClick={handleClose} sx={{ background: '#E6D2AA', color: 'white' }}>
                {cancelText}
              </StyledButton>
            </Grid>
            {confirmFunction && (
              <Grid item>
                <StyledButton
                  sx={{ backgroundColor: theme.palette.error.main, color: 'white' }}
                  onClick={confirmFunction}
                >
                  {confirmText}
                </StyledButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default GenericModal;
