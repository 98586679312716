import { Box, Link, Skeleton, SxProps, Typography, useMediaQuery, Theme } from '@mui/material';
import React, { useRef } from 'react';
import { Swiper as SwiperType, Navigation } from 'swiper';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { useHandleBooleanCalendar } from '../hooks/useHandleBooleanCalendar';
import { RangeMap } from '../../../models/Shared';
import { Colors } from '../../../theme/colors';
import { ReactComponent as ArrowIcon } from '../../../assets/svg/feature/arrow.svg';
import { ReactComponent as FlameIcon } from '../../../assets/svg/feature/flame.svg';
import ContainerBg from '../../../assets/svg/feature/daily-boolean-progress-bg.svg';
import { BooleanLogDataModal } from './BooleanLogDataModal';
import { CalendarTimelineItem } from '../types';
import theme from '../../../theme';

const slideOptions: SwiperProps = {
  spaceBetween: 0,
  speed: 400,
  centeredSlidesBounds: true,
  slideToClickedSlide: false,
  threshold: 15,
  slidesOffsetAfter: 0,
  slidesOffsetBefore: 0,
  breakpoints: {
    320: {
      slidesPerView: 5.5,
      slidesPerGroup: 1,
    },
    600: {
      slidesPerView: 5,
      slidesPerGroup: 1,
    },
  },
  allowSlideNext: true,
  allowSlidePrev: true,
};

const slideActionOptions: SwiperProps = {
  spaceBetween: 4,
  speed: 400,
  slideToClickedSlide: false,
  threshold: 15,
  slidesOffsetAfter: 0,
  slidesOffsetBefore: 0,
  // breakpoints: {
  //   320: {
  //     slidesPerView: 2.5,
  //     slidesPerGroup: 1,
  //   },
  //   600: {
  //     slidesPerView: 3,
  //     slidesPerGroup: 1,
  //   },
  // },
  allowSlideNext: true,
  allowSlidePrev: true,
};

type BooleanCalendarProps = {
  sx?: SxProps<Theme>;
  isLoading?: boolean;
  startDate?: string;
  endDate?: string;
  rangeDate: RangeMap;
  visible: boolean;
  timelineScore: CalendarTimelineItem[];
  teamTimelineScore: CalendarTimelineItem[];
  isTeam?: boolean;
  showDate?: Date;
  maxCount?: number;
  actions?: string[];
  handleUpdateHabit: (date: string, isSuccess: boolean) => () => void;
  openModal: () => void;
  onClose: () => void;
};

const getInitialSlide = (idx: number) => {
  if (idx < 5) return 0;
  return idx - 4;
};

export const BooleanCalendar: React.FC<BooleanCalendarProps> = ({
  sx,
  isLoading,
  rangeDate,
  visible,
  timelineScore,
  teamTimelineScore,
  startDate,
  endDate,
  isTeam = false,
  showDate,
  maxCount,
  actions,
  onClose,
  openModal,
  handleUpdateHabit,
}) => {
  const swiperRef = useRef<SwiperType>();
  const actionSwiperRef = useRef<SwiperType>();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  if (isLoading) {
    return <Skeleton variant="rectangular" sx={{ width: '100%', height: 200, background: '#FEF7E9' }} />;
  }

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          paddingTop: '11px',
          paddingInline: { xs: 'unset', table: '14px' },
          border: `5px solid #FEF7E9`,
          borderRadius: '20px',
          overflow: 'hidden',
          background: `linear-gradient(180deg, #FEF7E9 0%, #FDE7BC 100%)`,
          ...sx,
        }}
      >
        <img
          src={ContainerBg}
          style={{ position: 'absolute', zIndex: 0, top: 0, left: 0, bottom: 0, right: 0, objectFit: 'cover' }}
        />
        <Box
          sx={{
            position: 'relative',
            zIndex: 1,
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            paddingInline: { xs: '14px', table: 'unset' },
          }}
        >
          <Typography sx={{ fontWeight: 700, fontSize: '14px', color: Colors.gray[700] }}>
            {isTeam ? `Team's daily progress` : 'Daily progress'}
          </Typography>
          <Link
            sx={{
              fontSize: '12px',
              cursor: 'pointer',
              color: Colors.orange[600],
              textDecorationColor: Colors.orange[600],
            }}
            onClick={openModal}
          >
            View Calendar
          </Link>
        </Box>
        <Box
          sx={{
            position: 'relative',
            zIndex: 1,
            display: 'flex',
            width: '100%',
            maxWidth: '360px',
            marginLeft: 'auto',
            marginRight: 'auto',
            borderRadius: { xs: 'unset', sm: '15px' },
            marginTop: '40px',
            marginBottom: actions ? '8px' : '60px',
            background: '#FEF7E9',
          }}
        >
          {!smDown && rangeDate.length > 5 && (
            <Box
              component="button"
              sx={{
                height: 64,
                width: 20,
                background: 'unset',
                padding: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
              onClick={() => swiperRef.current?.slidePrev()}
            >
              <ArrowIcon height="12px" width="12px" fill="#9FA09C" />
            </Box>
          )}
          <Swiper
            {...slideOptions}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            key={'boolean-calendar'}
            modules={[Navigation]}
            initialSlide={
              new Date(endDate ?? new Date()) < new Date()
                ? rangeDate.length
                : getInitialSlide(rangeDate.findIndex((item) => item.isToday))
            }
          >
            {rangeDate.map((item) => (
              <SwiperSlide key={item.date}>
                <Box
                  sx={{
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: { xs: 50, sm: 64 },
                    width: { xs: 50, sm: 64 },
                    cursor: 'pointer',
                  }}
                  onClick={handleUpdateHabit(item.date, item.isSuccess)}
                >
                  {item.isSuccess ? (
                    <>
                      <Typography
                        sx={{
                          position: 'absolute',
                          fontSize: { xs: '6px', table: '8px' },
                          fontWeight: '300',
                          top: '4px',
                          left: '6px',
                        }}
                      >
                        {item.day}
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: { xs: 32, sm: 44 },
                          width: { xs: 32, sm: 44 },
                          borderRadius: '100%',
                          border: `1px solid ${Colors.orange[200]}`,
                          background: `radial-gradient(circle, ${Colors.orange[200]} 0%, ${Colors.orange[500]} 100%)`,
                        }}
                      >
                        {item.isComplete || !item?.label ? (
                          <FlameIcon height={smDown ? 20 : 24} width={smDown ? 20 : 24} fill={Colors.white} />
                        ) : (
                          <Typography sx={{ fontSize: { xs: '14px' }, fontWeight: '700', color: Colors.white }}>
                            {item.label}
                          </Typography>
                        )}
                      </Box>
                    </>
                  ) : (
                    <>
                      {item.label && (
                        <Typography
                          sx={{
                            position: 'absolute',
                            fontSize: { xs: '6px', table: '8px' },
                            fontWeight: '300',
                            top: '4px',
                            left: '6px',
                          }}
                        >
                          {item.day}
                        </Typography>
                      )}
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: { xs: 32, sm: 44 },
                          width: { xs: 32, sm: 44 },
                          borderRadius: '100%',
                          background: Colors.white,
                          border: `1px solid ${Colors.orange[200]}`,
                        }}
                      >
                        {item.isComplete || !item?.label ? (
                          <Typography sx={{ fontSize: { xs: '12px' }, fontWeight: '300' }}>{item.day}</Typography>
                        ) : (
                          <Typography sx={{ fontSize: { xs: '14px' }, fontWeight: '700', color: Colors.orange[500] }}>
                            {item.label}
                          </Typography>
                        )}
                      </Box>
                    </>
                  )}

                  {item.isToday && (
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: 29,
                        height: 3,
                        background: Colors.magenta[300],
                        borderTopLeftRadius: '100px',
                        borderTopRightRadius: '100px',
                      }}
                    />
                  )}
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
          {!smDown && rangeDate.length > 5 && (
            <Box
              component="button"
              sx={{
                height: 64,
                width: 20,
                padding: 0,
                background: 'unset',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
              onClick={() => swiperRef.current?.slideNext()}
            >
              <ArrowIcon height="12px" width="12px" fill="#9FA09C" style={{ transform: 'rotate(180deg)' }} />
            </Box>
          )}
        </Box>
        {actions && actions.length > 0 && (
          <Box
            sx={{
              position: 'relative',
              zIndex: 1,
              display: 'flex',
              marginBottom: '12px',
              width: '100%',
              maxWidth: '360px',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            {!smDown && actions?.length > 3 && (
              <Box
                component="button"
                sx={{ height: '84px', borderRadius: '15px', background: '#FEF7E9', marginRight: '4px' }}
                onClick={() => actionSwiperRef.current?.slidePrev()}
              >
                <ArrowIcon height="12px" width="12px" fill="#9FA09C" />
              </Box>
            )}
            <Swiper
              {...slideActionOptions}
              breakpoints={{
                320: {
                  slidesPerView: actions.length < 3 ? actions.length : 2.5,
                  slidesPerGroup: 1,
                },
                600: {
                  slidesPerView: actions.length < 3 ? actions.length : 3,
                  slidesPerGroup: 1,
                },
              }}
              onBeforeInit={(swiper) => {
                actionSwiperRef.current = swiper;
              }}
              observer
              // initialSlide={0}
              key={`boolean-action-${actions?.length}`}
              modules={[Navigation]}
            >
              {actions.map((item) => {
                return (
                  <SwiperSlide key={item}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        ...(actions.length < 3
                          ? { paddingTop: '16px', paddingBottom: '16px', height: '100%' }
                          : { height: '84px' }),
                        borderRadius: '15px',
                        paddingInline: '8px',
                        background: '#FEF7E9',
                      }}
                    >
                      <Typography sx={{ fontSize: '10px', color: Colors.gray[700], textAlign: 'center' }}>
                        {item}
                      </Typography>
                    </Box>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            {!smDown && actions?.length > 3 && (
              <Box
                component="button"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '84px',
                  borderRadius: '15px',
                  background: '#FEF7E9',
                  marginLeft: '4px',
                }}
                onClick={() => actionSwiperRef.current?.slideNext()}
              >
                <ArrowIcon height="12px" width="12px" fill="#9FA09C" style={{ transform: 'rotate(180deg)' }} />
              </Box>
            )}
          </Box>
        )}
      </Box>
      <BooleanLogDataModal
        visible={visible}
        startDate={startDate}
        endDate={endDate}
        timelineScore={timelineScore}
        teamTimelineScore={teamTimelineScore}
        onClose={onClose}
        handleUpdateHabit={handleUpdateHabit}
        isTeam={isTeam}
        showDate={showDate}
        maxCount={maxCount}
      />
    </>
  );
};

export const BooleanCalendarLayout: React.FC<{ sx?: SxProps<Theme> }> = ({ sx }) => {
  const props = useHandleBooleanCalendar();
  return <BooleanCalendar sx={sx} {...props} />;
};
