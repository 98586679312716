import React from 'react';
import { Points, rankingType } from '../../../models/Points';
import { Box, CardMedia, Grid, Typography, useMediaQuery } from '@mui/material';
import { Colors } from '../../../theme/colors';
import ExperiencePointBg from '../../../assets/png/experience-point-bg.png';
import AchievementPointBg from '../../../assets/png/achievement-point-bg.png';
import { ReactComponent as GoldIcon } from '../../../assets/svg/feature/emblems/gold.svg';
import { ReactComponent as SilverIcon } from '../../../assets/svg/feature/emblems/silver.svg';
import { ReactComponent as BronzeIcon } from '../../../assets/svg/feature/emblems/bronze.svg';
import { ReactComponent as BlueIcon } from '../../../assets/svg/feature/emblems/blue.svg';
import { ReactComponent as WreathIcon } from '../../../assets/svg/feature/wreath-white.svg';
import theme from '../../../theme';
import { pluralWordRule } from '../../../utils/pluralWordRule';
import { numberSuffix } from '../../../utils/numberSuffix';
import { BADGE } from '../../../models/enum/BADGE';

const ExperienceStyle = {
  background: 'linear-gradient(180deg, #FDF9FF 0%, #DAB9F1 100%)',
  bgImage: ExperiencePointBg,
  mainColor: Colors.magenta[300],
  mainBorderColor: '#EBDAF7',
  secondBorderColor: Colors.magenta[300],
};
const AchievementStyle = {
  background: 'linear-gradient(180.5deg, #FBEDEE 2.35%, #F0B8BA 116.48%)',
  bgImage: AchievementPointBg,
  mainColor: Colors.red[400],
  mainBorderColor: '#F8E1E2',
  secondBorderColor: Colors.red[300],
};

export const getEmblem = (i: BADGE) => {
  if (i === BADGE.NONE) return BlueIcon;
  else if (i === BADGE.BRONZE) return BronzeIcon;
  else if (i === BADGE.SILVER) return SilverIcon;
  else if (i === BADGE.GOLD) return GoldIcon;
  else return BlueIcon;
};
export const PointCard: React.FC<{ point: Points }> = ({ point }) => {
  const smDown = useMediaQuery(theme.breakpoints.down('md'));
  const BudgeIcon = getEmblem(point.badge);

  const CardStyle = point.rankingType === rankingType.ACHIEVEMENT ? AchievementStyle : ExperienceStyle;
  return (
    <Box sx={{ position: 'relative', height: '100%', flex: 1, zIndex: 3 }}>
      <Grid
        sx={{
          position: 'relative',
          borderWidth: '5px',
          borderStyle: 'solid',
          borderColor: CardStyle.mainBorderColor,
          background: CardStyle.background,
          borderRadius: '20px',
          zIndex: 3,
          flexShrink: 0,
          height: { xs: 303, md: 312 },
          overflow: 'hidden',
        }}
      >
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 600,
            lineHeight: 1,
            color: Colors.gray[700],
            padding: '19px 19px 20px 19px',
          }}
        >
          {point.rankingType === rankingType.ACHIEVEMENT ? 'Achievements points' : 'Experience points'}
        </Typography>

        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            background: 'rgba(255, 255, 255, 0.5)',
            height: '48px',
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <Typography sx={{ ml: '8px', fontSize: '16px', fontWeight: 400, color: CardStyle.mainColor }}>
              {point.moduleTitle}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <BudgeIcon width={smDown ? 83 : 100} height={smDown ? 83 : 100} />
        </Box>
        <Box sx={{ position: 'absolute', top: { xs: '84px', md: '88px' }, left: '50%', transform: 'translateX(-50%)' }}>
          <WreathIcon width={smDown ? 167 : 185} height={smDown ? 119 : 131} />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: { xs: '173px', md: '189px' },
            left: 0,
            mx: '15px',
            borderWidth: '1px',
            borderStyle: 'dashed',
            borderColor: point.rankingType === rankingType.ACHIEVEMENT ? Colors.red[50] : Colors.magenta[50],
            width: 'calc(100% - 30px)',
          }}
        ></Box>

        <Box
          sx={{
            position: 'relative',
            margin: '16px auto 8px',
            background: CardStyle.mainColor,
            borderColor: point.rankingType === rankingType.ACHIEVEMENT ? Colors.red[300] : Colors.magenta[200],
            borderWidth: 2,
            borderStyle: 'solid',
            borderRadius: '16px',
            width: '100px',
            height: { xs: '39px', md: '41px' },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{ fontSize: { xs: '22px', md: '24px' }, lineHeight: 1, fontWeight: 900, color: Colors.white }}
          >
            {`${point.position}${numberSuffix(Number(point.position))}`}
          </Typography>
        </Box>

        <Typography sx={{ textAlign: 'center', fontSize: '18px', fontWeight: 900, color: Colors.white }}>
          {`${point.points} point${pluralWordRule(point.points)}`}
        </Typography>

        <CardMedia
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: `url(${CardStyle.bgImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            zIndex: -1,
          }}
        />
      </Grid>
    </Box>
  );
};
