import React, { useEffect, useRef, useState } from 'react';
import { IonContent, IonPage, useIonRouter } from '@ionic/react';
import CustomNewHeader from '../../components/features/CustomNewHeader';
import { Box, CardMedia, Collapse, Container, Link, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { Colors } from '../../theme/colors';
import { styles } from './styles';
import IconPlaceholder from '../../assets/defaultBlog.png';
import { ReactComponent as GoalIcon } from '../../assets/svg/goal.svg';
import { ReactComponent as ClockIcon } from '../../assets/svg/clock.svg';
import { ReactComponent as ArrowIcon } from '../../assets/svg/feature/arrow.svg';
import { ReactComponent as PlusIcon } from '../../assets/svg/plus.svg';
import { ReactComponent as EditIcon } from '../../assets/svg/feature/edit.svg';
import { ReactComponent as WearableMetric } from '../../assets/svg/wearable-metric.svg';
import { ReactComponent as ChallengeType } from '../../assets/svg/feature/сhellange-type.svg';
import { ReactComponent as Sum } from '../../assets/svg/feature/sum.svg';
import { ReactComponent as Winner } from '../../assets/svg/feature/winner.svg';
import { ReactComponent as LogIcon } from '../../assets/svg/feature/log.svg';
import { ReactComponent as WearableIcon } from '../../assets/svg/wearable.svg';
import { CardLabel } from '../../components/features/CardLabel';
import { useHandle } from './hooks/useHandle';
import { RouteComponentProps } from 'react-router';
import Grid from '@mui/material/Grid';
import { CustomAvatar } from '../../components/features/CustomAvatar';
import { CustomBudge } from '../../components/features/CustomBudge';
import { DIMENSION_LIST } from '../../models/enum/DIMENTION_LIST';
import { COMPETITION_LIST } from '../../models/enum/COMPETITION_LIST';
import { AWARD_LIST } from '../../models/enum/AWARD_LIST';
import { CustomButton } from '../../components/features/CustomButton';
import theme from '../../theme';
import { CollapseBox } from '../../components/features/CollapseBox';
import { Challenge } from '../../models/Challenge';
import { PublishForm } from '../../components/features/forms/PublishForm/PublishForm';
import { usePublishHandle } from '../TemplatesAdminPage/usePublishHandle';
import { COMPETITION } from '../../models/enum/COMPETITION';
import { AWARD_TRIGGER } from '../../models/enum/AWARD_TRIGGER';
import { CHALLENGE_TYPE } from '../../models/enum/CHALLENGE_TYPE';
import { AWARD_TYPE } from '../../models/enum/AWARD_TYPE';
import { capitalize } from '../../utils/stringCapitalize';
import { GROUP_CATEGORY } from '../../models/enum/GROUP_CATEGORY';
import { CALCULATION_TYPE } from '../../models/enum/CALCULATION_TYPE';
import { CHALLENGE_TYPE_LABEL } from '../../models/enum/CHALLENGE_TYPE_LABEL';
import { TRACKING_TYPE } from '../../models/enum/TRACKING_TYPE';

type Props = RouteComponentProps<{
  id: string;
}>;
type RouteParams = {
  id: string;
};

export const TemplateAdminDetailPage: React.FC<Props> = ({ match }) => {
  const router = useIonRouter();
  const fullDescriptionRef = useRef<null | HTMLDivElement>(null);

  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const { challenge, challengeTitle, emptyTrophy, expanded, durationInDays, challengeLoading, handleExpandClick } =
    useHandle(match.params as RouteParams);
  const [isTeamChallenge, setIsTeamChallenge] = useState(false);
  const [showNumberOfWinners, setShowNumberOfWinners] = useState(false);
  const {
    range,
    publishForm,
    isLoading,
    publish,
    getDisabledPeriod,
    handleChangeRange,
    handleChangeTeamSize,
    handleChangeWinners,
    handleChangeSlackUrl,
  } = usePublishHandle(challenge, () => router.push('/page/employees-challenges'));

  useEffect(() => {
    setIsTeamChallenge(challenge?.challengeCharacteristic.competition === COMPETITION.TEAM);
    setShowNumberOfWinners(
      challenge?.challengeAward?.awardTrigger === AWARD_TRIGGER.TOP ||
        challenge?.challengeAward?.awardTrigger === AWARD_TRIGGER.FASTEST,
    );
  }, [challenge]);
  const executeScrollToDescription = () => {
    setTimeout(() => {
      if (fullDescriptionRef && fullDescriptionRef.current) {
        fullDescriptionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100);
  };
  const Actions: React.FC = () => {
    return (
      <CustomButton
        disabled={
          getDisabledPeriod() ||
          (showNumberOfWinners ? !Boolean(publishForm.winners) : false) ||
          (isTeamChallenge ? !Boolean(publishForm.teamSize) : false)
        }
        type={'bigOrange'}
        text={'Publish'}
        inputProps={{
          variant: 'contained',
          startIcon: <PlusIcon width={16} height={16} />,
        }}
        sx={{ maxWidth: '100%' }}
        onClick={publish}
      />
    );
  };

  const EditButton: React.FC = () => (
    <CustomButton
      sx={{ width: mdDown ? '100%' : '139px', maxWidth: '328px' }}
      type={'bigLightOrange'}
      text={'Edit'}
      inputProps={{
        variant: 'contained',
        startIcon: <EditIcon width={16} height={16} style={{ fill: Colors.orange[500] }} />,
      }}
      onClick={() =>
        router.push(
          `/page/employees-challenges/edit-challenge/${
            challenge && challenge.metadata.templateChallengeUUID ? challenge.metadata.templateChallengeUUID : null
          }?isTemplate=true`,
        )
      }
    />
  );

  const Information: React.FC<{ challenge: Challenge }> = ({ challenge }) => {
    return (
      <>
        <Grid sx={{ display: 'flex', borderBottom: mdDown ? '1px dashed #F2F2F2' : 'none', py: mdDown ? '16px' : 0 }}>
          <Grid sx={{ mr: '16px' }}>
            <Typography sx={{ fontSize: '12px', color: Colors.gray[400], fontWeight: 700, mb: '4px' }}>
              Tracking:
            </Typography>
            <Box sx={{ borderRight: mdDown ? 'none' : '1px dashed #F2F2F2', pr: mdDown ? 0 : '16px' }}>
              <Box
                sx={[
                  {
                    padding: '2px',
                    backgroundColor: Colors.gray[100],
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    borderRadius: '24px',
                  },
                ]}
              >
                <Box
                  alignItems={'center'}
                  justifyContent={'center'}
                  display={'flex'}
                  sx={{
                    width: challenge.challengeAction.actionSource === TRACKING_TYPE.HYBRID ? '100%' : 24,
                    height: 24,
                    px: challenge.challengeAction.actionSource === TRACKING_TYPE.HYBRID ? '6px' : 0,
                    backgroundColor: emptyTrophy.mainColor,
                    borderWidth: 1,
                    borderColor: emptyTrophy.borderColor,
                    borderRadius: 100,
                    borderStyle: 'solid',
                  }}
                >
                  {(challenge.challengeAction.actionSource === TRACKING_TYPE.HYBRID ||
                    challenge.challengeAction.actionSource === TRACKING_TYPE.MANUAL) && (
                    <LogIcon width={12} height={12} fill={Colors.white} />
                  )}

                  {challenge.challengeAction.actionSource === TRACKING_TYPE.HYBRID && (
                    <Typography sx={{ fontSize: 12, color: Colors.white, lineHeight: 12 }}>+</Typography>
                  )}
                  {(challenge.challengeAction.actionSource === TRACKING_TYPE.HYBRID ||
                    challenge.challengeAction.actionSource === TRACKING_TYPE.AUTO) && (
                    <WearableIcon width={12} height={12} />
                  )}
                </Box>
                <Typography
                  sx={[
                    {
                      ml: '8px',
                      mr: '12px',
                      color: Colors.gray[700],
                      fontSize: '12px',
                      fontWeight: 700,
                    },
                  ]}
                >
                  {capitalize(challenge.challengeAction.actionSource.toLowerCase())}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid>
            <Typography sx={{ fontSize: '12px', color: Colors.gray[400], fontWeight: 700, mb: '4px' }}>
              Duration:
            </Typography>
            <Box sx={{ borderRight: mdDown ? 'none' : '1px dashed #F2F2F2', pr: mdDown ? 0 : '16px' }}>
              <CustomBudge key={'Duration'} Icon={ClockIcon} label={durationInDays} />
            </Box>
          </Grid>
        </Grid>

        <Grid
          sx={{
            display: 'flex',
            ml: mdDown ? 0 : '16px',
            borderBottom: mdDown ? '1px dashed #F2F2F2' : 'none',
            py: mdDown ? '16px' : 0,
          }}
        >
          <Grid sx={{ mr: '16px' }}>
            <Typography sx={{ fontSize: '12px', color: Colors.gray[400], fontWeight: 700, mb: '4px' }}>
              Dimension:
            </Typography>
            <Grid sx={{ display: 'flex', flexWrap: 'no-wrap' }}>
              {challenge.challengeCharacteristic.challengePillars.map((challengePillar) => {
                return DIMENSION_LIST.map((item, index) =>
                  item.value === challengePillar ? (
                    <Grid
                      key={item.value}
                      sx={{
                        mr:
                          challenge.challengeCharacteristic.challengePillars.length > 1 &&
                          index !== challenge.challengeCharacteristic.challengePillars.length - 1
                            ? '4px'
                            : 0,
                      }}
                      item
                    >
                      <CustomAvatar size={28} bgColor={item.mainColor} borderBg={item.borderColor} borderWidth={1}>
                        <item.Icon width={12} height={12} />
                      </CustomAvatar>
                    </Grid>
                  ) : null,
                );
              })}
            </Grid>
          </Grid>

          <Grid>
            <Typography sx={{ fontSize: '12px', color: Colors.gray[400], fontWeight: 700, mb: '4px' }}>
              Competition:
            </Typography>
            <Box sx={{ borderRight: mdDown ? 'none' : '1px dashed #F2F2F2', pr: mdDown ? 0 : '16px' }}>
              {COMPETITION_LIST.map((item) =>
                item.value === challenge.challengeCharacteristic.competition ? (
                  <CustomBudge
                    key={'Competition'}
                    Icon={item.Icon}
                    boxColor={item.mainColor}
                    borderColor={item.borderColor}
                    bgColor={'#D3DFE0'}
                    textColor={item.mainColor}
                    label={item.label}
                  />
                ) : null,
              )}
            </Box>
          </Grid>
        </Grid>

        <Grid
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            padding: mdDown ? '16px 0 8px 0' : 0,
            ml: mdDown ? 0 : '16px',
          }}
        >
          <Grid
            sx={{
              mr:
                challenge.challengeAward?.awards.length &&
                challenge.challengeAward?.awards[0].awardType === AWARD_TYPE.OTHER
                  ? '16px'
                  : 0,
            }}
          >
            <Typography sx={{ fontSize: '12px', color: Colors.gray[400], fontWeight: 700, mb: '4px' }}>
              Award:
            </Typography>
            {!challenge.challengeAward || !challenge.challengeAward?.awards.length ? (
              <CustomBudge
                key={'Award'}
                Icon={emptyTrophy.Icon}
                boxColor={emptyTrophy.mainColor}
                borderColor={emptyTrophy.borderColor}
                textColor={Colors.gray[700]}
                label={emptyTrophy.label}
              />
            ) : (
              AWARD_LIST.map((item) =>
                item.value === challenge.challengeAward?.awards[0].awardType ? (
                  <Grid key={item.value} item>
                    <CustomBudge
                      Icon={item.Icon}
                      boxColor={item.mainColor}
                      borderColor={item.borderColor}
                      bgColor={item.backgroundColor}
                      textColor={item.value === AWARD_TYPE.NONE ? Colors.gray[700] : Colors.yellow[600]}
                      label={
                        challenge.challengeAward?.awards[0].points
                          ? challenge.challengeAward.awards[0].points
                          : item.label
                      }
                    />
                  </Grid>
                ) : null,
              )
            )}
          </Grid>
          {challenge.challengeAward?.awards.length &&
            challenge.challengeAward?.awards[0].awardType === AWARD_TYPE.OTHER && (
              <Typography sx={{ fontSize: '14px', color: Colors.gray[500] }}>
                {challenge.challengeAward.awards[0].awardTitle}
              </Typography>
            )}
        </Grid>
      </>
    );
  };

  const SecondInformation: React.FC<{ challenge: Challenge }> = ({ challenge }) => {
    return (
      <Grid
        sx={{
          display: 'flex',
          borderTop: mdDown ? '1px dashed #F2F2F2' : 'none',
          py: mdDown ? '16px' : 0,
          mt: mdDown ? '16px' : '24px',
        }}
      >
        <Grid sx={{ mr: '16px' }}>
          <Typography sx={{ fontSize: '12px', color: Colors.gray[400], fontWeight: 700, mb: '4px' }}>
            Challenge type:
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <CustomBudge
              key={'Challenge type'}
              Icon={ChallengeType}
              label={CHALLENGE_TYPE_LABEL[challenge.challengeCharacteristic.challengeType as CHALLENGE_TYPE]}
              sx={{ marginRight: '8px' }}
            />
            <CustomBudge
              key={'Winner'}
              Icon={Winner}
              label={
                challenge.challengeAward?.awardTrigger === AWARD_TRIGGER.COMPLETION
                  ? 'All'
                  : capitalize(challenge.challengeAward?.awardTrigger.toLowerCase())
              }
              sx={{ marginRight: '8px' }}
            />
            {!(
              challenge.challengeCharacteristic.competition === COMPETITION.INDIVIDUAL &&
              challenge.challengeCharacteristic.calculationType === CALCULATION_TYPE.NONE
            ) && (
              <CustomBudge
                key={'Sum'}
                Icon={Sum}
                label={
                  challenge.challengeCharacteristic.competition === COMPETITION.TEAM &&
                  challenge.challengeCharacteristic.calculationType === CALCULATION_TYPE.NONE
                    ? 'Self Performance'
                    : capitalize(challenge.challengeCharacteristic.calculationType.toLowerCase())
                }
              />
            )}
          </Box>
        </Grid>
      </Grid>
    );
  };

  const SkeletonContent: React.FC = () => {
    return mdDown ? (
      <Grid sx={{ position: 'relative', width: '100%', marginRight: '24px' }}>
        <Skeleton variant="rectangular" height={'160px'} width={'100%'} sx={{ borderRadius: '20px' }} />

        <Box sx={{ width: '100%', mt: '26px' }}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box>
        <Skeleton variant="rectangular" height={'54px'} sx={{ borderRadius: '20px', margin: '32px auto 0 auto' }} />
        <Skeleton variant="rectangular" height={'54px'} sx={{ borderRadius: '20px', margin: '18px auto 0 auto' }} />
      </Grid>
    ) : (
      <Grid container display={'flex'} wrap={'nowrap'}>
        <Grid sx={{ position: 'relative', width: '100%', marginRight: '24px' }}>
          <Skeleton variant="rectangular" height={'324px'} width={'100%'} sx={{ borderRadius: '20px' }} />

          <Skeleton
            variant="rectangular"
            height={'92px'}
            width={'90%'}
            sx={{ borderRadius: '20px', margin: '-46px auto 0 auto' }}
          />

          <Box sx={{ width: '100%', mt: '26px' }}>
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
          </Box>
        </Grid>
        <Grid
          sx={{
            width: '276px',
          }}
        >
          <Skeleton variant="rectangular" height={'212px'} width={'276px'} sx={{ borderRadius: '20px' }} />
          <Skeleton variant="rectangular" height={'46px'} width={'100%'} sx={{ mt: '40px', borderRadius: '100px' }} />
        </Grid>
      </Grid>
    );
  };

  return (
    <IonPage>
      <CustomNewHeader
        breadcrumb={{
          Icon: GoalIcon,
          list: [
            { label: "Your Employees' Challenges", url: '/page/employees-challenges' },
            { label: 'Challenge templates', url: '/page/employees-challenges/challenge-templates' },
            { label: `Detailed:${challengeTitle}`, url: '/' },
          ],
        }}
        inProgress={challengeLoading}
        label={challengeTitle}
      >
        {!mdDown && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <EditButton />
          </Box>
        )}
      </CustomNewHeader>

      <IonContent style={{ '--padding-bottom': '36px' }} scrollEvents={true}>
        <Container maxWidth={'lg'}>
          {challengeLoading ? (
            <SkeletonContent />
          ) : challenge ? (
            <Grid container display={'flex'} wrap={'nowrap'}>
              <Grid sx={{ position: 'relative', width: '100%', marginRight: mdDown ? 0 : '24px' }}>
                <CardMedia
                  sx={mdDown ? styles.mdCardImage : styles.cardImage}
                  image={challenge.challengeInfo.imageUrl ?? IconPlaceholder}
                />
                <CardLabel challenge={challenge} type={'lg'} sxContainer={styles.labelStatusPosition} />

                {!mdDown && (
                  <Grid
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '21px 16px',
                      background: Colors.white,
                      borderLeft: '5px solid #B573E2',
                      boxShadow: '5px 0px 15px rgba(108, 123, 132, 0.05)',
                      borderRadius: '20px',
                      margin: '-46px 32px 0 32px',
                    }}
                  >
                    <Grid
                      sx={{
                        display: 'flex',
                        alignItems: 'flex-end',
                      }}
                    >
                      <Information challenge={challenge} />
                    </Grid>
                    <SecondInformation challenge={challenge} />
                  </Grid>
                )}

                <Grid
                  sx={{
                    padding: mdDown ? '16px' : '24px',
                    mt: mdDown ? '32px' : '26px',
                    mb: '32px',
                    backgroundColor: '#F7F9F9',
                    borderRadius: '20px',
                  }}
                >
                  <Typography sx={{ fontSize: mdDown ? '14px' : '16px', color: Colors.gray[500] }}>
                    {challenge.challengeInfo.shortDescription}
                  </Typography>

                  {!expanded ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        maxWidth: '150px',
                        cursor: 'pointer',
                        mt: mdDown ? '4px' : 0,
                      }}
                      onClick={() => {
                        handleExpandClick();
                        executeScrollToDescription();
                      }}
                    >
                      <Link sx={{ fontSize: mdDown ? '14px' : '16px', color: Colors.blue[500], mr: '4px' }}>
                        Read more...
                      </Link>
                      <ArrowIcon
                        height={16}
                        width={16}
                        fill={Colors.blue[500]}
                        style={{ transform: 'rotate(-90deg)' }}
                      />
                    </Box>
                  ) : (
                    <Box sx={{ height: 17, mt: mdDown ? '4px' : 0 }} />
                  )}
                </Grid>

                {mdDown && (
                  <Box sx={{ mb: '45px', textAlign: 'center' }}>
                    <EditButton />
                  </Box>
                )}

                {mdDown && (
                  <Box>
                    <CollapseBox title={'Information'} borderColor={Colors.magenta[300]}>
                      <Information challenge={challenge} />
                      <SecondInformation challenge={challenge} />
                    </CollapseBox>
                    <CollapseBox title={'Setup information'} borderColor={Colors.green[500]} sx={{ mt: '18px' }}>
                      <Box sx={{ mt: '16px', mb: '8px' }}>
                        <PublishForm
                          loading={isLoading}
                          publishForm={publishForm}
                          range={range}
                          suggestedDuration={challenge ? challenge.challengeDuration.suggestedDuration : ''}
                          isTeam={isTeamChallenge}
                          isCorporate={challenge.challengeCharacteristic.groupCategory === GROUP_CATEGORY.CORPORATE}
                          showNumberOfWinners={showNumberOfWinners}
                          handleChangeRange={handleChangeRange}
                          handleChangeTeamSize={handleChangeTeamSize}
                          handleChangeWinners={handleChangeWinners}
                          handleChangeSlackUrl={handleChangeSlackUrl}
                        />
                        <Box sx={{ maxWidth: '296px', margin: '40px auto 0' }}>
                          <Actions />
                        </Box>
                      </Box>
                    </CollapseBox>
                  </Box>
                )}

                <Box ref={fullDescriptionRef}>
                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                    {challenge && (
                      <Typography
                        sx={{
                          mt: mdDown ? '40px' : 0,
                          fontSize: mdDown ? '14px' : '16px',
                          color: Colors.gray[500],
                          '& > * iframe': {
                            border: 'none',
                            ...(mdDown && { width: '100%', height: 'calc(80vw * 0.75)', maxHeight: '315px' }),
                          },
                        }}
                        dangerouslySetInnerHTML={{ __html: challenge.challengeInfo.description }}
                      />
                    )}

                    {expanded && (
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', mt: '16px' }}
                        onClick={() => handleExpandClick()}
                      >
                        <Link sx={{ fontSize: mdDown ? '14px' : '16px', color: Colors.blue[500], mr: '4px' }}>
                          View less
                        </Link>
                        <ArrowIcon
                          height={16}
                          width={16}
                          fill={Colors.blue[500]}
                          style={{ transform: 'rotate(90deg)' }}
                        />
                      </Box>
                    )}
                  </Collapse>
                </Box>
              </Grid>
              {!mdDown && (
                <Grid>
                  <Grid
                    sx={{
                      width: '276px',
                      padding: '24px 16px',
                      backgroundColor: Colors.white,
                      borderRadius: '20px',
                      borderTop: `5px solid ${Colors.green[500]}`,
                      boxShadow: '5px 0px 15px rgba(108, 123, 132, 0.05)',
                    }}
                  >
                    <PublishForm
                      loading={isLoading}
                      publishForm={publishForm}
                      range={range}
                      suggestedDuration={challenge ? challenge.challengeDuration.suggestedDuration : ''}
                      isTeam={isTeamChallenge}
                      isCorporate={challenge.challengeCharacteristic.groupCategory === GROUP_CATEGORY.CORPORATE}
                      showNumberOfWinners={showNumberOfWinners}
                      handleChangeRange={handleChangeRange}
                      handleChangeTeamSize={handleChangeTeamSize}
                      handleChangeWinners={handleChangeWinners}
                      handleChangeSlackUrl={handleChangeSlackUrl}
                    />
                    <Box sx={{ mt: '40px' }}>
                      <Actions />
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Grid>
          ) : null}
        </Container>
      </IonContent>
    </IonPage>
  );
};
