import React from 'react';
import { Box, Grid } from '@mui/material';

type CustomDividerProps = {
  width?: string | number;
  marginY?: number;
};

const CustomDivider: React.FC<CustomDividerProps> = ({ width = '60%', marginY = 6 }) => {
  return (
    <Grid container justifyContent={'center'}>
      <Box sx={{ width, border: '1px solid #8B8B8B', my: marginY }} />
    </Grid>
  );
};

export default CustomDivider;
