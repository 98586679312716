import { Skeleton, Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { Colors } from '../../../theme/colors';
import ContainerBg from '../../../assets/svg/feature/ranking/award-bg.svg';
import ContainerBigBg from '../../../assets/svg/feature/ranking/award-big-bg.svg';
import DefaultAwards from '../../../assets/svg/feature/ranking/awards.svg';
import TopAwards from '../../../assets/svg/feature/ranking/top-awards.svg';
import OtherAwardDisable from '../../../assets/svg/feature/ranking/award-other-disable.svg';
import OtherAward from '../../../assets/svg/feature/ranking/award-other.svg';
import Ribbon from '../../../assets/svg/feature/ranking/ribbon.svg';
import RibbonActive from '../../../assets/svg/feature/ranking/ribbon-active.svg';

import { ReactComponent as GoalIcon } from '../../../assets/svg/feature/goal.svg';
import { AWARD_TYPE } from '../../../models/enum/AWARD_TYPE';
import theme from '../../../theme';

type AwardCardProps = {
  isLoading?: boolean;
  isFirst?: boolean | null;
  awardsType?: AWARD_TYPE | null;
  points?: number | null;
  awardCounts?: number | null;
  type?: 'lg' | 'md' | 'sm' | 'xl';
  awardIcon?: string | null;
};

const AwardTitle = {
  [AWARD_TYPE.BADGE]: 'Badge',
  [AWARD_TYPE.NONE]: 'None',
  [AWARD_TYPE.OTHER]: 'Other',
  [AWARD_TYPE.POINT]: 'Point',
};

export const AwardCard: React.FC<AwardCardProps> = ({
  isLoading = false,
  isFirst = false,
  awardsType,
  points,
  awardCounts,
  type = 'lg',
  awardIcon,
}) => {
  const matchXs = useMediaQuery(theme.breakpoints.down('sm'));
  const downMd = useMediaQuery(theme.breakpoints.down('md'));
  if (isLoading || !awardsType) {
    return (
      <Skeleton
        component="div"
        variant="rectangular"
        sx={{ flex: 1, height: 260, width: '100%', borderRadius: '10px', background: Colors.gray[150] }}
      />
    );
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        border: `5px solid ${Colors.gray[50]}`,
        flex: 1,
        height: type === 'md' ? '100%' : 'unset',
        paddingBottom: { xs: '20px', md: type === 'sm' ? '11px' : type === 'md' || type === 'xl' ? '8px' : '24px' },
        borderRadius: '10px',
        background: `linear-gradient(197.61deg, ${Colors.gray[50]} 0%, ${Colors.gray[150]} 111.5%)`,
        overflow: 'hidden',
      }}
    >
      <img
        src={type === 'xl' ? ContainerBigBg : ContainerBg}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          objectFit: 'cover',
        }}
      />

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingInline: '10px',
          paddingTop: '10px',
        }}
      >
        <Typography sx={{ fontWeight: 700, fontSize: '14px', color: Colors.gray[700] }}>Award</Typography>
        {typeof awardCounts === 'number' && (
          <Box sx={{ display: 'flex' }}>
            <GoalIcon height="12px" width="12px" fill={Colors.gray[400]} />
            <Typography sx={{ fontSize: 10, color: Colors.gray[400], marginLeft: '4px' }}>
              Given <b>{awardCounts}</b> times
            </Typography>
          </Box>
        )}
      </Box>
      <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            maxWidth: type === 'sm' ? '180px' : type === 'xl' ? '306px' : '260px',
            paddingInline: '24px',
          }}
        >
          <img
            src={
              awardIcon
                ? awardIcon
                : awardsType === AWARD_TYPE.OTHER
                ? isFirst
                  ? OtherAward
                  : OtherAwardDisable
                : isFirst
                ? TopAwards
                : DefaultAwards
            }
            style={{
              position: 'relative',
              marginInline: (type === 'md' && !matchXs) || (type === 'xl' && !downMd) ? '15%' : '28%',
              width: (type === 'md' && !matchXs) || (type === 'xl' && !downMd) ? '70%' : '44%',
              height: 'auto',
              objectFit: 'contain',
              marginTop: matchXs ? 18 : 0,
              filter: awardIcon && !isFirst ? 'grayscale(100%)' : 'unset',
            }}
          />
          <Box
            sx={{
              position: 'relative',
              transform: type === 'md' ? (matchXs ? 'translateY(-20px)' : 'translateY(-10px)') : 'translateY(-18px)',
              width: { xs: type === 'md' ? '100%' : 160, md: '100%' },
              marginInline: 'auto',
            }}
          >
            <img
              src={isFirst ? RibbonActive : Ribbon}
              style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
            />
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                left: '50%',
                transform: 'translateX(-50%)',
                padding: {
                  xs: '7px',
                  md: type === 'sm' ? '6px 8px' : type === 'md' ? '7px 8px' : type === 'xl' ? '12px 14px' : '10px 12px',
                },
                borderRadius: 100,
                border: `1px solid ${isFirst ? '#F5F0D0' : '#E2E5E6'}`,
                background: isFirst
                  ? 'linear-gradient(133deg, rgba(236,224,162,1) 0%, rgba(217,193,69,1) 100%)'
                  : 'linear-gradient(90deg, rgba(196,202,206,1) 0%, rgba(137,149,157,1) 100%)',
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: 10, md: type === 'sm' ? 8.5 : type === 'md' ? 10 : 16 },
                  fontWeight: 900,
                  lineHeight: 1,
                  color: Colors.white,
                  marginTop: '4px',
                }}
              >
                {awardsType === AWARD_TYPE.POINT ? points : AwardTitle[awardsType]}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
