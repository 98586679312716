import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Collapse, Typography } from '@mui/material';
import { Colors } from '../../theme/colors';
import { ReactComponent as ArrowIcon } from '../../assets/svg/feature/arrow.svg';
import theme from '../../theme';
import { SxProps, Theme } from '@mui/system';

type Props = {
  title: string;
  borderColor: string;
  externalExpanded?: boolean;
  sx?: SxProps<Theme>;
};
export const CollapseBox: React.FC<Props> = ({ children, title, borderColor, externalExpanded, sx }) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    if (!externalExpanded) return;
    setExpanded(externalExpanded);
  }, [externalExpanded]);
  return (
    <Grid
      sx={{
        borderTop: `5px solid ${borderColor}`,
        borderRadius: '20px',
        background: Colors.white,
        padding: '16px',
        boxShadow: '5px 0px 15px rgba(108, 123, 132, 0.05)',
        ...sx,
      }}
    >
      <Grid
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}
        onClick={() => setExpanded(!expanded)}
      >
        <Typography sx={{ fontSize: '16px', color: Colors.gray[700], fontWeight: 700 }}>{title}</Typography>
        <ArrowIcon
          height={16}
          width={16}
          fill={Colors.gray[400]}
          style={{
            transform: !expanded ? 'rotate(-90deg)' : 'rotate(90deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
              duration: theme.transitions.duration.shortest,
            }),
          }}
        />
      </Grid>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </Grid>
  );
};
