import { useCallback, useEffect, useMemo, useState } from 'react';
import { getChallengeWinWeekly } from '../../../../lib/api/http/requests/challenge';
import { WeeklyChallengeProps } from '../components/WeeklyChallenge';
import { ChallengeWeekly } from '../../../../models/Challenge';
import { TEMPLATE_TYPE } from '../../../../models/enum/TEMPLATE_TYPE';
import { useDispatch, useSelector } from 'react-redux';
import { getJoinToChallenge } from '../../../../redux/slices/challengeSlice';
import { handleBackendError } from '../../../../utils/handleBackendError';
import { CHALLENGE_STATUS } from '../../../../models/enum/CHALLENGE_STATUS';
import { getPeriodRange } from '../../../../utils/booleanCalendarUtils';
import { useIonRouter } from '@ionic/react';
import { openToast } from '../../../../redux/slices/appSlice';
import {
  fetchWeeklyChallenge,
  updateChallengeMetric,
  updateHabit,
} from '../../../../redux/slices/challengeProgressSlice';
import { AppDispatch, RootState } from '../../../../redux/store';
import { CHALLENGE_TYPE } from '../../../../models/enum/CHALLENGE_TYPE';
import { EditCalendarTimelineItem } from '../../../ChallengeActivityPage/types';
import moment from 'moment';
import { METRICS_TYPE } from '../../../../models/enum/METRICS_TYPE';
import { metricsValidation } from '../../../../utils/metricsValidation';

export const useHandleChallenge = (moduleOrder: number, periodIndex: number) => {
  const [isOpenJoinModal, setIsOpenJoinModal] = useState(false);
  const [isOpenLogModal, setIsOpenLogModal] = useState(false);
  const [joinToChallengeLoading, setJoinToChallengeLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [input, setInput] = useState<string>();
  const [secondInput, setSecondInput] = useState<string>();
  const [date, setDate] = useState<Date>();
  const [listUpdatedDay, setListUpdatedDay] = useState<EditCalendarTimelineItem[]>([]);

  const challenge = useSelector((state: RootState) => state.challengeProgress.data.challengeWeekly);

  const dispatch: AppDispatch = useDispatch();
  const router = useIonRouter();

  const handleOpenLink = () => {
    if (!challenge) return;
    const uuid =
      challenge.challengeStatus === CHALLENGE_STATUS.TEMPLATE
        ? challenge.metadata.templateChallengeUUID
        : challenge.metadata.challengeUUID;
    const queryParam = challenge.challengeStatus === CHALLENGE_STATUS.TEMPLATE ? '?isTemplate=true' : '';

    if (
      challenge.challengeStatus === CHALLENGE_STATUS.ACTIVE ||
      challenge.challengeStatus === CHALLENGE_STATUS.COMPLETED ||
      challenge.challengeStatus === CHALLENGE_STATUS.IN_GRACE_PERIOD
    ) {
      if (challenge.challengeParticipation.isUserParticipant) {
        router.push(`/page/your-challenges/challenge/${uuid}/activity`);
        return;
      }
    }

    router.push(`/page/your-challenges/challenge/${uuid}${queryParam}`);
  };

  const handleOpenLogModal = (e: any) => {
    e.stopPropagation();
    setIsOpenLogModal(true);
  };

  const handleOpenJoinModal = (e: any) => {
    e.stopPropagation();
    setIsOpenJoinModal(true);
  };

  const onChangeDate = (date: Date) => {
    const today = new Date().setHours(0, 0, 0, 0);
    const minDay = new Date(today).setDate(new Date(today).getDate() - 7);
    if (!challenge?.challengeDuration.startDate && !challenge?.challengeDuration.endDate) return;
    const startDate = new Date(challenge.challengeDuration.startDate).setHours(0, 0, 0, 0);
    const endDate = new Date().setHours(0, 0, 0, 0);
    const selectedDay = new Date(date).setHours(0, 0, 0, 0);
    if (date.getTime() >= startDate && date.getTime() <= endDate) {
      if (today < selectedDay) {
        dispatch(openToast({ text: 'The day has not yet come', type: 'error' }));
        return;
      }
      if (minDay >= selectedDay) {
        dispatch(openToast({ text: 'You can only log data for the past 7 days', type: 'error' }));
        return;
      }
      setDate(date);
      const findDateData = listUpdatedDay.find((item) => new Date(item.date).setHours(0, 0, 0, 0) === selectedDay);
      if (challenge?.challengeAction?.metricType === METRICS_TYPE.SLEEP_DURATION) {
        const minutes = (findDateData?.newValue ?? findDateData?.value ?? 0) % 60;
        const hours = ((findDateData?.newValue ?? findDateData?.value ?? 0) - minutes) / 60;
        setInput(hours.toString());
        setSecondInput(minutes.toString());
        return;
      }
      setInput(findDateData?.newValue?.toString() ?? findDateData?.value.toString() ?? '');
    }
  };

  const handlePlusButton = () => {
    if (!date) {
      dispatch(openToast({ text: 'Please select a day to log your data', type: 'error' }));
      return;
    }
    if (!challenge?.challengeAction.metricType) {
      dispatch(openToast({ text: 'Unknown metric type or missing metric type', type: 'error' }));
      return;
    }
    const validation = metricsValidation(challenge.challengeAction.metricType, input ?? '', secondInput);
    if (!validation.success) {
      dispatch(openToast({ text: validation.error ?? 'Wrong input value', type: 'error' }));
      return;
    }
    const itemIndex = listUpdatedDay.findIndex(
      (item) => new Date(item.date).setHours(0, 0, 0, 0) === new Date(date).setHours(0, 0, 0, 0),
    );
    if (itemIndex >= 0) {
      if (challenge?.challengeAction.metricType === METRICS_TYPE.SLEEP_DURATION) {
        if (!Number(secondInput)) {
          setListUpdatedDay(
            listUpdatedDay.map((item, idx) =>
              idx === itemIndex
                ? {
                    ...item,
                    newValue: Math.round(Number(input) * 60),
                  }
                : item,
            ),
          );
        }
        setListUpdatedDay(
          listUpdatedDay.map((item, idx) =>
            idx === itemIndex
              ? {
                  ...item,
                  newValue: Math.round(Number(input) * 60 + Number(secondInput)),
                }
              : item,
          ),
        );
        return;
      }
      setListUpdatedDay(
        listUpdatedDay.map((item, idx) =>
          idx === itemIndex
            ? {
                ...item,
                newValue: Math.round(Number(input)),
              }
            : item,
        ),
      );
      return;
    }
    setListUpdatedDay([
      ...listUpdatedDay,
      { date: moment(date).format('YYYY-MM-DD'), value: 0, newValue: Math.round(Number(input)) },
    ]);
  };

  const handleUpdate = (date: string, isSuccess: boolean) => {
    const isGracePeriodEnd = challenge?.challengeDuration.gracePeriodEnd
      ? new Date().setHours(0, 0, 0, 0) > new Date(challenge.challengeDuration.gracePeriodEnd).setHours(0, 0, 0, 0)
      : false;
    if (isGracePeriodEnd) return;
    if (!challenge?.metadata?.challengeUUID) return;
    const today = new Date().setHours(0, 0, 0, 0);
    const selectedDay = new Date(date).setHours(0, 0, 0, 0);
    const minDay = new Date(today).setDate(new Date(today).getDate() - 7);
    if (today < selectedDay) {
      dispatch(openToast({ text: 'The day has not yet come', type: 'error' }));
      return;
    }
    if (minDay >= selectedDay) {
      dispatch(openToast({ text: 'You can only log data for the past 7 days', type: 'error' }));
      return;
    }
    dispatch(updateHabit({ date, habitValue: !isSuccess, challengeUUID: challenge?.metadata?.challengeUUID }));
  };
  const handleCloseOpenJoinModal = () => setIsOpenJoinModal(false);

  const handleAddValue = () => {
    if (isUpdating) return;
    setIsUpdating(true);
    const newListMetric = listUpdatedDay.filter(
      (item) => item?.newValue && item.newValue >= 0 && item.value !== item.newValue,
    ) as Array<{ date: string; newValue: number }>;
    if (
      newListMetric.length > 0 &&
      challenge?.challengeAction?.metricType &&
      challenge?.challengeCharacteristic?.groupCategory
    ) {
      dispatch(
        updateChallengeMetric({
          metricType: challenge?.challengeAction?.metricType,
          title: challenge?.challengeAction?.title,
          group: challenge?.challengeCharacteristic?.groupCategory,
          newListMetric,
        }),
      )
        .unwrap()
        .then(handleCloseOpenJoinModal);
    }
  };

  const handleJoinToChallenge = async (winParams?: { action?: string; startDate: string; endDate: string }) => {
    if (!challenge) return;

    const params =
      challenge.challengeCharacteristic.templateType !== TEMPLATE_TYPE.WELLNESS
        ? {
            templateChallengeUUID: challenge.metadata.templateChallengeUUID,
            status: challenge.challengeStatus,
            ...winParams,
          }
        : { challengeUUID: challenge.metadata.challengeUUID, status: challenge.challengeStatus, ...winParams };

    const dispatchData = await (dispatch(getJoinToChallenge(params)) as any).unwrap();
    dispatch(fetchWeeklyChallenge({ moduleOrder, periodIndex }));
    handleCloseOpenJoinModal();
  };

  useEffect(() => {
    if (moduleOrder && periodIndex && periodIndex > 0) {
      dispatch(fetchWeeklyChallenge({ moduleOrder, periodIndex }));
    }
  }, [moduleOrder, periodIndex]);

  const challengeCard: WeeklyChallengeProps | undefined = useMemo(() => {
    if (!challenge) return undefined;
    const status =
      (challenge.challengeStatus === CHALLENGE_STATUS.ACTIVE ||
        challenge.challengeStatus === CHALLENGE_STATUS.IN_GRACE_PERIOD) &&
      challenge.challengeParticipation.isUserParticipant
        ? 'active'
        : challenge.challengeStatus === CHALLENGE_STATUS.COMPLETED && challenge.challengeParticipation.isUserParticipant
        ? 'finished'
        : 'in-active';
    const isBoolean = challenge.challengeAction.actionType === 'HABIT';
    const resProps = {
      isTarget:
        challenge.challengeCharacteristic.challengeType === CHALLENGE_TYPE.LEADERBOARD ||
        challenge.challengeCharacteristic.challengeType === CHALLENGE_TYPE.TARGET,
      score: challenge?.myRankingHst?.score ?? 0,
      percent: challenge.challengeGoal?.goalValue
        ? ((challenge?.myRankingHst?.score ?? 0) / challenge.challengeGoal.goalValue) * 100
        : 0,
    };
    return {
      imageUrl: challenge.challengeInfo.imageUrl ?? undefined,
      title: challenge.challengeInfo?.title ?? '',
      description: challenge.challengeInfo?.shortDescription ?? '',
      status,
      handleOpen: handleOpenLink,
      joinBlock: {
        avatars: challenge.participantsAvatars ?? [],
        count: challenge.challengeParticipation.numberOfParticipants,
        onClickJoin: handleOpenJoinModal,
      },
      resultBlock: challenge.myRankingHst
        ? {
            ...resProps,
            awardUrl: challenge?.challengeAward?.awards?.[0].badgeImageUrl,
            isAward: !!challenge?.myRankingHst?.award,
            onClickJoin: handleOpenJoinModal,
          }
        : undefined,
      logDataBlock:
        status === 'active'
          ? {
              isBoolean,
              ...(isBoolean
                ? {
                    rangeDate: getPeriodRange(
                      challenge.challengeDuration.startDate,
                      challenge.challengeDuration.endDate,
                      challenge?.userRanking?.scoreTimeline ?? [],
                    ),
                  }
                : {}),

              handleUpdate,
              handleOpenLogModal,
              ...resProps,
            }
          : undefined,
    };
    return undefined;
  }, [challenge]);

  return {
    challengeCard,
    challenge,
    isOpenJoinModal,
    joinToChallengeLoading,
    logModalDataProps: {
      visible: isOpenLogModal,
      date,
      input,
      secondInput,
      timelineScore: challenge?.userRanking?.scoreTimeline ?? [],
      startDate: challenge?.challengeDuration?.startDate,
      endDate: challenge?.challengeDuration?.endDate,
      metricTitle: challenge?.challengeAction?.title ?? '',
      metricType: challenge?.challengeAction.metricType,
      disableLogData: listUpdatedDay.length === 0,
      onChangeDate,
      onChangeInput: setInput,
      onChangeSecondInput: setSecondInput,
      onClose: handleCloseOpenJoinModal,
      handleAddValue,
      handlePlusButton,
    },
    handleCloseOpenJoinModal,
    handleJoinToChallenge,
  };
};
