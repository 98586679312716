import React from 'react';
import { Box, SxProps, Theme } from '@mui/material';

type TableCellProps = {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  onClick?: () => void;
};

export const TableCell: React.FC<TableCellProps> = ({ children, sx, onClick }) => (
  <Box
    sx={{ display: 'flex', alignItems: 'center', paddingInline: '24px', flex: 1, background: 'unset', ...sx }}
    {...(onClick && { onClick, component: 'button' })}
  >
    {children}
  </Box>
);
