import React from 'react';
import { Box, Grid, IconButton, styled, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { StyledIconButton } from './StyledIconButton';
import { Colors } from '../../theme/colors';

type PaginatorProps = {
  pageSize: number;
  /**
   * @param currentPage  minimum is 0
   */
  currentPage: number;
  totalItems: number;
  setPage: (p: number) => void;
};

const CustomPaginator: React.FC<PaginatorProps> = ({ pageSize, currentPage, totalItems, setPage }) => {
  const getMinCurrentPage = () => {
    if (totalItems) return currentPage * pageSize + 1;
    else return 0;
  };

  const getMaxCurrentPage = () => {
    return Math.min((currentPage + 1) * pageSize, totalItems);
  };

  const handleGoNext = () => {
    if ((currentPage + 1) * pageSize < totalItems) setPage(currentPage + 1);
  };

  const handleGoPrevious = () => {
    if (currentPage > 0) setPage(currentPage - 1);
  };

  const goNextDisabled = () => {
    return (currentPage + 1) * pageSize >= totalItems;
  };

  const goPreviousDisabled = () => {
    return currentPage <= 0;
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Grid item sx={{ mr: '10px' }}>
        <StyledIconButton disabled={goPreviousDisabled()} onClick={handleGoPrevious}>
          <ChevronLeftIcon />
        </StyledIconButton>
      </Grid>
      <Grid
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: Colors.white,
          height: '28px',
          minWidth: '95px',
          padding: '0 16px',
          borderRadius: '30px',
          border: '1px solid #E2E5E6',
          wrap: 'nowrap',
        }}
      >
        <Grid item>
          <Typography
            sx={{ fontSize: '14px', fontWeight: 700, color: Colors.gray[700] }}
          >{`${getMinCurrentPage()}-${getMaxCurrentPage()}`}</Typography>
        </Grid>
        <Grid item sx={{ px: '4px', fontSize: '14px', color: Colors.gray[700] }}>
          {'of'}
        </Grid>
        <Grid item>
          <Typography sx={{ fontSize: '14px', fontWeight: 700, color: Colors.gray[700] }}>{totalItems}</Typography>
        </Grid>
      </Grid>
      <Grid item sx={{ ml: '10px' }}>
        <StyledIconButton disabled={goNextDisabled()} onClick={handleGoNext}>
          <ChevronRightIcon />
        </StyledIconButton>
      </Grid>
    </Box>
  );
};

export default CustomPaginator;
