import { Box, TextField, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { Colors } from '../../../theme/colors';

type MobileSleepInputProps = {
  unit: string;
  value: string;
  onClickDecrease: () => void;
  onClickIncrease: () => void;
  onChangeInput: (val: string) => void;
};

export const MobileSleepInput: React.FC<MobileSleepInputProps> = ({
  unit,
  value,
  onClickDecrease,
  onClickIncrease,
  onChangeInput,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const handleFocus = () => {
    inputRef.current?.focus();
  };
  return (
    <Box sx={{ display: 'flex', mb: '16px' }}>
      <Box
        component="button"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '48px',
          width: '48px',
          borderRadius: '10px',
          border: `1px solid ${Colors.gray[100]}`,
          background: Colors.white,
          fontWeight: '700',
          color: Colors.gray[700],
          fontSize: '18px',
        }}
        onClick={onClickDecrease}
      >
        -
      </Box>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          height: '48px',
          border: `1px solid ${Colors.gray[100]}`,
          borderRadius: '10px',
          marginInline: '8px',
          userSelect: 'none',
        }}
        onClick={handleFocus}
      >
        <Typography sx={{ color: Colors.gray[700], fontWeight: '900', fontSize: '18px' }}>
          {Number(value)}{' '}
          <Box component="span" sx={{ fontSize: '10px', fontWeight: 400 }}>
            {unit}
          </Box>
        </Typography>
      </Box>
      <Box
        component="button"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '48px',
          width: '48px',
          borderRadius: '10px',
          border: `1px solid ${Colors.gray[100]}`,
          background: Colors.white,
          fontWeight: '700',
          color: Colors.gray[700],
          fontSize: '18px',
        }}
        onClick={onClickIncrease}
      >
        +
      </Box>
      <TextField
        inputRef={inputRef}
        sx={{
          opacity: 0,
          zIndex: -1,
          border: '1px solid red',
          position: 'absolute',
          height: 0,
          width: 0,
        }}
        value={value}
        onChange={(el) => onChangeInput(el.target.value)}
        inputProps={{
          inputMode: 'decimal',
        }}
      />
    </Box>
  );
};
