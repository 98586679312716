import React, { FC } from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { Colors } from '../../../theme/colors';
import { CustomButton } from '../CustomButton';
import { ReactComponent as ThymiaIcon } from '../../../assets/svg/thymiaIcon.svg';
import { ReactComponent as DangerIcon } from '../../../assets/svg/feature/danger.svg';

import theme from '../../../theme';

interface Props {
  notification: string;
  handleRetryInitialise: () => void;
}
export const RetrySection: FC<Props> = ({ notification, handleRetryInitialise }) => {
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', padding: '40px 24px' }}>
      <Grid sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', textAlign: 'center' }}>
        <Typography sx={{ fontSize: 18, lineHeight: 1, color: Colors.gray[700], fontWeight: 800 }}>
          Incomplete activity
        </Typography>
      </Grid>

      <Box sx={{ my: { xs: '16px', sm: '24px' }, textAlign: 'center' }}>
        <ThymiaIcon style={{ width: xsDown ? 250 : '100%', height: xsDown ? 150 : '204px' }} />
      </Box>

      {notification.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: Colors.yellow[100],
            borderRadius: '30px',
            padding: '8px 16px',
          }}
        >
          <DangerIcon width={16} height={16} style={{ fill: Colors.yellow[600], flexShrink: 0 }} />
          <Typography
            sx={{
              fontSize: 12,
              lineHeight: 1.4,
              color: Colors.yellow[600],
              fontWeight: 400,
              ml: '8px',
            }}
          >
            {notification}
          </Typography>
        </Box>
      )}

      <CustomButton
        type={'bigBlue'}
        text={'Retake an assessment'}
        sx={{ maxWidth: 281, width: '100%', mt: '40px' }}
        onClick={handleRetryInitialise}
      />
    </Box>
  );
};
