import moment from 'moment';
import { CalendarTimelineItem } from '../pages/ChallengeActivityPage/types';
import { RangeMap } from '../models/Shared';

export const getPeriodRange = (
  start: string,
  end: string,
  timeLine: CalendarTimelineItem[],
  teamTimeLine?: CalendarTimelineItem[],
  maxSize?: number,
  isTeamGoal?: boolean,
) => {
  const timelineRange = new Map<string, { date: string; value?: number; status?: string }>();
  const today = moment();
  timeLine.forEach((item) => timelineRange.set(moment(item.date).format('YYYY-MM-DD'), item));
  const fromDate = moment(start);
  const toDate = moment(end);
  const diff = toDate.isAfter(today, 'days') ? today.diff(fromDate, 'days') : toDate.diff(fromDate, 'days');
  const rangeDate: RangeMap = [];
  if (teamTimeLine) {
    const teamTimelineRange = new Map<string, { date: string; value?: number; status?: string }>();
    teamTimeLine.forEach((item) => teamTimelineRange.set(moment(item.date).format('YYYY-MM-DD'), item));
    for (let i = 0; i <= diff; i++) {
      const day = moment(start).add(i, 'days');
      const key = day.format('YYYY-MM-DD');
      rangeDate.push({
        date: key,
        day: day.date(),
        isToday: day.isSame(today, 'day'),
        label: teamTimelineRange.get(key)?.value ? `${teamTimelineRange.get(key)?.value}/${maxSize}` : undefined,
        isSuccess: timelineRange.get(key)?.status === 'SUCCESS',
        isComplete: isTeamGoal ? teamTimelineRange.get(key)?.status === 'SUCCESS' : false,
      });
    }
    return rangeDate;
  }

  for (let i = 0; i <= diff; i++) {
    const day = moment(start).add(i, 'days');
    const key = day.format('YYYY-MM-DD');
    rangeDate.push({
      date: key,
      day: day.date(),
      isToday: day.isSame(today, 'day'),
      isSuccess: timelineRange.get(key)?.status === 'SUCCESS',
    });
  }
  return rangeDate;
};
