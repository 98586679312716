import { throttle } from 'lodash';
import { useState, useEffect, useRef } from 'react';
import { QueryFilterData } from '../../components/features/HeaderFilter/useHandleFilter';
import { getChallengesRequest } from '../../lib/api/http/requests/challengeAdmin';
import { Challenge } from '../../models/Challenge';
import { CHALLENGE_STATUS } from '../../models/enum/CHALLENGE_STATUS';
import useIsPageActive from '../../hooks/useIsPageActive';
import { ANALYTICS_PAGE } from '../../models/enum/ANALYTICS_PAGE';
import UserService from '../../services/UserService';
import { useExportExcel } from '../../hooks/useExportExcel';
import { getRequest } from '../../services/HttpService';
import { useDispatch } from 'react-redux';
import { openToast } from '../../redux/slices/appSlice';
import { handleBackendError } from '../../utils/handleBackendError';

const SIZE_PAGE = 12;

export const useHandleFetch = (challengeStatus: CHALLENGE_STATUS[], queryFilter: QueryFilterData) => {
  const [items, setItems] = useState<Challenge[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);

  const { exportToExcel, exportFileName } = useExportExcel();
  const dispatch = useDispatch();

  const visible = useIsPageActive(ANALYTICS_PAGE.CHALLENGES_ADMIN_VIEW_ALL);
  const divRef = useRef<HTMLIonContentElement>(null);
  const uuid = UserService.getMineId();

  const handleNext = async () => {
    try {
      if (isFetching || !hasNextPage || loading) return;
      setIsFetching(true);
      const { data } = await getChallengesRequest({
        searchText: queryFilter.searchText,
        challengePillars: queryFilter.challengePillars,
        competition: queryFilter.competition,
        page: page,
        size: SIZE_PAGE,
        metric: queryFilter.metric,
        challengeStatus,
        publisher: queryFilter.publisher ? uuid : undefined,
      });
      setPage(data.pageable.pageNumber + 1);
      setHasNextPage(!data.last);
      setItems([...items, ...data.content]);
      setIsFetching(false);
    } catch (err) {
      handleBackendError(err, 'Failed fetch challenges');
      setHasNextPage(false);
      setIsFetching(false);
    }
  };

  const handleExport = async () => {
    try {
      const params = challengeStatus.map((item) => `challengeStatus=${item}`).join('&');
      const response: { data?: string } = await getRequest(`/gamification-service/admin/challenges/export?${params}`);
      if (response.data) {
        await exportToExcel(response.data, exportFileName('challenge-export'));
        return;
      }
      dispatch(openToast({ type: 'error', text: 'Export file is empty' }));
    } catch (err) {
      handleBackendError(err, 'Fail to export challenges');
    }
  };

  const handleScrollEvent = throttle(async () => {
    if (divRef.current) {
      const { scrollHeight, scrollTop, offsetHeight } = await divRef.current.getScrollElement();
      const currentPosition = scrollTop + offsetHeight;
      const triggerPosition = scrollHeight - 40;
      if (currentPosition >= triggerPosition && (!isFetching || hasNextPage)) {
        handleNext();
      }
    }
  }, 200);

  const firstFetch = async () => {
    try {
      scrollToTop();
      setLoading(true);
      const { data } = await getChallengesRequest({
        searchText: queryFilter.searchText,
        challengePillars: queryFilter.challengePillars,
        competition: queryFilter.competition,
        page: 0,
        size: SIZE_PAGE,
        metric: queryFilter.metric,
        challengeStatus,
        publisher: queryFilter.publisher ? uuid : undefined,
      });
      if (divRef.current && !data.last) {
        const { scrollHeight, scrollTop, offsetHeight } = await divRef.current.getScrollElement();
        const currentPosition = scrollTop + offsetHeight;
        const triggerPosition = scrollHeight - 40;
        if (currentPosition >= triggerPosition) {
          const { data: nextPageData } = await getChallengesRequest({
            searchText: queryFilter.searchText,
            challengePillars: queryFilter.challengePillars,
            competition: queryFilter.competition,
            page: 1,
            size: SIZE_PAGE,
            metric: queryFilter.metric,
            onlyCorporate: queryFilter.onlyCorporate,
            challengeStatus,
          });
          setHasNextPage(!nextPageData.last);
          setItems([...data.content, ...nextPageData.content]);
          setPage(nextPageData.pageable.pageNumber + 1);
          setLoading(false);
          return;
        }
      }

      setHasNextPage(!data.last);
      setItems(data.content);
      setPage(data.pageable.pageNumber + 1);
      setLoading(false);
    } catch (err) {
      handleBackendError(err, 'Failed fetch challenges');
      setLoading(false);
    }
  };

  const scrollToTop = () => {
    divRef.current && divRef.current.scrollToTop();
  };

  useEffect(() => {
    const subscribe = setTimeout(firstFetch, 600);
    return () => clearTimeout(subscribe);
  }, [queryFilter, visible]);

  const handleUpdateJoinData = async () => await firstFetch();

  return { items, loading, isFetching, divRef, handleScrollEvent, handleUpdateJoinData, handleExport };
};
