import { useSelector } from 'react-redux';
import moment from 'moment';
import { Swiper as SwiperType } from 'swiper';
import { RootState } from '../../../redux/store';
import { CalendarTimelineItem } from '../types';
import { ScoreTimeItemType } from '../../../models/Shared';
import { useEffect, useMemo, useState } from 'react';

export type TeamDailyRange = Array<{
  date: string;
  day: number;
  month: string;
  percent: number;
  isToday: boolean;
  score?: number;
  target?: number | null;
  label?: string;
}>;

const getPeriodRange = (
  start?: string,
  end?: string,
  target?: number | null,
  timelineScore?: CalendarTimelineItem[],
  teamTimelineScore?: CalendarTimelineItem[],
  maxSize?: number,
): TeamDailyRange => {
  if (!timelineScore || !maxSize || !start || !end) return [];
  const timelineRange = new Map<string, ScoreTimeItemType>();
  const teamTimeLinRange = new Map<string, ScoreTimeItemType>();

  const today = new Date();
  const fromDate = moment(start);
  const toDate = moment(end);
  const diff = toDate.diff(fromDate, 'days');
  const rangeDate: TeamDailyRange = [];

  timelineScore.forEach((item) => timelineRange.set(moment(item.date).format('YYYY-MM-DD'), item));
  (teamTimelineScore ?? []).forEach((item) => teamTimeLinRange.set(moment(item.date).format('YYYY-MM-DD'), item));
  for (let i = 0; i <= diff; i++) {
    const day = moment(start).add(i, 'days');
    const key = day.format('YYYY-MM-DD');
    const completedMember = teamTimeLinRange.get(key)?.value;
    const timeItem = timelineRange.get(key);

    rangeDate.push({
      date: key,
      day: day.date(),
      month: day.format('MMMM'),
      score: timeItem?.value,
      label: !completedMember || !maxSize ? `0/${maxSize}` : `${completedMember}/${maxSize}`,
      percent: timeItem && target ? (timeItem.value > target ? 100 : (timeItem.value / target) * 100) : 0,
      isToday: day.isSame(today, 'day'),
      target,
    });
  }
  return rangeDate;
};

export const useHandleTeamDailyProgress = () => {
  const { progress, duration, user, detail } = useSelector((state: RootState) => state.challengeProgress.data);

  const [monthName, setMonthName] = useState('');
  const [dailyScore, setDailyScore] = useState(0);
  const [activeIndex, setActiveIndex] = useState(
    duration && new Date().getTime() > new Date(duration.endDate).getTime() ? duration.challengeDays - 1 : 0,
  );

  const range = useMemo(() => {
    return getPeriodRange(
      duration?.startDate,
      duration?.endDate,
      progress?.goalValue,
      progress?.scoreTimeline,
      progress?.teamScoreTimeLine,
      detail?.teamSize,
    );
  }, [
    duration?.startDate,
    duration?.endDate,
    progress?.goalValue,
    progress?.scoreTimeline,
    progress?.teamScoreTimeLine,
    detail?.teamSize,
  ]);

  const onSlideChange = ({ activeIndex }: SwiperType) => {
    setActiveIndex(activeIndex);
  };

  useEffect(() => {
    if (range) {
      setMonthName(range[activeIndex]?.month ?? '');
      setDailyScore(range[activeIndex].score ?? 0);
    }
  }, [range, activeIndex]);

  // useEffect(() => {
  //   if (duration && new Date().getTime() > new Date(duration.endDate).getTime()) {
  //     setActiveIndex(duration.challengeDays - 1);
  //   }
  // }, [duration]);

  return {
    monthName,
    dailyScore,
    target: progress?.goalValue ?? 0,
    range,
    metricTitle: progress?.metricTitle,
    onSlideChange,
  };
};
