import React from 'react';
import { CollapseBox } from '../../../components/features/CollapseBox';
import { Colors } from '../../../theme/colors';
import { SettingsForm } from '../../NotificationsSettingsPage/components/SettingsForm';
import { FieldsName } from '../../NotificationsSettingsPage/hooks/useSettingsHandle';
import { ChallengeNotificationSettingsForm } from '../../../models/NotificationSettings';

export const NotificationSettingsSection: React.FC<{
  challengeSettings: ChallengeNotificationSettingsForm;
  externalExpanded?: boolean;
  handleChallengeSettingsChange: (index: number, name: FieldsName, value: boolean) => void;
}> = ({ challengeSettings, externalExpanded, handleChallengeSettingsChange }) => {
  return (
    <CollapseBox
      title={'Challenge notifications'}
      externalExpanded={externalExpanded}
      borderColor={Colors.orange[300]}
      sx={{ mt: '18px' }}
    >
      <SettingsForm
        component
        form={challengeSettings}
        disabled={!challengeSettings.receive}
        handleFormChange={handleChallengeSettingsChange}
      />
    </CollapseBox>
  );
};
