import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { setupIonicReact } from '@ionic/react';
import store from './redux/store';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import theme from './theme';
import UserService from './services/UserService';
import HttpService from './services/HttpService';

setupIonicReact({
  rippleEffect: false,
  mode: 'ios',
});

export const dispatch = store.dispatch;
export const getStore = store.getState;

const renderApp = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
};

UserService.initKeycloak(renderApp);
HttpService.configureKeycloak();
// HttpService.setSSLPinning();
