import * as React from 'react';
import { Card, CardContent, Chip, ChipProps, Grid, styled, useMediaQuery, useTheme } from '@mui/material';
import WellnessLineChart from '../charts/WellnessLineChart';
import MultipleDropdownSelect from '../common/MultipleDropdownSelect';
import CustomDropdown from '../common/CustomDropdown';
import CustomRangePicker from '../common/CustomRangePicker';
import { GraphMetric, IMetric } from '../../models/Metrics';
import { Range } from 'react-date-range';
import moment from 'moment';
import { PERIOD } from '../../models/Shared';
import CustomGoalDropdown from '../common/CustomGoalDropdown';

interface ChartChipProps extends ChipProps {
  chipColor?: string;
}

const ChartChip = styled(Chip, { shouldForwardProp: (prop) => prop !== 'chipColor' })<ChartChipProps>(
  ({ chipColor, theme }) => ({
    width: '100%',
    color: theme.palette.common.white,
    backgroundColor: `${chipColor} !important`,
    '&:hover': {
      backgroundColor: chipColor,
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: chipColor,
      borderColor: chipColor,
    },
  }),
);

const METRIC_COLORS = ['#00B6BE', '#DA4E53', '#FAAF1F', '#C154B0'];

interface WellnessCardProps {
  colors: string[];
  selectedMetrics: IMetric[];
  setSelectedMetrics: (s: IMetric[]) => void;
  metricsList: IMetric[];
  setRange: (range: Range[]) => void;
  range: Range[];
  graph: GraphMetric;
  period: PERIOD;
  setPeriod: (p: PERIOD) => void;
  selectedGoal: string;
  setSelectedGoal: (s: string) => void;
  goalsList: string[];
}

const WellnessCard: React.FC<WellnessCardProps> = ({
  colors,
  selectedMetrics,
  metricsList,
  setSelectedMetrics,
  range,
  setRange,
  graph,
  period,
  setPeriod,
  goalsList,
  selectedGoal,
  setSelectedGoal,
}) => {
  const theme = useTheme();
  const breakpointDown = useMediaQuery(theme.breakpoints.down(700));

  const getDisabledPeriod = () => {
    if (range.length === 1) {
      const { startDate, endDate } = range[0];
      return !moment(startDate).isSame(endDate);
    } else return false;
  };
  return (
    <Card
      sx={{
        borderRadius: 2,
        backgroundColor: 'common.white',
        boxShadow: `0px 4px 24px rgba(138, 138, 138, 0.25)`,
        flex: 1,
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          pb: 1,
          '&:last-child': { pb: 1 },
          flexGrow: 1,
        }}
      >
        <Grid container sx={{ py: 1 }}>
          <Grid item xs={12} container justifyContent={'flex-end'} wrap={'nowrap'} spacing={2}>
            <Grid item>
              <CustomDropdown
                disabled={getDisabledPeriod()}
                fullWidth={breakpointDown}
                width={150}
                list={Object.values(PERIOD)}
                selected={period}
                setSelected={(s: string) => setPeriod(s as unknown as PERIOD)}
              />
            </Grid>
            <Grid item>
              <CustomRangePicker mini={breakpointDown} range={range} setRange={setRange} />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            container
            sx={{ mt: 2 }}
            wrap={breakpointDown ? 'wrap' : 'nowrap'}
            spacing={2}
            alignItems={'flex-start'}
          >
            <Grid item sx={{ position: 'relative', flex: 1, minWidth: '300px' }} xs={breakpointDown ? 12 : undefined}>
              <WellnessLineChart graph={graph} colors={colors} selectedGoal={selectedGoal} />
            </Grid>
            <Grid container item spacing={1} sx={{ width: breakpointDown ? undefined : 260 }}>
              <Grid container item spacing={1}>
                <Grid item xs={breakpointDown ? 6 : 12}>
                  <MultipleDropdownSelect
                    fullWidth
                    list={metricsList}
                    selected={selectedMetrics}
                    setSelected={setSelectedMetrics}
                  />
                </Grid>
                <Grid item xs={breakpointDown ? 6 : 12}>
                  <CustomGoalDropdown
                    fullWidth
                    list={goalsList.map((g) => {
                      return { value: g, label: g };
                    })}
                    selected={selectedGoal}
                    setSelected={setSelectedGoal}
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={1} xs={12}>
                {selectedMetrics?.map((metric, index) => (
                  <Grid item key={`chart-chip-${index}`} xs={breakpointDown ? 6 : 12}>
                    <ChartChip label={metric.title} chipColor={METRIC_COLORS[index]} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default WellnessCard;
