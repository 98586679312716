import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { handleBackendError } from '../../utils/handleBackendError';
import { getIsUserEligible } from '../../lib/api/http/requests/mentalAssessment';
import { IMentalAssessmentForm } from '../../models/MentalAssessment';

export interface MentalAssessmentState {
  initialiseData: {
    id: string | null;
    activityLink: string | null;
  };
  isUserEligible: boolean;
  showMentalAssessmentModal: boolean;
  personalData: IMentalAssessmentForm;
  isAllowInitPlugin: boolean;
}

const initialState: MentalAssessmentState = {
  initialiseData: {
    id: null,
    activityLink: null,
  },
  isUserEligible: false,
  showMentalAssessmentModal: false,
  personalData: {
    user: {
      userLabel: '',
      dateOfBirth: '',
      birthSex: '',
    },
    language: '',
    activityType: '',
  },
  isAllowInitPlugin: false,
};

export const asyncGetIsUserEligible = createAsyncThunk('mentalAssessment/asyncGetIsUserEligible', async () => {
  try {
    const response = await getIsUserEligible();
    return response.data.userEligible;
  } catch (error) {
    handleBackendError(error, 'Failed to update get WHO-%');
  }
});

export const mentalAssessmentSlice = createSlice({
  name: 'mentalAssessment',
  initialState,
  reducers: {
    setInitialData: (
      state,
      action: PayloadAction<{
        id: string;
        activityLink: string;
      } | null>,
    ) => {
      if (!action.payload) {
        state.initialiseData = {
          id: null,
          activityLink: null,
        };
        return;
      }
      state.initialiseData.id = action.payload.id;
      state.initialiseData.activityLink = action.payload.activityLink;
    },
    setShowMentalAssessmentModal: (state, action: PayloadAction<boolean>) => {
      state.showMentalAssessmentModal = action.payload;
    },
    setIsAllowInitPlugin: (state, action: PayloadAction<boolean>) => {
      state.isAllowInitPlugin = action.payload;
    },
    setPersonalData: (state, action: PayloadAction<IMentalAssessmentForm>) => {
      state.personalData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncGetIsUserEligible.fulfilled, (state, action) => {
      state.isUserEligible = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setInitialData, setShowMentalAssessmentModal, setIsAllowInitPlugin, setPersonalData } =
  mentalAssessmentSlice.actions;

export default mentalAssessmentSlice.reducer;
