import * as React from 'react';
import {
  Box,
  Card,
  CardContent,
  Chip,
  ChipProps,
  Grid,
  Typography,
  alpha,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Range } from 'react-date-range';
import moment from 'moment';

import WellnessLineChart from '../../charts/WellnessLineChart';
import MultipleDropdownSelect from '../../common/MultipleDropdownSelect';
import CustomDropdown from '../../common/CustomDropdown';
import CustomRangePicker from '../../common/CustomRangePicker';
import { GraphMetric, IMetric } from '../../../models/Metrics';
import { PERIOD } from '../../../models/Shared';
import CustomGoalDropdown from '../../common/CustomGoalDropdown';
import WellicsLineChart from '../WellicsLineChart';
import { Colors } from '../../../theme/colors';
import EmptyImage from '../../../assets/png/empty-statistics.png';

interface ChartChipProps extends ChipProps {
  chipColor?: string;
}

const ChartChip = styled(Chip, { shouldForwardProp: (prop) => prop !== 'chipColor' })<ChartChipProps>(
  ({ chipColor, theme }) => ({
    width: '100%',
    color: theme.palette.common.white,
    backgroundColor: `${chipColor} !important`,
    '&:hover': {
      backgroundColor: chipColor,
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: chipColor,
      borderColor: chipColor,
    },
  }),
);

const METRIC_COLORS = ['#00B6BE', '#DA4E53', '#FAAF1F', '#C154B0'];

interface WellnessCardProps {
  colors: string[];
  selectedMetrics: IMetric[];
  setSelectedMetrics: (s: IMetric[]) => void;
  metricsList: IMetric[];
  setRange: (range: Range[]) => void;
  range: Range[];
  graph: GraphMetric;
  period: PERIOD;
  setPeriod: (p: PERIOD) => void;
  selectedGoal: string;
  setSelectedGoal: (s: string) => void;
  goalsList: string[];
}

export const WellnessCard: React.FC<WellnessCardProps> = ({
  colors,
  selectedMetrics,
  metricsList,
  setSelectedMetrics,
  range,
  setRange,
  graph,
  period,
  setPeriod,
  goalsList,
  selectedGoal,
  setSelectedGoal,
}) => {
  const theme = useTheme();
  const breakpointDown = useMediaQuery(theme.breakpoints.down(700));

  const isNotEmpty =
    graph &&
    (graph.metrics[0]?.metric_samples?.length > 0 ||
      graph.metrics?.[1]?.metric_samples?.length > 0 ||
      graph.metrics?.[2]?.metric_samples?.length > 0 ||
      graph.metrics?.[3]?.metric_samples?.length > 0);

  const getDisabledPeriod = () => {
    if (range.length === 1) {
      const { startDate, endDate } = range[0];
      return !moment(startDate).isSame(endDate);
    } else return false;
  };

  const getGraphPeriods = (): { startDate?: number; endDate?: number } | undefined => {
    if (getDisabledPeriod()) {
      return {
        startDate: range[0].startDate?.getTime(),
        endDate: range[0].endDate?.getTime(),
      };
    }
    return {
      startDate: moment()
        .subtract(
          period === PERIOD.WEEK ? 7 : 1,
          period === PERIOD.YEAR ? 'year' : period === PERIOD.MONTH ? 'month' : 'days',
        )
        .valueOf(),
      endDate: moment().valueOf(),
    };
  };

  return (
    <Card
      sx={{
        borderRadius: '20px',
        backgroundColor: 'common.white',
        boxShadow: `0px 4px 24px rgba(138, 138, 138, 0.25)`,
        flex: 1,
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          pb: 1,
          '&:last-child': { pb: 1 },
          flexGrow: 1,
        }}
      >
        <Grid container sx={{ py: 1 }}>
          <Grid
            item
            xs={12}
            container
            justifyContent={'flex-end'}
            wrap={breakpointDown ? 'wrap' : 'nowrap'}
            spacing={2}
          >
            {!breakpointDown ? (
              <>
                <Grid item>
                  <CustomDropdown
                    disabled={getDisabledPeriod()}
                    fullWidth={breakpointDown}
                    width={150}
                    list={Object.values(PERIOD)}
                    selected={period}
                    setSelected={(s: string) => setPeriod(s as unknown as PERIOD)}
                  />
                </Grid>
                <Grid item>
                  <CustomRangePicker disableFeature fontSize={14} range={range} setRange={setRange} />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <CustomRangePicker disableFeature fontSize={14} range={range} setRange={setRange} />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                    <Box sx={{ flex: 1, mb: '8px' }}>
                      <CustomDropdown
                        disabled={getDisabledPeriod()}
                        fullWidth={breakpointDown}
                        width={150}
                        list={Object.values(PERIOD)}
                        selected={period}
                        setSelected={(s: string) => setPeriod(s as unknown as PERIOD)}
                      />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <MultipleDropdownSelect
                        fullWidth
                        list={metricsList}
                        selected={selectedMetrics}
                        setSelected={setSelectedMetrics}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <CustomGoalDropdown
                    fullWidth
                    list={goalsList.map((g) => {
                      return { value: g, label: g };
                    })}
                    selected={selectedGoal}
                    setSelected={setSelectedGoal}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            container
            sx={{ mt: 2 }}
            wrap={breakpointDown ? 'wrap' : 'nowrap'}
            spacing={2}
            alignItems={'flex-start'}
          >
            <Grid
              item
              sx={{ position: 'relative', flex: 1, minWidth: '300px', mb: breakpointDown ? 0 : '24px' }}
              xs={breakpointDown ? 12 : undefined}
            >
              {isNotEmpty ? (
                <WellicsLineChart
                  graph={graph}
                  height={280}
                  colors={colors}
                  selectedGoal={selectedGoal}
                  notCommonMaxValue
                  {...getGraphPeriods()}
                />
              ) : (
                <Box
                  flex={1}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  sx={{ paddingTop: '16px', paddingBottom: '16px' }}
                >
                  <img
                    src={EmptyImage}
                    style={{
                      height: '100%',
                      width: 'auto',
                      maxHeight: '128px',
                      objectFit: 'contain',
                      marginBottom: '20px',
                    }}
                  />
                  <Typography sx={{ textAlign: 'center', fontSize: '18px', color: Colors.gray[300] }}>
                    No data logged
                  </Typography>
                </Box>
              )}
            </Grid>
            <Grid container item spacing={1} sx={{ width: breakpointDown ? undefined : 260 }}>
              <Grid container item spacing={1}>
                {!breakpointDown && (
                  <Grid item xs={breakpointDown ? 6 : 12} mb="8px">
                    <MultipleDropdownSelect
                      sx={{ mb: '8px' }}
                      fullWidth
                      list={metricsList}
                      selected={selectedMetrics}
                      setSelected={setSelectedMetrics}
                    />
                    <CustomGoalDropdown
                      fullWidth
                      list={goalsList.map((g) => {
                        return { value: g, label: g };
                      })}
                      selected={selectedGoal}
                      setSelected={setSelectedGoal}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sx={{ display: 'flex', flexWrap: 'wrap', ...(breakpointDown && { mb: '16px' }) }}>
                  {selectedMetrics?.map((metric, index) => (
                    <Box
                      key={`metric-${metric.metric_type}`}
                      display="flex"
                      alignItems="center"
                      mt={breakpointDown ? '16px' : '22px'}
                      sx={{ flexWrap: 'wrap' }}
                      width={breakpointDown ? '50%' : '100%'}
                    >
                      <Box
                        sx={{
                          background: METRIC_COLORS[index],
                          border: `1px solid ${alpha('#ffffff', 0.25)}`,
                          ...(breakpointDown ? { height: '12px', width: '12px' } : { height: '16px', width: '16px' }),
                          borderRadius: '50%',
                          marginRight: '8px',
                        }}
                      />
                      <Typography
                        sx={{ fontSize: breakpointDown ? '12px' : '16px', fontWeight: 'regular', color: '#5D6C6D' }}
                      >
                        {metric.title}
                      </Typography>
                    </Box>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
