import React, { useState } from 'react';
import CustomNewHeader from '../../components/features/CustomNewHeader';
import { Box, Container, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { IonContent, IonPage } from '@ionic/react';
import { ReactComponent as GoalIcon } from '../../assets/svg/goal.svg';
import { RouteComponentProps } from 'react-router';
import { useHandle } from './hooks/useHandle';
import Grid from '@mui/material/Grid';
import theme from '../../theme';
import { Colors } from '../../theme/colors';
import { CustomBudge } from '../../components/features/CustomBudge';
import { CHALLENGE_TYPE } from '../../models/enum/CHALLENGE_TYPE';
import { ReactComponent as ChallengeType } from '../../assets/svg/feature/сhellange-type.svg';
import { ReactComponent as Sum } from '../../assets/svg/feature/sum.svg';
import { ReactComponent as Winner } from '../../assets/svg/feature/winner.svg';
import { ReactComponent as StepsIcon } from '../../assets/svg/feature/metrics.svg';
import { ReactComponent as ClockIcon } from '../../assets/svg/clock.svg';
import { capitalize } from '../../utils/stringCapitalize';
import { DIMENSION_LIST } from '../../models/enum/DIMENTION_LIST';
import { RoundIconButton } from '../../components/features/RoundIconButton';
import { COMPETITION_LIST } from '../../models/enum/COMPETITION_LIST';
import { CustomStepper } from './components/CustomStepper';
import { FirstForm } from './components/FirstForm';
import { SecondForm } from './components/SecondForm';
import { ThirdForm } from './components/ThirdForm';
import { useStepHandle } from './hooks/useStepHandle';
import { CollapseBox } from '../../components/features/CollapseBox';
import { AWARD_TRIGGER } from '../../models/enum/AWARD_TRIGGER';
import { COMPETITION } from '../../models/enum/COMPETITION';
import { CALCULATION_TYPE } from '../../models/enum/CALCULATION_TYPE';
import { CHALLENGE_TYPE_LABEL } from '../../models/enum/CHALLENGE_TYPE_LABEL';

type Props = RouteComponentProps<{
  id: string;
}>;
type RouteParams = {
  id: string;
};

export const EditChallengePage: React.FC<Props> = ({ match }) => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const {
    challenge,
    challengeLoading,
    challengeTitle,
    challengeStatusBreadcrumbItem,
    templatesBreadcrumbItem,
    detailsPageBreadcrumbItem,
    durationInDays,
    form,
    range,
    isPublish,
    handleUpdateImage,
    handleChangeTitle,
    handleChangeShortDescription,
    handleUpdateBadgeImage,
    handleUpdateAwardType,
    handleUpdateAwardTitle,
    handleUpdateTotalGoal,
    handleChangeRange,
    handleChangeTeamSize,
    handleChangeWinners,
    handleChangeSlackUrl,
    handleChangeTrackingType,
    handleChangeNumberOfDays,
    handleChangeDailyGoal,
    handleChangeNumberOfPoints,
    Publish,
  } = useHandle(match.params as RouteParams);
  const {
    activeStep,
    isAvailableToSecondForm,
    isAvailableToThirdForm,
    isAvailablePublish,
    handleStep,
    handleNext,
    handleBack,
  } = useStepHandle(form, challenge);

  const CharacteristicSection: React.FC = () => {
    return (
      challenge && (
        <Grid>
          <Grid sx={{ pb: '16px' }}>
            <Typography sx={{ fontSize: '12px', color: Colors.gray[400], fontWeight: 700 }}>Dimension:</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {challenge.challengeCharacteristic.challengePillars.map((challengePillar) => {
                return DIMENSION_LIST.map(
                  (item, index) =>
                    item.value === challengePillar && (
                      <RoundIconButton
                        key={item.value}
                        sx={{
                          mt: '8px',
                          width: 'fit-content',
                          cursor: 'auto',
                        }}
                        textSx={{ fontSize: '14px', fontWeight: 700, ml: '8px' }}
                        Icon={item.Icon}
                        size={36}
                        background={item.mainColor}
                        roundColor={item.backgroundColor}
                        roundBorderColor={item.borderColor}
                        label={item.label}
                        onClick={() => undefined}
                      />
                    ),
                );
              })}
            </Box>
          </Grid>
          <Grid
            sx={{
              borderTop: '1px dashed #F2F2F2',
              py: '16px',
            }}
          >
            <Typography sx={{ fontSize: '12px', color: Colors.gray[400], fontWeight: 700 }}>Competition:</Typography>
            <Box sx={{ mt: '8px' }}>
              {COMPETITION_LIST.map((item) =>
                item.value === challenge.challengeCharacteristic.competition ? (
                  <CustomBudge
                    key={'Competition'}
                    Icon={item.Icon}
                    boxColor={item.mainColor}
                    borderColor={item.borderColor}
                    bgColor={'#D3DFE0'}
                    textColor={item.mainColor}
                    label={item.label}
                    size={32}
                    sx={{ width: 'fit-content' }}
                  />
                ) : null,
              )}
            </Box>
          </Grid>
          <Grid
            sx={{
              borderTop: '1px dashed #F2F2F2',
              py: '16px',
            }}
          >
            <Typography sx={{ fontSize: '12px', color: Colors.gray[400], fontWeight: 700 }}>Challenge type:</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CustomBudge
                key={'Challenge type'}
                Icon={ChallengeType}
                label={CHALLENGE_TYPE_LABEL[challenge.challengeCharacteristic.challengeType as CHALLENGE_TYPE]}
                size={32}
                sx={{ width: 'fit-content', mt: '8px' }}
              />
              <CustomBudge
                key={'Winner'}
                Icon={Winner}
                label={
                  challenge.challengeAward?.awardTrigger === AWARD_TRIGGER.COMPLETION
                    ? 'All'
                    : capitalize(challenge.challengeAward?.awardTrigger.toLowerCase())
                }
                size={32}
                sx={{ width: 'fit-content', mt: '8px' }}
              />
              {!(
                challenge.challengeCharacteristic.competition === COMPETITION.INDIVIDUAL &&
                challenge.challengeCharacteristic.calculationType === CALCULATION_TYPE.NONE
              ) && (
                <CustomBudge
                  key={'Sum'}
                  Icon={Sum}
                  label={
                    challenge.challengeCharacteristic.competition === COMPETITION.TEAM &&
                    challenge.challengeCharacteristic.calculationType === CALCULATION_TYPE.NONE
                      ? 'Self Performance'
                      : capitalize(challenge.challengeCharacteristic.calculationType.toLowerCase())
                  }
                  size={32}
                  sx={{ width: 'fit-content', mt: '8px' }}
                />
              )}
            </Box>
          </Grid>

          <Grid sx={{ display: 'flex', flexWrap: 'wrap', borderTop: '1px dashed #F2F2F2' }}>
            <Grid sx={{ mr: '16px', pt: '16px' }}>
              <Typography sx={{ fontSize: '12px', color: Colors.gray[400], fontWeight: 700 }}>Action:</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CustomBudge
                  key={'Action'}
                  bgColor={Colors.red[100]}
                  boxColor={Colors.red[500]}
                  borderColor={Colors.red[300]}
                  textColor={Colors.red[500]}
                  Icon={StepsIcon}
                  label={capitalize(challenge.challengeAction.title)}
                  size={32}
                  sx={{ width: 'fit-content', mt: '8px' }}
                />
              </Box>
            </Grid>
            <Grid sx={{ pt: '16px' }}>
              <Typography sx={{ fontSize: '12px', color: Colors.gray[400], fontWeight: 700, mb: '4px' }}>
                Duration:
              </Typography>
              <Box>
                <CustomBudge
                  key={'Duration'}
                  Icon={ClockIcon}
                  label={durationInDays}
                  size={32}
                  sx={{ width: 'fit-content', mt: '8px' }}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )
    );
  };
  const SkeletonContent: React.FC = () => {
    return mdDown ? (
      <Grid
        sx={{
          position: 'relative',
          width: '100%',
        }}
      >
        <Skeleton
          variant="rectangular"
          height={'56px'}
          width={'285px'}
          sx={{ borderRadius: '20px', margin: '8px auto 0' }}
        />

        <Skeleton
          variant="rectangular"
          height={'56px'}
          width={'100%'}
          sx={{ borderRadius: '20px', margin: '24px auto 0' }}
        />

        <Box
          sx={{
            width: '100%',
            mt: '26px',
            display: 'flex',
            flexDirection: 'column',
            background: Colors.white,
            padding: '24px 24px 39px 24px',
            borderRadius: '20px',
          }}
        >
          <Box sx={{ width: '100%', pb: '15px', display: 'flex', borderBottom: '1px dashed #F2F2F2' }}>
            <Skeleton sx={{ height: '21px', width: '120px' }} />
          </Box>
          <Skeleton sx={{ height: '21px', width: '30px', mt: '24px' }} />
          <Skeleton animation="wave" sx={{ height: '46px', borderRadius: '10px' }} />
          <Box sx={{ width: '25%' }}>
            <Skeleton sx={{ height: '18px', width: '110px' }} />
          </Box>
          <Box sx={{ width: '100%' }}>
            <Skeleton animation="wave" sx={{ height: '98px' }} />
          </Box>
          <Skeleton
            variant="rectangular"
            height={'37px'}
            width={'100%'}
            sx={{ borderRadius: '100px', margin: '40px auto 0', maxWidth: '296px' }}
          />
        </Box>
      </Grid>
    ) : (
      <Grid container display={'flex'} wrap={'nowrap'}>
        <Grid
          sx={{
            position: 'relative',
            width: '100%',
            marginRight: '24px',
            background: { xs: 'none', md: Colors.white },
            padding: { xs: 0, md: '24px 24px 39px 24px' },
            borderRadius: '20px',
          }}
        >
          <Skeleton variant="rectangular" height={'23px'} width={'190px'} sx={{ borderRadius: '20px' }} />

          <Skeleton
            variant="rectangular"
            height={'56px'}
            width={'285px'}
            sx={{ borderRadius: '20px', margin: '8px auto 0' }}
          />

          <Box sx={{ width: '100%', mt: '26px', display: 'flex', borderBottom: '1px dashed #F2F2F2' }}>
            <Box sx={{ width: '25%', pt: '20px' }}>
              <Skeleton sx={{ height: '18px', width: '110px' }} />
            </Box>
            <Box sx={{ width: '75%' }}>
              <Skeleton animation="wave" sx={{ height: '98px' }} />
            </Box>
          </Box>

          <Box sx={{ width: '100%', mt: '26px', display: 'flex' }}>
            <Box sx={{ width: '25%', pt: '20px' }}>
              <Skeleton sx={{ height: '18px', width: '110px' }} />
            </Box>
            <Box sx={{ width: '75%' }}>
              <Skeleton sx={{ height: '21px', width: '30px', ml: '12px' }} />
              <Skeleton animation="wave" sx={{ height: '46px', borderRadius: '10px' }} />

              <Box sx={{ mt: '24px' }}>
                <Skeleton sx={{ height: '21px', width: '30px', ml: '12px' }} />
                <Skeleton animation="wave" sx={{ height: '46px', borderRadius: '10px' }} />
              </Box>
            </Box>
          </Box>
          <Skeleton
            variant="rectangular"
            height={'37px'}
            width={'200px'}
            sx={{ borderRadius: '100px', margin: '40px auto 0' }}
          />
        </Grid>
        <Grid
          sx={{
            width: '276px',
          }}
        >
          <Skeleton variant="rectangular" height={'627px'} width={'276px'} sx={{ borderRadius: '20px' }} />
        </Grid>
      </Grid>
    );
  };

  return (
    <IonPage>
      <CustomNewHeader
        breadcrumb={{
          Icon: GoalIcon,
          list: [
            { label: "Your Employees' Challenges", url: '/page/employees-challenges' },
            ...challengeStatusBreadcrumbItem,
            ...templatesBreadcrumbItem,
            ...detailsPageBreadcrumbItem,
            { label: `Edit: ${challengeTitle}`, url: '/' },
          ],
        }}
        inProgress={challengeLoading}
        label={challengeTitle}
      ></CustomNewHeader>

      <IonContent style={{ '--padding-bottom': '36px' }} scrollEvents={true}>
        <Container maxWidth={'lg'}>
          {challengeLoading ? (
            <SkeletonContent />
          ) : (
            challenge && (
              <Grid container display={'flex'} wrap={'nowrap'}>
                <Grid sx={{ position: 'relative', width: '100%', marginRight: mdDown ? 0 : '24px' }}>
                  <Grid
                    sx={{
                      background: { xs: 'none', md: Colors.white },
                      boxShadow: { xs: 'none', md: '5px 0px 15px rgba(108, 123, 132, 0.05)' },
                      borderRadius: { xs: 0, md: '20px' },
                      padding: { xs: 0, md: '24px 24px 39px 24px' },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '18px',
                        color: Colors.gray[700],
                        fontWeight: 900,
                        mb: '8px',
                        display: { xs: 'none', md: 'block' },
                      }}
                    >
                      Template information
                    </Typography>

                    <CustomStepper activeStep={activeStep} sx={{ mt: { xs: '20px', md: 0 } }} onClick={handleStep} />

                    {mdDown && (
                      <Box sx={{ mt: '19px' }}>
                        <CollapseBox title={'Challenge characteristics'} borderColor={Colors.green[500]}>
                          <Box sx={{ mt: '12px' }}>
                            <CharacteristicSection />
                          </Box>
                        </CollapseBox>
                      </Box>
                    )}
                    <Grid
                      sx={{
                        mt: { xs: '24px', md: 0 },
                        backgroundColor: Colors.white,
                        borderRadius: { xs: '20px', md: 0 },
                        padding: { xs: '24px 16px 40px 16px', md: 0 },
                      }}
                    >
                      <Grid
                        sx={{
                          paddingBottom: '15px',
                          borderBottom: '1px dashed #F2F2F2',
                          display: { xs: 'block', md: 'none' },
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 900,
                            fontSize: 16,
                            lineHeight: 1.4,
                            color: 'text.primary',
                          }}
                        >
                          Template information
                        </Typography>
                      </Grid>
                      {activeStep === 1 && (
                        <FirstForm
                          form={form}
                          isAvailableToSecondForm={isAvailableToSecondForm}
                          onClick={handleNext}
                          onUpload={handleUpdateImage}
                          handleChangeShortDescription={handleChangeShortDescription}
                          handleChangeTitle={handleChangeTitle}
                        />
                      )}
                      {activeStep === 2 && (
                        <SecondForm
                          form={form}
                          isAvailableToThirdForm={isAvailableToThirdForm}
                          challenge={challenge}
                          handleChangeNumberOfDays={handleChangeNumberOfDays}
                          handleChangeDailyGoal={handleChangeDailyGoal}
                          handleUpdateTotalGoal={handleUpdateTotalGoal}
                          handleChangeAwardTitle={handleUpdateAwardTitle}
                          handleChangeAwardType={handleUpdateAwardType}
                          handleChangeNumberOfPoints={handleChangeNumberOfPoints}
                          onUpload={handleUpdateBadgeImage}
                          onBack={handleBack}
                          onContinue={handleNext}
                        />
                      )}
                      {activeStep === 3 && (
                        <ThirdForm
                          form={form}
                          challenge={challenge}
                          range={range}
                          isAvailablePublish={isAvailablePublish}
                          handleChangeTrackingType={handleChangeTrackingType}
                          handleChangeTeamSize={handleChangeTeamSize}
                          handleChangeWinners={handleChangeWinners}
                          handleChangeSlackUrl={handleChangeSlackUrl}
                          handleChangeRange={handleChangeRange}
                          onBack={handleBack}
                          onPublish={async () => {
                            if (isPublish) return;
                            await Publish();
                            handleStep(1);
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                {!mdDown && (
                  <Grid>
                    <Grid
                      sx={{
                        width: '276px',
                        padding: '24px 16px',
                        backgroundColor: Colors.white,
                        borderRadius: '20px',
                        borderTop: `5px solid ${Colors.green[500]}`,
                        boxShadow: '5px 0px 15px rgba(108, 123, 132, 0.05)',
                      }}
                    >
                      <Typography sx={{ fontSize: '16px', color: Colors.gray[700], fontWeight: 700, mb: '16px' }}>
                        Challenge characteristics
                      </Typography>
                      <CharacteristicSection />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )
          )}
        </Container>
      </IonContent>
    </IonPage>
  );
};
