import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  deleteWearableRequest,
  getWearableInfoRequest,
  getWearableSessionRequest,
  postNotificationRequest,
  putWearableRequest,
} from '../../lib/api/http/requests/wearable';
import { handleBackendError } from '../../utils/handleBackendError';
import { completeTutorialRequest } from '../../lib/api/http/requests/profile';
import { Capacitor } from '@capacitor/core';
import { Connections, Terra } from 'terra-capacitor';
import { InAppBrowser, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser';
import { trackEvent } from '../../utils/analytics';
import { ANALYTICS_PAGE } from '../../models/enum/ANALYTICS_PAGE';
import { initializeTerra } from '../../services/terraServices';
import { RootState } from '../store';

export const connectWearable = createAsyncThunk('app/connectWearable', async (params: { uuid: string }, thunkAPI) => {
  try {
    thunkAPI.dispatch(setWearableLoading(true));

    const redirectUrl = `${process.env.REACT_APP_REDIRECT_URL}/page/your-profile`;
    const res = await getWearableSessionRequest(redirectUrl);

    if (res.data.url) {
      if (!Capacitor.isNativePlatform()) {
        window.location = res.data.url;
      } else {
        const options: InAppBrowserOptions = {
          zoom: 'no',
          location: 'no',
          fullscreen: 'yes',
          hidenavigationbuttons: 'yes',
          cleardata: 'no',
          clearcache: 'no',
          clearsessioncache: 'no',
          usewkwebview: 'yes',
          closebuttoncaption: 'Cancel',
        };
        const browser = InAppBrowser.create(res.data.url, '_blank', options);
        const listener = browser.on('loadstart').subscribe((event: any) => {
          const callback = encodeURI(event.url);
          //Check the redirect uri
          if (callback.indexOf(redirectUrl) > -1) {
            thunkAPI.dispatch(setWearableLoading(true));

            listener.unsubscribe();
            browser.close();

            const urlSearchParams = new URLSearchParams(callback.substring(callback.indexOf('?')));
            const searchParams = Object.fromEntries(urlSearchParams.entries());
            if (
              searchParams.hasOwnProperty('user_id') &&
              searchParams.hasOwnProperty('resource') &&
              !searchParams.hasOwnProperty('reason')
            ) {
              thunkAPI.dispatch(
                updateWearable({ userId: searchParams.user_id, provider: searchParams.resource, uuid: params.uuid }),
              );
              trackEvent('wearable_connected', {
                page: ANALYTICS_PAGE.PROFILE,
                user_id: searchParams.user_id,
                provider: searchParams.resource,
              });
            }
          }
        });
      }
    }
  } catch (error) {
    handleBackendError(error, 'Connection attempt failed');
  } finally {
    thunkAPI.dispatch(setWearableLoading(false));
  }
});

export const getWearableInfo = createAsyncThunk(
  'app/getWearableInfo',
  async (params: { uuid: string; initTerra?: boolean }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsInitSDK(true));
      const res = await getWearableInfoRequest(params.uuid);
      if (res.data?.connected_device) {
        const userId = res.data?.user_id;
        if (Capacitor.getPlatform() === 'ios' && params.initTerra && res.data?.provider?.toLowerCase() === 'apple') {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const devId = process.env.REACT_APP_TERRA_DEV_ID!;
          await Terra.initTerra({
            devId,
            referenceId: params.uuid,
          });

          console.log(`Initializing Terra with user_id: ${userId}, lastUpdated: ${res.data?.last_webhook_update}`);
          const terraUserId = await initializeTerra(Connections.APPLE_HEALTH, res.data?.last_webhook_update);
          if (!terraUserId.userId && terraUserId.error) {
            thunkAPI.dispatch(openToast({ type: 'error', text: terraUserId.error }));
          }
        }

        //Terra ANDROID GOOGLE/SAMSUNG
        if (Capacitor.getPlatform() === 'android' && params.initTerra) {
          // if (res.data?.provider?.toLowerCase() === 'google') {
          //   // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          //   const devId = process.env.REACT_APP_TERRA_DEV_ID!;
          //   await Terra.initTerra({
          //     devId,
          //     referenceId: params.uuid,
          //   });

          //   console.log(`Initializing Terra with user_id: ${userId}, lastUpdated: ${res.data?.last_webhook_update}`);
          //   const userData = await initializeTerra(Connections.GOOGLE, res.data?.last_webhook_update);
          //   if (!userData.userId && userData.error) {
          //     thunkAPI.dispatch(openToast({ type: 'error', text: userData.error }));
          //   }
          // }
          if (res.data?.provider?.toLowerCase() === 'samsung' && params.initTerra) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const devId = process.env.REACT_APP_TERRA_DEV_ID!;
            await Terra.initTerra({
              devId,
              referenceId: params.uuid,
            });

            console.log(`Initializing Terra with user_id: ${userId}, lastUpdated: ${res.data?.last_webhook_update}`);
            const userData = await initializeTerra(Connections.SAMSUNG, res.data?.last_webhook_update);
            if (!userData.userId && userData.error) {
              thunkAPI.dispatch(openToast({ type: 'error', text: userData.error }));
            }
          }
        }
        thunkAPI.dispatch(setWearableConnected(true));
      } else thunkAPI.dispatch(setWearableConnected(false));
    } catch (error) {
      console.log(error);
    } finally {
      thunkAPI.dispatch(setIsInitSDK(false));
    }
  },
  {
    condition: (_, { getState }) => {
      const isInit = (getState() as RootState).app.isInitSDK;
      if (isInit) {
        return false;
      }
    },
  },
);

export const disconnectWearable = createAsyncThunk('app/disconnectWearable', async (_, thunkAPI) => {
  try {
    thunkAPI.dispatch(setWearableLoading(true));
    await deleteWearableRequest();
    thunkAPI.dispatch(setWearableConnected(false));
    trackEvent('wearable_disconnected', {
      page: ANALYTICS_PAGE.PROFILE,
    });
  } catch (error) {
    handleBackendError(error, 'Failed to disconnect the wearable');
  } finally {
    thunkAPI.dispatch(setWearableLoading(false));
  }
});

export const updateWearable = createAsyncThunk(
  'app/updateWearable',
  async (params: { userId: string; provider: string; uuid: string }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsInitSDK(true));
      thunkAPI.dispatch(setWearableLoading(true));

      let userId: string = params.userId;

      //Terra AppleHealth
      if (params.provider.toLowerCase() === 'apple' && Capacitor.getPlatform() === 'ios') {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const devId = process.env.REACT_APP_TERRA_DEV_ID!;
        const data = await Terra.initTerra({
          devId,
          referenceId: params.uuid,
        });

        const terraUserId: any = await initializeTerra(Connections.APPLE_HEALTH);
        if (!terraUserId.userId && terraUserId.error) {
          thunkAPI.dispatch(openToast({ type: 'error', text: terraUserId.error }));
          return;
        }
        userId = terraUserId.userId!;

        console.log(`Initializing Terra with user_id: ${userId}`);
      }

      //Terra ANDROID GOOGLE/SAMSUNG
      if (Capacitor.getPlatform() === 'android') {
        // if (params.provider.toLowerCase() === 'google') {
        //   // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        //   const devId = process.env.REACT_APP_TERRA_DEV_ID!;
        //   const data = await Terra.initTerra({
        //     devId,
        //     referenceId: params.uuid,
        //   });

        //   const userData: any = await initializeTerra(Connections.GOOGLE);
        //   if (!userData.userId && userData.error) {
        //     thunkAPI.dispatch(openToast({ type: 'error', text: userData.error }));
        //     return;
        //   }
        //   userId = userData.userId!;

        //   console.log(`Initializing Terra with user_id: ${userId}`);
        // }
        if (params.provider.toLowerCase() === 'samsung') {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const devId = process.env.REACT_APP_TERRA_DEV_ID!;
          const data = await Terra.initTerra({
            devId,
            referenceId: params.uuid,
          });

          const userData: any = await initializeTerra(Connections.SAMSUNG);
          if (!userData.userId && userData.error) {
            thunkAPI.dispatch(openToast({ type: 'error', text: userData.error }));
            return;
          }
          userId = userData.userId!;

          console.log(`Initializing Terra with user_id: ${userId}`);
        }
      }
      console.log(userId);
      if (!userId || userId === 'None') {
        thunkAPI.dispatch(openToast({ type: 'error', text: 'Cannot connect a wearable with an incorrect user ID' }));
        return;
      }
      await putWearableRequest(userId, params.provider);
      thunkAPI.dispatch(setWearableConnected(true));
      thunkAPI.dispatch(openToast({ text: 'Wearable Connected' }));
    } catch (error) {
      handleBackendError(error, 'Failed update wearable');
    } finally {
      thunkAPI.dispatch(setWearableLoading(false));
      thunkAPI.dispatch(setIsInitSDK(false));
    }
  },
  {
    condition: (_, { getState }) => {
      const isInit = (getState() as RootState).app.isInitSDK;
      if (isInit) {
        return false;
      }
    },
  },
);

export const syncNotificationToken = createAsyncThunk(
  'app/syncNotificationToken',
  async (params: { fcmToken: string }) => {
    try {
      await postNotificationRequest(params.fcmToken);
    } catch (error) {
      handleBackendError(error, 'Failed to sync notification token to Wellics™');
    }
  },
);

export const completeTutorial = createAsyncThunk('app/completeTutorial', async () => {
  try {
    await completeTutorialRequest();
  } catch (error) {
    handleBackendError(error, 'Tutorial update failed');
  }
});

export interface AppState {
  isAdmin: boolean;
  isInitSDK: boolean;
  wifiConnected: boolean;
  headerLoading: boolean;
  wearableLoading: boolean;
  wearableConnected: boolean;
  authToken: string | null;
  refreshToken: string | null;
  notLoggined?: boolean;
  notification: {
    open: boolean;
    text: string;
    category: string;
    challengeUuid: string;
    challengeActionSource: string;
    isUser: boolean;
    isParticipant: boolean;
  };
  toast: {
    open: boolean;
    text: string;
    type: 'success' | 'error';
  };
  showCustomFab: boolean;
  handleNotificationCallback?: () => void;
}

const initialState: AppState = {
  isAdmin: true,
  isInitSDK: false,
  wifiConnected: true,
  headerLoading: false,
  wearableLoading: false,
  wearableConnected: false,
  authToken: null,
  refreshToken: null,
  notification: {
    open: false,
    text: '',
    category: '',
    challengeUuid: '',
    challengeActionSource: '',
    isUser: false,
    isParticipant: false,
  },
  toast: {
    open: false,
    text: '',
    type: 'success',
  },
  showCustomFab: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    openToast: (state, action: PayloadAction<{ text: string; type?: 'success' | 'error' }>) => {
      state.toast.open = true;
      state.toast.text = action.payload.text;
      state.toast.type = action.payload.type || 'success';
    },
    openNotification: (
      state,
      action: PayloadAction<{
        text: string;
        category: string;
        challengeUuid?: string;
        challengeActionSource?: string;
        isUser: boolean;
        isParticipant: boolean;
      }>,
    ) => {
      state.notification.open = true;
      state.notification.text = action.payload.text ?? '';
      state.notification.category = action.payload.category ?? '';
      state.notification.challengeUuid = action.payload.challengeUuid ?? '';
      state.notification.challengeActionSource = action.payload.challengeActionSource ?? '';
      state.notification.isUser = action.payload.isUser ?? false;
      state.notification.isParticipant = action.payload.isParticipant ?? false;
    },
    setWifiConnected: (state, action) => {
      state.wifiConnected = action.payload;
    },
    setAuthToken: (state, action) => {
      state.authToken = action.payload;
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },
    closeToast: (state) => {
      state.toast.open = false;
      state.toast.text = '';
    },
    closeNotification: (state) => {
      state.notification.open = false;
      state.notification.text = '';
      state.notification.category = '';
      state.notification.challengeUuid = '';
      state.notification.isUser = false;
      state.notification.isParticipant = false;
    },
    setHeaderLoading: (state, action: PayloadAction<boolean>) => {
      state.headerLoading = action.payload;
    },
    setWearableLoading: (state, action: PayloadAction<boolean>) => {
      state.wearableLoading = action.payload;
    },
    setWearableConnected: (state, action: PayloadAction<boolean>) => {
      state.wearableConnected = action.payload;
    },
    setIsInitSDK: (state, action: PayloadAction<boolean>) => {
      state.isInitSDK = action.payload;
    },
    setShowCustomFab: (state, action: PayloadAction<boolean>) => {
      state.showCustomFab = action.payload;
    },
    setHandleNotificationCallback: (state, action: PayloadAction<(() => void) | undefined>) => {
      state.handleNotificationCallback = action.payload;
    },
    setNotLoggined: (state, action: PayloadAction<boolean>) => {
      state.notLoggined = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  openToast,
  openNotification,
  setWifiConnected,
  setAuthToken,
  setRefreshToken,
  closeToast,
  closeNotification,
  setHeaderLoading,
  setWearableLoading,
  setWearableConnected,
  setIsInitSDK,
  setShowCustomFab,
  setHandleNotificationCallback,
  setNotLoggined,
} = appSlice.actions;

export default appSlice.reducer;
