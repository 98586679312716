import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  styled,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CustomTextField from '../common/CustomTextField';
import CloseIcon from '@mui/icons-material/Close';
import { validate } from 'email-validator';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Delete';
import { ReactComponent as BambooHRIcon } from '../../assets/svg/bamboohr.svg';
import { FileRejection, useDropzone } from 'react-dropzone';
import { ReactComponent as UserIcon } from '../../assets/svg/user.svg';
import { Link } from '@mui/icons-material';
import { Clipboard } from '@capacitor/clipboard';
import { dispatch } from '../../index';
import { openToast } from '../../redux/slices/appSlice';
import CustomSubHeader from '../structure/CustomSubHeader';

const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '4px',
  borderWidth: 2,
  borderRadius: '8px',
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#656565',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  minHeight: 64,
  position: 'relative',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const StyledButton = styled(Button)({
  minWidth: '144px',
  borderRadius: '999px',
  fontWeight: 'bold',
  paddingLeft: '24px',
  paddingRight: '24px',
});

interface BambooHRModalInterface {
  open: boolean;
  onClose: () => void;
  handleBambooHRSubmit: (clientDomain: string, apiKey: string) => void;
}

const BambooHRModal: FC<BambooHRModalInterface> = ({ open, onClose, handleBambooHRSubmit }) => {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [values, setValues] = useState<{ clientDomain: string; apiKey: string }>({ clientDomain: '', apiKey: '' });
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!open) {
      setValues({ clientDomain: '', apiKey: '' });
      setError(false);
    }
  }, [open]);

  const isDisabled = !values.clientDomain || !values.apiKey || error;

  const handleChangeInput = (e: React.ChangeEvent<{ name: string; value: any }>) => {
    if (e.target.name === 'apiKey') {
      setValues((val) => ({ ...val, apiKey: e.target.value }));
      if (e.target.value) {
        if (e.target.value?.length !== 40 || !e.target.value.match('^[0-9a-fA-F]+$')) {
          setError(true);
          return;
        }
      }
      setError(false);
    } else if (e.target.name === 'clientDomain') {
      setValues((val) => ({ ...val, clientDomain: e.target.value }));
    }
  };

  const handleSubmitFunction = () => {
    handleBambooHRSubmit(values.clientDomain, values.apiKey);
  };

  return (
    <Dialog
      maxWidth={'sm'}
      open={open}
      onClose={onClose}
      sx={{ textAlign: 'center' }}
      PaperProps={{ style: { borderRadius: '8px', margin: '8px' } }}
    >
      <DialogTitle sx={{ minWidth: xsDown ? undefined : 360, background: '#F0F2F3' }}>
        <Typography sx={{ fontSize: 24, fontWeight: 'bold', textAlign: 'left' }}>{'BambooHR'}</Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: xsDown ? undefined : '460px',
          background: '#F0F2F3',
          borderRadius: '8px',
        }}
      >
        <Grid container>
          <Grid item xs={12} sx={{ pt: 4, mb: 2 }}>
            <CustomTextField
              name="clientDomain"
              value={values.clientDomain}
              setValue={handleChangeInput}
              label={'Client Domain'}
            />
          </Grid>
          <Grid item xs={12} sx={{ pt: 4, mb: 2 }}>
            <CustomTextField
              error={error}
              name="apiKey"
              value={values.apiKey}
              setValue={handleChangeInput}
              label={'API Key'}
            />
          </Grid>
          <Grid item xs={12} container justifyContent={'center'} sx={{ mt: 2 }}>
            <Grid item>
              <StyledButton variant="contained" onClick={handleSubmitFunction} disabled={isDisabled}>
                {'Submit'}
              </StyledButton>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

interface InvitationModalInterface {
  open: boolean;
  onClose: () => void;
  handleFunction: (emailList: string[], file: any) => void;
  handleBambooHRSubmit: (clientDomain: string, apiKey: string) => void;
  maxUsers: number | null;
  remainingUsers: number | null;
  invitationLink?: string;
}

const InvitationModal: FC<InvitationModalInterface> = ({
  open,
  onClose,
  handleFunction,
  handleBambooHRSubmit,
  maxUsers,
  remainingUsers,
  invitationLink,
}) => {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [inputValue, setInputValue] = useState<string>('');
  const [files, setFiles] = useState<any[]>([]);
  const [emailList, setEmailList] = useState<string[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [bambooHrOpen, setBambooHrOpen] = useState<boolean>(false);

  useEffect(() => {
    setInputValue('');
    setEmailList([]);
    setFiles([]);
  }, [open]);

  const onDrop = useCallback((acceptedFiles: any) => {
    setErrorMsg('');
    setFiles([...acceptedFiles]);
  }, []);

  const isDisabled =
    remainingUsers === null
      ? false
      : remainingUsers <= 0 || (emailList.length === 0 && files.length === 0 && inputValue.length === 0);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: ['text/csv', 'application/vnd.ms-excel'],
    maxSize: 1048500,
    onDrop,
    onDropRejected: (e: FileRejection[]) => handleReject(e),
    multiple: false,
    disabled: emailList.length > 0 || !!inputValue || (remainingUsers !== null && remainingUsers <= 0),
  });

  const handleReject = (e: FileRejection[]) => {
    if (e.length > 0) {
      const firstFile = e[0];
      if (firstFile.errors.length > 0) {
        setErrorMsg(firstFile.errors[0].message);
        console.log('Rejected file, ', firstFile.file);
      }
    }
  };

  const style: any = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  const fileNames = files.map((file: any) => <li key={file.path}>{file.path}</li>);

  const handleClose = () => {
    onClose();
  };

  const handleChangeInput = (e: React.ChangeEvent<{ name: string; value: any; type: any }>) => {
    setInputValue(e.target.value);
    setError(false);
  };

  const handleEnter = (event: any) => {
    if (event.key === 'Enter') {
      handleExpandEmailList();
    }
  };

  const handleExpandEmailList = () => {
    if (validate(inputValue)) {
      setEmailList([...emailList, inputValue]);
      setInputValue('');
    } else {
      setError(true);
    }
  };

  const handleSendFunction = () => {
    if (files.length) {
      if (inputValue === '') {
        handleFunction(emailList, files[0]);
      } else if (validate(inputValue)) {
        if (remainingUsers === null || remainingUsers - emailList.length > 0) {
          handleFunction([...emailList, inputValue], files[0]);
        } else {
          handleFunction(emailList, files[0]);
        }
      }
      handleClose();
    } else if (inputValue === '') {
      handleFunction(emailList, files.length ? files[0] : null);
      handleClose();
    } else if (validate(inputValue)) {
      if (remainingUsers === null || remainingUsers - emailList.length > 0) {
        handleFunction([...emailList, inputValue], files.length ? files[0] : null);
      } else {
        handleFunction(emailList, files.length ? files[0] : null);
      }
      handleClose();
    } else {
      setError(true);
    }
  };

  const openBambooHr = () => {
    setBambooHrOpen(true);
  };

  const closeBambooHr = () => {
    setBambooHrOpen(false);
  };

  const submitBambooHr = (clientDomain: string, apiKey: string) => {
    setBambooHrOpen(false);
    onClose();
    handleBambooHRSubmit(clientDomain, apiKey);
  };

  const removePill = (email: string) => {
    setEmailList(emailList.filter((e) => e !== email));
  };

  const getPill = (email: string) => {
    const actualString = validate(email) ? email.split('@')[0] : email;
    return (
      <Box sx={{ border: '1px solid #E6D2AA', whiteSpace: 'nowrap', borderRadius: '99px', padding: '4px 8px' }}>
        <Grid container wrap={'nowrap'} alignItems={'center'}>
          <Grid item>
            <Typography sx={{ color: '#E6D2AA' }}>{actualString}</Typography>
          </Grid>
          <Grid item sx={{ ml: 1, cursor: 'pointer' }} container alignItems={'center'}>
            <CloseIcon style={{ fill: '#E6D2AA', width: 16, height: 16 }} onClick={() => removePill(email)} />
          </Grid>
        </Grid>
      </Box>
    );
  };

  const copyInvitationLink = () => {
    Clipboard.write({
      url: invitationLink,
      label: 'Wellics invitation link',
    }).then(() => {
      dispatch(openToast({ text: 'Invitation link copied to clipboard!', type: 'success' }));
    });
  };

  return (
    <>
      <Dialog
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
        sx={{ textAlign: 'center' }}
        PaperProps={{ style: { borderRadius: '8px', margin: '8px' } }}
      >
        <DialogTitle
          sx={{
            minWidth: xsDown ? undefined : 360,
            background: '#F0F2F3',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <CustomSubHeader
            label={'INVITE USERS'}
            tooltip={
              <>
                {'Invite your Users '}
                <br />
                {'(1) via '}
                <b>email</b>
                {' by typing addresses in the relevant box, or '}
                <br />
                {'(2) upload a '}
                <b>CSV</b>
                {' file for massive invitations of Users and Admins, or '}
                <br />
                {'(3) copy the '}
                <b>self-registration</b>
                {' link to share with your team.'}
              </>
            }
          />
          {/* TODO temporarily commented WEl-183 */}
          {/*{remainingUsers !== null ? (*/}
          {/*  <Grid*/}
          {/*    item*/}
          {/*    xs={12}*/}
          {/*    display={'flex'}*/}
          {/*    justifyContent={'center'}*/}
          {/*    alignItems={'flex-start'}*/}
          {/*    gap={0.5}*/}
          {/*    sx={{ opacity: 0.5 }}*/}
          {/*  >*/}
          {/*    <Typography*/}
          {/*      variant={'caption'}*/}
          {/*      sx={{*/}
          {/*        fontWeight: 'bold',*/}
          {/*        ...(remainingUsers - emailList.length <= 0 ? { color: theme.palette.error.main } : null),*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      {`${remainingUsers - emailList.length} out of ${maxUsers}`}*/}
          {/*    </Typography>*/}
          {/*    <UserIcon width={16} height={16} />*/}
          {/*  </Grid>*/}
          {/*) : null}*/}
        </DialogTitle>
        <DialogContent
          sx={{
            minHeight: '360px',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: xsDown ? undefined : '460px',
            background: '#F0F2F3',
            borderRadius: '8px',
          }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{ pt: 4, mb: 1 }}
              container
              wrap={'nowrap'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Grid
                item
                style={{ flex: 1 }}
                onKeyUp={handleEnter}
                position={'relative'}
                display={'flex'}
                alignItems={'center'}
              >
                <CustomTextField
                  error={error}
                  name="email"
                  value={inputValue}
                  setValue={handleChangeInput}
                  label={'via Email'}
                  inputProps={{
                    disabled: files.length > 0 || (remainingUsers !== null && remainingUsers - emailList.length <= 0),
                    InputProps: {
                      sx: {
                        opacity:
                          files.length > 0 || (remainingUsers !== null && remainingUsers - emailList.length <= 0)
                            ? 0.3
                            : 1,
                      },
                    },
                  }}
                />
                <Fab
                  onClick={handleExpandEmailList}
                  disabled={files.length > 0 || (remainingUsers !== null && remainingUsers - emailList.length <= 0)}
                  size={'small'}
                  color="secondary"
                  aria-label="add"
                  sx={{
                    position: 'absolute',
                    right: 8,
                    height: 24,
                    minHeight: 24,
                    width: 24,
                    minWidth: 24,
                    boxShadow: 'none',
                    ':active': {
                      boxShadow: 'none',
                    },
                  }}
                >
                  <AddIcon fontSize={'inherit'} sx={{ color: theme.palette.common.white }} />
                </Fab>
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={1}>
              {emailList.map((email, index) => (
                <Grid item key={index}>
                  {getPill(email)}
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12}>
              <Box display={'flex'} justifyContent={'space-between'} marginTop={1}>
                <Typography sx={{ fontWeight: 'bold', textAlign: 'start', marginLeft: '15px' }}>or CSV</Typography>
                <a
                  href="/invite_template.csv"
                  download="template.csv"
                  style={{
                    cursor: 'pointer',
                    textDecoration: 'none',
                  }}
                >
                  <Typography color={'primary'} sx={{ fontSize: 12, fontWeight: 'bold' }}>
                    Sample CSV
                  </Typography>
                </a>
              </Box>

              <Box
                sx={{
                  opacity:
                    emailList.length > 0 || !!inputValue || (remainingUsers !== null && remainingUsers <= 0) ? 0.3 : 1,
                }}
              >
                <div {...getRootProps({ style })}>
                  <input {...getInputProps()} />
                  {files.length ? (
                    <>
                      <Typography variant={'body1'}>{fileNames}</Typography>
                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation();
                          setFiles([]);
                        }}
                        size={'small'}
                        sx={{ position: 'absolute', top: 0, right: 0 }}
                      >
                        <RemoveIcon fontSize={'inherit'} />
                      </IconButton>
                    </>
                  ) : errorMsg ? (
                    <Typography variant={'body1'} color={'error'}>
                      {errorMsg}
                    </Typography>
                  ) : (
                    <Typography variant={'body1'}>Upload CSV</Typography>
                  )}
                </div>
              </Box>
            </Grid>

            <Grid item xs={12} container justifyContent={'center'}>
              <Grid item>
                <StyledButton variant="contained" onClick={handleSendFunction} disabled={isDisabled}>
                  {'Invite users'}
                </StyledButton>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography sx={{ fontSize: 14, fontWeight: 'bold', textAlign: 'center', opacity: 0.5 }}>
                {'or'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <StyledButton
                color={'primary'}
                variant={'contained'}
                sx={{
                  opacity: remainingUsers !== null && remainingUsers - emailList.length <= 0 ? 0.3 : 1,
                }}
                onClick={copyInvitationLink}
                startIcon={<Link />}
                disabled={remainingUsers !== null && remainingUsers - emailList.length <= 0}
              >
                {'Self-registration'}
              </StyledButton>
            </Grid>
            {/* WEL-583 */}
            {/*<Grid item xs={12}>*/}
            {/*  <StyledButton*/}
            {/*    color={'success'}*/}
            {/*    variant={'contained'}*/}
            {/*    sx={{*/}
            {/*      opacity: remainingUsers !== null && remainingUsers - emailList.length <= 0 ? 0.3 : 1,*/}
            {/*    }}*/}
            {/*    onClick={openBambooHr}*/}
            {/*    startIcon={*/}
            {/*      <SvgIcon>*/}
            {/*        <BambooHRIcon />*/}
            {/*      </SvgIcon>*/}
            {/*    }*/}
            {/*    disabled={remainingUsers !== null && remainingUsers - emailList.length <= 0}*/}
            {/*  >*/}
            {/*    {'BambooHR invite'}*/}
            {/*  </StyledButton>*/}
            {/*</Grid>*/}
          </Grid>
        </DialogContent>
      </Dialog>
      <BambooHRModal open={bambooHrOpen} onClose={closeBambooHr} handleBambooHRSubmit={submitBambooHr} />
    </>
  );
};

export default InvitationModal;
