import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { IMetric } from '../../models/Metrics';
import { RootState } from '../../redux/store';
import StyledButton from '../common/Button';
import CustomCalendar from '../common/CustomCalendar';
import CustomDropdown from '../common/CustomDropdown';
import InputField from '../common/CustomTextField';
import { AdminRoutes } from '../../models/AdminRoutes';
import { useIonRouter } from '@ionic/react';

interface Props {
  date: any;
  setDate: any;
  selectedMetric: string;
  setSelectedMetric: (selected: string) => void;
  newMetric: boolean;
  setNewMetric: (newMetric: boolean) => void;
}

const MetricsModalHead: FC<Props> = ({ date, setDate, selectedMetric, setSelectedMetric, newMetric, setNewMetric }) => {
  const router = useIonRouter();
  const { predefinedMetric } = useSelector((state: RootState) => state.journeyPage.data.metricsModal);
  const { metricsModalData } = useSelector((state: RootState) => state.journeyPage.data);

  const handleNewMetric = () => {
    setNewMetric(!newMetric);
  };

  const extractNewButtons = () => {
    if (newMetric) {
      return (
        <>
          <StyledButton
            inputProps={{ variant: 'contained', color: 'primary', sx: { width: '100%', fontWeight: 'bold' } }}
            text="Choose from our metrics"
            onClick={handleNewMetric}
          />
        </>
      );
    } else {
      return (
        <>
          <StyledButton
            inputProps={{ variant: 'contained', color: 'secondary', sx: { width: '100%', fontWeight: 'bold' } }}
            text="Create your own metric"
            onClick={handleNewMetric}
          />
        </>
      );
    }
  };

  const extractSelectors = () => {
    if (predefinedMetric === '' && !newMetric) {
      const blueButton = !AdminRoutes.includes(router.routeInfo.pathname);
      const list = blueButton
        ? metricsModalData?.metrics
            ?.filter((metric: IMetric) => metric.metric_characteristics.tracking_type !== 'AUTO')
            .map((metric: IMetric) => metric.title)
        : metricsModalData?.metrics?.map((metric: IMetric) => metric.title);

      return (
        <>
          <Box display="flex" justifyContent="space-between" gap={1} flexWrap="wrap">
            <Box sx={{ flexGrow: 1 }}>
              <CustomDropdown
                width={'100%'}
                list={list}
                placeholder="Select a metric"
                selected={selectedMetric}
                setSelected={setSelectedMetric}
              />
            </Box>
            <Box>
              <CustomCalendar date={date} setDate={setDate} disableFuture />
            </Box>
          </Box>
        </>
      );
    } else if (predefinedMetric !== '') {
      return (
        <>
          <Box display="flex" flexWrap="wrap" justifyContent="space-between">
            <Box>
              <InputField
                label=""
                name="predefinedMetric"
                bold
                value={predefinedMetric}
                inputProps={{ disabled: true }}
              />
            </Box>
            <Box>
              <CustomCalendar date={date} setDate={setDate} disableFuture />
            </Box>
          </Box>
        </>
      );
    }
  };

  return (
    <Box width="100%" display="flex" flexDirection="column">
      {predefinedMetric === '' && extractNewButtons()}
      {predefinedMetric === '' && !newMetric && (
        <Box sx={{ py: 1, textAlign: 'center' }}>
          <Typography>or</Typography>
        </Box>
      )}
      {extractSelectors()}
    </Box>
  );
};

export default MetricsModalHead;
