import React from 'react';
import Grid from '@mui/material/Grid';
import { Box, Typography } from '@mui/material';
import { Colors } from '../../../theme/colors';
import { SxProps } from '@mui/system';
import CustomSwitch from '../../../components/features/CustomSwitch';
import CustomCheckbox from '../../../components/features/CustomCheckbox';
import { FieldsName } from '../hooks/useSettingsHandle';
import { ChallengeNotificationSettingsForm, NotificationSettingsForm } from '../../../models/NotificationSettings';

export const SettingsForm: React.FC<{
  form: NotificationSettingsForm | ChallengeNotificationSettingsForm;
  disabled: boolean;
  isAllUpdates?: boolean;
  isAllReminders?: boolean;
  index?: number;
  innerForm?: boolean;
  component?: boolean;
  handleFormChange: (index: number, name: FieldsName, value: boolean) => void;
}> = ({
  form,
  disabled,
  isAllUpdates = true,
  isAllReminders = true,
  index = -1,
  innerForm,
  component = false,
  handleFormChange,
}) => {
  const SettingsLine: React.FC<{ title: string; disabled: boolean; component?: boolean; sx?: SxProps }> = ({
    title,
    disabled,
    component,
    sx,
    children,
  }) => (
    <Grid container sx={{ display: 'flex', alignItems: 'baseline', ...sx }}>
      <Grid
        xs={12}
        md={component ? 12 : 5}
        sx={{ mb: { xs: component ? '10px' : '16px', md: component ? '10px' : 0 } }}
      >
        <Typography
          sx={{
            color: component ? Colors.gray[400] : disabled ? Colors.gray[300] : Colors.gray[700],
            fontSize: component ? '12px' : '14px',
            lineHeight: component ? '12px' : '14px',
            fontWeight: component ? 400 : 700,
          }}
          component="span"
          variant="body2"
        >
          {title}
        </Typography>
      </Grid>
      <Grid xs={12} md={component ? 12 : 7}>
        {children}
      </Grid>
    </Grid>
  );
  return (
    <Grid>
      <SettingsLine
        title={'Receive challenge notifications'}
        disabled={false}
        component={component}
        sx={{
          pt: { xs: component ? '16px' : innerForm ? 0 : '24px', md: innerForm ? '12px' : '16px' },
          pb: component ? '16px' : '24px',
          borderBottom: '1px dashed #F2F2F2',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography sx={{ fontSize: '14px', lineHeight: '14px', color: Colors.gray[700] }}>No</Typography>
          <CustomSwitch
            value={form.receive}
            setValue={(event) => handleFormChange(index, FieldsName.RECEIVE, event.target.checked)}
          />
          <Typography sx={{ fontSize: '14px', lineHeight: '14px', color: Colors.gray[700] }}>Yes</Typography>
        </Box>
      </SettingsLine>
      <SettingsLine
        title={'Receive notifications through:'}
        disabled={disabled}
        component={component}
        sx={{ pt: component ? '16px' : '24px', pb: component ? '16px' : '24px', borderBottom: '1px dashed #F2F2F2' }}
      >
        <Grid sx={{ display: 'flex' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mr: '40px' }}>
            <CustomCheckbox
              label={'Email'}
              disabled={!form.push || disabled}
              checked={form.email}
              setValue={(event) => handleFormChange(index, FieldsName.EMAIL, event.target.checked)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CustomCheckbox
              label={'Push'}
              disabled={!form.email || disabled}
              checked={form.push}
              setValue={(event) => handleFormChange(index, FieldsName.PUSH, event.target.checked)}
            />
          </Box>
        </Grid>
      </SettingsLine>
      <SettingsLine
        title={'Receive notifications for:'}
        disabled={disabled}
        component={component}
        sx={{ pt: component ? '16px' : '24px' }}
      >
        <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
          {FieldsName.GENERAL in form && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CustomCheckbox
                label={'General challenge notifications'}
                disabled={disabled}
                checked={form.general}
                setValue={(event) => handleFormChange(index, FieldsName.GENERAL, event.target.checked)}
                labelWidth={!innerForm && !component ? '208px' : component ? '212px' : '140px'}
              />
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mt: FieldsName.GENERAL in form ? '16px' : 0,
            }}
          >
            <CustomCheckbox
              label={!innerForm && !component ? 'Specific challenge updates' : 'Challenge updates'}
              disabled={disabled}
              checked={form.updates}
              setValue={(event) => handleFormChange(index, FieldsName.UPDATES, event.target.checked)}
              labelWidth={!innerForm && !component ? '208px' : component ? '212px' : '140px'}
              customSx={isAllUpdates ? undefined : { backgroundColor: '#A2E5EC', border: '1px solid #B9EBF1' }}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: component ? '10px' : '16px' }}>
            <CustomCheckbox
              label={'Reminders'}
              disabled={disabled}
              checked={form.reminders}
              setValue={(event) => handleFormChange(index, FieldsName.REMINDERS, event.target.checked)}
              labelWidth={!innerForm && !component ? '208px' : component ? '212px' : '140px'}
              customSx={isAllReminders ? undefined : { backgroundColor: '#A2E5EC', border: '1px solid #B9EBF1' }}
            />
          </Box>
        </Grid>
      </SettingsLine>
    </Grid>
  );
};
