import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { Colors } from '../../../theme/colors';
import { Box, DialogContent, List, Typography, useMediaQuery } from '@mui/material';
import CustomSearchbar from '../../../components/features/CustomSearchbar';
import { TeamItem } from './TeamItem';
import theme from '../../../theme';
import { TeamForm, useTeamHandle } from '../hooks/useTeamHandle';
import { ReactComponent as EmptyParticipantsIcon } from '../../../assets/svg/feature/empty-participants.svg';
import { ReactComponent as PlusIcon } from '../../../assets/svg/plus.svg';
import { CustomButton } from '../../../components/features/CustomButton';
import { Challenge } from '../../../models/Challenge';
import { TeamJoinModal } from '../../../components/features/modals/TeamJoinModal';
import { useJoinHandle } from '../hooks/useTeamJoinHandle';
import { useTeamAbortHandle } from '../hooks/useTeamAbortHandle';
import { TeamAbortModal } from '../../../components/features/modals/TeamAbortModal';
import { TeamModal } from '../../../components/features/modals/TeamModal';
import { useIonRouter } from '@ionic/react';
import { ReactComponent as ExportIcon } from '../../../assets/svg/feature/export.svg';
import { Team } from '../../../models/Team';
import { CHALLENGE_SUB_CATEGORY } from '../../../models/enum/CHALLENGE_SUB_CATEGORY';
import { Capacitor } from '@capacitor/core';

type Props = {
  challenge: Challenge;
  daysLeftToJoin: number;
  loading?: boolean;
  items: Team[];
  searchValue: string;
  form: TeamForm;
  showTeamModal: boolean;
  teamModalLoading: boolean;
  isTeamCreator: boolean;
  setShowTeamModal: (value: boolean) => void;
  handleUpdateImage: (value: string) => void;
  handleChangeTeamName: (value: string) => void;
  handleChangeSlackUrl: (slackUrl: string) => void;
  setSearchValue: (value: string) => void;
  handleUpdateData: () => Promise<void>;
  createTeamRequest: () => Promise<void>;
  handleExport: () => Promise<void>;
  onCloseTeamModal: () => void;
};
export const TeamSection: React.FC<Props> = ({
  challenge,
  daysLeftToJoin,
  loading,
  items,
  searchValue,
  form,
  showTeamModal,
  teamModalLoading,
  isTeamCreator,
  setShowTeamModal,
  handleUpdateImage,
  handleChangeTeamName,
  handleChangeSlackUrl,
  setSearchValue,
  handleUpdateData,
  createTeamRequest,
  handleExport,
  onCloseTeamModal,
}) => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const router = useIonRouter();
  const isWeb = !Capacitor.isNativePlatform();
  const challengeUUID = challenge.metadata.challengeUUID ? challenge.metadata.challengeUUID : '';

  const { joinLoading, joinTeam, setJoinTeam, joinToTeamRequest } = useJoinHandle(challengeUUID, handleUpdateData);
  const { abortLoading, abortTeamItem, setAbortTeamItem, abortRequest } = useTeamAbortHandle(
    challengeUUID,
    handleUpdateData,
  );
  return (
    <>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: { xs: '16px 16px 24px 16px', md: '21px 16px' },
          background: Colors.white,
          borderTop: { xs: '5px solid #73D8E2', md: 'none' },
          borderLeft: { xs: 'none', md: '5px solid #73D8E2' },
          boxShadow: '5px 0px 15px rgba(108, 123, 132, 0.05)',
          borderRadius: '20px',
          margin: { xs: '18px 0', md: '22px 32px 40px 32px' },
        }}
      >
        <Grid
          sx={{
            display: 'flex',
            flexWrap: { xs: 'wrap', md: 'noWrap' },
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: { xs: 'flex-start', md: 'center' },
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '16px', md: '22px' },
              lineHeight: { xs: '16px', md: '22px' },
              color: Colors.gray[700],
              fontWeight: 900,
            }}
          >
            Teams
          </Typography>
          <Grid
            sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap-reverse', width: { xs: '100%', md: 'auto' } }}
          >
            {isTeamCreator && isWeb && (
              <Box
                component="button"
                sx={{
                  display: 'flex',
                  alignItems: 'baseline',
                  marginRight: '16px',
                  marginTop: { xs: '16px', md: 'unset' },
                  cursor: 'pointer',
                  background: 'unset',
                }}
                onClick={handleExport}
              >
                <ExportIcon fill={Colors.gray[400]} style={{ transform: 'translateY(4px)' }} />
                <Typography sx={{ fontSize: '12px', textDecoration: 'underline', color: Colors.gray[400] }}>
                  Export as .xls file
                </Typography>
              </Box>
            )}
            <CustomSearchbar
              width={
                mdDown
                  ? '100%'
                  : daysLeftToJoin > 0 &&
                    challenge.challengeParticipation.isUserParticipant &&
                    !challenge.challengeParticipation.isUserTeamMember
                  ? 203
                  : 276
              }
              sx={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', mt: { xs: '16px', md: 0 } }}
              searchInput={searchValue}
              setSearchInput={setSearchValue}
              handleSearch={() => undefined}
            />
            {daysLeftToJoin > 0 &&
              challenge.challengeParticipation.isUserParticipant &&
              !challenge.challengeParticipation.isUserTeamMember && (
                <CustomButton
                  text={'Create team'}
                  inputProps={{
                    variant: 'contained',
                    startIcon: <PlusIcon width={12} height={12} />,
                  }}
                  sx={{ ml: '24px', display: { xs: 'none', md: 'flex' } }}
                  onClick={() => setShowTeamModal(true)}
                />
              )}
          </Grid>
        </Grid>

        {items.length ? (
          <DialogContent
            sx={{
              marginTop: '24px',
              padding: mdDown ? 0 : '0 10px 0 0',
              '&::-webkit-scrollbar-track': { background: '#F0F2F3!important' },
              '&::-webkit-scrollbar-thumb': { background: '#D3D7DA!important' },
              '&::-webkit-scrollbar-track:hover': { background: '#F0F2F3!important' },
              '*::-webkit-scrollbar': {
                width: '5px!important',
                height: '5px !important',
              },
            }}
          >
            <Box
              sx={{
                width: '100%',
                maxHeight: { xs: 406, md: 116 },
                maxWidth: '100%',
                bgcolor: 'background.paper',
                margin: '0 auto',
              }}
            >
              <List
                sx={{
                  width: '100%',
                  maxWidth: '100%',
                  position: 'relative',
                  overflowY: 'auto',
                  padding: 0,
                  pr: '7px',
                  '& ul': { padding: 0, margin: 0 },
                }}
              >
                <Grid sx={{ display: 'flex', flexWrap: 'wrap' }}>
                  {items.map((item, index) => (
                    <Grid sx={{ width: { xs: '100%', md: '50%' } }} key={index}>
                      <TeamItem
                        team={item}
                        showActions={
                          challenge.challengeSubCategory !== CHALLENGE_SUB_CATEGORY.FINISHED &&
                          challenge.challengeParticipation.isUserParticipant
                        }
                        showJoin={daysLeftToJoin > 0 && !challenge.challengeParticipation.isUserTeamMember}
                        sx={
                          mdDown
                            ? { marginTop: index === 0 ? 0 : '8px' }
                            : (index + 1) % 2
                            ? {
                                marginTop: index === 0 || index === 1 ? 0 : '8px',
                                marginRight: '8px',
                              }
                            : {
                                marginTop: index === 0 || index === 1 ? 0 : '8px',
                                marginLeft: '28px',
                              }
                        }
                        onJoin={(e) => {
                          e.stopPropagation();
                          setJoinTeam(item);
                        }}
                        onAbort={(e) => {
                          e.stopPropagation();
                          setAbortTeamItem(item);
                        }}
                        onClick={() =>
                          router.push(`/page/your-challenges/challenge/${challengeUUID}/team/${item.teamUUID}`)
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </List>
            </Box>
          </DialogContent>
        ) : (
          <Grid sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pb: '20px', mt: '24px' }}>
            <EmptyParticipantsIcon width={100} height={84} />
            <Typography sx={{ fontSize: '14px', color: Colors.gray[400], fontWeight: 400, mt: '10px' }}>
              Create the first team!
            </Typography>
          </Grid>
        )}

        {daysLeftToJoin > 0 &&
          challenge.challengeParticipation.isUserParticipant &&
          !challenge.challengeParticipation.isUserTeamMember && (
            <CustomButton
              text={'Create team'}
              type={'bigBlue'}
              inputProps={{
                variant: 'contained',
                startIcon: <PlusIcon width={12} height={12} />,
              }}
              sx={{ display: { xs: 'flex', md: 'none' }, maxWidth: '284px', width: '100%', margin: '50px auto 16px' }}
              onClick={() => setShowTeamModal(true)}
            />
          )}
      </Grid>
      <TeamJoinModal
        show={!!joinTeam}
        title={challenge ? challenge.challengeInfo.title : ''}
        challenge={challenge}
        loading={joinLoading}
        url={joinTeam ? `/page/your-challenges/challenge/${challengeUUID}/team/${joinTeam.teamUUID}` : '/'}
        onClose={() => setJoinTeam(null)}
        onJoin={joinToTeamRequest}
      />
      <TeamAbortModal
        show={!!abortTeamItem}
        loading={abortLoading}
        daysLeftToJoin={daysLeftToJoin}
        onClose={() => setAbortTeamItem(null)}
        onAbort={abortRequest}
      />
      <TeamModal
        show={showTeamModal}
        form={form}
        loading={teamModalLoading}
        handleChangeTeamName={handleChangeTeamName}
        handleUpdateImage={handleUpdateImage}
        handleChangeSlackUrl={handleChangeSlackUrl}
        onClose={onCloseTeamModal}
        onCreate={createTeamRequest}
      />
    </>
  );
};
