import { CircularProgress, Container, Grid, useMediaQuery } from '@mui/material';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { ICompanyInfo } from '../../models/Profile';
import { RootState } from '../../redux/store';
import StyledButton from '../common/Button';
import CustomDropdown from '../common/CustomDropdown';
import CustomTextField from '../common/CustomTextField';
import { ReactComponent as Crown } from '../../assets/svg/crown.svg';
import CompanyInfoSubSection from './CompanyInfoSubSection';
import { TransferAdminRole } from '../modals/ProfileModals';
import CustomLabelValueDropdown from '../common/CustomLabelValueDropdown';
import { useTheme } from '@mui/material/styles';
import { Countries } from '../../models/Countries';
import CustomSubHeader from '../structure/CustomSubHeader';

interface Props {
  edit: boolean;
  compInfo: ICompanyInfo;
  setCompInfo: (compInfo: ICompanyInfo) => void;
  size: string;
  setSize: (size: string) => void;
  sector: string;
  setSector: (sector: string) => void;
  country: string;
  setCountry: (country: string) => void;
  industry: string;
  setIndustry: (industry: string) => void;
}

const sectors = [
  { label: 'Primary (Extraction and agriculture)', value: 'PRIMARY' },
  { label: 'Secondary (Manufacturing)', value: 'SECONDARY' },
  { label: 'Tertiary (Services)', value: 'TERTIARY' },
  { label: 'Quaternary (Research and Development)', value: 'QUATERNARY' },
  { label: 'Quinary (High level decision making)', value: 'QUINARY' },
];

const sizes = [
  { label: 'Micro', value: 'MICRO' },
  { label: 'Small', value: 'SMALL' },
  { label: 'Medium', value: 'MEDIUM' },
  { label: 'Large', value: 'LARGE' },
];
const industries = [
  { label: 'Energy', value: 'ENERGY' },
  { label: 'Materials', value: 'MATERIALS' },
  { label: 'Industrials', value: 'INDUSTRIALS' },
  { label: 'Consumer Discretionary', value: 'DISCRETIONARY' },
  { label: 'Consumer Staples', value: 'STAPLES' },
  { label: 'Health Care', value: 'HEALTH_CARE' },
  { label: 'Financials', value: 'FINANCIALS' },
  { label: 'Information Technology', value: 'IT' },
  { label: 'Telecommunication Services', value: 'TELECOMS' },
  { label: 'Utilities', value: 'UTILITIES' },
  { label: 'Real Estate', value: 'REAL_ESTATE' },
];

const CompanyInfo: FC<Props> = ({
  edit,
  compInfo,
  setCompInfo,
  size,
  setSize,
  sector,
  setSector,
  country,
  setCountry,
  industry,
  setIndustry,
}) => {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [transferRoleModal, setTransferRoleModal] = useState(false);
  const { companyInfoLoading } = useSelector((state: RootState) => state.profile.meta);
  const handleCompanyInfo = (e: React.ChangeEvent<{ name: string; value: any }>) => {
    setCompInfo({ ...compInfo, company_details: { ...compInfo.company_details, [e.target.name]: e.target.value } });
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={6}>
        <Grid item container xs={12} justifyContent={'space-between'} spacing={2} wrap={xsDown ? undefined : 'nowrap'}>
          <Grid item container wrap={'nowrap'}>
            <Grid item sx={{ flex: 1 }}>
              <CustomSubHeader label={'Organization Details'} />
            </Grid>
            {companyInfoLoading && <CircularProgress />}
          </Grid>
          <Grid item xs={xsDown ? 12 : undefined} sx={{ position: 'relative' }}>
            <StyledButton
              sx={{ whiteSpace: 'nowrap', width: '100%' }}
              onClick={() => setTransferRoleModal(true)}
              inputProps={{ variant: 'contained', color: 'warning', disabled: !edit, startIcon: <Crown /> }}
              text="Transfer Admin Role"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} container spacing={6}>
          <Grid item xs={12}>
            <CustomTextField
              name="company_name"
              value={compInfo?.company_details?.company_name ?? ''}
              setValue={handleCompanyInfo}
              label={'Organization Name'}
              inputProps={{ disabled: !edit }}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              name="address"
              value={compInfo?.company_details?.address ?? ''}
              setValue={handleCompanyInfo}
              label={'Address'}
              inputProps={{ disabled: !edit }}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              name="state"
              value={compInfo?.company_details?.state ?? ''}
              setValue={handleCompanyInfo}
              label={'State'}
              inputProps={{ disabled: !edit }}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomDropdown
              placeholder="-"
              width={'100%'}
              disabled={!edit}
              label="Country"
              list={Countries}
              selected={country}
              setSelected={setCountry}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} container spacing={6}>
          <Grid item xs={12}>
            <CustomTextField
              name="organisation_website"
              value={compInfo?.company_details?.organisation_website ?? ''}
              setValue={handleCompanyInfo}
              label={'Organization Website'}
              inputProps={{ disabled: !edit }}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomLabelValueDropdown
              placeholder="-"
              width={'100%'}
              disabled={!edit}
              label="Sector"
              list={sectors}
              selected={sector}
              setSelected={setSector}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomLabelValueDropdown
              placeholder="-"
              width={'100%'}
              disabled={!edit}
              label="Size"
              list={sizes}
              selected={size}
              setSelected={setSize}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomLabelValueDropdown
              placeholder="-"
              width={'100%'}
              disabled={!edit}
              label="Industry"
              list={industries}
              selected={industry}
              setSelected={setIndustry}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            name="slack_url"
            value={compInfo?.company_details?.slack_url ?? ''}
            setValue={handleCompanyInfo}
            label={'Slack Channel Email'}
            inputProps={{ disabled: !edit }}
          />
        </Grid>
      </Grid>
      {/* 
      TODO: remove comment 
      <CompanyInfoSubSection companyInfo={compInfo} setCompanyInfo={setCompInfo} /> */}
      <TransferAdminRole open={transferRoleModal} setOpen={setTransferRoleModal} />
    </Container>
  );
};

export default CompanyInfo;
