import { Box, ButtonBase, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import React, { SVGProps } from 'react';
import { Colors } from '../../../theme/colors';

type RoundIconButton = {
  Icon: React.FunctionComponent<SVGProps<SVGSVGElement>>;
  label: string;
  onClick: () => void;
  size?: number;
  iconSize?: number;
  fontSize?: string;
  isDisable?: boolean;
  background?: string;
  iconColor?: string;
  roundColor?: string;
  textColor?: string;
  roundBorderColor?: string;
  disableColor?: string;
  disabledRoundColor?: string;
  disabledRoundBorderColor?: string;
  sx?: SxProps<Theme>;
  textSx?: SxProps<Theme>;
};

export const RoundIconButton: React.FC<RoundIconButton> = ({
  Icon,
  size = 36,
  label,
  isDisable = false,
  iconSize = 16,
  iconColor = Colors.white,
  roundColor = Colors.gray[150],
  disabledRoundColor = Colors.gray[150],
  textColor = Colors.white,
  roundBorderColor = '#E9EDEF',
  background = Colors.gray[100],
  disableColor = Colors.gray[100],
  disabledRoundBorderColor = '#E9EDEF',
  sx,
  textSx,
  onClick,
}) => {
  return (
    <Box
      component="button"
      sx={{
        p: '2px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        background: isDisable ? disableColor : background,
        borderRadius: `${size + 2}px`,
        ...sx,
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          height: size - 4,
          width: size - 4,
          borderRadius: size,
          border: `1px solid ${isDisable ? disabledRoundBorderColor : roundBorderColor}`,
          backgroundColor: isDisable ? disabledRoundColor : roundColor,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Icon width={iconSize} height={iconSize} fill={isDisable ? Colors.gray[500] : iconColor} />
      </Box>
      {label.length > 0 ? (
        <Typography sx={{ ml: '10px', mr: '12px', color: isDisable ? Colors.gray[500] : textColor, ...textSx }}>
          {label}
        </Typography>
      ) : null}
    </Box>
  );
};
