import { SxProps, Theme } from '@mui/material';
import { Colors } from '../../../theme/colors';

export const styles: { [key: string]: SxProps<Theme> } = {
  cardContainer: {
    position: 'relative',
    borderRadius: '20px',
    backgroundColor: Colors.white,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 276,
    minHeight: 319,
    height: '100%',
    width: '100%',
    boxShadow: 'none',
    overflow: 'visible',
    zIndex: 3,
  },
  selfCardContainer: {
    position: 'relative',
    borderRadius: '20px',
    backgroundColor: Colors.white,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 276,
    minHeight: 319,
    height: '100%',
    width: '100%',
    boxShadow: 'none',
    borderBottomWidth: '5px',
    borderBottomColor: Colors.blue[600],
    borderBottomStyle: 'solid',
    overflow: 'visible',
    zIndex: 3,
  },
  cardImage: { flexShrink: 0, height: 140, borderTopLeftRadius: '20px', borderTopRightRadius: '20px' },

  daysLeftPosition: { position: 'absolute', top: '10px', left: '10px' },
  daysLeftContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '2px 8px 2px 2px',
    background: 'rgba(65, 74, 79, 0.5)',
    backdropFilter: 'blur(2px)',
    borderRadius: '30px',
  },
  daysLeftCircle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '22px',
    minWidth: '22px',
    border: '1px solid #89959D',
    borderRadius: '100px',
    background: Colors.gray[700],
    padding: '4px',
  },

  labelStatusPosition: { position: 'absolute', top: '10px', right: '-7px' },

  selfCardContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    px: '16px',
    pt: '15px',
    pb: '19px!important',
  },
  cardContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    px: '16px',
    pt: '15px',
    pb: '24px!important',
  },
  cardTitle: {
    fontSize: 16,
    lineHeight: 1.4,
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
  },
  decorationLine: {
    pb: '11px',
    borderBottomWidth: '1px',
    borderBottomStyle: 'dashed',
    borderBottomColor: '#F2F2F2',
  },

  firstLayout: {
    position: 'absolute',
    width: '244px',
    height: '125px',
    left: '16px',
    bottom: '-8px',
    background: '#FBFBFB',
    boxShadow: '0px 4px 10px rgba(65, 74, 79, 0.05)',
    borderRadius: '20px',
    zIndex: 2,
  },
  secondLayout: {
    position: 'absolute',
    width: '212px',
    height: '125px',
    left: '32px',
    bottom: '-16px',
    background: '#F6F7F8',
    boxShadow: '0px 4px 10px rgba(65, 74, 79, 0.05)',
    borderRadius: '20px',
  },
} as const;
