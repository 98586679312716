import React from 'react';
import { Box, CardMedia, styled, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material';
import BadgeBackgroundIcon from '../../assets/svg/feature/trophyBackgrounds/badge.svg';
import OtherBackgroundIcon from '../../assets/svg/feature/trophyBackgrounds/other.svg';
import PointBackgroundIcon from '../../assets/svg/feature/trophyBackgrounds/point.svg';
import { ReactComponent as PointIcon } from '../../assets/svg/feature/trophy/point.svg';
import { ReactComponent as OtherIcon } from '../../assets/svg/feature/trophy/other.svg';
import { ReactComponent as TrophyDecorIcon } from '../../assets/svg/feature/trophy-decor.svg';
import { ReactComponent as GoalIcon } from '../../assets/svg/feature/goal.svg';
import { ReactComponent as CalendarIcon } from '../../assets/svg/feature/calendar.svg';
import Grid from '@mui/material/Grid';
import { Colors } from '../../theme/colors';
import { AWARD_TYPE } from '../../models/enum/AWARD_TYPE';
import moment from 'moment';
import { Award } from '../../models/Award';
import { dayIsFinished } from '../../utils/dayComparingUtils';

const trophyStyles = {
  POINT: {
    title: null,
    iconTopPosition: '24px',
    Icon: PointIcon,
    bg: PointBackgroundIcon,
    borderColor: Colors.blue[100],
  },
  CUSTOM: {
    title: 'Other',
    iconTopPosition: '20px',
    Icon: OtherIcon,
    bg: OtherBackgroundIcon,
    borderColor: Colors.blue[100],
  },
  BADGE: {
    title: 'Badge',
    iconTopPosition: '20px',
    Icon: OtherIcon,
    bg: BadgeBackgroundIcon,
    borderColor: Colors.gray[50],
  },
  NONE: {
    title: 'Badge',
    iconTopPosition: '20px',
    Icon: OtherIcon,
    bg: BadgeBackgroundIcon,
    borderColor: Colors.gray[50],
  },
};

type Props = {
  award: Award;
  onClick: () => void;
};

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 122,
    padding: '8px',
    borderRadius: '5px',
  },
});

export const TrophyCard: React.FC<Props> = ({ award, onClick }) => {
  const awardByType = trophyStyles[award.awardType];
  const TrophyIcon = awardByType.Icon;

  const winDayBegan = dayIsFinished(award.awardWinDate);

  const datesAwardWon =
    !winDayBegan && award.datesAwardWon.length ? award.datesAwardWon.slice(0, -1) : award.datesAwardWon;

  const wonDate = !winDayBegan ? award.datesAwardWon[award.datesAwardWon.length - 1] : award.awardWinDate;

  return (
    <Box sx={{ position: 'relative', height: '100%', flex: 1, cursor: 'pointer', zIndex: 3 }} onClick={onClick}>
      <Grid
        sx={{
          position: 'relative',
          padding: '16px',
          backgroundColor: Colors.white,
          borderRadius: '20px',
          zIndex: 3,
          boxShadow: '0px 4px 10px rgba(65, 74, 79, 0.05)',
          height: '100%',
        }}
      >
        <Grid sx={{ position: 'relative' }}>
          <CardMedia
            sx={{
              flexShrink: 0,
              height: 140,
              background: `url(${awardByType.bg})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              border: '4px solid',
              borderColor: awardByType.borderColor,
              borderRadius: '20px',
              overflow: 'hidden',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: awardByType.iconTopPosition,
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 2,
            }}
          >
            {award.awardType === AWARD_TYPE.BADGE ? (
              <CardMedia
                image={award.badgeImageUrl}
                sx={{
                  flexShrink: 0,
                  height: 84,
                  width: 84,
                }}
              />
            ) : (
              <TrophyIcon />
            )}
          </Box>
          <Box sx={{ position: 'absolute', bottom: '-12px', left: '50%', transform: 'translateX(-50%)', zIndex: 2 }}>
            <TrophyDecorIcon style={{ width: '164px', height: '46px' }} />
          </Box>
          <Box
            sx={{
              position: 'absolute',
              bottom: '-9px',
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 2,
              background: 'linear-gradient(100.45deg, #ECE0A2 -0.03%, #D9C145 104.97%)',
              border: '1px solid #F5F0D0',
              borderRadius: '100px',
              height: '28px',
              padding: '0 10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography sx={{ fontSize: '12px', color: Colors.white, fontWeight: 600 }}>
              {award.awardType === AWARD_TYPE.POINT ? award.points : awardByType.title}
            </Typography>
          </Box>
        </Grid>

        <Grid sx={{ mt: '15px' }}>
          <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {wonDate && (
              <CustomWidthTooltip
                key={'dates'}
                title={
                  <Typography variant={'body2'} sx={{ fontSize: '10px', color: Colors.white }}>
                    {datesAwardWon.map(
                      (item, index) =>
                        `${moment(item).format('DD/MM/YY')}${datesAwardWon.length === index + 1 ? '' : ', '}`,
                    )}
                  </Typography>
                }
                placement={'top-start'}
                enterTouchDelay={0}
                leaveTouchDelay={10000}
              >
                <Box
                  sx={{
                    padding: '1px',
                    backgroundColor: Colors.gray[100],
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    borderRadius: '24px',
                    pointerEvents: datesAwardWon.length <= 0 ? 'none' : 'auto',
                    cursor: datesAwardWon.length <= 0 ? 'default' : 'pointer',
                  }}
                >
                  <Box
                    sx={{
                      height: '18px',
                      width: '18px',
                      borderRadius: '100px',
                      border: `1px solid #E9EDEF`,
                      backgroundColor: Colors.gray[200],
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CalendarIcon width={10} height={10} fill={Colors.white} />
                  </Box>
                  <Typography
                    sx={{ ml: '8px', mr: '10px', color: Colors.gray[700], fontSize: '10px', fontWeight: 700 }}
                  >
                    {moment(wonDate).format('DD/MM/YY')}
                  </Typography>
                </Box>
              </CustomWidthTooltip>
            )}

            {award.timesWon > 1 && (
              <Grid display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <GoalIcon width={16} height={16} />
                <Box display={'flex'} alignItems={'center'} justifyContent={'center'} sx={{ marginLeft: '4px' }}>
                  <Typography color={Colors.gray[400]} sx={{ fontSize: '12px', lineHeight: 1.3 }}>
                    collected
                  </Typography>
                  <Typography
                    color={Colors.gray[400]}
                    sx={{
                      fontSize: '12px',
                      lineHeight: 1.3,
                      fontWeight: 'bold',
                      marginLeft: '4px',
                      marginRight: '4px',
                    }}
                  >
                    {award.timesWon}
                  </Typography>
                  <Typography color={Colors.gray[400]} sx={{ fontSize: '12px', lineHeight: 1.3 }}>
                    times
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
          <Grid sx={{ mt: '8px', mb: '8px' }}>
            <Typography
              variant="caption"
              sx={{ fontSize: 16, lineHeight: 1.4, fontWeight: 'bold', color: Colors.gray[700] }}
            >
              {award.challengeTitle}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {award.timesWon > 1 && (
        <>
          <Grid
            sx={{
              position: 'absolute',
              width: '244px',
              height: '125px',
              left: '50%',
              transform: 'translateX(-50%)',
              bottom: '-8px',
              background: '#FBFBFB',
              boxShadow: '0px 4px 10px rgba(65, 74, 79, 0.05)',
              borderRadius: '20px',
              zIndex: 2,
            }}
          />
          <Grid
            sx={{
              position: 'absolute',
              width: '212px',
              height: '125px',
              left: '50%',
              transform: 'translateX(-50%)',
              bottom: '-16px',
              background: '#F6F7F8',
              boxShadow: '0px 4px 10px rgba(65, 74, 79, 0.05)',
              borderRadius: '20px',
            }}
          />
        </>
      )}
    </Box>
  );
};
