import { Box, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { Swiper as SwiperType } from 'swiper';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { numberWithSpaces } from '../../../utils/numberWithSpace';
import ContainerBg from '../../../assets/svg/feature/team-daily-bg.svg';
import { Colors } from '../../../theme/colors';
import { TeamDailyRange, useHandleTeamDailyProgress } from '../hooks/useHandleTeamDailyProgress';
import { ReactComponent as ArrowIcon } from '../../../assets/svg/feature/arrow.svg';
import { RadialChart } from './RadialChart';

type TeamDailyProgressProps = {
  metricTitle?: string;
  target: number;
  dailyScore: number;
  range: TeamDailyRange;
  monthName: string;
  onSlideChange: (props: SwiperType) => void;
};

const slideOptions: SwiperProps = {
  spaceBetween: 0,
  speed: 400,
  centeredSlidesBounds: true,
  slideToClickedSlide: false,
  threshold: 15,
  slidesOffsetAfter: 0,
  slidesOffsetBefore: 0,
  slidesPerView: 1,
  slidesPerGroup: 1,
  allowSlideNext: true,
  allowSlidePrev: true,
  centeredSlides: true,
};

export const TeamDailyProgress: React.FC<TeamDailyProgressProps> = ({
  metricTitle = 'steps',
  target,
  dailyScore,
  range,
  monthName,
  onSlideChange,
}) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const swiperRef = useRef<SwiperType>();

  const handleOpenTooltip = (score: number) => (x: number, y: number) => {
    if (!tooltipRef.current) return;
    tooltipRef.current.children[0].innerHTML = `<b>${numberWithSpaces(score)}</b> out<br/> of <b>${numberWithSpaces(
      target,
    )}</b>`;
    tooltipRef.current.style.display = 'block';
    tooltipRef.current.style.top = `${y}px`;
    tooltipRef.current.style.left = `${x}px`;
  };
  const handleCloseTooltip = () => {
    if (!tooltipRef.current) return;
    tooltipRef.current.style.display = 'none';
  };

  const focusIndex = range.findIndex((item) => item.isToday);

  return (
    <Box
      sx={{
        flex: 1,
        position: 'relative',
        paddingTop: '11px',
        paddingBottom: '21px',
        paddingInline: '14px',
        border: `5px solid #FEF7E9`,
        borderRadius: '20px',
        overflow: 'hidden',
        background: `linear-gradient(180deg, #FEF7E9 0%, #FDE7BC 100%)`,
      }}
    >
      <img
        src={ContainerBg}
        style={{ position: 'absolute', zIndex: 0, top: 0, left: 0, bottom: 0, right: 0, objectFit: 'cover' }}
      />
      <Box sx={{ position: 'relative', zIndex: 10 }}>
        <Typography sx={{ fontWeight: 700, fontSize: '14px', color: Colors.gray[700], marginBottom: '16px' }}>
          Daily progress
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <Typography
            sx={{ fontWeight: 900, fontSize: '16px', color: Colors.gray[700], textAlign: 'center', lineHeight: 1 }}
          >
            {numberWithSpaces(dailyScore > target ? 0 : target - dailyScore)}
          </Typography>
          <Typography sx={{ fontSize: '10px', color: '#9F9988', textAlign: 'center' }}>
            {metricTitle.charAt(0).toUpperCase() + metricTitle.slice(1)} more to your daily goal
          </Typography>
        </Box>
        <Typography
          sx={{
            color: Colors.gray[700],
            width: '100%',
            fontSize: '12px',
            textAlign: 'center',
            marginBottom: '8px',
          }}
        >
          {monthName}
        </Typography>
        <Box
          sx={{
            position: 'relative',
            zIndex: 1,
            display: 'flex',
            width: '128px',
            marginLeft: 'auto',
            marginRight: 'auto',
            borderRadius: '15px',
            background: '#FEF7E9',
            marginBottom: '8px',
          }}
        >
          <Box
            component="button"
            sx={{
              height: 84,
              width: 22,
              background: 'unset',
              padding: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() => swiperRef.current?.slidePrev()}
          >
            <ArrowIcon height="12px" width="12px" fill="#9FA09C" />
          </Box>
          <Swiper
            {...slideOptions}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            initialSlide={focusIndex < 0 ? range.length - 1 : focusIndex}
            style={{ flex: 1 }}
            onSlideChange={onSlideChange}
          >
            {range.map((item) => (
              <SwiperSlide key={item.date}>
                <Box
                  sx={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 88,
                    height: 88,
                  }}
                >
                  <Typography
                    sx={{ position: 'absolute', top: '8px', left: '8px', fontSize: '10px', color: Colors.gray[700] }}
                  >
                    {item.day}
                  </Typography>
                  <Box
                    sx={{
                      position: 'absolute',
                      zIndex: 10,
                      top: '50%',
                      left: '50%',
                      height: 62,
                      width: 62,
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    <RadialChart
                      selfPercent={item.percent}
                      handleCloseTooltip={handleCloseTooltip}
                      handleOpenTooltip={handleOpenTooltip(item.score ?? 0)}
                    />
                  </Box>
                  {item?.label && (
                    <Box
                      sx={{
                        display: 'flex',
                        alignContent: 'center',
                        justifyItems: 'center',
                        height: 40,
                        width: 40,
                        borderRadius: '50%',
                        background: Colors.white,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography sx={{ fontWeight: '900', fontSize: '14px', color: Colors.magenta[200] }}>
                        {item.label}
                      </Typography>
                    </Box>
                  )}
                  {item.isToday && (
                    <Box
                      sx={{
                        position: 'absolute',
                        left: '50%',
                        bottom: 0,
                        height: '3px',
                        width: '40px',
                        background: Colors.magenta[300],
                        transform: 'translateX(-50%)',
                        borderTopLeftRadius: '50%',
                        borderTopRightRadius: '50%',
                      }}
                    />
                  )}
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
          <Box
            component="button"
            sx={{
              height: 84,
              width: 22,
              padding: 0,
              background: 'unset',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() => swiperRef.current?.slideNext()}
          >
            <ArrowIcon height="12px" width="12px" fill="#9FA09C" style={{ transform: 'rotate(180deg)' }} />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', paddingInline: '10px' }}>
          <Box sx={{ display: 'flex', marginBottom: '4px', alignItems: 'center', marginTop: 'auto' }}>
            <Box
              sx={{
                height: '8px',
                width: '8px',
                borderRadius: '8px',
                background: `linear-gradient(120deg, #FAAF1F 0%, #FDDFA5 100%)`,
                marginRight: '8px',
                border: `1px solid #FDDFA5`,
              }}
            />
            <Typography sx={{ fontSize: '10px', lineHeight: 1, color: '#9F9988' }}>You</Typography>
          </Box>
          <Box sx={{ display: 'flex', marginBottom: '4px', alignItems: 'center', marginTop: 'auto' }}>
            <Box
              sx={{
                height: '8px',
                width: '8px',
                borderRadius: '8px',
                background: Colors.white,
                marginRight: '8px',
              }}
            />
            <Typography sx={{ fontSize: '10px', lineHeight: 1, color: '#9F9988' }}>Remaining</Typography>
          </Box>
        </Box>
      </Box>
      <div
        ref={tooltipRef}
        style={{
          display: 'none',
          borderRadius: '5px',
          padding: '8px',
          background: 'rgba(0,0,0,0.7)',
          position: 'fixed',
          zIndex: 100,
          transform: 'translate(-100%,-100%)',
          top: 0,
          left: 0,
        }}
      >
        <Typography sx={{ fontSize: '10px', color: Colors.white }}></Typography>
      </div>
    </Box>
  );
};

export const TeamDailyProgressLayout: React.FC = () => {
  const props = useHandleTeamDailyProgress();

  return <TeamDailyProgress {...props} />;
};
