import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { Container, Grid, Skeleton } from '@mui/material';
import { HeaderFilter, useHandleFilter } from '../../components/features/HeaderFilter';
import { ReactComponent as GoalIcon } from '../../assets/svg/goal.svg';
import { CHALLENGE_STATUS } from '../../models/enum/CHALLENGE_STATUS';
import { ChallengeCard } from '../../components/features/ChallengeCard/ChallengeCard';
import { useHandleFetch } from './useHandleFetch';
import { EmptyState } from '../../components/features/EmptyState';
import { FilterAdditionalActions } from '../ChallengesAdminPage/FilterAdditionalActions';
import { useMetricsHandle } from '../ChallengesAdminPage/hooks/useMetricsHandle';

type optionProps = {
  label: string;
  challengeStatus: CHALLENGE_STATUS[];
};

const pageOptionsByType: { [key: string]: optionProps } = {
  ongoing: {
    label: 'Ongoing challenges',
    challengeStatus: [CHALLENGE_STATUS.ACTIVE],
  },
  join: {
    label: 'Starting soon challenges',
    challengeStatus: [CHALLENGE_STATUS.INACTIVE],
  },
  finished: {
    label: 'Finished challenges',
    challengeStatus: [CHALLENGE_STATUS.COMPLETED, CHALLENGE_STATUS.IN_GRACE_PERIOD],
  },
};

type ViewAllPageProps = {
  type: keyof typeof pageOptionsByType;
};

const SkeletonList: React.FC = () => (
  <>
    {new Array(12).fill('1').map((_, idx) => (
      <Grid item key={idx.toString()} xs={12} sm={6} md={3}>
        <Skeleton variant="rectangular" style={{ height: 270, width: '100%', borderRadius: '20px' }} />
      </Grid>
    ))}
  </>
);

const ChallengesAdminViewAllPage: React.FC<ViewAllPageProps> = ({ type }) => {
  const { queryFilter, ...headerFilterProps } = useHandleFilter();
  const { metrics } = useMetricsHandle();
  const { items, loading, isFetching, divRef, handleScrollEvent, handleUpdateJoinData, handleExport } = useHandleFetch(
    pageOptionsByType[type].challengeStatus,
    queryFilter,
  );

  return (
    <IonPage>
      <HeaderFilter
        breadcrumbs={{
          Icon: GoalIcon,
          list: [
            { label: "Your Employees' Challenges", url: '/page/employees-challenges' },
            { label: pageOptionsByType[type].label, url: '/' },
          ],
        }}
        loading={loading}
        isAdmin={true}
        actions={<FilterAdditionalActions handleExport={handleExport} />}
        label={pageOptionsByType[type].label}
        {...headerFilterProps}
        metrics={metrics}
      />
      <IonContent
        ref={divRef}
        onIonScroll={handleScrollEvent}
        style={{ '--padding-bottom': '36px' }}
        scrollEvents={true}
      >
        <Container maxWidth={'lg'}>
          <Grid container spacing="24px">
            {loading ? (
              <SkeletonList />
            ) : items.length <= 0 ? (
              <EmptyState />
            ) : (
              <>
                {items.map((item) => (
                  <Grid item key={item.metadata.challengeUUID} xs={12} sm={6} md={3}>
                    <ChallengeCard
                      status={pageOptionsByType[type].challengeStatus[0]}
                      challenge={item}
                      showJoin={false}
                      isAdmin={true}
                      sx={{ maxWidth: 'unset' }}
                      onJoin={() => undefined}
                    />
                  </Grid>
                ))}
                {isFetching ? <SkeletonList /> : null}
              </>
            )}
          </Grid>
        </Container>
      </IonContent>
    </IonPage>
  );
};

export default ChallengesAdminViewAllPage;
