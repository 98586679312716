import React from 'react';
import { CustomDialog } from '../CustomDialog';
import { List } from '@mui/material';
import { InstanceListItem } from './InstanceListItem';
import moment from 'moment';
import { StatisticInstance } from '../../../pages/ChallengeActivityPage/hooks/useHandlePreviousInstances';

type InstancesModalProps = {
  visible: boolean;
  onClose: () => void;
  data?: StatisticInstance[];
  target?: number | null;
  isLoading?: boolean;
  previousScore: number;
  startDate: string;
  handleOpenModal: (val: string) => () => void;
};

export const InstancesModal: React.FC<InstancesModalProps> = ({
  visible,
  onClose,
  data = [],
  target,
  previousScore,
  startDate,
  handleOpenModal,
}) => {
  return (
    <CustomDialog onClose={onClose} open={visible} label="Full leaderboard">
      <List
        sx={{
          overflow: 'auto',
          height: { xs: 'auto', md: 416 },
          width: { xs: 'auto', md: 470 },
          marginInline: '12px',
          paddingInline: '12px',
          marginBottom: { xs: 0, md: '34px' },
          paddingBottom: { xs: '34px', md: 0 },
          '&::-webkit-scrollbar': {
            width: '5px !important',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#D3D7DA',
          },
        }}
      >
        {data?.map((item, idx) => {
          const label = `${moment(item.startDate).format('DD MMM')} - ${moment(item.endDate).format('DD MMM')}`;
          return (
            <InstanceListItem
              key={label}
              label={label}
              score={item.score}
              place={item.place}
              target={target}
              scoreToPrevious={previousScore}
              isCurrent={item.startDate === startDate}
              isAwarded={item.isAwarded}
              onClick={handleOpenModal(item.label)}
            />
          );
        })}
      </List>
    </CustomDialog>
  );
};
