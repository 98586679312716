import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { AWARD_TYPE } from '../../../models/enum/AWARD_TYPE';

export const useHandleAward = () => {
  const isLoading = useSelector((state: RootState) => state.challengeProgress.meta.isLoading);
  const { award } = useSelector((state: RootState) => state.challengeProgress.data);

  return {
    isLoading,
    isFirst: award?.getAward,
    awardsType: award?.type ?? AWARD_TYPE.NONE,
    awardIcon: award?.awardIcon,
    points: award?.points,
  };
};
