import { useEffect, useState } from 'react';
import { Challenge } from '../../../models/Challenge';
import { deleteChallenge, deleteTemplateChallenge } from '../../../lib/api/http/requests/challengeAdmin';
import { useIonRouter } from '@ionic/react';
import UserService from '../../../services/UserService';

type Props = {
  id: string;
};
export const useDeleteHandle = (params: Props, challenge: Challenge | null) => {
  const router = useIonRouter();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showDeleteButton, setShowDeleteButton] = useState(false);

  useEffect(() => {
    setShowDeleteButton(false);
    if (!challenge) return;
    const uuid = UserService.getMineId();
    setShowDeleteButton(challenge.metadata.admin === uuid);
  }, [challenge]);
  useEffect(() => {
    if (deleteLoading) return;

    setShowDelete(false);
  }, [deleteLoading]);
  const deleteRequest = async () => {
    if (!params.id) return;
    setDeleteLoading(true);
    try {
      if (challenge && challenge.metadata.challengeUUID) {
        await deleteChallenge({ challengeUUID: challenge.metadata.challengeUUID });
      } else if (challenge && challenge.metadata.templateChallengeUUID) {
        await deleteTemplateChallenge({ templateChallengeUUID: challenge.metadata.templateChallengeUUID });
      }

      setDeleteLoading(false);
      router.push('/page/employees-challenges');
    } catch {
      setDeleteLoading(false);
    }
  };

  return {
    showDeleteButton,
    deleteLoading,
    showDelete,
    deleteRequest,
    setShowDelete,
  };
};
