import * as React from 'react';
import { Box, Card, CardContent, Collapse, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { Colors } from '../../../theme/colors';
import { ReactComponent as ArrowIcon } from '../../../assets/svg/feature/arrow.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/svg/feature/calendar.svg';
import { ReactComponent as StepsIcon } from '../../../assets/svg/feature/metrics.svg';
import moment from 'moment/moment';
import { IResult } from '../../../models/MentalAssessment';

const ResultTitle: { [key: string]: { title: string } } = {
  BURNOUT: {
    title: 'Burnout',
  },
  CONFIDENCE: {
    title: 'Confidence',
  },
  DISTRESS: {
    title: 'Distress',
  },
  MENTAL_STRAIN: {
    title: 'Mental Strain',
  },
  STRESS: {
    title: 'Stress',
  },
  TIREDNESS: {
    title: 'Tiredness',
  },
};

export const TestResultItem: React.FC<{ item: IResult; children?: React.ReactNode }> = ({ item, children }) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const metrics = Object.keys(item.metrics).map((key: string) => ({
    ...item.metrics[key],
    title: ResultTitle[key].title,
  }));

  return (
    <Card
      sx={{
        borderRadius: '20px',
        backgroundColor: 'common.white',
        boxShadow: `5px 0px 15px 0px rgba(108, 123, 132, 0.05);`,
      }}
    >
      <CardContent sx={{ display: 'flex', flexDirection: 'column', padding: { xs: '24px 16px', table: '24px' } }}>
        {children}

        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', table: 'row' }, width: '100%' }}>
          <Box sx={{ width: '100%' }}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{ cursor: 'pointer', mb: { xs: '8px', table: '10px' } }}
              onClick={() => handleExpandClick()}
            >
              <Box display="flex" flexDirection={'column'} alignItems="start">
                <Typography sx={{ fontSize: '12px', lineHeight: 1.3, fontWeight: 400, color: Colors.gray[400] }}>
                  Assessment Type:
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: '16px', sm: '18px' },
                    lineHeight: 1.6,
                    fontWeight: 900,
                    color: Colors.gray[700],
                    mr: '8px',
                  }}
                >
                  {item.activityType}
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '4px', mr: '24px' }}>
                  <CalendarIcon width={16} height={16} fill={Colors.gray[300]} />
                  <Typography sx={{ color: Colors.gray[300], fontSize: '14px', fontWeight: 700 }}>
                    {moment(item.activityDate).format('DD/MM/YY')}
                  </Typography>
                </Box>

                <ArrowIcon
                  height={24}
                  width={24}
                  fill={Colors.gray[400]}
                  style={{
                    transform: !expanded ? 'rotate(-90deg)' : 'rotate(90deg)',
                    marginLeft: 'auto',
                    transition: theme.transitions.create('transform', {
                      duration: theme.transitions.duration.shortest,
                    }),
                  }}
                />
              </Box>
            </Box>

            <Box sx={{ display: 'flex', flexWrap: 'wrap', rowGap: '10px', mr: { xs: 0, sm: '-8px' } }}>
              {metrics.map((el, index) => {
                return (
                  <Box key={index} sx={{ pr: { xs: 0, sm: '8px' }, width: { xs: '100%', sm: '50%', lg: '33.333%' } }}>
                    <Box
                      sx={{
                        px: '8px',
                        py: '8px',
                        border: '1px solid #E2E5E6',
                        borderRadius: '20px',
                        height: '100%',
                        display: expanded ? 'block' : 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography
                          sx={{
                            fontSize: { xs: '14px', table: '16px' },
                            lineHeight: 1,
                            color: Colors.gray[700],
                            fontWeight: { xs: 800, sm: 900 },
                            mt: { xs: 0, sm: '6px' },
                          }}
                        >
                          {el.title}
                        </Typography>

                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: { xs: '8px 16px', table: '12px 8px' },
                            gap: '8px',
                            background: el.colorCode,
                            borderRadius: '16px',
                            width: { xs: '140px', table: '175px' },
                          }}
                        >
                          <StepsIcon width={20} height={20} fill={el.iconColor} style={{ flexShrink: 0 }} />

                          {el.value === undefined ? (
                            <Typography
                              sx={{
                                fontSize: { xs: '14px', table: '16px' },
                                lineHeight: 1,
                                color: Colors.gray[700],
                                fontWeight: 800,
                                textAlign: 'center',
                                width: el.labelText.includes('Moderately') ? 'min-content' : 'auto',
                                whiteSpace: el.labelText.includes('Moderately') ? 'break-spaces' : 'normal',
                              }}
                            >
                              {el.labelText}
                            </Typography>
                          ) : (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Typography
                                sx={{
                                  fontSize: { xs: '14px', table: '16px' },
                                  lineHeight: 1,
                                  color: Colors.gray[700],
                                  fontWeight: 800,
                                  mr: '4px',
                                }}
                              >
                                {el.labelText}:
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: { xs: '14px', table: '16px' },
                                  lineHeight: 1,
                                  color: Colors.gray[700],
                                  fontWeight: 400,
                                }}
                              >
                                {el.value}
                              </Typography>
                              <Typography
                                sx={{ fontSize: 10, lineHeight: 1.5, color: Colors.gray[400], fontWeight: 400 }}
                              >
                                %
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>

                      <Collapse key={el.title} in={expanded} timeout="auto" unmountOnExit>
                        <Typography
                          sx={{ fontSize: 14, lineHeight: 1.4, color: '#5D6C6D', fontWeight: 400, mt: '8px' }}
                        >
                          {el.meaningText}
                        </Typography>
                      </Collapse>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
