import React from 'react';
import { Box, Card, Grid, Typography } from '@mui/material';
import { Colors } from '../../../../theme/colors';
import IconPlaceholder from '../../../../assets/defaultBlog.png';
import { ReactComponent as PlusIcon } from '../../../../assets/svg/plus.svg';
import { ReactComponent as LogIcon } from '../../../../assets/svg/feature/log.svg';
import { CustomButton } from '../../../../components/features/CustomButton';
import { RangeMap } from '../../../../models/Shared';
import { SmallBooleanCalendar } from './SmallBooleanCalendar';
import { WeeklyChallengeResultSection } from './WeeklyChallengeResultSection';
import { DayInRow } from './DayInRow';
import { TargetProgress } from './TargetProgress';

export type WeeklyChallengeProps = {
  imageUrl?: string;
  title: string;
  description: string;
  status: 'in-active' | 'active' | 'finished';
  handleOpen: () => void;
  joinBlock: {
    avatars: string[];
    count: number;
    awardUrl?: string;
    isAward?: boolean;
    onClickJoin: (e: any) => void;
  };
  resultBlock?: {
    isTarget: boolean;
    score: number;
    percent: number;
    onClickJoin: (e: any) => void;
  };
  logDataBlock?: {
    isBoolean: boolean;
    rangeDate?: RangeMap;
    isTarget: boolean;
    percent: number;
    score: number;
    handleUpdate: (date: string, isSuccess: boolean) => void;
    handleOpenLogModal: (e: any) => void;
  };
};

type SimplyAvatarListProps = { avatars: string[]; count: number };

const SimplyAvatarList: React.FC<SimplyAvatarListProps> = ({ avatars, count }) => {
  return (
    <Grid item container wrap={'nowrap'}>
      {avatars.map((item, index) => (
        <Grid key={index} item sx={!index ? {} : { ml: '-16px' }}>
          <Box
            sx={{
              height: '36px',
              width: '36px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderWidth: 2,
              borderColor: Colors.white,
              borderStyle: 'solid',
              position: 'relative',
              overflow: 'hidden',
              borderRadius: '100%',
            }}
          >
            <img
              src={item}
              alt={'background'}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
                position: 'absolute',
              }}
            />
          </Box>
        </Grid>
      ))}
      {count > 0 && (
        <Grid
          item
          sx={{
            ml: count > 0 ? 0 : '-16px',
          }}
        >
          <Box
            height={'36px'}
            width={'36px'}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderWidth: 2,
              borderColor: Colors.white,
              backgroundColor: Colors.gray[100],
              borderStyle: 'solid',
              position: 'relative',
              overflow: 'hidden',
              borderRadius: '100%',
            }}
          >
            <Typography color={Colors.gray[700]} sx={{ fontSize: '12px' }}>
              {count}
            </Typography>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export const WeeklyChallenge: React.FC<WeeklyChallengeProps> = ({
  imageUrl,
  title,
  description,
  status,
  logDataBlock,
  joinBlock,
  resultBlock,
  handleOpen,
}) => {
  return (
    <Box sx={{ position: 'relative', height: '100%' }}>
      <Grid item sx={{ position: 'absolute', right: -5, top: '16px' }}>
        <Grid
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: Colors.magenta[300],
            padding: '7px 7px 7px 8px',
            minWidth: '40px',
            borderTopLeftRadius: '30px',
            borderBottomLeftRadius: '30px',
            borderTopRightRadius: '10px',
            maxHeight: '29px',
          }}
        >
          <Typography sx={{ fontSize: 10, fontWeight: 700, color: Colors.white }}>Weekly</Typography>
        </Grid>
        <Grid
          sx={{
            backgroundColor: Colors.magenta[300],
            width: '7px',
            height: '7px',
            position: 'absolute',
            bottom: '-4px',
            right: '0px',
          }}
        />
        <Grid
          sx={{
            backgroundColor: Colors.magenta[500],
            width: '7px',
            height: '7px',
            borderTopRightRadius: '4px',
            borderBottomRightRadius: '4px',
            position: 'absolute',
            bottom: '-7px',
            right: '0px',
          }}
        />
      </Grid>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          boxShadow: 'unset',
          borderRadius: '20px',
          paddingBottom: '24px',
          height: '100%',
        }}
      >
        <Box
          sx={{
            maxHeight: '260px',
            height: { xs: '140px', md: '100%' },
            background: `linear-gradient(180deg, rgba(0, 0, 0, 0.4) 18.68%, rgba(0, 0, 0, 0) 47.54%), url(${
              imageUrl ?? IconPlaceholder
            })`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
          onClick={handleOpen}
        />
        <Box
          display="flex"
          flexDirection="column"
          flex={1}
          sx={{ paddingInline: '16px', marginTop: '16px' }}
          onClick={handleOpen}
        >
          <Typography
            sx={{
              textOverflow: 'ellipsis',
              wordWrap: 'break-word',
              overflow: 'hidden',
              fontSize: '22px',
              lineHeight: 1.3,
              fontWeight: 900,
              marginBottom: '8px',
              maxHeight: 87,
              color: Colors.gray[700],
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              textOverflow: 'ellipsis',
              wordWrap: 'break-word',
              overflow: 'hidden',
              fontSize: '14px',
              lineHeight: 1.45,
              mb: '16px',
              maxHeight: 105,
              color: Colors.gray[700],
            }}
          >
            {description}
          </Typography>
        </Box>
        <Box mt="auto" sx={{ paddingInline: '16px' }}>
          {logDataBlock ? (
            logDataBlock.isBoolean && logDataBlock.rangeDate ? (
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <SmallBooleanCalendar rangeData={logDataBlock.rangeDate} handleUpdate={logDataBlock.handleUpdate} />
              </Box>
            ) : (
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <DayInRow days={logDataBlock.score} sx={{ marginRight: '16px' }} />
                {logDataBlock.isTarget && <TargetProgress percent={logDataBlock.percent} />}
                <CustomButton
                  text={'Join'}
                  sx={{ height: '48px' }}
                  minWidth="160px"
                  inputProps={{
                    variant: 'contained',
                    startIcon: <LogIcon fill={Colors.white} height="16px" width="16px" />,
                  }}
                  onClick={logDataBlock.handleOpenLogModal}
                />
              </Box>
            )
          ) : resultBlock ? (
            <WeeklyChallengeResultSection {...resultBlock} />
          ) : (
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <SimplyAvatarList avatars={joinBlock.avatars} count={joinBlock.count} />
              <CustomButton
                text={'Join'}
                sx={{ height: '48px' }}
                minWidth="160px"
                inputProps={{
                  variant: 'contained',
                  startIcon: <PlusIcon width={12} height={12} />,
                }}
                onClick={joinBlock.onClickJoin}
              />
            </Box>
          )}
        </Box>
      </Card>
    </Box>
  );
};
