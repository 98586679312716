import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Colors } from '../../theme/colors';
import { IonContent, IonPage } from '@ionic/react';
import { Box, Container, Grid, Link, Skeleton, Typography, useMediaQuery } from '@mui/material';
import CustomNewHeader from '../../components/features/CustomNewHeader';
import { useHandle } from './useHandle';
import { LeaderBoardProgressWrapper, ProgressWrapper } from '../../components/features/progress/ProgressWrapper';
import theme from '../../theme';
import { ReactComponent as GoalIcon } from '../../assets/svg/goal.svg';
import { LeaderBoardModal } from '../../components/features/progress/LeaderBoardModal';
import { ReactComponent as SyncIcon } from '../../assets/svg/feature/sync.svg';
import { ReactComponent as EmployIcon } from '../../assets/svg/feature/employees.svg';
import { LeaderBoardList } from '../../components/features/progress/LeaderBoardList';
import { AwardCard } from '../../components/features/progress/AwardCard';
import { RemindChallengeDay } from '../../components/features/progress/RemindChallengeDay';
import { ReactComponent as ExportIcon } from '../../assets/svg/feature/export.svg';
import { ReactComponent as SlackIcon } from '../../assets/svg/feature/slack.svg';
import { RoundIconButton } from '../../components/features/RoundIconButton/RoundIconButton';
import { CustomAvatarList } from '../../components/features/CustomAvatarList';
import { pluralWordRule } from '../../utils/pluralWordRule';
import { useParticipantsHandle } from './useParticipantsHandle';
import { useTeamsHandle } from './useTeamsHandle';
import { ChallengeParticipantsModal } from '../../components/features/modals/ChallengeParticipantsModal';
import { Capacitor } from '@capacitor/core';

type Props = RouteComponentProps<{
  id: string;
}>;
type RouteParams = {
  id: string;
};
export const ChallengesAdminResultsPage: React.FC<Props> = ({ match }) => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const isWeb = !Capacitor.isNativePlatform();
  const {
    isLoading,
    visibleLeaderBoard,
    leaderboardList,
    title,
    breadcrumb,
    type,
    target,
    isGracePeriodEnd,
    triggerLimit,
    lastSync,
    participantsNumber,
    isTeam,
    awardType,
    points,
    dayPassed,
    days,
    slackURL,
    challenge,
    awardedUserCount,
    awardIcon,
    handleOpenDetail,
    handleOpenLeaderBoard,
    handleCloseLeaderBoard,
    handleExportRanking,
    handleExportParticipants,
    handleExportTeams,
    listRef,
    handleScrollEvent: handleLeaderBoardScrollEvent,
  } = useHandle(match.params.id);

  const [showParticipantsModal, setShowParticipantsModal] = useState(false);
  const [showTeamsModal, setShowTeamsModal] = useState(false);

  const { participants, participantsLoading, divParticipantsRef, handleScrollEvent } = useParticipantsHandle(
    match.params as RouteParams,
  );
  const { teams, teamsLoading, divTeamsRef, handleTeamScrollEvent } = useTeamsHandle(
    match.params as RouteParams,
    challenge,
  );
  return (
    <IonPage>
      <CustomNewHeader
        label={title}
        breadcrumb={{
          Icon: GoalIcon,
          list: breadcrumb,
        }}
        inProgress={isLoading}
        rightAction={
          <Box sx={{ alignSelf: { xs: 'stretch', md: 'center' }, marginLeft: 'auto' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: { xs: 'unset', md: '10px 24px' },
                borderRadius: 100,
                height: { xs: 36, md: 'unset' },
                width: { xs: 36, md: 'fit-content' },
                border: `1px solid ${Colors.orange[300]}`,
                background: '#FAEED7',
                marginRight: { xs: 'unset', md: '16px' },
                cursor: 'pointer',
              }}
              onClick={handleOpenDetail}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: 16,
                  justifyContent: 'space-between',
                  height: 3,
                  marginRight: { xs: 'auto', md: '8px' },
                  marginLeft: { xs: 'auto', md: 'unset' },
                }}
              >
                <Box sx={{ height: 3, width: 3, borderRadius: 3, background: Colors.orange[500] }} />
                <Box sx={{ height: 3, width: 3, borderRadius: 3, background: Colors.orange[500] }} />
                <Box sx={{ height: 3, width: 3, borderRadius: 3, background: Colors.orange[500] }} />
              </Box>
              {!mdDown && (
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWidth: 400,
                    lineHeight: 1,
                    color: Colors.orange[500],
                    whiteSpace: 'nowrap',
                  }}
                >
                  Challenge details
                </Typography>
              )}
            </Box>
          </Box>
        }
      />
      <IonContent style={{ '--padding-bottom': '36px', '--background': Colors.gray[50] }}>
        <Container maxWidth="lg">
          <Grid container spacing="24px">
            <ProgressWrapper>
              <RemindChallengeDay isLoading={isLoading} days={days} daysPassed={dayPassed} />
              <Box
                sx={{
                  display: 'flex',
                  flex: 1,
                  flexWrap: { xs: 'wrap', table: 'unset' },
                  background: 'white',
                  padding: { xs: '20px 24px', sm: '18px 17px 24px 25px' },
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    marginRight: { xs: 0, table: '16px' },
                    marginBottom: { xs: '24px', table: 'unset' },
                    maxWidth: { xs: 'unset', table: 354 },
                  }}
                >
                  <AwardCard
                    isLoading={isLoading}
                    isFirst={true}
                    awardsType={awardType}
                    points={points}
                    awardCounts={awardedUserCount}
                    awardIcon={awardIcon}
                  />
                </Box>
                <Box sx={{ width: { xs: '100%', table: '240px' } }}>
                  <Box>
                    <Typography sx={{ fontSize: '12px', color: Colors.gray[400], fontWeight: 900, mb: '12px' }}>
                      Participants:
                    </Typography>

                    {challenge && participants.length > 0 && (
                      <CustomAvatarList
                        sx={{ cursor: 'pointer' }}
                        challenge={challenge}
                        type={'lg'}
                        onClick={() => setShowParticipantsModal(true)}
                      />
                    )}
                  </Box>
                  {isTeam && (
                    <Box sx={{ mt: participants.length ? '16px' : 0 }}>
                      <Typography sx={{ fontSize: '12px', color: Colors.gray[400], fontWeight: 900, mb: '12px' }}>
                        Teams:
                      </Typography>

                      {challenge && teams.length > 0 && (
                        <CustomAvatarList
                          sx={{ cursor: 'pointer' }}
                          challenge={challenge}
                          isTeam
                          type={'lg'}
                          onClick={() => setShowTeamsModal(true)}
                        />
                      )}
                    </Box>
                  )}
                  {slackURL && (
                    <Box sx={{ pt: '16px', mt: '20px', borderTop: '1px dashed #F2F2F2' }}>
                      <Typography sx={{ fontSize: '12px', color: Colors.gray[400], fontWeight: 700, mb: '8px' }}>
                        Discussion board:
                      </Typography>

                      <RoundIconButton
                        sx={{
                          marginBottom: {
                            xs: '4px',
                            sm: 0,
                            width: { xs: '100%', sm: 'auto' },
                          },
                          alignSelf: { xs: 'stretch', sm: 'unset' },
                          backgroundColor: Colors.gray[50],
                          width: '100%',
                        }}
                        Icon={SlackIcon}
                        size={36}
                        background={Colors.white}
                        roundColor={Colors.white}
                        textColor={Colors.gray[600]}
                        roundBorderColor={Colors.gray[150]}
                        textSx={{
                          fontSize: '14px',
                          maxWidth: '150px',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                        label={title}
                        onClick={() => window.open(slackURL)}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </ProgressWrapper>
            <LeaderBoardProgressWrapper>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'baseline',
                  paddingInline: '24px',
                }}
              >
                <Typography sx={{ fontWeight: 900, fontSize: '20px', color: Colors.gray[700] }}>Leaderboard</Typography>

                {!mdDown && isWeb && (
                  <Box
                    component="button"
                    sx={{
                      display: 'flex',
                      alignItems: 'baseline',
                      marginLeft: 'auto',
                      marginRight: '16px',
                      cursor: 'pointer',
                      background: 'unset',
                    }}
                    onClick={handleExportRanking}
                  >
                    <ExportIcon fill={Colors.gray[400]} style={{ transform: 'translateY(4px)' }} />
                    <Typography sx={{ fontSize: '12px', textDecoration: 'underline', color: Colors.gray[400] }}>
                      Export as .xls file
                    </Typography>
                  </Box>
                )}
                <Link
                  sx={{
                    fontSize: '12px',
                    cursor: 'pointer',
                    color: Colors.orange[500],
                    textDecorationColor: Colors.orange[500],
                  }}
                  onClick={handleOpenLeaderBoard}
                >
                  View full
                </Link>
              </Box>

              {mdDown && isWeb && (
                <Box
                  component="button"
                  sx={{
                    display: 'flex',
                    alignItems: 'baseline',
                    cursor: 'pointer',
                    marginInline: '24px',
                    background: 'unset',
                  }}
                  onClick={handleExportRanking}
                >
                  <ExportIcon fill={Colors.gray[400]} style={{ transform: 'translateY(4px)' }} />
                  <Typography sx={{ fontSize: '12px', textDecoration: 'underline', color: Colors.gray[400] }}>
                    Export as .xls file
                  </Typography>
                </Box>
              )}
              <Box sx={{ paddingInline: '8px', marginTop: '24px' }}>
                <LeaderBoardList
                  listRef={listRef}
                  handleScrollEvent={handleLeaderBoardScrollEvent}
                  isLoading={isLoading}
                  data={leaderboardList}
                  target={target}
                  type={type}
                  isGracePeriodEnd={isGracePeriodEnd}
                  triggerLimit={triggerLimit}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                  paddingInline: '24px',
                  marginTop: '32px',
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <SyncIcon height="16px" width="16px" fill="#AAC3BB" style={{ marginRight: '8px' }} />
                  {isLoading ? (
                    <Skeleton variant="rectangular" sx={{ height: 12, width: 100, borderRadius: '4px' }} />
                  ) : (
                    <Typography sx={{ fontSize: '12px', color: '#AAC3BB', '& b': { fontWeight: 700 } }}>
                      Last sync: <b>{lastSync}</b>
                    </Typography>
                  )}
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <EmployIcon height="16px" width="16px" fill="#AAC3BB" style={{ marginRight: '8px' }} />
                  {isLoading ? (
                    <Skeleton variant="rectangular" sx={{ height: 12, width: 100, borderRadius: '2px' }} />
                  ) : (
                    <Typography sx={{ fontSize: '12px', color: '#AAC3BB', '& b': { fontWeight: 700 } }}>
                      <b>{participantsNumber}</b>{' '}
                      {isTeam
                        ? `team${pluralWordRule(participantsNumber)}`
                        : `participant${pluralWordRule(participantsNumber)}`}
                    </Typography>
                  )}
                </Box>
              </Box>
            </LeaderBoardProgressWrapper>
          </Grid>
        </Container>
        <LeaderBoardModal
          listRef={listRef}
          handleScrollEvent={handleLeaderBoardScrollEvent}
          isLoading={isLoading}
          visible={visibleLeaderBoard}
          onClose={handleCloseLeaderBoard}
          data={leaderboardList}
          target={target}
          type={type}
          isGracePeriodEnd={isGracePeriodEnd}
          triggerLimit={triggerLimit}
          handleExport={handleExportRanking}
        />

        <ChallengeParticipantsModal
          show={showParticipantsModal}
          divRef={divParticipantsRef}
          isAdmin={true}
          isUserParticipant={false}
          isParticipationLocked={false}
          participants={participants}
          loading={participantsLoading}
          isTeam={false}
          teams={teams}
          handleScrollEvent={handleScrollEvent}
          onClose={() => setShowParticipantsModal(false)}
          handleExport={handleExportParticipants}
        />

        <ChallengeParticipantsModal
          show={showTeamsModal}
          divRef={divTeamsRef}
          isUserParticipant={false}
          isParticipationLocked={false}
          participants={participants}
          loading={teamsLoading}
          isTeam={true}
          teams={teams}
          handleScrollEvent={handleTeamScrollEvent}
          onClose={() => setShowTeamsModal(false)}
          handleExport={handleExportTeams}
        />
      </IonContent>
    </IonPage>
  );
};
