import React, { FC } from 'react';
import { Box, SvgIcon, Typography } from '@mui/material';
import moment from 'moment';

import { ReactComponent as Automatic } from '../../../assets/svg/automatic.svg';
import { IMetric } from '../../../models/Metrics';
import { ReactComponent as SaveIcon } from '../../../assets/svg/feature/save.svg';
import { ReactComponent as Upwards } from '../../../assets/svg/upwards.svg';
import { ReactComponent as Downwards } from '../../../assets/svg/downwards.svg';
import { ReactComponent as Goal } from '../../../assets/svg/goal_indicator.svg';
import { ReactComponent as WearableMetric } from '../../../assets/svg/wearable-metric.svg';
import { ReactComponent as Edit } from '../../../assets/svg/edit.svg';
import { ANALYTICS_PAGE } from '../../../models/enum/ANALYTICS_PAGE';
import { Colors } from '../../../theme/colors';
import { useIonRouter } from '@ionic/react';
import { CustomButton } from '../CustomButton';
import { ProgressToTarget } from '../progress/ProgressToTarget';
import FocusLine from '../../../assets/svg/feature/metric-focuse.svg';
import { METRICS_TYPE } from '../../../models/enum/METRICS_TYPE';
import { Capacitor } from '@capacitor/core';

interface Props {
  metric: IMetric;
  date: any;
  onEdit: (metric: IMetric) => void;
  page?: ANALYTICS_PAGE;
}

export const calculateTime = (n: number, fontSize?: number) => {
  const num = n || 0;
  const hours = num / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  return (
    <span>
      {rhours}
      <span style={{ fontSize: fontSize || 16 }}>{' hrs '}</span>
      {rminutes}
      <span style={{ fontSize: fontSize || 16 }}>{' min'}</span>
    </span>
  );
};

const formatTime = (n: number) => {
  const minutes = n % 60;
  const hours = (n - minutes) / 60;
  return `${hours} hrs ${minutes} min`;
};

const WIN_METRIC_TYPE = [
  'MENTAL_ENERGY_AUDIT',
  'MOOD',
  'SLEEP_QUALITY',
  'SLEEP_DURATION',
  'VEGETABLES_CONSUMPTION',
  'FRUIT_CONSUMPTION',
  'WATER_CONSUMPTION',
  'FREQUENT_MOVEMENT',
  'EXERCISE_MINUTES',
  'STEPS',
];

export const MetricsCard: FC<Props> = ({ metric, date, onEdit, page }) => {
  const router = useIonRouter();

  const metricCategory =
    metric.metric_pillar === 'OTHER'
      ? 'MINE'
      : WIN_METRIC_TYPE.findIndex((item) => item === metric.metric_type) >= 0
      ? 'WIN'
      : 'EXTRA';

  const extractTrend = (trend: string) => {
    if (trend) {
      if (trend === 'INCREASE') {
        return (
          <SvgIcon sx={{ fontSize: '12px', transform: 'translateY(4px)' }}>
            <Upwards />
          </SvgIcon>
        );
      } else if (trend === 'DECREASE') {
        return (
          <SvgIcon sx={{ fontSize: '12px', transform: 'translateY(4px)' }}>
            <Downwards />
          </SvgIcon>
        );
      } else {
        return '-';
      }
    }
  };
  const extractSuffix = () => {
    if (metric?.metric_characteristics?.metric_unit !== 'NOT_AVAILABLE') {
      return metric?.metric_characteristics?.metric_unit?.toLowerCase();
    }
  };
  const extraData = () => {
    if (metric?.metric_samples?.length || metric?.metric_goal) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
          <Typography sx={{ fontSize: '10px', marginRight: '4px', color: Colors.gray[700] }}>Today:</Typography>

          <Box display="flex" alignItems="center" sx={{ pr: '4px' }}>
            {metric?.metric_type === 'SLEEP_DURATION' ? (
              <Typography sx={{ fontSize: '10px', fontWeight: 'bold', color: Colors.gray[700] }}>
                {formatTime(metric?.metric_samples?.[0]?.total_value ?? 0)}
              </Typography>
            ) : (
              <>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    // pr: '4px',
                    color: Colors.gray[700],
                  }}
                >
                  {metric?.metric_samples[0]?.total_value || 0} {extractSuffix()}
                </Typography>
              </>
            )}
          </Box>

          {metric?.metric_samples[0]?.metric_trend ? extractTrend(metric?.metric_samples[0]?.metric_trend) : '-'}
        </Box>
      );
    } else {
      return <Typography sx={{ fontSize: '10px', color: Colors.gray[700] }}>No data available</Typography>;
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        height: '120px',
        padding: '16px',
        borderRadius: '10px',
        background: Colors.white,
        mb: '8px',
      }}
    >
      {metric?.primary ? (
        <>
          <Box
            sx={{
              position: 'absolute',
              zIndex: -1,
              left: '-5px',
              top: 0,
              bottom: 0,
              width: '16px',
              borderTopLeftRadius: '16px',
              borderBottomLeftRadius: '16px',
              background: Colors.magenta[300],
            }}
          />
          <Box sx={{ position: 'absolute', zIndex: 10, top: '-5px', right: '-6px', width: '35px', height: '36px' }}>
            <Box component="img" src={FocusLine} sx={{ height: '100%', width: '100%' }} />
            <Typography
              sx={{
                fontWeight: 'bold',
                position: 'absolute',
                color: Colors.white,
                fontSize: '6px',
                top: '10px',
                ...(Capacitor.getPlatform() === 'android'
                  ? { transform: 'rotate(45deg) scale(0.74)', right: '-1px' }
                  : {
                      transform: 'rotate(45deg)',
                      right: '3px',
                    }),
              }}
            >
              FOCUS
            </Typography>
          </Box>
        </>
      ) : null}
      {metric.metric_goal && (
        <Goal
          overflow="visible"
          height={24}
          width={24}
          style={{
            position: 'absolute',
            top: -6,
            left: -6,
            display: 'block',
            overflow: 'visible',
            transform: 'rotate(-30deg)',
          }}
        />
      )}
      <Box>
        <Box display="flex" alignItems="center">
          {(metric?.metric_characteristics?.tracking_type === 'AUTO' && metric?.metric_type !== 'BMI') ||
          metric?.metric_characteristics?.tracking_type === 'HYBRID' ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: 'warning.main',
                height: '14px',
                width: '14px',
                borderRadius: '4px',
                mr: 1,
              }}
            >
              <WearableMetric height={10} width={10} />
            </Box>
          ) : null}
          <Typography sx={{ fontSize: '14px', fontWeight: '600', color: Colors.gray[700] }}>{metric.title}</Typography>
          {metric?.metric_type === 'CUSTOM' ? (
            <Edit
              height={16}
              width={16}
              style={{ cursor: 'pointer', marginLeft: 'auto' }}
              stroke={Colors.orange[300]}
              onClick={() => onEdit(metric)}
            />
          ) : null}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: '20px',
          mt: '9px',
          mb: '9px',
        }}
      >
        <Box sx={{ display: 'flex' }}>{extraData()}</Box>
        {metric?.metric_goal && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              background: Colors.orange[100],
              height: '20px',
              borderRadius: '10px',
              paddingInline: '8px',
            }}
          >
            <Typography sx={{ fontSize: '10px', color: Colors.orange[500], mr: '4px' }}>Goal:</Typography>
            <Typography
              sx={{
                fontSize: metric?.metric_type === METRICS_TYPE.SLEEP_DURATION ? '10px' : '12px',
                color: Colors.orange[500],
                fontWeight: '700',
              }}
            >
              {metric?.metric_type === METRICS_TYPE.SLEEP_DURATION
                ? formatTime(metric?.metric_goal.target_value)
                : metric?.metric_goal.target_value}
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: metric?.metric_goal ? 'space-between' : 'flex-end',
        }}
      >
        {metric?.metric_goal && <ProgressToTarget percent={metric.metric_goal.goal_percent || 0} />}
        {metric?.metric_characteristics?.tracking_type !== 'AUTO' ? (
          <CustomButton
            type="bigBlue"
            text="Log"
            width="80px"
            minWidth="80px"
            inputProps={{
              variant: 'contained',
              startIcon: <SaveIcon width={13} height={13} fill={Colors.white} />,
              style: { fontWeight: 'bold', minWidth: 80, fontSize: '12px', height: '28px' },
            }}
            onClick={() =>
              router.push(
                `/page/metrics-log-data?focusDate=${moment(date).format(
                  'YYYY-MM-DD',
                )}&category=${metricCategory}&metricType=${metric.metric_type}&metricTitle=${metric.title}`,
              )
            }
          />
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '24px',
              background: Colors.gray[50],
              padding: '1px 8px 1px 1px',
              borderRadius: '12px',
              width: 'fit-content',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '22px',
                width: '22px',
                borderRadius: '50%',
                background: Colors.gray[150],
                mr: '4px',
              }}
            >
              <SvgIcon htmlColor={Colors.gray[400]} sx={{ fontSize: '12px' }}>
                <Automatic />
              </SvgIcon>
            </Box>

            <Typography sx={{ color: Colors.gray[500], fontSize: 10 }}>{'Auto value'}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
