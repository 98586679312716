import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { setHeaderLoading } from './appSlice';
import { handleBackendError } from '../../utils/handleBackendError';
import { getChallengeMetrics } from '../../lib/api/http/requests/challenge';
import {
  abortChallenge,
  getChallenge,
  getChallengeParticipants,
  getChallengesRequest,
  joinToChallenge,
} from '../../lib/api/http/requests/challenge';
import { Challenge } from '../../models/Challenge';
import { CHALLENGE_STATUS } from '../../models/enum/CHALLENGE_STATUS';
import { DIMENSION } from '../../models/enum/DIMENSION';
import { COMPETITION } from '../../models/enum/COMPETITION';
import { Participant } from '../../models/Participant';

export interface ChallengesState {
  data: {
    challengeMetrics: string[];
    challenge: Challenge | null;

    challengesOngoing: Challenge[];
    challengesOpenToJoin: Challenge[];
    challengesFinished: Challenge[];
    challengesAllView: Challenge[];

    challengesOngoingCount: number;
    challengesOpenToJoinCount: number;
    challengesFinishedCount: number;

    pageOngoing: number;
    pageJoin: number;
    pageFinished: number;
  };
  meta: {
    challengeLoading: boolean;

    challengesOngoingLoading: boolean;
    challengesOpenToJoinLoading: boolean;
    challengesFinishedLoading: boolean;
    challengesAllViewLoading: boolean;

    joinToChallengeLoading: boolean;
    abortChallengeLoading: boolean;
  };
}

const initialState: ChallengesState = {
  data: {
    challengeMetrics: [],
    challenge: null,

    challengesOngoing: [],
    challengesOpenToJoin: [],
    challengesFinished: [],
    challengesAllView: [],

    challengesOngoingCount: 0,
    challengesOpenToJoinCount: 0,
    challengesFinishedCount: 0,
    pageOngoing: 0,
    pageJoin: 0,
    pageFinished: 0,
  },
  meta: {
    challengeLoading: false,

    challengesOngoingLoading: false,
    challengesOpenToJoinLoading: false,
    challengesFinishedLoading: false,
    challengesAllViewLoading: false,

    joinToChallengeLoading: false,
    abortChallengeLoading: false,
  },
};

export interface ChallengeParams {
  page: number;
  size: number;
  searchText: string;
  challengeStatus?: CHALLENGE_STATUS[];
  challengePillars?: DIMENSION[];
  competition?: COMPETITION[];
  metric?: string;
  onlyCorporate?: boolean;
}

export const getOngoingChallenges = createAsyncThunk(
  'challenge/getOngoingChallenges',
  async (params: ChallengeParams, thunkAPI) => {
    try {
      thunkAPI.dispatch(setHeaderLoading(true));

      const { data } = await getChallengesRequest({
        searchText: params.searchText,
        challengePillars: params.challengePillars,
        competition: params.competition,
        page: params.page,
        size: params.size,
        metric: params.metric,
        onlyCorporate: params.onlyCorporate,
        challengeStatus: [CHALLENGE_STATUS.ACTIVE],
      });

      const challenges: Challenge[] = data.content;
      const count = data.totalElements;
      return { challenges, count, page: params.page };
    } catch (error) {
      handleBackendError(error, 'Failed to fetch ongoing challenges');
    } finally {
      thunkAPI.dispatch(setHeaderLoading(false));
    }
  },
);

export const getOpenToJoinChallenges = createAsyncThunk(
  'challenge/getOpenToJoinChallenges',
  async (params: ChallengeParams, thunkAPI) => {
    try {
      thunkAPI.dispatch(setHeaderLoading(true));
      const { data } = await getChallengesRequest({
        searchText: params.searchText,
        challengePillars: params.challengePillars,
        competition: params.competition,
        page: params.page,
        size: params.size,
        metric: params.metric,
        onlyCorporate: params.onlyCorporate,
        challengeStatus: [CHALLENGE_STATUS.INACTIVE],
      });

      const challenges: Challenge[] = data.content;
      const count = data.totalElements;
      return { challenges, count, page: params.page };
    } catch (error) {
      handleBackendError(error, 'Failed to fetch to join challenges');
    } finally {
      thunkAPI.dispatch(setHeaderLoading(false));
    }
  },
);

export const getFinishedChallenges = createAsyncThunk(
  'challenge/getFinishedChallenges',
  async (params: ChallengeParams, thunkAPI) => {
    try {
      thunkAPI.dispatch(setHeaderLoading(true));
      const { data } = await getChallengesRequest({
        searchText: params.searchText,
        challengePillars: params.challengePillars,
        competition: params.competition,
        page: params.page,
        size: params.size,
        metric: params.metric,
        onlyCorporate: params.onlyCorporate,
        challengeStatus: [CHALLENGE_STATUS.COMPLETED, CHALLENGE_STATUS.IN_GRACE_PERIOD],
      });

      const challenges: Challenge[] = data.content;
      const count = data.totalElements;
      return { challenges, count, page: params.page };
    } catch (error) {
      handleBackendError(error, 'Failed to fetch finished challenges');
    } finally {
      thunkAPI.dispatch(setHeaderLoading(false));
    }
  },
);

export const getChallengeViewAll = createAsyncThunk(
  'challenge/getChallengeViewAll',
  async (params: ChallengeParams, thunkAPI) => {
    try {
      thunkAPI.dispatch(setHeaderLoading(true));
      const { data } = await getChallengesRequest({
        searchText: params.searchText,
        challengePillars: params.challengePillars,
        competition: params.competition,
        page: params.page,
        size: params.size,
        metric: params.metric,
        onlyCorporate: params.onlyCorporate,
        challengeStatus: params.challengeStatus,
      });
      const challenges: Challenge[] = data.content;
      return { challenges };
    } catch (error) {
      handleBackendError(error, 'Failed to fetch ongoing challenges');
    } finally {
      thunkAPI.dispatch(setHeaderLoading(false));
    }
  },
);
export const getJoinToChallenge = createAsyncThunk(
  'challenge/getJoinToChallenge',
  async (
    params: {
      challengeUUID?: string;
      templateChallengeUUID?: string;
      status?: CHALLENGE_STATUS;
      action?: string;
      startDate?: string;
      endDate?: string;
    },
    thunkAPI,
  ) => {
    try {
      thunkAPI.dispatch(challengeSlice.actions.setOpenToJoin(true));
      const requestParams: {
        challengeUUID?: string;
        templateChallengeUUID?: string;
        action?: string;
        startDate?: string;
        endDate?: string;
      } = {};
      if (params.challengeUUID) {
        requestParams['challengeUUID'] = params.challengeUUID;
      }
      if (params.templateChallengeUUID) {
        requestParams['templateChallengeUUID'] = params.templateChallengeUUID;
      }
      if ('action' in params) {
        requestParams['action'] = params.action;
      }
      if ('startDate' in params) {
        requestParams['startDate'] = params.startDate;
      }
      if ('endDate' in params) {
        requestParams['endDate'] = params.endDate;
      }

      const response = await joinToChallenge(requestParams);
      const data: Challenge = response.data;
      const challengeStatus = params.status;
      const uuid = 'challengeUUID' in requestParams ? requestParams.challengeUUID : requestParams.templateChallengeUUID;

      thunkAPI.dispatch(challengeSlice.actions.setOpenToJoin(false));
      return { data, challengeStatus, uuid };
    } catch (error) {
      handleBackendError(error, 'Failed to join the challenge');
      thunkAPI.dispatch(challengeSlice.actions.setOpenToJoin(false));
    } finally {
    }
  },
);

export const asyncGetChallengeMetrics = createAsyncThunk('challenge/asyncGetChallengeMetrics', async (_, thunkAPI) => {
  try {
    const { data } = await getChallengeMetrics();
    return { data };
  } catch (err) {
    console.log(err);
  }
});
export const getAbortChallenge = createAsyncThunk(
  'challenge/getAbortChallenge',
  async (params: { challengeUUID?: string; templateChallengeUUID?: string; status?: CHALLENGE_STATUS }, thunkAPI) => {
    try {
      const requestParams: { challengeUUID?: string; templateChallengeUUID?: string } = {};
      if (params.challengeUUID) {
        requestParams['challengeUUID'] = params.challengeUUID;
      }
      if (params.templateChallengeUUID) {
        requestParams['templateChallengeUUID'] = params.templateChallengeUUID;
      }

      const response = await abortChallenge(requestParams);
      const data: Challenge = response.data;
      const challengeStatus = params.status;
      const uuid = 'challengeUUID' in requestParams ? requestParams.challengeUUID : requestParams.templateChallengeUUID;

      return { data, challengeStatus, uuid };
    } catch (error) {
      handleBackendError(error, 'Failed to abort the challenge');
    } finally {
    }
  },
);

export const fetchChallenge = createAsyncThunk(
  'challenge/fetchChallenge',
  async (params: { id: string; isTemplate: string | null }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setHeaderLoading(true));
      const requestParams: { challengeUUID?: string; templateChallengeUUID: string } = params.isTemplate
        ? { templateChallengeUUID: params.id }
        : {
            challengeUUID: params.id,
            templateChallengeUUID: params.id,
          };

      const { data } = await getChallenge(requestParams);
      const challenge: Challenge = data;

      return { challenge };
    } catch (error) {
      handleBackendError(error, 'Failed to load the challenge');
      thunkAPI.dispatch(setHeaderLoading(false));
    } finally {
      thunkAPI.dispatch(setHeaderLoading(false));
    }
  },
);

export const challengeSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    setOpenToJoin: (state, action: PayloadAction<boolean>) => {
      state.meta.challengesOpenToJoinLoading = action.payload;
    },
    updateOngoing: (state, action: PayloadAction<Challenge | undefined>) => {
      state.data.challengesOngoing = state.data.challengesOngoing.map((item) =>
        item?.metadata?.challengeUUID === action.payload?.metadata?.challengeUUID
          ? { ...item, userRanking: action.payload?.userRanking, teamRanking: action.payload?.teamRanking }
          : item,
      );
    },
    updateFinished: (state, action: PayloadAction<Challenge | undefined>) => {
      state.data.challengesFinished = state.data.challengesFinished.map((item) =>
        item?.metadata?.challengeUUID === action.payload?.metadata?.challengeUUID
          ? { ...item, userRanking: action.payload?.userRanking, teamRanking: action.payload?.teamRanking }
          : item,
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOngoingChallenges.fulfilled, (state, action) => {
      const challenges = action.payload?.challenges || [];
      if (action.payload?.page && action.payload.page > 0) {
        state.data.challengesOngoing.push(...challenges);
      } else {
        state.data.challengesOngoing = challenges;
      }
      state.data.challengesOngoingCount = action.payload?.count || 0;
      state.meta.challengesOngoingLoading = false;
    });
    builder.addCase(getOngoingChallenges.rejected, (state) => {
      state.meta.challengesOngoingLoading = false;
    });
    builder.addCase(getOngoingChallenges.pending, (state) => {
      state.meta.challengesOngoingLoading = true;
    });

    builder.addCase(getOpenToJoinChallenges.fulfilled, (state, action) => {
      const challenges = action.payload?.challenges || [];
      if (action.payload?.page && action.payload.page > 0) {
        state.data.challengesOpenToJoin.push(...challenges);
      } else {
        state.data.challengesOpenToJoin = challenges;
      }
      state.data.challengesOpenToJoinCount = action.payload?.count || 0;
      state.meta.challengesOpenToJoinLoading = false;
    });
    builder.addCase(getOpenToJoinChallenges.rejected, (state) => {
      state.meta.challengesOpenToJoinLoading = false;
    });
    builder.addCase(getOpenToJoinChallenges.pending, (state) => {
      state.meta.challengesOpenToJoinLoading = true;
    });

    builder.addCase(getFinishedChallenges.fulfilled, (state, action) => {
      const challenges = action.payload?.challenges || [];
      if (action.payload?.page && action.payload.page > 0) {
        state.data.challengesFinished.push(...challenges);
      } else {
        state.data.challengesFinished = challenges;
      }
      state.data.challengesFinishedCount = action.payload?.count || 0;
      state.meta.challengesFinishedLoading = false;
    });
    builder.addCase(getFinishedChallenges.rejected, (state) => {
      state.meta.challengesFinishedLoading = false;
    });
    builder.addCase(getFinishedChallenges.pending, (state) => {
      state.meta.challengesFinishedLoading = true;
    });

    builder.addCase(getChallengeViewAll.fulfilled, (state, action) => {
      state.data.challengesAllView = action.payload?.challenges || [];
      state.meta.challengesAllViewLoading = false;
    });
    builder.addCase(getChallengeViewAll.rejected, (state) => {
      state.meta.challengesAllViewLoading = false;
    });
    builder.addCase(getChallengeViewAll.pending, (state) => {
      state.meta.challengesAllViewLoading = true;
    });

    builder.addCase(getJoinToChallenge.fulfilled, (state, action) => {
      if (action.payload && action.payload.data) {
        state.data.challenge = action.payload.data;
      }

      state.meta.joinToChallengeLoading = false;
    });
    builder.addCase(getJoinToChallenge.rejected, (state) => {
      state.meta.joinToChallengeLoading = false;
    });
    builder.addCase(getJoinToChallenge.pending, (state) => {
      state.meta.joinToChallengeLoading = true;
    });
    builder.addCase(asyncGetChallengeMetrics.fulfilled, (state, action) => {
      state.data.challengeMetrics = action.payload?.data || [];
    });
    builder.addCase(getAbortChallenge.fulfilled, (state, action) => {
      if (action.payload && action.payload.data) {
        state.data.challenge = action.payload.data;
      }

      state.meta.abortChallengeLoading = false;
    });
    builder.addCase(getAbortChallenge.rejected, (state) => {
      state.meta.abortChallengeLoading = false;
    });
    builder.addCase(getAbortChallenge.pending, (state) => {
      state.meta.abortChallengeLoading = true;
    });

    builder.addCase(fetchChallenge.fulfilled, (state, action) => {
      state.data.challenge = action.payload?.challenge || null;
      state.meta.challengeLoading = false;
    });
    builder.addCase(fetchChallenge.rejected, (state) => {
      state.meta.challengeLoading = false;
    });
    builder.addCase(fetchChallenge.pending, (state) => {
      state.meta.challengeLoading = true;
    });
  },
});

export const { updateOngoing, updateFinished } = challengeSlice.actions;

export default challengeSlice.reducer;
