import { getRequest, postRequest } from '../../../../services/HttpService';

export const getModuleSelectorRequest = (userRole: 'USER' | 'ADMIN') => {
  return getRequest('/module-service/contents/user/selector', {
    userRole,
  });
};

export const postEngagementRequest = (logExperiencePointsDTO: { content_type: string; module_uuid: string }) => {
  return postRequest('/user-engagement-service/log/experience_points', logExperiencePointsDTO);
};

export const getBlogsRequest = (params: { userRole: 'USER' | 'ADMIN'; module?: number; week?: number }) => {
  return getRequest(`/module-service/contents/user/${params.userRole}`, {
    ...(params.module && { moduleId: params.module.toString() }),
    ...(params.week && { periodIndex: params.week.toString() }),
  });
};

export const getToolboxRequest = (params: {
  page: number;
  size: number;
  contentType?: string[];
  dimension?: string[];
  searchText?: string;
  sort?: string;
  tag?: string;
}) => {
  return getRequest('/module-service/toolbox', {
    page: `${params.page || 0}`,
    size: `${params.size || 10}`,
    dimension: `${params.dimension || []}`,
    contentType: `${params.contentType || []}`,
    ...(params.searchText && { searchText: params.searchText }),
    ...(params.sort && { sort: params.sort }),
    ...(params.tag && { tag: params.tag }),
  });
};

export const getToolboxSearchRequest = () => {
  return getRequest('/module-service/toolbox/control/textsearch');
};

export const getContentTypesRequest = () => {
  return getRequest('/module-service/utils/contenttypes');
};
