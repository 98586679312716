export const METRICS = [
  /**** MENTAL HEALTH ****/
  { label: 'Current mood', value: 'MOOD' },
  { label: 'Meditation minutes', value: 'MEDITATION_MINUTES' },
  { label: 'Mental energy audit', value: 'MENTAL_ENERGY_AUDIT' },
  /**** SLEEP ****/
  { label: 'Sleep duration', value: 'SLEEP_DURATION' },
  { label: 'Sleep efficiency', value: 'SLEEP_SCORE' },
  { label: 'Subjective sleep quality', value: 'SLEEP_QUALITY' },
  /**** NUTRITION ****/
  { label: 'Water consumption', value: 'WATER_CONSUMPTION' },
  { label: 'Fruit consumption', value: 'FRUIT_CONSUMPTION' },
  { label: 'Vegetable consumption', value: 'VEGETABLES_CONSUMPTION' },
  { label: 'Calories burned', value: 'CALORIES_BURNED' },
  { label: 'Calories intake', value: 'CALORIES_INTAKE' },
  /**** PHYSICAL ACTIVITY ****/
  { label: 'Steps', value: 'STEPS' },
  { label: 'Exercise sessions', value: 'EXERCISE_SESSIONS' },
  { label: 'Distance', value: 'DISTANCE' },
  { label: 'Exercise minutes', value: 'EXERCISE_MINUTES' },
  { label: 'Move every 50min', value: 'FREQUENT_MOVEMENT' },
  /**** HEALTH ****/
  { label: 'Heart Rate Variability', value: 'HEART_RATE_VARIABILITY' },
  { label: 'Resting Heart Rate', value: 'RESTING_HEART_RATE' },
  { label: 'Weight', value: 'WEIGHT' },
  { label: 'BMI', value: 'BMI' },
  /**** WELLBEING ****/
  { label: 'Wellics Index', value: 'WELLBEING' },

  { label: 'Other', value: 'OTHER' },
];
