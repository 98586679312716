import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { COMPETITION } from '../../../models/enum/COMPETITION';
import { CHALLENGE_TYPE } from '../../../models/enum/CHALLENGE_TYPE';

type LayoutVariant = 'boolean' | 'numeric' | 'daily';

export const useHandleMainLayout = () => {
  const detail = useSelector((state: RootState) => state.challengeProgress.data.detail);
  const leaderBoard = useSelector((state: RootState) => state.challengeProgress.data.leaderBoard);

  const isSelf = detail?.competition === COMPETITION.SELF;
  const isFullScreen = (leaderBoard?.instanceList ?? []).length <= 1 && isSelf;

  const getLayoutVariant = (): LayoutVariant => {
    if (detail?.isBoolean) return 'boolean';
    if (
      (detail?.challengeTyp === CHALLENGE_TYPE.LONGER_STREAK || detail?.challengeTyp === CHALLENGE_TYPE.STICK_TO_IT) &&
      !detail?.isBoolean
    )
      return 'daily';
    return 'numeric';
  };

  return { detail, isSelf, isFullScreen, layoutVariant: getLayoutVariant() };
};
