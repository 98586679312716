import { METRICS_TYPE } from '../models/enum/METRICS_TYPE';

type ResultMetricsValidation = {
  success: boolean;
  error?: string;
};

export const metricsValidation = (
  metric: string,
  firstValue: string,
  secondValue?: string,
): ResultMetricsValidation => {
  if (!firstValue && !secondValue) return { success: false, error: 'This field is required' };
  if (secondValue && metric === METRICS_TYPE.SLEEP_DURATION) {
    const firstToNumber = Number(firstValue);
    const secondToNumber = Number(secondValue);
    let error;
    if (firstToNumber > 19) {
      error = 'The value of the hours cannot be more than 19';
    }
    if (secondToNumber > 59) {
      error = 'The value of the minutes cannot be more than 59';
    }
    if (error) {
      return { success: false, error };
    }
    return { success: true };
  }
  if (!firstValue) return { success: false, error: 'This field is required' };
  const value = Number(firstValue);
  switch (metric) {
    case METRICS_TYPE.MOOD:
      if (value === 0) {
        return {
          success: false,
          error: 'The value of the current mood cannot be 0',
        };
      }
      if (value > 5) {
        return {
          success: false,
          error: 'The value of the current mood cannot be more than 5',
        };
      }
      return { success: true };
    case METRICS_TYPE.MEDITATION_MINUTES:
      if (value > 360) {
        return {
          success: false,
          error: 'The value of the meditation minutes cannot be more than 360',
        };
      }
      return {
        success: true,
      };
    case METRICS_TYPE.MENTAL_ENERGY_AUDIT:
      if (value < 1) {
        return {
          success: false,
          error: 'The value of the mental energy audit cannot be less than 1',
        };
      }
      if (value > 10) {
        return {
          success: false,
          error: 'The value of the mental energy audit cannot be more than 10',
        };
      }
      return {
        success: true,
      };
    case METRICS_TYPE.SLEEP_QUALITY:
      if (value < 1) {
        return {
          success: false,
          error: 'The value of the sleep quality cannot be less than 1',
        };
      }
      if (value > 5) {
        return {
          success: false,
          error: 'The value of the sleep quality cannot be more than 5',
        };
      }
      return {
        success: true,
      };
    case METRICS_TYPE.WATER_CONSUMPTION:
      if (value > 30) {
        return {
          success: false,
          error: 'The value of the water consumption cannot be more than 30',
        };
      }
      return {
        success: true,
      };
    case METRICS_TYPE.FRUIT_CONSUMPTION:
      if (value > 15) {
        return {
          success: false,
          error: 'The value of the fruit consumption cannot be more than 15',
        };
      }
      return {
        success: true,
      };
    case METRICS_TYPE.VEGETABLES_CONSUMPTION:
      if (value > 50) {
        return {
          success: false,
          error: 'The value of the vegetables consumption cannot be more than 50',
        };
      }
      return {
        success: true,
      };
    case METRICS_TYPE.CALORIES_BURNED:
      if (value > 5000) {
        return {
          success: false,
          error: 'The value of the calories burned cannot be more than 5000',
        };
      }
      return {
        success: true,
      };
    case METRICS_TYPE.CALORIES_INTAKE:
      if (value > 5000) {
        return {
          success: false,
          error: 'The value of the calories intake cannot be more than 5000',
        };
      }
      return {
        success: true,
      };
    case METRICS_TYPE.STEPS:
      if (value > 300000) {
        return {
          success: false,
          error: 'The value of the steps cannot be more than 300000',
        };
      }
      return {
        success: true,
      };
    case METRICS_TYPE.EXERCISE_SESSIONS:
      if (value > 100) {
        return {
          success: false,
          error: 'The value of the exercise sessions cannot be more than 100',
        };
      }
      return {
        success: true,
      };
    case METRICS_TYPE.DISTANCE:
      if (value > 200) {
        return {
          success: false,
          error: 'The value of the distance cannot be more than 200',
        };
      }
      return {
        success: true,
      };
    case METRICS_TYPE.EXERCISE_MINUTES:
      if (value > 1440) {
        return {
          success: false,
          error: 'The value of the exercise minutes cannot be more than 1440',
        };
      }
      return {
        success: true,
      };
    case METRICS_TYPE.FREQUENT_MOVEMENT:
      if (value > 24) {
        return {
          success: false,
          error: 'The value of the move every 50min cannot be more than 24',
        };
      }
      return {
        success: true,
      };
    case METRICS_TYPE.WEIGHT:
      if (value > 300) {
        return {
          success: false,
          error: 'The value of the weight cannot be more than 300',
        };
      }
      return {
        success: true,
      };
    default:
      return { success: true };
  }
};
