import React from '@ionic/react-router';
import { Box, Container, Grid, Typography } from '@mui/material';
import StyledButton from '../common/Button';
import CustomSwitch from '../CustomSwitch';
import { ReactComponent as Bin } from '../../assets/svg/bin.svg';
import { ReactComponent as Avatar } from '../../assets/svg/avatar.svg';
import { IAccountInfo } from '../../models/Profile';
import { ChangeEvent, FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  asyncClearData,
  asyncDeleteAccount,
  asyncEmailUnsubscribe,
  asyncQuestionnaireUnsubscribe,
  asyncUserConsistencyRewards,
  asyncUserPerformanceRewards,
  setAdminModal,
} from '../../redux/slices/profileSlice';
import CustomCheckbox from '../common/CustomCheckbox';
import { useDebounceBoolean } from '../../hooks/useDebounce';
import useDidMountEffect from '../../hooks/useDidMount';
import GenericModal from '../modals/GenericModal';
import CustomSubHeader from '../structure/CustomSubHeader';

interface Props {
  personalInfo: IAccountInfo;
  setPersonalInfo: (personalInfo: IAccountInfo) => void;
}

const AccountInfoSubSection: FC<Props> = ({ personalInfo, setPersonalInfo }) => {
  const dispatch = useDispatch();
  const [openDeleteAccountModal, setOpenDeleteAccountModal] = useState<boolean>(false);
  const [openClearDataModal, setOpenClearDataModal] = useState<boolean>(false);
  const [action, setAction] = useState(false);

  const debouncedPerformance = useDebounceBoolean(personalInfo?.rewards?.performance_rewards, 500);
  const debouncedConsistency = useDebounceBoolean(personalInfo?.rewards?.consistency_rewards, 500);
  const debouncedEmails = useDebounceBoolean(personalInfo?.subscribed_emails, 500);
  const debouncedQuestionnaires = useDebounceBoolean(personalInfo?.subscribed_questionnaires, 500);

  useDidMountEffect(() => {
    if (!action) return;
    dispatch(asyncUserPerformanceRewards({ performance: debouncedPerformance }));
  }, [debouncedPerformance, dispatch]);

  useDidMountEffect(() => {
    if (!action) return;
    dispatch(asyncUserConsistencyRewards({ consistency: debouncedConsistency }));
  }, [debouncedConsistency, dispatch]);

  useDidMountEffect(() => {
    if (!action) return;
    dispatch(asyncEmailUnsubscribe({ unsubscribed_emails: debouncedEmails }));
  }, [debouncedEmails, dispatch]);

  useDidMountEffect(() => {
    if (!action) return;
    dispatch(asyncQuestionnaireUnsubscribe({ unsubscribed_questionnaires: debouncedQuestionnaires }));
  }, [debouncedQuestionnaires, dispatch]);

  const handleChangeConsistency = (e: ChangeEvent<{ checked: boolean }>) => {
    setPersonalInfo({ ...personalInfo, rewards: { ...personalInfo.rewards, consistency_rewards: e.target.checked } });
    setAction(true);
  };

  const handleChangePerformance = (e: ChangeEvent<{ checked: boolean }>) => {
    setPersonalInfo({ ...personalInfo, rewards: { ...personalInfo.rewards, performance_rewards: e.target.checked } });
    setAction(true);
  };

  const handleNewsletter = (e: ChangeEvent<{ checked: boolean }>) => {
    setPersonalInfo({ ...personalInfo, subscribed_emails: e.target.checked });
    setAction(true);
  };

  const handleQuestionnaireNotifications = (e: ChangeEvent<{ checked: boolean }>) => {
    setPersonalInfo({ ...personalInfo, subscribed_questionnaires: e.target.checked });
    setAction(true);
  };

  const handleClearData = () => {
    setOpenClearDataModal(false);
    dispatch(asyncClearData());
  };

  const handleCloseAccount = () => {
    setOpenDeleteAccountModal(false);
    dispatch(asyncDeleteAccount());
  };

  const handleClickDeleteAccount = () => {
    if (personalInfo.last_admin) {
      dispatch(setAdminModal(true));
    } else {
      setOpenDeleteAccountModal(true);
    }
  };
  return (
    <Container maxWidth="lg">
      {/*
      TODO: remove comment
      */}
      {/* <Box sx={{ border: '1px solid #8B8B8B', my: 6, mx: 4 }} />
      <CustomSubHeader
        label={`Reward System`}
        tooltip={
          'Participate in your organization’s reward system and be eligible for awards. By opting-in, you allow your Admin to see your relevant points. You can opt-in or out any time.'
        }
      />
      <Grid container spacing={2} sx={{ pt: 3 }}>
        {(!personalInfo?.rewards?.company_consistency_rewards &&
          !personalInfo?.rewards?.company_performance_rewards) ?? (
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: 'bold', color: '#00B6BE' }}>{'No available rewards enabled'}</Typography>
          </Grid>
        )}
        <Grid item xs={12} md={6} sx={{ paddingBottom: 2 }}>
          <Box sx={{ display: 'flex', gap: 2, paddingBottom: 2 }}>
            <Typography sx={{ fontWeight: 'bold' }}>Opt-in for Experience Rewards</Typography>
            <CustomSwitch
              disabled={!personalInfo?.rewards?.company_consistency_rewards}
              value={
                !personalInfo?.rewards?.company_consistency_rewards ? false : personalInfo?.rewards?.consistency_rewards
              }
              setValue={handleChangeConsistency}
            />
          </Box>
          <Typography>
            <>
              {
                'Allow your organization to see the points that you have collected through your engagement with the content of '
              }
              <i>Your Focus</i> {' page and be eligible for rewards.'}
            </>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{ paddingBottom: 2 }}>
          <Box sx={{ display: 'flex', gap: 2, paddingBottom: 2 }}>
            <Typography sx={{ fontWeight: 'bold' }}>Opt-in for Achievement rewards</Typography>
            <CustomSwitch
              disabled={!personalInfo?.rewards?.company_performance_rewards}
              value={
                !personalInfo?.rewards?.company_performance_rewards ? false : personalInfo?.rewards?.performance_rewards
              }
              setValue={handleChangePerformance}
            />
          </Box>
          <Typography>
            <>
              {
                'Allow your organization to see the points that you have collected related to your achievements on the Focus metric as tracked through '
              }
              <i>Your Journey</i> {' page and be eligible for rewards.'}
            </>
          </Typography>
        </Grid>
      </Grid> */}
      <Box sx={{ border: '1px solid #8B8B8B', my: 6, mx: 4 }} />
      <CustomSubHeader label={`Features`} />
      <Grid container spacing={2} sx={{ pt: 3 }}>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: 'bold', color: '#FAAF1F' }}>Notifications</Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{ paddingBottom: 2 }}>
          <Box sx={{ display: 'flex', gap: 2, paddingBottom: 2, alignItems: 'center' }}>
            <Typography sx={{ fontWeight: 'bold' }}>Content notifications</Typography>
            <CustomCheckbox checked={personalInfo.subscribed_emails} setValue={handleNewsletter} />
          </Box>
          <Typography>Unselect if you don’t want to receive content notifications.</Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{ paddingBottom: 2 }}>
          <Box sx={{ display: 'flex', gap: 2, paddingBottom: 2, alignItems: 'center' }}>
            <Typography sx={{ fontWeight: 'bold' }}>Questionnaire notifications</Typography>
            <CustomCheckbox
              checked={personalInfo.subscribed_questionnaires}
              setValue={handleQuestionnaireNotifications}
            />
          </Box>
          <Typography>Unselect if you don’t want to receive questionnaire notifications.</Typography>
        </Grid>
        <Grid item xs={12} md={6} />
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: 'bold', color: '#FAAF1F' }}>Account</Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{ paddingBottom: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, paddingBottom: 2 }}>
            <Typography sx={{ fontWeight: 'bold' }}>Delete account</Typography>
          </Box>
          <Typography>
            Click the button below to delete your account and revoke your access to the Wellics™ app. This action is
            irreversible.
          </Typography>
          <StyledButton
            inputProps={{ color: 'warning', variant: 'contained', sx: { mt: 2 } }}
            text="Delete Account"
            startIcon={<Avatar className={'svgWhite'} />}
            onClick={handleClickDeleteAccount}
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{ paddingBottom: 2 }}>
          <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', paddingBottom: 2 }}>
            <Typography sx={{ fontWeight: 'bold' }}>Delete Data</Typography>
          </Box>
          <Typography>
            Click the button below to delete the data you have entered so far. This will not delete your account.
          </Typography>
          <StyledButton
            inputProps={{ color: 'warning', variant: 'contained', sx: { mt: 2 } }}
            text="Delete Data"
            startIcon={<Bin />}
            onClick={() => setOpenClearDataModal(true)}
          />
        </Grid>
      </Grid>
      <GenericModal
        title={'CLOSE ACCOUNT'}
        description={
          'You are about to permanently close your Wellics™ account. This action is irreversible. Are you sure you want to continue?'
        }
        open={openDeleteAccountModal}
        onClose={() => setOpenDeleteAccountModal(false)}
        cancelText={'Cancel'}
        confirmText={'Close Account'}
        confirmFunction={handleCloseAccount}
      />
      <GenericModal
        title={'CLEAR DATA'}
        description={
          'You are about to permanently clear your Wellics™ data. This action is irreversible. Are you sure you want to continue?'
        }
        open={openClearDataModal}
        onClose={() => setOpenClearDataModal(false)}
        cancelText={'Cancel'}
        confirmText={'Clear Data'}
        confirmFunction={handleClearData}
      />
    </Container>
  );
};

export default AccountInfoSubSection;
