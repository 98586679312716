import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getBlogsRequest, getModuleSelectorRequest } from '../../lib/api/http/requests/blogs';
import { Blog } from '../../models/Blog';
import { setHeaderLoading } from './appSlice';
import { handleBackendError } from '../../utils/handleBackendError';

export const getModuleAdminSelector = createAsyncThunk(
  'focusAdmin/getModuleAdminSelector',
  async (params: { callback?: () => void }, thunkAPI) => {
    try {
      const res = await getModuleSelectorRequest('ADMIN');
      const modules = res.data.selector;
      if (modules && modules.length) {
        thunkAPI.dispatch(setModules(modules || []));
      }
    } catch (error) {
      handleBackendError(error, 'Failed to get the selector');
    } finally {
      if (params.callback) params.callback();
    }
  },
);

export const getFocusAdminBlogs = createAsyncThunk(
  'focusAdmin/getFocusBlogs',
  async (params: { pagination: { module?: number; week?: number }; callback?: () => void }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setHeaderLoading(true));
      const res = await getBlogsRequest({ userRole: 'ADMIN', ...params.pagination });
      const blogs: Blog[] = res.data.other;
      const videoData: Array<{ location: string; contentUUID: string }> = res.data.videos;
      const language = res.data.language;
      let url = '';
      let videoUUID: string | undefined;
      if (videoData && videoData.length >= 1) {
        url = videoData[0].location;
        videoUUID = videoData[0]?.contentUUID;
      }
      return { blogs, url, language, videoUUID };
    } catch (error) {
      handleBackendError(error, 'Failed to fetch admin blogs');
    } finally {
      thunkAPI.dispatch(setHeaderLoading(false));
      if (params.callback) params.callback();
    }
  },
);

export interface focusAdminState {
  data: {
    savedVideoTime: number;
    url: string | undefined;
    videoUUID?: string;
    blogs?: Blog[];
    blogsCount: number;
    modules: Array<{
      moduleId: number;
      moduleTitle: string;
      periods: Array<{ periodIndex: number; periodTitle: string; current: boolean }>;
    }>;
    language: string | null;
  };
  meta: {
    blogsLoading: boolean;
  };
}

const initialState: focusAdminState = {
  data: {
    savedVideoTime: 0,
    url: undefined,
    blogs: undefined,
    blogsCount: 0,
    modules: [],
    language: null,
  },
  meta: {
    blogsLoading: false,
  },
};

export const focusAdminSlice = createSlice({
  name: 'focusAdmin',
  initialState,
  reducers: {
    setModules(state, action) {
      state.data.modules = action.payload;
    },
    setSavedVideoTime(state, action) {
      state.data.savedVideoTime = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFocusAdminBlogs.pending, (state) => {
      state.meta.blogsLoading = true;
    });
    builder.addCase(getFocusAdminBlogs.fulfilled, (state, action) => {
      if (action.payload) {
        state.meta.blogsLoading = false;
        state.data.blogs = action.payload?.blogs || [];
        state.data.url = action.payload?.url || '';
        state.data.language = action.payload?.language || null;
        state.data.videoUUID = action.payload?.videoUUID;
      }
    });
    builder.addCase(getFocusAdminBlogs.rejected, (state) => {
      state.meta.blogsLoading = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setModules, setSavedVideoTime } = focusAdminSlice.actions;

export default focusAdminSlice.reducer;
