import { IconButton, styled } from '@mui/material';
import { Colors } from '../../theme/colors';
import React from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

type Props = {
  disabled?: boolean;
  sxContainer?: SxProps<Theme>;
  onClick?: () => void;
};
const StyledButton = styled(IconButton)(({ theme }) => ({
  borderColor: Colors.gray[100],
  borderWidth: 1,
  borderStyle: 'solid',
  marginLeft: '0px',
  marginRight: '0px',
  width: 28,
  height: 28,
  backgroundColor: Colors.white,
  '.MuiButtonBase-root:disabled': {
    borderColor: Colors.gray[100],
    backgroundColor: Colors.gray[50],
  },
}));
export const StyledIconButton: React.FC<Props> = ({ children, disabled = false, sxContainer = {}, onClick }) => {
  return (
    <StyledButton sx={sxContainer} disabled={disabled} onClick={onClick}>
      {children}
    </StyledButton>
  );
};
