import Grid from '@mui/material/Grid';
import React from 'react';
import { Box, Tooltip, Typography, useMediaQuery } from '@mui/material';
import InputField from '../../../components/features/CustomTextField';
import theme from '../../../theme';
import { CustomButton } from '../../../components/features/CustomButton';
import TextAreaField from '../../../components/features/CustomTextarea';
import { InfoOutlined } from '@mui/icons-material';
import { UploadImage } from './UploadImage';
import { EditForm } from '../hooks/useHandle';

type Props = {
  form: EditForm;
  isAvailableToSecondForm: boolean;
  onClick: () => void;
  onUpload: (file: string) => void;
  handleChangeTitle: (value: string) => void;
  handleChangeShortDescription: (value: string) => void;
};
export const FirstForm: React.FC<Props> = ({
  form,
  isAvailableToSecondForm,
  onClick,
  onUpload,
  handleChangeTitle,
  handleChangeShortDescription,
}) => {
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid>
      <Grid container sx={{ paddingBottom: '24px', paddingTop: { xs: '16px', md: '24px' } }}>
        <Grid xs={12} md={3}>
          <Typography
            sx={{
              fontWeight: { xs: 900, md: 'bold' },
              fontSize: 14,
              lineHeight: 1.4,
              color: 'text.primary',
            }}
          >
            {'Challenge photo'}
          </Typography>
        </Grid>

        <Grid xs={12} md={9} sx={{ mt: { xs: '8px', md: 0 } }}>
          <UploadImage
            label={'Change a challenge picture.'}
            imageUrl={form.imageBase64 || form.imageUrl}
            onUpload={onUpload}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ pt: '24px', borderTop: '1px dashed #F2F2F2' }}>
        <Grid xs={12} md={3} sx={{ paddingTop: { xs: 0, md: '32px' } }}>
          <Typography
            sx={{
              fontWeight: { xs: 900, md: 'bold' },
              fontSize: 14,
              lineHeight: 1.4,
              color: 'text.primary',
            }}
          >
            {'Information'}
          </Typography>
        </Grid>
        <Grid xs={12} md={9}>
          <Box sx={{ mt: { xs: '16px', md: 0 } }}>
            <InputField
              bold={true}
              label={
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: 'fit-content',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 14,
                      lineHeight: 1.4,
                      color: 'text.primary',
                      mr: '4px',
                    }}
                  >
                    {'Title'}
                  </Typography>
                </Box>
              }
              setValue={(e) => handleChangeTitle(e.target.value)}
              name="title"
              value={form.title}
              placeholder={'Enter title'}
            />
          </Box>

          <Box sx={{ mt: '24px' }}>
            <TextAreaField
              bold={true}
              label={
                <Tooltip
                  title={
                    <Typography sx={{ fontSize: '10px', maxWidth: 166 }} variant={'body2'}>
                      {
                        'Describe briefly the challenge type, goal and award. It should be aligned with the Goal and Award fields, in the next step.'
                      }
                    </Typography>
                  }
                  placement={xsDown ? 'bottom-end' : 'top-start'}
                  enterTouchDelay={0}
                  leaveTouchDelay={10000}
                  sx={{ mx: 1, color: theme.palette.grey[600] }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: 'fit-content',
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        fontSize: 14,
                        lineHeight: 1.4,
                        color: 'text.primary',
                        mr: '4px',
                      }}
                    >
                      {'Short description'}
                    </Typography>
                    <InfoOutlined style={{ width: '12px', height: '12px' }} fontSize={'inherit'} color={'inherit'} />
                  </Box>
                </Tooltip>
              }
              setValue={(e) => handleChangeShortDescription(e.target.value)}
              name="description"
              value={form.shortDescription}
              placeholder={'Enter description'}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid sx={{ mt: { xs: '50px', md: '40px' }, textAlign: 'center' }}>
        <CustomButton
          type={'bigOrange'}
          text={'Continue'}
          width={'200px'}
          sx={{ height: 36, fontSize: '14px', lineHeight: '14px' }}
          disabled={!isAvailableToSecondForm}
          onClick={onClick}
        />
      </Grid>
    </Grid>
  );
};
