import React from 'react';
import { Box, FormControl, MenuItem, OutlinedInput, Select, styled, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Colors } from '../../theme/colors';

const MenuProps = {
  PaperProps: {
    style: {
      padding: '4px 8px',
      maxHeight: '400px',
      borderRadius: '20px',
      marginTop: '8px',
      boxShadow: 'var(--shadow-button)',
    },
  },
};

const StyledSelect = styled(Select)({
  fontSize: 14,
  fontWeight: 700,
  background: 'white',
  borderRadius: '999px',
  paddingInline: '8px',
  boxShadow: 'var(--shadow-button)',
  '& *': {
    border: 'none !important',
  },
});

const StyledMenuItem = styled(MenuItem)({
  mb: '4px',
  fontSize: '14px',
  borderRadius: '20px',
  fontWeight: '700',
  whiteSpace: 'pre-wrap',
  '&.Mui-selected': {
    backgroundColor: '#E2E8ED',
    fontWeight: '900',
  },
});

type CustomDropdownProps = {
  width?: number | string;
  label?: string;
  fullWidth?: boolean;
  small?: boolean;
  placeholder?: string;
  disabled?: boolean;
  list: { label: string; value: string }[];
  selected: string;
  setSelected: (s: string) => void;
};

const CustomLabelValueDropdown: React.FC<CustomDropdownProps> = ({
  width = 300,
  label,
  fullWidth,
  small,
  placeholder,
  list,
  disabled = true,
  selected,
  setSelected,
}) => {
  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    if (value !== selected) {
      setSelected(value);
    }
  };

  return (
    <div>
      <FormControl sx={{ width: fullWidth ? '100%' : width, position: 'relative' }} disabled={disabled}>
        {label && (
          <Box sx={{ position: 'absolute', top: '-24px', left: '12px' }}>
            <Typography sx={{ fontWeight: 'bold', fontSize: 16, color: 'text.primary' }}>{label}</Typography>
          </Box>
        )}
        <StyledSelect
          displayEmpty
          value={list.find((l) => l.value === selected) ? selected : ''}
          label={list.find((i) => i.value === selected)?.label}
          onChange={handleChange}
          input={<OutlinedInput />}
          MenuProps={MenuProps}
          IconComponent={ExpandMoreIcon}
          sx={{ height: small ? 36 : 40 }}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {placeholder && (
            <MenuItem disabled={disabled} value="">
              <em style={{ fontSize: '16px', fontWeight: 400, color: Colors.gray[200], fontStyle: 'normal' }}>
                {placeholder}
              </em>
            </MenuItem>
          )}
          {list.map((item) => (
            <StyledMenuItem key={item.value} value={item.value}>
              {item.label}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </div>
  );
};

export default CustomLabelValueDropdown;
