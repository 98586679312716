import { IonContent, IonHeader, IonMenu, IonMenuToggle, IonToolbar, useIonRouter } from '@ionic/react';

import React, { FunctionComponent, useEffect, useState } from 'react';
import { Box, Grid, styled, Tab, Tabs, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ReactComponent as FocusIcon } from '../../assets/svg/focus.svg';
import { ReactComponent as ToolboxIcon } from '../../assets/svg/book.svg';
import { ReactComponent as JourneyIcon } from '../../assets/svg/journey.svg';
import { ReactComponent as RewardsIcon } from '../../assets/svg/reward.svg';
import { ReactComponent as EmployeesIcon } from '../../assets/svg/employees.svg';
import { ReactComponent as GoalIcon } from '../../assets/svg/feature/goal.svg';
import { ReactComponent as StepsIcon } from '../../assets/svg/steps.svg';

import { ReactComponent as LogoutIcon } from '../../assets/svg/logout.svg';

import { ReactComponent as LogoMiniIcon } from '../../assets/svg/wellics_mini.svg';
import { ReactComponent as LogoFullIcon } from '../../assets/svg/wellics_full.svg';

import { ReactComponent as ArrowIcon } from '../../assets/svg/feature/arrow.svg';

import { getRole } from '../../utils/getRole';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { performLogout } from '../../utils/performLogout';
import { AdminChallengesPage } from '../../models/AdminRoutes';

interface AppPage {
  url: string;
  Icon?: FunctionComponent;
  title: string;
  isFillIcon?: boolean;
}

const appPages: AppPage[] = [
  {
    title: 'Your Focus',
    url: '/page/your-focus',
    Icon: FocusIcon,
  },
  {
    title: 'Your Challenges',
    url: '/page/your-challenges',
    Icon: GoalIcon,
    isFillIcon: true,
  },
  {
    title: 'Your Trusted Toolbox',
    url: '/page/your-toolbox',
    Icon: ToolboxIcon,
  },
  {
    title: 'Your Journey',
    url: '/page/your-journey',
    Icon: JourneyIcon,
  },
];

const adminPages: AppPage[] = [
  {
    title: "Your Employees' Focus",
    url: '/page/employees-focus',
    Icon: FocusIcon,
  },
  {
    title: "Your Employees' Challenges",
    url: '/page/employees-challenges',
    Icon: GoalIcon,
    isFillIcon: true,
  },
  // {
  //   title: 'Your Trusted Toolbox',
  //   url: '/page/AdminToolbox',
  //   Icon: ToolboxIcon,
  // },
  {
    title: "Your Employees' Journey",
    url: '/page/employees-journey',
    Icon: JourneyIcon,
  },
  {
    title: "Your Employees' Rewards",
    url: '/page/employees-rewards',
    Icon: RewardsIcon,
  },
  // {
  //   title: 'Your Employees Steps',
  //   url: '/page/employees-steps',
  //   Icon: StepsIcon,
  // },
  {
    title: 'Your Employees',
    url: '/page/employees',
    Icon: EmployeesIcon,
  },
];

const getStyledIcon = (selected?: boolean, admin?: boolean) => {
  if (!!selected && !admin) {
    return {
      '& *': {
        stroke: '#00B6BE',
        color: '#00B6BE',
      },
    };
  } else if (!!selected && admin) {
    return {
      '& *': {
        stroke: '#FAAF1F',
        color: '#FAAF1F',
      },
    };
  } else {
    return {
      '& *': {
        stroke: '#8B8B8B',
        color: '#8B8B8B',
      },
    };
  }
};

const getFillStyledIcon = (selected?: boolean, admin?: boolean) => {
  if (!!selected && !admin) {
    return {
      '& *': {
        fill: '#00B6BE',
        color: '#00B6BE',
      },
    };
  } else if (!!selected && admin) {
    return {
      '& *': {
        fill: '#FAAF1F',
        color: '#FAAF1F',
      },
    };
  } else {
    return {
      '& *': {
        fill: '#8B8B8B',
        color: '#8B8B8B',
      },
    };
  }
};

const ButtonExpander = styled(Box)({
  backgroundColor: '#094C53',
  position: 'absolute',
  height: 36,
  width: 36,
  top: 14,
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '100px',
  cursor: 'pointer',
  zIndex: 200,
  display: 'flex',
});

const FadeInBoxLabel = styled(Box)({
  paddingLeft: 1,
  fontSize: '12px',
  fontWeight: 'bold',
  flex: 1,
  opacity: 0,
  textAlign: 'left',
  marginLeft: '16px',
  width: 100,
  animation: 'appear 0.4s 0.1s ease-out forwards',
});

const StyledDivider = styled(Box)({
  width: '50%',
  position: 'relative',
  zIndex: 1,
  borderBottom: '1px solid #E2E5E6',
});

type PrimaryNavigationType = {
  expand: boolean;
  setExpand: (b: boolean) => void;
};

const PrimaryNavigation: React.FC<PrimaryNavigationType> = ({ expand, setExpand }) => {
  const router = useIonRouter();
  const [tab, setTab] = useState<number>(0);
  const theme = useTheme();
  const xdDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [imgError, setImgError] = useState<boolean>(false);
  const { accountInfo } = useSelector((state: RootState) => state.profile.data);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (xdDown) setExpand(true);
    // else setExpand(false);
  }, [xdDown, setExpand]);

  useEffect(() => {
    const pathname = router.routeInfo.pathname;
    const regex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;
    const url = router.routeInfo.pathname.replace(regex, '');
    if (appPages.findIndex((p) => pathname.startsWith(p.url)) >= 0) {
      const index = appPages.findIndex((p) => {
        return pathname.startsWith(p.url);
      });
      setTab(index);
    } else if (adminPages.map((p) => p.url).includes(pathname)) {
      const index = adminPages.findIndex((p) => p.url === pathname) + appPages.length;
      setTab(index);
    } else if (AdminChallengesPage.map((p) => p).includes(url)) {
      if (pathname.startsWith('/page/employees-challenges')) {
        setTab(5);
      } else {
        setTab(6);
      }
    } else if (
      pathname === '/page/your-profile' ||
      pathname === '/page/trophy-library' ||
      pathname === '/page/your-points' ||
      pathname === '/page/notifications' ||
      pathname === '/page/notifications-settings'
    ) {
      setTab(99);
    } else if (pathname === '/page/our-support') {
      setTab(100);
    } else if (pathname.startsWith('/page/metrics-log-data')) {
      setTab(3);
    } else if (pathname.startsWith('/page/employees-rewards')) {
      setTab(7);
    } else if (
      pathname.startsWith('/page/mental-assessment-results') ||
      pathname.startsWith('/page/mental-assessment')
    ) {
      setTab(3);
    }
  }, [router.routeInfo.pathname]);

  const handleChange = (newTab: number, url: string) => {
    router.push(url);
    setTab(newTab);
  };

  const handleLogout = () => {
    performLogout();
  };

  const getNormalTabs = () => {
    const hideSpan = tab >= appPages.length || tab === 99 || tab === 100;
    return (
      <Tabs
        orientation="vertical"
        value={tab < appPages.length ? tab : 0}
        aria-label="App Pages tabs"
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
        sx={{
          '& .MuiTabs-indicator': {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'transparent',
            width: 3,
            opacity: hideSpan ? 0 : undefined,
          },
          '& .MuiTabs-indicatorSpan': {
            width: 3,
            height: 24,
            backgroundColor: 'primary.main',
            borderRadius: '99px 0 0 99px',
            opacity: hideSpan ? 0 : undefined,
          },
        }}
      >
        {appPages.map((page, i) => {
          return (
            <Tooltip
              key={i}
              title={<Typography variant={'body2'}>{page.title}</Typography>}
              placement={xdDown ? 'bottom-end' : 'right-end'}
              enterTouchDelay={0}
              leaveTouchDelay={10000}
              disableFocusListener={expand}
              disableHoverListener={expand}
              disableTouchListener={expand}
              disableInteractive={expand}
            >
              <Tab
                value={i}
                label={expand ? <FadeInBoxLabel>{page.title}</FadeInBoxLabel> : undefined}
                icon={page.Icon ? <page.Icon /> : undefined}
                onClick={() => handleChange(i, page.url)}
                sx={{
                  ...(page.isFillIcon ? getFillStyledIcon(tab === i) : getStyledIcon(tab === i)),
                  minWidth: 0,
                  display: 'flex',
                  justifyContent: 'flex-start',
                  flexDirection: 'row',
                  minHeight: 0,
                  padding: xdDown ? '4px 16px' : undefined,
                  '&:active': {
                    backgroundColor: '#EDDFC3',
                  },
                }}
              />
            </Tooltip>
          );
        })}
      </Tabs>
    );
  };

  const getAdminTabs = () => {
    const hideSpan = tab < appPages.length || tab === 99 || tab === 100;
    const actualTab = hideSpan ? appPages.length - 1 : tab - appPages.length;
    return (
      <Tabs
        orientation="vertical"
        value={actualTab}
        aria-label="Admin Pages tabs"
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
        sx={{
          '& .MuiTabs-indicator': {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'transparent',
            width: 3,
            opacity: hideSpan ? 0 : undefined,
          },
          '& .MuiTabs-indicatorSpan': {
            width: 3,
            height: 24,
            backgroundColor: 'warning.main',
            borderRadius: '99px 0 0 99px',
            opacity: hideSpan ? 0 : undefined,
          },
        }}
      >
        {adminPages.map((page, i) => {
          return (
            <Tooltip
              key={appPages.length + i}
              title={<Typography variant={'body2'}>{page.title}</Typography>}
              placement={xdDown ? 'bottom-end' : 'right-end'}
              enterTouchDelay={0}
              leaveTouchDelay={10000}
              disableFocusListener={expand}
              disableHoverListener={expand}
              disableTouchListener={expand}
              disableInteractive={expand}
            >
              <Tab
                label={expand ? <FadeInBoxLabel>{page.title}</FadeInBoxLabel> : undefined}
                icon={page.Icon ? <page.Icon /> : undefined}
                onClick={() => handleChange(appPages.length + i, page.url)}
                sx={{
                  ...(page.isFillIcon
                    ? getFillStyledIcon(tab === appPages.length + i, true)
                    : getStyledIcon(tab === appPages.length + i, true)),
                  minWidth: 0,
                  display: 'flex',
                  justifyContent: 'flex-start',
                  flexDirection: 'row',
                  minHeight: 0,
                  padding: xdDown ? '4px 16px' : undefined,
                  '&:active': {
                    backgroundColor: '#EDDFC3',
                  },
                }}
              />
            </Tooltip>
          );
        })}
      </Tabs>
    );
  };

  const getCompanyLogo = () => {
    if (accountInfo.company_logo && !imgError) {
      return (
        <Box width={expand ? '140px' : '50px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <img
            alt={'company-logo'}
            src={accountInfo.company_logo}
            width={'50px'}
            height={'50px'}
            onError={() => setImgError(true)}
            style={{
              maxHeight: 50,
              objectFit: 'contain',
              borderRadius: '4px',
              overflow: 'hidden',
            }}
          />
        </Box>
      );
    } else {
      return expand ? <LogoFullIcon /> : <LogoMiniIcon />;
    }
  };
  return (
    <>
      {!xdDown && (
        <ButtonExpander style={{ left: expand ? 180 - 36 / 2 : 60 - 36 / 2 }} onClick={() => setExpand(!expand)}>
          {expand ? <ArrowIcon /> : <ArrowIcon style={{ transform: 'rotate(180deg)' }} />}
        </ButtonExpander>
      )}

      <IonMenu
        contentId="main"
        type="overlay"
        style={{
          transition: 'all 0.2s ease-out !important',
          '--width': expand ? '180px' : '60px',
          '--min-width': expand ? '180px' : '60px',
          '--max-width': expand ? '180px' : '60px',
        }}
      >
        <IonHeader>
          <IonToolbar
            style={{
              '--background': '#FFFFFF',
              '--border-width': 0,
              '--min-height': 0,
            }}
          />
        </IonHeader>
        <IonContent
          style={{
            '--background': '#FFFFFF',
          }}
        >
          {/*<Box sx={{ minHeight: '100%' }}>*/}
          <IonMenuToggle autoHide={false}>
            <Grid container direction={'column'} justifyContent={'space-between'} sx={{ minHeight: '100%' }}>
              <Grid item container style={{ flex: 0 }} xs={12}>
                <Grid item container justifyContent={'center'} style={{ paddingTop: xdDown ? 16 : 32 }}>
                  {getCompanyLogo()}
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent={'center'}
                  style={{ paddingTop: xdDown ? 8 : 24, paddingBottom: xdDown ? 4 : 16, position: 'relative' }}
                >
                  <StyledDivider />
                </Grid>
                <Grid item style={{ width: '100%' }} xs={12}>
                  {getNormalTabs()}
                </Grid>
                {getRole() === 'ADMIN' && (
                  <>
                    <Grid
                      item
                      xs={12}
                      container
                      justifyContent={'center'}
                      sx={{ paddingTop: xdDown ? 0.5 : 2, paddingBottom: xdDown ? 0.5 : 2 }}
                    >
                      <StyledDivider />
                    </Grid>
                    <Grid item style={{ width: '100%' }} xs={12}>
                      {getAdminTabs()}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            {/*</Box>*/}
          </IonMenuToggle>
        </IonContent>
      </IonMenu>
    </>
  );
};

export default PrimaryNavigation;
