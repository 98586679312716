import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RouteParams } from '../MetricsLogDataPage';
import { AppDispatch, RootState } from '../../../redux/store';
import {
  asyncAddNewMetric,
  asyncGetMetricsByCategory,
  updateMultiplyMetrics,
} from '../../../redux/slices/journeyPageSlice';
import { METRICS_CATEGORY } from '../../../models/enum/METRICS_CATEGORY';
import { MetricItemTypes, NewMetricSamples } from '../types';
import { useIonRouter } from '@ionic/react';
import moment from 'moment';
import { openToast } from '../../../redux/slices/appSlice';
import { METRICS_TYPE } from '../../../models/enum/METRICS_TYPE';
import { METRICS_UNIT } from '../../../models/enum/METRICS_UNIT';
import { EditCalendarTimelineItem } from '../../ChallengeActivityPage/types';
import { metricsValidation } from '../../../utils/metricsValidation';
import { IMultipleItem } from '../../../models/Metrics';
import { useMediaQuery } from '@mui/material';
import theme from '../../../theme';
import { asyncGetUserPoints } from '../../../redux/slices/profileSlice';

const getMonthStartEnd = (date: Date | string) => {
  const start = moment(date).startOf('month').format('YYYY-MM-DD');
  const end = moment(date).endOf('month').format('YYYY-MM-DD');
  return { start, end };
};

const roundWeight = (val: number, type: string) => {
  if (type === METRICS_TYPE.WEIGHT) return Math.round(val);
  return val;
};

export const useHandle = ({ focusDate, metricType, metricTitle, isCreateMetric, category }: RouteParams) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [focusedDate, setFocusedDate] = useState(focusDate ? new Date(focusDate) : new Date());
  const [focusedMonth, setFocusedMonth] = useState(moment(focusDate ?? new Date()).format('YYYY-MM'));
  const [selectedCategory, setSelectedCategory] = useState<METRICS_CATEGORY>(
    isCreateMetric ? METRICS_CATEGORY.MINE : category ?? METRICS_CATEGORY.WIN,
  );
  const [focusedMetric, setFocusedMetric] = useState(metricType ?? '');
  const [focusedTitle, setFocusedTitle] = useState(metricTitle ?? '');
  const [firstInput, setFirstInput] = useState('');
  const [secondInput, setSecondInput] = useState('');
  const [sliderValue, setSliderValue] = useState(1);
  const [targetFirstInput, setTargetFirstInput] = useState('');
  const [targetSecondInput, setTargetSecondInput] = useState('');
  const [targetSlideValue, setTargetSlideValue] = useState(1);
  const [newMetricSamples, setNewMetricSamples] = useState<NewMetricSamples | undefined>();
  const [timeline, setTimeline] = useState<
    (EditCalendarTimelineItem & { isSleepDuration: boolean; isNoUnit: boolean })[]
  >([]);
  const [metricTotalByFocusedDate, setMetricTotalByFocusedDate] = useState<{ [key: string]: string }>({});

  const [hasGoal, setHasGoal] = useState(false);

  const [isOpenLogData, setIsOpenLogData] = useState(false);

  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));

  const router = useIonRouter();

  const metrics = useSelector((state: RootState) => state.journeyPage.data.metricsByCategory);
  const dispatch: AppDispatch = useDispatch();

  const listMetric: MetricItemTypes[] = useMemo(() => {
    const list = (
      (selectedCategory === METRICS_CATEGORY.WIN
        ? metrics?.win
        : selectedCategory === METRICS_CATEGORY.EXTRA
        ? metrics?.extra
        : selectedCategory === METRICS_CATEGORY.MINE
        ? metrics?.mine
        : []) ?? []
    ).map((metric) => ({
      title: metric.title,
      tooltip: metric.metric_description,
      pillar: metric.metric_pillar,
      metricType: metric.metric_type,
      metricUnit: metric.metric_characteristics.metric_unit,
      maxValue: metric.metric_characteristics.max_value,
      isAuto: metric.metric_characteristics.tracking_type === 'AUTO',
      value: roundWeight(
        metric.metric_samples.find((sample) => sample.date === moment(focusedDate).format('YYYY-MM-DD'))
          ?.manual_value ?? 0,
        metric.metric_type,
      ).toString(),
      totalValue: roundWeight(
        metric.metric_samples.find((sample) => sample.date === moment(focusedDate).format('YYYY-MM-DD'))?.total_value ??
          0,
        metric.metric_type,
      ).toString(),
      autoValue: roundWeight(
        metric.metric_samples.find((sample) => sample.date === moment(focusedDate).format('YYYY-MM-DD'))?.auto_value ??
          0,
        metric.metric_type,
      ),
      timeline: metric.metric_samples.map((item) => ({
        ...item,
        ...(item.auto_value && {
          auto_value: roundWeight(item.auto_value, metric.metric_type),
        }),
        ...(item.manual_value && { manual_value: roundWeight(item.manual_value, metric.metric_type) }),
        ...(item.total_value && { total_value: roundWeight(item.total_value, metric.metric_type) }),
      })),
      goalValue: metric.metric_goal?.target_value,
      goalDate: metric.metric_goal?.start_date,
    }));
    return list;
  }, [metrics, focusedDate, selectedCategory]);

  const isMine = useMemo(() => selectedCategory === METRICS_CATEGORY.MINE, [selectedCategory]);

  const handleChangeMetricType = (type: string, title?: string) => () => {
    setFirstInput('');
    setSecondInput('');
    setSliderValue(1);
    setFocusedMetric(type);
    if (title) {
      setFocusedTitle(title);
    }
    if (xsDown) {
      const metric = listMetric.find((item) => item.metricType === type && item.title === title);
      if (!isCreateMetric && !metric?.isAuto) {
        setIsOpenLogData(true);
      }
    }
    const { start, end } = getMonthStartEnd(focusedDate);
    dispatch(
      asyncGetMetricsByCategory({
        startDate: start,
        endDate: end,
        searchDate: moment(focusedDate).format('YYYY-MM-DD'),
        metricType: type,
        title: title,
        category: selectedCategory,
      }),
    );
  };

  const handleFetchCategory = async (val: string) => {
    const { start, end } = getMonthStartEnd(focusedDate);
    await dispatch(
      asyncGetMetricsByCategory({
        startDate: start,
        endDate: end,
        searchDate: moment(focusedDate).format('YYYY-MM-DD'),
        category: val as METRICS_CATEGORY,
      }),
    ).unwrap();
    setFocusedMetric('');
    setSelectedCategory(val as METRICS_CATEGORY);
  };

  const handleChangeCategory = (val: string, isSelected: boolean) => () => {
    if (isSelected) return;
    handleFetchCategory(val);
  };

  const openCreateMetric = () => router.push(`/page/metrics-log-data?isCreateMetric=true`, 'forward', 'push');

  const handleShownDateChange = (date: Date, isDay?: boolean) => {
    if (moment(date).format('YYYY-MM') !== focusedMonth) {
      const { start, end } = getMonthStartEnd(date);
      setFocusedMonth(moment(date).format('YYYY-MM'));
      if (!isDay) {
        dispatch(
          asyncGetMetricsByCategory({
            startDate: start,
            endDate: end,
            searchDate: moment(focusedDate).format('YYYY-MM-DD'),
            ...(focusedMetric && { metricType: focusedMetric, title: focusedTitle }),
            category: selectedCategory,
          }),
        );
      }
    }
  };

  const handleChangeDay = (date: Date) => {
    const isAfter = moment(date).isAfter(new Date());
    if (isAfter) {
      dispatch(openToast({ text: 'the selected date cannot be after today', type: 'error' }));
      return;
    }
    handleShownDateChange(date, true);
    if (!moment(date).isSame(focusedDate)) {
      const { start, end } = getMonthStartEnd(date);
      dispatch(
        asyncGetMetricsByCategory({
          startDate: start,
          endDate: end,
          searchDate: moment(date).format('YYYY-MM-DD'),
          metricType: focusedMetric,
          title: focusedTitle,
          category: selectedCategory,
        }),
      );
    }

    setFocusedDate(date);
  };

  const handleAddMetric = () => {
    const metric = listMetric.find((item) => item.metricType === focusedMetric && item.title === focusedTitle);
    if (!metric) return;
    const metricKey = focusedMetric + focusedTitle;
    const newMetric = newMetricSamples?.[metricKey];
    const validation = metricsValidation(focusedMetric, firstInput.toString() ?? '', secondInput.toString());
    if (!validation.success && metric.metricUnit !== METRICS_UNIT.NOT_AVAILABLE) {
      dispatch(openToast({ text: validation.error ?? 'Wrong input value', type: 'error' }));
      return;
    }
    const formatFocusDate = moment(focusedDate).format('YYYY-MM-DD');
    if (focusedMetric === METRICS_TYPE.SLEEP_DURATION) {
      const value = Number(firstInput) * 60 + Number(secondInput);
      if (newMetric) {
        const valueAlreadyAdded = newMetric.metric_samples.findIndex((item) => item.date === formatFocusDate) >= 0;
        const samples = valueAlreadyAdded
          ? newMetric.metric_samples.map((item) =>
              item.date === formatFocusDate
                ? { ...item, date: item.date, value, teamValue: item.newValue + value }
                : item,
            )
          : [
              ...newMetric.metric_samples,
              { date: formatFocusDate, value, newValue: metric.autoValue, teamValue: value + metric.autoValue },
            ];
        setNewMetricSamples({
          ...newMetricSamples,
          [metricKey]: {
            metric_type: metric.metricType,
            title: metric.title,
            group: 'INDIVIDUAL',
            goal_date: newMetric.goal_date,
            goal_value: newMetric.goal_value,
            metric_samples: samples,
          },
        });
        return;
      }
      setNewMetricSamples({
        ...newMetricSamples,
        [metricKey]: {
          metric_type: metric.metricType,
          title: metric.title,
          group: 'INDIVIDUAL',
          goal_date: metric.goalDate,
          goal_value: metric.goalValue?.toString(),
          metric_samples: [
            {
              date: formatFocusDate,
              value: value,
              newValue: metric.autoValue,
              teamValue: value + metric.autoValue,
            },
          ],
        },
      });
      return;
    }
    if (metric.metricUnit === METRICS_UNIT.NOT_AVAILABLE) {
      if (newMetric) {
        const valueAlreadyAdded = newMetric.metric_samples.findIndex((item) => item.date === formatFocusDate) >= 0;
        const samples = valueAlreadyAdded
          ? newMetric.metric_samples.map((item) =>
              item.date === formatFocusDate
                ? { ...item, date: item.date, value: sliderValue, teamValue: sliderValue + item.newValue }
                : item,
            )
          : [
              ...newMetric.metric_samples,
              {
                date: formatFocusDate,
                value: sliderValue,
                newValue: metric.autoValue,
                teamValue: sliderValue + metric.autoValue,
              },
            ];
        setNewMetricSamples({
          ...newMetricSamples,
          [metricKey]: {
            metric_type: metric.metricType,
            title: metric.title,
            group: 'INDIVIDUAL',
            metric_samples: samples,
            goal_date: newMetric.goal_date,
            goal_value: newMetric.goal_value,
          },
        });
        return;
      }
      setNewMetricSamples({
        ...newMetricSamples,
        [metricKey]: {
          metric_type: metric.metricType,
          title: metric.title,
          group: 'INDIVIDUAL',
          goal_date: metric.goalDate,
          goal_value: metric.goalValue?.toString(),
          metric_samples: [
            {
              date: formatFocusDate,
              value: sliderValue,
              newValue: metric.autoValue,
              teamValue: sliderValue + metric.autoValue,
            },
          ],
        },
      });
      return;
    }
    if (newMetric) {
      const valueAlreadyAdded = newMetric.metric_samples.findIndex((item) => item.date === formatFocusDate) >= 0;
      const samples = valueAlreadyAdded
        ? newMetric.metric_samples.map((item) =>
            item.date === formatFocusDate
              ? { ...item, date: item.date, value: Number(firstInput), teamValue: Number(firstInput) + item.newValue }
              : item,
          )
        : [
            ...newMetric.metric_samples,
            {
              date: formatFocusDate,
              value: Number(firstInput),
              newValue: metric.autoValue,
              teamValue: Number(firstInput) + metric.autoValue,
            },
          ];
      setNewMetricSamples({
        ...newMetricSamples,
        [metricKey]: {
          metric_type: metric.metricType,
          title: metric.title,
          group: 'INDIVIDUAL',
          metric_samples: samples,
          goal_date: newMetric.goal_date,
          goal_value: newMetric.goal_value,
        },
      });
      return;
    }
    setNewMetricSamples({
      ...newMetricSamples,
      [metricKey]: {
        metric_type: metric.metricType,
        title: metric.title,
        group: 'INDIVIDUAL',
        goal_date: metric.goalDate,
        goal_value: metric.goalValue?.toString(),
        metric_samples: [
          {
            date: formatFocusDate,
            value: Number(firstInput),
            newValue: metric.autoValue,
            teamValue: Number(firstInput) + metric.autoValue,
          },
        ],
      },
    });
  };

  const handleAddGoal = () => {
    const metricKey = focusedMetric + focusedTitle;
    const newMetric = newMetricSamples?.[metricKey];
    const metric = listMetric.find((item) => item.metricType === focusedMetric && item.title === focusedTitle);
    if (!metric) return;
    const validation = metricsValidation(
      focusedMetric,
      targetFirstInput ?? '',
      targetSecondInput.length ? targetSecondInput : undefined,
    );
    if (!validation.success && metric.metricUnit !== METRICS_UNIT.NOT_AVAILABLE) {
      dispatch(openToast({ text: validation.error ?? 'Wrong input value', type: 'error' }));
      return;
    }
    const goalValue =
      focusedMetric === METRICS_TYPE.SLEEP_DURATION
        ? Number(targetFirstInput) * 60 + Number(targetSecondInput)
        : metric.metricUnit === METRICS_UNIT.NOT_AVAILABLE
        ? targetSlideValue
        : Number(targetFirstInput);
    if (goalValue === 0) {
      dispatch(openToast({ text: `The value of the goal cannot be 0`, type: 'error' }));
      return;
    }
    const formatFocusDate = moment(focusedDate).format('YYYY-MM-DD');
    if (newMetric) {
      setNewMetricSamples({
        ...newMetricSamples,
        [metricKey]: {
          ...newMetric,
          goal_date: formatFocusDate,
          goal_value: goalValue.toString(),
        },
      });
      return;
    }
    setNewMetricSamples({
      ...newMetricSamples,
      [metricKey]: {
        metric_type: metric.metricType,
        title: metric.title,
        group: 'INDIVIDUAL',
        metric_samples: [],
        goal_date: formatFocusDate,
        goal_value: goalValue.toString(),
      },
    });
  };

  const handleRemoveGoal = () => {
    const metricKey = focusedMetric + focusedTitle;
    const newMetric = newMetricSamples?.[metricKey];
    const metric = listMetric.find((item) => item.metricType === focusedMetric && item.title === focusedTitle);
    if (!metric) return;
    if (newMetric) {
      setNewMetricSamples({
        ...newMetricSamples,
        [metricKey]: {
          ...newMetric,
          goal_date: undefined,
          goal_value: 'remove',
        },
      });
      return;
    }
    setNewMetricSamples({
      ...newMetricSamples,
      [metricKey]: {
        metric_type: metric.metricType,
        title: metric.title,
        group: 'INDIVIDUAL',
        metric_samples: [],
        goal_date: undefined,
        goal_value: 'remove',
      },
    });
  };

  const handleSubmitUpdate = async () => {
    try {
      if (!newMetricSamples || isUpdating) return;
      setIsUpdating(true);
      const logMetricAndGoalList: IMultipleItem[] = [];
      for (const keyName in newMetricSamples) {
        const updatedMetric = newMetricSamples[keyName];
        logMetricAndGoalList.push({
          ...updatedMetric,
          metric_samples: updatedMetric.metric_samples.map((item) => ({
            date: item.date,
            metric_value: item.value.toString(),
          })),
          ...(updatedMetric.goal_value === 'remove' && { goal_date: undefined, goal_value: undefined }),
        });
      }
      if (logMetricAndGoalList.length) {
        await dispatch(updateMultiplyMetrics({ logMetricAndGoalList })).unwrap();
        dispatch(openToast({ text: 'Metrics successfully submitted', type: 'success' }));
        await dispatch(
          asyncGetMetricsByCategory({
            startDate: moment(focusedDate).format('YYYY-MM-DD'),
            endDate: moment(focusedDate).format('YYYY-MM-DD'),
            searchDate: moment(focusedDate).format('YYYY-MM-DD'),
            ...(focusedMetric && { metricType: focusedMetric, title: focusedTitle }),
            category: selectedCategory,
          }),
        ).unwrap();
        setNewMetricSamples(undefined);
        setMetricTotalByFocusedDate({});
        setFocusedMetric('');
        dispatch(asyncGetUserPoints());
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleCreateMetric = async (isMobile?: boolean) => {
    try {
      if (!firstInput.length || !secondInput.length) {
        dispatch(openToast({ text: 'Please enter metric name and current value' }));
        return;
      }
      await dispatch(
        asyncAddNewMetric({
          date: moment(focusDate).format('YYYY-MM-DD'),
          metric: {
            metric_value: Number(secondInput),
            metric_category_group: 'INDIVIDUAL',
            title: firstInput,
            ...(targetFirstInput.length && { goal_value: Number(targetFirstInput) }),
          },
        }),
      ).unwrap();
      const { start, end } = getMonthStartEnd(focusedDate);
      await dispatch(
        asyncGetMetricsByCategory({
          startDate: start,
          endDate: end,
          searchDate: moment(focusedDate).format('YYYY-MM-DD'),
          ...(focusedMetric && { metricType: focusedMetric, title: focusedTitle }),
          category: selectedCategory,
        }),
      ).unwrap();
      setFirstInput('');
      setSecondInput('');
      setTargetFirstInput('');
      setHasGoal(false);
      if (isMobile) {
        setIsOpenLogData(false);
      }
      router.push('/page/metrics-log-data', 'forward', 'replace');
    } catch (err) {
      console.log(err);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleMobileSave = (withGoal?: boolean, isRemove?: boolean) => async () => {
    try {
      const isCustom = focusedMetric === 'CUSTOM';
      const metric = listMetric.find((item) =>
        isCustom ? item.metricType === focusedMetric && item.title === focusedTitle : item.metricType === focusedMetric,
      );
      if (!metric || isUpdating) return;
      const isSleepDuration = metric.metricType === METRICS_TYPE.SLEEP_DURATION;
      const isUnit = metric.metricUnit !== METRICS_UNIT.NOT_AVAILABLE;
      if (isSleepDuration) {
        if (withGoal) {
          const validationGoal = metricsValidation(
            metric.metricType,
            targetFirstInput,
            targetSecondInput.length ? targetSecondInput : undefined,
          );
          if ((targetFirstInput.length || targetSecondInput.length) && !validationGoal.success) {
            dispatch(openToast({ text: validationGoal.error ?? 'Wrong input goal', type: 'error' }));
            return;
          }
        }
        if (firstInput.length || secondInput.length) {
          const validationValue = metricsValidation(metric.metricType, firstInput, secondInput);
          if (!validationValue.success) {
            dispatch(openToast({ text: validationValue.error ?? 'Wrong input value', type: 'error' }));
            return;
          }
        }
      } else {
        if (isUnit) {
          if (withGoal) {
            const validationGoal = metricsValidation(
              metric.metricType,
              targetFirstInput,
              targetSecondInput.length ? targetSecondInput : undefined,
            );
            if (targetFirstInput.length && !validationGoal.success) {
              dispatch(openToast({ text: validationGoal.error ?? 'Wrong input goal', type: 'error' }));
              return;
            }
          }
          if (firstInput.length || secondInput.length) {
            const validationValue = metricsValidation(metric.metricType, firstInput, secondInput);
            if (!validationValue.success) {
              dispatch(openToast({ text: validationValue.error ?? 'Wrong input value', type: 'error' }));
              return;
            }
          }
        }
      }
      setIsUpdating(true);
      const formatFocusDate = moment(focusedDate).format('YYYY-MM-DD');
      const updatedMetric = listMetric.find((item) => item.metricType === focusedMetric && item.title === focusedTitle);
      const goalValue = isSleepDuration
        ? Number(targetFirstInput) * 60 + Number(targetSecondInput)
        : isUnit
        ? Number(targetFirstInput)
        : targetSlideValue;
      console.log(goalValue);
      if (withGoal && !isRemove && goalValue === 0) {
        dispatch(openToast({ text: 'The value of the goal cannot be 0', type: 'error' }));
        return;
      }
      await dispatch(
        updateMultiplyMetrics({
          logMetricAndGoalList: [
            {
              metric_type: metric.metricType,
              title: metric.title,
              group: 'INDIVIDUAL',
              metric_samples: [
                {
                  date: formatFocusDate,
                  metric_value: isSleepDuration
                    ? (Number(firstInput) * 60 + Number(secondInput)).toString()
                    : isUnit
                    ? firstInput
                    : sliderValue.toString(),
                },
              ],
              ...(withGoal
                ? isRemove
                  ? { goal_date: undefined, goal_value: undefined }
                  : {
                      goal_date: formatFocusDate,
                      goal_value: goalValue.toString(),
                    }
                : { goal_date: updatedMetric?.goalDate, goal_value: updatedMetric?.goalValue?.toString() }),
            },
          ],
        }),
      ).unwrap();
      dispatch(asyncGetUserPoints());
      dispatch(openToast({ text: 'Metrics successfully submitted ', type: 'success' }));
      setIsOpenLogData(false);
      await dispatch(
        asyncGetMetricsByCategory({
          startDate: moment(focusedDate).format('YYYY-MM-DD'),
          endDate: moment(focusedDate).format('YYYY-MM-DD'),
          searchDate: moment(focusedDate).format('YYYY-MM-DD'),
          ...(focusedMetric && { metricType: focusedMetric, title: focusedTitle }),
          category: selectedCategory,
        }),
      ).unwrap();
      setNewMetricSamples(undefined);
      setMetricTotalByFocusedDate({});
    } catch (err) {
      console.log(err);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleCloseLogData = () => {
    setFirstInput('');
    setSecondInput('');
    setSliderValue(1);
    setTargetFirstInput('');
    setTargetSecondInput('');
    setTargetSlideValue(1);
    setIsOpenLogData(false);
  };

  const handleOpenLogData = () => {
    setIsOpenLogData(true);
  };

  useEffect(() => {
    const firstFetch = async () => {
      const { start, end } = getMonthStartEnd(focusedDate);
      await dispatch(
        asyncGetMetricsByCategory({
          startDate: start,
          endDate: end,
          searchDate: moment(focusedDate).format('YYYY-MM-DD'),
          ...(focusedMetric && { metricType: focusedMetric, title: focusedTitle }),
          category: selectedCategory,
        }),
      ).unwrap();
      setIsLoading(false);
    };
    firstFetch();
  }, [dispatch]);

  useEffect(() => {
    const timelineItem = timeline.find((item) => item.date === moment(focusedDate).format('YYYY-MM-DD'));
    if (timelineItem && !isCreateMetric) {
      if (timelineItem.isSleepDuration) {
        const minutes = timelineItem.value % 60;
        const hours = (timelineItem.value - minutes) / 60;
        setFirstInput(hours.toString());
        setSecondInput(minutes.toString());
        return;
      }
      if (timelineItem.isNoUnit) {
        setSliderValue(timelineItem.value);
        return;
      }
      setFirstInput(timelineItem.value.toString());
      return;
    }
    setFirstInput('');
    setSecondInput('');
    setSliderValue(1);
  }, [focusedDate, timeline, isCreateMetric]);

  useEffect(() => {
    if (isCreateMetric) {
      return;
    }
    if (focusedDate && focusedMetric) {
      const metricKey = focusedMetric + focusedTitle;
      const metric = listMetric.find((item) => item.metricType === focusedMetric && item.title === focusedTitle);
      const newMetric = newMetricSamples?.[metricKey];
      if (!metric?.goalValue && !newMetric?.goal_value) {
        setTargetSlideValue(1);
        setTargetFirstInput('');
        setTargetSecondInput('');
        return;
      }
      if (newMetric?.goal_value !== 'remove') {
        const goal = Number(newMetric?.goal_value ?? metric?.goalValue);
        if (metric?.metricType === METRICS_TYPE.SLEEP_DURATION) {
          const minutes = goal % 60;
          const hours = (goal - minutes) / 60;
          setTargetFirstInput(hours.toString());
          setTargetSecondInput(minutes.toString());
          return;
        }
        if (metric?.metricUnit === METRICS_UNIT.NOT_AVAILABLE) {
          setTargetSlideValue(goal);
          return;
        }
        setTargetFirstInput(goal.toString());
        return;
      }
      setTargetFirstInput('');
      setTargetSecondInput('');
      setTargetSlideValue(1);
    }
  }, [listMetric, focusedDate, focusedTitle, focusedMetric, newMetricSamples, isCreateMetric]);

  useEffect(() => {
    if (focusedMetric) {
      const metric = listMetric.find((item) => item.metricType === focusedMetric && item.title === focusedTitle);
      const newMetric = newMetricSamples?.[focusedMetric + focusedTitle];
      const newSamples = newMetric?.metric_samples;
      if (metric) {
        setHasGoal(newMetric?.goal_value !== 'remove' && (metric?.goalValue ?? newMetric?.goal_value) ? true : false);
        const metricTimeline: EditCalendarTimelineItem[] = metric.timeline.map((item) => ({
          date: item.date,
          value: item.manual_value ?? 0,
          newValue: item.auto_value ?? 0,
          teamValue: (item.manual_value ?? 0) + (item.auto_value ?? 0),
        }));
        if (!newSamples) {
          setTimeline(
            metricTimeline
              .map((item) => ({ ...item }))
              .map((item) => ({
                ...item,
                isSleepDuration: metric.metricType === METRICS_TYPE.SLEEP_DURATION,
                isNoUnit: metric.metricUnit === METRICS_UNIT.NOT_AVAILABLE,
              })),
          );
          return;
        }
        setTimeline(
          [...newSamples, ...metricTimeline].map((item) => ({
            ...item,
            isSleepDuration: metric.metricType === METRICS_TYPE.SLEEP_DURATION,
            isNoUnit: metric.metricUnit === METRICS_UNIT.NOT_AVAILABLE,
          })),
        );
      }
      return;
    }
    setTimeline([]);
  }, [focusedMetric, focusedTitle, listMetric, newMetricSamples]);

  useEffect(() => {
    if (isCreateMetric) {
      setIsOpenLogData(true);
      setFirstInput('');
      setSecondInput('');
      setTargetFirstInput('');
      setHasGoal(false);
    }
  }, [isCreateMetric]);

  useEffect(() => {
    if (focusedDate && newMetricSamples) {
      const formatDate = moment(focusedDate).format('YYYY-MM-DD');
      const newTotalMetrics: { [key: string]: string } = {};
      for (const key in newMetricSamples) {
        const focusedDateValue = newMetricSamples[key].metric_samples.find((sample) => sample.date === formatDate);
        if (focusedDateValue) {
          const total = focusedDateValue.value + focusedDateValue.newValue;
          newTotalMetrics[key] = total.toString();
        }
      }
      setMetricTotalByFocusedDate(newTotalMetrics);
    }
  }, [focusedDate, newMetricSamples]);

  return {
    isUpdating,
    isLoading,
    metrics,
    selectedCategory,
    listMetric,
    focusedMetric,
    firstInput,
    secondInput,
    sliderValue,
    targetFirstInput,
    targetSecondInput,
    targetSlideValue,
    focusedDate,
    timeline,
    isOpenLogData,
    focusedTitle,
    isMine,
    isDisableSave: typeof newMetricSamples !== 'object',
    newMetricSamples,
    hasGoal,
    metricTotalByFocusedDate,
    setFirstInput,
    setSecondInput,
    setSliderValue,
    handleChangeMetricType,
    handleChangeCategory,
    setTargetFirstInput,
    setTargetSecondInput,
    setTargetSlideValue,
    openCreateMetric,
    handleChangeDay,
    handleShownDateChange,
    handleAddMetric,
    handleAddGoal,
    handleSubmitUpdate,
    handleCreateMetric,
    handleCloseLogData,
    handleMobileSave,
    handleOpenLogData,
    handleRemoveGoal,
  };
};
