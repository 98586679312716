import Vimeo, { PlayEvent, TimeUpdateEvent } from '@u-wave/react-vimeo';
import React from 'react';
import { Colors } from '../../theme/colors';

type VideoComponentProps = {
  isLoading: boolean;
  url?: string;
  lang: string | null;
  startTime?: number;
  onTimeUpdate?: (event: TimeUpdateEvent) => void;
  onPlayVideo?: (event: PlayEvent) => void;
};

export const VideoComponent: React.FC<VideoComponentProps> = ({
  isLoading,
  url,
  lang = null,
  startTime,
  onTimeUpdate,
  onPlayVideo,
}) => {
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '0',
        paddingBottom: '56.25%',
        backgroundColor: Colors.gray[700],
        overflow: 'hidden',
        borderRadius: '8px',
      }}
    >
      {!isLoading && url && (
        <Vimeo
          key={url}
          video={url}
          transparent={false}
          start={startTime}
          onTimeUpdate={onTimeUpdate}
          className="videoPlayer"
          onPlay={onPlayVideo}
          textTrack={!lang ? undefined : lang}
          style={{
            borderRadius: '8px',
            overflow: 'hidden',
            background: 'black',
          }}
        />
      )}
    </div>
  );
};
