import React, { FC } from 'react';
import { Box, CircularProgress, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../../assets/svg/feature/close.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/svg/feature/delete.svg';
import { Colors } from '../../../theme/colors';
import { CustomButton } from '../CustomButton';
import { CustomInfoDialog } from './CustomInfoDialog';

interface ModalProps {
  show: boolean;
  loading: boolean;
  onClose: () => void;
  onDelete: () => void;
}

export const TeamDeleteModal: FC<ModalProps> = ({ show = false, loading, onClose, onDelete }) => {
  return (
    <CustomInfoDialog show={show}>
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 2,
            background: 'rgba(65, 74, 79, 0.3)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress color="inherit" style={{ width: 30, height: 30 }} />
        </Box>
      )}

      <DialogTitle display={'flex'} flexDirection={'column'} sx={{ padding: '16px 16px 0 16px' }}>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <IconButton sx={{ width: 28, height: 28, backgroundColor: Colors.gray[50] }} onClick={onClose}>
            <CloseIcon style={{ width: 12, height: 12 }} fill={Colors.gray[400]} />
          </IconButton>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mt: '6px', maxWidth: '296px', mx: 'auto' }}
        >
          <Typography
            variant="h5"
            sx={{ textAlign: 'center', color: Colors.gray[700], fontWeight: 'bold', fontSize: 18 }}
          >
            Delete the team
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ padding: '0 32px 0 32px' }}>
        <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" sx={{ mt: '16px' }}>
          <Typography variant="h5" sx={{ textAlign: 'center', color: Colors.gray[500], fontWeight: 400, fontSize: 16 }}>
            Are you sure you want to delete the team?
          </Typography>
        </Box>
      </DialogContent>

      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{ margin: '24px auto 50px auto', maxWidth: 320, width: '100%' }}
      >
        <CustomButton
          type={'bigLightRed'}
          text={'Delete'}
          inputProps={{
            variant: 'contained',
            startIcon: <DeleteIcon width={16} height={16} style={{ fill: Colors.red[500] }} />,
          }}
          onClick={onDelete}
        />
        <CustomButton type={'bigLightBlue'} text={'Cancel'} onClick={onClose} />
      </Box>
    </CustomInfoDialog>
  );
};
