import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { ReactComponent as WellicsLogo } from '../assets/svg/wellics_full.svg';
import StyledButton from '../components/common/Button';
import UserService from '../services/UserService';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import useViewIsVisible from '../hooks/useViewIsVisible';
import { ANALYTICS_PAGE } from '../models/enum/ANALYTICS_PAGE';

const LoginPage: React.FC = () => {
  const { wifiConnected } = useSelector((state: RootState) => state.app);
  const visible = useViewIsVisible(ANALYTICS_PAGE.LOGIN);

  const handleLogin = () => {
    if (wifiConnected) {
      UserService.doLogin();
    } else {
      alert('Please check your Internet connection');
    }
  };

  return (
    <IonPage>
      <IonContent>
        <Container maxWidth="xs" sx={{ display: 'flex', flex: 1, height: '100%' }}>
          <Grid container direction={'column'} justifyContent={'space-between'} sx={{ pt: 8, pb: 4 }}>
            <Grid item container justifyContent={'center'}>
              <Grid item xs={12} container justifyContent={'center'}>
                <WellicsLogo />
              </Grid>
              <Grid item xs={12} sx={{ mt: 8 }}>
                <Typography sx={{ textAlign: 'center', fontSize: 20, fontWeight: 'bold' }}>{'Hello there!'}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ textAlign: 'center', fontSize: 28, fontWeight: 'bold' }}>
                  {'Welcome to Wellics™'}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              {/*<StyledButton*/}
              {/*  inputProps={{*/}
              {/*    color: 'primary',*/}
              {/*    variant: 'outlined',*/}
              {/*    sx: { fontSize: 20, fontWeight: 'bold', width: '100%' },*/}
              {/*  }}*/}
              {/*  text="Register"*/}
              {/*/>*/}
              <StyledButton
                inputProps={{
                  color: 'primary',
                  variant: 'contained',
                  sx: { mt: 2, fontSize: 20, fontWeight: 'bold', width: '100%' },
                }}
                text="Login"
                onClick={handleLogin}
              />
            </Grid>
          </Grid>
        </Container>
      </IonContent>
    </IonPage>
  );
};

export default LoginPage;
