import { useState } from 'react';
import { useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import { trackScreen } from '../utils/analytics';

const useIsPageActive = (pageName: string) => {
  const [isVisible, setIsVisible] = useState(false);

  useIonViewWillEnter(() => {
    setIsVisible(true);
    trackScreen(pageName);
  });

  useIonViewWillLeave(() => {
    setIsVisible(false);
  });

  return isVisible;
};

export default useIsPageActive;
