import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import { asyncGetLifeBalance, updateMultiplyMetrics } from '../../../redux/slices/journeyPageSlice';
import { IMultipleItem } from '../../../models/Metrics';
import moment from 'moment';

const defaultValues = [0, 0, 0, 0, 0, 0, 0, 0];

export const useHandleLifeBalance = (date: string) => {
  const [isLifeUpdating, setIsLifeUpdating] = useState(false);
  const [newValues, setNewValues] = useState(defaultValues);
  const [isEdit, setIsEdit] = useState(true);

  const dispatch: AppDispatch = useDispatch();

  const lifeBalance = useSelector((state: RootState) => state.journeyPage.data.lifeBalance);
  const lifeMetrics = useMemo(() => {
    if (!lifeBalance) return;
    const values: number[] = [];
    const labels: { label: string; description: string }[] = [];
    let date = '';
    lifeBalance.forEach((item, idx) => {
      if (item.metric_samples[0]) {
        if (!date) {
          date = item.metric_samples[0].date;
        } else {
          if (moment(item.metric_samples[0].date).isAfter(date)) {
            date = item.metric_samples[0].date;
          }
        }
      }
      values.push(item.metric_samples?.[0]?.total_value ?? 0);
      labels.push({ label: item.title, description: item.metric_description });
    });
    return { values, labels, date };
  }, [lifeBalance]);

  const onLifeBalanceChange = (idx: number, val: number) => () => {
    setNewValues(newValues.map((item, index) => (index === idx ? val : item)));
  };

  const handelSubmitLifeBalance = async () => {
    try {
      if (!lifeBalance || isLifeUpdating) return;
      setIsLifeUpdating(true);
      const logMetricAndGoalList: IMultipleItem[] = [];
      newValues.forEach((val, idx) => {
        if (!val) return;
        const metric = lifeBalance[idx];
        logMetricAndGoalList.push({
          title: metric.title,
          metric_type: metric.metric_type,
          metric_samples: [{ date, metric_value: val.toString() }],
          group: metric.group,
        });
      });
      if (logMetricAndGoalList.length) {
        await dispatch(updateMultiplyMetrics({ logMetricAndGoalList })).unwrap();
        await dispatch(asyncGetLifeBalance({ date })).unwrap();
        setNewValues(defaultValues);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLifeUpdating(false);
    }
  };

  useEffect(() => {
    dispatch(asyncGetLifeBalance({ date }));
  }, [date]);

  useEffect(() => {
    if (lifeMetrics?.date?.length) {
      setIsEdit(moment(date).isAfter(lifeMetrics.date));
      return;
    }
    setIsEdit(true);
  }, [lifeMetrics, date]);

  return { isLifeUpdating, newValues, lifeMetrics, isEdit, onLifeBalanceChange, handelSubmitLifeBalance };
};
