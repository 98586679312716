import { deleteRequest, getRequest, postRequest, putRequest } from '../../../../services/HttpService';
import axios from 'axios';
import { Capacitor } from '@capacitor/core';

const authAxios = axios.create({
  baseURL: process.env.REACT_APP_REDIRECT_URL,
  timeoutErrorMessage: 'Request took long to complete, times up!',
});

const configure = () => {
  authAxios.interceptors.request.use((config) => {
    // if (UserService.isLoggedIn()) {

    if (config.headers === undefined) {
      config.headers = {};
    }
    config.headers['Content-Type'] = `application/x-www-form-urlencoded`;

    // config.headers['client_id'] = `wellmojo`;
    // config.headers['grant_type'] = `password`;
    // config.headers['client_secret'] = `da40c031-3922-4a04-9de0-4f231ad591f8`;

    return Promise.resolve(config);
    // };
    // return UserService.updateToken(cb);
  });
};

configure();

export const loginRequest = (params: { email: string; password: string }) => {
  const urlParms = new URLSearchParams();
  urlParms.append('Content-Type', 'application/x-www-form-urlencoded');
  urlParms.append('grant_type', 'password');
  urlParms.append('client_id', 'wellics-webapp');
  // urlParms.append('client_secret', 'da40c031-3922-4a04-9de0-4f231ad591f8');
  urlParms.append('username', params.email);
  urlParms.append('password', params.password);

  return authAxios.post('/auth/realms/organisation/protocol/openid-connect/token', urlParms);
};

export const getWearableSessionRequest = (redirectUrl: string) => {
  return postRequest(
    '/physical-activity-service/terra/session',
    {},
    {
      applicationType:
        Capacitor.getPlatform() === 'ios' ? 'IOS' : Capacitor.getPlatform() === 'android' ? 'ANDROID' : 'WEB',
      redirectUrl,
    },
  );
};

export const getWearableInfoRequest = (empUUID: string) => {
  return getRequest('/physical-activity-service/terra/user/info', { empUUID });
};

export const deleteWearableRequest = () => {
  return deleteRequest('/physical-activity-service/terra/deauthentication');
};

export const putWearableRequest = (userId: string, provider: string) => {
  return putRequest(`/physical-activity-service/terra/user/info`, { user_id: userId, provider });
};

export const postNotificationRequest = (fcmToken: string) => {
  return postRequest(`/notification-service/firebase/token/registration`, { fcmToken });
};
