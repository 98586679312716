import PrimaryNavigation from './PrimaryNavigation';
import { IonSplitPane } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

const Layout: React.FC = ({ children }) => {
  const [expand, setExpand] = useState<boolean>(false);
  const { first_login } = useSelector((state: RootState) => state.profile.data.accountInfo);

  useEffect(() => {
    if (first_login) setExpand(true);
  }, [first_login]);

  return (
    <IonSplitPane
      contentId="main"
      style={{ '--side-min-width': 10, '--side-width': expand ? '180px' : '60px', '--border': 'none' }}
      when="(min-width: 600px)"
    >
      <PrimaryNavigation expand={expand} setExpand={setExpand} />
      {children}
    </IonSplitPane>
  );
};

export default Layout;
