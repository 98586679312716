import React, { useEffect, useState } from 'react';
import { Box, Grid, Popover, styled, TextField } from '@mui/material';
import { ReactComponent as SearchIcon } from '../../assets/svg/feature/search.svg';
import { useDebounce } from '../../hooks/useDebounce';
import { Colors } from '../../theme/colors';
import { SxProps } from '@mui/system';

const CustomTextField = styled(TextField)(({ theme }) => ({
  zIndex: 1,
  borderLeft: '1px solid #F0F2F3',
  backgroundColor: 'white',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    color: theme.palette.secondary.main,
  },
  opacity: 1,
}));

const StyledBox = styled(Box)({
  position: 'relative',
  height: '36px',
  display: 'flex',
  alignItems: 'center',
  background: Colors.white,
  borderRadius: '1000px',
  overflow: 'hidden',
});

type CustomSearchbarProps = {
  searchInput: string;
  setSearchInput: (s: string) => void;
  handleSearch?: (s: string) => void;
  width?: number | string;
  fullWidth?: boolean;
  autoCompleteList?: string[];
  enableSearchList?: boolean;
  sx?: SxProps;
};

const CustomSearchbar: React.FC<CustomSearchbarProps> = ({
  width = 320,
  fullWidth,
  searchInput,
  setSearchInput,
  handleSearch,
  autoCompleteList,
  enableSearchList = true,
  sx,
}) => {
  const [showList, setShowList] = useState<boolean>(false);
  const [actualList, setActualList] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const debouncedList = useDebounce(actualList, 700);

  useEffect(() => {
    if (!searchInput) {
      setActualList([]);
    } else if (autoCompleteList) {
      const tempList = autoCompleteList.filter((i) => i.toLowerCase().includes(searchInput.toLowerCase()));
      setActualList(tempList);
    } else setActualList([]);
  }, [autoCompleteList, searchInput]);

  useEffect(() => {
    if (debouncedList?.length) {
      setShowList(true);
    }
  }, [debouncedList]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  };

  const handleAutofillClick = (s: string) => {
    if (handleSearch) handleSearch(s);

    setShowList(false);
  };

  const expandTheSearchbar = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <StyledBox sx={{ width: width, ...sx }} onClick={expandTheSearchbar}>
        <SearchIcon style={{ zIndex: 2, position: 'absolute', left: 16, top: 10, cursor: 'pointer' }} />
        <Box
          style={{
            zIndex: 2,
            position: 'absolute',
            left: 42,
            top: 10,
            background: Colors.gray[50],
            width: 1,
            height: 16,
          }}
        />
        <CustomTextField
          fullWidth
          sx={{ input: { color: 'text.primary', padding: '8px 16px 8px 52px' } }}
          variant="outlined"
          size="small"
          color="secondary"
          value={searchInput}
          onChange={handleChange}
          autoComplete={'off'}
          placeholder={'Search'}
        />
      </StyledBox>
      <Popover
        open={showList && enableSearchList}
        onClose={() => setShowList(false)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          sx: {
            '& .rdrDefinedRangesWrapper': {
              display: 'none !important',
            },
            borderRadius: '24px',
            boxShadow: 'var(--shadow-shape)',
            mt: 2,
          },
        }}
      >
        <Box
          style={{
            // position: 'absolute',
            maxWidth: '500px',
            // top: 'calc(100% + 8px)',
            // right: '8px',
            backgroundColor: 'white',
            boxShadow: 'var(--shadow-button)',
            borderRadius: '16px',
            padding: '8px',
            maxHeight: '400px',
            overflow: 'auto',
            // zIndex: 3000,
          }}
        >
          <Grid container>
            {actualList.map((item, index) => (
              <Grid
                item
                xs={12}
                onClick={() => handleAutofillClick(item)}
                key={index}
                sx={{
                  cursor: 'pointer',
                  my: 1,
                  px: 2,
                  py: 1,
                  borderRadius: '16px',
                  fontSize: 14,
                  '&:hover': {
                    backgroundColor: 'rgba(0, 182, 190, 0.12)',
                  },
                }}
              >
                {item}
              </Grid>
            ))}
          </Grid>
        </Box>
      </Popover>
    </>
  );
};

export default CustomSearchbar;
