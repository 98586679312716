export const AdminChallengesPage = [
  '/page/employees-challenges',
  '/page/employees-challenges/challenge//results',
  '/page/employees-challenges/challenge-templates',
  '/page/employees-challenges/ongoing',
  '/page/employees-challenges/join',
  '/page/employees-challenges/finished',
  '/page/employees-challenges/challenge-templates/template/',
  '/page/employees-challenges/challenge/',
  '/page/employees-challenges/edit-challenge/',
];
export const AdminRoutes = [
  '/page/employees-focus',
  '/page/employees-journey',
  '/page/employees-rewards',
  '/page/employees-steps',
  '/page/employees',
  '/page/employees',
  ...AdminChallengesPage,
];
