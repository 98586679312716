import React from 'react';
import Grid from '@mui/material/Grid';
import { Skeleton, useMediaQuery } from '@mui/material';
import theme from '../../../theme';
export const SkeletonContent: React.FC = () => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  return mdDown ? (
    <Grid sx={{ position: 'relative', width: '100%', marginRight: '24px' }}>
      <Skeleton variant="rectangular" height={'204px'} width={'100%'} sx={{ borderRadius: '20px' }} />

      <Skeleton variant="rectangular" height={'410px'} sx={{ borderRadius: '20px', marginTop: '24px' }} />
    </Grid>
  ) : (
    <Grid container display={'flex'} wrap={'nowrap'}>
      <Grid sx={{ position: 'relative', width: '100%', marginRight: '24px' }}>
        <Skeleton variant="rectangular" height={'410px'} width={'100%'} sx={{ borderRadius: '20px' }} />
      </Grid>
      <Grid
        sx={{
          width: '276px',
        }}
      >
        <Skeleton variant="rectangular" height={'176px'} width={'100%'} sx={{ borderRadius: '20px' }} />
      </Grid>
    </Grid>
  );
};
