import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { useEffect, useMemo, useRef, useState } from 'react';
import useViewIsVisible from '../../../../hooks/useViewIsVisible';
import { ANALYTICS_PAGE } from '../../../../models/enum/ANALYTICS_PAGE';
import { PlayEvent, TimeUpdateEvent } from '@u-wave/react-vimeo';
import { trackEvent } from '../../../../utils/analytics';
import { getFocusAdminBlogs, setSavedVideoTime } from '../../../../redux/slices/focusAdminSlice';
import { handlerTrackEvent } from '../../../../utils/handlerTrackEvent';

export const useHandle = () => {
  const [module, setModule] = useState<string>('1'); //ID
  const [week, setWeek] = useState<string>('-1'); //INDEX
  const [videoWatched, setVideoWatched] = useState<boolean>(false);
  const [showVideo, setShowVideo] = useState(false);

  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const visible = useViewIsVisible(ANALYTICS_PAGE.FOCUS);

  const dispatch = useDispatch();
  const { blogs, url, language, modules, savedVideoTime, videoUUID } = useSelector(
    (root: RootState) => root.focusAdminPage.data,
  );
  const blogsLoading = useSelector((root: RootState) => root.focusAdminPage.meta.blogsLoading);

  const onTimeUpdate = (event: TimeUpdateEvent) => {
    dispatch(setSavedVideoTime(event.seconds));
    if (!videoWatched && event.percent >= 0.95) {
      handlerTrackEvent({ type: 'VIDEOS', uuid: videoUUID, videoAction: 'COMPLETED' });
      trackEvent('video_watched', { page: ANALYTICS_PAGE.FOCUS_ADMIN });
      setVideoWatched(true);
    }
  };

  const onPlayVideo = (event: PlayEvent) => {
    if (event.percent === 0 && !videoWatched) {
      handlerTrackEvent({ type: 'VIDEOS', videoAction: 'STARTED', uuid: videoUUID });
    }
  };

  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop(1000);
  };

  useEffect(() => {
    //Scroll to top when fetch new blogs
    scrollToTop();
  }, [blogs]);

  const handleChangeModule = (newModule: string) => {
    try {
      if (newModule !== module) {
        dispatch(setSavedVideoTime(0));
        setWeek('1');
        setModule(newModule);
        setVideoWatched(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleChangeWeek = (val: string) => {
    dispatch(setSavedVideoTime(0));
    setWeek(val);
  };

  const disabled = modules.length === 0;

  const list = useMemo(() => {
    let newList: { label: string; value: string }[] = [];
    if (modules.length > 0) {
      const foundModule = modules?.findIndex((mod) => mod.moduleId === Number(module));
      if (foundModule !== -1) {
        newList = modules[foundModule].periods?.map((i) => {
          return { label: `Month ${i.periodIndex}: ` + i.periodTitle, value: String(i.periodIndex) };
        });
      }
    }
    return newList;
  }, [modules, module]);

  const foundModule = (modules ?? []).find((m) => m.moduleId === Number(module));

  useEffect(() => {
    visible ? setShowVideo(true) : setShowVideo(false);
  }, [visible]);

  useEffect(() => {
    if (!visible) return;

    if (week !== '-1') {
      dispatch(
        getFocusAdminBlogs({
          pagination: {
            module: Number(module),
            week: Number(week),
          },
        }),
      );
    }
  }, [dispatch, module, week, visible]);

  useEffect(() => {
    if (modules && modules.length && modules[modules.length - 1].periods) {
      const lastModule = modules[modules.length - 1];
      setModule(String(lastModule.moduleId));
      modules.forEach((mod) => {
        mod.periods.forEach((w, index) => {
          if (w.current === true) setWeek(String(index + 1));
        });
      });
    }
  }, [modules]);

  return {
    week,
    url,
    language,
    modules,
    module,
    savedVideoTime,
    disabled,
    list,
    blogs,
    blogsLoading,
    foundModule,
    showVideo,
    onTimeUpdate,
    setWeek,
    setVideoWatched,
    handleChangeModule,
    handleChangeWeek,
    onPlayVideo,
  };
};
