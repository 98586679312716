import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { fetchChallengeResult, fetchNextPageAdminLeaderBoard } from '../../redux/slices/challengeAdminSlice';
import { CHALLENGE_SUB_CATEGORY } from '../../models/enum/CHALLENGE_SUB_CATEGORY';
import { useIonRouter } from '@ionic/react';
import { formatLeaderboardList } from '../../utils/formatData';
import { CHALLENGE_TYPE } from '../../models/enum/CHALLENGE_TYPE';
import { AWARD_TRIGGER } from '../../models/enum/AWARD_TRIGGER';
import { LeaderBoardType } from '../../models/Shared';
import moment from 'moment';
import { COMPETITION } from '../../models/enum/COMPETITION';
import { getRequest } from '../../services/HttpService';
import { useExportExcel } from '../../hooks/useExportExcel';
import { handleBackendError } from '../../utils/handleBackendError';
import { openToast } from '../../redux/slices/appSlice';
import { throttle } from 'lodash';

export const useHandle = (challengeUUID: string) => {
  const [visibleLeaderBoard, setVisibleLeaderBoard] = useState(false);

  const listRef = useRef<HTMLUListElement>(null);

  const { exportToExcel, exportFileName } = useExportExcel();

  const challenge = useSelector((state: RootState) => state.challengeAdmin.data.challengeResult);
  const leaderboard = useSelector((state: RootState) => state.challengeAdmin.data.challengeResultRanking);
  const isLoading = useSelector((state: RootState) => state.challengeAdmin.meta.challengeResultLoading);
  const isFetching = useSelector((state: RootState) => state.challengeAdmin.meta.isFetching);

  const dispatch: AppDispatch = useDispatch();
  const router = useIonRouter();

  const handleScrollEvent = throttle(() => {
    if (listRef.current) {
      const { scrollHeight, scrollTop, offsetHeight } = listRef.current;
      const currentPosition = scrollTop + offsetHeight;
      const triggerPosition = scrollHeight - 40;
      if (currentPosition >= triggerPosition && !isFetching) {
        handleNextPageLeaderBoard();
      }
    }
  }, 300);

  const handleNextPageLeaderBoard = () => {
    if (!leaderboard || leaderboard.last) return;
    dispatch(fetchNextPageAdminLeaderBoard({ challengeUUID, page: (leaderboard.pageable.pageNumber + 1).toString() }));
  };

  const props = useMemo(() => {
    const isGracePeriodEnd = challenge?.challengeDuration.gracePeriodEnd
      ? new Date().setHours(0, 0, 0, 0) > new Date(challenge.challengeDuration.gracePeriodEnd).setHours(0, 0, 0, 0)
      : false;
    const isTeam = challenge?.challengeCharacteristic.competition === COMPETITION.TEAM;
    const participantsAvatars = challenge?.participantsAvatars ?? [];
    const teamsAvatars = challenge?.teamsAvatars ?? [];
    const numberOfParticipants = challenge?.challengeParticipation.numberOfParticipants ?? 0;
    const numberOfTeams = challenge?.challengeParticipation.numberOfTeams ?? 0;
    return {
      title: challenge?.challengeInfo.title ?? '',
      breadcrumb: [
        { label: "Your Employees' challenges", url: '/page/employees-challenges' },
        ...(challenge
          ? [
              {
                label:
                  challenge.challengeSubCategory === CHALLENGE_SUB_CATEGORY.ONGOING
                    ? 'Ongoing challenges'
                    : 'Finished challenges',
                url:
                  challenge.challengeSubCategory === CHALLENGE_SUB_CATEGORY.ONGOING
                    ? '/page/employees-challenges/ongoing'
                    : '/page/employees-challenges/finished',
              },
            ]
          : []),
        { label: challenge?.challengeInfo.title ?? '', url: '/' },
      ],
      leaderboardList: formatLeaderboardList(leaderboard?.content ?? []),
      target: challenge?.challengeGoal?.totalGoalValue ?? 0,
      type: (challenge?.challengeCharacteristic.challengeType === CHALLENGE_TYPE.TARGET &&
      challenge?.challengeAward.awardTrigger !== AWARD_TRIGGER.FASTEST
        ? 'Target'
        : challenge?.challengeAward?.awardTrigger === AWARD_TRIGGER.TOP ||
          challenge?.challengeAward?.awardTrigger === AWARD_TRIGGER.FASTEST
        ? 'Top'
        : 'Completion') as LeaderBoardType,
      isGracePeriodEnd,
      triggerLimit: challenge?.challengeAward?.awardTriggerLimit ?? null,
      lastSync: moment(challenge?.leaderboardLastSync).format('DD/MM/YY'),
      participantsNumber: isTeam
        ? teamsAvatars.length + numberOfTeams
        : participantsAvatars.length + numberOfParticipants,
      isTeam,
      awardType: challenge?.challengeAward?.awards?.[0]?.awardType,
      awardIcon: challenge?.challengeAward?.awards?.[0]?.badgeImageUrl,
      points: challenge?.challengeAward?.awards?.[0]?.points,
      dayPassed: challenge
        ? challenge.challengeDuration.currentDayInChallenge > challenge.challengeDuration?.durationInDays
          ? challenge?.challengeDuration?.durationInDays
          : challenge.challengeDuration.currentDayInChallenge
        : 0,
      days: challenge?.challengeDuration?.durationInDays,
      awardedUserCount: challenge?.challengeAward?.awardedUserCount,
      slackURL: challenge?.metadata.slackURL,
    };
  }, [challenge, leaderboard]);

  const handleOpenDetail = () =>
    challengeUUID ? router.push(`/page/employees-challenges/challenge/${challengeUUID}`) : null;

  const handleOpenLeaderBoard = () => (isLoading ? null : setVisibleLeaderBoard(true));
  const handleCloseLeaderBoard = () => setVisibleLeaderBoard(false);

  const handleExportRanking = async () => {
    try {
      const response: { data?: string } = await getRequest('/gamification-service/admin/challenge/ranking/export', {
        challengeUUID,
      });
      if (response.data) {
        await exportToExcel(
          response.data,
          exportFileName('ranking-export', challenge?.metadata.challengeUUID, challenge?.challengeInfo.title),
        );
        return;
      }
      dispatch(openToast({ type: 'error', text: 'Export file is empty' }));
    } catch (err) {
      handleBackendError(err, 'Fail to export leaderboard');
    }
  };

  const handleExportParticipants = async () => {
    try {
      const response: { data?: string } = await getRequest(
        '/gamification-service/admin/challenge/participants/export',
        {
          challengeUUID,
        },
      );
      if (response.data) {
        await exportToExcel(
          response.data,
          exportFileName('participants-export', challenge?.metadata.challengeUUID, challenge?.challengeInfo.title),
        );
        return;
      }
      dispatch(openToast({ type: 'error', text: 'Export file is empty' }));
    } catch (err) {
      handleBackendError(err, 'Fail to export participants');
    }
  };

  const handleExportTeams = async () => {
    try {
      const response: { data?: string } = await getRequest('/gamification-service/admin/challenge/teams/export', {
        challengeUUID,
      });
      if (response.data) {
        await exportToExcel(
          response.data,
          exportFileName('teams-export', challenge?.metadata.challengeUUID, challenge?.challengeInfo.title),
        );
        return;
      }
      dispatch(openToast({ type: 'error', text: 'Export file is empty' }));
    } catch (err) {
      handleBackendError(err, 'Fail to export teams');
    }
  };

  useEffect(() => {
    dispatch(fetchChallengeResult({ challengeUUID }));
  }, [challengeUUID]);

  return {
    ...props,
    isLoading,
    visibleLeaderBoard,
    challenge,
    handleOpenDetail,
    handleOpenLeaderBoard,
    handleCloseLeaderBoard,
    handleExportRanking,
    handleExportParticipants,
    handleExportTeams,
    listRef,
    handleScrollEvent,
  };
};
