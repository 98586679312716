import { Colors } from '../../theme/colors';
import { Box, Theme, Typography } from '@mui/material';
import React, { SVGProps } from 'react';
import { SxProps } from '@mui/system';

type Props = {
  Icon: React.FunctionComponent<SVGProps<SVGSVGElement>>;
  label: string;
  size?: number;
  bgColor?: string;
  textColor?: string;
  borderColor?: string;
  boxColor?: string;
  iconColor?: string;
  sx?: SxProps<Theme>;
  labelSx?: SxProps<Theme>;
  onClick?: () => void;
};
export const CustomBudge: React.FC<Props> = ({
  Icon,
  label,
  size = 24,
  bgColor = Colors.gray[100],
  textColor = Colors.gray[700],
  borderColor = '#E9EDEF',
  boxColor = Colors.gray[200],
  iconColor = Colors.white,
  sx,
  labelSx,
  onClick,
}) => {
  return (
    <Box
      sx={[
        {
          padding: '2px',
          backgroundColor: bgColor,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          borderRadius: '24px',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      onClick={onClick}
    >
      <Box
        sx={{
          height: size,
          width: size,
          borderRadius: size,
          border: `1px solid ${borderColor}`,
          backgroundColor: boxColor,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Icon width={size > 24 ? 16 : 12} height={size > 24 ? 16 : 12} fill={iconColor} />
      </Box>
      <Typography
        sx={[
          {
            ml: '8px',
            mr: '12px',
            color: textColor,
            fontSize: size > 24 ? '14px' : '12px',
            fontWeight: 700,
          },
          ...(Array.isArray(labelSx) ? labelSx : [labelSx]),
        ]}
      >
        {label}
      </Typography>
    </Box>
  );
};
